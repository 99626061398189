<template>
  <div>
    <AdminPanelHeader
      icon="mdi-barcode"
      title="Catalog Purpose Code"
      :actions="headerActions"
      @search="onSearch"
    />

    <X12catalogPurposeCodeTable
      :paginatedList="currentEntity"
      :searchParams="paginatedQueryParams"
      :isLoading="isLoading"
      v-on:onDelete="onDelete"
    />
  </div>
</template>

<script>
import apiClient from "../../../../apiClient";
import { AdminPanelHeaderAction } from "../../../../components/AdminPanelHeader/AdminPanelHeaderAction";
import { showDialog } from "../../../../components/Dialogs/NotifyDialog";
import { parentComponentMixin } from "../../../../mixins/parentComponentMixin";
export default {
  mixins:[parentComponentMixin],
  name: "X12CatalogPurposeCode",
  data() {
    return {
      headerActions: [
        new AdminPanelHeaderAction({
          color: "accent",
          label: "Add new",
          icon: "add",
          action: () =>
            this.$router.push(
              "/admin-panel/definitions/catalogPurposeCodes/add"
            ),
        }),
      ],
      paginatedQueryParams: {
        pageSize: 10,
        pageNumber: 0,
        searchValue: "",
        sortField: "catalogPurposeId",
        sortDirection: "DESC",
      },
    };
  },
  methods: {
      async onDelete(id){
          try {
              this.isLoading = true;
              await apiClient.X12("x12catalogPurposeCodes").delete(id);
              showDialog("Deleted Successfully");
              this.getPaginated();
          } catch (error) {
              showDialog("Could not delete item", error);
          }finally{
              this.isLoading = false;
          }
      },
    async getPaginated() {
      try {
        this.isLoading = true;
        this.currentEntity =
          await apiClient.X12("x12catalogPurposeCodes").getPaginated(
            this.paginatedQueryParams
          );
      } catch (error) {
        showDialog("Could not get data", error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>