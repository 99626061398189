<template>
  <v-app>
    <vue-snotify></vue-snotify>
    <div class="headers">
      <emb-header-v1
        v-if="$route.meta.header != 2 && $route.meta.header != 3"
      ></emb-header-v1>
      <emb-header-v2 v-if="$route.meta.header === 2"></emb-header-v2>
      <emb-header-v3 v-if="$route.meta.header === 3"></emb-header-v3>
    </div>

    <!-- side menu for mobile -->
    <v-navigation-drawer
      v-model="mobileMenu"
      temporary
      absolute
      dark
      class="sidebar-bg"
    >
      <emb-sidebar v-if="$msal.isAccountSelected()" :menus="getMenuItems()">
      </emb-sidebar>
      <emb-sidebar v-else :menus="getMenuItems()">
      </emb-sidebar> </v-navigation-drawer
    ><!-- side menu for mobile -->

    <div>
      <router-view :key="$route.fullPath"> </router-view>
    </div>

    <emb-footer-V1
      title="About us"
      :description="aboutUs"
      img="/static/images/cards.png"
    >
    </emb-footer-V1>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import { search } from "../mixins/index";

export default {
  data: () => ({
    aboutUs: process.env.VUE_APP_ABOUTUS_TEXT,
  }),

  computed: {
    ...mapGetters(["rtlLayout", "menus", "clientPanelMenusNoAccount"]),
    ...mapGetters({
      productCategories: "search/getProductCategories",
      isAccountSelected: "session/isAccountSelected",
      isAnyLicenseExpired: "session/isAnyLicenseExpired",
      isAnyLicenseActive: "session/isAnyLicenseActive",
      isCreditOnHold: "session/isCreditOnHold",
    }),

    mobileMenu: {
      get() {
        return this.$store.getters.sidebarOpen;
      },
      set(val) {
        this.$store.dispatch("toggleSidebar", val);
      },
    },
  },
  /**
   * Method To set the Rtl While page is opened
   */
  mounted() {
    if (this.rtlLayout) {
      this.$vuetify.rtl = this.rtlLayout;
    }
  },
  methods: {
    /**
     * Method To Toggle The RTL Layout
     */
    toggleRTLLayout() {
      this.$vuetify.rtl = !this.rtlLayout;
      this.$store.dispatch("changeRtlLayout");
    },

    getMenuItems() {
      return search.methods.createMenuItems(
        this.productCategories,
        this.isAccountSelected,
        this.isAnyLicenseActive,
        this.isCreditOnHold
      );
    },
  },

  mixins: [search],
};
</script>
