<template>
  <div>
    <AdminPanelHeader
      icon="mdi-credit-card-settings-outline"
      title="Payment Methods"
      :actions="headerActions"
      @search="onSearch"
    />

    <X12paymentMethodTable 
    :paginatedList="currentEntity"
    :isLoading="isLoading"
    v-on:onDelete="onDelete"
    :searchParams="paginatedQueryParams"
    />
  </div>
</template>

<script>
import apiClient from '../../../../apiClient';
import { AdminPanelHeaderAction } from '../../../../components/AdminPanelHeader/AdminPanelHeaderAction';
import { showDialog } from '../../../../components/Dialogs/NotifyDialog';
import { parentComponentMixin } from '../../../../mixins/parentComponentMixin';
export default {
  mixins: [parentComponentMixin],
  name: "X12paymentMethod",
  data() {
    return {
      paginatedQueryParams: {
        pageSize: 10,
        pageNumber: 0,
        searchValue: "",
        sortField: "paymentMethodId",
        sortDirection: "DESC",
      },
      headerActions: [
        new AdminPanelHeaderAction({
          color: "accent",
          label: "Add new",
          icon: "add",
          action: () =>
            this.$router.push(
              "/admin-panel/definitions/paymentMethod/add"
            ),
        }),
      ],
    };
  },
  methods:{
      async onDelete(id){
          try {
              this.isLoading = true;
              await apiClient.X12("x12paymentMethods").delete(id);
              showDialog("Deleted Successfully");
              this.getPaginated();
          } catch (error) {
              showDialog("Could not delete item",error);
          }finally{
              this.isLoading = false;
          }
      },
      async getPaginated(){
          try {
              this.isLoading = true;
              this.currentEntity = await apiClient.X12("x12paymentMethods").getPaginated(this.paginatedQueryParams);
          } catch (error) {
              showDialog("Could not retrieve data",error)
          }finally{
              this.isLoading = false;
          }
      }
  },
};
</script>
