var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "px-4 py-2 mb-4", class: { "py-7": !_vm.searchBar } },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _vm.searchBar
            ? _c(
                "v-col",
                {
                  staticClass:
                    "d-flex align-center justify-center justify-sm-start",
                  attrs: { cols: "12", sm: "3" }
                },
                [
                  _c("v-icon", { staticClass: "mr-2" }, [
                    _vm._v(_vm._s(_vm.icon))
                  ]),
                  _c("h5", { staticClass: "ma-0" }, [_vm._v(_vm._s(_vm.title))])
                ],
                1
              )
            : _vm._e(),
          _vm.searchBar
            ? _c(
                "v-col",
                {
                  staticClass: "d-flex justify-center align-center",
                  attrs: { cols: "12", sm: "6" }
                },
                [
                  _c("v-text-field", {
                    staticClass: "mr-4",
                    attrs: {
                      "append-icon": "search",
                      label: _vm.searchPlaceholder
                    },
                    on: { change: _vm.onSearch, "click:append": _vm.onSearch },
                    model: {
                      value: _vm.searchValue,
                      callback: function($$v) {
                        _vm.searchValue = $$v
                      },
                      expression: "searchValue"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-col",
            [
              _c(
                "v-layout",
                {
                  staticStyle: { height: "100%" },
                  attrs: { "justify-end": "", "align-center": "" }
                },
                _vm._l(_vm.actions, function(item, index) {
                  return _c(
                    "v-btn",
                    {
                      key: index,
                      staticClass: "ml-2",
                      attrs: { color: item.color, text: item.text },
                      on: {
                        click: function($event) {
                          return _vm.onActionClicked(item)
                        }
                      }
                    },
                    [
                      item.icon
                        ? _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v(" " + _vm._s(item.icon) + " ")
                          ])
                        : _vm._e(),
                      _vm._v(" " + _vm._s(item.label) + " ")
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }