import axios from '../api';

export default (url) => ({
    /**
     * @param {Number} id
     */
    async getById(id) {
        const response = await axios.get(`${url}/${id}`);
        return response.data;
    },

    async getPaginated({ pageNumber, pageSize, searchValue, sortDirection, sortField }) {
        const response = await axios.get(`${url}/paginated`, {
            params: {
                pageNumber, pageSize, searchValue, sortDirection, sortField
            }
        });
        return response.data;
    },
    
    async getFilteredPaginated({ pageNumber, pageSize, searchValue, sortDirection, sortField,filterValue }) {
        const response = await axios.get(`${url}/filtered/paginated`, {
            params: {
                pageNumber, pageSize, searchValue, sortDirection, sortField,filterValue
            }
        });
        return response.data;
    },

    async delete(id) {
        const response = await axios.delete(`${url}/${id}`);
        return response.data;
    },

    async update(id, body) {
        const response = await axios.put(`${url}/${id}`, body);
        return response.data;
    },

    async create(body) {
        const response = await axios.post(url, body);
        return response.data;
    },
})