/* eslint-disable */

export const orders = {
    methods: {
        calculateTotal(order) {
            let subTotal = this.calculateSubTotal(order);
            let discounts = this.calculateDiscounts(order);
            let deposits = this.calculateDeposits(order);
            return subTotal + deposits - discounts ;
        },

        calculateSubTotal(order) {
            let sum = 0;

            for (const orderLine of order.orderLines) {
                sum += orderLine.quantity * orderLine.unitPrice;
            }

            return sum;
        },

        calculateDiscounts(order) {
            let sum = 0;
    
            for (const orderLine of order.orderLines) {
                sum += orderLine.quantity * (orderLine.discount || 0);
            }
    
            return sum;
        },

        calculateDeposits(order) {
            let sum = 0;

            for (const orderLine of order.orderLines) {
                sum += orderLine.quantity * (orderLine.depositPrice || 0);
            }

            return sum;
        },
    },
};
