<template>
	<div>
		<gmap-map
			:center="center"
			:zoom="7"
			style="width:100%;  height: 400px;"
			>
			<gmap-marker
				:key="index"
				v-for="(m, index) in markers"
				:position="m.position"
				:clickable="true"
				:draggable="true"
			>
			</gmap-marker>
		</gmap-map>
	</div>
</template>

<script>
export default {
	name: "GoogleMap",
	data() {
		return {
			center: { lat: 29.868260, lng: -95.533040 },
			markers: [
				{
					label: " 9010 W Little York Rd , Houston, TX 77040",
					position: {lat: 29.868260, lng: -95.533040}
				}, 
			]	
		};
	}
}
</script>
