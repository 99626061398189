<template>
  <div>
    <AdminPanelHeader
      icon="mdi-file-account"
      title="Subscription FileType Associations"
      :actions="headerActions"
      @search="onSearch"
    />
    <X12subscriptionFileTypeAssociationTable
      v-on:onDelete="onDelete"
      :paginatedList="currentEntity"
      :isLoading="isLoading"
      :searchParams="paginatedQueryParams"
    />
  </div>
</template>

<script>
import apiClient from "../../../../../apiClient";
import { AdminPanelHeaderAction } from "../../../../../components/AdminPanelHeader/AdminPanelHeaderAction";
import { showDialog } from "../../../../../components/Dialogs/NotifyDialog";
import { parentComponentMixin } from '../../../../../mixins/parentComponentMixin';

export default {
  name: "SubscriptionFileTypeAssociations",
  mixins:[parentComponentMixin],
  data() {
    return {
      paginatedQueryParams: {
        pageSize: 10,
        pageNumber: 0,
        searchValue: "",
        sortField: "X12subscriptionFileTypeAssociationId",
        sortDirection: "DESC",
        filterValue:""
      },
      headerActions: [
        new AdminPanelHeaderAction({
          color: "accent",
          label: "Add new",
          icon: "add",
          action: () =>
            this.$router.push(
              "/admin-panel/SubscriptionFileTypeAssociations/add"
            ),
        }),
      ],
    };
  },
  methods: {
    async onDelete(id) {
      try {
        this.isLoading = true;
        await apiClient.X12("x12subscriptionFileTypeAssociation").delete(id);
        this.getPaginated();
        showDialog("Item deleted successfully");
      } catch (error) {
        showDialog("Could not delete item", error);
      } finally {
        this.isLoading = false;
      }
    },
    async getPaginated() {
      try {
        this.isLoading = true;
        this.paginatedQueryParams.filterValue = this.$route.params.id;    
        this.currentEntity = await apiClient.X12("x12subscriptionFileTypeAssociation").getFilteredPaginated(
            this.paginatedQueryParams
          );
      } catch (error) {
        showDialog("Could not get data", error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>