<template>
    <div>
        <Breadcrumbs class="mb-4" :items="breadcrumbs"/>

        <div class="center-x" v-if="isLoading">
            <v-skeleton-loader type="text" width="100%"/>
        </div>
        <v-card>
            <v-card-title>Charge Type</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" sm="6" v-show="!isAdd">
                        <v-text-field
                            label="Charge Type Id"
                            v-model.number="currentObject.chargeTypeId"
                            disabled
                        />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field
                            label="Charge Type Description"
                            v-model="currentObject.chargeTypeDescription"
                        />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field
                            label="Charge Type"
                            v-model="currentObject.chargeType"
                        />
                    </v-col>
                    <v-col cols="12" sm="6" v-show="!isAdd">
                        <v-text-field
                            label="Last Edited By"
                            v-model.number="currentObject.lastEditedBy"
                            disabled
                        />
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-row>
            <v-col>
                <div class="d-flex justify-end">
                    <v-btn color="error" large @click="save">Save</v-btn>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import apiClient from "../../../../apiClient";
import { BreadcrumbItem } from "../../../../components/Breadcrumbs/BreadcrumbItem";
import { showDialog } from '../../../../components/Dialogs/NotifyDialog';
import { formsMixin } from '../../../../mixins/formsMixin';
import { StaticRoutes } from "../../../../router/Routes";

export default {
    mixins:[formsMixin],
    name: "X12ChargeTypesForm",
    data() {
        return {
            breadcrumbs: [
                new BreadcrumbItem({
                    label: "Charge Types",
                    route: StaticRoutes.adminPanel.trading.x12Definitions.chargeTypes
                }),
                new BreadcrumbItem({ label: "Charge Type Details" })
            ],
        }
    },
    methods: {
        async getById() {
            try {
                this.isLoading = true
                this.currentObject = await apiClient.X12("X12ChargeTypes").getById(this.id);
            } catch (error) {
                showDialog("Could not load Charge Type.", error)
            } finally {
                this.isLoading = false
            }
        },
        async save() {
            try {
                this.isLoading = true
                if (this.isEdit) {
                    await apiClient.X12("X12ChargeTypes").update(this.id,this.currentObject)
                    showDialog('Updated Successfully')
                }
                if (this.isAdd) {
                    await apiClient.X12("X12ChargeTypes").create(this.currentObject)
                    showDialog('Created Successfully')
                }
                this.$router.push("/admin-panel/definitions/chargeTypes")
            } catch (error) {
                showDialog('Could not update', error)
            } finally {
                this.isLoading = false
            }
        },
    },
}
</script>