var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.userDetails
    ? _c(
        "div",
        { staticClass: "emb-FinalReceipt-wrap" },
        [
          _c("emb-page-title", { attrs: { heading: "Payment Information" } }),
          _c(
            "div",
            { staticClass: "final-receipt  section-gap" },
            [
              _c(
                "v-container",
                { attrs: { "grid-list-xl": "", "py-0": "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { "align-center": "", "justify-center": "" } },
                    [
                      _c(
                        "v-flex",
                        {
                          attrs: {
                            xs12: "",
                            sm12: "",
                            md10: "",
                            lg7: "",
                            xl7: ""
                          }
                        },
                        [
                          _c("div", { staticClass: "emb-card pb-12" }, [
                            _c("div", { attrs: { id: "payment-receipt" } }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "text-center bg-grey pa-sm-12 pa-6"
                                },
                                [
                                  _c("h1", [_vm._v("Thank You")]),
                                  _c("h5", { staticClass: "mb-6" }, [
                                    _vm._v(
                                      "Payment is successfully processsed and your order is on the way "
                                    )
                                  ]),
                                  _c("h6", { staticClass: "mb-6" }, [
                                    _vm._v(
                                      "Transaction ID: " +
                                        _vm._s(_vm.invoiceData.transactionId)
                                    )
                                  ]),
                                  _c("img", {
                                    attrs: {
                                      src: "/static/images/checked.png",
                                      width: "64",
                                      height: "64",
                                      alt: "Success"
                                    }
                                  })
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "py-sm-12 py-8 px-sm-6 px-4" },
                                [
                                  _c(
                                    "v-layout",
                                    {
                                      attrs: { row: "", wrap: "", "mb-6": "" }
                                    },
                                    [
                                      _c(
                                        "v-flex",
                                        {
                                          attrs: {
                                            xs12: "",
                                            sm6: "",
                                            md6: "",
                                            lg6: "",
                                            xl6: ""
                                          }
                                        },
                                        [
                                          _c("h6", [_vm._v("Summary")]),
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "mb-1 text-capitalize"
                                            },
                                            [
                                              _vm._v(
                                                "Order ID: " +
                                                  _vm._s(
                                                    _vm.invoiceData.orderId
                                                  )
                                              )
                                            ]
                                          ),
                                          _c("p", { staticClass: "mb-1" }, [
                                            _vm._v(
                                              "Order Date: " +
                                                _vm._s(
                                                  _vm.invoiceData.orderedDate
                                                )
                                            )
                                          ]),
                                          _c(
                                            "p",
                                            { staticClass: "mb-1" },
                                            [
                                              _vm._v("Order Total: "),
                                              _c("emb-currency-sign"),
                                              _vm._v(
                                                _vm._s(_vm.getTotalPrice())
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          attrs: {
                                            xs12: "",
                                            sm6: "",
                                            md6: "",
                                            lg6: "",
                                            xl6: ""
                                          }
                                        },
                                        [
                                          _c("h6", [_vm._v("Ship To")]),
                                          _c("p", { staticClass: "mb-1" }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.userDetails.firstName
                                              ) +
                                                " " +
                                                _vm._s(_vm.userDetails.lastName)
                                            )
                                          ]),
                                          _c("p", { staticClass: "mb-1" }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.userDetails.aptBuilding
                                              )
                                            )
                                          ]),
                                          _c("p", { staticClass: "mb-1" }, [
                                            _vm._v(
                                              _vm._s(_vm.userDetails.cityState)
                                            )
                                          ]),
                                          _c("p", { staticClass: "mb-1" }, [
                                            _vm._v(
                                              _vm._s(_vm.userDetails.country) +
                                                " - " +
                                                _vm._s(_vm.userDetails.zipCode)
                                            )
                                          ]),
                                          _c("p", { staticClass: "mb-1" }, [
                                            _vm._v(
                                              "Contact No. " +
                                                _vm._s(_vm.userDetails.phone)
                                            )
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "py-6 text-center bg-grey px-3"
                                    },
                                    [
                                      _c("h4", [
                                        _vm._v("Expected Date of Delivery")
                                      ]),
                                      _c("h3", [
                                        _vm._v(
                                          _vm._s(_vm.invoiceData.deliveryDate)
                                        )
                                      ])
                                    ]
                                  ),
                                  _c("h4", { staticClass: "pt-6" }, [
                                    _vm._v("Your Ordered Details")
                                  ]),
                                  _vm._l(_vm.invoiceData.products, function(
                                    cart,
                                    index
                                  ) {
                                    return [
                                      _vm.invoiceData.products
                                        ? _c(
                                            "div",
                                            {
                                              key: index,
                                              staticClass:
                                                "layout row wrap align-center d-flex my-0 text-md-left text-center"
                                            },
                                            [
                                              _c(
                                                "v-flex",
                                                {
                                                  attrs: {
                                                    xs12: "",
                                                    sm12: "",
                                                    md3: "",
                                                    lg3: "",
                                                    xl3: ""
                                                  }
                                                },
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      alt: "cart Image",
                                                      width: "100",
                                                      src: cart.image
                                                    }
                                                  })
                                                ]
                                              ),
                                              _c(
                                                "v-flex",
                                                {
                                                  attrs: {
                                                    xs12: "",
                                                    sm4: "",
                                                    md3: "",
                                                    lg3: "",
                                                    xl3: ""
                                                  }
                                                },
                                                [
                                                  _c("h6", [
                                                    _vm._v("Product Name")
                                                  ]),
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "mb-0 font-weight-bold"
                                                    },
                                                    [_vm._v(_vm._s(cart.name))]
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "v-flex",
                                                {
                                                  attrs: {
                                                    xs6: "",
                                                    sm4: "",
                                                    md3: "",
                                                    lg3: "",
                                                    xl3: ""
                                                  }
                                                },
                                                [
                                                  _c("h6", [
                                                    _vm._v("Quantity")
                                                  ]),
                                                  _c(
                                                    "p",
                                                    { staticClass: "mb-0" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(cart.quantity)
                                                      )
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "v-flex",
                                                {
                                                  attrs: {
                                                    xs6: "",
                                                    sm4: "",
                                                    md3: "",
                                                    lg3: "",
                                                    xl3: ""
                                                  }
                                                },
                                                [
                                                  _c("h6", [_vm._v("Price")]),
                                                  _c(
                                                    "p",
                                                    { staticClass: "mb-0" },
                                                    [
                                                      _vm._v(
                                                        "$" + _vm._s(cart.price)
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ]
                                  }),
                                  _c("v-divider", { staticClass: "my-4" }),
                                  _c("div", [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex align-center justify-space-between mt-4 mb-4"
                                      },
                                      [
                                        _c("div", [
                                          _c("p", { staticClass: "mb-0" }, [
                                            _vm._v("Subtotal")
                                          ])
                                        ]),
                                        _c("div", [
                                          _c(
                                            "span",
                                            [
                                              _c("emb-currency-sign"),
                                              _vm._v(_vm._s(_vm.itemTotal()))
                                            ],
                                            1
                                          )
                                        ])
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex align-center justify-space-between mb-4"
                                      },
                                      [
                                        _c("p", { staticClass: "mb-0" }, [
                                          _vm._v("Shipping")
                                        ]),
                                        _c(
                                          "span",
                                          [
                                            _c("emb-currency-sign"),
                                            _vm._v(_vm._s(_vm.shipping))
                                          ],
                                          1
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex align-center justify-space-between mb-4"
                                      },
                                      [
                                        _c("p", { staticClass: "mb-0" }, [
                                          _vm._v("Tax(GST)")
                                        ]),
                                        _c(
                                          "span",
                                          [
                                            _c("emb-currency-sign"),
                                            _vm._v(_vm._s(_vm.tax))
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  ]),
                                  _c("v-divider", { staticClass: "my-4" }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex align-center justify-space-between"
                                    },
                                    [
                                      _c("h4", [_vm._v("Total")]),
                                      _c(
                                        "h4",
                                        [
                                          _c("emb-currency-sign"),
                                          _vm._v(_vm._s(_vm.getTotalPrice()))
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ],
                                2
                              )
                            ]),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "layout row wrap pt-6 px-4 text-center"
                              },
                              [
                                _c(
                                  "v-flex",
                                  {
                                    attrs: {
                                      xs12: "",
                                      sm6: "",
                                      md6: "",
                                      lg6: "",
                                      xl6: "",
                                      "text-md-left": ""
                                    }
                                  },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "accent",
                                        attrs: { large: "" }
                                      },
                                      [_vm._v("Download PDF")]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-flex",
                                  {
                                    attrs: {
                                      xs12: "",
                                      sm6: "",
                                      md6: "",
                                      lg6: "",
                                      xl6: "",
                                      "text-md-right": ""
                                    }
                                  },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "accent",
                                        attrs: { large: "", to: "/" }
                                      },
                                      [_vm._v("Go To Home")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }