var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { style: { "background-color": _vm.primaryColor } },
        [
          _c(
            "v-container",
            { attrs: { "grid-list-xl": "" } },
            [
              _c(
                "v-layout",
                {
                  attrs: {
                    row: "",
                    wrap: "",
                    "align-center": "",
                    "justify-space-between": "",
                    "pa-0": "",
                    "ma-0": ""
                  }
                },
                [
                  _c(
                    "v-flex",
                    {
                      attrs: {
                        xs6: "",
                        sm6: "",
                        md4: "",
                        lg3: "",
                        xl3: "",
                        "px-0": ""
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "site-logo d-inline-block" },
                        [
                          _c("router-link", { attrs: { to: "/login" } }, [
                            _c("img", {
                              attrs: {
                                alt: "site-logo",
                                height: "34",
                                src: _vm.appLogo,
                                width: "70%"
                              }
                            })
                          ])
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "emb-signIn-wrap section-gap d-flex justify-center align-center",
          staticStyle: { height: "90vh" }
        },
        [
          _c(
            "div",
            { staticClass: "container py-16 py-md-0" },
            [
              _c(
                "v-layout",
                {
                  attrs: {
                    row: "",
                    wrap: "",
                    "align-center": "",
                    "justify-center": ""
                  }
                },
                [
                  _c(
                    "v-flex",
                    { attrs: { sm12: "", md12: "", lg8: "", xl7: "" } },
                    [
                      _c(
                        "v-layout",
                        {
                          attrs: {
                            row: "",
                            "mx-sm-0": "",
                            "mx-3": "",
                            wrap: "",
                            "align-center": "",
                            "justify-center": ""
                          }
                        },
                        [
                          _c(
                            "v-flex",
                            {
                              attrs: {
                                sm6: "",
                                md7: "",
                                lg6: "",
                                xl6: "",
                                "hidden-sm-and-down": ""
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "form-img sign-in-image" },
                                [
                                  _c("v-img", {
                                    attrs: { src: _vm.loginImage }
                                  })
                                ],
                                1
                              )
                            ]
                          ),
                          _c(
                            "v-flex",
                            {
                              staticStyle: { "z-index": "1" },
                              attrs: { sm10: "", md5: "", lg5: "", xl6: "" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "emb-card sign-in-form form-margin d-block white pa-6"
                                },
                                [
                                  _c(
                                    "h4",
                                    { staticClass: "text-center mb-6" },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "font-weight-light" },
                                        [_vm._v("Welcome to ")]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            color: "rgb(80, 80, 80)"
                                          }
                                        },
                                        [_vm._v(_vm._s(_vm.title))]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "accent mb-3 ma-0",
                                      attrs: {
                                        loading: _vm.isPerformingOperation,
                                        disabled:
                                          _vm.isPerformingOperation ||
                                          _vm.isLoginDisabled,
                                        block: "",
                                        large: ""
                                      },
                                      on: { click: _vm.signIn }
                                    },
                                    [_vm._v("Sign In / Sign Up")]
                                  ),
                                  _c("v-checkbox", {
                                    attrs: { label: "Remember Me" },
                                    model: {
                                      value: _vm.rememberMe,
                                      callback: function($$v) {
                                        _vm.rememberMe = $$v
                                      },
                                      expression: "rememberMe"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c(
        "div",
        { staticClass: "footer" },
        [
          _c("v-container", { staticClass: "pa-12" }, [
            _c("div", { staticClass: "d-flex justify-center mb-6" }, [
              _c("div", [
                _c("h6", [_vm._v("About us")]),
                _c("p", [_vm._v(_vm._s(_vm.aboutUs))])
              ])
            ]),
            _c("hr", { staticClass: "mb-10" }),
            _c("div", { staticClass: "d-flex justify-center justify-sm-end" }, [
              _c("p", [_vm._v(_vm._s(_vm.copyrightText))])
            ])
          ])
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "400" },
          model: {
            value: _vm.isSessionExpired,
            callback: function($$v) {
              _vm.isSessionExpired = $$v
            },
            expression: "isSessionExpired"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-text",
                [
                  _c("v-card-title", [_vm._v("Your session expired!")]),
                  _c("p", { staticClass: "ma-0 pa-0" }, [
                    _vm._v(
                      " Sign in again to keep using the app. You will be redirected to the last page you visited. "
                    )
                  ])
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "d-flex justify-end" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: { click: _vm.restoreSession }
                    },
                    [_vm._v("Sign in again")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error" },
                      on: { click: _vm.cancelSessionRestore }
                    },
                    [_vm._v("Cancel")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            persistent: "",
            "max-width": "300",
            "v-model": _vm.isRestoringSession
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "pa-3" },
            [
              _c(
                "v-card-text",
                { staticClass: "d-flex flex-column align-center" },
                [
                  _c("p", [_vm._v("Restoring session...")]),
                  _c("v-progress-circular", {
                    attrs: { width: "2", indeterminate: "" }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("vue-snotify")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }