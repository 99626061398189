<template>
  <div>
    <Breadcrumbs class="mb-4" :items="breadcrumbs" />
    <div class="center-x" v-if="isLoading">
      <v-skeleton-loader type="text" width="100%" />
    </div>
    <v-card>
      <v-card-title>Market Area Qualifier </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" v-show="!isAdd">
            <v-text-field
              label="Market Area Qualifier Id"
              v-model="currentObject.marketAreaQualifierId"
              disabled
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              label="Market Area Qualifier Description"
              v-model="currentObject.marketAreaQualifierDescription"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              label="Market Area Qualifier"
              v-model="currentObject.marketAreaQualifier"
            />
          </v-col>
          <v-col cols="12" sm="6" v-if="!isAdd">
            <v-text-field
              label="Last Edited By"
              v-model="currentObject.lastEditedBy"
              disabled
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-row>
      <v-col>
        <div class="d-flex justify-end">
          <v-btn color="error" large @click="save">Save</v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import apiClient from "../../../../apiClient";
import { BreadcrumbItem } from "../../../../components/Breadcrumbs/BreadcrumbItem";
import { showDialog } from "../../../../components/Dialogs/NotifyDialog";
import { formsMixin } from "../../../../mixins/formsMixin";
import { StaticRoutes } from "../../../../router/Routes";
export default {
  mixins: [formsMixin],
  name: "X12marketAreaQualifierForm",
  data() {
    return {
      breadcrumbs: [
        new BreadcrumbItem({
          label: "Market Area Qualifier",
          route:
            StaticRoutes.adminPanel.trading.x12Definitions.marketAreaQualifier,
        }),
        new BreadcrumbItem({ label: "Market Area Qualifier Details" }),
      ],
    };
  },
  methods: {
    async getById() {
      try {
        this.isLoading = true;
        this.currentObject =await apiClient.X12("x12marketAreaQualifiers").getById(
          this.id
        );
      } catch (error) {
        showDialog("Could not get data", error);
      } finally {
        this.isLoading = false;
      }
    },
    async save() {
      try {
        this.isLoading = true;
        if (this.isAdd) {
          await apiClient.X12("x12marketAreaQualifiers").create(this.currentObject)
          showDialog("Created Successfully");
        }
        if (this.isEdit) {
          await apiClient.X12("x12marketAreaQualifiers").update(this.id,this.currentObject);
          showDialog("Updated Successfully");
        }
          this.$router.push("/admin-panel/definitions/marketAreaQualifier");
      } catch (error) {
        showDialog("Could not save item", error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>