/* eslint-disable */

const clientId            = process.env.VUE_APP_AUTH_CLIENTID;
const tenantId            = process.env.VUE_APP_AUTH_TENANTID;
const tenantName          = process.env.VUE_APP_AUTH_TENANTNAME;
const tenantUrl           = process.env.VUE_APP_AUTH_TENANTURL;
const signUpSignInPolicy  = process.env.VUE_APP_AUTH_SUSIPOLICY;
const passwordResetPolicy = process.env.VUE_APP_AUTH_FORGOTPOLICY;

const b2cPolicies = {
    names: {
        signUpSignIn: "b2c_1_susi",
        forgotPassword: "b2c_1_reset",
    },

    authorities: {
        signUpSignIn: {
            authority: `https://${tenantName}.b2clogin.com/${tenantUrl}/${signUpSignInPolicy}`,
        },
        forgotPassword: {
            authority: "https://fabrikamb2c.b2clogin.com/fabrikamb2c.onmicrosoft.com/b2c_1_reset",
        },
    },
};

export const msalConfig = {
    auth: {
        clientId: clientId,
        authority: `https://${tenantName}.b2clogin.com/${tenantUrl}/${signUpSignInPolicy}`,
        validateAuthority: false,
        navigateToLoginRequestUrl: false, // TODO Remove?
        //postLogoutRedirectUri: window.location.origin + '/login',
        //postLogoutRedirectUri: 'https://fbb2cdevscus.b2clogin.com/fbb2cdevscus.onmicrosoft.com/B2C_1_Portal_Signup_Signin/oauth2/v2.0/logout?post_logout_redirect_uri=https://portal.lnfdistributors.dev',
    },
};

/**
 * The configuration used to get the access token (bearer token)
 */
export const tokenRequest = {
    scopes: [
        process.env.VUE_APP_AUTH_SCOPE,
    ],
};
