<template>
    <v-card>
        <v-card-text>
            <v-row>
                <v-col cols="12" sm="6">
                    <v-text-field
                        :disabled="isView"
                        label="Address Line 1 *"
                        :rules="requiredRule"
                        v-model="selectedSupplier.deliveryAddressLine1"/>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field
                        :disabled="isView"
                        label="Address Line 2"
                        v-model="selectedSupplier.deliveryAddressLine2"/>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="6">
                    <v-select
                        :disabled="isView"
                        label="City *"
                        :rules="requiredRule"
                        :items="cities"
                        item-text="cityName"
                        item-value="cityId"
                        v-model="selectedSupplier.deliveryCityId"/>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field
                        :disabled="isView"
                        label="Postal Code *"
                        :rules="requiredRule"
                        type="number"
                        v-model="selectedSupplier.deliveryPostalCode"/>
                </v-col>
            </v-row>

            <v-select
                :disabled="isView"
                label="Location"
                v-model="selectedSupplier.deliveryLocation"/>
            
            <v-row>
                <v-col cols="12" sm="6">
                    <v-select
                        :disabled="isView"
                        label="Delivery Method *"
                        :rules="requiredRule"
                        :items="deliveryMethods"
                        item-text="deliveryMethodName"
                        item-value="deliveryMethodId"
                        v-model="selectedSupplier.DeliveryMethodId"/>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field
                        :disabled="isView"
                        label="Fax Number *"
                        :rules="requiredRule"
                        type="number"
                        v-model="selectedSupplier.faxNumber"/>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    props: ['selectedSupplier'],

    data: () => ({
        isEdit: false,
        isView: false,

        requiredRule: [
            v => !!v || 'Required',
        ],
    }),

    computed: {
        ...mapGetters({
            cities: 'catalog/getCities',
            deliveryMethods: 'catalog/getDeliveryMethods'
        }),
    }, // computed

    beforeMount() {
        this.isEdit = this.$route.path.includes('edit');
        this.isView = this.$route.path.includes('view');
    }, // beforeMount

    mounted() {
        this.requestGetCities();
        this.requestGetDeliveryMethods();
    }, // mounted

    methods: {
        ...mapActions({
            requestGetCities: 'catalog/requestGetCities',
            requestGetDeliveryMethods: 'catalog/requestGetDeliveryMethods',
        }),
    }, // methods
}
</script>
