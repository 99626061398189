<template>
    <v-card>
        <v-card-text>
            <v-row>
                <v-col cols="12" sm="6">
                    <v-text-field
                        :disabled="isView"
                        label="Phone number *"
                        :rules="requiredRule"
                        type="number"
                        v-model="selectedSupplier.phoneNumber"/>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field
                        :disabled="isView"
                        label="Reference"
                        :rules="requiredRule"
                        v-model="selectedSupplier.supplierReference"/>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="6">
                    <v-select
                        :disabled="isView"
                        label="Primary Contact *"
                        :rules="requiredRule"
                        :items="people"
                        item-text="fullName"
                        item-value="personId"
                        v-model="selectedSupplier.primaryContactPersonId"/>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-select
                        :disabled="isView"
                        label="Alternate Contact"
                        :items="people"
                        item-text="fullName"
                        item-value="personId"
                        v-model="selectedSupplier.alternateContactPersonId"/>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="6">
                    <v-select
                        :disabled="isView"
                        label="Category *"
                        :rules="requiredRule"
                        :items="supplierCategories"
                        item-text="supplierCategoryName"
                        item-value="supplierCategoryId"
                        v-model="selectedSupplier.supplierCategoryId"/>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field
                        :disabled="isView"
                        label="Website"
                        v-model="selectedSupplier.websiteUrl"/>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    props: ['selectedSupplier'],

    data: () => ({
        isEdit: false,
        isView: false,
        
        requiredRule: [
            v => !!v || 'Required',
        ],
    }),

    computed: {
        ...mapGetters({
            people: 'catalog/getPeople',
            supplierCategories: 'catalog/getSupplierCategories'
        }),
    }, // computed

    beforeMount() {
        this.isEdit = this.$route.path.includes('edit');
        this.isView = this.$route.path.includes('view');
    }, // beforeMount

    mounted() {
        this.requestGetPeople();
        this.requestGetSupplierCategories();
    }, // mounted

    methods: {
        ...mapActions({
            requestGetPeople: 'catalog/requestGetPeople',
            requestGetSupplierCategories: 'catalog/requestGetSupplierCategories',
        }),
    }, // methods
}
</script>
