var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-list-item",
        { staticClass: "mb-6" },
        [
          _c(
            "v-list-item-avatar",
            {
              attrs: { size: "70" },
              on: {
                click: function($event) {
                  _vm.isChoosingImage = true
                }
              }
            },
            [
              _c("avatar", {
                attrs: { fullname: _vm.person.fullName, size: 70 }
              })
            ],
            1
          ),
          _c(
            "v-list-item-content",
            [
              _c("v-list-item-title", { staticClass: "text-h5 mb-2" }, [
                _vm._v(
                  "Hi, " +
                    _vm._s(_vm.person.firstName) +
                    " " +
                    _vm._s(_vm.person.lastName)
                )
              ]),
              _c("v-list-item-subtitle", { staticClass: "text-xl" }, [
                _vm._v(_vm._s(_vm.person.emailAddress))
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card",
        [
          _c(
            "v-tabs",
            {
              attrs: {
                vertical: _vm.verticalTabs,
                centered: !_vm.verticalTabs,
                "center-active": !_vm.verticalTabs
              }
            },
            [
              _c(
                "v-tab",
                {
                  staticClass:
                    "d-flex flex-column flex-md-row justify-md-start justify-center"
                },
                [
                  _c("v-icon", { attrs: { left: _vm.verticalTabs } }, [
                    _vm._v("account_circle")
                  ]),
                  _vm._v(" Profile ")
                ],
                1
              ),
              _c(
                "v-tab",
                {
                  staticClass:
                    "d-flex flex-column flex-md-row justify-md-start justify-center"
                },
                [
                  _c("v-icon", { attrs: { left: _vm.verticalTabs } }, [
                    _vm._v("location_on")
                  ]),
                  _vm._v(" Collaboration ")
                ],
                1
              ),
              _c(
                "v-tab",
                {
                  staticClass:
                    "d-flex flex-column flex-md-row justify-md-start justify-center"
                },
                [
                  _c("v-icon", { attrs: { left: _vm.verticalTabs } }, [
                    _vm._v("credit_card")
                  ]),
                  _vm._v(" Settings ")
                ],
                1
              ),
              _c("v-tab-item", [_c("v-card", [_c("profile")], 1)], 1),
              _c("v-tab-item", [_c("v-card", [_c("collaboration")], 1)], 1),
              _c("v-tab-item", [_c("v-card", [_c("account-setting")], 1)], 1)
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          staticClass: "pa-4",
          attrs: { "max-width": "400px" },
          model: {
            value: _vm.isChoosingImage,
            callback: function($$v) {
              _vm.isChoosingImage = $$v
            },
            expression: "isChoosingImage"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-text",
                [
                  _c("h5", { staticClass: "text-center pt-6" }, [
                    _vm._v("Choose an image")
                  ]),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "d-flex flex-column align-center" },
                        [
                          _c("p", [_vm._v("From Camera")]),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "primary",
                                dark: "",
                                large: "",
                                fab: ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.chooseImage("imageCameraInput")
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("camera_alt")])],
                            1
                          ),
                          _c("input", {
                            staticStyle: { display: "none" },
                            attrs: {
                              id: "imageCameraInput",
                              type: "file",
                              accept: "image/*",
                              capture: "camera"
                            },
                            on: { change: _vm.onImageSelected }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "d-flex flex-column align-center" },
                        [
                          _c("p", [_vm._v("From Gallery")]),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "primary",
                                dark: "",
                                large: "",
                                fab: ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.chooseImage("imageGalleryInput")
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("collections")])],
                            1
                          ),
                          _c("input", {
                            staticStyle: { display: "none" },
                            attrs: {
                              id: "imageGalleryInput",
                              type: "file",
                              accept: "image/*"
                            },
                            on: { change: _vm.onImageSelected }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }