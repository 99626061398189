<template>
    <div class="table-responsive">
        <v-data-table
            :headers="headers"
            :items="paginatedList.items"
            :server-items-length="paginatedList.totalCount"
            :options.sync="listViewOptions"
            :loading="isLoading"
            :footer-props="{ 'items-per-page-options': [10, 20, 30, 40, 50] }">

            <template v-slot:[`item.orderDate`]="{ item }">
                {{getOrderDate(item)}}
            </template>

            <template v-slot:[`item.expectedDeliveryDate`]="{ item }">
                {{getOrderExpectedDeliveryDate(item)}}
            </template>

            <template v-slot:[`item.lastEditedDate`]="{ item }">
                {{getOrderLastEditedDate(item)}}
            </template>

            <template v-slot:[`item.lastEditedBy`]="{ item }">
                {{getLastEditedByPerson(item)}}
            </template>

            <template v-slot:[`item.status`]="{ item }">
                <span v-if="item.isOrderSubmitted" style="color: green">Submitted</span>
                <span v-else style="color: gray">Pending</span>
            </template>

            <template v-slot:[`item.action`]="{ item }">
                <Menu :menuItems="rowMenuItems" :menuItemContext="item">
                    <v-btn small icon class="primary--text">
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                </Menu>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { utils } from '../../../mixins/index';
import { MenuItem } from "../../../components/Menu/MenuItem";
import { tableProps } from "../../../mixins/tableProps";

export default {
    mixins: [ utils, tableProps ],

    data() {
        return {
            rowMenuItems: [
                    new MenuItem({
                    icon: "visibility",
                    label: "View",
                    action: (item) => {
                        this.$router.push('/admin-panel/order-view/' + item.orderId);
                    },
                }),
            ],
            headers: [
                {
                    text: "Order #",
                    sortable: false,
                    value: "orderId",
                },
                {
                    text: "Account",
                    sortable: false,
                    value: "customer.customerName",
                },
                {
                    text: "Created Date",
                    sortable: false,
                    value: "orderDate",
                },
                {
                    text: "Expected Delivery Date",
                    sortable: false,
                    value: "expectedDeliveryDate",
                },
                {
                    text: "Last Edited Date",
                    sortable: false,
                    value: "lastEditedDate",
                },
                {
                    text: "Last Edited By",
                    sortable: false,
                    value: "lastEditedBy",
                },
                {
                    text: "Status",
                    value: "status",
                },
                {
                    text: '',
                    sortable: false,
                    value: "action",
                },
            ],

            peopleById: [], // to show lastEditedBy in order table
        }
    }, // data

    mounted() {
        this.listViewOptions.page = this.searchParams.pageNumber;
        this.listViewOptions.itemsPerPage = this.searchParams.pageSize;
    }, // mounted

    methods: {
        ...mapActions({
            getPerson: 'people/getById',
        }),
        getOrderDate(order) {
            if (!order || !order.orderDate) {
                return '';
            }

            let dateUtc = order.orderDate.toString();
            let formattedDate = utils.methods.getFormattedDateUtcShortForOrderDate(dateUtc);
            return formattedDate;
        },

        getOrderExpectedDeliveryDate(order) {
            if (!order || !order.expectedDeliveryDate) {
                return '';
            }

            let dateUtc = order.expectedDeliveryDate.toString();
            let formattedDate = utils.methods.getFormattedDateUtcShortForOrderDate(dateUtc);
            return formattedDate;
        },

        getOrderLastEditedDate(order) {
            if (!order || !order.lastEditedWhen) {
                return '';
            }

            let dateUtc = order.lastEditedWhen.toString();
            let formattedDate = utils.methods.getFormattedDateUtcShortForOrderDate(dateUtc);
            return formattedDate;
        },

        getLastEditedByPerson(order) {
            if (!this.peopleById)
                return '--------------------';

            let person = this.peopleById.find(person => person.personId == order.lastEditedBy);

            if (!person)
                return '--------------------';

            return person.fullName;
        },
    }, // methods

    watch: {
        listViewOptions: {
            handler() {
                this.searchParams.pageNumber = this.listViewOptions.page;
                this.searchParams.pageSize = this.listViewOptions.itemsPerPage;

                let sortField = this.listViewOptions.sortBy[0];
                let sortDesc = this.listViewOptions.sortDesc[0];

                if (sortField) {
                    this.searchParams.sortField = sortField;
                    this.searchParams.sortDirection = sortDesc? 'DESC' : 'ASC';
                }

                this.$emit('search-params-changed', this.searchParams);
            },
        },

        paginatedList() {
            const promises = this.paginatedList.items.map(order => this.getPerson(order.lastEditedBy));

            Promise.all(promises).then(result => {
                this.peopleById = result.map(res => res.data);
            });
        },
    }, // watch
}
</script>
