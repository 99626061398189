var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "order-history-wrap emb-card pa-4" },
    [
      _c("h4", { staticClass: "mb-4" }, [_vm._v("Order History")]),
      _c("v-data-table", {
        attrs: {
          headers: _vm.headers,
          items: _vm.tableData,
          "hide-default-footer": ""
        },
        scopedSlots: _vm._u([
          {
            key: "item.action",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "a",
                  { attrs: { href: "javascript:void(0)" } },
                  [
                    _c("v-icon", { staticClass: "accent--text" }, [
                      _vm._v("remove_red_eye")
                    ])
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }