var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-card-text",
        [
          _c(
            "v-expansion-panels",
            {
              staticClass: "mb-4",
              attrs: { focusable: "", multiple: "", value: [0, 1, 2, 3] }
            },
            [
              _c(
                "v-expansion-panel",
                [
                  _c("v-expansion-panel-header", [_vm._v("Basic Information")]),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c("v-select", {
                        attrs: {
                          disabled: _vm.isView,
                          label: "Name *",
                          items: _vm.suppliers,
                          "item-text": "supplierName",
                          "item-value": "supplierId",
                          rules: _vm.requiredRule
                        },
                        on: {
                          change: function($event) {
                            _vm.selectedSupplier = _vm.suppliers.find(function(
                              s
                            ) {
                              return s.supplierId == _vm.product.supplierId
                            })
                          }
                        },
                        model: {
                          value: _vm.product.supplierId,
                          callback: function($$v) {
                            _vm.$set(_vm.product, "supplierId", _vm._n($$v))
                          },
                          expression: "product.supplierId"
                        }
                      }),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "6" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  disabled: "",
                                  label: "Phone number",
                                  type: "number"
                                },
                                model: {
                                  value: _vm.selectedSupplier.phoneNumber,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.selectedSupplier,
                                      "phoneNumber",
                                      $$v
                                    )
                                  },
                                  expression: "selectedSupplier.phoneNumber"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "6" } },
                            [
                              _c("v-text-field", {
                                attrs: { disabled: "", label: "Reference" },
                                model: {
                                  value: _vm.selectedSupplier.supplierReference,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.selectedSupplier,
                                      "supplierReference",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "selectedSupplier.supplierReference"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "6" } },
                            [
                              !_vm.selectedSupplier.primaryContactPerson
                                ? _c("v-select", {
                                    attrs: {
                                      disabled: "",
                                      label: "Primary Contact"
                                    }
                                  })
                                : _vm._e(),
                              _vm.selectedSupplier.primaryContactPerson
                                ? _c("v-select", {
                                    attrs: {
                                      disabled: "",
                                      label: "Primary Contact"
                                    },
                                    model: {
                                      value:
                                        _vm.selectedSupplier
                                          .primaryContactPerson.fullName,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.selectedSupplier
                                            .primaryContactPerson,
                                          "fullName",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "selectedSupplier.primaryContactPerson.fullName"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "6" } },
                            [
                              !_vm.selectedSupplier.alternateContactPerson
                                ? _c("v-select", {
                                    attrs: {
                                      disabled: "",
                                      label: "Alternate Contact"
                                    }
                                  })
                                : _vm._e(),
                              _vm.selectedSupplier.alternateContactPerson
                                ? _c("v-select", {
                                    attrs: {
                                      disabled: "",
                                      label: "Alternate Contact"
                                    },
                                    model: {
                                      value:
                                        _vm.selectedSupplier
                                          .alternateContactPerson.fullName,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.selectedSupplier
                                            .alternateContactPerson,
                                          "fullName",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "selectedSupplier.alternateContactPerson.fullName"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "6" } },
                            [
                              _c("v-select", {
                                attrs: { disabled: "", label: "Category" },
                                model: {
                                  value: _vm.selectedSupplier.supplierCategory,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.selectedSupplier,
                                      "supplierCategory",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "selectedSupplier.supplierCategory"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "6" } },
                            [
                              _c("v-text-field", {
                                attrs: { disabled: "", label: "Website" },
                                model: {
                                  value: _vm.selectedSupplier.websiteUrl,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.selectedSupplier,
                                      "websiteUrl",
                                      $$v
                                    )
                                  },
                                  expression: "selectedSupplier.websiteUrl"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-expansion-panel",
                { staticClass: "d-none" },
                [
                  _c("v-expansion-panel-header", [_vm._v("Bank Account")]),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "6" } },
                            [
                              _c("v-text-field", {
                                attrs: { disabled: "", label: "Branch" },
                                model: {
                                  value: _vm.selectedSupplier.bankAccountBranch,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.selectedSupplier,
                                      "bankAccountBranch",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "selectedSupplier.bankAccountBranch"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "6" } },
                            [
                              _c("v-text-field", {
                                attrs: { disabled: "", label: "Code" },
                                model: {
                                  value: _vm.selectedSupplier.bankAccountCode,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.selectedSupplier,
                                      "bankAccountCode",
                                      $$v
                                    )
                                  },
                                  expression: "selectedSupplier.bankAccountCode"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "6" } },
                            [
                              _c("v-text-field", {
                                attrs: { disabled: "", label: "Name" },
                                model: {
                                  value: _vm.selectedSupplier.bankAccountName,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.selectedSupplier,
                                      "bankAccountName",
                                      $$v
                                    )
                                  },
                                  expression: "selectedSupplier.bankAccountName"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "6" } },
                            [
                              _c("v-text-field", {
                                attrs: { disabled: "", label: "Number" },
                                model: {
                                  value: _vm.selectedSupplier.bankAccountNumber,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.selectedSupplier,
                                      "bankAccountNumber",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "selectedSupplier.bankAccountNumber"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("v-text-field", {
                        attrs: { disabled: "", label: "International Code" },
                        model: {
                          value: _vm.selectedSupplier.bankInternationalCode,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.selectedSupplier,
                              "bankInternationalCode",
                              $$v
                            )
                          },
                          expression: "selectedSupplier.bankInternationalCode"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-expansion-panel",
                [
                  _c("v-expansion-panel-header", [_vm._v("Postal Address")]),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "6" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  disabled: "",
                                  label: "Address Line 1"
                                },
                                model: {
                                  value:
                                    _vm.selectedSupplier.postalAddressLine1,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.selectedSupplier,
                                      "postalAddressLine1",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "selectedSupplier.postalAddressLine1"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "6" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  disabled: "",
                                  label: "Address Line 2"
                                },
                                model: {
                                  value: _vm.selectedSupplier.postalAddressLin2,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.selectedSupplier,
                                      "postalAddressLin2",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "selectedSupplier.postalAddressLin2"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "6" } },
                            [
                              _c("v-select", {
                                attrs: { disabled: "", label: "City" },
                                model: {
                                  value: _vm.selectedSupplier.postalCity,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.selectedSupplier,
                                      "postalCity",
                                      $$v
                                    )
                                  },
                                  expression: "selectedSupplier.postalCity"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "6" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  disabled: "",
                                  label: "Postal Code",
                                  type: "number"
                                },
                                model: {
                                  value: _vm.selectedSupplier.postalPostalCode,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.selectedSupplier,
                                      "postalPostalCode",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "selectedSupplier.postalPostalCode"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-expansion-panel",
                [
                  _c("v-expansion-panel-header", [_vm._v("Delivery")]),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "6" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  disabled: "",
                                  label: "Address Line 1"
                                },
                                model: {
                                  value:
                                    _vm.selectedSupplier.deliveryAddressLine1,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.selectedSupplier,
                                      "deliveryAddressLine1",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "selectedSupplier.deliveryAddressLine1"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "6" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  disabled: "",
                                  label: "Address Line 2"
                                },
                                model: {
                                  value:
                                    _vm.selectedSupplier.deliveryAddressLine2,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.selectedSupplier,
                                      "deliveryAddressLine2",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "selectedSupplier.deliveryAddressLine2"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "6" } },
                            [
                              _c("v-select", {
                                attrs: { disabled: "", label: "City" },
                                model: {
                                  value: _vm.selectedSupplier.deliveryCity,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.selectedSupplier,
                                      "deliveryCity",
                                      $$v
                                    )
                                  },
                                  expression: "selectedSupplier.deliveryCity"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "6" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  disabled: "",
                                  label: "Postal Code",
                                  type: "number"
                                },
                                model: {
                                  value:
                                    _vm.selectedSupplier.deliveryPostalCode,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.selectedSupplier,
                                      "deliveryPostalCode",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "selectedSupplier.deliveryPostalCode"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("v-select", {
                        attrs: { disabled: "", label: "Location" },
                        model: {
                          value: _vm.selectedSupplier.deliveryLocation,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.selectedSupplier,
                              "deliveryLocation",
                              $$v
                            )
                          },
                          expression: "selectedSupplier.deliveryLocation"
                        }
                      }),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "6" } },
                            [
                              !_vm.selectedSupplier.deliveryMethod
                                ? _c("v-select", {
                                    attrs: {
                                      disabled: "",
                                      label: "Delivery Method"
                                    }
                                  })
                                : _vm._e(),
                              _vm.selectedSupplier.deliveryMethod
                                ? _c("v-select", {
                                    attrs: {
                                      disabled: "",
                                      label: "Delivery Method"
                                    },
                                    model: {
                                      value:
                                        _vm.selectedSupplier.deliveryMethod
                                          .deliveryMethodName,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.selectedSupplier.deliveryMethod,
                                          "deliveryMethodName",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "selectedSupplier.deliveryMethod.deliveryMethodName"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "6" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  disabled: "",
                                  label: "Fax Number",
                                  type: "number"
                                },
                                model: {
                                  value: _vm.selectedSupplier.faxNumber,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.selectedSupplier,
                                      "faxNumber",
                                      $$v
                                    )
                                  },
                                  expression: "selectedSupplier.faxNumber"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }