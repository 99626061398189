var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$route.query.type == "address" ||
    _vm.$route.query.type == "ship-address"
    ? _c("div", [
        _c(
          "div",
          { staticClass: "edit-address-wrap emb-card pa-4" },
          [
            _vm.$route.query.type == "ship-address"
              ? _c("h4", [_vm._v("Edit shipping Information")])
              : _c("h4", [_vm._v("Edit Billing Information")]),
            _c(
              "v-form",
              {
                ref: "form",
                model: {
                  value: _vm.valid,
                  callback: function($$v) {
                    _vm.valid = $$v
                  },
                  expression: "valid"
                }
              },
              [
                _c(
                  "v-layout",
                  { attrs: { row: "", wrap: "" } },
                  [
                    _c(
                      "v-flex",
                      {
                        attrs: {
                          xs12: "",
                          sm12: "",
                          md12: "",
                          lg6: "",
                          xl6: ""
                        }
                      },
                      [
                        _c(
                          "v-layout",
                          { attrs: { row: "", wrap: "" } },
                          [
                            _c(
                              "v-flex",
                              {
                                attrs: {
                                  xs12: "",
                                  sm12: "",
                                  md12: "",
                                  lg12: "",
                                  xl12: "",
                                  "py-2": ""
                                }
                              },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    label: "Address",
                                    rules: _vm.inputRules.basictextRules
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-flex",
                              {
                                attrs: {
                                  xs12: "",
                                  sm12: "",
                                  md12: "",
                                  lg12: "",
                                  xl12: "",
                                  "py-2": ""
                                }
                              },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    label: "Buidling Name",
                                    rules: _vm.inputRules.basictextRules
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-flex",
                              {
                                attrs: {
                                  xs12: "",
                                  sm12: "",
                                  md12: "",
                                  lg12: "",
                                  xl12: "",
                                  "py-2": ""
                                }
                              },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    label: "Street no",
                                    rules: _vm.inputRules.basictextRules
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-flex",
                              {
                                attrs: {
                                  xs12: "",
                                  sm12: "",
                                  md12: "",
                                  lg12: "",
                                  xl12: "",
                                  "py-2": ""
                                }
                              },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    label: "State",
                                    rules: _vm.inputRules.basictextRules
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-flex",
                              {
                                attrs: {
                                  xs12: "",
                                  sm12: "",
                                  md12: "",
                                  lg12: "",
                                  xl12: "",
                                  "py-2": ""
                                }
                              },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    label: "Zip Code/Pin Code ",
                                    rules: _vm.inputRules.basictextRules
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-flex",
                              {
                                attrs: {
                                  xs12: "",
                                  sm12: "",
                                  md12: "",
                                  lg12: "",
                                  xl12: "",
                                  "py-2": ""
                                }
                              },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    label: "Country",
                                    rules: _vm.inputRules.basictextRules
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-flex",
                              {
                                attrs: {
                                  xs12: "",
                                  sm12: "",
                                  md12: "",
                                  lg12: "",
                                  xl12: "",
                                  "py-2": ""
                                }
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "accent mx-0 mb-4",
                                    attrs: { type: "billing" },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        $event.preventDefault()
                                        return _vm.saveDetails($event)
                                      }
                                    }
                                  },
                                  [_vm._v("Save")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("v-flex", {
                      attrs: {
                        xs12: "",
                        sm12: "",
                        md6: "",
                        lg6: "",
                        xl6: "",
                        "reciept-bg": "",
                        "edit-profile": "",
                        "hidden-md-and-down": ""
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }