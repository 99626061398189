var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("AdminPanelHeader", {
        attrs: {
          icon: "mdi-file-account",
          title: "Subscription FileType Associations",
          actions: _vm.headerActions
        },
        on: { search: _vm.onSearch }
      }),
      _c("X12subscriptionFileTypeAssociationTable", {
        attrs: {
          paginatedList: _vm.currentEntity,
          isLoading: _vm.isLoading,
          searchParams: _vm.paginatedQueryParams
        },
        on: { onDelete: _vm.onDelete }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }