<template>
    <div class="shop-content-wrap">

        <!-- search bar -->
        <v-card class="pa-4 mb-4">
            <v-row no-gutters>
                <v-col cols="12" sm="3" class="d-flex align-center justify-center justify-sm-start">
                    <v-icon class="mr-1">people</v-icon>
                    <h5 class="ma-0">Suppliers</h5>
                </v-col>
                <v-col cols="12" sm="6" class="d-flex justify-center align-center">
                    <v-text-field class="mr-4" label="Search Suppliers" v-model="searchValue" @change="executeSearch"/>
                    <v-btn color="primary" @click="executeSearch">Search</v-btn>
                </v-col>
            </v-row>
        </v-card><!-- search bar -->

        <supplier-items-list-view
            :isLoading="isLoading"
            :paginatedList="suppliersPaginatedList"
            :searchParams="searchParams"
            v-on:search-params-changed="onSearchParamsChanged">
        </supplier-items-list-view>

    </div>
</template>

<script>
import SupplierItemsListView from './SupplierItemsListView.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
    data() {
        return {
            isLoading: true,
            searchValue: undefined,
        };
    }, // data

    computed: {
        ...mapGetters({
            suppliersPaginatedList: 'suppliers/getSuppliers',
            searchParams: 'suppliers/getSearchParams',
        }),
    }, // computed

    mounted() {
        let resetSearchParams = this.$route.query.resetSearchParams;
        if (resetSearchParams && resetSearchParams.toString() == true.toString()) this.resetSearchParams();

        this.searchValue = this.searchParams.searchValue;
        this.getSuppliers().then(() => this.isLoading = false);
    }, // mounted

    methods: {
        ...mapMutations({
            setSearchValue: 'suppliers/SET_SEARCH_PARAM_SEARCH_VALUE',
            setSearchParams: 'suppliers/SET_SEARCH_PARAMS',
            resetSearchParams: 'suppliers/RESET_SEARCH_PARAMS',
        }),

        ...mapActions({
            getSuppliers: 'suppliers/get',
        }),

        executeSearch() {
            this.isLoading = true;
            this.setSearchValue(this.searchValue);

            this.getSuppliers().then(() => this.isLoading = false);
        },

        onSearchParamsChanged(searchParams) {
            this.isLoading = true;
            this.setSearchParams(searchParams);
            this.setSearchValue(this.searchValue);

            this.getSuppliers().then(() => this.isLoading = false);
        },
    }, // methods

    components: {
        SupplierItemsListView,
    }, // components
}
</script>
