export default {
	"home": "Home",
	"homeOne": "Home One",
	"homeTwo": "Home Two",
	"homeThree": "Home Three",
	"userProfile": "My Account",
	"myaccount": "My Account",
	"shop": "Shop",
	"productDetails": "Product Details",
	"cart": "Cart",
	"checkout": "Checkout",
	"men": "Men",
	"payment": "Payment",
	"fashion": "Fashion",
	"gadgets": "Gadgets",
	"accessories": "Accessories",
	"categories": "Categories",
	"wine": "Wine",
	"beer": "Beer",
	"spirits": "Spirits",
	"mixer": "Mixer",
	"non-alcoholic": "Non alcoholic",
	"anheuser-busch": "Anheuser-Busch",
	"seltzer": "Seltzer",
	"mead" : "Mead",
	"merchandise": "Merchandise",
	"mysalesteam": "My Sales Team",
	"myorders": "My Orders",
	"mytopproducts": "My Top Products",
	"jean": "Red Wines",
	"jackets": "White Wines",
	"shirt": "Sparkling Wines",
	"tShirt": "Rose",
	"women": "Women",
	"dress": "Dress",
	"dresses": "Dresses",
	"headphone": "Headphone",
	"smartphone": "Smartphone",
	"watch": "Watch",
	"speaker": "Speaker",
	"laptopAccessories": "Laptop Accessories",
	"belts": "Belts",
	"jewellery": "Jewellery",
	"purse": "Purse",
	"pages": "Pages",
	"about": "About",
	"termAndCondition": "Terms and Conditions",
	"privacyPolicy": "Privacy Policy",
	"blogDetail": "Blog Detail",
	"faq": "FAQ",
	"Page": "Page",
	"404Page": '404 Page',
	"session": "Session",
	"signIn": "Sign In",
	"register": "Register",
	"forgotPassword": "Forgot Password",
	"thankYou": "Thank You",
	"contactUs": "Contact Us",
	"social": "Social",
	"facebook": "Facebook",
	"twitter": "Twitter",
	"youtube": "Youtube",
	"instagram": "Instagram",
	"reports": "Reports",
	"invoices": "Invoices",
	"profile": "Profile",
	"products": "Products",
	"productAdd": "Product Add",
	"goToSite": "Go To Site",
	"adminPanel": "Admin Panel",
	"edit": "Edit",
	"accounts": "Accounts",
	"accountAdd": "Account Add"
}