/* eslint-disable no-console */
/* eslint-disable no-unused-vars */

import api from "@/api";
import Vue from 'vue';

export default {
    namespaced: true,
    
    state: {
        moduleName: 'account',
        team: {}, // people paginated list
        searchParams: {
            pageSize: 10,
            pageNumber: 1,
            searchValue: undefined, // searchValue can either be fullName or emailAddress
            sortField: 'fullName',
            sortDirection: 'ASC',
        },
    }, // state

    getters: {
        getTeam: state => state.team,
        getSearchParams: state => state.searchParams,
    }, // getters

    mutations: {
        SET_TEAM(state, data) {
          state.team = data.data;
        },
        SET_SEARCH_PARAM_PAGE_SIZE(state, pageSize) {
            state.searchParams.pageSize = pageSize;
        },
        SET_SEARCH_PARAM_PAGE_NUMBER(state, pageNumber) {
            state.searchParams.pageNumber = pageNumber;
        },
        SET_SEARCH_PARAM_SEARCH_VALUE(state, searchValue) {
            state.searchParams.searchValue = searchValue;
        },
        SET_SEARCH_PARAM_SORT_FIELD(state, sortField) {
            state.searchParams.sortField = sortField;
        },
        SET_SEARCH_PARAM_SORT_DIRECTION(state, sortDirection) {
            state.searchParams.sortDirection = sortDirection;
        },
    }, // mutations

    actions: {
        get({ state, commit }, customerInternalId) {
          return api.get(`${state.moduleName}/team/${customerInternalId}`, { params: state.searchParams })
          .then(result => commit("SET_TEAM", result));
        },
    }, // actions
}
