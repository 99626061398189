/*eslint-disable*/

import api from '@/api';
import Vue from 'vue';

export default {
    namespaced: true,

    state: {
        moduleName: 'people',
        people: [],
        currentPerson: undefined,
        searchParams: {
            pageSize: 10,
            pageNumber: 1,
            sortField: 'fullName',
            sortDirection: 'ASC',
            searchValue: undefined, // searchValue can either be fullName or emailAddress
            showAllPeople: false,
        },
    }, // state

    getters: {
        getPeople: state => state.people,
        getCurrentPerson: state => state.currentPerson,
        getSearchParams: state => state.searchParams,
    }, // getters

    mutations: {
        SET_PEOPLE(state, data) {
            state.people = data.data;
            Vue.set(state.people, 'items', data.data.items);
        },
        SET_CURRENT_PERSON(state, person) {
            state.currentPerson = person;
        },
        SET_SEARCH_PARAM_PAGE_SIZE(state, pageSize) {
            state.searchParams.pageSize = pageSize;
        },
        SET_SEARCH_PARAM_PAGE_NUMBER(state, pageNumber) {
            state.searchParams.pageNumber = pageNumber;
        },
        SET_SEARCH_PARAM_SEARCH_VALUE(state, searchValue) {
            state.searchParams.searchValue = searchValue;
        },
        SET_SEARCH_PARAM_SORT_FIELD(state, sortField) {
            state.searchParams.sortField = sortField;
        },
        SET_SEARCH_PARAM_SORT_DIRECTION(state, sortDirection) {
            state.searchParams.sortDirection = sortDirection;
        },
        SET_SEARCH_PARAMS(state, searchParams) {
            state.searchParams = searchParams;
        },
        RESET_SEARCH_PARAMS(state) {
            state.searchParams.pageSize = 10;
            state.searchParams.pageNumber = 1;
            state.searchParams.sortField = 'fullName';
            state.searchParams.sortDirection = 'ASC';
            state.searchParams.searchValue = undefined;
        },
    }, // mutations

    actions: {
        get({ state, commit }) {
            return api.get(`${state.moduleName}`, { params: state.searchParams })
            .then(result => commit('SET_PEOPLE', result));
        },

        getById({ state, commit }, personId) {
            return api.get(`${state.moduleName}/${personId}`);
        },

        getByPersonId({ state, commit }, personId) {
            return api.get(`${state.moduleName}/${personId}`)
            .then(result => commit('SET_CURRENT_PERSON', result.data));
        },

        getByLocation({ state, commit }) {
            return api.get(`${state.moduleName}/location`, { params: state.searchParams })
            .then(result => commit('SET_PEOPLE', result));
        },

        post({ state, commit }, person) {
            return api.post(`${state.moduleName}`, person);
        },

        update({ state, commit }, person) {
            return api.put(`${state.moduleName}/${person.personId}`, person);
        },
    }, // actions
}
