<template>
  <div class="table-responsive" v-if="paginatedList">
    <v-data-table
      :headers="headers"
      :items="paginatedList.items"
      :server-items-length="paginatedList.totalCount"
      :options.sync="listViewOptions"
      :loading="isLoading"
      :footer-props="{ 'items-per-page-options': [10, 20, 30, 40, 50] }"
    >
      <template v-slot:[`item.action`]="{ item }">
        <Menu :menuItems="rowMenuItems" :menuItemContext="item">
          <v-btn icon>
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </Menu>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { MenuItem } from "./Menu/MenuItem";
import { DynamicRoutes } from "../router/Routes";
import { tableProps } from "../mixins/tableProps";

export default {
  mixins: [tableProps],
  props: ["paginatedList", "isLoading", "searchParams"],

  data: (thiz) => ({
    headers: [
      {
        text: "Employee ID",
        sortable: true,
        value: "employeeId",
      },
      {
        text: "Employee Code",
        sortable: true,
        value: "employeeCode",
      },
      {
        text: "Name",
        sortable: true,
        value: "fullName",
      },
      {
        text: "Position Title",
        sortable: true,
        value: "positionTitle",
      },
      {
        text: "Route ID",
        sortable: true,
        value: "routeId",
      },
      {
        sortable: false,
        value: "action",
      },
    ],
    rowMenuItems: [
      new MenuItem({
        icon: "edit",
        label: "Edit",
        action: (person) => {
          const route = DynamicRoutes.adminPanel.personDetails(person.personId);
          thiz.$router.push(route);
        },
      }),
    ],
  }), // data

  mounted() {
    this.listViewOptions.page = this.searchParams.pageNumber;
    this.listViewOptions.itemsPerPage = this.searchParams.pageSize;
  }, // mounted

  watch: {
    listViewOptions: {
      handler() {
        this.searchParams.pageNumber = this.listViewOptions.page;
        this.searchParams.pageSize = this.listViewOptions.itemsPerPage;

        let sortField = this.listViewOptions.sortBy[0];
        let sortDesc = this.listViewOptions.sortDesc[0];

        if (sortField) {
          this.searchParams.sortField = sortField;
          this.searchParams.sortDirection = sortDesc ? "DESC" : "ASC";
        }

        this.$emit("search-params-changed", this.searchParams);
      },
    },
  }, // watch
};
</script>
