import vue from 'vue'

import AdminPanelHeader from './AdminPanelHeader/AdminPanelHeader.vue'
vue.component('AdminPanelHeader', AdminPanelHeader)

import Breadcrumbs from './Breadcrumbs/Breadcrumbs.vue'
vue.component('Breadcrumbs', Breadcrumbs)

import CustomersTable from './CustomersTable.vue'
vue.component('CustomersTable', CustomersTable)

import Menu from './Menu/Menu.vue'
vue.component('Menu', Menu)

import PeopleTable from './PeopleTable.vue'
vue.component('PeopleTable', PeopleTable)

import PersonCustomersTab from '../views/AdminPanel/People/Tabs/PersonCustomersTab.vue'
vue.component('PersonCustomersTab', PersonCustomersTab)

import PersonPermissionsTab from '../views/AdminPanel/People/Tabs/PersonPermissionsTab.vue'
vue.component('PersonPermissionsTab', PersonPermissionsTab)

import PersonProfileTab from '../views/AdminPanel/People/Tabs/PersonProfileTab.vue'
vue.component('PersonProfileTab', PersonProfileTab)

import PersonRouteForm from '../views/AdminPanel/PeopleRoutes/PersonRouteForm.vue'
vue.component('PersonRouteForm', PersonRouteForm)

import PeopleRoutesTable from '../views/AdminPanel/PeopleRoutes/PersonRoutesTable.vue'
vue.component('PeopleRoutesTable', PeopleRoutesTable)

import TradingMessageForm from '../views/AdminPanel/TradingMessages/TradingMessageForm.vue'
vue.component('TradingMessageForm', TradingMessageForm)

import TradingMessage from '../views/AdminPanel/TradingMessages/TradingMessage.vue'
vue.component('TradingMessage', TradingMessage)

import TradingPartnerTable from '../views/AdminPanel/TradingPartners/TradingPartnerTable.vue'
vue.component('TradingPartnerTable', TradingPartnerTable)

import TradingPartnerForm  from '../views/AdminPanel/TradingPartners/TradingPartnerForm.vue'
vue.component('TradingPartnerForm', TradingPartnerForm)

import X12ConfigData  from '../views/AdminPanel/TradingPartners/Tabs/X12tradingPartnerConfigData/X12ConfigData.vue'
vue.component('X12ConfigData', X12ConfigData)

import X12ConfigDataTable  from '../views/AdminPanel/TradingPartners/Tabs/X12tradingPartnerConfigData/X12ConfigDataTable.vue'
vue.component('X12ConfigDataTable', X12ConfigDataTable)

import TradingSubscriptionsPage  from '../views/AdminPanel/TradingSubscriptions/TradingSubscriptionsPage.vue'
vue.component('TradingSubscriptionsPage', TradingSubscriptionsPage)

import Log from '../views/AdminPanel/TradingMessages/Tabs/Logs/Log.vue'
vue.component('Log', Log)

import LogTable from '../views/AdminPanel/TradingMessages/Tabs/Logs/LogTable.vue'
vue.component('LogTable', LogTable)

import TradingSubscriptionsTable from '../views/AdminPanel/TradingSubscriptions/TradingSubscriptionsTable.vue'
vue.component('TradingSubscriptionsTable', TradingSubscriptionsTable)


import TradingSubscriptionsMappings from '../views/AdminPanel/TradingSubscriptions/tabs/UnitMappings.vue'
vue.component('TradingSubscriptionsMappings', TradingSubscriptionsMappings)

import X12FileTypesVersionsTable from '../views/AdminPanel/X12Definitions/X12FileTypeVersions/X12FileTypesVersionsTable.vue'
vue.component('X12FileTypesVersionsTable', X12FileTypesVersionsTable)

import FileTypeTable from '../views/AdminPanel/X12Definitions/X12FileTypes/FileTypeTable.vue';
vue.component('FileTypeTable',FileTypeTable)

import DateTimeQualifiersTable from '../views/AdminPanel/X12Definitions/X12DateTimeQualifiers/DateTimeQualifiersTable.vue';
vue.component('DateTimeQualifiersTable',DateTimeQualifiersTable)

import X12refIdentificationQualifierTable from '../views/AdminPanel/X12Definitions/X12refIdentificationQualifiers/X12refIdentificationQualifierTable.vue'
vue.component('X12refIdentificationQualifierTable',X12refIdentificationQualifierTable);

import TradingMessagesTable from '../views/AdminPanel/TradingMessages/TradingMessagesTable.vue'
vue.component('TradingMessagesTable',TradingMessagesTable);


import X12PotypesCodeTables from '../views/AdminPanel/X12Definitions/X12PotypesCodes/X12PotypesCodeTables.vue';
vue.component('X12PotypesCodeTables',X12PotypesCodeTables)

import X12termsBasisDateCodeTable from '../views/AdminPanel/X12Definitions/X12termsBasisDateCodes/X12termsBasisDateCodeTable.vue';
vue.component('X12termsBasisDateCodeTable',X12termsBasisDateCodeTable)

import X12termsTypeCodeTable from '../views/AdminPanel/X12Definitions/X12termsTypeCodes/X12termsTypeCodeTable.vue';
vue.component('X12termsTypeCodeTable',X12termsTypeCodeTable)

import X12productServiceQualifierTable from '../views/AdminPanel/X12Definitions/X12productServiceQualifiers/X12productServiceQualifierTable.vue';
vue.component('X12productServiceQualifierTable',X12productServiceQualifierTable)

import X12catalogPurposeCodeTable from '../views/AdminPanel/X12Definitions/X12catalogPurposeCodes/X12catalogPurposeCodeTable.vue';
vue.component('X12catalogPurposeCodeTable',X12catalogPurposeCodeTable)

import X12priceIdentifierCodeTable from '../views/AdminPanel/X12Definitions/X12priceIdentifierCodes/X12priceIdentifierCodeTable.vue';
vue.component('X12priceIdentifierCodeTable',X12priceIdentifierCodeTable)

import X12paymentMethodTable from '../views/AdminPanel/X12Definitions/X12paymentMethods/X12paymentMethodTable.vue';
vue.component('X12paymentMethodTable',X12paymentMethodTable)

import X12marketAreaQualifierTable from '../views/AdminPanel/X12Definitions/X12marketAreaQualifiers/X12marketAreaQualifierTable.vue';
vue.component('X12marketAreaQualifierTable',X12marketAreaQualifierTable)

import X12locationCodeTable from '../views/AdminPanel/X12Definitions/X12locationCodes/X12locationCodeTable.vue';
vue.component('X12locationCodeTable',X12locationCodeTable)

import X12subscriptionFileTypeAssociationTable from '../views/AdminPanel/TradingPartners/Tabs/X12subscriptionFileTypeAssociations/X12subscriptionFileTypeAssociationTable.vue';
vue.component('X12subscriptionFileTypeAssociationTable',X12subscriptionFileTypeAssociationTable)

import X12subscriptionFileTypeAssociation from 'Views/AdminPanel/TradingPartners/Tabs/X12subscriptionFileTypeAssociations/X12subscriptionFileTypeAssociation.vue'
vue.component('X12subscriptionFileTypeAssociation',X12subscriptionFileTypeAssociation)

import X12srvPromAllwChrgCodeTable from 'Views/AdminPanel/X12Definitions/X12srvPromAllwChrgCodes/X12srvPromAllwChrgCodeTable.vue'
vue.component('X12srvPromAllwChrgCodeTable',X12srvPromAllwChrgCodeTable)

import YesNoDialog from './Dialogs/YesNoDialog';
vue.component('YesNoDialog',YesNoDialog);

import X12FunctionalCodesTable from '../views/AdminPanel/X12Definitions/X12FunctionalCodes/X12FunctionalCodesTable.vue';
vue.component('X12FunctionalCodesTable', X12FunctionalCodesTable)

import X12TraceTypesCodesTable from '../views/AdminPanel/X12Definitions/X12TraceTypesCodes/X12TraceTypesCodesTable.vue'
vue.component('X12TraceTypesCodesTable', X12TraceTypesCodesTable)

import X12TrxHandleCodesTable from '../views/AdminPanel/X12Definitions/X12TrxHandleCodes/X12TrxHandleCodesTable.vue'
vue.component('X12TrxHandleCodesTable', X12TrxHandleCodesTable)

import X12ChargeTypesTable from '../views/AdminPanel/X12Definitions/X12ChargeTypes/X12ChargeTypesTable.vue'
vue.component('X12ChargeTypesTable', X12ChargeTypesTable)

import X12ClassTradeCodesTable from '../views/AdminPanel/X12Definitions/X12ClassTradeCodes/X12ClassTradeCodesTable.vue'
vue.component('X12ClassTradeCodesTable', X12ClassTradeCodesTable)

import X12CommQualifiersTable from '../views/AdminPanel/X12Definitions/X12CommQualifiers/X12CommQualifiersTable.vue'
vue.component('X12CommQualifiersTable', X12CommQualifiersTable)

import X12EntityIdentifierCodesTable from '../views/AdminPanel/X12Definitions/X12EntityIdentifierCodes/X12EntityIdentifierCodesTable.vue'
vue.component('X12EntityIdentifierCodesTable', X12EntityIdentifierCodesTable)

import X12InterchangeQualifiersTable from '../views/AdminPanel/X12Definitions/X12InterchangeQualifiers/X12InterchangeQualifiersTable.vue'
vue.component('X12InterchangeQualifiersTable', X12InterchangeQualifiersTable)

import X12InterchangeTradingPartnerTypesTable from '../views/AdminPanel/X12Definitions/X12InterchangeTradingPartnerTypes/X12InterchangeTradingPartnerTypesTable.vue'
vue.component('X12InterchangeTradingPartnerTypesTable', X12InterchangeTradingPartnerTypesTable)