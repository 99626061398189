<template>
  <div v-if="product">
    <div class="product-item-wrap emb-card image-max-width cursor-pointer" @click="goToDetail()">
      <div class="image-container center-center pa-5">
        <v-img
          alt="product"
          max-height="250px"
          min-height="250px"
          :src="getProductImage()"
          :contain="true"
        />
      </div>
      <div class="emb-card-content pa-4">
        <h5 class="font-weight-medium text-capitalize">{{product.name}}</h5>
        <!-- <div class="inline-block">
          <p>{{product.productDescription}}</p>
        </div>-->
        <div class="emb-meta-info layout align-center justify-space-between">
          <div class="inline-block">
            <h6 class="accent--text">
              <emb-currency-sign></emb-currency-sign>
              {{formatNumber(product.unitPrice) || 'XX.XX'}}
            </h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { currency } from "../../../mixins";
export default {
  props: ["product"],

  mixins: [currency],

  mounted() {
    
  }, // mounted

  methods: {
    goToDetail() {
      this.$router.push(
        "/products/" + this.product.categories + "/" + this.product.id
      );
    },

    getProductImage() {
      //console.log('getProductImage()', this.product);
      return this.product.thumbnail;
    },
  }, // methods

};
</script>

<style lang="scss" scoped>
.image-container {
  position: relative;
}
.no-max-height {
  max-height: unset !important;
}

.image-max-width {
  max-width: 200px !important;
}

.thumb-warp {
  position: relative;

  img {
    transition: all 0.8s ease-in-out;
    max-height: 10pc;
    padding: 1pc;
  }

  .add-to-cart {
    .v-btn {
      z-index: 2;
      bottom: -8%;
    }
  }
}
</style>