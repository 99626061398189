var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "550" },
      model: {
        value: _vm.open,
        callback: function($$v) {
          _vm.open = $$v
        },
        expression: "open"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "py-6 px-2" },
        [
          _c("h4", { staticClass: " text-center mb-6" }, [
            _vm._v(_vm._s(_vm.message))
          ]),
          _c(
            "v-card-actions",
            { staticClass: "layout justify-center" },
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "accent mx-2" },
                  on: {
                    click: function($event) {
                      _vm.open = false
                    }
                  }
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "accent" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("onConfirm")
                    }
                  }
                },
                [_vm._v("Yes")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }