var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "testimonail-v2 text-center section-gap" },
    [
      _c(
        "v-container",
        { attrs: { "grid-list-xl": "", "py-0": "" } },
        [
          _c(
            "v-layout",
            {
              attrs: {
                row: "",
                wrap: "",
                "align-center": "",
                "justify-center": ""
              }
            },
            [
              _c(
                "v-flex",
                { attrs: { xl6: "", sm12: "", md8: "", lg6: "" } },
                [
                  _c(
                    "slick",
                    { ref: "carousel", attrs: { options: _vm.slickOptions } },
                    _vm._l(_vm.reviews, function(review, key) {
                      return _c(
                        "div",
                        { key: key, staticClass: "slide-item px-4" },
                        [
                          _c("div", { staticClass: "sec-title" }, [
                            _c("h2", { staticClass: "white--text" }, [
                              _vm._v(_vm._s(review.name))
                            ])
                          ]),
                          _c(
                            "div",
                            { staticClass: "testi-card-v2 mb-6 text-center" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "layout row wrap align-center justify-center"
                                },
                                [
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: {
                                        xs5: "",
                                        sm4: "",
                                        md5: "",
                                        lg4: "",
                                        xl3: "",
                                        "text-center": "",
                                        "user-img": ""
                                      }
                                    },
                                    [
                                      _c("img", {
                                        staticClass:
                                          "br-100 text-center mx-auto",
                                        attrs: {
                                          src: review.img,
                                          width: "200",
                                          height: "200",
                                          alt: "team-image"
                                        }
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "testi-content text-center" },
                            [
                              _c(
                                "h4",
                                {
                                  staticClass:
                                    "font-weight-medium mb-0 white--text"
                                },
                                [_vm._v(_vm._s(review.name))]
                              ),
                              _c(
                                "p",
                                { staticClass: "text-inverse white--text" },
                                [_vm._v(_vm._s(review.position))]
                              ),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "text-inverse testi-content white--text"
                                },
                                [_vm._v(_vm._s(review.desc))]
                              )
                            ]
                          )
                        ]
                      )
                    }),
                    0
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }