<template>
  <div class="emb-gadget-wrap">
    <!-- page title -->
    <v-flex d-none d-xs-none d-sm-none d-md-flex>
      <emb-page-title :heading="imageTitle" :imageType="imageType">
      </emb-page-title> </v-flex
    ><!-- page title -->

    <!-- products and filters -->
    <div class="gadget-content section-gap">
      <v-container grid-list-xl py-0>
        <v-layout row wrap>
          <!-- filters -->
          <v-flex d-none d-xs-none d-sm-none d-md-flex md4 lg3 xl3>
            <emb-sidebar-filters></emb-sidebar-filters> </v-flex
          ><!-- filters -->

          <!-- products -->
          <v-flex xs12 sm12 md8 lg9 xl9>
            <product-items></product-items> </v-flex
          ><!-- products -->
        </v-layout>
      </v-container>
    </div>
    <!-- products and filters -->
  </div>
</template>

<script>
/* eslint-disable */
import AppConfig from "Constants/AppConfig";
import ProductItems from "./ProductItems";
import SidebarFilters from "Components/Ecommerce/SidebarFilters";
import algoliasearch from "algoliasearch/lite";
import { mapMutations, mapActions,mapGetters } from "vuex";
import "instantsearch.css/themes/algolia-min.css";

export default {
  components: {
    embSidebarFilters: SidebarFilters,
    ProductItems,
  }, // components

  data: () => ({
    searchClient: algoliasearch("latency", AppConfig.algoliaApiKey),
    productType: "products",
    imageTitle: "Products",
    imageType: "products",
    showItems: true

  }), // data
  

  mounted() {
      this.searchProductsRequest();
  }, // mounted
  computed: {
      ...mapGetters({
        request: "search/request"
      }),
    },
  methods: {
    ...mapMutations({
      setProductCategory: "search/SET_PRODUCT_CATEGORY",
      setFacets: "search/SET_FACETS",
      setFacetsFormat:"search/SET_FACETS_FORMAT"
    }),

    ...mapActions({
      search: "search/get",
      getProductsByTagName: "search/getByTagName",
      
    }),

    getCurrentRoutePath() {
      return this.$router.currentRoute.path;
    },

    getCurrentProductType() {
      const path = this.getCurrentRoutePath();
      const pathParts = path.split("/");
      const type = pathParts.length > 2 ? pathParts[2] : "products";
      return type.toLowerCase();
    },

    getCurrentImageTitle() {
      const type = this.getCurrentProductType();
      const words = type.split("-");
      const capitalizedWords = words.map(
        (word) => word[0].toUpperCase() + word.slice(1)
      );
      return capitalizedWords.join("-");
    },

    getCurrentImageType() {
      const availableImageTypes = new Set(["beer", "wine", "spirits"]);
      const type = this.getCurrentProductType();
      return availableImageTypes.has(type) ? type : "products";
    },

    searchProductsRequest() {
      //this.request.facets = facets;
      //this.setFacets(facets);
      this.setProductTypeAndImages();
      console.log("===================")
      console.log("Category image for SET-CATEGORY", this.imageTitle);
      this.setProductCategory(this.imageTitle);
    },

    setProductTypeAndImages() {
      this.productType = this.getCurrentProductType(); // Must go first
      this.imageTitle = this.getCurrentImageTitle(); // Must go after currentProductType
      this.imageType = this.getCurrentImageType(); // Must go after currentProductType
    },
  },
  watch:{
    $route(){
      this.searchProductsRequest();
    }
  }
};
</script>
