<template>
    <div class="table-responsive">

        <!-- table -->
        <v-data-table
            :headers="headers"
            :items="paginatedList.items"
            :server-items-length="paginatedList.totalCount"
            :options.sync="listViewOptions"
            :loading="isLoading"
            :footer-props="{ 'items-per-page-options': [10, 20, 30, 40, 50] }">

            <!-- slots -->
            <template v-slot:item.action="{ item }">

                <!-- edit -->
                <!--<v-btn small icon class="primary--text" @click="showEditViewDialog(item, true)">
                    <v-icon>edit</v-icon>
                </v-btn>--><!-- edit -->

                <!-- view -->
                <v-btn small icon class="primary--text" @click="showEditViewDialog(item, false)">
                    <v-icon>visibility</v-icon>
                </v-btn><!-- view -->

            </template><!-- slots -->

        </v-data-table><!-- table -->

        <!-- edit/view stock group dialog -->
        <v-dialog v-model="isShowingEditViewDialog" max-width="400">
            <v-card>

                <v-card-title>
                    Viewing Stock Group
                </v-card-title>
                <v-divider></v-divider>
                
                <v-card-text>

                    <div class="mb-4"></div>

                    <!-- id -->
                    <v-text-field
                        label="ID"
                        :disabled="isDialogView"
                        v-model="selectedStockGroup.stockGroupId"/>

                    <!-- alternate id -->
                    <v-text-field
                        label="Alternate ID"
                        :disabled="isDialogView"
                        v-model="selectedStockGroup.stockGroupAlternateID"/>

                    <!-- display name -->
                    <v-text-field
                        label="Display Name"
                        :disabled="isDialogView"
                        v-model="selectedStockGroup.stockGroupDisplayName"/>

                    <!-- name -->
                    <v-text-field
                        label="Stock Group Name"
                        :disabled="isDialogView"
                        v-model="selectedStockGroup.stockGroupName"/>

                </v-card-text>

                <v-divider></v-divider>
                <v-card-actions>
                    <div class="d-flex justify-end" style="width: 100%">

                        <v-btn text color="primary" @click="isShowingEditViewDialog = false">Close</v-btn>

                    </div>
                </v-card-actions>

            </v-card>
        </v-dialog><!-- edit/view stock group dialog -->

    </div>
</template>

<script>
import { utils } from '../../../mixins/index';

export default {

    props: {
        paginatedList: Object,
        isLoading: Boolean,
        searchParams: Object,
    }, // props

    data: () => ({
        headers: [
            {
                text: "ID",
                sortable: false,
                value: "stockGroupId",
            },
            {
                text: "Alternate ID",
                sortable: false,
                value: "stockGroupAlternateID",
            },
            {
                text: "Display Name",
                sortable: false,
                value: "stockGroupDisplayName",
            },
            {
                text: "Stock Group Name",
                sortable: false,
                value: "stockGroupName",
            },
            {
                text: '',
                sortable: false,
                value: "action",
            },
        ],

        listViewOptions: {
            page: 1,
            itemsPerPage: 10,
        },

        isShowingEditViewDialog: false,
        isDialogEdit: false,
        isDialogView: false,
        selectedStockGroup: {},
    }), // data

    mounted() {
        this.listViewOptions.page = this.searchParams.pageNumber;
        this.listViewOptions.itemsPerPage = this.searchParams.pageSize;
    }, // mounted

    methods: {
        getOrderDate(order) {
            if (!order || !order.orderDate) {
                return '';
            }

            let dateUtc = order.orderDate.toString();
            let formattedDate = utils.methods.getFormattedDateUtcShort(dateUtc);
            return formattedDate;
        },

        getOrderExpectedDeliveryDate(order) {
            if (!order || !order.expectedDeliveryDate) {
                return '';
            }

            let dateUtc = order.expectedDeliveryDate.toString();
            let formattedDate = utils.methods.getFormattedDateUtcShort(dateUtc);
            return formattedDate;
        },

        showEditViewDialog(stockGroup, isEdit) {
            this.isShowingEditViewDialog = true;
            this.selectedStockGroup = stockGroup;

            if (isEdit) {
                this.isDialogEdit = isEdit == true;
                this.isDialogView = isEdit == false;
            }
            else {
                this.isDialogEdit = false;
                this.isDialogView = true;
            }
        },
    }, // methods

    watch: {
        listViewOptions: {
            handler() {
                this.searchParams.pageNumber = this.listViewOptions.page;
                this.searchParams.pageSize = this.listViewOptions.itemsPerPage;

                let sortField = this.listViewOptions.sortBy[0];
                let sortDesc = this.listViewOptions.sortDesc[0];

                if (sortField) {
                    this.searchParams.sortField = sortField;
                    this.searchParams.sortDirection = sortDesc? 'DESC' : 'ASC';
                }

                this.$emit('search-params-changed', this.searchParams);
            },
        },
    }, // watch

    mixins: [ utils ],
}
</script>
