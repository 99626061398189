<template>
    <div class="table-responsive">
        <v-data-table
            :headers="headers"
            :items="paginatedList.items"
            :server-items-length="paginatedList.totalCount"
            :options.sync="listViewOptions"
            :loading="isLoading"
            :footer-props="{'items-per-page-options': [10, 15, 20, 25, 50] }"
        >
        <template v-slot:[`item.action`]="{ item }">
        <Menu :menuItems="rowMenuItems" :menuItemContext="item">
          <v-btn small icon class="primary--text">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </Menu>
      </template>
        </v-data-table>
        <YesNoDialog ref="yesNoDialog"/>
    </div>
</template>

<script>
import { MenuItem } from "../../../../components/Menu/MenuItem";
import { yesNoDialogMixin } from "../../../../mixins/showYesNoDialog";
import { tableProps } from "../../../../mixins/tableProps";
export default {
    mixins: [tableProps, yesNoDialogMixin],
    name: "ChargeTypesTable",

    data() {
        return {
            rowMenuItems: [
                new MenuItem({
                    icon: "edit",
                    label: "Edit",
                    action: (item) => {
                        this.$router.push(
                        "/admin-panel/definitions/chargeTypes/edit/" +
                            item.chargeTypeId
                        );
                    },
                }),
                new MenuItem({
                    icon: "delete",
                    label: "Delete",
                    action: (item) => {
                        this.showDeleteDialog(item.chargeTypeId);
                    },
                }),
            ],
            headers: [
                {
                    text: "Charge Type Id",
                    sortable: true,
                    value: "chargeTypeId",
                },
                {
                    text: "Charge Type Description",
                    sortable: true,
                    value: "chargeTypeDescription",
                },
                {
                    text: "Charge Type",
                    sortable: true,
                    value: "chargeType",
                },
                {
                    text: "Actions",
                    sortable: false,
                    value: "action",
                },
            ],
        }
    },
}
</script>
