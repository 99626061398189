import people from './PeopleApi'
import products from "./products"
import routes from './RoutesApi'
import X12 from './X12Api';
export default {
    people,
    products,
    routes,
    X12
}
