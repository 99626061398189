var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "emb-gadget-wrap" },
    [
      _c("emb-page-title", {
        attrs: { heading: "Gadgets", subHeading: "Checkout our new Gadgets." }
      }),
      _c(
        "div",
        { staticClass: "gadget-content section-gap" },
        [
          _c(
            "ais-instant-search",
            {
              attrs: { "search-client": _vm.searchClient, "index-name": "ikea" }
            },
            [
              _c(
                "v-container",
                { attrs: { "grid-list-xl": "", "py-0": "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        {
                          attrs: {
                            xs12: "",
                            sm12: "",
                            md4: "",
                            lg3: "",
                            xl3: ""
                          }
                        },
                        [_c("emb-sidebar-filters")],
                        1
                      ),
                      _c(
                        "v-flex",
                        {
                          attrs: {
                            xs12: "",
                            sm12: "",
                            md8: "",
                            lg9: "",
                            xl9: ""
                          }
                        },
                        [
                          _c("product-items", {
                            attrs: {
                              cols: 6,
                              colxl: 4,
                              collg: 4,
                              colmd: 6,
                              colsm: 6,
                              colxs: 12
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }