/*eslint-disable*/

import api from '@/api';

export default {
    namespaced: true,

    state: {
        moduleName: 'stockGroups',
        stockGroups: {}, // StockGroupsPaginatedList
        searchParams: {
            pageNumber: 1,
            pageSize: 10,
            searchValue: null, // string
            //sortDirection: 'ASC',
            //sortField: 'quantityOnHand',
        },
    }, // state

    getters: {
        getStockGroups: state => state.stockGroups,
        getSearchParams: state => state.searchParams,
    }, // getters

    mutations: {
        SET_STOCK_GROUPS(state, stockGroups) {
            state.stockGroups = stockGroups;
        },
        SET_SEARCH_PARAM_PAGE_NUMBER(state, pageNumber) {
            state.searchParams.pageNumber = pageNumber;
        },
        SET_SEARCH_PARAM_PAGE_SIZE(state, pageSize) {
            state.searchParams.pageSize = pageSize;
        },
        SET_SEARCH_PARAM_SEARCH_VALUE(state, searchValue) {
            state.searchParams.searchValue = searchValue;
        },
        SET_SEARCH_PARAM_SORT_DIRECTION(state, sortDirection) {
            state.searchParams.sortDirection = sortDirection;
        },
        SET_SEARCH_PARAM_SORT_FIELD(state, sortField) {
            state.searchParams.sortField = sortField;
        },
        SET_SEARCH_PARAMS(state, searchParams) {
            state.searchParams = searchParams;
        },
        RESET_SEARCH_PARAMS(state) {
            state.searchParams.pageSize = 10;
            state.searchParams.pageNumber = 1;
            state.searchParams.searchValue = null;
            //state.searchParams.sortField = 'quantityOnHand';
            //state.searchParams.sortDirection = 'DESC';
        },
    }, // mutations

    actions: {
        get({ state, commit }) {
            return api.get(`${state.moduleName}`, { params: state.searchParams })
            .then(result => commit('SET_STOCK_GROUPS', result.data));
        },
    },
}
