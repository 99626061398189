var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { style: { "background-color": _vm.primaryColor } },
      [
        _c(
          "v-container",
          { attrs: { "grid-list-xl": "" } },
          [
            _c(
              "v-layout",
              {
                attrs: {
                  row: "",
                  wrap: "",
                  "align-center": "",
                  "justify-space-between": "",
                  "pa-0": "",
                  "ma-0": ""
                }
              },
              [
                _c(
                  "v-flex",
                  {
                    attrs: {
                      xs6: "",
                      sm6: "",
                      md4: "",
                      lg3: "",
                      xl3: "",
                      "px-0": ""
                    }
                  },
                  [
                    _c("div", { staticClass: "site-logo d-inline-block" }, [
                      _c("img", {
                        attrs: {
                          alt: "site-logo",
                          height: "34",
                          src: _vm.appLogo,
                          width: "70%"
                        }
                      })
                    ])
                  ]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass:
          "emb-notFound-wrap section-gap d-flex justify-center align-center",
        staticStyle: { height: "90vh" }
      },
      [
        _c("div", { staticClass: "container" }, [
          _c(
            "div",
            { staticClass: "layout align-center justify-center" },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "", sm9: "", md6: "", lg5: "", xl5: "" } },
                [
                  _c(
                    "div",
                    { staticClass: "emb-card text-center pa-6" },
                    [
                      _c("h1", [_vm._v("403")]),
                      _c("p", [
                        _vm._v("Hello "),
                        _c("b", [_vm._v(_vm._s(_vm.userEmail))])
                      ]),
                      _c("h5", { staticClass: "mb-4" }, [
                        _vm._v(
                          "Your account seems to be unactive or nonexistent."
                        )
                      ]),
                      _c("p", [
                        _vm._v(
                          "If you just signed up, an admin needs to activate your account. If you already had an active or existing account, contact your administrator for more details."
                        )
                      ]),
                      _c("p", [
                        _vm._v(
                          "You can try to sign in again once your account is activated."
                        )
                      ]),
                      _c(
                        "v-btn",
                        {
                          staticClass: "accent",
                          attrs: {
                            loading: _vm.isLoggingOut,
                            disabled: _vm.isLoggingOut,
                            block: ""
                          },
                          on: { click: _vm.logout }
                        },
                        [_vm._v("Logout")]
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ])
      ]
    ),
    _c(
      "div",
      { staticClass: "footer" },
      [
        _c("v-container", { staticClass: "pa-12" }, [
          _c("div", { staticClass: "d-flex justify-center mb-6" }, [
            _c("div", [
              _c("h6", [_vm._v("About us")]),
              _c("p", [_vm._v(_vm._s(_vm.aboutUs))])
            ])
          ]),
          _c("hr", { staticClass: "mb-10" }),
          _c("div", { staticClass: "d-flex justify-center justify-sm-end" }, [
            _c("p", [_vm._v(_vm._s(_vm.copyrightText))])
          ])
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }