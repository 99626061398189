var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "admin-panel" },
    [
      _c("vue-snotify"),
      _c(
        "v-navigation-drawer",
        {
          staticClass: "primary",
          attrs: { app: "", right: _vm.rtlLayout, width: _vm.navbarWidth },
          model: {
            value: _vm.drawer,
            callback: function($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer"
          }
        },
        [
          _c(
            "div",
            { staticClass: "site-logo py-4 mb-12" },
            [
              _c(
                "router-link",
                { staticClass: "d-block text-center", attrs: { to: "/" } },
                [
                  _c("img", {
                    attrs: {
                      alt: "site-logo",
                      height: "34",
                      src: _vm.appLogo,
                      width: "70%"
                    }
                  })
                ]
              )
            ],
            1
          ),
          _c(
            "v-list",
            { staticClass: "admin-menu-wrap", attrs: { dense: "" } },
            [
              _vm._l(_vm.adminPanelMenus, function(menuItem, key) {
                return [
                  menuItem.children == null
                    ? [
                        _c(
                          "v-list-item",
                          {
                            key: key,
                            class: menuItem.active,
                            attrs: { to: menuItem.path },
                            on: {
                              click: function($event) {
                                return _vm.updateSidebarMenu(menuItem)
                              }
                            }
                          },
                          [
                            _c(
                              "v-list-item-action",
                              { staticClass: "ma-0" },
                              [_c("v-icon", [_vm._v(_vm._s(menuItem.icon))])],
                              1
                            ),
                            _c(
                              "v-list-item-content",
                              { staticClass: "py-0 text-left pl-3" },
                              [
                                _c("v-list-item-title", [
                                  _vm._v(_vm._s(_vm.$t(menuItem.name)))
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    : [
                        _c(
                          "v-list-group",
                          {
                            key: key,
                            attrs: {
                              "no-action": "",
                              "prepend-icon": menuItem.icon,
                              "append-icon": "keyboard_arrow_down"
                            },
                            on: { click: _vm.onNavMenuClicked },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function() {
                                    return [
                                      _c(
                                        "v-list",
                                        { attrs: { to: menuItem.path } },
                                        [
                                          _c(
                                            "v-list-item-content",
                                            {
                                              staticClass: "py-0 text-left pl-3"
                                            },
                                            [
                                              _c("v-list-item-title", [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(menuItem.name)
                                                    )
                                                  )
                                                ])
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              true
                            ),
                            model: {
                              value: menuItem.active,
                              callback: function($$v) {
                                _vm.$set(menuItem, "active", $$v)
                              },
                              expression: "menuItem.active"
                            }
                          },
                          [
                            menuItem.children
                              ? [
                                  _vm._l(menuItem.children, function(
                                    subItem,
                                    index
                                  ) {
                                    return [
                                      subItem.children == null
                                        ? [
                                            _c(
                                              "v-list-item",
                                              {
                                                key: index,
                                                staticStyle: {
                                                  "padding-left":
                                                    "50px !important"
                                                },
                                                attrs: { to: subItem.path },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.updateSidebarMenu(
                                                      menuItem
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-list-item-icon",
                                                  {
                                                    staticStyle: {
                                                      "margin-right":
                                                        "10px !important"
                                                    }
                                                  },
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v(
                                                        _vm._s(subItem.icon)
                                                      )
                                                    ])
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-list-item-content",
                                                  [
                                                    _c(
                                                      "v-list-item-title",
                                                      {
                                                        staticClass:
                                                          "white--text"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(subItem.name)
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        : [
                                            _c(
                                              "v-list-group",
                                              {
                                                key: index,
                                                attrs: {
                                                  "sub-group": "",
                                                  "append-icon":
                                                    "keyboard_arrow_down"
                                                },
                                                on: {
                                                  click:
                                                    _vm.onNavChildMenuClicked
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function() {
                                                        return [
                                                          _c(
                                                            "v-list-item-content",
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                {
                                                                  domProps: {
                                                                    textContent: _vm._s(
                                                                      subItem.name
                                                                    )
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      },
                                                      proxy: true
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              },
                                              _vm._l(subItem.children, function(
                                                subMenu,
                                                i
                                              ) {
                                                return _c(
                                                  "v-list-item",
                                                  {
                                                    key: i,
                                                    staticStyle: {
                                                      "padding-left":
                                                        "75px !important",
                                                      width: "500px !important"
                                                    },
                                                    attrs: { to: subMenu.path }
                                                  },
                                                  [
                                                    _c("v-list-item-title", {
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          subMenu.name
                                                        )
                                                      }
                                                    }),
                                                    _c(
                                                      "v-list-item-icon",
                                                      {
                                                        staticStyle: {
                                                          "padding-right":
                                                            "90px !important"
                                                        }
                                                      },
                                                      [
                                                        _c("v-icon", {
                                                          domProps: {
                                                            textContent: _vm._s(
                                                              subMenu.icon
                                                            )
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              }),
                                              1
                                            )
                                          ]
                                    ]
                                  })
                                ]
                              : _vm._e()
                          ],
                          2
                        )
                      ]
                ]
              })
            ],
            2
          )
        ],
        1
      ),
      _c(
        "v-app-bar",
        {
          staticClass: "admin-panel-toolbar",
          attrs: { color: "white", dark: "", app: "" }
        },
        [
          _c(
            "v-btn",
            {
              staticClass: "mx-2",
              attrs: { fab: "", dark: "", small: "", color: "primary" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  _vm.drawer = !_vm.drawer
                }
              }
            },
            [_c("v-icon", { attrs: { dark: "" } }, [_vm._v("menu")])],
            1
          ),
          _c(
            "div",
            { staticClass: "d-inline-flex align-center" },
            [
              _c("div", { staticClass: "options mr-2" }, [_c("emb-lang")], 1),
              _c(
                "v-toolbar-title",
                {
                  staticClass: "mr-2",
                  on: {
                    click: function($event) {
                      _vm.isSwitchingLocation = true
                    }
                  }
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "white--text font-weight-regular",
                      attrs: { href: "javascript:void(0)" }
                    },
                    [_vm._v(_vm._s(_vm.currentLocation.locationName))]
                  ),
                  _c("v-icon", { attrs: { dark: "" } }, [
                    _vm._v("arrow_drop_down")
                  ])
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "notifications" },
                [
                  _c("emb-user-block", {
                    attrs: { userBlockItems: _vm.userBlockItems }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-content",
        [
          _c(
            "v-container",
            { attrs: { "fill-height": "", "container-fluid": "" } },
            [
              _c(
                "v-layout",
                {
                  staticClass: "py-6 px-sm-2",
                  attrs: { "justify-center": "", "align-center": "" }
                },
                [_c("v-flex", [_c("router-view")], 1)],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "300" },
          model: {
            value: _vm.isLoggingOut,
            callback: function($$v) {
              _vm.isLoggingOut = $$v
            },
            expression: "isLoggingOut"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "pa-3" },
            [
              _c(
                "v-card-text",
                { staticClass: "d-flex flex-column align-center" },
                [
                  _c("p", [_vm._v("Logging out")]),
                  _c("v-progress-circular", {
                    attrs: { width: "2", indeterminate: "" }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500" },
          model: {
            value: _vm.isSwitchingLocation,
            callback: function($$v) {
              _vm.isSwitchingLocation = $$v
            },
            expression: "isSwitchingLocation"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-text",
                [
                  _vm.currentLocation
                    ? _c(
                        "div",
                        [
                          _c(
                            "h5",
                            { staticClass: "font-weight-regular pt-6 mb-3" },
                            [_vm._v(" Your current location is ")]
                          ),
                          _c(
                            "v-card",
                            { staticClass: "mb-6" },
                            [
                              _c("v-card-title", [
                                _vm._v(_vm._s(_vm.currentLocation.locationName))
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("h5", { staticClass: "font-weight-regular mb-3" }, [
                    _vm._v("Select another location")
                  ]),
                  _vm._l(_vm.locations, function(location) {
                    return _c(
                      "div",
                      { key: location.locationId },
                      [
                        _c("v-hover", {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var hover = ref.hover
                                  return [
                                    _c(
                                      "v-card",
                                      {
                                        staticClass: "hoverable mb-3",
                                        class: { "on-hover": hover },
                                        attrs: { elevation: hover ? 6 : 2 },
                                        on: {
                                          click: function($event) {
                                            return _vm.onLocationSelected(
                                              location
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("v-card-title", [
                                          _vm._v(_vm._s(location.locationName))
                                        ])
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  })
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "300" },
          model: {
            value: _vm.isLoadingLocation,
            callback: function($$v) {
              _vm.isLoadingLocation = $$v
            },
            expression: "isLoadingLocation"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "pa-3" },
            [
              _c(
                "v-card-text",
                { staticClass: "d-flex flex-column align-center" },
                [
                  _c("p", [_vm._v("Switching location...")]),
                  _c("v-progress-circular", {
                    attrs: { width: "2", indeterminate: "" }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }