<template>
  <div class="sidebar-filter-wrap" v-if="request.facets.length > 0">
    <div
      class="cateogary-block emb-card white mb-6 pa-6"
      v-for="(facet, index) in request.facets"
      :key="index"
      :class="{ hide: !showFacet(facet, index) }"
    >
      <h5>{{ facet.facet }}</h5>
      <ul class="facet-options">
        <li
          v-for="(option, index) in facet.options"
          :key="index"
          :class="{ hide: index > 4 && !facet.showAll }"
        >
          <label>
            <input
              type="checkbox"
              v-model="option.value"
              @change="getSearch(facet.facet, option.name, facet.orderValue)"
            />
            <span class="option-name">{{ option.name }}</span>
            <span class="option-value">{{ option.count }}</span>
          </label>
        </li>
        <h6
          v-if="facet.options.length > 5"
          class="accent--text cursor-pointer mb-0 mt-5 text-center"
          @click="showAllFacetOptions(facet.facet)"
        >
          {{ facet.showAll ? "Show Less" : "Show All" }}
        </h6>
      </ul>
    </div>

    <div class="emb-card white pa-6">
      <div class="ais-ClearRefinements">
        <v-btn small block color="accent" @click="clearfilters()">
          Clear All Filters
        </v-btn>
      </div>
    </div>
  </div>
  <div class="sidebar-filter-wrap" v-else>
    <v-skeleton-loader
      :elevation="2"
      type="card-heading, list-item-three-line, list-item-three-line"
    ></v-skeleton-loader>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  props: ["facets"],

  data() {
    return {
      isSearchedByTagOnlyOneTime: false,
    };
  },

  computed: {
    ...mapGetters({ request: "search/request" }),
  },

  mounted() {

  },
  
  methods: {
    ...mapActions({ search: "search/get" }),
    ...mapMutations({ setFacets: "search/SET_FACETS" }),

    /**
     * Checks if the search request has all the neccesary
     * fields to search products by tag name
     */
    isRequestReadyForTagSearch() {
      let tagFacet = this.request.facets.find((facet) => facet.facet == "tags");
      return tagFacet != null;
    },

    showFacet(facet, index) {
      var result = false;
      var facetName = facet.facet.toLowerCase();
      switch (facetName) {
        case "categories":
          result =
            this.request.category.toLowerCase() == "products" ||
            (this.request.category.toLowerCase() != "wine" &&
              this.request.category.toLowerCase() != "beer" &&
              this.request.category.toLowerCase() != "spirits");
          return result;
        case "varietals":
          result = this.request.category.toLowerCase() == "wine";
          break;
        case "style":
          result = this.request.category.toLowerCase() != "products";
          break;
        case "subtype":
          result = this.request.category.toLowerCase() != "products";
          break;
        case "appelation":
          result = this.request.category.toLowerCase() == "wine";
          break;
        case "region":
          result =
            this.request.category.toLowerCase() == "wine" ||
            this.request.category.toLowerCase() == "beer";
          break;
        case "tags":
          return (
            this.request.category.toLowerCase() == "products" &&
            facet.options.length > 0
          );
        default:
          result = true;
          break;
      }

      // Show sub types when wine is selected
      if (
        facetName == "subtype" &&
        this.request.category.toLowerCase().includes("wine")
      )
        return true;

      return (
        result &&
        (facet.options.length > 1 || facet.options.some((x) => x.value)) &&
        (facet.orderValue == 1 ||
          (facet.orderValue > 1 &&
            this.request.facets[index - 1].options.some((o) => o.value)))
      );
    },

    getSearch(facet, option, orderValue) {
      let newFacets = this.request.facets.map((currentFacet) => {
        if (currentFacet.orderValue == 0) return currentFacet;

        currentFacet.options = currentFacet.options.map((currentOption) => {
          let isCurrentFacetMorePriority = currentFacet.orderValue > orderValue;
          let isCurrentFacetSameWithDifferentOption =
            currentFacet.facet == facet && option != currentOption.name;

          currentOption.value =
            isCurrentFacetMorePriority || isCurrentFacetSameWithDifferentOption
              ? false
              : currentOption.value;

          return currentOption;
        });

        return currentFacet;
      });

      this.setFacets(newFacets);
      this.search();
    },

    /**
     * Searches the facet options in the current request and sets it's
     * value to the new provided one. This method internally sets the
     * search request in the store.
     */
    setFacetOptionValue(facetName, optionName, newValue) {
      let newFacets = this.request.facets.map((currentFacet) => {
        if (currentFacet.facet != facetName) return currentFacet;

        currentFacet.options = currentFacet.options.map((currentOption) => {
          if (currentOption.name != optionName) return currentOption;

          currentOption.value = newValue;

          return currentOption;
        });

        return currentFacet;
      });

      this.setFacets(newFacets);
    },

    showAllFacetOptions(facet) {
      this.setFacets(
        this.request.facets.map((x) => {
          if (x.facet != facet) return x;

          x.showAll = !x.showAll;
          return x;
        })
      );
    },

    clearfilters() {
      this.setFacets(
        this.request.facets.map((x) => {
          x.options = x.options.map((o) => {
            o.value = false;
            return o;
          });
          return x;
        })
      );
      this.search();
    },
  }, // methods

  watch: {
    /**
     * Changes for the request object must be watched to figure out if
     * a search by tag needs to be executed. The watch is neccesary because
     * we need at least one complete request object sent from the backend
     * in order for the search to be executed correctly.
     */
    request: {
      deep: true,
      handler() {
        let tag = this.$route.query.tag;

        // If no tag was provided in query params, continue with normal searches
        if (!tag) {
          return;
        }

        // A search with the query param tag must be done only once
        if (this.isSearchedByTagOnlyOneTime) {
          return;
        }

        // If the request object is not complete yet, check again on the next change
        if (!this.isRequestReadyForTagSearch()) {
          return;
        }

        this.setFacetOptionValue("tags", tag, true);
        this.search();
        this.isSearchedByTagOnlyOneTime = true;
      },
    },
  }, // watch
};
</script>

<style  scoped>
.sidebar-filter-wrap {
  width: 100%;
}
.facet-options {
  padding-left: 0;
  list-style: none;
  font-weight: 400;
  line-height: 1.5;
  font-size: 1rem;
  margin: 0;
  padding: 0;
}
.facet-options li {
  margin-bottom: 1rem;
  font-size: 1rem;
}
.facet-options li label input {
  margin-right: 0.8rem;
}
.facet-options li label .option-name {
  color: #3a405b;
}

.facet-options li label .option-value {
  color: #fff;
  background: rgba(0, 0, 0, 0.4);
  padding: 0.2rem 0.3rem;
  border-radius: 3px;
  margin-left: 5px;
  font-weight: 500;
  font-size: 0.8rem;
}

.hide {
  display: none;
}
</style>