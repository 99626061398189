<template>
  <div class="table-responsive">
    <v-data-table
      :headers="headers"
      :items="paginatedList.items"
      :server-items-length="paginatedList.totalCount"
      :options.sync="listViewOptions"
      :loading="isLoading"
      :footer-props="{ 'items-per-page-options': [10, 20, 30, 40, 50] }"
    >
      <template v-slot:[`item.action`]="{ item }">
        <Menu :menuItems="rowMenuItems" :menuItemContext="item">
          <v-btn small icon class="primary--text">
              <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </Menu>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { MenuItem } from "../components/Menu/MenuItem";
import { tableProps } from "../mixins/tableProps";

export default {
  mixins: [tableProps],
  props: ['paginatedList', 'isLoading', 'searchParams'],

  data() {
    return {
      rowMenuItems: [
        new MenuItem({
            icon: "edit",
            label: "Edit",
            action: (item) => {
                this.$router.push('/admin-panel/account-edit/' + item.internalId);
            },
        }),
        new MenuItem({
          icon: "visibility",
          label: "View",
          action: (item) => {
              this.$router.push('/admin-panel/account-view/' + item.internalId);
          },
        }),
      ],
      headers: [
        {
          text: "Account Number",
          sortable: true,
          value: "internalId"
        },
        {
          text: "Description",
          sortable: true,
          value: "customerName"
        },
        {
          text: "Action",
          sortable: false,
          value: "action"
        }
      ],
    };
  }, // data

  mounted() {
    console.log("this.paginatedList:", this.paginatedList);
    console.log("this.searchParams:", this.searchParams);
    console.log("this.isLoading:", this.isLoading);
    this.listViewOptions.page = this.searchParams.pageNumber;
    this.listViewOptions.itemsPerPage = this.searchParams.pageSize;
  }, // mounted

  watch: {
    listViewOptions: {
      handler() {
        this.searchParams.pageNumber = this.listViewOptions.page;
        this.searchParams.pageSize = this.listViewOptions.itemsPerPage;

        let sortField = this.listViewOptions.sortBy[0];
        let sortDesc = this.listViewOptions.sortDesc[0];

        if (sortField) {
          this.searchParams.sortField = sortField;
          this.searchParams.sortDirection = sortDesc? 'DESC' : 'ASC';
        }

        this.$emit('search-params-changed', this.searchParams);
      },
    },
  }, // watch
};
</script>
