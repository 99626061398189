<template>
  <div class="responsive-table">
    <v-data-table
      :headers="headers"
      :items="paginatedList.items"
      :server-items-length="paginatedList.totalCount"
      :options.sync="listViewOptions"
      :loading="isLoading"
      :footer-props="{ 'items-per-page-options': [10, 15, 20, 25, 50] }"
    >
      <template v-slot:[`item.action`]="{ item }">
        <Menu :menuItems="rowMenuItems" :menuItemContext="item">
          <v-btn small icon class="primary--text">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </Menu>
      </template>
    </v-data-table>
    <YesNoDialog ref="yesNoDialog" />
  </div>
</template>
<script>
import { MenuItem } from "../../../../components/Menu/MenuItem";
import { tableProps } from "../../../../mixins/tableProps";
import { yesNoDialogMixin } from "../../../../mixins/showYesNoDialog";
export default {
  mixins: [tableProps,yesNoDialogMixin],
  name: "X12catalogPurposeCodeTable",
  data() {
    return {
      rowMenuItems: [
        new MenuItem({
          icon: "edit",
          label: "Edit",
          action: (item) => {
            this.$router.push(
              "/admin-panel/definitions/catalogPurposeCodes/edit/" +
                item.catalogPurposeId
            );
          },
        }),
        new MenuItem({
          icon: "delete",
          label: "Delete",
          action: (item) => {
            this.showDeleteDialog(item.catalogPurposeId);
          },
        }),
      ],
      headers: [
        {
          text: "Catalog Purpose Id",
          sortable: true,
          value: "catalogPurposeId",
        },
        {
          text: "Catalog Purpose Decription",
          sortable: true,
          value: "catalogPurposeDescription",
        },
        {
          text: "Catalog Purpose Code",
          sortable: true,
          value: "catalogPurposeCode",
        },
        {
          text: "Actions",
          sortable: true,
          value: "action",
        },
      ],
      
    };
  },
};
</script>