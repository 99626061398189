var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-title-bar", class: [_vm.imageType] }, [
    _c("div", { staticClass: "container" }, [
      _c("h1", { staticClass: "mb-4" }, [_vm._v(_vm._s(_vm.heading))]),
      _c("p", { staticClass: "white--text font-weight-regular" }, [
        _vm._v(_vm._s(_vm.subHeading))
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }