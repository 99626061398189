<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col cols="12" sm="4" md="3" lg="2" class="d-flex justify-center">

          <avatar v-if="!isPhotoAvailable"
            :fullname="selectedPerson.fullName" :size="130" class="hoverable">
          </avatar>

          <v-avatar v-else size="180">
            <v-img
              alt="Profile Image"
              :src="'data:image/png;base64, ' + selectedPerson.photo">
            </v-img>
          </v-avatar>


        </v-col>

        <v-col cols="12" sm="8" md="9" lg="10">
          <v-text-field :disabled="isView" label="Logon Name" v-model="selectedPerson.logonName" />
          <v-text-field :disabled="isView" label="Email" v-model="selectedPerson.emailAddress" />
        </v-col>

      </v-row>

      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field :disabled="isView" label="Full Name" v-model="selectedPerson.fullName" />
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            :disabled="isView"
            label="Preffered Name"
            v-model="selectedPerson.preferredName"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            :disabled="isView"
            label="Phone Number"
            v-model="selectedPerson.phoneNumber"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field :disabled="isView" label="Fax Number" v-model="selectedPerson.faxNumber" />
        </v-col>
      </v-row>

      <div v-if="isAdd">
            <div class="d-flex justify-end">
                <v-switch
                    class="ma-0 pa-0"
                    label="Is employee?"
                    @change="isEmployee=!isEmployee,selectedPerson.isEmployee=!selectedPerson.isEmployee"/>
            </div>
        </div><!-- filter people by location switch -->

      <v-expansion-panels multiple focusable :value="[0]" v-if="isEmployee || (isView && selectedPerson.isEmployee) || (isEdit && selectedPerson.isEmployee) ">
        <!-- employee data -->
        <v-expansion-panel>
          <v-expansion-panel-header>Employee Data</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col>
                <v-text-field :disabled="isView" label="Company Name" v-model="selectedPerson.companyName"/>
              </v-col>
              <v-col>
                <v-text-field :disabled="isView" label="Route ID" v-model="selectedPerson.routeId"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field :disabled="isView" label="Employee ID" v-model="selectedPerson.employeeId"/>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field :disabled="isView" label="Employee Code" v-model="selectedPerson.employeeCode"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field :disabled="isView" label="Business Title" v-model="selectedPerson.businessTitle"/>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field :disabled="isView" label="Department" v-model="selectedPerson.department"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field :disabled="isView" label="Work Phone Number" v-model="selectedPerson.workPhoneNumber"/>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field :disabled="isView" label="Primary Work Location" v-model="selectedPerson.primaryWorkLocation"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field :disabled="isView" label="Security Group" v-model="selectedPerson.securityGroup"/>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field :disabled="isView" label="Badge ID" v-model="selectedPerson.badgeId"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field :disabled="isView" label="Primary Supervisor" v-model="selectedPerson.supervisorPrimary"/>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field :disabled="isView" label="Status" v-model="selectedPerson.status"/>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel><!-- employee data -->
      </v-expansion-panels>
    </v-card-text>
  </v-card>
</template>

<script>
import Avatar from "vue-avatar-component";

export default {
  props: ["selectedPerson"],

  data: () => ({
    isEdit: false,
    isView: false,
    isAdd:false,
    isEmployee:false
  }), // data

  computed: {
    isPhotoAvailable() {
      return this.selectedPerson.photo != null;
    },
  },

  beforeMount() {
    this.isEdit = this.$route.path.includes("edit");
    this.isView = this.$route.path.includes("view");
    this.isAdd = this.$route.path.includes('add');
  }, // beforeMount

  mounted() {

  }, // mounted
  
  
  components: { Avatar },
};
</script>

<style scoped>
.hoverable:hover {
  cursor: pointer;
}
</style>
