var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "emb-faq-wrap" },
    [
      _c("emb-page-title", {
        attrs: {
          heading: "Frequently Asked Question",
          subHeading: "We explained the basics question in this section."
        }
      }),
      _c("div", { staticClass: "emb-faq-content section-gap pb-0" }, [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "faq" }, [
            _vm._m(0),
            _c(
              "div",
              { staticClass: "section-gap pt-4" },
              [
                _c(
                  "v-expansion-panels",
                  _vm._l(_vm.faq, function(question, key) {
                    return _c(
                      "v-expansion-panel",
                      { key: key },
                      [
                        _c("v-expansion-panel-header", [
                          _c("h5", { staticClass: "mb-0" }, [
                            _vm._v(_vm._s(question.ques))
                          ])
                        ]),
                        _c("v-expansion-panel-content", [
                          _vm._v(" " + _vm._s(question.ans) + " ")
                        ])
                      ],
                      1
                    )
                  }),
                  1
                )
              ],
              1
            )
          ])
        ]),
        _c(
          "div",
          {
            staticClass: "subscribe-block subscribe-bg section-gap text-center"
          },
          [
            _c(
              "div",
              { staticClass: "container pt-0" },
              [
                _c(
                  "v-layout",
                  {
                    attrs: {
                      row: "",
                      warp: "",
                      "align-center": "",
                      "justify-center": ""
                    }
                  },
                  [
                    _c(
                      "v-flex",
                      { attrs: { sm12: "", xl6: "", lg6: "", md6: "" } },
                      [
                        _c(
                          "h2",
                          { staticClass: "font-bold white--text mb-6" },
                          [_vm._v(" Did not get your answer here?")]
                        ),
                        _c("h6", { staticClass: "white--text mb-6" }, [
                          _vm._v(
                            " If your are unable to get the answers then feel free to contact us by submit a support request. We will very happy to listen from you. "
                          )
                        ]),
                        _c(
                          "v-btn",
                          {
                            staticClass: "accent",
                            attrs: { large: "", to: "/contact" }
                          },
                          [_vm._v(" Submit Request ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "sec-title mb-12" }, [
      _c("div", { staticClass: "layout align-start" }, [
        _c("h2", { staticClass: "mb-6" }, [
          _vm._v("Get Instant answers for most common questions")
        ])
      ]),
      _c("p", [
        _vm._v(
          "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Magni, aspernatur minus voluptatibus quaerat officiis, incidunt maiores, tempora nam eaque ab consequatur tenetur explicabo voluptatem vel voluptatum perspiciatis accusantium. Illum, nemo?"
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }