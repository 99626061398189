/*eslint-disable*/

import api from '@/api';

export default {
    namespaced: true,

    state: {
        moduleName: 'licenses',
        licenses: [],
        currentLicense: undefined,
        requestParams: {
            pageSize: 30,
            pageNumber: 1,
            sortField: 'licenseDescription',
            sortDirection: 'ASC',
        },
    }, // state

    getters: {
        getLicenses: state => state.licenses,
        getCurrentLicense: state => state.currentLicense,
    }, // getters

    mutations: {
        SET_LICENSES(state, licenses) {
            state.licenses = licenses;
        },
        SET_CURRENT_LICENSE(state, license) {
            state.currentLicense = license;
        },
    }, // mutations

    actions: {
        get({ state, commit }) {
            return api.get(`${state.moduleName}`, { params: state.requestParams })
            .then(result => commit('SET_LICENSES', result.data.items));
        },

        getByInternalId({ state, commit }, internalId) {
            return api.get(`${state.moduleName}/${internalId}`)
                .then(result => commit('SET_CURRENT_LICENSE', result.data.items));
        },

        getByCustomerId({ state, commit }, customerId) {
            return api.get(`${state.moduleName}/account/${customerId}`)
                .then(result => commit('SET_LICENSES', result.data.items));
        },
    }, // actions
}
