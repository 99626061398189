var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "top-products-wrap section-gap" },
    [
      _c("v-container", { attrs: { "grid-list-xl": "" } }, [
        _c(
          "div",
          {
            staticClass:
              "layout align-baseline justify-space-between sec-wrap ma-0"
          },
          [
            _c("div", { staticClass: "sec-title" }, [
              _c("h3", [_vm._v(_vm._s(_vm.secTitle))])
            ]),
            _c("routerLink", { attrs: { to: "/products" } }, [
              _vm._v("View All")
            ])
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "layout row wrap mb-0" },
          [
            _c(
              "v-container",
              [
                _c(
                  "v-layout",
                  { attrs: { row: "", wrap: "" } },
                  _vm._l(_vm.toBeShown, function(product, index) {
                    return _c(
                      "v-flex",
                      {
                        key: index,
                        attrs: { xs12: "", sm6: "", md6: "", lg3: "", xl3: "" }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "emb-card box-shadow-md pa-6" },
                          [
                            _c("a", { attrs: { href: "javascript:void(0)" } }, [
                              _c("img", {
                                attrs: { src: product.image, alt: "Photo" }
                              })
                            ]),
                            _c(
                              "div",
                              { staticClass: "emb-card-content pt-4" },
                              [
                                _c("p", { staticClass: "mb-0" }, [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "text-muted ",
                                      attrs: { href: "javascript:void(0)" }
                                    },
                                    [_vm._v(_vm._s(product.category))]
                                  )
                                ]),
                                _c("h5", [
                                  _c(
                                    "a",
                                    { attrs: { href: "javascript:void(0)" } },
                                    [_vm._v(" " + _vm._s(product.name))]
                                  )
                                ]),
                                _c(
                                  "div",
                                  { attrs: { fxLayoutAlign: "space-between" } },
                                  [
                                    _c(
                                      "p",
                                      { staticClass: "accent--text" },
                                      [
                                        _c("emb-currency-sign"),
                                        _vm._v(_vm._s(product.price))
                                      ],
                                      1
                                    )
                                  ]
                                ),
                                _vm.ifItemExistInCart(product)
                                  ? _c(
                                      "v-btn",
                                      {
                                        staticClass: "my-0 mx-0",
                                        attrs: { to: "/cart" }
                                      },
                                      [_vm._v(" View Cart ")]
                                    )
                                  : _c(
                                      "v-btn",
                                      {
                                        staticClass: "my-0 mx-0",
                                        on: {
                                          click: function($event) {
                                            return _vm.addProductToCart(product)
                                          }
                                        }
                                      },
                                      [_vm._v(" Add To Cart ")]
                                    )
                              ],
                              1
                            )
                          ]
                        )
                      ]
                    )
                  }),
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "btn-wrap" },
          [
            _vm.currentPage != _vm.totalPages
              ? _c(
                  "v-btn",
                  {
                    staticClass: "transparent-btn mb-0",
                    attrs: { block: "" },
                    on: { click: _vm.nextPage }
                  },
                  [_vm._v("Show All")]
                )
              : _vm._e(),
            _vm.currentPage != 1
              ? _c(
                  "v-btn",
                  {
                    staticClass: "transparent-btn mb-0",
                    attrs: { block: "" },
                    on: { click: _vm.prevPage }
                  },
                  [_vm._v("Less")]
                )
              : _vm._e()
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }