/*eslint-disable*/

import api from '@/api';
import Vue from 'vue';

export default {
    namespaced: true,

    state: {
        moduleName: 'stockHoldings',
        stockHoldings: [],
        selectedImage: undefined,
        currentStockHolding: undefined,
        searchParams: {
            pageSize: 10,
            pageNumber: 1,
            searchValue: undefined, // it must be stockItemHoldingId
            sortField: 'quantityOnHand',
            //sortField: 'stockItemHoldings/stockItems/stockItemName',
            //sortField: 'stockItems/stockItemName',
            // sortField: 'stockItems.stockItemName',
            sortDirection: 'DESC',
        },
        currentStockItemId: 0,
        stockItem: {},
    }, // state

    getters: {
        getStockHoldings: state => state.stockHoldings,
        getCurrentStockHolding: state => state.currentStockHolding,
        getSearchParams: state => state.searchParams,
        getCurrentStockItem: state => state.stockItem,
        getSelectedProductImage: state => state.selectedImage,
    }, // getters

    mutations: {
        SET_STOCK_HOLDINGS(state, data) {
            state.stockHoldings = data.data;
            Vue.set(state.stockHoldings, 'items', data.data.items);
        },
        SET_CURRENT_STOCK_HOLDING(state, currentStockHolding) {
            state.currentStockHolding = currentStockHolding;
        },
        SET_SEARCH_PARAM_PAGE_SIZE(state, pageSize) {
            state.searchParams.pageSize = pageSize;
        },
        SET_SEARCH_PARAM_PAGE_NUMBER(state, pageNumber) {
            state.searchParams.pageNumber = pageNumber;
        },
        SET_SEARCH_PARAM_SEARCH_VALUE(state, searchValue) {
            state.searchParams.searchValue = searchValue;
        },
        SET_SEARCH_PARAM_SORT_FIELD(state, sortField) {
            state.searchParams.sortField = sortField;
        },
        SET_SEARCH_PARAM_SORT_DIRECTION(state, sortDirection) {
            state.searchParams.sortDirection = sortDirection;
        },
        SET_SEARCH_PARAMS(state, searchParams) {
            state.searchParams = searchParams;
        },
        RESET_SEARCH_PARAMS(state) {
            state.searchParams.pageSize = 10;
            state.searchParams.pageNumber = 1;
            state.searchParams.searchValue = undefined;
            state.searchParams.sortField = 'quantityOnHand';
            state.searchParams.sortDirection = 'DESC';
        },
        SET_CURRENT_STOCKITEM(state, data) {
            state.stockItem = data.data;
            state.selectedImage = data.data?.stockItems?.stockItemImages[0]?.location; // add logic later when there are image types.
        },
        SET_CURRENT_PRODUCT_ID(state, data) {
            state.currentStockItemId = data;
        },
        SET_SELECTED_IMAGE(state, data) {
            state.selectedImage = data;
        },
    }, // mutations

    actions: {
        get({ state, commit }) {
            return api.get(`${state.moduleName}`, { params: state.searchParams })
                .then(result => commit('SET_STOCK_HOLDINGS', result));
        },

        getCurrentStockItem({ state, commit }) {
            return api.get(`${state.moduleName}/stockitem/${state.currentStockItemId}`, { params: state.searchParams })
                .then(result => commit('SET_CURRENT_STOCKITEM', result));
        },

        getById({ state, commit }, stockItemHoldingId) {
            return api.get(`${state.moduleName}/${stockItemHoldingId}`)
                .then(result => commit('SET_CURRENT_STOCK_HOLDING', result.data));
        },
        
        getByLocation({ state, commit }) {
            return api.get(`${state.moduleName}/location`, { params: state.searchParams })
                .then(result => commit('SET_STOCK_HOLDINGS', result));
        },
    },
}
