var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-card-text",
        [
          _c(
            "v-list",
            [
              _c(
                "v-list-item",
                {
                  attrs: { disabled: _vm.isView },
                  on: {
                    click: function($event) {
                      _vm.selectedPerson.isPermittedToLogon = !_vm
                        .selectedPerson.isPermittedToLogon
                    }
                  }
                },
                [
                  _c(
                    "v-list-item-action",
                    [
                      _c("v-switch", {
                        attrs: { disabled: _vm.isView },
                        model: {
                          value: _vm.selectedPerson.isPermittedToLogon,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.selectedPerson,
                              "isPermittedToLogon",
                              $$v
                            )
                          },
                          expression: "selectedPerson.isPermittedToLogon"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", [_vm._v("Permitted to logon")]),
                      _c("v-list-item-subtitle", [
                        _vm._v("Wether the user can log into the app")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-list-item",
                {
                  attrs: { disabled: _vm.isView },
                  on: {
                    click: function($event) {
                      _vm.selectedPerson.isExternalLogonProvider = !_vm
                        .selectedPerson.isExternalLogonProvider
                    }
                  }
                },
                [
                  _c(
                    "v-list-item-action",
                    [
                      _c("v-switch", {
                        attrs: { disabled: _vm.isView },
                        model: {
                          value: _vm.selectedPerson.isExternalLogonProvider,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.selectedPerson,
                              "isExternalLogonProvider",
                              $$v
                            )
                          },
                          expression: "selectedPerson.isExternalLogonProvider"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", [
                        _vm._v("External logon provider")
                      ]),
                      _c("v-list-item-subtitle", [
                        _vm._v(
                          "Lorem ipsum, dolor sit amet consectetur adipisicing elit"
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-list-item",
                {
                  attrs: { disabled: _vm.isView },
                  on: {
                    click: function($event) {
                      _vm.selectedPerson.isSystemUser = !_vm.selectedPerson
                        .isSystemUser
                    }
                  }
                },
                [
                  _c(
                    "v-list-item-action",
                    [
                      _c("v-switch", {
                        attrs: { disabled: _vm.isView },
                        model: {
                          value: _vm.selectedPerson.isSystemUser,
                          callback: function($$v) {
                            _vm.$set(_vm.selectedPerson, "isSystemUser", $$v)
                          },
                          expression: "selectedPerson.isSystemUser"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", [_vm._v("System User")]),
                      _c("v-list-item-subtitle", [
                        _vm._v("Read-only access to catalog")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-list-item",
                {
                  attrs: { disabled: _vm.isView },
                  on: {
                    click: function($event) {
                      _vm.selectedPerson.isEmployee = !_vm.selectedPerson
                        .isEmployee
                    }
                  }
                },
                [
                  _c(
                    "v-list-item-action",
                    [
                      _c("v-switch", {
                        attrs: { disabled: _vm.isView },
                        model: {
                          value: _vm.selectedPerson.isEmployee,
                          callback: function($$v) {
                            _vm.$set(_vm.selectedPerson, "isEmployee", $$v)
                          },
                          expression: "selectedPerson.isEmployee"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", [_vm._v("Employee")]),
                      _c("v-list-item-subtitle", [
                        _vm._v("Can view warehouse locations")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-list-item",
                {
                  attrs: { disabled: _vm.isView },
                  on: {
                    click: function($event) {
                      _vm.selectedPerson.isSalesperson = !_vm.selectedPerson
                        .isSalesperson
                    }
                  }
                },
                [
                  _c(
                    "v-list-item-action",
                    [
                      _c("v-switch", {
                        attrs: { disabled: _vm.isView },
                        model: {
                          value: _vm.selectedPerson.isSalesperson,
                          callback: function($$v) {
                            _vm.$set(_vm.selectedPerson, "isSalesperson", $$v)
                          },
                          expression: "selectedPerson.isSalesperson"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", [_vm._v("Salesman")]),
                      _c("v-list-item-subtitle", [
                        _vm._v("Can view user accounts")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-list-item",
                {
                  attrs: { disabled: _vm.isView },
                  on: {
                    click: function($event) {
                      _vm.selectedPerson.isAdmin = !_vm.selectedPerson.isAdmin
                    }
                  }
                },
                [
                  _c(
                    "v-list-item-action",
                    [
                      _c("v-switch", {
                        attrs: { disabled: _vm.isView },
                        model: {
                          value: _vm.selectedPerson.isAdmin,
                          callback: function($$v) {
                            _vm.$set(_vm.selectedPerson, "isAdmin", $$v)
                          },
                          expression: "selectedPerson.isAdmin"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", [_vm._v("Admin")]),
                      _c("v-list-item-subtitle", [
                        _vm._v("Full read/write access to app")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }