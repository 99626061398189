var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "emb-shop-card section-gap title-gap" }, [
    _c(
      "div",
      { staticClass: "container" },
      [
        _c("div", { staticClass: "sec-title" }, [
          _c("h2", [_vm._v(_vm._s(_vm.secTitle))])
        ]),
        _c(
          "slick",
          {
            ref: "carousel",
            staticClass: "shop-card-slider",
            attrs: { options: _vm.slickOptions }
          },
          _vm._l(_vm.data, function(brand, key) {
            return _c(
              "div",
              { key: key, staticClass: "text-center slick-item" },
              [
                _c("router-link", { attrs: { to: "/products" } }, [
                  _c("img", {
                    attrs: {
                      alt: "client-logo",
                      src: brand,
                      width: "250",
                      height: "50"
                    }
                  })
                ])
              ],
              1
            )
          }),
          0
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }