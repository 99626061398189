export default [
   {
      image: "/static/images/cat-shoes.jpg",
      stockItemName: "Anciano Tempranillo 3 Years",
      type: "Red Wine",
      unitPrice: "23.99"
   },
   {
      image: "/static/images/women/9-item-a.jpg",
      stockItemName: "Angeline Cabernet Sauvignon California, 2017",
      type: "Red Wine",
      unitPrice: "14.99"
   },
   {
      image: "/static/images/gadgets/g-5-a.jpg",
      stockItemName: "Cune Rioja Crianza 750ML",
      type: "Still Wine",
      unitPrice: "9.99"
   },
   {
      image: "/static/images/accesories/a-5-a.jpg",
      stockItemName: "Virus Vodka 750ML",
      type: "Spirits",
      unitPrice: "40"
   },
]