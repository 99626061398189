<template>
  <div>
    <AdminPanelHeader
      icon="account_box"
      title="Accounts"
      searchPlaceholder="Search Accounts"
      @search="onSearch"/>

    <CustomersTable
      :isLoading="isLoading"
      :paginatedList="accountsPaginatedList"
      :searchParams="searchParams"
      v-on:search-params-changed="onSearchParamsChanged"/>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  data() {
    return {
      isLoading: true,
      searchValue: undefined,
    };
  }, // data

  computed: {
    ...mapGetters({
      currentLocation: "session/getLocation",
      currentPerson: "session/getPerson",
      accountsPaginatedList: "accounts/getAccounts",
      searchParams: "accounts/getSearchParams",
    }),
  }, // computed

  mounted() {
    let resetSearchParams = this.$route.query.resetSearchParams;
    if (resetSearchParams && resetSearchParams.toString() == true.toString())
      this.resetSearchParams();

    this.searchValue = this.searchParams.searchValue;
    this.getAccountsByLocation().then(() => (this.isLoading = false));
  }, // mounted

  methods: {
    ...mapMutations({
      setSearchValue: "accounts/SET_SEARCH_PARAM_SEARCH_VALUE",
      setSearchParams: "accounts/SET_SEARCH_PARAMS",
      resetSearchParams: "accounts/RESET_SEARCH_PARAMS",
    }),

    ...mapActions({
      getAccountsByLocation: "accounts/getByLocation",
    }),

    executeSearch() {
      this.isLoading = true;
      this.setSearchValue(this.searchValue);

      this.getAccountsByLocation().then(() => (this.isLoading = false));
    },

    onSearch(searchValue) {
      this.searchValue = searchValue
      this.executeSearch()
    },

    onSearchParamsChanged(searchParams) {
      this.isLoading = true;
      this.setSearchParams(searchParams);
      this.setSearchValue(this.searchValue);

      this.getAccountsByLocation().then(() => (this.isLoading = false));
    },
  }, // methods
};
</script>

