<template>
  <div v-if="product">
    <div
      class="emb-product-edit"
      v-if="product.stockItemHoldingId !== undefined && product.stockItems"
    >
      <v-row>
        <v-col cols="12" sm="9" md="10" lg="8" class="mx-auto">
          <v-row>
            <!-- images -->
            <v-col cols="12" sm="12" md="6" lg="6" pl-5>
              <div class="product-images-wrap d-flex">
                <div class="product-images mr-4">
                  <div
                    class="thumb-wrap ml-auto"
                    for="upload"
                    v-for="(img, key) in product.stockItems.stockItemImages"
                    :key="key"
                    @mouseover="doHover(img)"
                  >
                    <v-img
                      :src="img.location"
                      style="height: 70px"
                      :contain="true"
                    />
                  </div>
                </div>
                <div
                  v-if="
                    product.stockItems.stockItemImages &&
                    product.stockItems.stockItemImages.length > 0
                  "
                  class="product-preview-wrap"
                >
                  <v-img
                    :src="selectedImage"
                    max-height="700px"
                    max-width="100%"
                    :contain="true"
                  />
                </div>
                <div
                  v-if="
                    !product.stockItems.stockItemImages ||
                    product.stockItems.stockItemImages.length == 0
                  "
                  class="product-preview-wrap"
                >
                  <v-img
                    :src="pictureUrl"
                    max-height="700px"
                    max-width="100%"
                    :contain="true"
                  />
                </div>
              </div> </v-col
            ><!-- images -->

            <!-- product details and cart button -->
            <v-col cols="12" sm="12" md="6" lg="6" class="content-wrap pl-md-6">
              <!-- back button -->
              <div class="d-flex py-4">
                <span class="font-weight-medium cursor-pointer" @click="back()"
                  >Back to Shop</span
                >
              </div>

              <!-- product name -->
              <h3>{{ product.stockItems.stockItemName }}</h3>

              <!-- product price -->
              <h5 v-if="isProductPurchasable" class="accent--text">
                <emb-currency-sign></emb-currency-sign>
                {{ formatNumber(product.unitPrice) || "XX.XX" }}
              </h5>

              <p v-if="product.depositPrice && product.depositPrice > 0">
                <b>Deposit:</b> <emb-currency-sign></emb-currency-sign>
                {{ formatNumber(product.depositPrice) || "XX.XX" }}
              </p>

              <p class="mb-1">{{ product.stockItems.marketingComments }}</p>
              <p class="mb-1 mt-4"><b>Availability:</b> In Stock</p>
              <p class="mt-1">
                <b>Weight Per Unit:</b>
                {{ product.stockItems.typicalWeightPerUnit }}
                Kg
              </p>
              <p class="mb-1 mt-1">
                <b>Stock: </b> {{ product.quantityOnHand }} Units
              </p>
              <p class="mb-1">
                <b>Product Code:</b> #{{ product.stockItems.internalId }}
              </p>
              <p v-if="product.stockItems.type" class="mb-1">
                <b>Type:</b> {{ product.stockItems.type }}
              </p>
              <p v-if="product.stockItems.subType" class="mb-1">
                Sub Type : {{ product.stockItems.subType }}
              </p>
              <p v-if="product.stockItems.style" class="mb-1">
                Style : {{ product.stockItems.style }}
              </p>
              <p v-if="product.stockItems.materials" class="mb-1">
                Varietal : {{ product.stockItems.materials }}
              </p>
              <p v-if="product.stockItems.alcoholByVolume" class="mb-1">
                ABV : {{ product.stockItems.alcoholByVolume }}
              </p>
              <p v-if="product.stockItems.originRegion" class="mb-1">
                Region : {{ product.stockItems.originRegion }}
              </p>

              <!-- product is not purchasable -->
              <div v-if="!isProductPurchasable" class="mt-6">
                <v-alert dense border="left" type="warning">
                  You are not licensed to purchase this product from us. Please
                  contact your sales rep if you have questions.
                </v-alert>
              </div>

              <!-- add to cart options (only when product is purchasable) -->
              <div v-if="isProductPurchasable" class="mt-6">
                <v-form v-model="isFormValid" class="mb-2">
                  <v-text-field
                    label="Quantity"
                    style="max-width: 150px"
                    type="number"
                    :rules="quantityRules"
                    v-model="quantity"
                  />
                </v-form>

                <v-alert
                  v-if="!isQuantityInRange"
                  dense
                  border="left"
                  type="error"
                >
                  The quantity must be between 0 and 250
                </v-alert>

                <v-alert
                  v-if="isQuantityInRange && isQuantityOverRecommended"
                  dense
                  border="left"
                  type="warning"
                >
                  Your selected quantity seems pretty large.
                </v-alert>

                <!-- add to cart button -->
                <v-btn
                  color="accent"
                  large
                  :disabled="!isFormValid || isAddingToCart"
                  :loading="isAddingToCart"
                  @click="onAddToCartClicked"
                >
                  Add To Cart </v-btn
                ><!-- add to cart button -->
              </div>
              <!-- add to cart options (only when product is purchasable) --> </v-col
            ><!-- product details and cart button -->
          </v-row>
        </v-col>
      </v-row>
    </div>

    <!-- recommended items -->
    <div class="d-block recommended-section pa-4" v-if="product.stockItems">
      <h2 class="text-center">Recommended</h2>

      <product-recommendations
        :category="getProductCategory()"
        :subType="getProductSubType()"
        :productStyle="getProductStyle()"
      >
      </product-recommendations>
    </div>
    <!-- recommended items -->

    <!-- big order warning -->
    <v-dialog v-model="isShowingWarningDialog" max-width="400">
      <v-card>
        <v-card-title>
          <v-icon class="mr-4" style="color: orange">warning</v-icon>
          Warning
        </v-card-title>
        <v-divider />

        <v-card-text>
          <p class="ma-0 pt-5">
            You are placing a large quantity order of
            <strong>{{ quantity }}</strong> items. Are you sure you would like
            to continue?
          </p>
        </v-card-text>

        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn text color="primary" @click="onConfirmBigOrder">Yes</v-btn>
          <v-btn text color="error" @click="isShowingWarningDialog = false"
            >No</v-btn
          >
        </v-card-actions>
      </v-card> </v-dialog
    ><!-- big order warning -->
  </div>
</template>

<script>
/* eslint-disable */

import ProductRecommendations from "./ProductRecommendations";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { currency, products } from "../../../mixins/index";

export default {
  data() {
    return {
      checkbox: false,
      selectedProduct: null,
      type: "",
      id: "",
      isFormValid: false,
      quantity: 1,
      quantities: [1, 2, 3, 4, 5, 10, 15, 20, 25, 50, 100],
      quantityRules: [
        (v) => !!v || "This field is required",
        (v) => (v && v > 0) || "Quantity must be of at least 1",
        (v) => (v && v <= 250) || "Max allowed quantity is 250",
      ],
      pictureUrl: "",
      isAddingToCart: false,
      isShowingWarningDialog: false,
    };
  }, // data

  computed: {
    ...mapGetters({
      eligibilities: "session/getStockGroupEligibilities",
      product: "stockHoldings/getCurrentStockItem",
      selectedImage: "stockHoldings/getSelectedProductImage",
    }),

    isQuantityInRange() {
      return this.quantity >= 1 && this.quantity <= 250;
    },

    isQuantityOverRecommended() {
      return this.quantity >= 100;
    },

    isProductPurchasable() {
      return products.methods.isProductPurchasableForCustomer(
        this.product,
        this.eligibilities
      );
    },

    productPrice() {
      const noPricePlaceholder = "XX.XX";

      if (!this.product) return noPricePlaceholder;

      const unitPrice = this.product.unitPrice;

      if (!unitPrice) return noPricePlaceholder;

      return formatNumber(unitPrice);
    },
  }, // computed

  mounted() {
    this.setProduct({ data: {} });
    this.type = this.$route.params.type;
    this.id = this.$route.params.id;
    this.getPlaceHolderImage();
    this.setCurrentProductId(this.id);
    this.getProductById().then(() => this.onProductLoaded());
  },

  methods: {
    ...mapActions({
      getProductById: "stockHoldings/getCurrentStockItem",
      addProductToCart: "order/addProductToCart2",
    }),
    ...mapMutations({
      setCurrentProductId: "stockHoldings/SET_CURRENT_PRODUCT_ID",
      setSelectedImage: "stockHoldings/SET_SELECTED_IMAGE",
      addToOrderLines: "order/ADD_TO_CART",
      setProduct: "stockHoldings/SET_CURRENT_STOCKITEM",
    }),
    getPlaceHolderImage: function () {
      this.pictureUrl =
        "/static/images/placeholder/" + this.type.toLowerCase() + ".png";
    },

    getProductCategory() {
      let groups = this.product.stockItems.stockItemStockGroups;

      if (!groups || groups.length == 0) return "";

      let stockItemGroup = groups[0];

      if (!stockItemGroup) return "";
      if (!stockItemGroup.stockGroup) return "";

      return stockItemGroup.stockGroup.stockGroupDisplayName;
    },

    getProductStyle() {
      return this.product.stockItems.style;
    },

    getProductSubType() {
      return this.product.stockItems.subType;
    },

    /* for toggling image **/
    doHover(image) {
      this.setSelectedImage(image.location);
    },

    back() {
      window.history?.length > 2 ? this.$router.go(-1) : this.$router.push("/");
    },

    onAddToCartClicked() {
      if (this.isQuantityOverRecommended) {
        this.isShowingWarningDialog = true;
        return;
      }

      this.addToCart();
    },

    onConfirmBigOrder() {
      this.isShowingWarningDialog = false;
      this.addToCart();
    },

    onProductLoaded() {
      console.log("onProductLoaded()", this.product);
    },

    addToCart() {
      this.isAddingToCart = true;

      this.addProductToCart({
        quantity: this.quantity,
        stockItemId: this.product.stockItems.stockItemId,
      })
        .then(() => {
          this.$snotify.success("Product added!", { timeout: 2000 });
        })
        .catch((error) => {
          this.$snotify.error(`Could not add to cart: ${error}`, {
            closeOnClick: true,
            pauseOnHover: true,
            timeout: 4000,
          });
        })
        .finally(() => {
          this.isAddingToCart = false;
          this.isShowingAddToCartDialog = false;
        });
    },
  },

  components: { ProductRecommendations },

  mixins: [currency, products],

  watch: {
    $route: function () {
      this.setProduct({});
      this.type = this.$route.params.type;
      this.id = this.$route.params.id;
      this.getPlaceHolderImage();
      this.setCurrentProductId(this.id);
      this.getProductById();
    },
  },
};
</script>

<style lang="css" scoped>
.recommended-section {
  background-color: #f5f6f7;
}
</style>