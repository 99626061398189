<template>
	<div class="pa-4">
		<v-text-field disabled label="Email" v-model="person.emailAddress"/>
		<v-text-field disabled label="Username" v-model="person.fullName"/>

		<v-row>
			<v-col cols="12" sm="6">
				<v-text-field :disabled="isView" label="First Name" v-model="person.firstName"/>
			</v-col>
			<v-col cols="12" sm="6">
				<v-text-field :disabled="isView" label="Last Name" v-model="person.lastName"/>
			</v-col>
		</v-row>

		<v-text-field :disabled="isView" label="Phone Number" v-model="person.phoneNumber"/>

		<div class="d-flex justify-end mb-4">
			<p class="ma-0">Change my password</p>
		</div>

    	<div v-if="!person.isEmployee" class="d-flex justify-end">
      		<v-btn v-if="isView" color="error" @click="editSettings">Edit profile</v-btn>
      		<v-btn v-if="!isView" color="primary" :disabled="isUpdatingPerson" :loading="isUpdatingPerson" @click="saveSettings">Save profile</v-btn>
    	</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
	data() {
		return {
			isView: true,
			isUpdatingPerson: false,
			checkbox: false,
			otherSelected: true,
    	};
	}, // data

	computed: {
		...mapGetters({
			person: 'session/getPerson',
		}),
	}, // computed

	beforeMount() {

	}, // beforMeMount

	methods: {
		...mapActions({
			getUserProfile: 'session/getUserProfile',
			updatePerson: 'people/update',
		}),
		editSettings() {
			this.isView = false;
		},

		saveSettings() {
			this.isUpdatingPerson = true;

			console.log('Updating person!', this.person);

			this.updatePerson(this.person)
			.then(async () => {
				await this.getUserProfile(this.person.emailAddress);
				this.$router.go();
			})
			.catch(error => console.error(error))
			.finally(() => {
				this.isView = true;
				this.isUpdatingPerson = false
			});
		},
	}, // methods

};
</script>

<style>
.thumb-width {
	width: 70px !important ;
}

.account-text .v-text-field__details {
	display: none;
}

.hoverable:hover {
	cursor: pointer;
}
</style>
