var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex justify-center align-center" },
    [
      _c("v-select", {
        attrs: {
          "hide-details": "",
          disabled: _vm.disabled,
          label: "Price Sorting",
          items: _vm.priceSortingOptions,
          "item-text": "value",
          "item-value": "id"
        },
        on: { change: _vm.onPriceSoringChanged },
        model: {
          value: _vm.priceSorting,
          callback: function($$v) {
            _vm.priceSorting = $$v
          },
          expression: "priceSorting"
        }
      }),
      _c("div", { staticClass: "mx-3" }),
      _c("v-select", {
        attrs: {
          "hide-details": "",
          disabled: _vm.disabled,
          label: "Results per page",
          "item-text": "value",
          "item-value": "id",
          items: _vm.pageSizeOptions
        },
        on: { change: _vm.onPageSizeChanged },
        model: {
          value: _vm.pageSize,
          callback: function($$v) {
            _vm.pageSize = $$v
          },
          expression: "pageSize"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }