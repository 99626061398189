<template>
  <div>
    <Breadcrumbs class="mb-4" :items="breadcrumbs" />

    <div class="center-x" v-if="isLoading">
      <v-skeleton-loader type="text" width="100%" />
    </div>
    <v-card>
      <v-card-title>Trace Type Code</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" v-show="!isAdd">
            <v-text-field
              label="Trace Type Id"
              v-model.number="currentObject.traceTypeId"
              disabled
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              label="Trace Type Description"
              v-model="currentObject.traceTypeDescription"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              label="Trace Type Code"
              v-model="currentObject.traceTypeCode"
            />
          </v-col>
          <v-col cols="12" sm="6" v-show="!isAdd">
            <v-text-field
              label="Last Edited By"
              v-model.number="currentObject.lastEditedBy"
              disabled
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-row>
      <v-col>
        <div class="d-flex justify-end">
          <v-btn color="error" large @click="save">Save</v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import apiClient from "../../../../apiClient";
import { BreadcrumbItem } from "../../../../components/Breadcrumbs/BreadcrumbItem";
import { StaticRoutes } from "../../../../router/Routes";
import { showDialog } from "../../../../components/Dialogs/NotifyDialog";
import { formsMixin } from "../../../../mixins/formsMixin";

export default {
  name: "X12TraceTypesCodesForm",
  mixins: [formsMixin],
  data() {
    return {
      breadcrumbs: [
        new BreadcrumbItem({
          label: "Trace Types Codes",
          route: StaticRoutes.adminPanel.trading.x12Definitions.traceTypesCodes,
        }),
        new BreadcrumbItem({ label: "Trace Type Code Details" }),
      ],
    };
  },
  methods: {
    async getById() {
      try {
        this.isLoading = true;
        this.currentObject = await apiClient.X12("X12TraceTypesCodes").getById(
          this.id
        );
      } catch (error) {
        showDialog("Could not load trace type code.", error);
      } finally {
        this.isLoading = false;
      }
    },

    async save() {
      try {
        this.isLoading = true;
        if (this.isEdit) {
          await apiClient
            .X12("X12TraceTypesCodes")
            .update(this.id, this.currentObject);
          showDialog("Updated Successfully");
        }

        if (this.isAdd) {
          await apiClient.X12("X12TraceTypesCodes").create(this.currentObject);
          showDialog("Created Successfully");
        }
        this.$router.push("/admin-panel/definitions/traceTypesCodes");
      } catch (error) {
        showDialog("Could not update.", error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>