/*eslint-disable*/

import api from '@/api';

export default {
    namespaced: true,
    state: {
        moduleName: 'routes',
        routes: [],
        searchParams: {
            pageSize: 10,
            pageNumber: 1,
            sortField: 'RouteName',
            sortDirection: 'ASC',
            searchValue: undefined,
        }

    },
    getters: {
        getSearchParams: state => state.searchParams,
        getRoutes: state => state.routes
    },
    mutations: {
        SET_ROUTES(state, data) {
            state.routes = data.items;
        },
        SET_SEARCH_PARAMS(state, searchParams) {
            state.searchParams = searchParams;
        },
        RESET_SEARCH_PARAMS(state) {
            state.searchParams.pageSize = 10;
            state.searchParams.pageNumber = 1;
            state.searchParams.sortField = 'RouteName';
            state.searchParams.sortDirection = 'ASC';
            state.searchParams.searchValue = undefined;
        },
        SET_SEARCH_PARAM_SEARCH_VALUE(state, searchValue) {
            state.searchValue = searchValue;
        },
    },

    actions: {

        async get({ state, commit }, searchParams) {
            const response = await api.get(`${state.moduleName}/paginated`, { params: searchParams })
            const routes = response.data;
            console.log("somethin", routes)
            commit('SET_ROUTES', routes)
            return routes;
        },
        async getMessagesById({ state, commit }, id) {
            const response = await api.get(`${state.moduleName}/${id}`)
            return response.data;
        },

    }

}