<template>
    <div class="table-responsive">
        <v-data-table
            :headers="headers"
            :items="reports"
            :options.sync="listViewOptions"
            :loading="isLoading"
            :footer-props="{ 'items-per-page-options': [10, 15, 20, 25, 50] }"
        >
            <template v-slot:[`item.actions`]="{ item }">
                <Menu :menuItems="rowMenuItems" :menuItemContext="item">
                    <v-btn small icon class="primary--text">
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                </Menu>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { MenuItem } from "../../../components/Menu/MenuItem";
import { tableProps } from "../../../mixins/tableProps";

export default {
    mixins: [tableProps],

    data() {
        return {
            rowMenuItems: [
                new MenuItem({
                    icon: "edit",
                    label: "Edit",
                    action: () => {
                        this.$router.push("/admin-panel/reports/edit");
                    },
                }),
                new MenuItem({
                    icon: "visibility",
                    label: "View",
                    action: () => {
                        this.$router.push("/admin-panel/reports/view");
                    },
                }),
            ],
            headers: [
                {
                    text: 'Report Name',
                    value: 'reportName',
                    sortable: false,
                },
                {
                    text: 'Permissions',
                    value: 'permissions',
                    sortable: false,
                },
                {
                    value: 'actions',
                    sortable: false,
                },
            ],
            reports: [],
        }
    }, // data

    mounted() {
        this.reports = this.createDummyReports();
    }, // mounted

    methods: {
        createDummyReports() {
            return Array.from({ length: 10 }, (_, index) => ({
                reportName: `Report #${index + 1}`,
                permissions: 'Admin, Salesman, Employee, ...',
            }));
        },
    }, // methods
}
</script>
