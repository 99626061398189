<template>
  <div class="table-responsive">
    <v-data-table
      :headers="headers"
      :footer-props="{ 'items-per-page-options': [5, 10, 15, 20, 25] }"
    >
      <template v-slot:[`item.x12tradingPartnerId`]="{ item }">
        <v-menu
          :close-on-content-click="false"
          :nudge-width="200"
          offset-x
          transition="slide-x-transition"
          @input="onPartnerIdMenuInput"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-layout
              align-center
              class="app-hover-pointer"
              style="display: inline-block"
              v-bind="attrs"
              v-on="on"
            >
              <span class="mr-2">{{ item.x12tradingPartnerId }}</span>
              <v-icon class="ma-0">mdi-pencil</v-icon>
            </v-layout>
          </template>
          <v-card max-width="400px">
            <v-card-text>
              <h3 class="text-truncate mt-2 mb-6">Trading partner Id</h3>
              <v-text-field
                filled
                readonly
                label="Current Trading Partner Id"
                v-model="item.x12tradingPartnerId"
              />
              <v-text-field
                filled
                :disabled="savingSrsCode == true"
                hide-details
                label="New Partner Id"
                v-model="newPartnerId"
              />
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                :disabled="!isPartnerIdValid || saving == true"
                :loading="savingSrsCode"
                text
                @click="onSaveSrsCodeClicked(item)"
                >Save</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-menu>
      </template>
      <template v-slot:[`item.eventTime`]="{ item }">
        {{ getFormattedDate(item.eventTime) }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
// import apiClient from "../../../../apiClient";
export default {
  name: "UnitMappings",
  data() {
    return {
      saving: false,
      newPartnerId: undefined,
      headers: [
        {
          text: "Internal Id",
          sortable: true,
          value: "InternalId",
        },
        {
          text: "Local Pacakge Type",
          sortable: true,
          value: "LocalPacakgeType",
        },
        {
          text: "External Package Type",
          sortable: true,
          value: "External Package Type",
        },

        {
          text: "Stock Group",
          sortable: true,
          value: "StockGroup",
        },
      ],
    };
  },
  computed: {
    isPartnerIdValid() {
      return (
        this.newPartnerId != undefined &&
        this.newPartnerId != null &&
        this.newPartnerId.trim().length > 0
      );
    },
  },
  methods: {
    async onSaveTradingPartnerId() {
      if (!this.isPartnerIdValid) return;
      try {
        this.saving = true;
      } catch (error) {
        this.saving = false;
      }
      this.saving = false;
    },
    onPartnerIdMenuInput(asd) {
      console.log("something", asd);
    },
  },
};
</script>