<template>
  <div>

    <AdminPanelHeader
      :actions="headerActions"
      icon="shopping_cart"
      title="Products"
      @search="onSearch"/>

    <StockHoldingsListView
      :isLoading="isLoading"
      :paginatedList="stockHoldingsPaginatedList"
      :searchParams="searchParams"
      v-on:search-params-changed="onSearchParamsChanged"/>

  </div>
</template>

<script>
import StockHoldingsListView from './ProductStockHoldingsItemsListView';
import { mapGetters, mapActions, mapMutations } from "vuex";
import { AdminPanelHeaderAction } from '../../../components/AdminPanelHeader/AdminPanelHeaderAction';
import { StaticRoutes } from '../../../router/Routes';

export default {

  data: (thiz) => ({
    isLoading: false,
    searchValue: undefined,
    headerActions: [
      new AdminPanelHeaderAction({
        color: "accent",
        label: "New Product",
        action: () => thiz.$router.push(StaticRoutes.adminPanel.productAdd),
      }),
    ],
  }), // data

  computed: {
    ...mapGetters({
      currentLocation: 'session/getLocation',
      stockHoldingsPaginatedList: 'stockHoldings/getStockHoldings',
      searchParams: 'stockHoldings/getSearchParams',
    }),
  }, // computed

  mounted() {
    let resetSearchParams = this.$route.query.resetSearchParams;
    if (resetSearchParams && resetSearchParams.toString() == true.toString()) {
      this.resetSearchParams();
    }

    this.searchValue = this.searchParams.searchValue;
    this.getStockHoldingsByLocation().then(() => this.isLoading = false);
  }, // mounted

  methods: {
    ...mapMutations({
      setSearchValue: 'stockHoldings/SET_SEARCH_PARAM_SEARCH_VALUE',
      setSearchParams: 'stockHoldings/SET_SEARCH_PARAMS',
      resetSearchParams: 'stockHoldings/RESET_SEARCH_PARAMS',
    }),

    ...mapActions({
      getStockHoldingsByLocation: 'stockHoldings/getByLocation',
    }),
    
    executeSearch() {
      this.isLoading = true;
      this.setSearchValue(this.searchValue);
      this.getStockHoldingsByLocation().then(() => this.isLoading = false);
    },

    onSearch(searchValue) {
      this.searchValue = searchValue;
      this.executeSearch();
    },

    onSearchParamsChanged(searchParams) {
      this.isLoading = true;
      this.setSearchParams(searchParams);
      this.setSearchValue(this.searchValue);

      this.getStockHoldingsByLocation().then(() => this.isLoading = false);
    },
  }, // methods

  components: {
    StockHoldingsListView,
  }, // components
};
</script>
