var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "search-box" },
      [
        _vm.isHidden
          ? _c(
              "v-btn",
              {
                staticClass: "d-inline-block",
                attrs: { fab: "", dark: "", color: "accent" },
                on: {
                  click: function($event) {
                    _vm.isHidden = false
                  }
                }
              },
              [_c("v-icon", [_vm._v("search")])],
              1
            )
          : _vm._e()
      ],
      1
    ),
    !_vm.isHidden
      ? _c("div", { staticClass: "search-form" }, [
          _c(
            "div",
            { staticClass: "form" },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchValue,
                    expression: "searchValue"
                  }
                ],
                attrs: { type: "text", placeholder: "Search Here" },
                domProps: { value: _vm.searchValue },
                on: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.onSearchTriggered($event)
                  },
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.searchValue = $event.target.value
                  }
                }
              }),
              _c(
                "v-btn",
                {
                  staticClass: "close-btn white",
                  on: {
                    click: function($event) {
                      _vm.isHidden = !_vm.isHidden
                    }
                  }
                },
                [_c("v-icon", [_vm._v("close")])],
                1
              )
            ],
            1
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }