<template>
    <div>
        
        <!-- hidden input for file browsing -->
        <input
            id="dragAndDropFileInput"
            type="file"
            :multiple="multiple"
            style="display: none"
            @change="handleFileInput">
        <!-- hidden input for file browsing -->

        <!-- drop zone -->
        <div class="pt-2"
            :class="{ 'pb-4': files.length == 0 }"
            style="border: 3px dashed darkgray; border-radius: 20px"
            @dragover.prevent @drop.prevent @drop="handleFileDrop">

            <!-- file input -->
            <div>
                <p class="text-center">
                    <v-icon size="64" style="color: darkgray">cloud_upload</v-icon>
                </p>

                <h5 class="text-center font-weight-regular">Drag & Drop here</h5>

                <h6 class="text-center font-weight-regular">or</h6>
                
                <div class="d-flex justify-center">
                    <v-btn color="primary" outlined @click="triggerFileInput">Browse Files</v-btn>
                </div>
            </div><!-- file input -->

            <!-- files -->
            <div class="d-flex flex-wrap" :class="{ 'mt-5': files.length > 0 }">
                <v-card v-for="(file, index) in files" :key="index" width="100" height="100" class="mx-3 mb-3">
                    <div class="d-flex justify-end mb-4">
                        <v-btn small icon @click="removeFile(index)">
                            <v-icon>close</v-icon>
                        </v-btn>
                    </div>
                    <p class="text-center ma-0 pa-0">{{file.name}}</p>
                </v-card>
            </div><!-- files -->

        </div><!-- drop zone -->

    </div>
</template>

<script>
export default {

    props: {
        multiple: Boolean,
    }, // props

    data: () => ({
        files: [],
    }), // data

    methods: {
        handleFileDrop(e) {
            let droppedFiles = e.dataTransfer.files;

            if (!droppedFiles || droppedFiles.length == 0)
                return;

            if (this.multiple == true) {
                ([...droppedFiles]).forEach(f => {
                    this.files.push(f);
                });
            }
            else if (this.multiple == false) {
                let singleFile = droppedFiles[0];
                this.files = [ singleFile ];
            }
        },

        handleFileInput(e) {
            let selectedFiles = e.target.files;

            if(!selectedFiles || selectedFiles.length == 0)
                return;
            
            if (this.multiple == false)
                this.files = [];

            ([...selectedFiles]).forEach(f => {
                this.files.push(f);
            });
        },

        removeFile(fileKey){
            this.files.splice(fileKey, 1);
        },

        triggerFileInput() {
            document.getElementById('dragAndDropFileInput').click();
        },
    }, // methods
}
</script>
