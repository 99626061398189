export class AdminPanelHeaderAction {
    /**
     * @type {'primary' | 'accent'}
     */
    color

    /**
     * @type {String}
     */
    icon

    /**
     * @type {String}
     */
    label

    /**
     * @type {Boolean}
     */
    text

    /**
     * @type {Function}
     */
    action

    constructor({ color, icon, label, text, action }) {
        this.color = color
        this.icon = icon
        this.label = label
        this.text = text
        this.action = action
    }
}
