export default {
	"home":"Casa",
	"homeOne":"Casa uno",
	"homeTwo":"Casa dos",
	"homeThree":"Casa tres",
	"userProfile":"Perfil del usuario",
	"shop":"tienda",
	"productDetails": "detalles del producto",
	"cart": "Carro",
	"checkout":"Revisa",
	"payment":"Pago",
	"fashion":"Moda",
	"gadgets":"Gadgets",
	"accessories":"Accesorios",
	"categories":"Categorías",
	"men":"Hombres",
	"jean":"Vaquero",
	"jackets":"Chaquetas",
	"shirt":"Camisa",
	"tShirt":"Camiseta",
	"women":"Mujer",
	"dress":"Vestido",
	"dresses": "Vestidos",
	"headphone":"Auricular",
	"smartphone":"Smartphone",
	"watch":"Reloj",
	"speaker":"Altavoz",
	"laptopAccessories":"Accesorios para laptop",
	"belts":"Cinturones",
	"jewellery":"Joyería",
	"purse":"Bolso",
	"pages":"Páginas",
	"about":"Acerca de",
	"termAndCondition":"Término y condición",
	"privacyPolicy":"Política de privacidad",
	"blogDetail":"Detalle del blog",
	"faq":"Preguntas más frecuentes",
	"Page":"Página",
	"404Page":'Página 404',
	"session":"Sesión",
	"signIn":"Registrarse",
	"register":"Registro",
	"forgotPassword":"Se te olvidó tu contraseña",
	"thankYou":"Gracias",
	"contactUs":"Contáctenos",
	"social":"Social",
	"facebook":"Facebook",
	"twitter":"Gorjeo",
	"youtube":"Youtube",
	"googlePlus":"Google Mas",
	"reports":"Informes",
	"invoices":"Facturas",
	"profile":"Perfil",
	"products":"Productos",
	"productAdd":"Producto añadido",
	"goToSite":"Ir al sitio",
	"adminPanel":"Panel de administrador",
	"edit": "editar"
}