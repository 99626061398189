<template>
  <div>
    <Breadcrumbs class="mb-4" :items="breadcrumbs" />
    <div class="center-x" v-if="isLoading">
      <v-skeleton-loader type="text" width="100%" />
    </div>
    <v-card>
      <v-card-title>Ref Identification Qualifier </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" v-show="!isAdd">
            <v-text-field
              label="Ref Qualifier Id"
              v-model="currentObject.refQualifierId"
              disabled
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              label="Reference Quelifier Description"
              v-model="currentObject.referenceQualifierDescription"
            />
          </v-col>

          <v-col cols="12" sm="6" v-if="!isAdd">
            <v-text-field
              label="Last Edited By"
              v-model="currentObject.lastEditedBy"
              disabled
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              label="Reference Id Qualifier"
              v-model="currentObject.referenceIdqualifier"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-row>
      <v-col>
        <div class="d-flex justify-end">
          <v-btn color="error" large @click="save">Save</v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import apiClient from "../../../../apiClient";
import { BreadcrumbItem } from "../../../../components/Breadcrumbs/BreadcrumbItem";
import { showDialog } from "../../../../components/Dialogs/NotifyDialog";
import { formsMixin } from "../../../../mixins/formsMixin";
import { StaticRoutes } from "../../../../router/Routes";
export default {
  mixins: [formsMixin],
  name: "X12RefIdentificationQualifierForm",
  data() {
    return {
      breadcrumbs: [
        new BreadcrumbItem({
          label: "Ref Identification Qualifier",
          route:
            StaticRoutes.adminPanel.trading.x12Definitions
              .refIdentificationQualifiers,
        }),
        new BreadcrumbItem({ label: "Ref Identification Qualifier Details" }),
      ],
    };
  },
  methods: {
    async getById() {
      try {
        this.isLoading = true;
        this.currentObject = await apiClient
          .X12("X12refIdentificationQualifiers")
          .getById(this.id);
      } catch (error) {
        showDialog("Could not get data", error);
      } finally {
        this.isLoading = false;
      }
    },
    async save() {
      try {
        this.isLoading = true;
        if (this.isEdit) {
          await apiClient
            .X12("X12refIdentificationQualifiers")
            .update(this.id, this.currentObject);
          showDialog("Updated Successfully");
        }
        if (this.isAdd) {
          await apiClient
            .X12("X12refIdentificationQualifiers")
            .create(this.currentObject);
          showDialog("Created Successfully");
        }
        this.$router.push(
          "/admin-panel/definitions/refIdentificationQualifiers"
        );
      } catch (error) {
        showDialog("Could not save file", error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>