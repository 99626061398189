<template>
	<v-container>

		<!-- user profile pic and info -->
		<v-list-item class="mb-6">
			<v-list-item-avatar size="70" @click="isChoosingImage = true">
				<avatar :fullname="person.fullName" :size="70"></avatar>
			</v-list-item-avatar>
			<v-list-item-content>
				<v-list-item-title class="text-h5 mb-2">Hi, {{person.firstName}} {{person.lastName}}</v-list-item-title>
				<v-list-item-subtitle class="text-xl">{{person.emailAddress}}</v-list-item-subtitle>
			</v-list-item-content>
		</v-list-item><!-- user profile pic and info -->

		<!-- card with tabs -->
		<v-card>
			<v-tabs :vertical="verticalTabs" :centered="!verticalTabs" :center-active="!verticalTabs">
				<v-tab class="d-flex flex-column flex-md-row justify-md-start justify-center">
					<v-icon :left="verticalTabs">account_circle</v-icon>
					Profile
				</v-tab>
				<v-tab class="d-flex flex-column flex-md-row justify-md-start justify-center">
					<v-icon :left="verticalTabs">location_on</v-icon>
					Collaboration
				</v-tab>
				<v-tab class="d-flex flex-column flex-md-row justify-md-start justify-center">
					<v-icon :left="verticalTabs">credit_card</v-icon>
					Settings
				</v-tab>
				<v-tab-item>
					<v-card>
						<profile></profile>
					</v-card>
				</v-tab-item>
				<v-tab-item>
					<v-card>
						<collaboration></collaboration>
					</v-card>
				</v-tab-item>
				<v-tab-item>
					<v-card>
						<account-setting></account-setting>
					</v-card>
				</v-tab-item>
			</v-tabs>
		</v-card><!-- card with tabs -->

		<!-- choose image dialog -->
		<v-dialog max-width="400px" class="pa-4" v-model="isChoosingImage">
			<v-card>
				<v-card-text>
					<h5 class="text-center pt-6">Choose an image</h5>
					<v-row>
						<v-col class="d-flex flex-column align-center">
							<p>From Camera</p>
							<v-btn color="primary" dark large fab @click="chooseImage('imageCameraInput')">
								<v-icon>camera_alt</v-icon>
							</v-btn>
							<input @change="onImageSelected" id="imageCameraInput" type="file" accept="image/*" capture="camera" style="display: none"/>
						</v-col>
						<v-col class="d-flex flex-column align-center">
							<p>From Gallery</p>
							<v-btn color="primary" dark large fab @click="chooseImage('imageGalleryInput')">
								<v-icon>collections</v-icon>
							</v-btn>
							<input @change="onImageSelected" id="imageGalleryInput" type="file" accept="image/*" style="display: none"/>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>
		</v-dialog><!-- choose image dialog -->

	</v-container>
</template>

<script>
import Avatar from 'vue-avatar-component';
import Profile from './Profile.vue';
import Collaboration from './Collaboration.vue';
import AccountSetting from './AccountSetting.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
	data() {
		return {
			isView: true,
			verticalTabs: true,
			isChoosingImage: false,
    	};
	}, // data

	computed: {
		...mapGetters({
			person: 'session/getPerson',
			account: 'session/getAccount',
		}),
	}, // computed

	mounted() {
		// TODO Remove next line in production mode
		this.getCurrentEnvironmentInfo().then(result => console.log('Current Environment Info:', result));
		
		this.$nextTick(() => {
			window.addEventListener('resize', () => {
				this.verticalTabs = window.innerWidth >= 1000;
			});
		});
		this.verticalTabs = window.innerWidth >= 1000;
	}, // mounted

	methods: {
		...mapActions({
			getCurrentEnvironmentInfo: 'session/getCurrentEnvironmentInfo',
		}),

		chooseImage(inputId) {
			if (!this.isView) {
				document.getElementById(inputId).click();
			}
		},

		onImageSelected($event) {
			let selectedImages = $event.target.files;

			if (selectedImages.length == 0) {
				return;
			}
			//let selectedImage = selectedImages[0];
			//let selectedImageUrl = URL.createObjectURL(selectedImage);
			this.isChoosingImage = false;
		},
	}, // methods

	components: {
		Profile,
		Collaboration,
		AccountSetting,
		Avatar,
	}, // components
};
</script>
