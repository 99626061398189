<template>
    <div>
        <AdminPanelHeader
            icon="mdi-poll"
            title="Reports"
            :actions="headerActions"
            @search="onSearch"
        />

        <reports-list-view
            :isLoading="isLoading"
            :searchParams="paginatedQueryParams"
        />
    </div>
</template>

<script>
import ReportsListView from './ReportsListView';
import { AdminPanelHeaderAction } from '../../../components/AdminPanelHeader/AdminPanelHeaderAction';
import { parentComponentMixin } from "../../../mixins/parentComponentMixin";

export default {
    mixins: [parentComponentMixin],
    components:{
        ReportsListView,
    },
    data() {
        return {
            headerActions: [
                new AdminPanelHeaderAction({
                    color: "accent",
                    label: "Add new",
                    icon: "add",
                    action: () => this.$router.push("/admin-panel/reports/add"),
                }),
            ],
            paginatedQueryParams: {
                pageSize: 10,
                pageNumber: 0,
                searchValue: "",
                sortField: "reportName",
                sortDirection: "DESC",
            },
        }
    },

    methods: {
        onSearch() {
        },

        getPaginated() {

        }
    }
}
</script>
