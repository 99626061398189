var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Breadcrumbs", {
        staticClass: "mb-4",
        attrs: { items: _vm.breadcrumbs }
      }),
      _c(
        "v-card",
        { staticClass: "mb-4" },
        [
          _c(
            "v-tabs",
            {
              attrs: {
                vertical: _vm.verticalTabs,
                centered: !_vm.verticalTabs,
                "center-active": !_vm.verticalTabs
              }
            },
            [
              _c(
                "v-tab",
                {
                  staticClass:
                    "d-flex flex-column flex-md-row justify-md-start justify-center"
                },
                [
                  _c("v-icon", { attrs: { left: _vm.verticalTabs } }, [
                    _vm._v("account_circle")
                  ]),
                  _vm._v(" Overview ")
                ],
                1
              ),
              _c(
                "v-tab",
                {
                  staticClass:
                    "d-flex flex-column flex-md-row justify-md-start justify-center"
                },
                [
                  _c("v-icon", { attrs: { left: _vm.verticalTabs } }, [
                    _vm._v("mdi-view-headline")
                  ]),
                  _vm._v(" Logs ")
                ],
                1
              ),
              _c(
                "v-tab-item",
                [
                  _vm.isLoading
                    ? _c(
                        "div",
                        [
                          _c("v-skeleton-loader", {
                            staticClass: "mb-4",
                            attrs: { type: "text", width: "100%" }
                          }),
                          _c("v-skeleton-loader", {
                            staticClass: "mb-4",
                            attrs: { type: "text", width: "100%" }
                          }),
                          _c("v-skeleton-loader", {
                            staticClass: "mb-4",
                            attrs: { type: "text", width: "100%" }
                          }),
                          _c("v-skeleton-loader", {
                            staticClass: "mb-4",
                            attrs: { type: "text", width: "100%" }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("TradingMessageForm", { attrs: { message: _vm.message } })
                ],
                1
              ),
              _c(
                "v-tab-item",
                [
                  _c("Log", {
                    attrs: { correlationId: _vm.message.x12correlationId }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }