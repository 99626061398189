<template>
  <div class="responsive-table">
    <v-data-table
      :headers="headers"
      :items="paginatedList.items"
      :server-items-length="paginatedList.totalCount"
      :loading="isLoading"
      :options.sync="listViewOptions"
      :footer-props="{ 'items-per-page-options': [10, 15, 20, 25, 50] }"
    >
    <template v-slot:[`item.action`]="{ item }">
        <Menu :menuItems="rowMenuItems" :menuItemContext="item">
          <v-btn
            small
            icon
            class="primary--text" 
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </Menu>
      </template>
    </v-data-table>
    <YesNoDialog ref="yesNoDialog" />
  </div>
</template>

<script>
import { MenuItem } from "../../../../components/Menu/MenuItem";
import { yesNoDialogMixin } from '../../../../mixins/showYesNoDialog';
import { tableProps } from '../../../../mixins/tableProps';
export default {
  mixins: [tableProps,yesNoDialogMixin],
  name: "X12PotypesCodeTable",
  data() {
    return {
      rowMenuItems: [
        new MenuItem({
          icon: "edit",
          label: "Edit",
          action: (item) => {
            this.$router.push(
              "/admin-panel/definitions/potypesCodes/edit/" + item.potypeId
            );
          },
        }),
        new MenuItem({
          icon: "delete",
          label: "Delete",
          action: (item) => {
            this.showDeleteDialog(item.potypeId);
          },
        }),
      ],
      headers: [
        {
          text: "Potype Id",
          sortable: true,
          value: "potypeId",
        },
        {
          text: "Potype Description",
          sortable: true,
          value: "potypeDescription",
        },
        {
          text: "Potype Code",
          sortable: true,
          value: "potypeCode",
        },
        {
          text:"Actions",
          sortable:false,
          value:"action"
        }
      ],
      
    };
  },
};
</script>