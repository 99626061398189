<template>
    <div v-if="product">

        <!-- product card -->
        <div class="product-item-wrap emb-card cursor-pointer">

            <!-- image and button -->
            <div class="image-container pa-5">

                <v-img
                    :src="product.thumbnail"
                    :contain="true"
                    max-height="250px"
                    min-height="250px"
                    @click="goToDetail()"/>

                <!-- add to cart button -->
                <div class="add-to-cart">
                    <v-btn
                        :class="{
                            purchasable: isProductPurchasable,
                            'not-purchasable': !isProductPurchasable,
                        }"
                        small icon
                        @click="isProductPurchasable ? showAddToCartDialog() : showNotPurchasableDialog()">
                        <v-icon>{{ isProductPurchasable ? "shopping_cart" : "priority_high" }}</v-icon>
                    </v-btn>
                </div><!-- add to cart button -->

            </div><!-- image and button -->

            <!-- details -->
            <div class="emb-card-content pa-4" @click="goToDetail()">
            <h5 class="font-weight-medium text-capitalize h5-fixed-lines-3">
                {{ product.name }}
            </h5>
            <div  class="emb-meta-info layout align-center justify-space-between">
                <div class="inline-block">
                <h6>
                    <span v-if="isProductPurchasable" class="accent--text">
                        <emb-currency-sign></emb-currency-sign>{{ formatNumber(product.unitPrice) || "XX.XX" }}
                    </span>
                    <span v-else style="visibility: hidden">Price Not Available</span>
                </h6>
                </div>
            </div>
            </div><!-- details -->

        </div><!-- product card -->

        <!-- add to cart dialog -->
        <v-dialog v-model="isShowingAddToCartDialog" max-width="400px" persistent>
            <v-card>

                <!-- amount -->
                <v-card-text class="pa-5">
                    <v-text-field
                        label="How Many?"
                        type="number"
                        hide-details
                        v-model="quantity"/>
                </v-card-text><!-- amount -->

                <!-- buttons -->
                <v-card-actions>
                    <div class="d-flex justify-end" style="width: 100%">
                        <v-btn
                            color="accent"
                            class="ml-2"
                            :disabled="!isAmountValid() || isAddingToCart"
                            :loading="isAddingToCart"
                            @click="addToCart">
                            Add To Cart
                        </v-btn>
                        <v-btn
                            color="primary"
                            class="ml-2"
                            :disabled="isAddingToCart"
                            @click="isShowingAddToCartDialog = false">
                            Cancel
                        </v-btn>
                    </div>
                </v-card-actions><!-- buttons -->

            </v-card>
        </v-dialog><!-- add to cart dialog -->

        <!-- not purchasable dialog -->
        <v-dialog
            v-model="isShowingNotPurchasableDialog"
            max-width="500px">
            <v-card>

                <v-card-text class="pa-5">
                    <v-alert dense border="left" type="warning">
                        You are not licensed to purchase this product from us. Please contact your sales rep if you have questions.
                    </v-alert>
                </v-card-text>

                <v-card-actions>
                    <div class="d-flex justify-end" style="width: 100%">
                        <v-btn text @click="isShowingNotPurchasableDialog = false">Ok</v-btn>
                    </div>
                </v-card-actions>

            </v-card>
        </v-dialog><!-- not purchasable dialog -->

    </div>
</template>

<script>
/* eslint-disable */

import { mapActions, mapGetters, mapMutations } from "vuex";
import { currency, products } from '../../../mixins/index';

export default {

    props: {
        product: Object,

    }, // props

    data() {
        return {
            isAddingToCart: false,
            isShowingAddToCartDialog: false,
            isShowingNotPurchasableDialog: false,
            quantity: 1,
            productToAdd: {},
        };
    }, // data

    computed: {
        ...mapGetters({
            eligibilities: 'session/getStockGroupEligibilities',
        }),

        isProductPurchasable() {

            if (!this.product.stockGroupIds){    
                return false;
            }

            
            const stockGroupIds = new Set(this.product.stockGroupIds);
            return this.eligibilities.some(eligibility => stockGroupIds.has(eligibility.stockGroupId));
        },
    }, // computed

    mounted() {
        //console.log("product:", this.product);
        
    }, // mounted

    methods: {
        ...mapActions({
            addProductToCart: "order/addProductToCart2",
        }),

        addToCart() {
            this.isAddingToCart = true;

            this.addProductToCart({
                quantity: this.quantity,
                stockItemId: this.product.id,
            })
            .then(() => {
                this.$snotify.success("Product added!", { timeout: 2000 });
            })
            .catch((error) => {
                this.$snotify.error(`Could not add to cart: ${error}`, {
                    closeOnClick: true,
                    pauseOnHover: true,
                    timeout: 4000,
                });
            })
            .finally(() => {
                this.isAddingToCart = false;
                this.isShowingAddToCartDialog = false;
            });
        },

        isAmountValid() {
            return this.quantity && this.quantity > 0;
        },

        goToDetail() {
            let url = `/products/${this.product.categories}/${this.product.id}`;
            this.$router.push(url);
        },

        showAddToCartDialog() {
            this.isShowingAddToCartDialog = true;
        },

        showNotPurchasableDialog() {
            this.isShowingNotPurchasableDialog = true;
        },
    }, // methods

    mixins: [currency],
};
</script>

<style lang="scss" scoped>
.image-container {
  position: relative;
  cursor: pointer;
}
.image-wraper {
  max-height: 250px;
  min-height: 250px;
  overflow: hidden;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.no-max-height {
  max-height: unset !important;
}

.thumb-warp {
  position: relative;

  img {
    transition: all 0.8s ease-in-out;
    max-height: 10pc;
    padding: 1pc;
  }

  .add-to-cart {
    .v-btn {
      z-index: 2;
      bottom: -8%;
    }
  }
}

.purchasable {
  background-color: rgba(230, 20, 20, 1);
}

.not-purchasable {
  background-color: rgba(190, 0, 0, 0.7);
}
</style>