<template>
    <div>
        <AdminPanelHeader
            icon="mdi-hammer-screwdriver"
            title="Trx Handle Codes"
            :actions="headerActions"
            @search="onSearch"
        />

        <X12TrxHandleCodesTable
            :paginatedList="currentEntity"
            :isLoading="isLoading"
            :searchParams="paginatedQueryParams"
            v-on:onDelete="onDelete"
        />
    </div>
</template>

<script>
import apiClient from "../../../../apiClient"
import { AdminPanelHeaderAction } from '../../../../components/AdminPanelHeader/AdminPanelHeaderAction'
import { showDialog } from '../../../../components/Dialogs/NotifyDialog'
import { parentComponentMixin } from '../../../../mixins/parentComponentMixin'

export default {
    name:"X12TrxHandleCodes",
    mixins:[parentComponentMixin],
    data() {
        return {
            headerActions: [
                new AdminPanelHeaderAction({
                    color: "accent",
                    label: "Add new",
                    icon: "add",
                    action: () => this.$router.push("/admin-panel/definitions/trxHandleCodes/add"),
                })
            ],
            paginatedQueryParams: {
                pageSize: 10,
                pageNumber: 0,
                searchValue: "",
                sortField: "trxHandleId",
                sortDirection: "DESC",
            },
        }
    },
    methods: {
        async onDelete(id) {
            try {
                this.isLoading = true;
                await apiClient.X12("X12TrxHandleCodes").delete(id)
                showDialog("Deleted successfully")
                await this.getPaginated();
            }
            catch (error) {
                showDialog("Could not delete record", error)
            }finally{
                this.isLoading = false;
            }
        },
        async getPaginated() {
            try {
                this.isLoading = true
                this.currentEntity = await apiClient.X12("X12TrxHandleCodes").getPaginated(this.paginatedQueryParams)
            } catch (error) {
                showDialog("Could not load Trx Handle Codes data.", error)
            } finally {
                this.isLoading = false
            }
        },
    },
}
</script>
