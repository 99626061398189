<template>
  <div>
    <v-row >
      <v-col cols="12" sm="6">
        <v-text-field
          disabled
          label="Correlation Id"
          v-model="message.x12correlationId"
        />
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          label="Message Process Status"
          v-model="message.x12messageProcessStatus"
          disabled
        />
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          label="Received Status"
          v-model="message.x12messageReceivedStatus"
          disabled
        />
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          label="File Type Version"
          v-model="message.x12messageFileTypeVersion"
          disabled
        />
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          label="Direction"
          v-model="message.x12messageDirection"
          disabled
        />
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          label="Received Time"
          v-model="message.x12messageReceivedTimestamp"
          disabled
        />
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          label="Reveived Validation Status"
          v-model="message.x12messageReveivedValidationStatus"
          disabled
        />
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          label="Message Acknowledgment Status"
          v-model="message.x12messageSentAcknowledgmentStatus"
          disabled
        />
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          label="Correlation Id"
          v-model="message.x12correlationId"
          disabled
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    message: Object,
  },
  name: "TradingMessageForm",
  data() {
    return {
      verticalTabs: true,
    };
  },
  mounted() {
    this.verticalTabs = window.innerWidth >= 960;
    this.$nextTick(() => {
      window.addEventListener("resize", () => {
        this.verticalTabs = window.innerWidth >= 960;
      });
    });
  }, // mounted
};
</script>