var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Breadcrumbs", {
        staticClass: "mb-4",
        attrs: { items: _vm.breadcrumbs }
      }),
      _c(
        "v-card",
        { staticClass: "mb-4" },
        [
          _c(
            "v-tabs",
            {
              attrs: {
                vertical: _vm.verticalTabs,
                centered: !_vm.verticalTabs,
                "center-active": !_vm.verticalTabs
              }
            },
            [
              _c(
                "v-tab",
                {
                  staticClass:
                    "d-flex flex-column flex-md-row justify-md-start justify-center"
                },
                [
                  _c("v-icon", { attrs: { left: _vm.verticalTabs } }, [
                    _vm._v("mdi-information-outline")
                  ]),
                  _vm._v(" Overview ")
                ],
                1
              ),
              _c(
                "v-tab",
                {
                  staticClass:
                    "d-flex flex-column flex-md-row justify-md-start justify-center"
                },
                [
                  _c("v-icon", { attrs: { left: _vm.verticalTabs } }, [
                    _vm._v("mdi-blur")
                  ]),
                  _vm._v(" Unit Mappings ")
                ],
                1
              ),
              _c(
                "v-tab-item",
                { staticClass: "pa-4" },
                [
                  _vm.isLoading
                    ? _c(
                        "div",
                        [
                          _c("v-skeleton-loader", {
                            staticClass: "mb-4",
                            attrs: { type: "text", width: "100%" }
                          }),
                          _c("v-skeleton-loader", {
                            staticClass: "mb-4",
                            attrs: { type: "text", width: "100%" }
                          }),
                          _c("v-skeleton-loader", {
                            staticClass: "mb-4",
                            attrs: { type: "text", width: "100%" }
                          }),
                          _c("v-skeleton-loader", {
                            staticClass: "mb-4",
                            attrs: { type: "text", width: "100%" }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.isLoading
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "6" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Subscription Id",
                                  disabled: _vm.isView
                                },
                                model: {
                                  value: _vm.currentObject.x12subscriptionId,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.currentObject,
                                      "x12subscriptionId",
                                      $$v
                                    )
                                  },
                                  expression: "currentObject.x12subscriptionId"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "6" } },
                            [
                              _c("v-autocomplete", {
                                attrs: {
                                  items: _vm.tradingPartners.items,
                                  "item-value": "x12tradingPartnerId",
                                  "item-text": "x12tradingPartnerId",
                                  label: "x12tradingPartnerId",
                                  disabled: _vm.isView
                                },
                                model: {
                                  value: _vm.currentObject.x12tradingPartnerId,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.currentObject,
                                      "x12tradingPartnerId",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "currentObject.x12tradingPartnerId"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "6" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Chain Id",
                                  disabled: _vm.isView
                                },
                                model: {
                                  value: _vm.currentObject.chainId,
                                  callback: function($$v) {
                                    _vm.$set(_vm.currentObject, "chainId", $$v)
                                  },
                                  expression: "currentObject.chainId"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "6" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Buying Group Id",
                                  disabled: _vm.isView
                                },
                                model: {
                                  value: _vm.currentObject.buyingGroupId,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.currentObject,
                                      "buyingGroupId",
                                      $$v
                                    )
                                  },
                                  expression: "currentObject.buyingGroupId"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "6" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Subscription Description",
                                  disabled: _vm.isView
                                },
                                model: {
                                  value:
                                    _vm.currentObject
                                      .x12subscriptionDescription,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.currentObject,
                                      "x12subscriptionDescription",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "currentObject.x12subscriptionDescription"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c("v-tab-item", [_c("TradingSubscriptionsMappings")], 1)
            ],
            1
          )
        ],
        1
      ),
      !_vm.isView
        ? _c(
            "v-row",
            { attrs: { justify: "end" } },
            [
              _c(
                "v-col",
                { attrs: { "align-self": "end", cols: "auto" } },
                [
                  _c(
                    "v-btn",
                    { attrs: { color: "primary" }, on: { click: _vm.save } },
                    [_vm._v("Save")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }