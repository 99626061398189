/* eslint-disable */

export const search = {
    methods: {

        createMenuItems(productCategories, isAccountSelected, isAnyLicenseActive,isCreditOnHold) {
            let menuItems = [];
            
            if ((isAccountSelected == true && isAnyLicenseActive == true) || isCreditOnHold )
                menuItems.push(homeMenuItem);

            if ((isAccountSelected == true && isAnyLicenseActive == true) || isCreditOnHold )
                menuItems.push(categoriesMenuItem(productCategories));

            if ((isAccountSelected == true && isAnyLicenseActive == true) || isCreditOnHold )
                menuItems.push(myTopProductsMenuItem);

            if ((isAccountSelected == true && isAnyLicenseActive == true) || (isAccountSelected == true && isAnyLicenseActive == false))
                menuItems.push(myAccountMenuItem);

            if ((isAccountSelected == true && isAnyLicenseActive == true) || isCreditOnHold )
                menuItems.push(contactUsMenuItem);

            menuItems.push(adminPanelMeneItem);

            return menuItems;
        },
    }
};

const homeMenuItem = {
    path: '/home',
    name: "message.home",
    icon: "home",
    type: "sub_menu",
};

const categoriesMenuItem = (productCategories) => ({
    path: '/products',
    name: "message.categories",
    icon: 'party_mode',
    type: 'sub_menu',
    children: productCategories.slice(0, 6).map(category => {
        let categoryName = category.trim().toLowerCase();
        return {
            path: `/products/${categoryName}`,
            name: `${categoryName}`,
        };
    }),
});

const myTopProductsMenuItem = {
    path: '/products/top',
    name: "message.mytopproducts",
    type: "sub_menu",
};

const myAccountMenuItem = {
    path: '/account/profile',
    name: "message.myaccount",
    icon: 'party_mode',
    type: 'sub_menu',
    children: [
        {
            path: '/account/order-history',
            name: 'message.myorders'
        }
    ],
};

const contactUsMenuItem = {
    path: '/contact',
    name: "message.contactUs",
    icon: 'perm_contact_calendar',
};

const adminPanelMeneItem = {
    path: '/admin-panel/reports',
    name: "message.adminPanel",
    icon: 'perm_identity',
};