var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "px-sm-4 px-md-12" },
    [
      _vm.isClient
        ? _c(
            "div",
            { staticClass: "mb-4" },
            [
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-icon",
                    { staticClass: "mr-4" },
                    [
                      _c("v-icon", { attrs: { size: "38" } }, [
                        _vm._v("account_box")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", [
                        _c("h5", { staticClass: "mb-0" }, [
                          _vm._v(
                            " (" +
                              _vm._s(_vm.account.internalId) +
                              ") " +
                              _vm._s(_vm.account.customerName) +
                              " "
                          )
                        ])
                      ]),
                      _vm.location
                        ? _c("v-list-item-subtitle", [
                            _c(
                              "h6",
                              { staticClass: "font-weight-regular mb-0" },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.location.locationName) + " "
                                )
                              ]
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      !_vm.isClient
        ? _c("Breadcrumbs", {
            staticClass: "mb-4",
            attrs: { items: _vm.breadcrumbs }
          })
        : _vm._e(),
      _c(
        "v-card",
        [
          _c(
            "v-tabs",
            {
              attrs: {
                vertical: _vm.verticalTabs,
                centered: !_vm.verticalTabs,
                "center-active": !_vm.verticalTabs
              },
              model: {
                value: _vm.activeTab,
                callback: function($$v) {
                  _vm.activeTab = $$v
                },
                expression: "activeTab"
              }
            },
            [
              _c(
                "v-tab",
                {
                  staticClass:
                    "d-flex flex-column flex-md-row justify-md-start justify-center"
                },
                [
                  _c("v-icon", { attrs: { left: _vm.verticalTabs } }, [
                    _vm._v("info")
                  ]),
                  _vm._v("Overview ")
                ],
                1
              ),
              _c(
                "v-tab",
                {
                  staticClass:
                    "d-flex flex-column flex-md-row justify-md-start justify-center"
                },
                [
                  _c("v-icon", { attrs: { left: _vm.verticalTabs } }, [
                    _vm._v("poll")
                  ]),
                  _vm._v("Orders ")
                ],
                1
              ),
              !_vm.isClient
                ? _c(
                    "v-tab",
                    {
                      staticClass:
                        "d-flex flex-column flex-md-row justify-md-start justify-center"
                    },
                    [
                      _c("v-icon", { attrs: { left: _vm.verticalTabs } }, [
                        _vm._v("account_circle")
                      ]),
                      _vm._v("Team ")
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-tab",
                {
                  staticClass:
                    "d-flex flex-column flex-md-row justify-md-start justify-center"
                },
                [
                  _c("v-icon", { attrs: { left: _vm.verticalTabs } }, [
                    _vm._v("location_on")
                  ]),
                  _vm._v("Addresses ")
                ],
                1
              ),
              _c(
                "v-tab",
                {
                  staticClass:
                    "d-flex flex-column flex-md-row justify-md-start justify-center"
                },
                [
                  _c("v-icon", { attrs: { left: _vm.verticalTabs } }, [
                    _vm._v("account_box")
                  ]),
                  _vm._v("Licenses ")
                ],
                1
              ),
              !_vm.isClient
                ? _c(
                    "v-tab",
                    {
                      staticClass:
                        "d-flex flex-column flex-md-row justify-md-start justify-center"
                    },
                    [
                      _c("v-icon", { attrs: { left: _vm.verticalTabs } }, [
                        _vm._v("poll")
                      ]),
                      _vm._v("Surveys ")
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.isClient
                ? _c(
                    "v-tab",
                    {
                      staticClass:
                        "d-flex flex-column flex-md-row justify-md-start justify-center"
                    },
                    [
                      _c("v-icon", { attrs: { left: _vm.verticalTabs } }, [
                        _vm._v("poll")
                      ]),
                      _vm._v("Forms ")
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.isClient
                ? _c(
                    "v-tab",
                    {
                      staticClass:
                        "d-flex flex-column flex-md-row justify-md-start justify-center"
                    },
                    [
                      _c("v-icon", { attrs: { left: _vm.verticalTabs } }, [
                        _vm._v("mdi-road")
                      ]),
                      _vm._v("Routes ")
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-tab-item",
                [
                  _c(
                    "v-card",
                    { attrs: { flat: "" } },
                    [
                      _c("account-basic-info", {
                        attrs: {
                          account: _vm.account,
                          readOnly: _vm.readOnly,
                          isClient: _vm.isClient
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-tab-item",
                [
                  _c(
                    "v-card",
                    { attrs: { flat: "" } },
                    [
                      _c(
                        "v-card-text",
                        [
                          _c("account-orders", {
                            attrs: {
                              account: _vm.account,
                              readOnly: _vm.readOnly
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              !_vm.isClient
                ? _c(
                    "v-tab-item",
                    [
                      _c(
                        "v-card",
                        { attrs: { flat: "" } },
                        [
                          _c(
                            "v-card-text",
                            [
                              _c("account-collaboration", {
                                attrs: {
                                  account: _vm.account,
                                  readOnly: _vm.readOnly
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-tab-item",
                [
                  _c(
                    "v-card",
                    { attrs: { flat: "" } },
                    [
                      _c(
                        "v-card-text",
                        [
                          _c("account-addresses", {
                            attrs: {
                              account: _vm.account,
                              readOnly: _vm.readOnly,
                              isClient: _vm.isClient
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-tab-item",
                [
                  _c(
                    "v-card",
                    { attrs: { flat: "" } },
                    [
                      _c(
                        "v-card-text",
                        [
                          _c("account-licenses", {
                            attrs: {
                              account: _vm.account,
                              readOnly: _vm.readOnly
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              !_vm.isClient
                ? _c(
                    "v-tab-item",
                    [
                      _c(
                        "v-card",
                        { attrs: { flat: "" } },
                        [
                          _c(
                            "v-card-text",
                            [
                              _c("account-surveys", {
                                attrs: {
                                  account: _vm.account,
                                  data: _vm.surveys,
                                  readOnly: _vm.readOnly
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.isClient
                ? _c(
                    "v-tab-item",
                    [
                      _c(
                        "v-card",
                        { attrs: { flat: "" } },
                        [_c("v-card-text", [_c("account-forms-tab")], 1)],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.isClient
                ? _c(
                    "v-tab-item",
                    [
                      _c(
                        "v-card",
                        { attrs: { flat: "" } },
                        [
                          _c(
                            "v-card-text",
                            [
                              _c("PeopleRoutesTable", {
                                attrs: {
                                  isLoading: _vm.isLoading,
                                  paginatedList: _vm.routesPaginatedList,
                                  searchParams: _vm.paginatedQueryParams
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }