// TODO Update paths

export default [
   {
      image: "static/images/home-sliders/Home-Page-Slider-Pure-Gold.jpg",
      subHeading: "Beer in it's organic form",
      heading: "Pure Gold",
      percent:"",
      //offer: "Aged to perfection"
      url: "/search-results/pure%20gold"
   },
   {
      image: "static/images/home-sliders/Home Page Slider Montucky.jpg",
      subHeading: "MONTUCKY",
      heading: "Cold Snack",
      //percent: "",
      offer: "Try it today!",
      url: "/search-results/montucky%20cold%20snacks"
   },
   {
      image: "static/images/home-sliders/Home-Page-Slider-Luchi.jpg",
      subHeading: "LUCHI",
      heading: "Pinot Grigio",
      percent:"",
      offer: "",
      url: "/search-results/luchi"
   },
   {
      image: "static/images/home-sliders/Home-Page-Slider-Karbach-Ranch-Water-Hard-Seltzer.jpg",
      subHeading: "Karbach",
      heading: "Ranch Water",
      percent:"",
      //offer: "Riserva"
      url: "/search-results/karbach%20ranch%20water"
   },
   {
      image: "static/images/home-sliders/Home-Page-Slider-Western-Son-Vodka-Original.jpg",
      subHeading: "Western Son",
      heading: "Texas Vodka",
      percent:" ",
      off: " ",
      url: "/search-results/western%20son"
   }
]