/*eslint-disable*/

import api from '@/api';

export default {
    namespaced: true,

    state: {
        moduleName: 'specialDeals',
    }, // state

    getters: {

    }, // getters

    mutations: {

    }, // mutations

    actions: {
        search({ state, commit }, filters) {
            return api.post(`${state.moduleName}/search`, filters);
        },
    },
}
