<template>
  <div class="table-responsive">
    <template>
      <v-data-table
        :headers="headers"
        :items="paginatedList.items"
        :server-items-length="paginatedList.totalCount"
        :options.sync="listViewOptions"
        :loading="isLoading"
        :footer-props="{ 'items-per-page-options': [5, 10, 15, 20, 25] }"
      >
        <template v-slot:[`item.x12messageReceivedTimestamp`]="{ item }">
          {{ getFormattedDate(item.x12messageReceivedTimestamp) }}
        </template>

        <template v-slot:[`item.x12messageProcessStatus`]="{ item }">
          {{ getShortenString(item.x12messageProcessStatus) }}
        </template>

        <template v-slot:[`item.action`]="{ item }">
          <Menu :menuItems="rowMenuItems" :menuItemContext="item">
            <v-btn small icon class="primary--text">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </Menu>
        </template>
      </v-data-table>
    </template>
  </div>
</template>

<script>
import { MenuItem } from "../../../components/Menu/MenuItem";
import moment from "moment";
import { tableProps } from '../../../mixins/tableProps';

export default {
  mixins:[tableProps],
  data() {
    return {
      rowMenuItems: [
        new MenuItem({
          icon: "visibility",
          label: "View",
          action: (messages) => {
            this.$router.push(
              "/admin-panel/trading-messages/detail/" + messages.x12messageId
            );
          },
        }),
      ],
      headers: [
        {
          text: "Message Id",
          sortable: true,
          value: "x12messageId",
        },

        {
          text: "Received Time",
          sortable: true,
          value: "x12messageReceivedTimestamp",
        },
        {
          text: "Message Direction",
          sortable: true,
          value: "x12messageDirection",
        },
        {
          text: "Message Process Status",
          sortable: true,
          value: "x12messageProcessStatus",
        },
        {
          text:"Actions",
          sortable: false,
          value: "action",
        },
      ],
    };
  },
  methods: {
    getShortenString(message) {
      if (!message) {
        return;
      }
      if (message.length < 30) return message;

      var result = message.slice(0, 80);
      result = result + "...";
      return result;
    },
    getFormattedDate(date) {
      if (!date) {
        return date;
      }
      return moment(new Date(date.toString())).format("MMMM Do YYYY");
    },
  },
};
</script>

