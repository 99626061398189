import { render, staticRenderFns } from "./OrderTotals.vue?vue&type=template&id=4d14761d&"
import script from "./OrderTotals.vue?vue&type=script&lang=js&"
export * from "./OrderTotals.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\a\\1\\s\\Lnf.Portal\\Lnf.Portal.Web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4d14761d')) {
      api.createRecord('4d14761d', component.options)
    } else {
      api.reload('4d14761d', component.options)
    }
    module.hot.accept("./OrderTotals.vue?vue&type=template&id=4d14761d&", function () {
      api.rerender('4d14761d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/OrderTotals.vue"
export default component.exports