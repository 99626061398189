var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "400", persistent: "" },
      model: {
        value: _vm.isDialogOpened,
        callback: function($$v) {
          _vm.isDialogOpened = $$v
        },
        expression: "isDialogOpened"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v(_vm._s(_vm.title))]),
          _c("v-divider"),
          _c("v-card-text", { staticClass: "py-12" }, [
            _c("p", { staticClass: "text-center" }, [
              _vm._v(" " + _vm._s(_vm.message) + " ")
            ]),
            _c(
              "div",
              { staticClass: "d-flex justify-center" },
              [_c("v-progress-circular", { attrs: { indeterminate: "" } })],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }