<template>
  <div>
    <Breadcrumbs class="mb-4" :items="breadcrumbs" />
    <div class="center-x" v-if="isLoading">
      <v-skeleton-loader type="text" width="100%" />
    </div>

    <v-card>
      <v-card-title>Product Service Qualifiers </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" v-show="!isAdd">
            <v-text-field
              label="Product Service QualifierId"
              v-model="currentObject.productServiceQualifierId"
              disabled
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              label="Product Service Qualifier Description"
              v-model="
                currentObject.productServiceQualifierDescription
              "
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              label="Product Service Qualifier"
              v-model="currentObject.productServiceQualifier"
            />
          </v-col>
          <v-col cols="12" sm="6" v-if="!isAdd">
            <v-text-field
              label="Last Edited By"
              v-model="currentObject.lastEditedBy"
              disabled
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-row>
      <v-col>
        <div class="d-flex justify-end">
          <v-btn color="error" large @click="save">Save</v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import apiClient from "../../../../apiClient";
import { BreadcrumbItem } from "../../../../components/Breadcrumbs/BreadcrumbItem";
import { showDialog } from "../../../../components/Dialogs/NotifyDialog";
import { formsMixin } from "../../../../mixins/formsMixin";
import { StaticRoutes } from "../../../../router/Routes";
export default {
  mixins: [formsMixin],
  name: "X12ProductServiceQualifier",
  data() {
    return {
      breadcrumbs: [
        new BreadcrumbItem({
          label: "Product Service Qualifiers",
          route:
            StaticRoutes.adminPanel.trading.x12Definitions
              .productServiceQualifiers,
        }),
        new BreadcrumbItem({ label: "Product Service Qualifiers Details" }),
      ],
    };
  },
  methods: {
    async getById() {
      try {
        this.isLoading = true;
        this.currentObject =
          await apiClient.X12("X12productServiceQualifiers").getById(this.id);
      } catch (error) {
        showDialog("Could not retrieve data", error);
      } finally {
        this.isLoading = false;
      }
    },
    async save() {
      try {
        this.isLoading = true;
        if (this.isAdd) {
          await apiClient.X12("X12productServiceQualifiers").create(
            this.currentObject
          );
          showDialog("Created Successfully");
        }
        if (this.isEdit) {
          await apiClient.X12("X12productServiceQualifiers").update(this.id,
            this.currentObject
          );
          showDialog("Updated Successfully");
        }
          this.$router.push(
            "/admin-panel/definitions/productServiceQualifiers"
          );
      } catch (error) {
        showDialog("Could not save item", error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>