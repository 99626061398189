var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cta-single-banner section-gap pt-0" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "cta text-center" }, [
        _c("div", { staticClass: "overlay-section-overlay cta-image" }, [
          _c("img", {
            attrs: { src: "static/images/bg-sunglass.jpg", alt: "banner-image" }
          }),
          _c(
            "div",
            { staticClass: "cta-content" },
            [
              _c(
                "p",
                [
                  _c("v-icon", { attrs: { color: "white" } }, [
                    _vm._v("brightness_5")
                  ])
                ],
                1
              ),
              _c("h4", { staticClass: "font-weight-bold mb-6 white--text" }, [
                _vm._v("new arrival")
              ]),
              _c("h2", { staticClass: "mb-6 white--text" }, [
                _vm._v("Sunglasses for Everyone")
              ]),
              _c("v-btn", { staticClass: "my-0", attrs: { to: "/products" } }, [
                _vm._v("SHOP NOW")
              ])
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }