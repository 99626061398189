var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "table-responsive" },
    [
      _c("v-data-table", {
        attrs: {
          headers: _vm.headers,
          items: _vm.paginatedList.items,
          "server-items-length": _vm.paginatedList.totalCount,
          options: _vm.listViewOptions,
          loading: _vm.isLoading,
          "footer-props": { "items-per-page-options": [10, 20, 30, 40, 50] }
        },
        on: {
          "update:options": function($event) {
            _vm.listViewOptions = $event
          }
        }
      }),
      _c("YesNoDialog", { ref: "yesNoDialog" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }