var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "headerV2-wrap" }, [
      !_vm.isAnyLicenseActive
        ? _c(
            "div",
            {
              staticClass: "d-flex justify-center align-center pa-1",
              staticStyle: { "background-color": "#f44336" }
            },
            [
              _c("v-icon", { staticClass: "mr-1", attrs: { dark: "" } }, [
                _vm._v("warning")
              ]),
              _c("h6", { staticClass: "font-weight-regular ma-0 pa-0" }, [
                !_vm.isAnyLicenseActive || _vm.isCreditOnHold
                  ? _c(
                      "span",
                      { staticClass: "mr-2", staticStyle: { color: "white" } },
                      [
                        _vm._v(
                          "All of your licenses are expired or your account is on hold"
                        )
                      ]
                    )
                  : _vm._e()
              ]),
              !_vm.isAnyLicenseActive || _vm.isCreditOnHold
                ? _c(
                    "h6",
                    { staticClass: "font-weight-regular ma-0 pa-0" },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/account/profile?tab=licenses" } },
                        [_vm._v("View my licenses")]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "header-wrap" }, [
        _c("div", { staticClass: "top-header-wrap d-block primary" }, [
          _c(
            "div",
            {
              staticClass: "top-header",
              style: { "background-color": _vm.primaryColor }
            },
            [
              _c(
                "v-container",
                { attrs: { "grid-list-xl": "" } },
                [
                  _c(
                    "v-layout",
                    {
                      attrs: {
                        row: "",
                        wrap: "",
                        "align-center": "",
                        "justify-space-between": "",
                        "pa-0": "",
                        "ma-0": ""
                      }
                    },
                    [
                      _c(
                        "v-flex",
                        {
                          attrs: {
                            xs6: "",
                            sm6: "",
                            md4: "",
                            lg3: "",
                            xl3: "",
                            "px-0": ""
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "site-logo d-inline-block" },
                            [
                              _c("router-link", { attrs: { to: "/" } }, [
                                _c("img", {
                                  attrs: {
                                    alt: "site-logo",
                                    height: "85",
                                    src: _vm.appLogo,
                                    width: "240"
                                  }
                                })
                              ])
                            ],
                            1
                          )
                        ]
                      ),
                      _vm.autoCompleteData && _vm.isAnyLicenseActive
                        ? _c(
                            "v-flex",
                            {
                              attrs: {
                                xs12: "",
                                sm7: "",
                                md5: "",
                                lg7: "",
                                xl7: "",
                                "search-v2-wrap": ""
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "search-v2 placeholder",
                                  attrs: {
                                    "data-placeholder":
                                      _vm.autoCompleteData &&
                                      _vm.autoCompleteData.length > 0 &&
                                      _vm.showOptions &&
                                      _vm.autoCompleteData[0].name.includes(
                                        _vm.searchValue
                                      )
                                        ? _vm.autoCompleteData[0].name
                                        : ""
                                  }
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.searchValue,
                                        expression: "searchValue"
                                      }
                                    ],
                                    attrs: {
                                      type: "text",
                                      placeholder: "Enter a keyword to search"
                                    },
                                    domProps: { value: _vm.searchValue },
                                    on: {
                                      keyup: [
                                        function($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          ) {
                                            return null
                                          }
                                          return _vm.search($event)
                                        },
                                        _vm.onKeyAutoComplete
                                      ],
                                      focus: function($event) {
                                        _vm.showOptions = true
                                      },
                                      blur: function($event) {
                                        return _vm.hideOptions()
                                      },
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.searchValue = $event.target.value
                                      }
                                    }
                                  }),
                                  _vm.autoCompleteData &&
                                  _vm.autoCompleteData.length > 0 &&
                                  _vm.showOptions
                                    ? _c(
                                        "div",
                                        { staticClass: "input-select" },
                                        _vm._l(
                                          _vm.autoCompleteData.filter(function(
                                            x
                                          ) {
                                            return (
                                              x.name !=
                                              _vm.autoCompleteData[0].name
                                            )
                                          }),
                                          function(data) {
                                            return _c("div", {
                                              key:
                                                data.name +
                                                Math.random(1 * 100) * 50,
                                              domProps: {
                                                innerHTML: _vm._s(data.name)
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.selectSearchOption(
                                                    data
                                                  )
                                                }
                                              }
                                            })
                                          }
                                        ),
                                        0
                                      )
                                    : _vm._e()
                                ]
                              )
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "v-flex",
                        {
                          attrs: {
                            xs6: "",
                            sm6: "",
                            md3: "",
                            lg2: "",
                            xl2: "",
                            "px-0": ""
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "\n                    notifications\n                    d-flex\n                    align-items-center\n                    justify-end\n                    mb-2 mb-sm-0\n                  "
                            },
                            [
                              _c(
                                "v-toolbar-title",
                                {
                                  staticClass: "d-none d-sm-block mr-1",
                                  on: {
                                    click: function($event) {
                                      _vm.isSwitchingLocation = true
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "white--text font-weight-regular",
                                      attrs: { href: "javascript:void(0)" }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.currentLocation.locationName
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c("v-icon", { attrs: { dark: "" } }, [
                                    _vm._v("arrow_drop_down")
                                  ])
                                ],
                                1
                              ),
                              _vm.isAnyLicenseActive
                                ? _c("emb-cart")
                                : _vm._e(),
                              _c("emb-user-block", {
                                attrs: { userBlockItems: _vm.userBlockItems }
                              })
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex justify-end" },
                            [
                              _c(
                                "v-toolbar-title",
                                {
                                  staticClass: "d-block d-sm-none mr-1",
                                  on: {
                                    click: function($event) {
                                      _vm.isSwitchingLocation = true
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "white--text font-weight-regular",
                                      attrs: { href: "javascript:void(0)" }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.currentLocation.locationName)
                                      )
                                    ]
                                  ),
                                  _c("v-icon", { attrs: { dark: "" } }, [
                                    _vm._v("arrow_drop_down")
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]),
        _c(
          "div",
          {
            staticClass: "bottom-header",
            style: { "background-color": _vm.primaryColor }
          },
          [
            _c("v-container", [
              _c(
                "div",
                { staticClass: "bottom-header-inner" },
                [
                  _c(
                    "div",
                    { staticClass: "menu-alignment" },
                    [_c("emb-menu", { attrs: { menus: _vm.getMenuItems() } })],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "layout align-left responsive-menu" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "toggle-btn",
                          attrs: { icon: "", dark: "" },
                          on: { click: _vm.toggleMobileSidebar }
                        },
                        [
                          _c("i", { staticClass: "material-icons" }, [
                            _vm._v("menu")
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _vm.isAnyLicenseActive
                    ? _c("emb-search", {
                        on: { search: _vm.onMobileSearchTriggered }
                      })
                    : _vm._e()
                ],
                1
              )
            ])
          ],
          1
        )
      ])
    ]),
    _c(
      "div",
      { attrs: { id: "fixedHeader" } },
      [_c("emb-fixed-header", { attrs: { menus: _vm.getMenuItems() } })],
      1
    ),
    _c(
      "div",
      [
        _c(
          "v-dialog",
          {
            attrs: { persistent: "", "max-width": "300" },
            model: {
              value: _vm.isLoggingOut,
              callback: function($$v) {
                _vm.isLoggingOut = $$v
              },
              expression: "isLoggingOut"
            }
          },
          [
            _c(
              "v-card",
              { staticClass: "pa-3" },
              [
                _c(
                  "v-card-text",
                  { staticClass: "d-flex flex-column align-center" },
                  [
                    _c("p", [_vm._v("Logging out")]),
                    _c("v-progress-circular", {
                      attrs: { width: "2", indeterminate: "" }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "v-dialog",
          {
            attrs: { "max-width": "500" },
            model: {
              value: _vm.isSwitchingLocation,
              callback: function($$v) {
                _vm.isSwitchingLocation = $$v
              },
              expression: "isSwitchingLocation"
            }
          },
          [
            _c(
              "v-card",
              [
                _c(
                  "v-card-text",
                  [
                    _vm.currentLocation
                      ? _c(
                          "div",
                          [
                            _c(
                              "h5",
                              { staticClass: "font-weight-regular pt-6 mb-3" },
                              [_vm._v(" Your current location is ")]
                            ),
                            _c(
                              "v-card",
                              { staticClass: "mb-6" },
                              [
                                _c("v-card-title", [
                                  _vm._v(
                                    _vm._s(_vm.currentLocation.locationName)
                                  )
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("h5", { staticClass: "font-weight-regular mb-3" }, [
                      _vm._v("Select another location")
                    ]),
                    _vm._l(_vm.locations, function(location) {
                      return _c(
                        "div",
                        { key: location.locationId },
                        [
                          _c("v-hover", {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var hover = ref.hover
                                    return [
                                      _c(
                                        "v-card",
                                        {
                                          staticClass: "hoverable mb-3",
                                          class: { "on-hover": hover },
                                          attrs: { elevation: hover ? 6 : 2 },
                                          on: {
                                            click: function($event) {
                                              return _vm.onLocationSelected(
                                                location
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("v-card-title", [
                                            _vm._v(
                                              _vm._s(location.locationName)
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ],
                        1
                      )
                    })
                  ],
                  2
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "v-dialog",
          {
            attrs: { persistent: "", "max-width": "300" },
            model: {
              value: _vm.isLoadingLocation,
              callback: function($$v) {
                _vm.isLoadingLocation = $$v
              },
              expression: "isLoadingLocation"
            }
          },
          [
            _c(
              "v-card",
              { staticClass: "pa-3" },
              [
                _c(
                  "v-card-text",
                  { staticClass: "d-flex flex-column align-center" },
                  [
                    _c("p", [_vm._v("Switching location...")]),
                    _c("v-progress-circular", {
                      attrs: { width: "2", indeterminate: "" }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }