<template>
	<div>
		<v-menu transition="scale-transition" offset-overflow nudge-bottom="30" nudge-right="20" min-width="200"
			max-width="200" class="userblock-dropdown" light>
			<template v-slot:activator="{ on }">
				<v-btn fab small v-on="on">
					<avatar :fullname="person.fullName" :size="40"></avatar>
				</v-btn>
			</template>
			<v-list class="user-dropdown-list">
				<v-list-item v-for="item in userBlockItems" :key="item.title" @click="item.onClick">
					<v-icon class="mr-2">{{item.icon}}</v-icon>
					<span>{{item.title}}</span>
				</v-list-item>
			</v-list>
		</v-menu>
	</div>
</template>

<script>
import Avatar from 'vue-avatar-component';
import { mapGetters } from 'vuex';

export default {
	props: ['userBlockItems'],

	computed: {
		...mapGetters({
			person: 'session/getPerson',
		}),
	}, // computed

	mounted() {

	}, // mounted

	components: {
		Avatar,
	}, // components
}
</script>
