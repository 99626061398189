var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      disabled: _vm.isView,
                      label: "Address Line 1 *",
                      rules: _vm.requiredRule
                    },
                    model: {
                      value: _vm.selectedSupplier.postalAddressLine1,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.selectedSupplier,
                          "postalAddressLine1",
                          $$v
                        )
                      },
                      expression: "selectedSupplier.postalAddressLine1"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6" } },
                [
                  _c("v-text-field", {
                    attrs: { disabled: _vm.isView, label: "Address Line 2" },
                    model: {
                      value: _vm.selectedSupplier.postalAddressLin2,
                      callback: function($$v) {
                        _vm.$set(_vm.selectedSupplier, "postalAddressLin2", $$v)
                      },
                      expression: "selectedSupplier.postalAddressLin2"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6" } },
                [
                  _c("v-select", {
                    attrs: {
                      disabled: _vm.isView,
                      label: "City *",
                      rules: _vm.requiredRule,
                      items: _vm.cities,
                      "item-text": "cityName",
                      "item-value": "cityId"
                    },
                    model: {
                      value: _vm.selectedSupplier.postalCityId,
                      callback: function($$v) {
                        _vm.$set(_vm.selectedSupplier, "postalCityId", $$v)
                      },
                      expression: "selectedSupplier.postalCityId"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      disabled: _vm.isView,
                      label: "Postal Code *",
                      rules: _vm.requiredRule,
                      type: "number"
                    },
                    model: {
                      value: _vm.selectedSupplier.postalPostalCode,
                      callback: function($$v) {
                        _vm.$set(_vm.selectedSupplier, "postalPostalCode", $$v)
                      },
                      expression: "selectedSupplier.postalPostalCode"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }