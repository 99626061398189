var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("AdminPanelHeader", {
        attrs: { title: "Indexer", icon: "cached", searchBar: false }
      }),
      _vm._m(0),
      _c(
        "v-layout",
        { staticClass: "mb-6", attrs: { "justify-center": "" } },
        [
          _c(
            "v-btn",
            {
              attrs: {
                color: "accent",
                disabled:
                  _vm.isConnectingToIndexer ||
                  _vm.isConnectionFailed ||
                  _vm.taskRunning
              },
              on: { click: _vm.onRegenerateIndexesClicked }
            },
            [_vm._v(" Re-generate indexes ")]
          )
        ],
        1
      ),
      _vm.isConnectingToIndexer
        ? _c("div", [
            _c(
              "p",
              { staticClass: "text-center" },
              [
                _vm._v(" Please wait. Connecting to Indexer... "),
                _c("v-progress-circular", {
                  staticClass: "ml-2",
                  attrs: { indeterminate: "", size: "28" }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _vm.isConnectionFailed
        ? _c(
            "div",
            [
              _c(
                "v-layout",
                { attrs: { "justify-center": "" } },
                [
                  _c(
                    "v-card",
                    { attrs: { width: "500" } },
                    [
                      _c(
                        "v-card-text",
                        [
                          _c("h2", { staticClass: "text-center error--text" }, [
                            _vm._v("Error!")
                          ]),
                          _c(
                            "h5",
                            {
                              staticClass:
                                "text-center font-weight-regular mb-4"
                            },
                            [_vm._v(" Could not connect to the indexer ")]
                          ),
                          _c(
                            "v-layout",
                            { attrs: { "justify-center": "" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary" },
                                  on: { click: _vm.onRefreshPageClicked }
                                },
                                [_vm._v(" Refresh Page ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.taskRunning
        ? _c(
            "div",
            [
              _c(
                "h5",
                { staticClass: "text-center mb-6" },
                [
                  _vm._v(" Re-generating indexes "),
                  _c("v-progress-circular", {
                    staticClass: "ml-2",
                    attrs: { indeterminate: "" }
                  })
                ],
                1
              ),
              _c("h6", [
                _vm._v(
                  " Re-indexed products (" +
                    _vm._s(_vm.totalCurrentlyIndexed) +
                    " of " +
                    _vm._s(_vm.totalToIndex) +
                    ") "
                )
              ]),
              _c("IndexedProductsTable", {
                attrs: { indexedProducts: _vm.indexedProducts }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "400", persistent: "" },
          model: {
            value: _vm.showSuccessDialog,
            callback: function($$v) {
              _vm.showSuccessDialog = $$v
            },
            expression: "showSuccessDialog"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "pt-5" },
            [
              _c("v-card-text", [
                _c(
                  "div",
                  { staticClass: "d-flex justify-center mb-3" },
                  [
                    _c("v-icon", { attrs: { size: "64", color: "green" } }, [
                      _vm._v("check_circle")
                    ])
                  ],
                  1
                ),
                _c(
                  "h4",
                  {
                    staticClass: "text-center",
                    staticStyle: { color: "green" }
                  },
                  [_vm._v("Indexing Succeeded!")]
                ),
                _c(
                  "h5",
                  { staticClass: "text-center ma-0 font-weight-regular" },
                  [
                    _vm._v(" Indexed "),
                    _c("b", [_vm._v(_vm._s(_vm.totalToIndex))]),
                    _vm._v(" products ")
                  ]
                )
              ]),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-layout",
                    { attrs: { "justify-end": "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: {
                            click: function($event) {
                              _vm.showSuccessDialog = false
                            }
                          }
                        },
                        [_vm._v(" Ok ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", scrollable: "" },
          model: {
            value: _vm.showErrorDialog,
            callback: function($$v) {
              _vm.showErrorDialog = $$v
            },
            expression: "showErrorDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v("Indexing failed!")]),
              _c("v-divider"),
              _c("v-card-text", { staticClass: "py-4" }, [
                _vm._v(" " + _vm._s(_vm.errorMessage) + " ")
              ]),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-layout",
                    { attrs: { "justify-end": "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: {
                            click: function($event) {
                              _vm.showErrorDialog = false
                            }
                          }
                        },
                        [_vm._v(" Close ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("YesNoDialog", { ref: "yesNoDialog" })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", { staticClass: "font-weight-regular mb-6" }, [
      _vm._v(
        " Click the button below to re-generate the indexes for the products. "
      ),
      _c("br"),
      _vm._v(" Generating the indexes will cache all of the products. ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }