export default {
  "home":"Accueil",
  "homeOne":"Première maison",
  "homeTwo":"Maison deux",
  "homeThree":"Maison trois",
  "userProfile":"Compte d'utilisateur",
  "shop":"Boutique",
  "productDetails": "détails du produit",
  "cart" : "Chariot",
  "checkout":"Check-out",
  "payment":"Paiement",
  "fashion":"Mode",
  "gadgets":"Gadgets",
  "accessories":"Accessories",
  "categories":"Catégories",
  "men":"Männer",
  "jean":"Jean",
  "jackets":"Vestes",
  "shirt":"Chemise",
  "tShirt":"T-shirt",
  "women":"Femmes",
  "dress":"Robe",
  "dresses":"Robes",
  "headphone":"casque de musique",
  "smartphone":"Téléphone intelligent",
  "watch":"Regarder",
  "speaker":"Orateur",
  "laptopAccessories":"Accessoires pour ordinateur portabl",
  "belts":"Ceintures",
  "jewellery":"Bijoux",
  "purse":"Bourse",
  "pages":"Pages",
  "about":"Sur",
  "termAndCondition":"Termes et conditions",
  "privacyPolicy":"Politique de confidentialité",
  "blogDetail":"Détail du blog",
  "faq":"FAQ",
  "Page":"Page",
  "404Page":"404 Page",
  "session":"Session",
  "signIn":"Se connecter",
  "register":"Registre",
  "forgotPassword":"Mot de passe oublié",
  "thankYou":"Je vous remercie",
  "contactUs":"Contactez nous",
  "social":"Social",
  "facebook":"Facebook",
  "twitter":"Twitter",
  "youtube":"Youtube",
  "googlePlus":"Google Plus",
  "reports":"Rapports",
	"invoices":"Factures",
	"profile":"Profil",
	"products":"Des produits",
	"productAdd":"Ajout de produit",
  "goToSite":"Aller au site",
  "adminPanel":"panneau d'administration",
  "edit": "modifier"
} 