var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "main" },
      [
        _c(
          "div",
          { staticClass: "banner-wrap" },
          [
            _c("emb-home-main-banner-v2", {
              attrs: { data: _vm.homeBannerV2Data }
            })
          ],
          1
        ),
        _c("card-items", { attrs: { data: _vm.cardData } }),
        _c("section", [
          !_vm.promotedTags
            ? _c("div")
            : _vm.promotedTags && !_vm.productsByPromotedTags
            ? _c(
                "div",
                _vm._l(_vm.promotedTags, function(tag) {
                  return _c(
                    "div",
                    { key: tag.stockItemTagId, staticClass: "mb-16" },
                    [
                      _c("lightening-deals", {
                        attrs: { title: tag.stockItemTagName, isLoading: true }
                      })
                    ],
                    1
                  )
                }),
                0
              )
            : _vm.promotedTags && _vm.productsByPromotedTags
            ? _c(
                "div",
                _vm._l(Object.keys(_vm.productsByPromotedTags), function(
                  tagName
                ) {
                  return _c("div", { key: tagName }, [
                    _vm.productsByPromotedTags[tagName].items &&
                    _vm.productsByPromotedTags[tagName].items.length > 0
                      ? _c(
                          "div",
                          [
                            _c("lightening-deals", {
                              attrs: {
                                title: tagName,
                                items: _vm.productsByPromotedTags[tagName].items
                              },
                              on: {
                                "view-all": _vm.onViewAllClicked,
                                "item-clicked": _vm.onItemClicked,
                                "category-clicked": _vm.onCategoryClicked
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                }),
                0
              )
            : _vm._e()
        ]),
        _c(
          "div",
          { staticClass: "top-brands-wrap-v1" },
          [
            _c("emb-shop-card", {
              staticClass: "pt-16",
              attrs: { secTitle: "Our Brands", data: _vm.topBrandsData }
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }