var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              _c("v-text-field", {
                attrs: { disabled: "", label: "Correlation Id" },
                model: {
                  value: _vm.message.x12correlationId,
                  callback: function($$v) {
                    _vm.$set(_vm.message, "x12correlationId", $$v)
                  },
                  expression: "message.x12correlationId"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              _c("v-text-field", {
                attrs: { label: "Message Process Status", disabled: "" },
                model: {
                  value: _vm.message.x12messageProcessStatus,
                  callback: function($$v) {
                    _vm.$set(_vm.message, "x12messageProcessStatus", $$v)
                  },
                  expression: "message.x12messageProcessStatus"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              _c("v-text-field", {
                attrs: { label: "Received Status", disabled: "" },
                model: {
                  value: _vm.message.x12messageReceivedStatus,
                  callback: function($$v) {
                    _vm.$set(_vm.message, "x12messageReceivedStatus", $$v)
                  },
                  expression: "message.x12messageReceivedStatus"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              _c("v-text-field", {
                attrs: { label: "File Type Version", disabled: "" },
                model: {
                  value: _vm.message.x12messageFileTypeVersion,
                  callback: function($$v) {
                    _vm.$set(_vm.message, "x12messageFileTypeVersion", $$v)
                  },
                  expression: "message.x12messageFileTypeVersion"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              _c("v-text-field", {
                attrs: { label: "Direction", disabled: "" },
                model: {
                  value: _vm.message.x12messageDirection,
                  callback: function($$v) {
                    _vm.$set(_vm.message, "x12messageDirection", $$v)
                  },
                  expression: "message.x12messageDirection"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              _c("v-text-field", {
                attrs: { label: "Received Time", disabled: "" },
                model: {
                  value: _vm.message.x12messageReceivedTimestamp,
                  callback: function($$v) {
                    _vm.$set(_vm.message, "x12messageReceivedTimestamp", $$v)
                  },
                  expression: "message.x12messageReceivedTimestamp"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              _c("v-text-field", {
                attrs: { label: "Reveived Validation Status", disabled: "" },
                model: {
                  value: _vm.message.x12messageReveivedValidationStatus,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.message,
                      "x12messageReveivedValidationStatus",
                      $$v
                    )
                  },
                  expression: "message.x12messageReveivedValidationStatus"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              _c("v-text-field", {
                attrs: { label: "Message Acknowledgment Status", disabled: "" },
                model: {
                  value: _vm.message.x12messageSentAcknowledgmentStatus,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.message,
                      "x12messageSentAcknowledgmentStatus",
                      $$v
                    )
                  },
                  expression: "message.x12messageSentAcknowledgmentStatus"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              _c("v-text-field", {
                attrs: { label: "Correlation Id", disabled: "" },
                model: {
                  value: _vm.message.x12correlationId,
                  callback: function($$v) {
                    _vm.$set(_vm.message, "x12correlationId", $$v)
                  },
                  expression: "message.x12correlationId"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }