<template>
  <div class="table-responsive">
    <v-data-table
      :headers="headers"
      :items="paginatedList.items"
      :server-items-length="paginatedList.totalCount"
      :options.sync="listViewOptions"
      :loading="isLoading"
      :footer-props="{ 'items-per-page-options': [10, 15, 35, 75, 100] }"
    >
      <template v-slot:[`item.message`]="{ item }">
        {{ getShortenString(item.message) }}
      </template>
      <template v-slot:[`item.eventTime`]="{ item }">
        {{ getFormattedDate(item.eventTime) }}
      </template>
    </v-data-table>
  </div>
</template>
<script>
import moment from "moment";
import { tableProps } from '../../../../../mixins/tableProps';
export default {
  mixins:[tableProps],
  name: "LogsTable",
  data() {
    return {
      headers: [
        {
          text: "Message",
          sortable: true,
          value: "message",
        },

        {
          text: "Level",
          sortable: true,
          value: "level",
        },
        {
          text: "EventTime",
          sortable: true,
          value: "eventTime",
        },
        {
          text: "Log Event",
          sortable: true,
          value: "logEvent",
        },
      ],
    };
  },
  methods: {
    getShortenString(message) {
      if (!message) {
        return message;
      }
      return message.slice(0, 50);
    },
    getFormattedDate(date) {
      // return moment.utc(date).format("MMMM Do YYYY");
      return moment(new Date(date.toString())).format("MMMM Do YYYY");
    },
  },
};
</script>

