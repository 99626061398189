<template>
    <v-card>
        <v-card-text>
            <v-list>

                <!-- permission item -->
                <v-list-item :disabled="isView" @click="selectedPerson.isPermittedToLogon = !selectedPerson.isPermittedToLogon">
                    <v-list-item-action>
                        <v-switch :disabled="isView" v-model="selectedPerson.isPermittedToLogon"/>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Permitted to logon</v-list-item-title>
                        <v-list-item-subtitle>Wether the user can log into the app</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item><!-- permission item -->

                <!-- permission item -->
                <v-list-item :disabled="isView" @click="selectedPerson.isExternalLogonProvider = !selectedPerson.isExternalLogonProvider">
                    <v-list-item-action>
                        <v-switch :disabled="isView" v-model="selectedPerson.isExternalLogonProvider"/>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>External logon provider</v-list-item-title>
                        <v-list-item-subtitle>Lorem ipsum, dolor sit amet consectetur adipisicing elit</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item><!-- permission item -->

                <!-- permission item -->
                <v-list-item :disabled="isView" @click="selectedPerson.isSystemUser = !selectedPerson.isSystemUser">
                    <v-list-item-action>
                        <v-switch :disabled="isView" v-model="selectedPerson.isSystemUser"/>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>System User</v-list-item-title>
                        <v-list-item-subtitle>Read-only access to catalog</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item><!-- permission item -->
                
                <!-- permission item -->
                <v-list-item :disabled="isView" @click="selectedPerson.isEmployee = !selectedPerson.isEmployee">
                    <v-list-item-action>
                        <v-switch :disabled="isView" v-model="selectedPerson.isEmployee"/>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Employee</v-list-item-title>
                        <v-list-item-subtitle>Can view warehouse locations</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item><!-- permission item -->

                <!-- permission item -->
                <v-list-item :disabled="isView" @click="selectedPerson.isSalesperson = !selectedPerson.isSalesperson">
                    <v-list-item-action>
                        <v-switch :disabled="isView" v-model="selectedPerson.isSalesperson"/>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Salesman</v-list-item-title>
                        <v-list-item-subtitle>Can view user accounts</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item><!-- permission item -->

                <!-- permission item -->
                <v-list-item :disabled="isView" @click="selectedPerson.isAdmin = !selectedPerson.isAdmin">
                    <v-list-item-action>
                        <v-switch :disabled="isView" v-model="selectedPerson.isAdmin"/>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Admin</v-list-item-title>
                        <v-list-item-subtitle>Full read/write access to app</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item><!-- permission item -->

            </v-list>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    props: ['selectedPerson'],

    data: () => ({
        isEdit: false,
        isView: false,
        isAdd:false,
    }), // data

    beforeMount() {
        this.isEdit = this.$route.path.includes('edit');
        this.isView = this.$route.path.includes('view');
        this.isAdd = this.$route.path.includes('add');
    }, // beforeMount

    mounted() {
        
    }, // mounted
}
</script>
