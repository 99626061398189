<template>
    <div>
        <div class="pagination-container">

            <!-- first -->
            <span
                class="first"
                :class="{ disabled: disabled || currentPageIndex == 1 }"
                @click="firstPage()">
                <v-icon left>mdi-chevron-double-left</v-icon>
            </span><!-- first -->

            <!-- prev -->
            <span
                class="previous"
                :class="{ disabled: disabled || currentPageIndex == 1 }"
                @click="previousPage()">
                <v-icon left>mdi-chevron-left</v-icon>
            </span><!-- prev -->

            <!-- current -->
            <div v-for="index in 7 + leftMostPage" :key="index"
                @click="changePage(index)">
                <a :class="{
                    disabled: disabled,
                    selected: index == currentPageIndex,
                    show: index > totalPageCount || index < leftMostPage,
                }">
                    {{index}}
                </a>
            </div><!-- current -->

            <!-- next -->
            <span
                class="next"
                :class="{ disabled: disabled || currentPageIndex == totalPageCount }"
                @click="nextPage()">
                <v-icon left>mdi-chevron-right</v-icon>
            </span><!-- next -->

            <!-- last -->
            <span
                class="last"
                :class="{ disabled: disabled || currentPageIndex == totalPageCount }"
                @click="lastPage()">
                <v-icon left>mdi-chevron-double-right</v-icon>
            </span><!-- last -->

        </div>
    </div>
</template>

<script>
export default {

    props: {
        pageIndex: {
            type: Number,
            default: 1,
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        totalPageCount: {
            type: Number,
            default: 8,
        },
    },

    data: () => ({
        currentPageIndex: 1,
        leftMostPage: 1,
    }), // data

    mounted() {
        this.currentPageIndex = this.pageIndex;
    },

    methods: {
        emitPageChanged() {
            console.log("emitPageChanged", this.currentPageIndex);
            this.$emit("page-changed", this.currentPageIndex);
        },

        adjustLeftMostPage() {
            if (this.currentPageIndex == 1)
                this.leftMostPage = 1;
            
            if (this.currentPageIndex == this.totalPageCount)
                this.leftMostPage = this.currentPageIndex - 7;

            if (this.currentPageIndex >= this.leftMostPage + 4 && this.leftMostPage <= this.totalPageCount - 7)
                this.leftMostPage++;

            if (this.currentPageIndex <= this.leftMostPage + 2 && this.leftMostPage >= 1)
                this.leftMostPage--;
        },

        changePage(index) {
            this.currentPageIndex = index;
            this.adjustLeftMostPage();
            this.emitPageChanged();
        },

        previousPage() {
            if (this.currentPageIndex == 1)
                return;
            
            this.currentPageIndex -= 1;
            this.adjustLeftMostPage();
            this.emitPageChanged();
        },

        nextPage() {
            if (this.currentPageIndex == this.totalPageCount)
                return;
            
            this.currentPageIndex += 1;
            this.adjustLeftMostPage();
            this.emitPageChanged();
        },

        firstPage() {
            if (this.currentPageIndex == 1)
                return;
            
            this.currentPageIndex = 1;
            this.adjustLeftMostPage();
            this.emitPageChanged();
        },

        lastPage() {
            if (this.currentPageIndex == this.totalPageCount)
                return;
            
            this.currentPageIndex = this.totalPageCount;
            this.adjustLeftMostPage();
            this.emitPageChanged();
        },
    }, // methods
}
</script>
