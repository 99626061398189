<template>
    <div>
        <AdminPanelHeader
            icon="mdi-handshake"
            title="Interchange Trading Partner Types"
            :actions="headerActions"
            @search="onSearch"
        />

        <X12InterchangeTradingPartnerTypesTable
            :paginatedList="currentEntity"
            :isLoading="isLoading"
            :searchParams="paginatedQueryParams"
            v-on:onDelete="onDelete"
        />
    </div>
</template>

<script>
import apiClient from "../../../../apiClient"
import { AdminPanelHeaderAction } from '../../../../components/AdminPanelHeader/AdminPanelHeaderAction';
import { showDialog } from '../../../../components/Dialogs/NotifyDialog';
import { parentComponentMixin } from "../../../../mixins/parentComponentMixin";

export default {
    mixins: [parentComponentMixin],
    data() {
        return {
            headerActions: [
                new AdminPanelHeaderAction({
                    color: "accent",
                    label: "Add new",
                    icon: "add",
                    action: () => this.$router.push("/admin-panel/definitions/interchangeTradingPartnerTypes/add"),
                }),
            ],
            paginatedQueryParams: {
                pageSize: 10,
                pageNumber: 0,
                searchValue: "",
                sortField: "interchangeTradingPartnerTypeId",
                sortDirection: "DESC",
            },
        };
    },

    methods: {
        async onDelete(id) {
            try {
                this.isLoading = true;
                await apiClient.X12("X12InterchangeTradingPartnerTypes").delete(id)
                showDialog("Record deleted successfully")
                await this.getPaginated()
            } catch (error) {
                showDialog('Could not delete item', error)
            } finally {
              this.isLoading = false;
            }
        },

        async getPaginated() {
            try {
                this.isloading = true
                this.currentEntity = await apiClient.X12("X12InterchangeTradingPartnerTypes").getPaginated(this.paginatedQueryParams)
            } catch (error) {
                showDialog('Could not get data.', error)
            } finally {
                this.isloading = false
            }
        },
    },
}
</script>
