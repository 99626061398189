var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "table-responsive" },
    [
      _c("v-data-table", {
        attrs: {
          headers: _vm.headers,
          items: _vm.paginatedList.items,
          "server-items-length": _vm.paginatedList.totalCount,
          options: _vm.listViewOptions,
          loading: _vm.isLoading,
          "footer-props": { "items-per-page-options": [10, 15, 20, 25, 50] }
        },
        on: {
          "update:options": function($event) {
            _vm.listViewOptions = $event
          }
        },
        scopedSlots: _vm._u(
          [
            {
              key: "item.action",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "Menu",
                    {
                      attrs: {
                        menuItems: _vm.rowMenuItems,
                        menuItemContext: item
                      }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "primary--text",
                          attrs: { small: "", icon: "" }
                        },
                        [_c("v-icon", [_vm._v("mdi-dots-vertical")])],
                        1
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ],
          null,
          true
        )
      }),
      _c("YesNoDialog", { ref: "yesNoDialog" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }