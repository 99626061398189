<template>
  <div>
        <CustomersTable :isLoading="isLoading" :paginatedList="paginatedList" :searchParams="searchParams"/>
  </div>
</template>

<script>
export default {
    props: {
        person: {
            type: Object,
        },
    }, // props

    data: () => ({
        paginatedList: {
            items: [],
            totalCount: 0,
        },
        searchParams: {
            pageSize: 10,
            pageNumber: 1,
            sortField: 'fullName',
            sortDirection: 'ASC',
            searchValue: undefined, // searchValue can either be fullName or emailAddress
            showAllPeople: false,
        },
        isLoading: false,
    }), // data

    mounted() {
        console.log("person:", this.person);
    }, // mounted
}
</script>


