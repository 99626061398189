<template>
    <div>

        <!-- subtotal -->
        <div class="d-flex justify-space-between align-center mb-3 px-3">
            <h6 class="font-weight-regular ma-0 mb-2">Subtotal</h6>

            <v-skeleton-loader v-if="isLoadingSpecialDeals" type="text" width="100"/>

            <h5 v-if="!isLoadingSpecialDeals" class="font-weight-regular ma-0">
                <emb-currency-sign></emb-currency-sign>
                {{formatNumber(amountSubtotal || subtotal)}}
            </h5>
        </div><!-- subtotal -->

        <!-- deposits -->
        <div class="d-flex justify-space-between align-center mb-3 px-3">
            <h6 class="font-weight-regular ma-0 mb-2">Deposits</h6>

            <v-skeleton-loader v-if="isLoadingSpecialDeals" type="text" width="100"/>

            <h5 v-if="!isLoadingSpecialDeals" class="font-weight-regular ma-0">
                <emb-currency-sign></emb-currency-sign>
                {{formatNumber(amountDeposits || deposits)}}
            </h5>
        </div><!-- deposits -->

        <!-- discounts -->
        <div class="d-flex justify-space-between align-center mb-3 px-3">
            <h6 class="font-weight-regular ma-0 mb-2">Discounts</h6>

            <v-skeleton-loader v-if="isLoadingSpecialDeals" type="text" width="100"/>

            <h5 v-if="!isLoadingSpecialDeals" class="font-weight-regular ma-0">
                <emb-currency-sign></emb-currency-sign>
                {{formatNumber(amountDiscounts || discounts)}}
            </h5>
        </div><!-- discounts -->

        <v-divider class="my-4"></v-divider>

        <!-- total -->
        <div class="d-flex justify-space-between align-center mb-2 px-3">
            <h4 class="font-weight-regular ma-0 mb-1">Total</h4>

            <v-skeleton-loader v-if="isLoadingSpecialDeals" type="text" width="130"/>

            <h3 v-if="!isLoadingSpecialDeals" class="font-weight-regular ma-0">
                <emb-currency-sign></emb-currency-sign>
                {{formatNumber(amountTotal || total)}}
            </h3>
        </div><!-- total -->

        <v-divider class="mt-4"></v-divider>

    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { currency, utils } from "../mixins";

export default {

    props: {
        order: Object,
        amountSubtotal: {
            type: Number,
            required: false,
        },
        amountDeposits: {
            type: Number,
            required: false,
        },
        amountDiscounts: {
            type: Number,
            required: false,
        },
        amountTotal: {
            type: Number,
            required: false,
        },
        useOrderCustomerId: {
            type: Boolean,
            default: false, // false to use current customer id, true to use the order id
        },
    }, // props

    data: () => ({
        isLoadingSpecialDeals: true,
        specialDeals: {}, // specialDeals[stockItemId] = array of specialDeals
    }),

    mounted() {
        this.getSpecialDealsRequest();
    },

    computed: {
        ...mapGetters({
            currentCustomer: "session/getAccount",
        }),

        deposits() {
            let total = 0;

            for (const orderLine of this.order.orderLines) {
                total += this.getOrderLineDeposit(orderLine);
            }

            return total;
        },

        discounts() {
            let total = 0;

            for (const orderLine of this.order.orderLines) {
                if (this.hasSpecialDeals(orderLine.stockItemId) && this.isDiscountApplied(orderLine)) {
                    total += this.getOrderLineTotalDiscount(orderLine);
                }
            }

            return total;
        },

        subtotal() {
            let total = 0;

            for (const orderLine of this.order.orderLines) {
                total += this.getOrderLineSubTotalOnlyUnitPrice(orderLine);
            }

            return total;
        },

        total() {
            return this.subtotal + this.deposits - this.discounts;
        },

    }, // computed

    methods: {
        ...mapActions({
            getSpecialDeals: "specialDeals/search",
        }),

        getAppliedSpecialDeal(orderLine) {
            const specialDeals = this.getSpecialDealsOfStockItem(orderLine.stockItemId);

            if (specialDeals.length == 1)
                return specialDeals[0];
            
            const sortedSpecialDeals = Object.assign([], specialDeals.sort((a, b) => a.minimumQuantity - b.minimumQuantity));
            const currentQuantity = orderLine.quantity;
            let currentAppliedDeal = sortedSpecialDeals.shift();

            for (const deal of sortedSpecialDeals) {
                if (currentQuantity >= deal.minimumQuantity) {
                    currentAppliedDeal = deal;
                }
            }

            return currentAppliedDeal;
        },

        getMinQuantitiesForDiscount(stockItemId) {
            return this.specialDeals[stockItemId]
                .map(deal => deal.minimumQuantity)
                .sort((a, b) => a - b);
        },

        getOrderLineDeposit(orderLine) {
            const deposit = orderLine.depositPrice;
            const quantity = orderLine.quantity;
            return deposit * quantity;
        },

        getOrderLineTotalDiscount(orderLine) {
            const discount = this.getOrderLineSingleDiscount(orderLine);
            const quantity = orderLine.quantity;
            return discount * quantity;
        },

        getOrderLineSingleDiscount(orderLine) {
            return this.isDiscountApplied(orderLine)
                ? orderLine.baseUnitPrice - this.getAppliedSpecialDeal(orderLine).unitPrice
                : 0;
        },

        /**
         * Use this for global calculating (since subtotal, discounts and deposits are calculated seperately)
         */
        getOrderLineSubTotalOnlyUnitPrice(orderLine) {
            const unitPrice = orderLine.baseUnitPrice;
            const quantity = orderLine.quantity;
            return unitPrice * quantity;
        },

        getSpecialDealsOfStockItem(stockItemId) {
            return this.specialDeals[stockItemId];
        },

        getSpecialDealsRequest() {
            const promises = this.order.orderLines.map(orderLine => {
                const filters = {
                    customerId: this.useOrderCustomerId
                        ? this.order.customerId
                        : this.currentCustomer.customerId,
                    stockItemId: orderLine.stockItem.stockItemId,
                };
    
                return this.getSpecialDeals(filters);
            });

            this.isLoadingSpecialDeals = true;
            this.specialDeals = {};

            Promise.all(promises).then(responses => {
                const specialDealsArrays = responses.map(res => res.data);

                for (const specialDeals of specialDealsArrays) {
                    if (specialDeals.length > 0) {
                        const stockItemId = specialDeals[0].stockItemId;
                        this.specialDeals[stockItemId] = specialDeals;
                    }
                }

                this.isLoadingSpecialDeals = false;

            }).catch(() => this.isLoadingSpecialDeals = false);
        },

        hasSpecialDeals(stockItemId) {
            const specialDeals = this.specialDeals[stockItemId];
            return specialDeals != undefined && specialDeals != null;
        },

        isDiscountApplied(orderLine) {
            if (!this.hasSpecialDeals(orderLine.stockItemId))
                return false;
            
            const currentQuantity = orderLine.quantity;
            return this.getMinQuantitiesForDiscount(orderLine.stockItemId).some(quantity => currentQuantity >= quantity);
        },

    }, // methods

    mixins: [ currency, utils ],
}
</script>