var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.paginatedList
    ? _c(
        "div",
        { staticClass: "table-responsive" },
        [
          _c("v-data-table", {
            attrs: {
              headers: _vm.headers,
              items: _vm.paginatedList.items,
              "server-items-length": _vm.paginatedList.totalCount,
              options: _vm.listViewOptions,
              loading: _vm.isLoading,
              "footer-props": { "items-per-page-options": [10, 20, 30, 40, 50] }
            },
            on: {
              "update:options": function($event) {
                _vm.listViewOptions = $event
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.action",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "v-btn",
                        {
                          staticClass: "primary--text",
                          attrs: { small: "", icon: "" },
                          on: {
                            click: function($event) {
                              return _vm.showLicenseTypeDialog(item, true)
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("edit")])],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "primary--text",
                          attrs: { small: "", icon: "" },
                          on: {
                            click: function($event) {
                              return _vm.showLicenseTypeDialog(item, false)
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("visibility")])],
                        1
                      )
                    ]
                  }
                }
              ],
              null,
              false,
              3380263538
            )
          }),
          _c(
            "v-dialog",
            {
              attrs: { "max-width": "400" },
              model: {
                value: _vm.isShowingLicenseTypeDialog,
                callback: function($$v) {
                  _vm.isShowingLicenseTypeDialog = $$v
                },
                expression: "isShowingLicenseTypeDialog"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-text",
                    [
                      _vm.isEditingLicenseType
                        ? _c("v-card-title", [_vm._v("Editing License")])
                        : _c("v-card-title", [_vm._v("Viewing License")]),
                      _c("v-text-field", {
                        attrs: {
                          label: "License Code",
                          disabled: !_vm.isEditingLicenseType
                        },
                        model: {
                          value: _vm.lastSelectedLicenseType.licenseCode,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.lastSelectedLicenseType,
                              "licenseCode",
                              $$v
                            )
                          },
                          expression: "lastSelectedLicenseType.licenseCode"
                        }
                      }),
                      _c("v-text-field", {
                        attrs: {
                          label: "License Code",
                          disabled: !_vm.isEditingLicenseType
                        },
                        model: {
                          value:
                            _vm.lastSelectedLicenseType.licenseTypeDescription,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.lastSelectedLicenseType,
                              "licenseTypeDescription",
                              $$v
                            )
                          },
                          expression:
                            "lastSelectedLicenseType.licenseTypeDescription"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    { staticClass: "d-flex justify-end" },
                    [
                      _vm.isEditingLicenseType == true
                        ? _c(
                            "v-btn",
                            {
                              attrs: {
                                loading: _vm.isDoingOperation,
                                disabled: _vm.isDoingOperation,
                                color: "primary"
                              },
                              on: { click: _vm.editLicenseType }
                            },
                            [_vm._v("Edit")]
                          )
                        : _vm._e(),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            disabled: _vm.isDoingOperation,
                            color: "error"
                          },
                          on: {
                            click: function($event) {
                              _vm.isShowingLicenseTypeDialog = false
                            }
                          }
                        },
                        [_vm._v("Cancel")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }