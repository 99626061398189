/*eslint-disable*/

import UserPanel from 'Container/UserPanel';

//const HomeV1 = () => import('Views/HomeV1');
const HomeV2 = () => import('Views/HomeV2');
const HomeV3 = () => import('Views/HomeV3');
const Accessory = () => import('Views/Accesory');
const Gadget = () => import('Views/Gadget');
const Product = () => import('Views/ClientPanel/Products/Product');
const TopProducts = () => import('Views/ClientPanel/Products/TopProducts');
const Payment = () => import('Views/Payment/Payment');
const Cart = () => import('Views/Cart');
const Checkout = () => import('Views/Checkout');
const FinalReceipt = () => import('Views/FinalReceipt');
const ProductDetail = () => import('Views/ClientPanel/Products/ProductDetail');
const AboutUs = () => import('Views/AboutUs');
const TermCondiition = () => import('Views/TermsAndCondition');
const Faq = () => import('Views/Faq');
const Account = () => import('Views/UserAccount/Account.vue');
const OrderHistory = () => import('Views/UserAccount/OrderHistory.vue');
const Profile = () => import('Views/UserAccount/Profile.vue');
const Address = () => import('Views/UserAccount/Address.vue');
const Cards = () => import('Views/UserAccount/Cards.vue');
const EditProfile = () => import('Views/UserAccount/EditProfile.vue');
const EditAddress = () => import('Views/UserAccount/EditAddress.vue');
const EditProfileInfo = () => import('Views/UserAccount/EditProfileInfo.vue');
const BlogDetail = () => import('Views/Blogs/BlogDetails');
const ContactUs = () => import('Views/Contact');
const PrivacyPolicy = () => import('Views/PrivacyPolicy');
const Register = () => import('Views/Sessions/Register');
const ForgotPassword = () => import('Views/Sessions/ForgotPassword');
const ThankYou = () => import('Views/Sessions/ThankYou');
const SignIn = () => import('Views/Sessions/SignIn');
const UserProfile = () => import('Views/ClientPanel/UserProfile');
const SwitchAccount = () => import('Views/ClientPanel/SwitchAccount');
const SearchResults = () => import('Views/ClientPanel/SearchResults/SearchResults');

// Guards
import { permissionGuard, permissionGuardNoAccount, permissionGuardExpiredLicense } from './guards';

export default {
	path: '/',
	component: UserPanel,
	redirect: '/home',
	children: [
		{
			path: '/home',
			component: HomeV2,
			meta: {
				header: 1
			},
			beforeEnter: permissionGuard,
		},
		{
			path: '/home-two',
			component: HomeV2,
			meta: {
				header: 2
			},
			beforeEnter: permissionGuard,
		},
		{
			path: '/home-three',
			component: HomeV3,
			meta: {
				header: 3
			},
			beforeEnter: permissionGuard,
		},
		{
			path: '/products/accessories',
			component: Accessory,
			beforeEnter: permissionGuard,
		},
		{
			path: '/gadget',
			component: Gadget,
			beforeEnter: permissionGuard,
		},
		{
			path: '/products/top',
			component: TopProducts,
			name: 'TopProducts',
			beforeEnter: permissionGuard,
		},
		{
			path: '/products/:title',
			component: Product,
			name: 'Products',
			beforeEnter: permissionGuard,
		},
		{
			path: '/products',
			component: Product,
			name: 'Products',
			beforeEnter: permissionGuard,
		},
		{
			path: '/checkout/payment',
			component: Payment,
			beforeEnter: permissionGuard,
		},
		{
			path: '/cart',
			component: Cart,
			beforeEnter: permissionGuard,
		},
		{
			path: '/checkout',
			component: Checkout,
			beforeEnter: permissionGuard,
		},
		{
			path: '/products/:type/:id',
			component: ProductDetail,
			name: 'productDetail',
			beforeEnter: permissionGuard,
		},
		{
			path: '/checkout/final-receipt',
			component: FinalReceipt,
			name: 'FinalReceipt',
			beforeEnter: permissionGuard,
		},
		{
			path: '/about',
			component: AboutUs,
			name: 'about',
			beforeEnter: permissionGuard,
		},
		{
			path: '/term-condition',
			component: TermCondiition,
			name: 'term-condition',
			beforeEnter: permissionGuard,
		},
		{
			path: '/faq',
			component: Faq,
			name: 'Faq',
			beforeEnter: permissionGuard,
		},
		{
			path: '/account',
			component: Account,
			name: 'Account',
			children: [
				{
					path: '/account/order-history', name: 'OrderHistory', component: OrderHistory,
				},
				{
					path: '/account/profile', name: 'Profile', component: Profile,
				},
				{
					path: '/account/address', name: 'Address', component: Address,
				},
				{
					path: '/account/cards', name: 'Cards', component: Cards,
				},
				{
					path: '/account/profile/edit', name: 'EditProfile', component: EditProfile
				},
				{
					path: '/account/profile/edit', name: 'EditAddress', component: EditAddress
				},
				{
					path: '/account/profile/edit', name: 'EditProfileInfo', component: EditProfileInfo,
				},
			],
			beforeEnter: permissionGuardExpiredLicense,
		},
		{
			path: '/blog-detail/:id',
			component: BlogDetail,
			name: 'BlogDetail;',
			beforeEnter: permissionGuard,
		},
		{
			path: '/contact',
			component: ContactUs,
			name: 'ContactUs',
			beforeEnter: permissionGuard,
		},

		{
			path: '/privacy-policy',
			component: PrivacyPolicy,
			name: 'PrivacyPolicy',
			beforeEnter: permissionGuard,
		},
		{
			path: '/session/signup',
			component: Register,
			name: 'Register',
			beforeEnter: permissionGuard,
		},
		{
			path: '/session/forgot-password',
			component: ForgotPassword,
			name: 'ForgotPassword',
			beforeEnter: permissionGuard,
		},
		{
			path: '/session/thank-you',
			component: ThankYou,
			name: 'ThankYou',
			beforeEnter: permissionGuard,
		},
		{
			path: '/session/signin',
			component: SignIn,
			name: 'SignIn',
			beforeEnter: permissionGuard,
		},
		{
			path: '/me/profile',
			component: UserProfile,
			name: 'UserProfile',
			beforeEnter: permissionGuard,
		},
		{
			path: '/switch/account',
			component: SwitchAccount,
			name: 'SwitchAccount',
			beforeEnter: permissionGuardNoAccount,
		},
		{
			path: '/search-results/:searchValue?',
			component: SearchResults,
			name: 'SearchResults',
			beforeEnter: permissionGuardNoAccount,
		},
	]
}

