var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "profile-wrapper emb-card pa-4" }, [
    _c("h4", [_vm._v("Profile Information")]),
    _c(
      "div",
      { staticClass: "pt-4" },
      [
        _vm._l(_vm.profileData, function(info, key) {
          return _c("div", { key: key, staticClass: "proflie-field mb-4" }, [
            _c("label", [_vm._v(_vm._s(info.key) + " :")]),
            _c("span", [_vm._v(_vm._s(info.value))])
          ])
        }),
        _c(
          "router-link",
          {
            attrs: { to: { name: "EditProfileInfo", query: { type: "info" } } }
          },
          [_c("v-btn", { staticClass: "accent mx-0" }, [_vm._v("Edit")])],
          1
        )
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }