var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "table-responsive" },
    [
      _c("v-data-table", {
        attrs: {
          headers: _vm.headers,
          items: _vm.paginatedList.items,
          "server-items-length": _vm.paginatedList.totalCount,
          options: _vm.listViewOptions,
          loading: _vm.isLoading,
          "footer-props": { "items-per-page-options": [10, 20, 30, 40, 50] }
        },
        on: {
          "update:options": function($event) {
            _vm.listViewOptions = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "item.action",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "v-btn",
                  {
                    staticClass: "primary--text",
                    attrs: { small: "", icon: "" },
                    on: {
                      click: function($event) {
                        return _vm.showEditViewDialog(item, false)
                      }
                    }
                  },
                  [_c("v-icon", [_vm._v("visibility")])],
                  1
                )
              ]
            }
          }
        ])
      }),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "400" },
          model: {
            value: _vm.isShowingEditViewDialog,
            callback: function($$v) {
              _vm.isShowingEditViewDialog = $$v
            },
            expression: "isShowingEditViewDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v(" Viewing Stock Group ")]),
              _c("v-divider"),
              _c(
                "v-card-text",
                [
                  _c("div", { staticClass: "mb-4" }),
                  _c("v-text-field", {
                    attrs: { label: "ID", disabled: _vm.isDialogView },
                    model: {
                      value: _vm.selectedStockGroup.stockGroupId,
                      callback: function($$v) {
                        _vm.$set(_vm.selectedStockGroup, "stockGroupId", $$v)
                      },
                      expression: "selectedStockGroup.stockGroupId"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      label: "Alternate ID",
                      disabled: _vm.isDialogView
                    },
                    model: {
                      value: _vm.selectedStockGroup.stockGroupAlternateID,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.selectedStockGroup,
                          "stockGroupAlternateID",
                          $$v
                        )
                      },
                      expression: "selectedStockGroup.stockGroupAlternateID"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      label: "Display Name",
                      disabled: _vm.isDialogView
                    },
                    model: {
                      value: _vm.selectedStockGroup.stockGroupDisplayName,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.selectedStockGroup,
                          "stockGroupDisplayName",
                          $$v
                        )
                      },
                      expression: "selectedStockGroup.stockGroupDisplayName"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      label: "Stock Group Name",
                      disabled: _vm.isDialogView
                    },
                    model: {
                      value: _vm.selectedStockGroup.stockGroupName,
                      callback: function($$v) {
                        _vm.$set(_vm.selectedStockGroup, "stockGroupName", $$v)
                      },
                      expression: "selectedStockGroup.stockGroupName"
                    }
                  })
                ],
                1
              ),
              _c("v-divider"),
              _c("v-card-actions", [
                _c(
                  "div",
                  {
                    staticClass: "d-flex justify-end",
                    staticStyle: { width: "100%" }
                  },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: { text: "", color: "primary" },
                        on: {
                          click: function($event) {
                            _vm.isShowingEditViewDialog = false
                          }
                        }
                      },
                      [_vm._v("Close")]
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }