var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("CustomersTable", {
        attrs: {
          isLoading: _vm.isLoading,
          paginatedList: _vm.paginatedList,
          searchParams: _vm.searchParams
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }