var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "category-v2-wrapper bg-grey section-gap" },
    [
      _c(
        "v-container",
        { attrs: { "grid-list-xl": "" } },
        [
          _c("div", { staticClass: "sec-title" }, [
            _c("h3", { staticClass: "font-weight-light" }, [
              _vm._v(_vm._s(_vm.secTitle))
            ])
          ]),
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            _vm._l(_vm.data, function(product) {
              return _c(
                "v-flex",
                {
                  key: product.id,
                  attrs: {
                    xs12: "",
                    sm6: "",
                    md3: "",
                    lg3: "",
                    xl3: "",
                    "category-v2-list": ""
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "overlay-section-overlay category-v2-list-wrap"
                    },
                    [
                      _c("img", {
                        attrs: { src: product.image, alt: "category" }
                      }),
                      _c("div", { staticClass: "category-v2-content" }, [
                        _c(
                          "h4",
                          { staticClass: "white--text" },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "white--text text-capitalize",
                                attrs: { to: "/products" }
                              },
                              [_vm._v(_vm._s(product.category))]
                            )
                          ],
                          1
                        ),
                        _c("p", { staticClass: "mb-0 white--text" }, [
                          _vm._v(_vm._s(product.total))
                        ])
                      ])
                    ]
                  )
                ]
              )
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }