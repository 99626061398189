/* eslint-disable */

import api from '@/api';

const DefaultSearchParams = {
    pageIndex: 1,
    pageSize: 8,
    priceSorting: null,
    searchValue: "*",
};

export default {
    namespaced: true,

    state: {
        moduleName: 'productSearch',
        searchParams: DefaultSearchParams,
    }, // state

    getters: {
        getSearchParams: state => state.searchParams,
        getPageIndex: state => state.searchParams.pageIndex,
        getPageSize: state => state.searchParams.pageSize,
        getPriceSorting: state => state.searchParams.priceSorting,
        getSearchValue: state => state.searchParams.searchValue,
    }, // getters

    mutations: {
        RESET_SEARCH_PARAMS(state) {
            state.searchParams = DefaultSearchParams;
        },
        SET_SEARCH_PARAMS_PAGE_INDEX(state, pageIndex) {
            state.searchParams.pageIndex = pageIndex;
        },
        SET_SEARCH_PARAMS_PAGE_SIZE(state, pageSize) {
            state.searchParams.pageSize = pageSize;
        },
        SET_SEARCH_PARAMS_PRICE_SORTING(state, priceSorting) {
            state.searchParams.priceSorting = priceSorting;
        },
        SET_SEARCH_PARAMS_SEARCH_VALUE(state, searchValue) {
            state.searchParams.searchValue = searchValue;
        },
        SET_SEARCH_PARAMS(state, searchParams) {
            state.searchParams = searchParams;
        },
    }, // mutations

    actions: {
        search({ state }) {
            const searchParams = state.searchParams != undefined && state.searchParams != null
                ? state.searchParams
                : DefaultSearchParams;
            
            return api.post(`${state.moduleName}`, searchParams);
        },
    }, // actions
}
