import { render, staticRenderFns } from "./X12refIdentificationQualifierForm.vue?vue&type=template&id=4ff0bc70&"
import script from "./X12refIdentificationQualifierForm.vue?vue&type=script&lang=js&"
export * from "./X12refIdentificationQualifierForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\a\\1\\s\\Lnf.Portal\\Lnf.Portal.Web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4ff0bc70')) {
      api.createRecord('4ff0bc70', component.options)
    } else {
      api.reload('4ff0bc70', component.options)
    }
    module.hot.accept("./X12refIdentificationQualifierForm.vue?vue&type=template&id=4ff0bc70&", function () {
      api.rerender('4ff0bc70', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/AdminPanel/X12Definitions/X12refIdentificationQualifiers/X12refIdentificationQualifierForm.vue"
export default component.exports