<template>
    <div>
        <Breadcrumbs class="mb-4" :items="breadcrumbs"/>

        <div class="center-x" v-if="isLoading">
            <v-skeleton-loader type="text" width="100%"/>
        </div>
        <v-card>
            <v-card-title>Interchange Qualifier</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" sm="6" v-show="!isAdd">
                        <v-text-field
                            label="Interchange Qualifier Id"
                            v-model="currentObject.interchangeQualifierId"
                            disabled
                        />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field
                            label="Interchange Qualifier"
                            v-model="currentObject.interchangeQualifier"
                        />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field
                            label="Interchange Qualifier Definition"
                            v-model="currentObject.interchangeQualifierDefinition"
                        />
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-row>
            <v-col>
                <div class="d-flex justify-end">
                    <v-btn color="error" large @click="() => save()">Save</v-btn>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import apiClient from "../../../../apiClient";
import { BreadcrumbItem } from "../../../../components/Breadcrumbs/BreadcrumbItem";
import { showDialog } from '../../../../components/Dialogs/NotifyDialog';
import { StaticRoutes } from "../../../../router/Routes";
import { formsMixin } from '../../../../mixins/formsMixin'

export default {
    mixins:[formsMixin],
    name: "X12InterchangeQualifiersForm",
    data() {
        return {
            breadcrumbs: [
                new BreadcrumbItem({
                    label: "Interchange Qualifiers",
                    route: StaticRoutes.adminPanel.trading.x12Definitions.interchangeQualifiers
                }),
                new BreadcrumbItem({ label: "Interchange Qualifier Details" })
            ],
        }
    },

    methods: {
        async getById() {
            try {
                this.isLoading = true
                this.currentObject = await apiClient.X12("X12InterchangeQualifiers").getById(this.id)
            } catch (error) {
                showDialog("Could not load Interchange Qualifier.", error)
            } finally {
                this.isLoading = false
            }
        },

        async save() {
            try {
                this.isLoading = true
                if (this.isEdit) {
                    await apiClient.X12("X12InterchangeQualifiers").update(this.id, this.currentObject)
                    showDialog('Updated Successfully')
                }
                if (this.isAdd) {
                    await apiClient.X12("X12InterchangeQualifiers").create(this.currentObject)
                    showDialog('Created Successfully')
                }
                this.$router.push("/admin-panel/definitions/interchangeQualifiers")
            } catch (error) {
                showDialog('Could not update.', error)
            } finally {
                this.isLoading = false
            }
        },
    },
}
</script>