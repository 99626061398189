var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "gmap-map",
        {
          staticStyle: { width: "100%", height: "400px" },
          attrs: { center: _vm.center, zoom: 7 }
        },
        _vm._l(_vm.markers, function(m, index) {
          return _c("gmap-marker", {
            key: index,
            attrs: { position: m.position, clickable: true, draggable: true }
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }