/* eslint-disable */

import api from '@/api';

export default {
    namespaced: true,

    state: {
        moduleName: 'catalog',
        colors: [],
        countries: [],
        cities: [],
        locations: [],
        location: {},
        suppliers: [],
        supplierCategories: [],
        deliveryMethods: [],
        packageTypes: [],
        stockItemImageTypes: [],
        stockItemHoldings: [],
        people: [],
    }, // state

    getters: {
        getColors: state => state.colors,
        getCountries: state => state.countries,
        getCities: state => state.cities,
        getLocations: state => state.locations,
        getLocation: state => state.location,
        getSuppliers: state => state.suppliers,
        getSupplierCategories: state => state.supplierCategories,
        getDeliveryMethods: state => state.deliveryMethods,
        getPackageTypes: state => state.packageTypes,
        getStockItemImageTypes: state => state.stockItemImageTypes,
        getStockItemHoldings: state => state.stockItemHoldings,
        getPeople: state => state.people,
    }, // getters

    mutations: {
        SET_COLORS(state, colors) {
            state.colors = colors;
        },
        SET_COUNTRIES(state, countries) {
            state.countries = countries;
        },
        SET_CITIES(state, cities) {
            state.cities = cities;
        },
        SET_LOCATIONS(state, locations) {
            state.locations = locations;
        },
        SET_LOCATION(state, location) {
            state.location = location;
        },
        SET_SUPPLIERS(state, suppliers) {
            state.suppliers = suppliers;
        },
        SET_SUPPLIER_CATEGORIES(state, supplierCategories) {
            state.supplierCategories = supplierCategories;
        },
        SET_DELIVERY_METHODS(state, deliveryMethods) {
            state.deliveryMethods = deliveryMethods;
        },
        SET_PACKAGE_TYPES(state, packageTypes) {
            state.packageTypes = packageTypes;
        },
        SET_STOCK_ITEM_IMAGE_TYPES(state, stockItemImageTypes) {
            state.stockItemImageTypes = stockItemImageTypes;
        },
        SET_STOCK_ITEM_HOLDINGS(state, stockItemHoldings) {
            state.stockItemHoldings = stockItemHoldings;
        },
        SET_PEOPLE(state, people) {
            state.people = people;
        },
    }, // mutations

    actions: {
        requestGetColors({ state, commit }) {
            api.get(`${state.moduleName}/colors`)
            .then(result => {
                commit('SET_COLORS', result.data); // TODO Remove this and uncomment next line
                // commit('SET_COUNTRIES', result.data.items);
            })
            .catch(error => console.error(error));
        },

        requestGetCountries({ state, commit }) {
            api.get(`${state.moduleName}/countries`)
            .then(result => commit('SET_COUNTRIES', result.data.items))
            .catch(error => console.error(error));
        },

        requestGetCities({ state, commit }) {
            api.get(`${state.moduleName}/cities`)
            .then(result => commit('SET_CITIES', result.data.items))
            .catch(error => console.error(error));
        },

        getCityById({ state }, cityId) {
            return api.get(`${state.moduleName}/cities/${cityId}`);
        },

        requestGetLocations({ state, commit }) {
            return api.get(`${state.moduleName}/locations`)
            .then(result => commit('SET_LOCATIONS', result.data.items))
        },

        getLocationById({ state, commit }, locationId) {
            return api.get(`${state.moduleName}/locations/${locationId}`)
            .then(result => commit('SET_LOCATION', result.data));
        },

        requestGetSuppliers({ state, commit }) {
            api.get(`${state.moduleName}/suppliers`)
            .then(result => commit('SET_SUPPLIERS', result.data.items))
            .catch(error => console.error(error));
        },

        requestGetSupplierCategories({ state, commit }) {
            api.get(`${state.moduleName}/supplierCategories`)
            .then(result => commit('SET_SUPPLIER_CATEGORIES', result.data.items))
            .catch(error => console.error(error));
        },

        requestGetDeliveryMethods({ state, commit }) {
            api.get(`${state.moduleName}/deliveryMethods`)
            .then(result => commit('SET_DELIVERY_METHODS', result.data.items))
            .catch(error => console.error(error));
        },

        requestGetPackageTypes({ state, commit }, onSuccessCallback) {
            api.get(`${state.moduleName}/packageTypes`)
            .then(result => {
                commit('SET_PACKAGE_TYPES', result.data); // TODO Remove this and uncomment next line
                // commit('SET_PACKAGE_TYPES', result.data.items);
                onSuccessCallback();
            })
            .catch(error => console.error(error));
        },

        requestGetStockItemImageTypes({ state, commit }) {
            api.get(`${state.moduleName}/stockItemImageTypes`)
            .then(result => commit('SET_STOCK_ITEM_IMAGE_TYPES', result.data))
            .catch(error => console.error(error));
        },
        
        requestStockItemHoldings({ state, commit }, stockItemId) {
            api.get(`product/stockHoldings/${stockItemId}`)
            .then(result => commit('SET_STOCK_ITEM_HOLDINGS', result.data.items))
            .catch(error => console.error(error));
        },

        requestGetPeople({ state, commit }) {
            api.get(`${state.moduleName}/people`)
            .then(result => commit('SET_PEOPLE', result.data.items))
            .catch(error => console.error(error));
        },
    }, // actions
}
