var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "headers" },
        [
          _vm.$route.meta.header != 2 && _vm.$route.meta.header != 3
            ? _c("emb-header-v1")
            : _vm._e()
        ],
        1
      ),
      _c("div", { staticClass: "emb-notFound-wrap section-gap" }, [
        _c("div", { staticClass: "container" }, [
          _c(
            "div",
            { staticClass: "layout align-center justify-center" },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "", sm9: "", md6: "", lg5: "", xl5: "" } },
                [
                  _c(
                    "div",
                    { staticClass: "emb-card text-center pb-12 pa-6" },
                    [
                      _c("h1", [_vm._v("404")]),
                      _c("h5", { staticClass: "mb-4" }, [
                        _vm._v(
                          "We can’t seem to find the page you’re looking for."
                        )
                      ]),
                      _c(
                        "v-btn",
                        {
                          staticClass: "accent",
                          attrs: { block: "", to: "/" }
                        },
                        [_vm._v("Go To Home")]
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ])
      ]),
      _c("emb-footer-V1", {
        attrs: {
          title: "About us",
          description: _vm.aboutUs,
          img: "/static/images/cards.png"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }