<template>
  <div>
    <Breadcrumbs class="mb-4" :items="breadcrumbs" />
    <div class="center-x" v-if="isLoading">
      <v-skeleton-loader type="text" width="100%" />
    </div>

    <v-card>
      <v-card-title>Subscription FileType Associations</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" v-show="!isAdd">
            <v-text-field
              label="Subscription File Type Association Id"
              v-model="currentObject.x12subscriptionFileTypeAssociationId"
              disabled
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              label="Trading Partner Id"
              v-model="currentObject.x12tradingPartnerId"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              label="File Type Version Id"
              v-model="currentObject.x12fileTypeVersionId"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-row>
      <v-col>
        <div class="d-flex justify-end">
          <v-btn color="error" large @click="save">Save</v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import apiClient from '../../../../../apiClient';
import { BreadcrumbItem } from '../../../../../components/Breadcrumbs/BreadcrumbItem';
import { showDialog } from '../../../../../components/Dialogs/NotifyDialog';
import { formsMixin } from '../../../../../mixins/formsMixin';
import { StaticRoutes } from '../../../../../router/Routes';
export default {
  name: "SubscriptionFileTypeAssociationsForm",
  mixins:[formsMixin],
  data() {
    return{
      breadcrumbs: [
        new BreadcrumbItem({
          label: "",
          route: StaticRoutes.adminPanel.trading.subscriptionFileTypeAssociation,
        }),
        new BreadcrumbItem({ label: "Subscription File Type Asociation" }),
      ],
      paginatedQueryParams: {
        pageSize: 10,
        pageNumber: 0,
        searchValue: "",
        sortField: "",
        sortDirection: "",
        filterValue: "",
      },
    }
  },
  methods:{
    parseValues(){
      this.currentObject.x12tradingPartnerId = Number(this.currentObject.x12tradingPartnerId);
          this.currentObject.x12fileTypeVersionId = Number(this.currentObject.x12fileTypeVersionId);
    },
    async save(){
      try {
        this.isLoading = true;
        if(this.isAdd)  {
          this.parseValues();
          await apiClient.X12.create(this.currentObject);
        }
        if(this.isEdit){
          this.parseValues();
          await apiClient.X12("x12subscriptionFileTypeAssociation").update(this.id,this.currentObject)
        }
          this.$router.push("/admin-panel/partners");
          showDialog("Saved Successfully");
      } catch (error) {
        showDialog("Could not save item",error)
      }finally{
        this.isLoading = false;
      }
    },
    async getById(){
      try {
        this.isLoading = true;
        this.currentObject = await apiClient.X12("x12subscriptionFileTypeAssociation").getById(this.id);
      } catch (error) {
        showDialog("Could not get data", error);
      }finally{
        this.isLoading = false;
      }
    }
  }
};
</script>