<template>
  <v-layout align-center class="pa-2">

    <span v-if="back"
      class="mr-6"
      style="cursor: pointer; margin-top: 0.1rem;"
      @click="onBackClicked">
      <v-icon style="margin-bottom: 0.1rem;">mdi-chevron-left</v-icon>
      <b style="font-size: 0.875rem;">Back</b>
    </span>
    
    <v-breadcrumbs
      :items="items"
      class="pa-0 ma-0"
      >
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item>
          <router-link v-if="item.route" :to="item.route">
            <b>
              {{ item.label }}
            </b>
          </router-link>
          <span v-else style="color: gray; font-size: 0.875rem; margin-bottom: 0.06rem">
            {{ item.label }}
          </span>
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
  </v-layout>
</template>

<script>
export default {
  props: {
    back: {
      default: true,
      required: false,
      type: Boolean,
    },
    items: {
      type: Array,
    },
  }, // props

  methods: {
    onBackClicked() {
      this.$router.back()
    }
  }, // methods
};
</script>
