<template>
  <div>
    <AdminPanelHeader
      icon="mdi-angle-acute"
      title="Ref Identification Qualifier"
      :actions="headerActions"
      @search="onSearch"
    />

    <X12refIdentificationQualifierTable
      :paginatedList="currentEntity"
      :isLoading="isLoading"
      v-on:onDelete="onDelete"
      :searchParams="paginatedQueryParams"
    />
  </div>
</template>

<script>
import apiClient from "../../../../apiClient";
import { AdminPanelHeaderAction } from "../../../../components/AdminPanelHeader/AdminPanelHeaderAction";
import { showDialog } from "../../../../components/Dialogs/NotifyDialog";
import { parentComponentMixin } from '../../../../mixins/parentComponentMixin';

export default {
  name: "X12RefIdentificationQualifier",
  mixins: [parentComponentMixin],
  data() {
    return {
      paginatedQueryParams: {
        pageSize: 10,
        pageNumber: 0,
        searchValue: "",
        sortField: "refQualifierId",
        sortDirection: "DESC",
      },
      headerActions: [
        new AdminPanelHeaderAction({
          color: "accent",
          label: "Add New",
          icon: "add",
          action: () =>
            this.$router.push("/admin-panel/definitions/refIdentificationQualifiers/add"),
        }),
      ],
    };
  },
  methods: {
      async onDelete(id){
          try {
              this.isLoading = true;
              await apiClient.X12("X12refIdentificationQualifiers").delete(id);
              showDialog("Deleted Successfully")
          } catch (error) {
              showDialog("Could not remove record",error)
          }finally{
              this.isLoading = false;
          }
      },
    async getPaginated() {
      try {
        this.isLoading = true;
        this.currentEntity =
          await apiClient.X12("X12refIdentificationQualifiers").getPaginated(
            this.paginatedQueryParams
          );
      } catch (error) {
        showDialog("Could not load data", error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>