var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.asListItem == true
      ? _c("div", [
          _c("div", { staticClass: "d-flex align-center" }, [
            _c(
              "div",
              {
                staticClass: "mr-4",
                staticStyle: { "min-width": "60px", "min-height": "60px" }
              },
              [
                _c("v-img", {
                  attrs: {
                    contain: "",
                    "max-height": "60",
                    "max-width": "60",
                    src: _vm.image
                  }
                })
              ],
              1
            ),
            _c("div", [
              _c("h6", { staticClass: "ma-0" }, [
                _vm._v(_vm._s(_vm.item.stockItem.stockItemName))
              ]),
              _c(
                "p",
                { staticClass: "ma-0" },
                [
                  _c("emb-currency-sign"),
                  _vm._v(" " + _vm._s(_vm.formatNumber(_vm.subTotal)) + " ")
                ],
                1
              )
            ])
          ])
        ])
      : _c(
          "div",
          [
            _c(
              "v-row",
              [
                _c("v-col", { attrs: { cols: "12", sm: "12", md: "2" } }, [
                  _c(
                    "div",
                    { staticClass: "mb-2" },
                    [
                      _c("v-img", {
                        staticClass: "d-block ma-auto",
                        attrs: {
                          width: "130px",
                          height: "130px",
                          contain: true,
                          src: _vm.image
                        }
                      })
                    ],
                    1
                  ),
                  _c("p", { staticClass: "text-center ma-0" }, [
                    _c("b", [_vm._v("Product code:")]),
                    _vm._v(" #" + _vm._s(_vm.item.stockItem.internalId) + " ")
                  ]),
                  _vm.hasTypeAndId
                    ? _c(
                        "p",
                        { staticClass: "text-center ma-0" },
                        [
                          _c(
                            "router-link",
                            {
                              staticStyle: { color: "#AD1814" },
                              attrs: { to: _vm.viewProductPath }
                            },
                            [_vm._v("view product")]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ]),
                _c(
                  "v-col",
                  { attrs: { cols: "12", sm: "6", md: "4" } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "d-flex justify-center align-center mb-4"
                      },
                      [
                        _c("h5", { staticClass: "ma-0" }, [
                          _vm._v(_vm._s(_vm.item.stockItem.stockItemName))
                        ])
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "d-flex justify-space-between mb-2" },
                      [
                        _c("p", { staticClass: "ma-0" }, [
                          _c("b", [_vm._v("Unit Price:")])
                        ]),
                        _c(
                          "p",
                          { staticClass: "ma-0" },
                          [
                            _c("emb-currency-sign"),
                            _vm._v(
                              " " +
                                _vm._s(_vm.formatNumber(_vm.unitPrice)) +
                                " "
                            )
                          ],
                          1
                        )
                      ]
                    ),
                    _c("v-divider", { staticClass: "mb-2" }),
                    _c(
                      "div",
                      { staticClass: "d-flex justify-space-between mb-2" },
                      [
                        _c("p", { staticClass: "ma-0" }, [
                          _c("b", [_vm._v("Deposit:")])
                        ]),
                        _c(
                          "p",
                          { staticClass: "ma-0" },
                          [
                            _c("emb-currency-sign"),
                            _vm._v(
                              " " +
                                _vm._s(_vm.formatNumber(_vm.depositPrice)) +
                                " "
                            )
                          ],
                          1
                        )
                      ]
                    ),
                    _c("v-divider", { staticClass: "mb-2" }),
                    _vm.hasQuantityDiscount
                      ? _c(
                          "div",
                          { staticClass: "d-flex justify-space-between mb-2" },
                          [
                            _c("p", { staticClass: "ma-0" }, [
                              _c("b", [
                                _vm._v(
                                  "Discount " +
                                    _vm._s(_vm.discountDescription) +
                                    ":"
                                )
                              ])
                            ]),
                            _c(
                              "p",
                              { staticClass: "ma-0" },
                              [
                                _c("emb-currency-sign"),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.formatNumber(_vm.discount)) +
                                    " "
                                )
                              ],
                              1
                            )
                          ]
                        )
                      : _vm._e(),
                    _c("v-divider", { staticClass: "mb-2" })
                  ],
                  1
                ),
                _c(
                  "v-col",
                  {
                    staticClass: "d-flex justify-space-between align-center",
                    attrs: { cols: "6", sm: "3", md: "2" }
                  },
                  [
                    _c("div", [
                      _c(
                        "h6",
                        { staticClass: "text-center" },
                        [
                          _c(
                            "span",
                            { class: { "ml-5": _vm.canEditQuantity } },
                            [_vm._v("Quantity")]
                          ),
                          _vm.canEditQuantity
                            ? _c(
                                "v-btn",
                                {
                                  attrs: { small: "", icon: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.showEditQuantityDialog()
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", { attrs: { small: "" } }, [
                                    _vm._v("edit")
                                  ])
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c("p", { staticClass: "text-center" }, [
                        _vm._v(_vm._s(_vm.item.quantity))
                      ])
                    ])
                  ]
                ),
                _c(
                  "v-col",
                  {
                    staticClass: "d-flex justify-center align-center",
                    attrs: { cols: "6", sm: "3", md: "2" }
                  },
                  [
                    _c("div", [
                      _vm.isQuantityDiscountApplied == true ||
                      _vm.isMixAndMatchDiscountApplied == true
                        ? _c("div", [
                            _c(
                              "h5",
                              {
                                staticClass:
                                  "text-center font-weight-light discounted ma-0"
                              },
                              [
                                _vm.isQuantityDiscountApplied == true &&
                                _vm.isMixAndMatchDiscountApplied == true
                                  ? _c(
                                      "span",
                                      [
                                        _c("emb-currency-sign"),
                                        _vm._v(
                                          _vm._s(
                                            _vm.formatNumber(
                                              _vm.subTotalNoDiscount
                                            )
                                          ) + " "
                                        )
                                      ],
                                      1
                                    )
                                  : _vm.isQuantityDiscountApplied == true
                                  ? _c(
                                      "span",
                                      [
                                        _c("emb-currency-sign"),
                                        _vm._v(
                                          _vm._s(
                                            _vm.formatNumber(
                                              _vm.subTotalNoDiscount
                                            )
                                          ) + " "
                                        )
                                      ],
                                      1
                                    )
                                  : _vm.isMixAndMatchDiscountApplied == true
                                  ? _c(
                                      "span",
                                      [
                                        _c("emb-currency-sign"),
                                        _vm._v(
                                          _vm._s(
                                            _vm.formatNumber(
                                              _vm.subTotalNoDiscount
                                            )
                                          ) + " "
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]
                            )
                          ])
                        : _vm._e(),
                      _c(
                        "h4",
                        { staticClass: "text-center font-weight-regular ma-0" },
                        [
                          _c("emb-currency-sign"),
                          _vm._v(_vm._s(_vm.formatNumber(_vm.subTotal)) + " ")
                        ],
                        1
                      ),
                      _vm.isQuantityDiscountApplied == true ||
                      _vm.isMixAndMatchDiscountApplied == true
                        ? _c("p", { staticClass: "ma-0" }, [
                            _vm.isQuantityDiscountApplied == true &&
                            _vm.isMixAndMatchDiscountApplied == true
                              ? _c(
                                  "span",
                                  [
                                    _vm._v(" -"),
                                    _c("emb-currency-sign"),
                                    _vm._v(
                                      _vm._s(
                                        _vm.formatNumber(_vm.discountAll)
                                      ) + " from discounts "
                                    )
                                  ],
                                  1
                                )
                              : _vm.isQuantityDiscountApplied == true
                              ? _c(
                                  "span",
                                  [
                                    _vm._v(" -"),
                                    _c("emb-currency-sign"),
                                    _vm._v(
                                      _vm._s(
                                        _vm.formatNumber(_vm.discountAll)
                                      ) + " from discounts "
                                    )
                                  ],
                                  1
                                )
                              : _vm.isMixAndMatchDiscountApplied == true
                              ? _c(
                                  "span",
                                  [
                                    _vm._v(" -"),
                                    _c("emb-currency-sign"),
                                    _vm._v(
                                      _vm._s(
                                        _vm.formatNumber(_vm.discountAll)
                                      ) + " from discounts "
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ])
                        : _vm._e()
                    ])
                  ]
                ),
                _vm.canRemove
                  ? _c(
                      "v-col",
                      {
                        staticClass:
                          "res-float-icon d-inline-flex align-center justify-md-center justify-end",
                        attrs: { cols: "2", sm: "2", md: "2" }
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "accent--text remove-cart",
                            attrs: { href: "javascript:void(0)" },
                            on: {
                              click: function($event) {
                                return _vm.openRemoveFromCartDialog(_vm.item)
                              }
                            }
                          },
                          [
                            _c(
                              "i",
                              {
                                staticClass: "material-icons font-weight-bold"
                              },
                              [_vm._v("close")]
                            )
                          ]
                        )
                      ]
                    )
                  : _vm._e()
              ],
              1
            ),
            _c(
              "div",
              [
                _c(
                  "v-dialog",
                  {
                    attrs: { "max-width": "420", persistent: "" },
                    model: {
                      value: _vm.isShowingEditQuantityDialog,
                      callback: function($$v) {
                        _vm.isShowingEditQuantityDialog = $$v
                      },
                      expression: "isShowingEditQuantityDialog"
                    }
                  },
                  [
                    _c(
                      "v-card",
                      [
                        _c("v-card-title", [_vm._v("Edit Quantity")]),
                        _c("v-divider"),
                        _c(
                          "v-card-text",
                          [
                            _c(
                              "v-alert",
                              { staticClass: "mt-4", attrs: { type: "info" } },
                              [
                                _vm._v(
                                  " Page will reload automatically after editing the quantity in order for discounts to be re-calculated correctly "
                                )
                              ]
                            ),
                            _c("v-text-field", {
                              attrs: {
                                type: "number",
                                label: "Current Quantity",
                                disabled: ""
                              },
                              model: {
                                value: _vm.item.quantity,
                                callback: function($$v) {
                                  _vm.$set(_vm.item, "quantity", $$v)
                                },
                                expression: "item.quantity"
                              }
                            }),
                            _c("v-text-field", {
                              attrs: { type: "number", label: "New Quantity" },
                              model: {
                                value: _vm.newQuantity,
                                callback: function($$v) {
                                  _vm.newQuantity = $$v
                                },
                                expression: "newQuantity"
                              }
                            })
                          ],
                          1
                        ),
                        _c("v-divider"),
                        _c(
                          "v-card-actions",
                          [
                            _c("v-spacer"),
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  text: "",
                                  color: "primary",
                                  disabled:
                                    !_vm.isNewQuantityValid ||
                                    _vm.isUpdatingQuantity,
                                  loading: _vm.isUpdatingQuantity
                                },
                                on: { click: _vm.onConfirmQuantityChange }
                              },
                              [_vm._v(" Confirm ")]
                            ),
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  text: "",
                                  color: "error",
                                  disabled: _vm.isUpdatingQuantity
                                },
                                on: {
                                  click: function($event) {
                                    _vm.isShowingEditQuantityDialog = false
                                  }
                                }
                              },
                              [_vm._v(" Cancel ")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-dialog",
                  {
                    attrs: { "max-width": "600", persistent: "" },
                    model: {
                      value: _vm.isShowingRemoveFromCartDialog,
                      callback: function($$v) {
                        _vm.isShowingRemoveFromCartDialog = $$v
                      },
                      expression: "isShowingRemoveFromCartDialog"
                    }
                  },
                  [
                    _c(
                      "v-card",
                      [
                        _c("v-card-title", [_vm._v("Remove from cart")]),
                        _c("v-divider"),
                        _c("v-card-text", [
                          _c("p", { staticClass: "ma-0 mt-8 pa-0" }, [
                            _vm._v("Are you sure you want to remove "),
                            _c("b", [
                              _vm._v(_vm._s(_vm.stockItem.stockItemName))
                            ]),
                            _vm._v(" from your cart?")
                          ])
                        ]),
                        _c("v-divider"),
                        _c(
                          "v-card-actions",
                          [
                            _c("v-spacer"),
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  text: "",
                                  color: "primary",
                                  disabled: _vm.isRemovingFromCart,
                                  loading: _vm.isRemovingFromCart
                                },
                                on: { click: _vm.onRemoveFromCartYesClicked }
                              },
                              [_vm._v(" Yes ")]
                            ),
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  text: "",
                                  color: "error",
                                  disabled: _vm.isRemovingFromCart
                                },
                                on: {
                                  click: function($event) {
                                    _vm.isShowingRemoveFromCartDialog = false
                                  }
                                }
                              },
                              [_vm._v(" No ")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }