var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "image-group pt-sm-12 " },
    [
      _c(
        "v-container",
        { attrs: { "grid-list-xl": "" } },
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            _vm._l(_vm.data, function(item) {
              return _c(
                "v-flex",
                {
                  key: item.id,
                  attrs: { xs12: "", sm12: "", md6: "", lg4: "", xl4: "" }
                },
                [
                  _c(
                    "div",
                    { staticClass: "img-wrap" },
                    [
                      _c("router-link", { attrs: { to: item.path } }, [
                        _c("img", {
                          attrs: { src: item.image, alt: "product" }
                        })
                      ])
                    ],
                    1
                  ),
                  _c("div", { staticClass: "features" }, [
                    _c(
                      "h4",
                      {
                        staticClass: "inline-block",
                        attrs: { align: "center" }
                      },
                      [_vm._v(_vm._s(item.description))]
                    ),
                    _c(
                      "h5",
                      { staticClass: "grey--text", attrs: { align: "center" } },
                      [_vm._v(_vm._s(item.subheader))]
                    )
                  ])
                ]
              )
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }