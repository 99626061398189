<template>
  <div>
    <Breadcrumbs class="mb-4" :items="breadcrumbs" />
    <div class="center-x" v-if="isLoading">
      <v-skeleton-loader type="text" width="100%" />
    </div>

    <v-card>
      <v-card-title>Srv PromAll wChrgCodeTable </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" v-show="!isAdd">
            <v-text-field
              label="Agency Srv Code Id"
              v-model="currentObject.agencySrvCodeId"
              disabled
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              label="Agency Srv Code"
              v-model="currentObject.agencySrvCode"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              label="Agency Srv Definition"
              v-model="currentObject.agencySrvDefinition"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-row>
      <v-col>
        <div class="d-flex justify-end">
          <v-btn color="error" large @click="save">Save</v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import apiClient from "../../../../apiClient";
import { BreadcrumbItem } from "../../../../components/Breadcrumbs/BreadcrumbItem";
import { showDialog } from "../../../../components/Dialogs/NotifyDialog";
import { formsMixin } from "../../../../mixins/formsMixin";
import { StaticRoutes } from "../../../../router/Routes";
export default {
  mixins: [formsMixin],
  name: "X12SrvPromAllwChrgCode",

  data() {
    return {
      breadcrumbs: [
        new BreadcrumbItem({
          label: "",
          route:
            StaticRoutes.adminPanel.trading.x12Definitions.srvPromAllwChrgCode,
        }),
        new BreadcrumbItem({ label: "Srv Prom Allw ChrgCode Details" }),
      ],
    };
  },
  methods: {
    async save() {
      try {
        this.isLoading = true;
        if (this.isAdd) {
          await apiClient
            .X12("x12srvPromAllwChrgCodes")
            .create(this.currentObject);
          showDialog("Created Successfully");
        }
        if (this.isEdit) {
          await apiClient
            .X12("x12srvPromAllwChrgCodes")
            .update(this.id, this.currentObject);
          showDialog("Updated Successfully");
        }
        this.$router.push("/admin-panel/definitions/srvPromAllwChrgCode");
      } catch (error) {
        showDialog("Could not save item", error);
      } finally {
        this.isLoading = false;
      }
    },
    async getById() {
      try {
        this.isLoading = true;
        this.currentObject = await apiClient
          .X12("x12srvPromAllwChrgCodes")
          .getById(this.id);
      } catch (error) {
        showDialog("Could get data", error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>