<template>
    <div>
        <Breadcrumbs class="mb-4" :items="breadcrumbs"/>

        <div class="center-x" v-if="isLoading">
            <v-skeleton-loader type="text" width="100%"/>
        </div>
        <v-card>
            <v-card-title>Trx Handle Code</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" sm="6" v-show="!isAdd">
                        <v-text-field
                            label="Trx Handle Id"
                            v-model.number="currentObject.trxHandleId"
                            disabled
                        />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field
                            label="Trx Handle Description"
                            v-model="currentObject.trxHandleDescription"
                        />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field
                            label="Trx Handle Code"
                            v-model="currentObject.trxHandleCode"
                        />
                    </v-col>
                    <v-col cols="12" sm="6" v-show="!isAdd">
                        <v-text-field
                            label="Last Edited By"
                            v-model.number="currentObject.lastEditedBy"
                            disabled
                        />
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-row>
            <v-col>
                <div class="d-flex justify-end">
                    <v-btn color="error" large @click="save">Save</v-btn>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import apiClient from "../../../../apiClient";
import { BreadcrumbItem } from "../../../../components/Breadcrumbs/BreadcrumbItem";
import { StaticRoutes } from "../../../../router/Routes";
import { showDialog } from '../../../../components/Dialogs/NotifyDialog';
import { formsMixin } from '../../../../mixins/formsMixin';

export default {
    mixins:[formsMixin],
    name: "X12TraceTypesCodesForm",
    data() {
        return {
            breadcrumbs: [
                new BreadcrumbItem({
                    label: "Trx Handle Codes",
                    route: StaticRoutes.adminPanel.trading.x12Definitions.trxHandleCodes
                }),
                new BreadcrumbItem({ label: "Trx Handle Code Details" })
            ],
        }
    },
    methods: {
        async getById() {
            try {
                this.isLoading = true
                this.currentObject = await apiClient.X12("X12TrxHandleCodes").getById(this.id)
            } catch (error) {
                showDialog("Could not load Trx Handle Code.", error)
            } finally {
                this.isLoading = false
            }
        },

        async save() {
            try {
                this.isLoading = true
                if (this.isEdit) {
                    await apiClient.X12("X12TrxHandleCodes").update(this.id, this.currentObject)
                    showDialog('Updated Successfully')
                }
                if (this.isAdd) {
                    await apiClient.X12("X12TrxHandleCodes").create(this.currentObject)
                    showDialog('Created Successfully')
                }
                    this.$router.push("/admin-panel/definitions/trxHandleCodes")
            } catch (error) {
                showDialog('Could not update.', error)
            } finally {
                this.isLoading = false
            }
        },
    },
}
</script>