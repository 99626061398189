var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pa-4" },
    [
      _c(
        "v-expansion-panels",
        {
          staticClass: "mb-4",
          attrs: { focusable: "", multiple: "", value: [0] }
        },
        [
          _c(
            "v-expansion-panel",
            [
              _c("v-expansion-panel-header", [_vm._v("Notifications")]),
              _c(
                "v-expansion-panel-content",
                { staticClass: "pt-3" },
                [
                  _c("v-checkbox", {
                    staticClass: "ma-0 pa-0",
                    attrs: {
                      disabled: _vm.isViewNotifications,
                      label: "Enable Notifications"
                    }
                  }),
                  _c("v-checkbox", {
                    staticClass: "ma-0 pa-0",
                    attrs: {
                      disabled: _vm.isViewNotifications,
                      label: "Show Desktop Notifications"
                    }
                  }),
                  _c("v-checkbox", {
                    staticClass: "ma-0 pa-0",
                    attrs: {
                      disabled: _vm.isViewNotifications,
                      label: "Enable SMS messages"
                    }
                  }),
                  _c("v-checkbox", {
                    staticClass: "ma-0 pa-0",
                    attrs: {
                      disabled: _vm.isViewNotifications,
                      label: "Get e-mail notification for my own activity"
                    }
                  }),
                  _c("v-checkbox", {
                    staticClass: "ma-0 pa-0",
                    attrs: {
                      disabled: _vm.isViewNotifications,
                      label: "Get e-mail notification for request and reviews"
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "d-flex justify-end" },
                    [
                      _vm.isViewNotifications
                        ? _c(
                            "v-btn",
                            {
                              attrs: { color: "error" },
                              on: { click: _vm.editSettings }
                            },
                            [_vm._v("Edit")]
                          )
                        : _vm._e(),
                      !_vm.isViewNotifications
                        ? _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: { click: _vm.saveSettings }
                            },
                            [_vm._v("Save")]
                          )
                        : _vm._e(),
                      _vm._v(" ` ")
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-expansion-panel",
            [
              _c("v-expansion-panel-header", [
                _vm._v("Deactivate your account")
              ]),
              _c(
                "v-expansion-panel-content",
                { staticClass: "pt-3" },
                [
                  _c("p", [_vm._v("Specify the reason you are leaving:")]),
                  _c(
                    "v-radio-group",
                    {
                      attrs: { mandatory: false },
                      model: {
                        value: _vm.radios,
                        callback: function($$v) {
                          _vm.radios = $$v
                        },
                        expression: "radios"
                      }
                    },
                    [
                      _c("v-radio", {
                        attrs: {
                          label: "This is temporary. I'll be back.",
                          value: "radio1"
                        }
                      }),
                      _c("v-radio", {
                        attrs: {
                          label: "My account was hacked.",
                          value: "radio2"
                        }
                      }),
                      _c("v-radio", {
                        attrs: {
                          label: "I have a privacy concern.",
                          value: "radio3"
                        }
                      }),
                      _c("v-radio", {
                        attrs: { label: "Other", value: "radio4" }
                      })
                    ],
                    1
                  ),
                  _vm.otherSelected
                    ? _c(
                        "div",
                        [
                          _c("p", [_vm._v("If other, please explain:")]),
                          _c("v-textarea", {
                            attrs: { rows: "2", outlined: "", "auto-grow": "" }
                          }),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "error" },
                              on: {
                                click: function($event) {
                                  return _vm.deactivateAccount()
                                }
                              }
                            },
                            [_vm._v("Deactivate Account")]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("emb-delete-confirmation-2", {
        ref: "deleteConfirmationDialog",
        attrs: {
          messageTitle: "Are You Sure You Want To Delete?",
          messageDescription:
            "Are you sure you want to delete this account permanently?",
          btn1: "Cancel",
          btn2: "Yes"
        },
        on: { onConfirm: _vm.onDeactivateAccount }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }