var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c("vue-snotify"),
      _c(
        "div",
        { staticClass: "headers" },
        [
          _vm.$route.meta.header != 2 && _vm.$route.meta.header != 3
            ? _c("emb-header-v1")
            : _vm._e(),
          _vm.$route.meta.header === 2 ? _c("emb-header-v2") : _vm._e(),
          _vm.$route.meta.header === 3 ? _c("emb-header-v3") : _vm._e()
        ],
        1
      ),
      _c(
        "v-navigation-drawer",
        {
          staticClass: "sidebar-bg",
          attrs: { temporary: "", absolute: "", dark: "" },
          model: {
            value: _vm.mobileMenu,
            callback: function($$v) {
              _vm.mobileMenu = $$v
            },
            expression: "mobileMenu"
          }
        },
        [
          _vm.$msal.isAccountSelected()
            ? _c("emb-sidebar", { attrs: { menus: _vm.getMenuItems() } })
            : _c("emb-sidebar", { attrs: { menus: _vm.getMenuItems() } })
        ],
        1
      ),
      _c("div", [_c("router-view", { key: _vm.$route.fullPath })], 1),
      _c("emb-footer-V1", {
        attrs: {
          title: "About us",
          description: _vm.aboutUs,
          img: "/static/images/cards.png"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }