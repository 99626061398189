<template>
  <div class="table-responsive" >
    <v-data-table
      :headers="headers"
      :footer-props="{ 'items-per-page-options': [10, 20, 30, 40, 50] }">
    </v-data-table>
  </div>
</template>
<script>
export default {
  props: ['isLoading'],
  data (){
    return{
      headers: [
      {
        text: "Brand ID",
        sortable: true,
        value: "brandId",
      },
      {
        text: "Brand Code",
        sortable: true,
        value: "BrandCode",
      },
      {
        text: "Name",
        sortable: true,
        value: "fullName",
      },
      {
        text: "Position Title",
        sortable: true,
        value: "positionTitle",
      },
      {
        text: "Route ID",
        sortable: true,
        value: "routeId",
      },
      {
        sortable: false,
        value: "action",
      },
    ],

    }
    
  }
}
</script>