<template>
  <div class="mr-3">
    <v-menu
      transition="scale-transition"
      class="cart"
      min-width="300"
      max-width="300"
      offset-y
      light>
      <template v-slot:activator="{ on }">
        <v-btn class="cart-btn" fab dark small color="accent" v-on="on">
          <v-badge right large color="accent">
            <template>
              <span slot="badge">{{order.orderLines.length}}</span>
            </template>
            <v-icon dark>shopping_cart</v-icon>
          </v-badge>
        </v-btn>
      </template>

      <!-- cart has products -->
      <div v-if="order && order.orderLines.length > 0" class="cart-menu-list white">
        <emb-perfect-scrollbar class="scroll-area" style="max-height:280px">
          <v-list>
            <div v-for="(item, index) in order.orderLines" :key="index" class="mx-2">
              <OrderLineItem
                :asListItem="true"
                :item="item"
                :order="order"/>
              <v-divider class="mb-2" />
            </div>
          </v-list>
        </emb-perfect-scrollbar>

        <v-layout align-center pa-3>
          <v-btn class="accent white--text" block to="/cart">Cart</v-btn>
        </v-layout>
      </div><!-- cart has products -->

      <!-- cart is empty -->
      <div v-else class="text-center white pa-6">
        <v-icon size="31" class="accent--text">shopping_cart</v-icon>
        <h5>No Product Found</h5>
      </div><!-- cart is empty -->

    </v-menu>

    <LoadingDialog ref="loadingDialog"/>
    <YesNoDialog ref="yesNoDialog"/>

  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { currency, utils } from "../../../mixins";
import LoadingDialog from "@/components/Dialogs/LoadingDialog";
import OrderLineItem from '@/components/OrderLineItem';
import YesNoDialog from "@/components/Dialogs/YesNoDialog";
import VuePerfectScrollbar from "vue-perfect-scrollbar";

export default {

  mixins: [currency, utils],

  components: {
    embPerfectScrollbar: VuePerfectScrollbar,
    LoadingDialog,
    YesNoDialog,
    OrderLineItem,
  },

  data() {
    return {
      selectDeletedProduct: null,
      settings: {
        maxScrollbarLength: 160,
      },
    };
  },

  computed: {
    ...mapGetters({
      currentCustomer: "session/getAccount",
      order: "order/getCart",
    }),
    
    getTotalPrice() {
      let totalPrice = 0;
      for (const item of this.order.orderLines)
        totalPrice += item.unitPrice * item.quantity;
      return totalPrice.toFixed(2);
    },
  },
  methods: {
    ...mapMutations({ setCartProducts: "order/SET_ORDER_LINES" }),

    ...mapActions({
      removeFromCart: 'order/removeFromCart',
    }),

    hasSpecialDeals(orderLine) {
      const stockItem = orderLine.stockItem;
      if (!stockItem)
        return false;
      
      let specialDeals = stockItem.specialDeals;
      if (!specialDeals)
        return false;
      
      specialDeals = specialDeals.filter(deal => deal.customerId == this.currentCustomer.customerId);
      
      if (specialDeals.length == 0)
        return false;
      
      return true;
    },

    isDiscountApplied(orderLine) {
      if (!this.hasSpecialDeals(orderLine)) return false;
      const currentQuantity = orderLine.quantity;
      return currentQuantity >= this.minQuantityForDiscount(orderLine);
    },

    minQuantityForDiscount(orderLine) {
      const stockItem = orderLine.stockItem;
      const specialDeals = stockItem.specialDeals[0];
      return specialDeals.minimumQuantity;
    },

    subTotal(orderLine) {
      const unitPrice = orderLine.baseUnitPrice;
      const deposit = orderLine.depositPrice;
      const discount = !this.hasSpecialDeals(orderLine)
          ? 0
          : (this.isDiscountApplied(orderLine)
            ? orderLine.baseUnitPrice - orderLine.unitPrice
            : 0
          );
      const quantity = orderLine.quantity;
      return (unitPrice + deposit - discount) * quantity;
    },

    deleteProductFromCart(product) {
      this.$refs.deleteConfirmationDialog.openDialog();
      this.selectDeletedProduct = product;
    },

    onRemoveOrderLineClicked(orderLine) {
      const productName = orderLine.description;

      this.$refs.yesNoDialog.open({
        title: "Remove from cart",
        message: `Are you sure you want to remove ${productName} from you cart?`
      })
      .then(() => this.removeFromCartRequest(orderLine));
    },

    removeFromCartRequest(orderLine) {
      const productName = orderLine.description;

      this.$refs.loadingDialog.open({ title: "Removing from cart", message: `Please wait, removing ${productName} from your cart`, });

      this.removeFromCart({orderLineId:orderLine.orderLineId,removeAll: false})
      .then(() => {
          this.$snotify.success('The product has been removed!', {
              closeOnClick: false,
              pauseOnHover: false,
              timeout: 1500,
          });
      })
      .catch(error => {
          this.$snotify.error(`Could not remove product. ${error}`, {
              closeOnClick: false,
              pauseOnHover: false,
              timeout: 4000,
          });
      })
      .finally(() => this.$refs.loadingDialog.close());
    },
    
  },
};
</script>
