<template>
    <div>
        <AdminPanelHeader
            icon="mdi-clipboard-text"
            title="Interchange Qualifiers"
            :actions="headerActions"
            @search="onSearch"
        />

        <X12InterchangeQualifiersTable
            :paginatedList="currentEntity"
            :isLoading="isLoading"
            :searchParams="paginatedQueryParams"
            v-on:onDelete="onDelete"
        />
    </div>
</template>

<script>
import apiClient from "../../../../apiClient"
import { AdminPanelHeaderAction } from '../../../../components/AdminPanelHeader/AdminPanelHeaderAction'
import { showDialog } from '../../../../components/Dialogs/NotifyDialog'
import { parentComponentMixin } from "../../../../mixins/parentComponentMixin";

export default {
    mixins: [parentComponentMixin],
    data() {
        return {
            headerActions: [
                new AdminPanelHeaderAction({
                    color: "accent",
                    label: "Add new",
                    icon: "add",
                    action: () => this.$router.push("/admin-panel/definitions/interchangeQualifiers/add"),
                })
            ],
            paginatedQueryParams: {
                pageSize: 10,
                pageNumber: 0,
                searchValue: "",
                sortField: "interchangeQualifierId",
                sortDirection: "DESC",
            },
        }
    },
    methods: {
        async onDelete(id) {
            try {
                this.isLoading = true;
                await apiClient.X12("X12InterchangeQualifiers").delete(id)
                showDialog("Record deleted successfully")
                await this.getPaginated();
            }
            catch (error) {
                showDialog(`Could not delete record.`, error)
            } finally {
              this.isLoading = false;
            }
        },
        async getPaginated() {
            try {
                this.isLoading = true
                this.currentEntity = await apiClient.X12("X12InterchangeQualifiers").getPaginated(this.paginatedQueryParams)
            } catch (error) {
                showDialog('Could not load Interchange Qualifiers data.', error)
            } finally {
                this.isLoading = false
            }
        },
    },
}
</script>
