export const StaticRoutes = {
    adminPanel: {
        accounts: '/admin-panel/accounts',
        reports: '/admin-panel/reports',
        addPerson: '/admin-panel/people/new',
        orders: '/admin-panel/orders',
        peoplePage: '/admin-panel/people',
        routes: '/admin-panel/people-routes',
        products: '/admin-panel/products',
        supplier: '/admin-panel/suppliers',
        productAdd: '/admin-panel/product-add',
        trading: {
            partners:'/admin-panel/partners',
            messages: '/admin-panel/trading-messages',
            subscriptions: '/admin-panel/trading-subscriptions',
            x12Definitions:{
                fileTypeVersion:'/admin-panel/definitions/fileTypesVersion',
                fileType:'/admin-panel/definitions/fileTypes',
                dateTimeQualifiers:'/admin-panel/definitions/X12DateTimeQualifiers',
                functionalCodes: '/admin-panel/definitions/functionalCodes',
                potypesCodes:'/admin-panel/definitions/X12PotypesCodes',
                refIdentificationQualifiers:'/admin-panel/definitions/X12refIdentificationQualifiers',
                traceTypesCodes: '/admin-panel/definitions/X12TraceTypesCodes',
                trxHandleCodes: '/admin-panel/definitions/X12TrxHandleCodes',
                chargeTypes: '/admin-panel/definitions/X12ChargeTypes',
                classTradeCodes: '/admin-panel/definitions/X12ClassTradeCodes',
                commQualifiers: '/admin-panel/definitions/X12CommQualifiers',
                catalogPurposeCode:'/admin-panel/definitions/X12catalogPurposeCodes',
                termsBasisDateCodeForm:'/admin-panel/definitions/X12termsBasisDateCodeForm',
                entityIdentifierCode:'/admin-panel/definitions/X12EntityIdentifierCodes',
                termsTypeCodes:'/admin-panel/definitions/TermsTypeCodes',
                productServiceQualifiers:'/admin-panel/definitions/productServiceQualifiers',
                interchangeQualifiers: '/admin-panel/definitions/interchangeQualifiers',
                srvPromAllwChrgCode:'/admin-panel/definitions/srvPromAllwChrgCode',
                priceIdentifierCode:'/admin-panel/definitions/priceIdentifierCode',
                paymentMethodForm:'/admin-panel/definitions/paymentMethodForm',
                marketAreaQualifier:'/admin-panel/definitions/marketAreaQualifier',
                interchangeTradingPartnerTypes: '/admin-panel/definitions/interchangeTradingPartnerTypes',
                locationCodes:'/admin-panel/definitions/locationCodes',
            }
        },
    },
}

export const DynamicRoutes = {
    adminPanel: {
        personDetails: (personId) => `/admin-panel/people/${personId}`,
    },
}
