<template>
  <div class="table-responsive">
    <v-data-table
      :headers="headers"
      :items="paginatedList.items"
      :server-items-length="paginatedList.totalCount"
      :options.sync="listViewOptions"
      :loading="isLoading"
      :footer-props="{ 'items-per-page-options': [10, 20, 30, 40, 50] }"
    >
    </v-data-table>
    <YesNoDialog ref="yesNoDialog" />
  </div>
</template>

<script>
import { tableProps } from '../../../../../mixins/tableProps';

export default {
  name: "ConfigDataTable",
  mixins:[tableProps]  ,
  data() {
    return {

      headers: [
        {
          text: "Internal Id",
          sortable: true,
          value: "internalId",
        },
        {
          text: "Custom Field Name",
          sortable: true,
          value: "customFieldName",
        },
        {
          text: "Custom Field Value",
          sortable: true,
          value: "customFieldValue",
        },
      ],
    };
  },
};
</script>
