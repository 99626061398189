var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "d-flex justify-center",
                  attrs: { cols: "12", sm: "4", md: "3", lg: "2" }
                },
                [
                  !_vm.isPhotoAvailable
                    ? _c("avatar", {
                        staticClass: "hoverable",
                        attrs: {
                          fullname: _vm.selectedPerson.fullName,
                          size: 130
                        }
                      })
                    : _c(
                        "v-avatar",
                        { attrs: { size: "180" } },
                        [
                          _c("v-img", {
                            attrs: {
                              alt: "Profile Image",
                              src:
                                "data:image/png;base64, " +
                                _vm.selectedPerson.photo
                            }
                          })
                        ],
                        1
                      )
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "8", md: "9", lg: "10" } },
                [
                  _c("v-text-field", {
                    attrs: { disabled: _vm.isView, label: "Logon Name" },
                    model: {
                      value: _vm.selectedPerson.logonName,
                      callback: function($$v) {
                        _vm.$set(_vm.selectedPerson, "logonName", $$v)
                      },
                      expression: "selectedPerson.logonName"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: { disabled: _vm.isView, label: "Email" },
                    model: {
                      value: _vm.selectedPerson.emailAddress,
                      callback: function($$v) {
                        _vm.$set(_vm.selectedPerson, "emailAddress", $$v)
                      },
                      expression: "selectedPerson.emailAddress"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6" } },
                [
                  _c("v-text-field", {
                    attrs: { disabled: _vm.isView, label: "Full Name" },
                    model: {
                      value: _vm.selectedPerson.fullName,
                      callback: function($$v) {
                        _vm.$set(_vm.selectedPerson, "fullName", $$v)
                      },
                      expression: "selectedPerson.fullName"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6" } },
                [
                  _c("v-text-field", {
                    attrs: { disabled: _vm.isView, label: "Preffered Name" },
                    model: {
                      value: _vm.selectedPerson.preferredName,
                      callback: function($$v) {
                        _vm.$set(_vm.selectedPerson, "preferredName", $$v)
                      },
                      expression: "selectedPerson.preferredName"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6" } },
                [
                  _c("v-text-field", {
                    attrs: { disabled: _vm.isView, label: "Phone Number" },
                    model: {
                      value: _vm.selectedPerson.phoneNumber,
                      callback: function($$v) {
                        _vm.$set(_vm.selectedPerson, "phoneNumber", $$v)
                      },
                      expression: "selectedPerson.phoneNumber"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6" } },
                [
                  _c("v-text-field", {
                    attrs: { disabled: _vm.isView, label: "Fax Number" },
                    model: {
                      value: _vm.selectedPerson.faxNumber,
                      callback: function($$v) {
                        _vm.$set(_vm.selectedPerson, "faxNumber", $$v)
                      },
                      expression: "selectedPerson.faxNumber"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm.isAdd
            ? _c("div", [
                _c(
                  "div",
                  { staticClass: "d-flex justify-end" },
                  [
                    _c("v-switch", {
                      staticClass: "ma-0 pa-0",
                      attrs: { label: "Is employee?" },
                      on: {
                        change: function($event) {
                          ;(_vm.isEmployee = !_vm.isEmployee),
                            (_vm.selectedPerson.isEmployee = !_vm.selectedPerson
                              .isEmployee)
                        }
                      }
                    })
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm.isEmployee ||
          (_vm.isView && _vm.selectedPerson.isEmployee) ||
          (_vm.isEdit && _vm.selectedPerson.isEmployee)
            ? _c(
                "v-expansion-panels",
                { attrs: { multiple: "", focusable: "", value: [0] } },
                [
                  _c(
                    "v-expansion-panel",
                    [
                      _c("v-expansion-panel-header", [_vm._v("Employee Data")]),
                      _c(
                        "v-expansion-panel-content",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      disabled: _vm.isView,
                                      label: "Company Name"
                                    },
                                    model: {
                                      value: _vm.selectedPerson.companyName,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.selectedPerson,
                                          "companyName",
                                          $$v
                                        )
                                      },
                                      expression: "selectedPerson.companyName"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      disabled: _vm.isView,
                                      label: "Route ID"
                                    },
                                    model: {
                                      value: _vm.selectedPerson.routeId,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.selectedPerson,
                                          "routeId",
                                          $$v
                                        )
                                      },
                                      expression: "selectedPerson.routeId"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "6" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      disabled: _vm.isView,
                                      label: "Employee ID"
                                    },
                                    model: {
                                      value: _vm.selectedPerson.employeeId,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.selectedPerson,
                                          "employeeId",
                                          $$v
                                        )
                                      },
                                      expression: "selectedPerson.employeeId"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "6" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      disabled: _vm.isView,
                                      label: "Employee Code"
                                    },
                                    model: {
                                      value: _vm.selectedPerson.employeeCode,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.selectedPerson,
                                          "employeeCode",
                                          $$v
                                        )
                                      },
                                      expression: "selectedPerson.employeeCode"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "6" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      disabled: _vm.isView,
                                      label: "Business Title"
                                    },
                                    model: {
                                      value: _vm.selectedPerson.businessTitle,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.selectedPerson,
                                          "businessTitle",
                                          $$v
                                        )
                                      },
                                      expression: "selectedPerson.businessTitle"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "6" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      disabled: _vm.isView,
                                      label: "Department"
                                    },
                                    model: {
                                      value: _vm.selectedPerson.department,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.selectedPerson,
                                          "department",
                                          $$v
                                        )
                                      },
                                      expression: "selectedPerson.department"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "6" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      disabled: _vm.isView,
                                      label: "Work Phone Number"
                                    },
                                    model: {
                                      value: _vm.selectedPerson.workPhoneNumber,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.selectedPerson,
                                          "workPhoneNumber",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "selectedPerson.workPhoneNumber"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "6" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      disabled: _vm.isView,
                                      label: "Primary Work Location"
                                    },
                                    model: {
                                      value:
                                        _vm.selectedPerson.primaryWorkLocation,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.selectedPerson,
                                          "primaryWorkLocation",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "selectedPerson.primaryWorkLocation"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "6" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      disabled: _vm.isView,
                                      label: "Security Group"
                                    },
                                    model: {
                                      value: _vm.selectedPerson.securityGroup,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.selectedPerson,
                                          "securityGroup",
                                          $$v
                                        )
                                      },
                                      expression: "selectedPerson.securityGroup"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "6" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      disabled: _vm.isView,
                                      label: "Badge ID"
                                    },
                                    model: {
                                      value: _vm.selectedPerson.badgeId,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.selectedPerson,
                                          "badgeId",
                                          $$v
                                        )
                                      },
                                      expression: "selectedPerson.badgeId"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "6" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      disabled: _vm.isView,
                                      label: "Primary Supervisor"
                                    },
                                    model: {
                                      value:
                                        _vm.selectedPerson.supervisorPrimary,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.selectedPerson,
                                          "supervisorPrimary",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "selectedPerson.supervisorPrimary"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "6" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      disabled: _vm.isView,
                                      label: "Status"
                                    },
                                    model: {
                                      value: _vm.selectedPerson.status,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.selectedPerson,
                                          "status",
                                          $$v
                                        )
                                      },
                                      expression: "selectedPerson.status"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }