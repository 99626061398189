/*eslint-disable*/

import Vue from 'vue';

export const loginGuard = (to, from, next) => {
    next();
}

export const authGuard = (to, from, next) => {
    const msal = Vue.prototype.$msal;

    // Deny access
    if (!msal.isAuthenticated()) {
        next('/login');
    }
    // Grant access
    else {
        next();
    }
}

export const permissionGuard = (to, from, next) => {
    const msal = Vue.prototype.$msal;

    // User is not authenticated. Deny access
    if (!msal.isAuthenticated()) {
        next('/login');
    }
    else {
        // User account ois inactive. Deny access
        if (!msal.isPermittedToLogon()) {
            next('/inactive-account');
        }
        else {
            // Force to choose account
            if (!msal.isAccountSelected() || !msal.hasUserPreferences()) {

                // If the current route is the same one to which the app is navigating to,
                // reload page to avoid getting stuck in loading animation
                if (from.path == '/switch/account') {
                    window.location.reload();
                }

                next('/switch/account');
            }
            else {
                // License is expired. Redirect to account licenses page
                if (!msal.isAnyLicenseActive()) {
                    next('/account/profile?tab=licenses');
                }
                // Grant access
                else {
                    next();
                }
            }
        }
    }
}

export const permissionGuardNoAccount = (to, from, next) => {
    const msal = Vue.prototype.$msal;

    // Deny access
    if (!msal.isAuthenticated()) {
        console.log('User is not authenticated. Go to login');
        next('/login');
    }
    else {
        // Deny access
        if (!msal.isPermittedToLogon()) {
            console.log('User is not permited to logon. Go to inactive account');
            next('/inactive-account');
        }
        // Grant
        else {
            next();
        }
    }
}

export const permissionGuardExpiredLicense = (to, from, next) => {
    const msal = Vue.prototype.$msal;

    // Deny access
    if (!msal.isAuthenticated()) {
        console.log('User is not authenticated. Go to login');
        next('/login');
    }
    else {
        // Deny access
        if (!msal.isPermittedToLogon()) {
            console.log('User is not permited to logon. Go to inactive account');
            next('/inactive-account');
        }
        // Grant
        else {
            if (!msal.isAccountSelected()) {
                // If the current route is the same one to which the app is navigating to,
                // reload page to avoid getting stuck in loading animation
                if (from.path == '/switch/account') {
                    window.location.reload();
                }

                next('/switch/account');
            }
            else {
                // If the current route is the same one to which the app is navigating to,
                // reload page to avoid getting stuck in loading animation
                if (from.path == '/account/profile') {
                    window.location.reload();
                }

                next();
            }
        }
    }
}
