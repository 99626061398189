//-----------------------| Search Module |-------------------//

/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import api from "@/api";
import Vue from 'vue'

const state = {
  moduleName: 'types',
  types: [],
}

const getters = {
  getTypes: state => state.types,
}

const actions = {
  get({ state, commit }) {
    return api.get(`${state.moduleName}`).then(result => {
      console.log("result.data:", result.data);
      commit("SET_TYPES", result)
    });
  },
}

const mutations = {
  SET_TYPES(state, data) {
    Vue.set(state, 'types', data.data);
  },
}
export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}