<template>
  <div>
    <Breadcrumbs class="mb-4" :items="breadcrumbs" />
    <div class="center-x" v-if="isLoading">
      <v-skeleton-loader type="text" width="100%" />
    </div>
    <v-card>
      <v-card-title>Location Codes</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" v-show="!isAdd">
            <v-text-field
              label="Location Code Id"
              v-model="currentObject.locationCodeId"
              disabled
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              label="Location Code Description"
              v-model="currentObject.locationCodeDescription"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              label="Location Code"
              v-model="currentObject.locationCode"
            />
          </v-col>
          <v-col cols="12" sm="6" v-if="!isAdd">
            <v-text-field
              label="Last Edited By"
              v-model="currentObject.lastEditedBy"
              disabled
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-row>
      <v-col>
        <div class="d-flex justify-end">
          <v-btn color="error" large @click="save">Save</v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { StaticRoutes } from "../../../../router/Routes";
import { formsMixin } from "../../../../mixins/formsMixin";
import { BreadcrumbItem } from "../../../../components/Breadcrumbs/BreadcrumbItem";
import { showDialog } from "../../../../components/Dialogs/NotifyDialog";
import apiClient from "../../../../apiClient";
export default {
  name: "X12locationCodesForm",
  mixins: [formsMixin],
  data() {
    return {
      breadcrumbs: [
        new BreadcrumbItem({
          label: "Loca",
          route: StaticRoutes.adminPanel.trading.x12Definitions.locationCodes,
        }),
        new BreadcrumbItem({ label: "Location Codes Details" }),
      ],
    };
  },
  methods: {
      async getById(){
          try {
              this.isLoading = true;
              this.currentObject = await apiClient.X12("x12locationCodes").getById(this.id);
          } catch (error) {
              showDialog("Could not get data",error);
          }finally{
              this.isLoading = false;
          }
      },
    async save() {
      try {
        this.isLoading = true;
        if (this.isAdd) {
          await apiClient.X12("x12locationCodes").create(this.currentObject);
          showDialog("Created Successfully");
        }
        if (this.isEdit) {
          await apiClient.X12("x12locationCodes").update(this.id,this.currentObject);
          showDialog("Updated Successfully");
        }
          this.$router.push("/admin-panel/definitions/locationCodes");
      } catch (error) {
        showDialog("Could not save item", error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

