<template>
  <div>
    <AdminPanelHeader
      icon="mdi-bell"
      title="Trading Subscriptions"
      @search="onSearch"
      class="mb-5"
    />

    <TradingSubscriptionsTable
      :paginatedList="currentEntity"
      :isLoading="isLoading"
      :searchParams="paginatedQueryParams"
    />
  </div>
</template>

<script>
import apiClient from "../../../apiClient";
import { showDialog } from '../../../components/Dialogs/NotifyDialog';
import { parentComponentMixin } from '../../../mixins/parentComponentMixin';
export default {
  mixins: [parentComponentMixin],
  data: () => ({
    paginatedQueryParams: {
      pageSize: 10,
      pageNumber: 0,
      searchValue: "",
      sortField: "x12subscriptionId",
      sortDirection: "DESC",
      filterValue:""
    },
    id:null

  }), // data
  mounted(){
    this.id = this.$route.params.id;
  }, 
  methods: {
    async getPaginated() {
      try {
        this.isLoading = true;

        if(this.id){
          this.paginatedQueryParams.filterValue = this.id;
          this.currentEntity = await apiClient.X12("tradingSubscriptions").getFilteredPaginated(this.paginatedQueryParams) 
        }else{
          this.currentEntity = await apiClient.X12("tradingSubscriptions").getPaginated(this.paginatedQueryParams)
        }
      } catch(error){
        showDialog("Could not get data",error);
      }
        finally {
        this.isLoading = false;
      }
    },
  }, // methods
};
</script>