<template>
    <div>
        <Breadcrumbs class="mb-4" :items="breadcrumbs"/>

        <div class="center-x" v-if="isLoading">
            <v-skeleton-loader type="text" width="100%"/>
        </div>
        <v-card>
            <v-card-title>Entity Identifier Code</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" sm="6" v-show="!isAdd">
                        <v-text-field
                            label="Entity Identifier Id"
                            v-model.number="currentObject.entityIdentifierId"
                            disabled
                        />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field
                            label="Entity Identifier Description"
                            v-model="currentObject.entityIdentifierDescription"
                        />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field
                            label="Entity Identifier Code"
                            v-model="currentObject.entityIdentifierCode"
                        />
                    </v-col>
                    <v-col cols="12" sm="6" v-show="!isAdd">
                        <v-text-field
                            label="Last Edited By"
                            v-model.number="currentObject.lastEditedBy"
                            disabled
                        />
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-row>
            <v-col>
                <div class="d-flex justify-end">
                    <v-btn color="error" large @click="save">Save</v-btn>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import apiClient from "../../../../apiClient";
import { BreadcrumbItem } from "../../../../components/Breadcrumbs/BreadcrumbItem";
import { showDialog } from '../../../../components/Dialogs/NotifyDialog';
import { formsMixin } from '../../../../mixins/formsMixin';
import { StaticRoutes } from "../../../../router/Routes";

export default {
    mixins:[formsMixin],
    name: "X12EntityIdentifierCodesForm",
    data() {
        return {
            breadcrumbs: [
                new BreadcrumbItem({
                    label: "Entity Identifier Codes",
                    route: StaticRoutes.adminPanel.trading.x12Definitions.entityIdentifierCode
                }),
                new BreadcrumbItem({ label: "Entity Identifier Code Details" })
            ],
        }
    },

    methods: {
        async getById() {
            try {
                this.isLoading = true
                this.currentObject = await apiClient.X12("X12EntityIdentifierCodes").getById(this.id)
            } catch (error) {
                showDialog("Could not load Entity Identifier Code.", error)
            } finally {
                this.isLoading = false
            }
        },

        async save() {
            try {
                this.isLoading = true
                if (this.isEdit) {
                    await apiClient.X12("X12EntityIdentifierCodes").update(this.id,this.currentObject);
                    showDialog('Updated Successfully')
                }

                if (this.isAdd) {
                    await apiClient.X12("X12EntityIdentifierCodes").create(this.currentObject)
                    showDialog('Created Successfully')
                }
                    this.$router.push("/admin-panel/definitions/entityIdentifierCodes")
            } catch (error) {
                showDialog('Could not update.', error)
            } finally {
                this.isLoading = false
            }
        },
    },
}
</script>