var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "subscribe-wrap-v2 section-gap primary" },
    [
      _c(
        "v-container",
        { attrs: { "grid-list-xl": "", "py-0": "" } },
        [
          _c("div", { staticClass: "center-icon" }, [
            _c("i", { staticClass: "material-icons" }, [_vm._v("mail")])
          ]),
          _c(
            "v-layout",
            {
              attrs: {
                row: "",
                wrap: "",
                "align-center": "",
                "justify-center": ""
              }
            },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "", sm9: "", md7: "", lg5: "", xl4: "" } },
                [
                  _c("div", { staticClass: "subscribe-content" }, [
                    _c("div", [
                      _c("h3", { staticClass: "white--text" }, [
                        _vm._v(" " + _vm._s(_vm.heading) + " ")
                      ]),
                      _c("p", { staticClass: "white--text" }, [
                        _vm._v(" " + _vm._s(_vm.description) + " ")
                      ])
                    ])
                  ]),
                  _c(
                    "div",
                    { staticClass: "form-wrapper" },
                    [
                      _c("v-text-field", {
                        staticClass: "mt-0 pt-0",
                        attrs: {
                          dark: "",
                          color: "white",
                          label: "Your Email Address",
                          required: ""
                        },
                        model: {
                          value: _vm.email,
                          callback: function($$v) {
                            _vm.email = $$v
                          },
                          expression: "email"
                        }
                      }),
                      _c(
                        "a",
                        {
                          staticClass: "send-icon",
                          attrs: { heref: "javascript:void(0)" }
                        },
                        [
                          _c(
                            "i",
                            { staticClass: "material-icons white--text" },
                            [_vm._v("send")]
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }