<template>
  <div>
    <!-- refresh button -->
    <div class="d-flex justify-end align-center mb-3">
      <v-btn icon @click="refresh">
        <v-icon>refresh</v-icon>
      </v-btn>
    </div>

    <!-- order table -->
    <v-card>
      <v-data-table
        :headers="headers"
        :items="orders.items"
        :loading="isLoadingOrders"
        hide-default-footer
      >
        <template v-slot:item.createdDate="{ item }">
          {{ getOrderCreatedDate(item) }}
        </template>

        <template v-slot:item.expectedDeliveryDate="{ item }">
          {{ getOrderExpectedDeliveryDate(item) }}
        </template>

        <template v-slot:item.lastEditedDate="{ item }">
          {{ getOrderLastEditedDate(item) }}
        </template>

        <template v-slot:item.lastEditedBy="{ item }">
          {{ getLastEditedByPerson(item) }}
        </template>

        <template v-slot:item.status="{ item }">
          <span v-if="item.isOrderSubmitted" style="color: green"
            >Submitted</span
          >
          <span v-else style="color: gray">Pending</span>
        </template>

        <template v-slot:item.action="{ item }">
          <v-btn
            small
            icon
            class="text--accent-1"
            @click="showOrderViewDialog(item)"
          >
            <v-icon>visibility</v-icon>
          </v-btn>
        </template>
      </v-data-table> </v-card
    ><!-- order table -->

    <!-- order view dialog -->
    <v-dialog v-model="isShowingOrderViewDialog" max-width="980">
      <v-card flat>
        <v-card-text class="pt-6">
          <account-order-view :order="lastSelectedOrder"></account-order-view>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn
            v-if="!isAnyLicenseExpired"
            color="error"
            @click="showReorderDialog(lastSelectedOrder)"
            >Reorder</v-btn
          >
          <v-btn color="primary" @click="isShowingOrderViewDialog = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card> </v-dialog
    ><!-- order view dialog -->

    <!-- reorder dialog -->
    <v-dialog v-model="isShowingReorderDialog" max-width="500">
      <v-card>
        <v-card-text>
          <v-card-title>Do you want to make this order again?</v-card-title>
          <p>
            All the products from this order will be added to your cart. You can
            then continue adding more products or place your order.
          </p>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn
            color="error"
            :loading="isDoingReorder"
            :disabled="isDoingReorder"
            @click="reorder(orderToReorder)"
          >
            Yes
          </v-btn>
          <v-btn
            color="primary"
            :disabled="isDoingReorder"
            @click="isShowingReorderDialog = false"
            >No</v-btn
          >
        </v-card-actions>
      </v-card> </v-dialog
    ><!-- reorder dialog -->
  </div>
</template>

<script>
/* eslint-disable */
import { mapMutations, mapActions, mapGetters } from "vuex";
import { utils } from "../../../../mixins";
import AccountOrderView from "./AccountOrderView";

export default {
  mixins: [utils],

  props: ["account"],

  data() {
    return {
      collaborationList: null,
      open: false,
      select: null,
      valid: true,
      newLicense: {},
      loader: true,
      headers: [
        {
          text: "Order #",
          sortable: false,
          value: "orderId",
        },
        {
          text: "Created Date",
          sortable: false,
          value: "createdDate",
        },
        {
          text: "Expected Delivery Date",
          sortable: false,
          value: "expectedDeliveryDate",
        },
        {
          text: "Last Edited Date",
          sortable: false,
          value: "lastEditedDate",
        },
        {
          text: "Last Edited By",
          sortable: false,
          value: "lastEditedBy",
        },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "",
          sortable: false,
          value: "action",
        },
      ],
      selectDeletedItem: null,
      lastSelectedOrder: {},
      orderToReorder: {},
      isShowingOrderViewDialog: false,
      isShowingReorderDialog: false,
      isLoadingOrders: false,
      isDoingReorder: false,
      peopleById: [], // to show lastEditedBy in order table
    };
  },

  computed: {
    ...mapGetters({
      currentCustomer: "session/getAccount",
      orders: "order/getOrders",
      isAnyLicenseExpired: "session/isAnyLicenseExpired",
      isAnyLicenseActive: "session/isAnyLicenseActive",
    }),
  }, // computed

  mounted() {
    this.getOrdersRequest();

    console.log("->", this.isAnyLicenseExpired);
  }, // mounted

  methods: {
    ...mapMutations({
      addToCart: "order/ADD_TO_CART",
    }),
    ...mapActions({
      getOrders: "order/getOnlyFinalized",
      getPerson: "people/getById",
      makeReorder: "order/reorder",
    }),
    ...mapMutations({ setSelectedOrder: "order/SET_ORDER" }),

    getOrdersRequest() {
      this.isLoadingOrders = true;
      this.getOrders(this.currentCustomer.customerId).finally(
        () => (this.isLoadingOrders = false)
      );
    },

    getOrderCreatedDate(order) {
      if (!order || !order.orderDate) {
        return "";
      }

      let dateUtc = order.orderDate.toString();
      let formattedDate = utils.methods.getFormattedDateUtcShort(dateUtc);
      return formattedDate;
    },

    getOrderLastEditedDate(order) {
      if (!order || !order.lastEditedWhen) {
        return "";
      }

      let dateUtc = order.lastEditedWhen.toString();
      let formattedDate = utils.methods.getFormattedDateUtcShort(dateUtc);
      return formattedDate;
    },

    getOrderExpectedDeliveryDate(order) {
      if (!order || !order.expectedDeliveryDate) {
        return "";
      }

      let dateUtc = order.expectedDeliveryDate.toString();
      let formattedDate = utils.methods.getFormattedDateUtcShort(dateUtc);
      return formattedDate;
    },

    getLastEditedByPerson(order) {
      if (!this.peopleById) return "--------------------";

      let person = this.peopleById.find(
        (person) => person.personId == order.lastEditedBy
      );

      if (!person) return "--------------------";

      return person.fullName;
    },

    showOrderViewDialog(order) {
      this.lastSelectedOrder = order;
      this.isShowingOrderViewDialog = true;
    },

    showReorderDialog(order) {
      this.orderToReorder = order;
      this.isShowingReorderDialog = true;
    },

    refresh() {
      this.getOrdersRequest();
    },

    reorder(order) {
      this.isDoingReorder = true;

      let orderLinesToAdd = order.orderLines.map((orderLine) => {
        let cartEntry = {
          quantity: orderLine.quantity,
          stockItem: orderLine.stockItem,
          stockItemId: +orderLine.stockItem.stockItemId,
        };

        this.addToCart(cartEntry);
      });

      this.isDoingReorder = false;
      this.isShowingReorderDialog = false;
      order.dialog = false;
    },
  }, // methods

  watch: {
    orders: {
      handler() {
        let promises = this.orders.items.map((order) =>
          this.getPerson(order.lastEditedBy)
        );

        Promise.all(promises).then((result) => {
          this.peopleById = result.map((res) => res.data);
        });
      },
    },
  },

  components: { AccountOrderView },
};
</script>
