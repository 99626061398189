/* eslint-disable */

import api from '@/api';

export default {
    namespaced: true,

    state: {
        moduleName: 'email',
        emailRequest: {
            firstName: '',
            lastName: '',
            emailAddressFrom: '',
            emailAddressTo: '',
            subject: '',
            message: '',
        },
    }, // state

    getters: {
        getEmailRequest: state => state.emailRequest,
    }, // getters

    mutations: {
        SET_EMAIL_REQUEST(state, emailRequest) {
            state.emailRequest = emailRequest;
        },
        SET_EMAIL_REQUEST_FIRST_NAME(state, firstName) {
            state.firstName = firstName;
        },
        SET_EMAIL_REQUEST_LAST_NAME(state, lastName) {
            state.lastName = lastName;
        },
        SET_EMAIL_REQUEST_EMAIL_ADDRESS_FROM(state, emailAddressFrom) {
            state.emailAddressFrom = emailAddressFrom;
        },
        SET_EMAIL_REQUEST_EMAIL_ADDRESS_TO(state, emailAddressTo) {
            state.emailAddressTo = emailAddressTo;
        },
        SET_EMAIL_REQUEST_SUBJECT(state, subject) {
            state.subject = subject;
        },
        SET_EMAIL_REQUEST_MESSAGE(state, message) {
            state.message = message;
        },
        RESET_EMAIL_REQUEST(state) {
            state.emailRequest.firstName = '';
            state.emailRequest.lastName = '';
            state.emailRequest.emailAddressFrom = '';
            state.emailRequest.emailAddressTo = '';
            state.emailRequest.subject = '';
            state.emailRequest.message = '';
        },
    }, // mutations

    actions: {
        sendEmail({ state, commit }) {
            return api.post(`${state.moduleName}`, state.emailRequest);
        },
        
        sendInvitationEmail({ state, commit }, emailAddress) {
            return api.post(`${state.moduleName}/invite`, { emailAddressTo: emailAddress });
        },

        sendOrderConfirmationEmail({ state, commit }, { orderId, cartSnapshot }) {
            const request = {
                brandName: process.env.VUE_APP_BRAND,
                orderId,
                orderLines: cartSnapshot,
            };
        
            console.log('Request for order confirmation email is:', request);
        
            return api.post(`${state.moduleName}/order-confirmation`, request);
        },
        
    }, // actions
}
