import vue from 'vue'
export function showDialog(message, error) {
    if (!error) {
        vue.prototype.$snotify.success(`${message}`, {
            closeOnClick: false,
            pauseOnHover: false,
            timeout: 4000,
        });
        return;
    }
    vue.prototype.$snotify.error(`${message} ${error.message}`, {
        closeOnClick: false,
        pauseOnHover: false,
        timeout: 4000,
    });
}