<template>
  <div>
    <AdminPanelHeader
      icon="mdi-file"
      title="File Types"
      :actions="headerActions"
      @search="onSearch"
    />
    <FileTypeTable
      v-on:onDelete="onDelete"
      :paginatedList="currentEntity"
      :isLoading="isLoading"
      :searchParams="paginatedQueryParams"
    />
  </div>
</template>
<script>
import apiClient from "../../../../apiClient";
import { AdminPanelHeaderAction } from "../../../../components/AdminPanelHeader/AdminPanelHeaderAction";
import { parentComponentMixin } from "../../../../mixins/parentComponentMixin";
import { showDialog } from "../../../../components/Dialogs/NotifyDialog";
export default {
  mixins: [parentComponentMixin],
  name: "FileType",
  data() {
    return {
      paginatedQueryParams: {
        pageSize: 10,
        pageNumber: 0,
        searchValue: "",
        sortField: "x12fileTypeId",
        sortDirection: "DESC",
      },
      headerActions: [
        new AdminPanelHeaderAction({
          color: "accent",
          label: "Add New",
          icon: "add",
          action: () =>
            this.$router.push("/admin-panel/definitions/fileTypes/add"),
        }),
      ],
    };
  },
  methods: {
    async onDelete(id) {
      try {
        this.isLoading = true;
        await apiClient.X12("X12FileTypes").delete(id);
        showDialog("Deleted successfully");
        await this.getPaginated();
      } catch (error) {
        showDialog("Could not delete item", error);
      }finally{
        this.isLoading = false;
      }
    },
    async getPaginated() {
      try {
        this.isLoading = true;
        this.currentEntity = await apiClient.X12("X12FileTypes").getPaginated(
          this.paginatedQueryParams
        );
      } catch (error) {
        showDialog("Could not get data", error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>