<template>
  <div>
    <Breadcrumbs class="mb-4" :items="breadcrumbs" />

    <!-- <div class="d-flex mb-6">
      <router-link class="font-weight-medium" to="/admin-panel/people-routes"
        >Back</router-link
      >
    </div> -->

    <div class="center-x" v-if="isLoading">
      <v-skeleton-loader type="text" width="100%" />
    </div>
    <v-card>
      <v-card-title>Person Routes</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              label="Route Id"
              v-model="personModel.routeId"
              :disabled="(readOnly && !isEdit) || (!isEdit && !isView)"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              label="routeName"
              v-model="personModel.routeName"
              :disabled="readOnly"
              required
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              label="Route Type"
              v-model="personModel.routeType"
              :disabled="readOnly"
              required
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              required
              label="Priority Id"
              v-model="personModel.priorityId"
              :disabled="readOnly"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              label="Status"
              v-model="personModel.status"
              :disabled="readOnly"
              required
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              label="Internal Id"
              v-model="personModel.internalId"
              :disabled="readOnly"
              required
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-row>
      <v-col>
        <div class="d-flex justify-end" v-if="!isView">
          <v-btn color="error" large @click="createPersonRoute">Save</v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import apiClient from "../../../apiClient";
import moment from "moment";
import { BreadcrumbItem } from "../../../components/Breadcrumbs/BreadcrumbItem";
import { StaticRoutes } from "../../../router/Routes";
export default {
  data() {
    return {
      valid: true,
      emptyRule: [(v) => !!v || "Value is required"],
      personModel: {},
      isLoading: false,
      personRouteId: undefined,
      isEdit: undefined,
      isView: undefined,
      readOnly: false,
      breadcrumbs: [
        new BreadcrumbItem({
          label: "People routes",
          route: StaticRoutes.adminPanel.routes,
        }),
        new BreadcrumbItem({ label: "Routes Details" }),
      ],
    };
  },

  mounted() {
    this.isView = this.$route.path.includes("view");
    this.isEdit = this.$route.path.includes("edit");

    if (this.isEdit || this.isView) {
      this.personRouteId = this.$route.params.id;
      this.getCurrentRoute();
      this.readOnly = true;
    }
  },

  methods: {
    getFormattedDate(date) {
      // return moment.utc(date).format("MMMM Do YYYY");
      return moment(new Date(date.toString())).format("MMMM Do YYYY");
    },
    async createPersonRoute() {
      try {
        if (Object.keys(this.personModel).length === 0) {
          this.$snotify.error("Must have at least a value", {
            closeOnClick: false,
            pauseOnHover: false,
            timeout: 4000,
          });
        } else {
          apiClient.routes.createAsync(this.personModel);
          this.$router.replace("/admin-panel/people-routes");
        }
      } catch (error) {
        this.$snotify.error(`Could not save new route. ${error}`, {
          closeOnClick: false,
          pauseOnHover: false,
          timeout: 4000,
        });
      }
    },
    async getCurrentRoute() {
      try {
        this.isLoading = true;
        this.personModel = await apiClient.routes.getByIdAsync(
          this.personRouteId
        );
      } catch (error) {
        this.$snotify.error(`Could load current route. ${error}`, {
          closeOnClick: false,
          pauseOnHover: false,
          timeout: 4000,
        });
      }
      this.isLoading = false;
    },
  },
};
</script>