export const formsMixin = {
    data() {
        return {
            isAdd: false,
            isEdit: false,
            isView:false,
            isLoading: false,
            id: null,
            currentObject: {},
        }
    },
    mounted() {
        this.isAdd = this.$route.path.includes("add");
        this.isEdit = this.$route.path.includes("edit");
        this.isView = this.$route.path.includes("detail");
        if (this.isEdit || this.isView) {
            this.id = this.$route.params.id;
            this.getById();
        }
        
    }
}