var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "pagination-container" },
      [
        _c(
          "span",
          {
            staticClass: "first",
            class: { disabled: _vm.disabled || _vm.currentPageIndex == 1 },
            on: {
              click: function($event) {
                return _vm.firstPage()
              }
            }
          },
          [
            _c("v-icon", { attrs: { left: "" } }, [
              _vm._v("mdi-chevron-double-left")
            ])
          ],
          1
        ),
        _c(
          "span",
          {
            staticClass: "previous",
            class: { disabled: _vm.disabled || _vm.currentPageIndex == 1 },
            on: {
              click: function($event) {
                return _vm.previousPage()
              }
            }
          },
          [_c("v-icon", { attrs: { left: "" } }, [_vm._v("mdi-chevron-left")])],
          1
        ),
        _vm._l(7 + _vm.leftMostPage, function(index) {
          return _c(
            "div",
            {
              key: index,
              on: {
                click: function($event) {
                  return _vm.changePage(index)
                }
              }
            },
            [
              _c(
                "a",
                {
                  class: {
                    disabled: _vm.disabled,
                    selected: index == _vm.currentPageIndex,
                    show: index > _vm.totalPageCount || index < _vm.leftMostPage
                  }
                },
                [_vm._v(" " + _vm._s(index) + " ")]
              )
            ]
          )
        }),
        _c(
          "span",
          {
            staticClass: "next",
            class: {
              disabled:
                _vm.disabled || _vm.currentPageIndex == _vm.totalPageCount
            },
            on: {
              click: function($event) {
                return _vm.nextPage()
              }
            }
          },
          [
            _c("v-icon", { attrs: { left: "" } }, [_vm._v("mdi-chevron-right")])
          ],
          1
        ),
        _c(
          "span",
          {
            staticClass: "last",
            class: {
              disabled:
                _vm.disabled || _vm.currentPageIndex == _vm.totalPageCount
            },
            on: {
              click: function($event) {
                return _vm.lastPage()
              }
            }
          },
          [
            _c("v-icon", { attrs: { left: "" } }, [
              _vm._v("mdi-chevron-double-right")
            ])
          ],
          1
        )
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }