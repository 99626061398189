<template>
    <div>
        <Breadcrumbs class="mb-4" :items="breadcrumbs" />

        <div class="center-x" v-if="isLoading">
            <v-skeleton-loader type="text" width="100%"/>
        </div>
        <v-card>
            <v-card-title>Interchange Trading Partner Type</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" sm="6" v-if="!isAdd">
                        <v-text-field
                            label="Interchange Trading Partner Type Id"
                            v-model="currentObject.interchangeTradingPartnerTypeId"
                            disabled
                        />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field
                            label="Interchange Trading Partner Type Description"
                            v-model="currentObject.interchangeTradingPartnerTypeDescription"
                        />
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-row>
            <v-col>
                <div class="d-flex justify-end">
                    <v-btn color="error" large @click="() => save()">Save</v-btn>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import apiClient from "../../../../apiClient";
import { BreadcrumbItem } from "../../../../components/Breadcrumbs/BreadcrumbItem";
import { StaticRoutes } from "../../../../router/Routes";
import { showDialog } from '../../../../components/Dialogs/NotifyDialog';
import {formsMixin} from '../../../../mixins/formsMixin'

export default {
    mixins:[formsMixin],
    name: "FunctionalCodesForm",
    data() {
        return {
            breadcrumbs: [
                new BreadcrumbItem({
                    label: "Interchange Trading Partner Types",
                    route: StaticRoutes.adminPanel.trading.x12Definitions.interchangeTradingPartnerTypes
                }),
                new BreadcrumbItem({ label: "Interchange Trading Partner Type Details" }),
            ],
        };
    },

    methods: {
        async getById() {
            try {
                this.isLoading = true
                this.currentObject = await apiClient.X12("X12InterchangeTradingPartnerTypes").getById(this.id)
            } catch (error) {
                showDialog('Could not load Interchange Trading Partner Type.', error)
            } finally {
                this.isLoading = false
            }
        },

        async save() {
            try {
                this.isLoading = true
                if (this.isEdit) {
                    await apiClient.X12("X12InterchangeTradingPartnerTypes").update(this.id,this.currentObject)
                    showDialog('Updated Successfully')
                }
                if (this.isAdd) {
                    await apiClient.X12("X12InterchangeTradingPartnerTypes").create(this.currentObject)
                    showDialog('Created Successfully')
                }
                    this.$router.push("/admin-panel/definitions/interchangeTradingPartnerTypes")
            } catch (error) {
                showDialog('Could not update.', error)
            } finally {
                this.isLoading = false
            }
        },
    }
}

</script>