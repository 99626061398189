var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "collection-gallery-wrap section-gap overlay-section" },
    [
      _c(
        "v-container",
        { attrs: { "grid-list-xl": "" } },
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "", sm12: "", md4: "", lg3: "", xl3: "" } },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "main-bg overlay-section-item overlay-section-overlay relative",
                      staticStyle: {
                        "background-image":
                          "url('static/images/collection.jpg')",
                        "background-size": "cover"
                      }
                    },
                    [
                      _c("div", { staticClass: "end-left" }, [
                        _c(
                          "div",
                          { staticClass: "overlay-section-content" },
                          [
                            _c("h4", { staticClass: "mb-0 font-bold" }, [
                              _vm._v("Latest Collections")
                            ]),
                            _c(
                              "h4",
                              { staticClass: " text-xxl font-normal mb-4" },
                              [_vm._v("Save Upto 60%")]
                            ),
                            _c(
                              "v-btn",
                              {
                                staticClass: "ma-0",
                                attrs: { to: "/products" }
                              },
                              [_vm._v("SHOP NOW")]
                            )
                          ],
                          1
                        )
                      ])
                    ]
                  )
                ]
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "", sm12: "", md8: "", lg9: "", xl9: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        {
                          attrs: {
                            xs12: "",
                            sm12: "",
                            md6: "",
                            lg6: "",
                            xl6: ""
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "main-bg-inner overlay-section-item overlay-section-overlay relative",
                              staticStyle: {
                                "background-image":
                                  "url('static/images/col-men.jpg')"
                              }
                            },
                            [
                              _c("div", { staticClass: "end-left" }, [
                                _c(
                                  "div",
                                  { staticClass: "overlay-section-content" },
                                  [
                                    _c(
                                      "h4",
                                      { staticClass: "mb-0 font-bold" },
                                      [_vm._v("Men's Collections")]
                                    ),
                                    _c(
                                      "h4",
                                      {
                                        staticClass:
                                          " text-xxl font-normal mb-4"
                                      },
                                      [_vm._v("Save Upto 50%")]
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "ma-0",
                                        attrs: { to: "/products" }
                                      },
                                      [_vm._v("SHOP NOW")]
                                    )
                                  ],
                                  1
                                )
                              ])
                            ]
                          )
                        ]
                      ),
                      _c(
                        "v-flex",
                        {
                          attrs: {
                            xs12: "",
                            sm12: "",
                            md6: "",
                            lg6: "",
                            xl6: ""
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "main-bg-inner overlay-section-item overlay-section-overlay relative",
                              staticStyle: {
                                "background-image":
                                  "url('static/images/col-women.jpg')"
                              }
                            },
                            [
                              _c("div", { staticClass: "end-left" }, [
                                _c(
                                  "div",
                                  { staticClass: "overlay-section-content" },
                                  [
                                    _c(
                                      "h4",
                                      { staticClass: "mb-0 font-bold" },
                                      [_vm._v("Women's Collections")]
                                    ),
                                    _c(
                                      "h4",
                                      {
                                        staticClass:
                                          " text-xxl font-normal mb-4"
                                      },
                                      [_vm._v("Save Upto 50%")]
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "ma-0",
                                        attrs: { to: "/products" }
                                      },
                                      [_vm._v("SHOP NOW")]
                                    )
                                  ],
                                  1
                                )
                              ])
                            ]
                          )
                        ]
                      ),
                      _c(
                        "v-flex",
                        {
                          attrs: {
                            xs12: "",
                            sm12: "",
                            md6: "",
                            lg6: "",
                            xl6: ""
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "main-bg-inner overlay-section-item overlay-section-overlay relative",
                              staticStyle: {
                                "background-image":
                                  "url('static/images/col-watches.jpg')",
                                "background-size": "cover"
                              }
                            },
                            [
                              _c("div", { staticClass: "end-left" }, [
                                _c(
                                  "div",
                                  { staticClass: "overlay-section-content" },
                                  [
                                    _c(
                                      "h4",
                                      { staticClass: "mb-0 font-bold" },
                                      [_vm._v("Watches Collections")]
                                    ),
                                    _c(
                                      "h4",
                                      {
                                        staticClass:
                                          " text-xxl font-normal mb-4"
                                      },
                                      [_vm._v("Save Upto 60%")]
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "ma-0",
                                        attrs: { to: "/products" }
                                      },
                                      [_vm._v("SHOP NOW")]
                                    )
                                  ],
                                  1
                                )
                              ])
                            ]
                          )
                        ]
                      ),
                      _c(
                        "v-flex",
                        {
                          attrs: {
                            xs12: "",
                            sm12: "",
                            md6: "",
                            lg6: "",
                            xl6: ""
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "main-bg-inner overlay-section-item overlay-section-overlay relative",
                              staticStyle: {
                                "background-image":
                                  "url('static/images/col-accessories.jpg')",
                                "background-size": "cover"
                              }
                            },
                            [
                              _c("div", { staticClass: "end-left" }, [
                                _c(
                                  "div",
                                  { staticClass: "overlay-section-content" },
                                  [
                                    _c(
                                      "h4",
                                      { staticClass: "mb-0 font-bold" },
                                      [_vm._v("Accessories Collections")]
                                    ),
                                    _c(
                                      "h4",
                                      {
                                        staticClass:
                                          " text-xxl font-normal mb-4"
                                      },
                                      [_vm._v("Save Upto 60%")]
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "ma-0",
                                        attrs: { to: "/products" }
                                      },
                                      [_vm._v("SHOP NOW")]
                                    )
                                  ],
                                  1
                                )
                              ])
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }