<template>
  <div>
    <Breadcrumbs class="mb-4" :items="breadcrumbs" />

    <div class="center-x" v-if="isLoading">
      <v-skeleton-loader type="text" width="100%" />
    </div>
    <v-card>
      <v-card-title>Functional Code</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" v-if="!isAdd">
            <v-text-field
              label="Functional Code Id"
              v-model.number="currentObject.functionalCodeId"
              disabled
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              label="Functional Code"
              v-model="currentObject.functionalCode"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              label="Functional Definition"
              v-model="currentObject.functionalDefinition"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-row>
      <v-col>
        <div class="d-flex justify-end">
          <v-btn color="error" large @click="() => save()">Save</v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import apiClient from "../../../../apiClient";
import { BreadcrumbItem } from "../../../../components/Breadcrumbs/BreadcrumbItem";
import { StaticRoutes } from "../../../../router/Routes";
import { showDialog } from '../../../../components/Dialogs/NotifyDialog';
import { formsMixin } from '../../../../mixins/formsMixin'

export default {
    mixins:[formsMixin],
    name: "FunctionalCodesForm",
    data() {
        return {
            breadcrumbs: [
                new BreadcrumbItem({
                    label: "Functional Codes",
                    route: StaticRoutes.adminPanel.trading.x12Definitions.functionalCodes
                }),
                new BreadcrumbItem({ label: "Functional Code Details" }),
            ],
        };
    },

    methods: {
        async getById() {
            try {
                this.isLoading = true
                this.currentObject = await apiClient.X12("X12FunctionalCodes").getById(this.id)
            } catch (error) {
                showDialog(`Could not load functional code.`, error)
            } finally {
                this.isLoading = false
            }
        },
    async save() {
      try {
        this.isLoading = true;
        if (this.isEdit) {
          await apiClient
            .X12("X12FunctionalCodes")
            .update(this.id, this.currentObject);
          showDialog("Updated Successfully");
        }
        if (this.isAdd) {
          await apiClient.X12("X12FunctionalCodes").create(this.currentObject);
          showDialog("Created Successfully");
        }
        this.$router.push("/admin-panel/definitions/functionalCodes");
      } catch (error) {
        showDialog("Could not update.", error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>