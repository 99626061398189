var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("AdminPanelHeader", {
        attrs: { title: "Orders", icon: "local_shipping", refreshButton: true },
        on: { refresh: _vm.onRefresh, search: _vm.onSearch }
      }),
      _c("OrdersListView", {
        attrs: {
          paginatedList: _vm.ordersPaginatedList,
          isLoading: _vm.isLoading,
          searchParams: _vm.searchParams
        },
        on: { "search-params-changed": _vm.onSearchParamsChanged }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }