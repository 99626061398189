var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "emb-aboutus-wrap" },
    [
      _c("emb-page-title", {
        attrs: {
          heading: "About Us",
          subHeading: "Creative team and wide goals."
        }
      }),
      _c(
        "div",
        { staticClass: "emb-about-content" },
        [
          _c(
            "div",
            { staticClass: "about-page section-gap" },
            [
              _c(
                "v-container",
                { attrs: { "grid-list-xl": "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "", "align-center": "" } },
                    [
                      _c(
                        "v-flex",
                        {
                          attrs: {
                            xs12: "",
                            sm12: "",
                            md12: "",
                            lg6: "",
                            xl6: "",
                            "pr-5": ""
                          }
                        },
                        [
                          _c(
                            "h4",
                            { staticClass: "font-weight-regular mb-10" },
                            [
                              _vm._v(
                                "Purchased in 1978 by Joe LaMantia Jr., L&F Distributors began as a family business with a single location in McAllen, TX and less than 20 employees. Being an Anheuser- Busch wholesaler, L&F focused on mega-brands such as Budweiser, Bud Light and Michelob. As the brands and the distributorship grew, L&F acquired the rights to the surrounding counties including the cities of Harlingen, Alice and Laredo. With the acquisition of the Laredo distributorship, L&F became the primary beer distributor along the South Texas border with Mexico."
                              )
                            ]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "accent mx-0",
                              attrs: { large: "" },
                              on: { click: _vm.onReadMoreClicked }
                            },
                            [_vm._v(" Read More ")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        {
                          attrs: {
                            xs12: "",
                            md12: "",
                            lg6: "",
                            xl6: "",
                            "text-lg-left": "",
                            "text-center": ""
                          }
                        },
                        [
                          _c("img", {
                            attrs: {
                              alt: "About us Figure",
                              src: "/static/images/about-us.jpg",
                              width: "700",
                              height: "700"
                            }
                          })
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "about-info section-gap bg-grey" },
            [
              _c(
                "v-container",
                { attrs: { "grid-list-xl": "", "py-0": "" } },
                [
                  _c(
                    "v-layout",
                    {
                      attrs: {
                        row: "",
                        wrap: "",
                        "align-center": "",
                        "mb-md-0": "",
                        "mb-6": ""
                      }
                    },
                    [
                      _c(
                        "v-flex",
                        {
                          attrs: {
                            xl6: "",
                            sm12: "",
                            xs12: "",
                            md6: "",
                            lg6: "",
                            "text-md-left": "",
                            "text-center": ""
                          }
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: "/static/images/our-mission.jpg",
                              alt: "mission",
                              width: "900",
                              height: "700"
                            }
                          })
                        ]
                      ),
                      _c(
                        "v-flex",
                        {
                          staticClass: "pl-12",
                          attrs: { xl6: "", xs12: "", md6: "", lg6: "" }
                        },
                        [
                          _c("h2", { staticClass: "font-weight-bold" }, [
                            _vm._v(" Our Mission ")
                          ]),
                          _c("h5", { staticClass: "font-italic" }, [
                            _vm._v("We are a Family Business.")
                          ]),
                          _c("p", [
                            _vm._v(
                              "Our Mission is to provide the best service to our customers, fully support our community, be the trusted source of knowledge for our industry, and provide an ideal place of employment."
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-layout",
                    {
                      attrs: {
                        row: "",
                        wrap: "",
                        "align-center": "",
                        reverse: ""
                      }
                    },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xl6: "", xs12: "", md6: "", lg6: "" } },
                        [
                          _c("img", {
                            attrs: {
                              src: "/static/images/our-vision.jpg",
                              alt: "vission",
                              width: "900",
                              height: "700"
                            }
                          })
                        ]
                      ),
                      _c(
                        "v-flex",
                        {
                          staticClass: "pr-12",
                          attrs: { xl6: "", xs12: "", md6: "", lg6: "" }
                        },
                        [
                          _c("h2", { staticClass: "font-bold" }, [
                            _vm._v(" Our Vision ")
                          ]),
                          _c("h5", { staticClass: "font-italic" }, [
                            _vm._v("Humble. Aggressive. Honest")
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("TestimonialV2"),
          _c(
            "div",
            {
              staticClass:
                "team-section text-center section-gap blue-grey lighten-5"
            },
            [
              _c(
                "v-container",
                { attrs: { "grid-list-xl": "", "text-xl-center": "" } },
                [
                  _c("div", { staticClass: "sec-title" }, [
                    _c("h2", [_vm._v("Meet Our Crew")])
                  ]),
                  _c(
                    "v-layout",
                    {
                      attrs: {
                        row: "",
                        wrap: "",
                        "align-center": "",
                        "justify-center": ""
                      }
                    },
                    _vm._l(_vm.teamListing, function(teamMembers, key) {
                      return _c(
                        "v-flex",
                        {
                          key: key,
                          attrs: {
                            xs12: "",
                            sm12: "",
                            md4: "",
                            lg4: "",
                            xl4: "",
                            "text-center": "",
                            "mb-sm-0": "",
                            "mb-3": ""
                          }
                        },
                        [
                          _c("div", { staticClass: "team-card mb-6" }, [
                            _c("img", {
                              staticClass: "br-100",
                              attrs: {
                                src: teamMembers.image,
                                width: "200",
                                height: "200",
                                alt: "team-image"
                              }
                            })
                          ]),
                          _c("div", { staticClass: "team-content" }, [
                            _c("h4", { staticClass: "mb-0" }, [
                              _vm._v(_vm._s(teamMembers.name))
                            ]),
                            _c("p", { staticClass: "mb-6" }, [
                              _vm._v(_vm._s(teamMembers.designation))
                            ]),
                            _c("p", { staticClass: "mb-6" }, [
                              _vm._v(_vm._s(teamMembers.description))
                            ])
                          ])
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "about-contact-form" },
            [
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c("v-flex", {
                    staticClass: " about-contact-bg",
                    attrs: { xs12: "", sm12: "", md6: "", lg6: "", xl6: "" }
                  }),
                  _c(
                    "v-flex",
                    {
                      attrs: {
                        xs12: "",
                        sm12: "",
                        md6: "",
                        lg6: "",
                        xl6: "",
                        "section-gap": ""
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "about-contact-form-inner" },
                        [
                          _c("div", [
                            _c("h2", { staticClass: "mb-3" }, [
                              _vm._v("Write to Us")
                            ])
                          ]),
                          _c(
                            "v-form",
                            {
                              ref: "form",
                              model: {
                                value: _vm.valid,
                                callback: function($$v) {
                                  _vm.valid = $$v
                                },
                                expression: "valid"
                              }
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  type: "text",
                                  placeholder: "First Name",
                                  rules: _vm.inputRules.basictextRules
                                }
                              }),
                              _c("v-text-field", {
                                attrs: {
                                  type: "text",
                                  placeholder: "Last Name",
                                  rules: _vm.inputRules.basictextRules
                                }
                              }),
                              _c("v-text-field", {
                                attrs: {
                                  type: "email",
                                  placeholder: "Email",
                                  rules: _vm.emailRules
                                }
                              }),
                              _c("v-text-field", {
                                attrs: {
                                  type: "text",
                                  placeholder: "Subject",
                                  rules: _vm.inputRules.basictextRules
                                }
                              }),
                              _c("v-textarea", {
                                attrs: {
                                  rows: "2",
                                  label: "Leave a Message",
                                  rules: _vm.inputRules.basictextRules
                                }
                              }),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "accent mx-0",
                                  attrs: { large: "" },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      return _vm.saveDetails($event)
                                    }
                                  }
                                },
                                [_vm._v("Send Message")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }