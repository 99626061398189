<template>
  <div>
    <v-dialog max-width="500px" class="pa-4" v-model="openDialog">
      <template v-slot:activator="{ on }">
        <v-btn
          v-if="!isView"
          color="primary"
          slot="activator"
          v-on="on"
          @click.stop="openSurveyDialog"
        >Add survey</v-btn>
      </template>
      <v-card>
        <v-card-title class="h4">{{dialogTitle}}</v-card-title>

        <div
          class="image-container d-flex justify-center grey darken-4 cursor-pointer"
          @click="capturePicture"
        >
          <img v-if="image" :src="image" />
          <v-icon v-if="!image" class="my-12" color="grey lighten-3" size="80">camera_alt</v-icon>
          <input
            @change="onImageSelected"
            id="cameraInput"
            type="file"
            accept="image/*"
            capture="camera"
            style="display: none"
          />
        </div>

        <v-card-text>
          <v-form ref="form" v-model="isFormValid" lazy-validation>
            <v-row>
              <v-col sm="6">
                <v-text-field
                  label="RB Shelves"
                  type="number"
                  v-model="rbShelves"
                  :disabled="isView"
                  required
                />
              </v-col>
              <v-col sm="6">
                <v-text-field
                  label="Monster Shelves"
                  type="number"
                  v-model="monsterShelves"
                  :disabled="isView"
                  required
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="6">
                <v-text-field
                  label="Rockstar Shelves"
                  type="number"
                  v-model="rockstarShelves"
                  :disabled="isView"
                  required
                />
              </v-col>
              <v-col sm="6">
                <v-text-field
                  label="Bang Shelves"
                  type="number"
                  v-model="bangShelves"
                  :disabled="isView"
                  required
                />
              </v-col>
            </v-row>
            <v-btn color="primary" class="mr-3" @click="openDialog = false">Close</v-btn>
            <v-btn
              v-if="!isView"
              color="error"
              @click="addItemToSurveyList({
                            image: image,
                            rbShelves: rbShelves,
                            monsterShelves: monsterShelves,
                            rockstarShelves: rockstarShelves,
                            bangShelves: bangShelves,
                        })"
            >Submit</v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-data-table :headers="headers" :items="data" hide-default-footer @click:row="editSurveyItem" />
  </div>
</template>

<script>
export default {
  props: ["data", "readOnly"], // props

  data() {
    return {
      isView: false,
      dialogTitle: "Add New Survey",
      openDialog: false,
      isFormValid: true,
      image: undefined,
      status: undefined,
      rbShelves: 0,
      monsterShelves: 0,
      rockstarShelves: 0,
      bangShelves: 0,
      loader: true,
      headers: [
        {
          text: "Shelf status",
          sortable: false,
          value: "status"
        },
        {
          text: "RB Shelves",
          sortable: false,
          value: "rbShelves"
        },
        {
          text: "Monster Shelves",
          sortable: false,
          value: "monsterShelves"
        },
        {
          text: "Rockstar Shelves",
          sortable: false,
          value: "rockstarShelves"
        },
        {
          text: "Bang Shelves",
          sortable: false,
          value: "bangShelves"
        }
      ],
      options: {
        page: 1,
        itemsPerPage: 10
      }
    };
  }, // data

  mounted() {
    this.isView = this.$route.path.includes("view") || this.readOnly;
  },

  methods: {
    capturePicture() {
      if (this.isView) {
        return;
      }

      document.getElementById("cameraInput").click();
    },

    onImageSelected($event) {
      let selectedImages = $event.target.files;
      if (selectedImages.length == 0) {
        return;
      }
      let selectedImage = selectedImages[0];
      this.image = URL.createObjectURL(selectedImage);
    },

    openSurveyDialog() {
      this.dialogTitle = "Add New Survey";
      this.openDialog = true;
      this.image = undefined;
      this.openDialog = false;
      this.rbShelves = 0;
      this.monsterShelves = 0;
      this.rockstarShelves = 0;
      this.bangShelves = 0;
    },

    addItemToSurveyList(item) {
      if (this.$refs.form.validate()) {
        if (
          item.rbShelves >
          Math.max(item.monsterShelves, item.rockstarShelves, item.bangShelves)
        ) {
          item.status = "Winning";
        } else if (
          item.rbShelves ==
          Math.max(item.monsterShelves, item.rockstarShelves, item.bangShelves)
        ) {
          item.status = "Tied";
        } else {
          item.status = "Loosing";
        }

        this.data.push(item);
        this.image = undefined;
        this.openDialog = false;
        this.status = undefined;
        this.rbShelves = 0;
        this.monsterShelves = 0;
        this.rockstarShelves = 0;
        this.bangShelves = 0;

        this.$snotify.success("New survey has been added", {
          closeOnClick: false,
          pauseOnHover: false,
          timeout: 1000
        });
      } else {
        console.log("Invalid Inputs");
      }
    },

    editSurveyItem(event) {
      if (this.isView) {
        this.dialogTitle = "View Survey";
      } else {
        this.dialogTitle = "Edit Survey";
      }
      this.openDialog = true;
      this.image = event.image;
      this.status = event.status;
      this.rbShelves = event.rbShelves;
      this.monsterShelves = event.monsterShelves;
      this.rockstarShelves = event.rockstarShelves;
      this.bangShelves = event.bangShelves;
    }
  } // methods
};
</script>

<style scoped>
.image-container img {
  display: block;
  max-height: 250px;
  width: auto;
  height: auto;
}
</style>
