<template>
    <v-dialog v-model="isDialogOpened" max-width="500" persistent>
        <v-card>
            <v-card-title>{{title}}</v-card-title>
            <v-divider/>

            <v-card-text class="mt-6">
                <p class="ma-0">{{message}}</p>
            </v-card-text>

            <v-divider/>
            <v-card-actions>
                <v-spacer/>
                <v-btn
                    text color="primary"
                    @click.native="onYesClicked">
                    Yes
                </v-btn>
                <v-btn
                    text color="error"
                    @click.native="onNoClicked">
                    No
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {

  name: "YesNoDialog",

  data () {
    return {
      isDialogOpened: false,
      title: "Title",
      message: "This is a message",
      resolve: null,
      reject: null
    }
  }, // data

  methods: {
    open ({ title, message }) {
      this.isDialogOpened = true

      if (title) {this.title = title}
      if (message) {this.message = message}

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },

    onNoClicked () {
      if (this.reject) {this.reject()}
      this.isDialogOpened = false
    },

    onYesClicked () {
      if (this.resolve) {this.resolve()}
      this.isDialogOpened = false
    }
  } // methods
}
</script>
