<template>
	<div class="pa-4">
		<v-expansion-panels focusable multiple class="mb-4" :value="[0]">

			<!-- notifications -->
			<v-expansion-panel>
				<v-expansion-panel-header>Notifications</v-expansion-panel-header>
				<v-expansion-panel-content class="pt-3">
					<v-checkbox :disabled="isViewNotifications" class="ma-0 pa-0" label="Enable Notifications"/>
					<v-checkbox :disabled="isViewNotifications" class="ma-0 pa-0" label="Show Desktop Notifications"/>
					<v-checkbox :disabled="isViewNotifications" class="ma-0 pa-0" label="Enable SMS messages"/>
					<v-checkbox :disabled="isViewNotifications" class="ma-0 pa-0" label="Get e-mail notification for my own activity"/>
					<v-checkbox :disabled="isViewNotifications" class="ma-0 pa-0" label="Get e-mail notification for request and reviews"/>

					<div class="d-flex justify-end">
						<v-btn v-if="isViewNotifications" color="error" @click="editSettings">Edit</v-btn>
						<v-btn v-if="!isViewNotifications" color="primary" @click="saveSettings">Save</v-btn>
			`		</div>
				</v-expansion-panel-content>
			</v-expansion-panel><!-- notifications -->

			<!-- deactivate your account -->
			<v-expansion-panel>
				<v-expansion-panel-header>Deactivate your account</v-expansion-panel-header>
				<v-expansion-panel-content class="pt-3">
					<p>Specify the reason you are leaving:</p>
					<v-radio-group v-model="radios" :mandatory="false">
						<v-radio label="This is temporary. I'll be back." value="radio1"></v-radio>
						<v-radio label="My account was hacked." value="radio2"></v-radio>
						<v-radio label="I have a privacy concern." value="radio3"></v-radio>
						<v-radio label="Other" value="radio4"></v-radio>
					</v-radio-group>

					<div v-if="otherSelected">
						<p>If other, please explain:</p>
						<v-textarea rows="2" outlined auto-grow/>
						<v-btn color="error" @click="deactivateAccount()">Deactivate Account</v-btn>
					</div>
				</v-expansion-panel-content>
			</v-expansion-panel><!-- deactivate your account -->

		</v-expansion-panels>

		<!-- deactivate account dialog -->
		<emb-delete-confirmation-2
			ref="deleteConfirmationDialog"
			messageTitle="Are You Sure You Want To Delete?"
			messageDescription="Are you sure you want to delete this account permanently?" @onConfirm="onDeactivateAccount"
			btn1="Cancel"
			btn2="Yes"/>
		<!-- deactivate account dialog -->

	</div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
	data() {
		return {
			isViewNotifications: true,
			isChoosingImage: false,
			radios: "radio1",
			checkbox: false,
			otherSelected: true,
			profileImage: undefined,
    	};
	}, // data

	methods: {
    	deactivateAccount() {
      		this.$refs.deleteConfirmationDialog.openDialog();
		},
		
    	onDeactivateAccount() {
      		this.$refs.deleteConfirmationDialog.close();
		},

		editSettings() {
			this.isViewNotifications = false;
		},

		saveSettings() {
			this.isViewNotifications = true;
		},
	}, // methods

	computed: {
		...mapGetters({
			person: 'session/getPerson',
		}),
	}, // computed
};
</script>

<style>
.thumb-width {
	width: 70px !important ;
}

.account-text .v-text-field__details {
	display: none;
}

.hoverable:hover {
	cursor: pointer;
}
</style>
