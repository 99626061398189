var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "download-app py-2" }, [
    _c(
      "div",
      { staticClass: "container text-center" },
      [
        _c(
          "v-Layout",
          {
            attrs: { row: "", wrap: "", "text-center": "", "align-center": "" }
          },
          [
            _c(
              "v-flex",
              {
                attrs: {
                  xs12: "",
                  sm12: "",
                  md6: "",
                  lg6: "",
                  xl6: "",
                  "py-4": "",
                  "my-4": "",
                  "px-4": "",
                  "download-app-list": "",
                  "text-center": ""
                }
              },
              [
                _c("a", { attrs: { href: "javascript:void();" } }, [
                  _c("h4", { staticClass: "mb-0 font-weight-bold" }, [
                    _c("img", {
                      staticClass: "mr-4",
                      attrs: {
                        src: "static/images/apple.png",
                        alt: "16 x 16",
                        width: "22",
                        height: "22"
                      }
                    }),
                    _vm._v(" Download iOS App ")
                  ])
                ])
              ]
            ),
            _c(
              "v-flex",
              {
                attrs: {
                  xs12: "",
                  sm12: "",
                  md6: "",
                  lg6: "",
                  xl6: "",
                  "py-4": "",
                  "my-4": "",
                  "download-app-list": ""
                }
              },
              [
                _c("a", { attrs: { href: "javascript:void();" } }, [
                  _c("h4", { staticClass: "mb-0 font-bold" }, [
                    _c("img", {
                      staticClass: "mr-4",
                      attrs: {
                        src: "static/images/android-logo.png",
                        alt: "16 x 16",
                        width: "22",
                        height: "22"
                      }
                    }),
                    _vm._v(" Download Android App ")
                  ])
                ])
              ]
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }