var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "emb-gadget-wrap" },
    [
      _c(
        "v-flex",
        {
          attrs: {
            "d-none": "",
            "d-xs-none": "",
            "d-sm-none": "",
            "d-md-flex": ""
          }
        },
        [
          _c("emb-page-title", {
            attrs: { heading: _vm.imageTitle, imageType: _vm.imageType }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "gadget-content section-gap" },
        [
          _c(
            "v-container",
            { attrs: { "grid-list-xl": "", "py-0": "" } },
            [
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-flex",
                    {
                      attrs: {
                        "d-none": "",
                        "d-xs-none": "",
                        "d-sm-none": "",
                        "d-md-flex": "",
                        md4: "",
                        lg3: "",
                        xl3: ""
                      }
                    },
                    [_c("emb-sidebar-filters")],
                    1
                  ),
                  _c(
                    "v-flex",
                    {
                      attrs: { xs12: "", sm12: "", md8: "", lg9: "", xl9: "" }
                    },
                    [_c("product-items")],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }