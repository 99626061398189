<template>
    <div class="table-responsive" v-if="paginatedList">
        <v-data-table
            :headers="headers"
            :items="paginatedList.items"
            :server-items-length="paginatedList.totalCount"
            :options.sync="listViewOptions"
            :loading="isLoading"
            :footer-props="{ 'items-per-page-options': [10, 20, 30, 40, 50] }">

            <template v-slot:item.action="{ item }">
                <v-btn small icon class="primary--text" @click="showLicenseTypeDialog(item, true)">
                    <v-icon>edit</v-icon>
                </v-btn>
                <v-btn small icon class="primary--text" @click="showLicenseTypeDialog(item, false)">
                    <v-icon>visibility</v-icon>
                </v-btn>
            </template>
        
        </v-data-table>

        <!-- edit/view license type dialog -->
        <v-dialog v-model="isShowingLicenseTypeDialog" max-width="400">
            <v-card>
                <v-card-text>
                    <v-card-title v-if="isEditingLicenseType">Editing License</v-card-title>
                    <v-card-title v-else>Viewing License</v-card-title>

                    <v-text-field label="License Code" :disabled="!isEditingLicenseType" v-model="lastSelectedLicenseType.licenseCode"/>
                    <v-text-field label="License Code" :disabled="!isEditingLicenseType" v-model="lastSelectedLicenseType.licenseTypeDescription"/>
                </v-card-text>
                <v-card-actions class="d-flex justify-end">
                    <v-btn v-if="isEditingLicenseType == true" :loading="isDoingOperation" :disabled="isDoingOperation" color="primary" @click="editLicenseType">Edit</v-btn>
                    <v-btn :disabled="isDoingOperation" color="error" @click="isShowingLicenseTypeDialog = false">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog><!-- edit/view license type dialog -->

    </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {

    props: ['paginatedList', 'isLoading', 'searchParams'],

    data: () => ({
        headers: [
            {
                text: "Code",
                sortable: true,
                value: "licenseCode",
            },
            {
                text: "Description",
                sortable: true,
                value: "licenseTypeDescription",
            },
            {
                sortable: false,
                value: "action",
            },
        ],

        listViewOptions: {
            page: 1,
            itemsPerPage: 10,
        },

        isShowingLicenseTypeDialog: false,
        isEditingLicenseType: false,
        isDoingOperation: false,
        lastSelectedLicenseType: {},
    }), // data

    mounted() {
        this.listViewOptions.page = this.searchParams.pageNumber;
        this.listViewOptions.itemsPerPage = this.searchParams.pageSize;
    }, // mounted

    methods: {
        ...mapActions({
            updateLicenseType: 'licenseTypes/update',
        }),

        showLicenseTypeDialog(licenseType, isEditing) {
            this.isShowingLicenseTypeDialog = true;
            this.isEditingLicenseType = isEditing;

            // Copy to avoid modifying original
            let copyStr = JSON.stringify(licenseType);
            let copyJson = JSON.parse(copyStr);
            this.lastSelectedLicenseType = copyJson;
        },

        editLicenseType() {
            console.log('editLicenseType()', this.lastSelectedLicenseType);

            this.isDoingOperation = true;

            this.updateLicenseType(this.lastSelectedLicenseType)
            .then(() => {
                this.$emit('search-params-changed', this.searchParams);
                this.isShowingLicenseTypeDialog = false;
                this.$snotify.success('Successfully updated license type', {
                    closeOnClick: false,
                    pauseOnHover: false,
                    timeout: 1000,
                    showProgressBar: false
                });
            })
            .catch(error => {
                this.$snotify.error(`Could not update license type. ${error}`, {
                    pauseOnHover: true,
                    showProgressBar: true,
                    timeout: 4000,
                });
            })
            .finally(() => {
                this.isDoingOperation = false;
            });
        },
    }, // mmethods

    watch: {
        listViewOptions: {
            handler() {
                this.searchParams.pageNumber = this.listViewOptions.page;
                this.searchParams.pageSize = this.listViewOptions.itemsPerPage;

                let sortField = this.listViewOptions.sortBy[0];
                let sortDesc = this.listViewOptions.sortDesc[0];

                if (sortField) {
                    this.searchParams.sortField = sortField;
                    this.searchParams.sortDirection = sortDesc? 'DESC' : 'ASC';
                }

                this.$emit('search-params-changed', this.searchParams);
            },
        },
    }, // watch
};
</script>
