import Admin from 'Container/Adminpanel.vue';

const Reports = () => import('Views/AdminPanel/Reports/Reports.vue');
const Invoice = () => import('Views/AdminPanel/Invoices.vue');
const AdminAccount = () => import('Views/AdminPanel/Account.vue');
const AdminAccounts = () => import('Views/AdminPanel/Accounts/Accounts.vue');
const AdminAccountsForm = () => import('Views/AdminPanel/Accounts/AccountsForm.vue');
const AdminOrders = () => import('Views/AdminPanel/Orders/Orders.vue');
const AdminOrderView = () => import('Views/AdminPanel/Orders/OrderForm.vue');
const AdminOrderEdit = () => import('Views/AdminPanel/Orders/OrderForm.vue');
const AdminProducts = () => import('Views/AdminPanel/Products/Products.vue');
const AdminProductsForm = () => import('Views/AdminPanel/Products/ProductsForm.vue');
const AdminStockGroups = () => import('Views/AdminPanel/StockGroups/StockGroups.vue');
const PrivacyPolicy = () => import('Views/AdminPanel/Products/ProductsForm.vue');
const Suppliers = () => import('Views/AdminPanel/Suppliers/Suppliers.vue');
const SupplierForm = () => import('Views/AdminPanel/Suppliers/SupplierForm.vue')
const Tags = () => import('Views/AdminPanel/Tags/Tags.vue');
const TagForm = () => import('Views/AdminPanel/Tags/TagForm.vue');
const LicenseTypes = () => import('Views/AdminPanel/LicenseTypes/LicenseTypes.vue');
const ReportDetails = () => import('Views/AdminPanel/Reports/ReportDetails.vue');
const Brand = () => import('Views/AdminPanel/Brands/Brand.vue');
const Partners = () => import('Views/AdminPanel/TradingPartners/TradingPartner.vue');
const PartnerForm = () => import('Views/AdminPanel/TradingPartners/TradingPartnerForm.vue');
const TradingMessages = () => import('Views/AdminPanel/TradingMessages/TradingMessage.vue');
const Indexer = () => import('Views/AdminPanel/Indexer/Indexer.vue');

// Guards
import { permissionGuardNoAccount } from './guards';

export default {
	path: '/admin-panel',
	component: Admin,
	redirect: '/admin-panel/reports',
	beforeEnter: permissionGuardNoAccount,
	children: [
		{
			path: 'reports',
			component: Reports,
			name: 'Reports'
		},
		{
			path: 'reports/add',
			component: ReportDetails,
			name: 'ReportDetails',
		},
		{
			path: 'reports/edit',
			component: ReportDetails,
			name: 'ReportDetails',
		},
		{
			path: 'reports/view',
			component: ReportDetails,
			name: 'ReportDetails',
		},
		{
			path: 'brand',
			component: Brand,
			name: 'Brand',
		},
		{
			path: 'partners',
			component: Partners,
			name: 'Partners',
		},
		{
			path: 'partner-add',
			component: PartnerForm,
			name: 'PartnerAdd',
		},
		{
			path: 'partner-edit/:id',
			component: ()=>import('../views/AdminPanel/TradingPartners/TradingPartnerDetails.vue'),
			name: 'PartnerEdit',
		},
		{
			path: 'trading-messages',
			component: TradingMessages,
			name: 'TradingMessages',
		},
		{
			path: 'trading-messages/detail/:id',
			component: ()=>import('../views/AdminPanel/TradingMessages/TradingMessageDatail.vue'),
			name: 'TradingForm',
		},
		{
			path: 'trading-subscriptions',
			component: () => import('Views/AdminPanel/TradingSubscriptions/TradingSubscriptionsPage.vue'),
			name: 'TradingSubscription',
		},
		{
			path: 'trading-subscriptions/edit/:id',
			component: () => import('Views/AdminPanel/TradingSubscriptions/TradingSubscriptionsForm.vue'),
			name: 'TradingSubscriptionEdit',
		},
		{
			path: 'trading-subscriptions/detail/:id',
			component: () => import('Views/AdminPanel/TradingSubscriptions/TradingSubscriptionsForm.vue'),
			name: 'TradingSubscriptionDetails',
		},
		{
			path: 'subscriptionFileTypeAssociations/add',
			component: () => import('Views/AdminPanel/TradingPartners/Tabs/X12subscriptionFileTypeAssociations/X12subscriptionFileTypeAssociationsForm.vue'),
			name: 'SubscriptionFileTypeAssociationAdd',
		},
		{
			path: 'subscriptionFileTypeAssociations/edit/:id',
			component: () => import('Views/AdminPanel/TradingPartners/Tabs/X12subscriptionFileTypeAssociations/X12subscriptionFileTypeAssociationsForm.vue'),
			name: 'SubscriptionFileTypeAssociationEdit',
		},
		{
			path: 'definitions/termsTypeCodes',
			component: () => import('Views/AdminPanel/X12Definitions/X12termsTypeCodes/X12termsTypeCode.vue'),
			name: 'X12termsTypeCodes',
		},
		{
			path: 'definitions/termsTypeCodes/add',
			component: () => import('Views/AdminPanel/X12Definitions/X12termsTypeCodes/X12termsTypeCodeForm.vue'),
			name: 'X12termsTypeCodesAdd',
		},
		{
			path: 'definitions/termsTypeCodes/edit/:id',
			component: () => import('Views/AdminPanel/X12Definitions/X12termsTypeCodes/X12termsTypeCodeForm.vue'),
			name: 'X12termsTypeCodesEdit',
		},
		{
			path: 'definitions/termsBasisDateCodes',
			component: () => import('Views/AdminPanel/X12Definitions/X12termsBasisDateCodes/X12termsBasisDateCode.vue'),
			name: 'X12termsBasisDateCode',
		},
		{
			path: 'definitions/paymentMethod',
			component: () => import('Views/AdminPanel/X12Definitions/X12paymentMethods/X12paymentMethod.vue'),
			name: 'X12paymentMethod',
		},
		{
			path: 'definitions/paymentMethod/add',
			component: () => import('Views/AdminPanel/X12Definitions/X12paymentMethods/X12paymentMethodForm.vue'),
			name: 'X12paymentMethodAdd',
		},
		{
			path: 'definitions/paymentMethod/edit/:id',
			component: () => import('Views/AdminPanel/X12Definitions/X12paymentMethods/X12paymentMethodForm.vue'),
			name: 'X12paymentMethodEdit',
		},
		{
			path: 'definitions/locationCodes',
			component: () => import('Views/AdminPanel/X12Definitions/X12locationCodes/X12locationCode.vue'),
			name: 'X12locationCode',
		},
		{
			path: 'definitions/locationCodes/add',
			component: () => import('Views/AdminPanel/X12Definitions/X12locationCodes/X12locationCodeForm.vue'),
			name: 'X12locationCodeAdd',
		},
		{
			path: 'definitions/locationCodes/edit/:id',
			component: () => import('Views/AdminPanel/X12Definitions/X12locationCodes/X12locationCodeForm.vue'),
			name: 'X12locationCodeEdit',
		},
		{
			path: 'definitions/marketAreaQualifier',
			component: () => import('Views/AdminPanel/X12Definitions/X12marketAreaQualifiers/X12marketAreaQualifier.vue'),
			name: 'X12marketAreaQualifier',
		},
		{
			path: 'definitions/marketAreaQualifier/add',
			component: () => import('Views/AdminPanel/X12Definitions/X12marketAreaQualifiers/X12marketAreaQualifierForm.vue'),
			name: 'X12marketAreaQualifierAdd',
		},
		{
			path: 'definitions/marketAreaQualifier/edit/:id',
			component: () => import('Views/AdminPanel/X12Definitions/X12marketAreaQualifiers/X12marketAreaQualifierForm.vue'),
			name: 'X12marketAreaQualifierEdit',
		},
		{
			path: 'definitions/priceIdentifierCode',
			component: () => import('Views/AdminPanel/X12Definitions/X12priceIdentifierCodes/X12priceIdentifierCode.vue'),
			name: 'X12priceIdentifierCode',
		},
		{
			path: 'definitions/priceIdentifierCode/add',
			component: () => import('Views/AdminPanel/X12Definitions/X12priceIdentifierCodes/X12priceIdentifierCodeForm.vue'),
			name: 'X12priceIdentifierCodeAdd',
		},
		{
			path: 'definitions/priceIdentifierCode/edit/:id',
			component: () => import('Views/AdminPanel/X12Definitions/X12priceIdentifierCodes/X12priceIdentifierCodeForm.vue'),
			name: 'X12priceIdentifierCodeEdit',
		},
		{
			path: 'definitions/srvPromAllwChrgCode',
			component: () => import('Views/AdminPanel/X12Definitions/X12srvPromAllwChrgCodes/X12srvPromAllwChrgCode.vue'),
			name: 'X12srvPromAllwChrgCode',
		},
		{
			path: 'definitions/srvPromAllwChrgCode/add',
			component: () => import('Views/AdminPanel/X12Definitions/X12srvPromAllwChrgCodes/X12srvPromAllwChrgCodeForm.vue'),
			name: 'X12srvPromAllwChrgCodeAdd',
		},
		{
			path: 'definitions/srvPromAllwChrgCode/edit/:id',
			component: () => import('Views/AdminPanel/X12Definitions/X12srvPromAllwChrgCodes/X12srvPromAllwChrgCodeForm.vue'),
			name: 'X12srvPromAllwChrgCodeEdit',
		},
		{
			path: 'definitions/productServiceQualifiers',
			component: () => import('Views/AdminPanel/X12Definitions/X12productServiceQualifiers/X12productServiceQualifier.vue'),
			name: 'X12productServiceQualifier',
		},
		{
			path: 'definitions/productServiceQualifiers/add',
			component: () => import('Views/AdminPanel/X12Definitions/X12productServiceQualifiers/X12productServiceQualifierForm.vue'),
			name: 'X12productServiceQualifierAdd',
		},
		{
			path: 'definitions/productServiceQualifiers/edit/:id',
			component: () => import('Views/AdminPanel/X12Definitions/X12productServiceQualifiers/X12productServiceQualifierForm.vue'),
			name: 'X12productServiceQualifierEdit',
		},
		{
			path: 'definitions/termsBasisDateCodes/add',
			component: () => import('Views/AdminPanel/X12Definitions/X12termsBasisDateCodes/X12termsBasisDateCodeForm.vue'),
			name: 'X12termsBasisDateCodeAdd',
		},
		{
			path: 'definitions/termsBasisDateCodes/edit/:id',
			component: () => import('Views/AdminPanel/X12Definitions/X12termsBasisDateCodes/X12termsBasisDateCodeForm.vue'),
			name: 'X12termsBasisDateCodeEdit',
		},
		{
			path: 'definitions/catalogPurposeCodes',
			component: () => import('Views/AdminPanel/X12Definitions/X12catalogPurposeCodes/X12catalogPurposeCode.vue'),
			name: 'CatalogPurposeCodes',
		},
		{
			path: 'definitions/catalogPurposeCodes/add',
			component: () => import('Views/AdminPanel/X12Definitions/X12catalogPurposeCodes/X12catalogPurposeCodeForm.vue'),
			name: 'CatalogPurposeCodesAdd',
		},
		{
			path: 'definitions/catalogPurposeCodes/edit/:id',
			component: () => import('Views/AdminPanel/X12Definitions/X12catalogPurposeCodes/X12catalogPurposeCodeForm.vue'),
			name: 'CatalogPurposeCodesEdit',
		},
		{
			path: 'definitions/refIdentificationQualifiers',
			component: () => import('Views/AdminPanel/X12Definitions/X12refIdentificationQualifiers/X12refIdentificationQualifier.vue'),
			name: 'RefIdentificationQualifiers',
		},
		{
			path: 'definitions/refIdentificationQualifiers/add',
			component: () => import('Views/AdminPanel/X12Definitions/X12refIdentificationQualifiers/X12refIdentificationQualifierForm.vue'),
			name: 'RefIdentificationQualifiersAdd',
		},
		{
			path: 'definitions/refIdentificationQualifiers/edit/:id',
			component: () => import('Views/AdminPanel/X12Definitions/X12refIdentificationQualifiers/X12refIdentificationQualifierForm.vue'),
			name: 'RefIdentificationQualifiersEdit',
		},
		{
			path: 'definitions/potypesCodes',
			component: () => import('Views/AdminPanel/X12Definitions/X12PotypesCodes/X12PotypesCode.vue'),
			name: 'PotypesCodes',
		},
		{
			path: 'definitions/potypesCodes/edit/:id',
			component: () => import('Views/AdminPanel/X12Definitions/X12PotypesCodes/X12PotypesCodeForm.vue'),
			name: 'PotypesCodesEdit',
		},
		{
			path: 'definitions/potypesCodes/add',
			component: () => import('Views/AdminPanel/X12Definitions/X12PotypesCodes/X12PotypesCodeForm.vue'),
			name: 'PotypesCodesAdd',
		},
		{
			path: 'definitions/dateTimeQualifiers/edit/:id',
			component: () => import('Views/AdminPanel/X12Definitions/X12DateTimeQualifiers/DateTimeQualifiersForm.vue'),
			name: 'DateTimeQualifiersFormEdit',
		},
		{
			path: 'definitions/dateTimeQualifiers/add',
			component: () => import('Views/AdminPanel/X12Definitions/X12DateTimeQualifiers/DateTimeQualifiersForm.vue'),
			name: 'DateTimeQualifiersFormAdd',
		},
		{
			path: 'definitions/dateTimeQualifiers',
			component: () => import('Views/AdminPanel/X12Definitions/X12DateTimeQualifiers/DateTimeQualifiers.vue'),
			name: 'DateTimeQualifiers',
		},
		{
			path: 'definitions/fileTypes',
			component: () => import('Views/AdminPanel/X12Definitions/X12FileTypes/FileType.vue'),
			name: 'FileTypes',
		},
		{
			path: 'definitions/fileTypes/edit/:id',
			component: () => import('Views/AdminPanel/X12Definitions/X12FileTypes/FileTypeForm.vue'),
			name: 'FileTypesForm',
		},
		{
			path: 'definitions/fileTypes/add',
			component: () => import('Views/AdminPanel/X12Definitions/X12FileTypes/FileTypeForm.vue'),
			name: 'FileTypesForm',
		},
		{
			path: 'definitions/fileTypesVersion',
			component: () => import('Views/AdminPanel/X12Definitions/X12FileTypeVersions/X12FileTypeVersion.vue'),
			name: 'FileTypesVersions',
		},
		{
			path: 'definitions/fileTypesVersion/edit/:id',
			component: () => import('Views/AdminPanel/X12Definitions/X12FileTypeVersions/X12FileTypesversionsForm.vue'),
			name: 'FileTypesversionsForm',
		},
		{
			path: 'definitions/fileTypesVersion/add',
			component: () => import('Views/AdminPanel/X12Definitions/X12FileTypeVersions/X12FileTypesversionsForm.vue'),
			name: 'FileTypesversionsAdd',
		},
		{
			path: 'definitions/functionalCodes',
			component: () => import('Views/AdminPanel/X12Definitions/X12FunctionalCodes/X12FunctionalCode.vue'),
			name: 'FunctionalCodes',
		},
		{
			path: 'definitions/functionalCodes/edit/:id',
			component: () => import('Views/AdminPanel/X12Definitions/X12FunctionalCodes/X12FunctionalCodesForm.vue'),
			name: 'FunctionalCodesForm',
		},
		{
			path: 'definitions/functionalCodes/add',
			component: () => import('Views/AdminPanel/X12Definitions/X12FunctionalCodes/X12FunctionalCodesForm.vue'),
			name: 'FunctionalCodesAdd',
		},
		{
			path: 'definitions/traceTypesCodes',
			component: () => import('Views/AdminPanel/X12Definitions/X12TraceTypesCodes/X12TraceTypeCode.vue'),
			name: 'TraceTypesCodes',
		},
		{
			path: 'definitions/traceTypesCodes/edit/:id',
			component: () => import('Views/AdminPanel/X12Definitions/X12TraceTypesCodes/X12TraceTypesCodesForm.vue'),
			name: 'TraceTypesCodesForm',
		},
		{
			path: 'definitions/traceTypesCodes/add',
			component: () => import('Views/AdminPanel/X12Definitions/X12TraceTypesCodes/X12TraceTypesCodesForm.vue'),
			name: 'TraceTypesCodesAdd',
		},
		{
			path: 'definitions/trxHandleCodes',
			component: () => import('Views/AdminPanel/X12Definitions/X12TrxHandleCodes/X12TrxHandleCode.vue'),
			name: 'TrxHandleCodes',
		},
		{
			path: 'definitions/trxHandleCodes/edit/:id',
			component: () => import('Views/AdminPanel/X12Definitions/X12TrxHandleCodes/X12TrxHandleCodesForm.vue'),
			name: 'TrxHandleCodesForm',
		},
		{
			path: 'definitions/trxHandleCodes/add',
			component: () => import('Views/AdminPanel/X12Definitions/X12TrxHandleCodes/X12TrxHandleCodesForm.vue'),
			name: 'TrxHandleCodesAdd',
		},
		{
			path: 'definitions/chargeTypes',
			component: () => import('Views/AdminPanel/X12Definitions/X12ChargeTypes/X12ChargeType.vue'),
			name: 'ChargeTypes',
		},
		{
			path: 'definitions/chargeTypes/edit/:id',
			component: () => import('Views/AdminPanel/X12Definitions/X12ChargeTypes/X12ChargeTypesForm.vue'),
			name: 'ChargeTypesForm',
		},
		{
			path: 'definitions/chargeTypes/add',
			component: () => import('Views/AdminPanel/X12Definitions/X12ChargeTypes/X12ChargeTypesForm.vue'),
			name: 'ChargeTypesAdd',
		},
		{
			path: 'definitions/classTradeCodes',
			component: () => import('Views/AdminPanel/X12Definitions/X12ClassTradeCodes/X12ClassTradeCode.vue'),
			name: 'ClassTradeCodes',
		},
		{
			path: 'definitions/classTradeCodes/edit/:id',
			component: () => import('Views/AdminPanel/X12Definitions/X12ClassTradeCodes/X12ClassTradeCodesForm.vue'),
			name: 'ClassTradeCodesForm',
		},
		{
			path: 'definitions/classTradeCodes/add',
			component: () => import('Views/AdminPanel/X12Definitions/X12ClassTradeCodes/X12ClassTradeCodesForm.vue'),
			name: 'ClassTradeCodesAdd',
		},
		{
			path: 'definitions/commQualifiers',
			component: () => import('Views/AdminPanel/X12Definitions/X12CommQualifiers/X12CommQualifier.vue'),
			name: 'CommQualifiers',
		},
		{
			path: 'definitions/commQualifiers/edit/:id',
			component: () => import('Views/AdminPanel/X12Definitions/X12CommQualifiers/X12CommQualifiersForm.vue'),
			name: 'CommQualifiersForm',
		},
		{
			path: 'definitions/commQualifiers/add',
			component: () => import('Views/AdminPanel/X12Definitions/X12CommQualifiers/X12CommQualifiersForm.vue'),
			name: 'CommQualifiersAdd',
		},
		{
			path: 'definitions/entityIdentifierCodes',
			component: () => import('Views/AdminPanel/X12Definitions/X12EntityIdentifierCodes/X12EntityIdentifierCode.vue'),
			name: 'EntityIdentifierCodes',
		},
		{
			path: 'definitions/entityIdentifierCodes/edit/:id',
			component: () => import('Views/AdminPanel/X12Definitions/X12EntityIdentifierCodes/X12EntityIdentifierCodesForm.vue'),
			name: 'EntityIdentifierCodesForm',
		},
		{
			path: 'definitions/entityIdentifierCodes/add',
			component: () => import('Views/AdminPanel/X12Definitions/X12EntityIdentifierCodes/X12EntityIdentifierCodesForm.vue'),
			name: 'EntityIdentifierCodesAdd',
		},
		{
			path: 'definitions/interchangeQualifiers',
			component: () => import('Views/AdminPanel/X12Definitions/X12InterchangeQualifiers/X12InterchangeQualifier.vue'),
			name: 'InterchangeQualifiers',
		},
		{
			path: 'definitions/interchangeQualifiers/edit/:id',
			component: () => import('Views/AdminPanel/X12Definitions/X12InterchangeQualifiers/X12InterchangeQualifiersForm.vue'),
			name: 'InterchangeQualifiersForm',
		},
		{
			path: 'definitions/interchangeQualifiers/add',
			component: () => import('Views/AdminPanel/X12Definitions/X12InterchangeQualifiers/X12InterchangeQualifiersForm.vue'),
			name: 'InterchangeQualifiersAdd',
		},
		{
			path: 'definitions/interchangeTradingPartnerTypes',
			component: () => import('Views/AdminPanel/X12Definitions/X12InterchangeTradingPartnerTypes/X12InterchangeTradingPartnerType.vue'),
			name: 'InterchangeTradingPartnerTypes',
		},
		{
			path: 'definitions/interchangeTradingPartnerTypes/edit/:id',
			component: () => import('Views/AdminPanel/X12Definitions/X12InterchangeTradingPartnerTypes/X12InterchangeTradingPartnerTypesForm.vue'),
			name: 'InterchangeTradingPartnerTypesForm',
		},
		{
			path: 'definitions/interchangeTradingPartnerTypes/add',
			component: () => import('Views/AdminPanel/X12Definitions/X12InterchangeTradingPartnerTypes/X12InterchangeTradingPartnerTypesForm.vue'),
			name: 'InterchangeTradingPartnerTypesAdd',
		},
		{
			path: 'invoices',
			component: Invoice,
			name: 'Invoice'
		},
		{
			path: 'products',
			component: AdminProducts,
			name: 'AdminProducts'
		},
		{
			path: 'product-add',
			component: AdminProductsForm,
			name: 'AdminProductsAdd'
		},
		{
			path: 'product-edit/:id',
			component: AdminProductsForm,
			name: 'ProductsEdit',
		},
		{
			path: 'product-view/:id',
			component: AdminProductsForm,
			name: 'ProductsView',
		},
		{
			path: 'stock-groups',
			component: AdminStockGroups,
			name: 'AdminStockGroups',
		},
		{
			path: 'accounts',
			component: AdminAccounts,
			name: 'AdminAccounts'
		},
		{
			path: 'account-add',
			component: AdminAccountsForm,
			name: 'AdminAccountsAdd',
		},
		{
			path: 'account-edit/:id',
			component: AdminAccountsForm,
			name: 'AccountsEdit',
		},
		{
			path: 'account-view/:id',
			component: AdminAccountsForm,
			name: 'AccountsView',
		},
		{
			path: 'account/profile',
			component: AdminAccount,
			name: 'UserProfile',
		},
		{
			path: 'orders',
			component: AdminOrders,
			name: 'Orders',
		},
		{
			path: 'order-edit/:id',
			component: AdminOrderEdit,
			name: 'OrderEdit',
		},
		{
			path: 'order-view/:id',
			component: AdminOrderView,
			name: 'OrderView',
		},
		{
			path: 'suppliers',
			component: Suppliers,
			name: 'Suppliers',
		},
		{
			path: 'supplier-edit/:id',
			component: SupplierForm,
			name: 'SupplierForm',
		},
		{
			path: 'supplier-view/:id',
			component: SupplierForm,
			name: 'SupplierView',
		},
		{
			path: 'people',
			component: () => import('Views/AdminPanel/People/PeoplePage.vue'),
			name: 'People',
		},
		{
			path: 'people/new',
			component: () => import('Views/AdminPanel/People/PersonDetailsPage.vue'),
			name: 'PersonAdd',
		},
		{
			path: 'people/:id',
			component: () => import('Views/AdminPanel/People/PersonDetailsPage.vue'),
			name: 'PersonDetails',
		},
		{
			path: 'people-routes',
			component: () => import('Views/AdminPanel/PeopleRoutes/PeopleRoute.vue'),
			name: 'PeopleRoutes',
		},
		{
			path: 'person-route/new',
			component: () => import('Views/AdminPanel/PeopleRoutes/PersonRouteForm.vue'),
			name: 'AddPersonRoute',
		},
		{
			path: 'person-route/view/:id',
			component: () => import('Views/AdminPanel/PeopleRoutes/PersonRouteForm.vue'),
			name: 'PeopleRoutesForm',
		},
		{
			path: 'person-route/edit/:id',
			component: () => import('Views/AdminPanel/PeopleRoutes/PersonRouteForm.vue'),
			name: 'PeopleRoutesForm',
		},
		{
			path: 'tags',
			component: Tags,
			name: 'Tags',
		},
		{
			path: 'tags/:stockItemTagId',
			component: TagForm,
			name: 'TagForm',
		},
		{
			path: 'license-types',
			component: LicenseTypes,
			name: 'LicenseTypes',
		},
		{
			path: 'privacy-policy',
			component: PrivacyPolicy,
			name: 'PrivacyPolicy1'
		},
		{
			path: 'indexer',
			component: Indexer,
			name: 'Indexer'
		},
	]
}
