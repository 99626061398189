export const products = {
    methods: {

        isProductPurchasableForCustomer(product, eligibilities) {
            if (!product.stockItems)
                return false;

            if (!product.stockItems.stockItemStockGroups)
                return false;

            if (product.stockItems.stockItemStockGroups.length < 1)
                return false;
            
            const stockGroupIds = product.stockItems.stockItemStockGroups.map(group => group.stockGroupId);
            const stockGroupIdsUnique = new Set(stockGroupIds);

            return eligibilities.some(eligibility => stockGroupIdsUnique.has(eligibility.stockGroupId));
        },

    },
};
