var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-menu",
        {
          staticClass: "userblock-dropdown",
          attrs: {
            transition: "scale-transition",
            "offset-overflow": "",
            "nudge-bottom": "30",
            "nudge-right": "20",
            "min-width": "200",
            "max-width": "200",
            light: ""
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "v-btn",
                    _vm._g({ attrs: { fab: "", small: "" } }, on),
                    [
                      _c("avatar", {
                        attrs: { fullname: _vm.person.fullName, size: 40 }
                      })
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [
          _c(
            "v-list",
            { staticClass: "user-dropdown-list" },
            _vm._l(_vm.userBlockItems, function(item) {
              return _c(
                "v-list-item",
                { key: item.title, on: { click: item.onClick } },
                [
                  _c("v-icon", { staticClass: "mr-2" }, [
                    _vm._v(_vm._s(item.icon))
                  ]),
                  _c("span", [_vm._v(_vm._s(item.title))])
                ],
                1
              )
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }