export default {
    namespaced: true,

    state: {
        countries: [
            'France',
            'Mexico',
            'United States',
        ],
        regions: [
            'Cognac',
            'Idaho',
        ],
        subregions: [
            'Sub region 1',
            'Sub region 2',
            'Sub region 3',
            'Sub region 4',
            'Sub region 5',
            'Sub region 6',
            'Sub region 7',
            'Sub region 8',
            'Sub region 9',
        ],
    }, // state

    getters: {
        getCountries: state => { return state.countries },
        getRegions: state => { return state.regions },
        getSubregions: state => { return state.subregions },
    }, // getters
}

