<template>
  <div class="gadget-content">
    <emb-page-title :heading="'Choose an account'" imageType="my-account" />

    <div class="container py-12">
      <!-- current account -->
      <div class="mb-12">
        <h5 class="font-weight-light mb-3">Your current account is</h5>
        <h4
          class="font-weight-light mb-3"
          v-if="!currentPerson.userPreferences"
        >
          Not account selected
        </h4>

        <v-card class="mb-6">
          <v-card-text>
            <div v-if="currentAccount">
              <div class="d-flex align-center mb-3">
                <v-icon class="mr-2" style="font-size: 30px"
                  >account_circle</v-icon
                >
                <h5 class="font-weight-regular ma-0">
                  {{ currentAccount.customerName }}
                </h5>
              </div>

              <div class="d-flex align-center mb-3">
                <v-icon class="mr-2" style="font-size: 30px"
                  >local_shipping</v-icon
                >
                <h5 class="font-weight-light ma-0">
                  {{ getAddress(currentAccount) }}
                </h5>
              </div>

              <div class="d-flex align-center">
                <v-icon class="mr-2" style="font-size: 27px">tag</v-icon>
                <h5 class="font-weight-light ma-0">
                  {{ currentAccount.internalId }}
                </h5>
              </div>
            </div>
            <div v-else>
              <div class="d-flex align-center">
                <v-icon class="mr-2" style="font-size: 30px"
                  >error_outline</v-icon
                >
                <h5 class="font-weight-regular ma-0">No Account Selected</h5>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </div>
      <!-- current account -->

      <!-- user's other accounts -->
      <div>
        <h5 class="font-weight-light mb-3">Select another account</h5>

        <div class="">
          <v-col cols="12" sm="5" class="d-flex justify-center align-center">
            <v-text-field
              class="mr-4"
              v-model="searchValue"
              label="Search Account"
              @keyup.enter="searchAccountsRequest"
            />
            <v-btn color="primary" @click="searchAccountsRequest">Search</v-btn>
          </v-col>
        </div>

        <div v-if="!searchResult" class="d-flex flex-column align-center">
          <p>Loading accounts</p>
          <v-progress-circular indeterminate />
        </div>

        <div v-else>
          <div
            v-for="account in searchResult"
            :key="account.internalId"
            class="mb-4"
          >
            <v-hover v-slot:default="{ hover }">
              <v-card
                :elevation="hover ? 6 : 2"
                :class="{ 'on-hover': hover }"
                class="hoverable mb-3"
                @click="onAccountSelected(account)"
              >
                <v-card-text>
                  <div class="d-flex align-center mb-3">
                    <v-icon class="mr-2" style="font-size: 30px"
                      >account_circle</v-icon
                    >
                    <h5 class="font-weight-regular ma-0">
                      {{ account.customerName }}
                    </h5>
                  </div>

                  <div class="d-flex align-center mb-3">
                    <v-icon class="mr-2" style="font-size: 30px"
                      >local_shipping</v-icon
                    >
                    <h5 class="font-weight-light ma-0">
                      {{ getAddress(account) }}
                    </h5>
                  </div>
                  <div class="d-flex align-center">
                    <v-icon class="mr-2" style="font-size: 27px">tag</v-icon>
                    <h5 class="font-weight-light ma-0">
                      {{ account.internalId }}
                    </h5>
                  </div>
                </v-card-text>
              </v-card>
            </v-hover>
          </div>
        </div>
      </div>
      <!-- user's other accounts -->
    </div>

    <!-- switching account dialog -->
    <v-dialog persistent max-width="300" v-model="isSwitchingAccount">
      <v-card class="pa-3">
        <v-card-text class="d-flex flex-column align-center">
          <p>Switching account...</p>
          <v-progress-circular width="2" indeterminate />
        </v-card-text>
      </v-card> </v-dialog
    ><!-- switching account dialog -->
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  data: () => ({
    cities: [],
    isSwitchingAccount: false,
    searchValue: undefined,
    searchResult: undefined,
  }), // data

  computed: {
    ...mapGetters({
      accounts: "me/getAccounts",
      currentPerson: "session/getPerson",
      currentAccount: "session/getAccount",
      currentLocation: "session/getLocation",
    }),
  }, // computed

  mounted() {
    this.searchAccountsRequest();
    console.log("Current account ----> ", this.currentAccount);
    console.log("Current person ----> ", this.currentPerson);
  }, // mounted

  methods: {
    ...mapMutations({
      setSearchValue: "accounts/SET_SEARCH_PARAM_SEARCH_VALUE",
    }),
    ...mapActions({
      getAccounts: "me/getAccounts",
      getCities: "catalog/requestGetCities",
      getCityById: "catalog/getCityById",
      switchAccount: "session/switchAccount",
      searchAccounts: "accounts/search",
    }),

    getAccountsRequest() {
      this.getAccounts().then(() => this.onAccountsLoaded());
    },
    searchAccountsRequest() {
      this.searchResult = undefined;
      this.searchAccounts({ searchValue: this.searchValue }).then((result) => {
        console.log("----------------", result);
        this.searchResult = result.items;
        this.onAccountsLoaded();
      });
    },

    getAddress(account) {
      const address = account.deliveryAddressLine1;
      const city = this.getCityByIdFromLocalMemory(account.deliveryCityId);
      const cityName = city ? city.cityName : "";
      const finalAddress = `${address}, ${cityName}`;
      return this.toTitleCase(finalAddress);
    },

    getCitiesRequest() {
      const cityIds = this.searchResult.items.map(
        (account) => account.deliveryCityId
      );
      const requests = cityIds.map((cityId) => this.getCityById(cityId));

      Promise.all(requests).then((response) => {
        const cities = response.map((result) => result.data);
        this.cities = cities;
      });
    },

    getCityByIdFromLocalMemory(cityId) {
      return this.cities.find((city) => city.cityId == cityId);
    },

    onAccountsLoaded() {
      this.getCitiesRequest();
    },

    onAccountSelected(account) {
      this.isSwitchingAccount = true;

      this.switchAccount(account.internalId).then(() => this.$router.go());
    },

    toTitleCase(str) {
      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
  }, // methods
};
</script>
