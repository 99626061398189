<template>
  <div>
    <Breadcrumbs class="mb-4" :items="breadcrumbs" />
    <div class="center-x" v-if="isLoading">
      <v-skeleton-loader type="text" width="100%" />
    </div>

    <v-card>
      <v-card-title>File Type </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" v-show="!isAdd">
            <v-text-field
              label="File Type Id"
              v-model="currentObject.dateTimeQualifierId"
              disabled
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              label="Date Time Qualifier Description"
              v-model="currentObject.dateTimeQualifierDescription"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              label="Date Time Qualifier"
              v-model="currentObject.dateTimeQualifier"
            />
          </v-col>
          <v-col cols="12" sm="6" v-if="!isAdd">
            <v-text-field
              label="Last Edited By"
              v-model="currentObject.lastEditedBy" 
              disabled
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-row>
      <v-col>
        <div class="d-flex justify-end">
          <v-btn color="error" large @click="save">Save</v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import apiClient from '../../../../apiClient';
import { BreadcrumbItem } from '../../../../components/Breadcrumbs/BreadcrumbItem';
import { showDialog } from '../../../../components/Dialogs/NotifyDialog';
import { StaticRoutes } from '../../../../router/Routes';
import { formsMixin } from "../../../../mixins/formsMixin";
export default {
  name: "X12timeQualifiersForm",
  mixins: [formsMixin],
  data() {
    return {
        breadcrumbs: [
        new BreadcrumbItem({
          label: "Date Time Qualifiers",
          route: StaticRoutes.adminPanel.trading.x12Definitions.dateTimeQualifiers,
        }),
        new BreadcrumbItem({ label: "Date Time Qualifiers Details" }),
      ],
    };
  },
  
  methods:{
      async getById(){
          try {
              this.isLoading = true;
              this.currentObject = await apiClient.X12("X12dateTimeQualifiers").getById(this.id);
          } catch (error) {
              showDialog('Could not retrieve data', error);
          }finally{
              this.isLoading= false;
          }
      },
      async save(){
          try {
              this.isLoading = true;
              if(this.isEdit){
                  await apiClient.X12("X12dateTimeQualifiers").update(this.id,this.currentObject);
                  showDialog("Updated Successfully");
              }
              if(this.isAdd){
                  await apiClient.X12("X12dateTimeQualifiers").create(this.currentObject);
                  showDialog("Created Successfully");
              }
              this.$router.push("/admin-panel/definitions/dateTimeQualifiers");
          } catch (error) {
              showDialog("Could not save file", error);
          }finally{
              this.isLoading = false;
          }
      }
  }
};
</script>