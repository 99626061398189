<template>
    <div class="gadget-content section-gap">
        <user-profile></user-profile>
    </div>
</template>

<script>
import UserProfile from '../AdminPanel/Account';

export default {
    components: {
        UserProfile,
    },
}
</script>
