<template>
  <div>
    <AdminPanelHeader
      icon="mdi-bottle-soda"
      title="Product Service Qualifiers"
      :actions="headerActions"
      @search="onSearch"
    />
    <X12productServiceQualifierTable 
    :isLoading="isLoading"
    v-on:onDelete="onDelete"
    :searchParams="paginatedQueryParams"
    :paginatedList="currentEntity"
    />
  </div>
</template>

<script>
import apiClient from '../../../../apiClient';
import { AdminPanelHeaderAction } from '../../../../components/AdminPanelHeader/AdminPanelHeaderAction';
import { showDialog } from '../../../../components/Dialogs/NotifyDialog';
import { parentComponentMixin } from '../../../../mixins/parentComponentMixin';
export default {
  mixins: [parentComponentMixin],
  name: "X12ProductServiceQualifiers",
  data() {
    return {
      headerActions: [
        new AdminPanelHeaderAction({
          color: "accent",
          label: "Add new",
          icon: "add",
          action: () =>
            this.$router.push(
              "/admin-panel/definitions/productServiceQualifiers/add"
            ),
        }),
      ],
      paginatedQueryParams: {
        pageSize: 10,
        pageNumber: 0,
        searchValue: "",
        sortField: "productServiceQualifierId",
        sortDirection: "DESC",
      },
    };
  },
  methods: {
    async onDelete(id){
      try {
        this.isLoading = true;
        await apiClient.X12("X12productServiceQualifiers").delete(id);
        showDialog("Deleted successfully");
        this.getPaginated();
      } catch (error) {
        showDialog("Could not delete item", error);
      }finally{
        this.isLoading = false;
      }
    },
    async getPaginated() {
      try {
        this.isLoading = true;
        this.currentEntity = await apiClient.X12("X12productServiceQualifiers").getPaginated(this.paginatedQueryParams);
      } catch (error) {getPaginated
        showDialog("Could not retrieve data", error);
      }finally{
        this.isLoading = false;
      }
    },
  },
};
</script>
