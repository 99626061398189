<template>
  <div class="shop-content-wrap" >
    <!-- warning -->
    <v-alert type="error"> Discounts may be applied at checkout </v-alert
    ><!-- warning -->

    <!-- filters (mobile) -->
    <v-flex
      id="fixedFilters"
      class="fixed-top"
      d-flex
      d-xs-flex
      d-sm-flex
      d-md-none
      d-lg-none
      d-xl-none
      xs12
      sm12
    >
      <!-- facets expandable -->
      <v-row>
        <v-col cols="12">
          <v-expansion-panels accordion>
            <v-expansion-panel>
              <v-expansion-panel-header>Filters</v-expansion-panel-header>
              <v-expansion-panel-content>
                <sidebar-filters></sidebar-filters>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col> </v-row
      ><!-- facets expandable -->

      <!-- other filters (sorting and results per page) -->
      <v-row>
        <!-- sorting -->
        <v-col cols="12" sm="6" md="4" lg="3">
          <v-select
            label="Price Sorting"
            :items="priceDirection"
            item-text="value"
            item-value="id"
            v-model="price"
          >
          </v-select> </v-col
        ><!-- sorting -->

        <!-- results per page -->
        <v-col cols="12" sm="6" md="4" lg="3">
          <v-select
            label="Results per page"
            item-text="value"
            item-value="id"
            :items="pageSizeOptions"
            v-model="itemsPerPage"
          >
          </v-select> </v-col
        ><!-- results per page --> </v-row
      ><!-- other filters (sorting and results per page) --> </v-flex
    ><!-- filters (mobile) -->

    <!-- filters (desktop) -->
    <v-flex d-none d-xs-none d-sm-none d-md-flex md12>
      <v-row>
        <v-col cols="12" sm="6" md="4" lg="3">
          <v-select
            label="Price Sorting"
            :items="priceDirection"
            item-text="value"
            item-value="id"
            v-model="price"
          >
          </v-select>
        </v-col>

        <v-col cols="12" sm="6" md="4" lg="3">
          <v-select
            label="Results per page"
            item-text="value"
            item-value="id"
            :items="pageSizeOptions"
            v-model="itemsPerPage"
          >
          </v-select>
        </v-col>
      </v-row> </v-flex
    ><!-- filters (desktop) -->

    <!-- separator (mobile) -->
    <v-flex class="header-separator" d-none d-xs-flex d-sm-flex d-md-none>
    </v-flex
    ><!-- separator (mobile) -->

    <!-- products container -->
    <div>

      <!-- error message -->
      <div v-if="isShowingErrorMessage" class="my-12 py-12">
        <h4 class="text-center font-weight-light" style="color: gray">
          An error ocurred while trying to search the products
        </h4>

        <div class="d-flex justify-center mb-4">
          <v-icon size="82" style="color: darkgray">
            sentiment_dissatisfied
          </v-icon>
        </div>

        <div class="d-flex justify-center">
          <v-btn text color="accent" @click="searchProducts">
            Click Here to Try Again
          </v-btn>
        </div>
      </div><!-- error message -->

      <div v-else>

        <!-- loading products -->
        <v-row v-if="isLoadingProducts">
          <v-col
            v-for="index in itemsPerPage"
            :key="index"
            cols="12"
            sm="6"
            md="4"
            lg="3"
          >
            <v-skeleton-loader type="card" height="450" />
          </v-col>
        </v-row>

        <!-- products loaded -->
        <div v-else>

          <!-- no results found -->
          <div v-if="isSearchResultEmpty" class="my-12 py-12">
            <h4 class="text-center font-weight-light" style="color: gray">
              Looks like there aren't any results!
            </h4>
            <h6 class="text-center" style="color: gray">
              Try another category or changing some filters.
            </h6>

            <div class="d-flex justify-center mb-4">
              <v-icon size="82" style="color: darkgray"> search </v-icon>
            </div>
          </div><!-- no results found -->

          <!-- show results (if found) -->
          <v-row v-else>
            <v-col
              v-for="(product, index) in products.items"
              :key="index"
              cols="12"
              sm="6"
              md="4"
              lg="3"
            >
              <product-item :product="product"> </product-item>
            </v-col>
          </v-row><!-- show results (if found) -->

        </div><!-- products loaded -->

      </div>

    </div><!-- products container -->

    <!-- pagination -->
    <div v-if="!isShowingErrorMessage" class="pagination-container" cols="12">
      <span
        class="first"
        :class="{ disabled: productRequest.pageNumber == 1 }"
        @click="firstPage()"
      >
        <v-icon left>mdi-chevron-double-left</v-icon>
      </span>

      <span
        class="previous"
        :class="{ disabled: productRequest.pageNumber == 1 }"
        @click="previousPage()"
      >
        <v-icon left>mdi-chevron-left</v-icon>
      </span>

      <div
        v-for="index in 7 + productRequest.leftMostPage"
        :key="index"
        @click="changePage(index)"
      >
        <a
          :class="{
            selected: index == productRequest.pageNumber,
            show:
              index > products.totalPages ||
              index < productRequest.leftMostPage,
          }"
        >
          {{ index }}
        </a>
      </div>

      <span
        class="next"
        :class="{ disabled: productRequest.pageNumber == products.totalPages }"
        @click="nextPage()"
      >
        <v-icon left>mdi-chevron-right</v-icon>
      </span>

      <span
        class="last"
        :class="{ disabled: productRequest.pageNumber == products.totalPages }"
        @click="lastPage()"
      >
        <v-icon left>mdi-chevron-double-right</v-icon>
      </span>
    </div>
    <!-- pagination -->
    
  </div>
</template>

<style lang="scss" scoped>
.fixed-top {
  z-index: 1;
  width: 100%;
  background: white;
  left: 0;
  top: 156px;
  padding-left: 20px;
  padding: 0 10% !important;
  flex-direction: column;
}

.header-separator {
  height: 120px;
  width: 100%;
}
</style>

<script>
/* eslint-disable */

import SidebarFilters from "Components/Ecommerce/SidebarFilters";
import ProductItem from "./ProductItem";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {

  data() {
    return {
      priceDirection: [
        { id: null, value: "Default" },
        { id: "ASC", value: "Lowest Price" },
        { id: "DESC", value: "Highest Price" },
      ],
      pageSizeOptions: [
        { id: 8, value: "8 hits per page" },
        { id: 12, value: "12 hits per page" },
        { id: 15, value: "15 hits per page" },
      ],
      itemsPerPage: 8,
      price: null,
      isShowingErrorMessage: false,
    };
  }, // data

mounted() {
    var pastPageNumber = localStorage.getItem("products.pageNumber");
    var pastCategory = localStorage.getItem("products.category");
    var pastFacets = localStorage.getItem("products.facets");
    var pastRoute = localStorage.getItem("products.route");
    var pastPageNUmberInt  = pastPageNumber? parseInt(pastPageNumber) : 1;

    var currentRoute = JSON.stringify(this.getCurrentRoutePath());

    if(pastRoute == currentRoute){
      this.productRequest.pageNumber = pastPageNUmberInt;
      this.setPageNumber(pastPageNUmberInt);
      this.productRequest.facets = JSON.parse(pastFacets);
      this.setFacets(JSON.parse(pastFacets));

    }else{
      this.productRequest.pageNumber = 1;
      this.productRequest.leftMostPage = 1;
      this.setPageNumber(1);
      this.productRequest.facets = [];
      this.setFacets([]);
    }

    if (this.isCategoryFacetPassedThroughRoute) {
        this.setCategoryFacetFromRoute();
    }
    this.productRequest.category = this.getCurrentImageTitle();
    this.setProductCategory(this.productRequest.category);

    this.searchProducts();
  }, // mounted
  updated(){
        localStorage.setItem("products.pageNumber",this.productRequest.pageNumber.toString());
        localStorage.setItem("products.category",this.productRequest.category? this.productRequest.category:"");
        localStorage.setItem("products.facets",JSON.stringify(this.productRequest.facets));
        localStorage.setItem("products.route",JSON.stringify(this.getCurrentRoutePath()));
  },

  computed: {
    ...mapGetters({
      isLoadingProducts: "search/getIsSearching",
      products: "search/getProducts",
      productRequest: "search/request",
      // account: "accounts/getAccount",
    }),

    isCategoryFacetPassedThroughRoute() {
        const categories = ["beer", "alcohol", "cider", "mead", "seltzer", "anheuser-busch"];
      const path = this.$router.currentRoute.path;
      const pathParts = path.split("/");
      const partCount = pathParts.length;
      const category = pathParts[partCount - 1].trim().toLowerCase();
      return categories.some((cat) => cat.includes(category));
    },

    isSearchResultEmpty() {
      if (!this.products) {
        console.log("no products")
        return true;
      }

      if (!this.products.items) {
        console.log("no product items")
        return true;
      }

      console.log("product items length is zero")
      return this.products.items.length == 0;
    },
  }, // computed

  methods: {
    ...mapActions({
      getProducts: "search/get",
    }),

    ...mapMutations({
      setFacets: "search/SET_FACETS",
      setPageNumber: "search/SET_PAGE_NUMBER",
      setPageSize: "search/SET_PAGE_SIZE",
      setPriceOrder: "search/SET_SORT_DIRECTION",
      setProductCategory: "search/SET_PRODUCT_CATEGORY"
    }),
    getCurrentRoutePath() {
      return this.$router.currentRoute.path;
    },
    capitalizeFirstLetter(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    getCurrentProductType() {
      const path = this.getCurrentRoutePath();
      const pathParts = path.split("/");
      const type = pathParts.length > 2 ? pathParts[2] : "products";
      return type.toLowerCase();
    },

    getCurrentImageTitle() {
      const type = this.getCurrentProductType();
      const words = type.split("-");
      const capitalizedWords = words.map(
        (word) => word[0].toUpperCase() + word.slice(1)
      );
      return capitalizedWords.join("-");
    },

    changePage(index) {
      this.productRequest.pageNumber = index;
      this.setPageNumber(index);
      this.adjustLeftMostPage();
      this.getProducts();
    },

    previousPage() {
      if (this.productRequest.pageNumber == 1) return;
      this.setPageNumber(--this.productRequest.pageNumber);
      this.adjustLeftMostPage();
      this.getProducts();
    },

    nextPage() {
      if (this.productRequest.pageNumber == this.products.totalPages) return;
      this.setPageNumber(++this.productRequest.pageNumber);
      this.adjustLeftMostPage();
      this.getProducts();
    },

    firstPage() {
      if (this.productRequest.pageNumber == 1) return;

      this.productRequest.pageNumber = 1;
      this.setPageNumber(this.productRequest.pageNumber);
      this.adjustLeftMostPage();
      this.getProducts();
    },

    lastPage() {
      if (this.productRequest.pageNumber == this.products.totalPages) return;

      this.productRequest.pageNumber = this.products.totalPages;
      this.setPageNumber(this.productRequest.pageNumber);
      this.adjustLeftMostPage();
      this.getProducts();
    },

    adjustLeftMostPage() {
      if (this.productRequest.pageNumber == 1)
        this.productRequest.leftMostPage = 1;

      if (this.productRequest.pageNumber == this.products.totalPages)
        this.productRequest.leftMostPage = this.productRequest.pageNumber - 7;

      if (!this.products || !this.products.totalPages) return;

      if (
        this.productRequest.pageNumber >=
          this.productRequest.leftMostPage + 4 &&
        this.productRequest.leftMostPage <= this.products.totalPages - 7
      )
        this.productRequest.leftMostPage++;

      if (
        this.productRequest.pageNumber <=
          this.productRequest.leftMostPage + 2 &&
        this.productRequest.leftMostPage >= 1
      )
        this.productRequest.leftMostPage--;
    },

    searchProducts() {
      // if (this.isLoadingProducts == true)
      //   // avoid multiple api calls
      //   return;

      // this.isLoadingProducts = true;
      this.isShowingErrorMessage = false;

      console.log("Search products", this.productRequest);

      this.getProducts()
      .catch((err) => {
        if (err != "Cancel") {
          this.isShowingErrorMessage = true;
        }
        else {
          this.products.items = [];
        }
      });
    },

    setCategoryFacetFromRoute() {
      const path = this.$router.currentRoute.path;
      const pathParts = path.split("/");
      const partCount = pathParts.length;
      const category = pathParts[partCount - 1].trim().toLowerCase();

      const currentFacets = this.productRequest.facets;

      let newFacets = currentFacets.map((facetItem) => {
        if (facetItem.facet == "categories") {
          const hasCurrentCategoryFacet = facetItem.options.some(
            (option) => option.name.trim().toLowerCase() == category
          );

          if (hasCurrentCategoryFacet == true) {
            facetItem.options = facetItem.options.filter(
              (option) => option.name.trim().toLowerCase() != category
            );
          }
        }
        return facetItem;
      });

      if (newFacets.length == 0) {
        newFacets = [
          {
            facet: "categories",
            options: [
              {
                name: this.capitalizeFirstLetter(category),
                value: true,
              },
            ],
          },
        ];
      }

      this.setFacets(newFacets);
    },
  }, // methods

  watch: {
    itemsPerPage: function () {
      this.setPageSize(this.itemsPerPage);
      this.searchProducts();
    },

    price: function () {
      this.setPriceOrder(this.price);
      this.searchProducts();
    }
  }, // watch

  components: {
    ProductItem,
    SidebarFilters,
  }, // components
};
</script>
