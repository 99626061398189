<template>
    <div class="emb-gadget-wrap">

    <!-- page title -->
    <v-flex d-none d-xs-none d-sm-none d-md-flex>
        <emb-page-title
            :heading="pageTitle"
            :imageType="'products'">
        </emb-page-title>
    </v-flex><!-- page title -->
    
    <!-- products -->
    <div class="gadget-content section-gap">
        <v-container>

            <v-alert type="error">
                Discounts may be applied at checkout
            </v-alert>
            
            <div class="d-flex align-center mb-4">
                <Filters
                    :disabled="isLoadingProducts || errorMessage"
                    @page-size-changed="onPageSizeChanged"
                    @price-sorting-changed="onPriceSortingChanged"/>
            </div>

            <SearchResultsGrid
                :errorMessage="errorMessage"
                :isLoadingProducts="isLoadingProducts"
                :pageSize="pageSize"
                :productsPaginatedList="productsPaginatedList"
                @try-again="onTryAgain"/>

            <Pager v-if="productsPaginatedList"
                class="mt-4"
                :pageIndex="pageIndex"
                :totalPageCount="totalPageCount"
                @page-changed="onPageIndexChanged"/>

        </v-container>
    </div><!-- products -->

    </div>
</template>

<script>
import Filters from "@/views/ClientPanel/SearchResults/Filters";
import Pager from "@/views/ClientPanel/SearchResults/Pager";
import SearchResultsGrid from "@/views/ClientPanel/SearchResults/SearchResultsGrid";
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
    data: () => ({
        errorMessage: null,
        isLoadingProducts: true,
        productsPaginatedList: null,
    }), // data

    computed: {
        ...mapGetters({
            searchParams: "productSearch/getSearchParams",
            pageIndex: "productSearch/getPageIndex",
            pageSize: "productSearch/getPageSize",
            priceSorting: "productSearch/getPriceSorting",
            searchValue: "productSearch/getSearchValue",
        }),
        
        pageTitle() {
            return `Search results for "${this.searchValue}"`;
        },

        totalPageCount() {
            return this.productsPaginatedList.totalPages;
        },
    },

    mounted() {
        const searchValue = this.$route.params.searchValue;

        if (searchValue != undefined && searchValue != null) {
            this.setSearchValue(searchValue);
        } else {
            this.setSearchValue("*");
        }

        this.onSearchParamsChanged();
    },

    methods: {
        ...mapActions({
            searchProducts: "productSearch/search",
        }),

        ...mapMutations({
            setPageIndex: "productSearch/SET_SEARCH_PARAMS_PAGE_INDEX",
            setPageSize: "productSearch/SET_SEARCH_PARAMS_PAGE_SIZE",
            setPriceSorting: "productSearch/SET_SEARCH_PARAMS_PRICE_SORTING",
            setSearchValue: "productSearch/SET_SEARCH_PARAMS_SEARCH_VALUE",
        }),

        onPageIndexChanged(pageIndex) {
            console.log("onPageIndexChanged", pageIndex);
            this.setPageIndex(pageIndex);
        },

        onPageSizeChanged(pageSize) {
            this.setPageSize(pageSize);
        },

        onPriceSortingChanged(priceSorting) {
            this.setPriceSorting(priceSorting);
        },

        onSearchParamsChanged() {
            console.log("onSearchParamsChanged!", this.searchParams);

            this.isLoadingProducts = true;

            this.searchProducts()
            .then(response => {
                console.log("Products found!", response.data);
                this.productsPaginatedList = response.data;
                this.errorMessage = null;
            })
            .catch(() => this.errorMessage = "An error occurred while trying to search for products")
            .finally(() => this.isLoadingProducts = false);
        },

        onTryAgain() {
            this.onSearchParamsChanged();
        },
    },

    watch: {
        searchParams() {
            this.onSearchParamsChanged();
        },
        pageIndex() {
            this.onSearchParamsChanged();
        },
        pageSize() {
            this.onSearchParamsChanged();
        },
        priceSorting() {
            this.onSearchParamsChanged();
        },
        searchValue() {
            this.onSearchParamsChanged();
        },
    }, // watch

    components: {
        Filters,
        Pager,
        SearchResultsGrid,
    }, // components
}
</script>
