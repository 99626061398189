var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "table-responsive" },
    [
      _c("v-data-table", {
        attrs: {
          headers: _vm.headers,
          items: _vm.paginatedList.items,
          "server-items-length": _vm.paginatedList.totalCount,
          options: _vm.listViewOptions,
          loading: _vm.isLoading,
          "footer-props": { "items-per-page-options": [10, 20, 30, 40, 50] }
        },
        on: {
          "update:options": function($event) {
            _vm.listViewOptions = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "item.isTagPromoted",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("v-switch", {
                  on: {
                    change: function($event) {
                      return _vm.editTag(item)
                    }
                  },
                  model: {
                    value: item.isTagPromoted,
                    callback: function($$v) {
                      _vm.$set(item, "isTagPromoted", $$v)
                    },
                    expression: "item.isTagPromoted"
                  }
                })
              ]
            }
          },
          {
            key: "item.action",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      small: "",
                      icon: "",
                      to: "/admin-panel/tags/" + item.stockItemTagId
                    }
                  },
                  [_c("v-icon", [_vm._v("list")])],
                  1
                ),
                _c(
                  "v-btn",
                  {
                    attrs: { small: "", icon: "" },
                    on: {
                      click: function($event) {
                        return _vm.showEditTagDialog(item)
                      }
                    }
                  },
                  [_c("v-icon", [_vm._v("edit")])],
                  1
                ),
                _c(
                  "v-btn",
                  {
                    attrs: { small: "", icon: "" },
                    on: {
                      click: function($event) {
                        return _vm.showDeleteTagDialog(item)
                      }
                    }
                  },
                  [_c("v-icon", [_vm._v("delete")])],
                  1
                )
              ]
            }
          }
        ])
      }),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "400" },
          model: {
            value: _vm.isShowingDeleteDialog,
            callback: function($$v) {
              _vm.isShowingDeleteDialog = $$v
            },
            expression: "isShowingDeleteDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-text", [
                _c("h6", { staticClass: "font-weight-regular ma-0 pt-4" }, [
                  _vm._v(
                    ' Are you sure you want to delete tag "' +
                      _vm._s(_vm.selectedTag.stockItemTagName) +
                      '"? '
                  )
                ])
              ]),
              _c(
                "v-card-actions",
                { staticClass: "d-flex justify-end" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error" },
                      on: {
                        click: function($event) {
                          return _vm.deleteTag(_vm.selectedTag)
                        }
                      }
                    },
                    [_vm._v("Yes")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: {
                        click: function($event) {
                          _vm.isShowingDeleteDialog = false
                        }
                      }
                    },
                    [_vm._v("No")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "400" },
          model: {
            value: _vm.isShowingEditDialog,
            callback: function($$v) {
              _vm.isShowingEditDialog = $$v
            },
            expression: "isShowingEditDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-text",
                [
                  _c("v-card-title", [
                    _vm._v('Edit Tag "' + _vm._s(_vm.selectedTagName) + '"')
                  ]),
                  _c("v-text-field", {
                    attrs: { label: "Tag name", rules: _vm.requiredRule },
                    model: {
                      value: _vm.selectedTag.stockItemTagName,
                      callback: function($$v) {
                        _vm.$set(_vm.selectedTag, "stockItemTagName", $$v)
                      },
                      expression: "selectedTag.stockItemTagName"
                    }
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "d-flex justify-space-between align-center"
                    },
                    [
                      _c("p", { staticClass: "ma-0 pa-0" }, [
                        _vm._v("Promoted")
                      ]),
                      _c("v-switch", {
                        model: {
                          value: _vm.selectedTag.isTagPromoted,
                          callback: function($$v) {
                            _vm.$set(_vm.selectedTag, "isTagPromoted", $$v)
                          },
                          expression: "selectedTag.isTagPromoted"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "d-flex justify-end" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "error",
                        disabled:
                          !_vm.selectedTag.stockItemTagName ||
                          _vm.selectedTag.stockItemTagName.trim().length == 0
                      },
                      on: {
                        click: function($event) {
                          return _vm.editTag(_vm.selectedTag)
                        }
                      }
                    },
                    [_vm._v(" Edit ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: {
                        click: function($event) {
                          _vm.isShowingEditDialog = false
                        }
                      }
                    },
                    [_vm._v("Cancel")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }