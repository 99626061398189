var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "emb-thankyou-wrap section-gap" }, [
    _c("div", { staticClass: "container py-0" }, [
      _c(
        "div",
        { staticClass: "layout justify-center align-center" },
        [
          _c("v-flex", { attrs: { sm12: "", md10: "", lg8: "", xl8: "" } }, [
            _c(
              "div",
              {
                staticClass:
                  "form-img thank-you-image text-center hidden-sm-and-down"
              },
              [_c("h2", { staticClass: "white--text" }, [_vm._v("Thank You")])]
            ),
            _c(
              "div",
              { staticClass: "layout align-center justify-center" },
              [
                _c(
                  "v-flex",
                  { attrs: { xs12: "", md8: "", lg6: "", xl6: "" } },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "emb-card pa-6\n                        thankyou-block\n                        white pa-6\n                        text-center"
                      },
                      [
                        _c("h4", { staticClass: "px-6 pb-2 text-center" }, [
                          _vm._v("For every thing you had done with Embryo")
                        ]),
                        _c("v-divider", { staticClass: "pt-2 pb-4" }),
                        _c("div", { staticClass: "mx-auto text-center" }, [
                          _c("p", { staticClass: "mb-4" }, [
                            _vm._v("Spread The Word")
                          ]),
                          _c(
                            "div",
                            { staticClass: "mb-6 d-block" },
                            [_c("emb-social-share")],
                            1
                          )
                        ]),
                        _c(
                          "v-btn",
                          {
                            staticClass: "accent elevation-5 my-0",
                            attrs: { block: "", height: "50" }
                          },
                          [_vm._v("Buy Embryo Now")]
                        )
                      ],
                      1
                    )
                  ]
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }