import api from '@/api';

export default {
    namespaced:true,
    state:{
        moduleName:'imageType'
    },
    actions:{
        getTypes({state}){
            return api.get(`${state.moduleName}`);
        }
        
    }
}