<template>
    <div v-if="person" class="px-sm-4 px-md-12">

        <v-text-field
            class="tab-input-title mb-4"
            :disabled="isView"
            hide-details
            prepend-icon="person"
            required
            v-model="person.fullName"/>

        <Breadcrumbs class="mb-4" :items="breadcrumbs"/>

        <v-card class="mb-4">
            <v-tabs :vertical="verticalTabs" :centered="!verticalTabs" :center-active="!verticalTabs">
                <v-tab class="d-flex flex-column flex-md-row justify-md-start justify-center">
                    <v-icon :left="verticalTabs">account_circle</v-icon>
                    Profile
                </v-tab>
                <v-tab class="d-flex flex-column flex-md-row justify-md-start justify-center">
                    <v-icon :left="verticalTabs">security</v-icon>
                    Permissions
                </v-tab>
                <v-tab class="d-flex flex-column flex-md-row justify-md-start justify-center">
                    <v-icon :left="verticalTabs">account_box</v-icon>
                    Customers
                </v-tab>
                <v-tab-item>
                    <PersonProfileTab :selectedPerson="person"/>
                </v-tab-item>
                <v-tab-item>
                    <PersonPermissionsTab :selectedPerson="person"/>
                </v-tab-item>
                <v-tab-item>
                    <PersonCustomersTab :person="person"/>
                </v-tab-item>
            </v-tabs>
        </v-card>

        <div class="d-flex justify-end" v-if="!isView">
            <v-btn :loading="isPerformingOperation" :disabled="isPerformingOperation" color="error" large @click="onClickSave">Save</v-btn>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { BreadcrumbItem } from '../../../components/Breadcrumbs/BreadcrumbItem'

export default {
    data: () => ({
        isEdit: false,
        isView: false,
        isAdd:false,
        newPerson:{
            fullName:'',
            isPermittedToLogon:false,
            isExternalLogonProvider:false,
            isSystemUser:false,
            isEmployee:false,
            isSalesperson:false,
            isAdmin:false,
            lastEditedBy:1,
            searchName:'',
        },
        personId: undefined,
        verticalTabs: true,
        isPerformingOperation: false,
    }), // data

    computed: {
        ...mapGetters({
            person: 'people/getCurrentPerson',
            session: 'session/getAccount',
        }),

        breadcrumbs() {
            return [
                new BreadcrumbItem({ label: 'People', route: '/admin-panel/people' }),
                new BreadcrumbItem({ label: 'Person Details' }),
            ]
        },
    }, // computed

    beforeMount() {
        this.isEdit = this.$route.path.includes('edit');
        this.isView = this.$route.path.includes('view');
        this.isAdd = this.$route.path.includes('add');
        this.personId = this.$route.params.id;

        if (this.personId) {
            this.getByPersonId(this.personId);
        }
        
        
    }, // beforeMount

    mounted() {
        // watch for window dimensions (for tabs responsiveness)
        this.$nextTick(() => {
            window.addEventListener('resize', () => {
                this.verticalTabs = window.innerWidth >= 960;
            });
        });
        this.verticalTabs = window.innerWidth >= 960;
        if(this.isAdd){
            this.person = null;
        }
    }, // mounted

    methods: {
        ...mapActions({
            getByPersonId: 'people/getByPersonId',
			updatePerson: 'people/update',
            createPerson:'people/post'
        }),

        onClickSave() {
            this.isPerformingOperation = true;
            if(this.isAdd){
                console.log('adding person:', this.newPerson);
                this.createPerson(this.newPerson)
                .then(()=> 
                {   
                    this.$snotify.success('The user has been created!', { timeout: 2000 });
                    this.$router.push('/admin-panel/people');
                    this.newPerson.fullName = null;
                    this.newPerson.isPermittedToLogon = null;
                    this.newPerson.isExternalLogonProvider = null;
                    this.newPerson.isSystemUser = null;
                    this.newPerson.isEmployee = null;
                    this.newPerson.isSalesperson = null;
                    this.newPerson.isAdmin = null;
                    this.newPerson.lastEditedBy = null;
                    this.newPerson.searchName = null;
                })
                .catch(error => console.error(error));
            
            }else{
                console.log('Updating person:', this.person);
    
                this.updatePerson(this.person)
                .then(() => this.$router.go())
                .catch(error => console.error(error));
            }

        },
    }, // methods
}
</script>
