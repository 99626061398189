export class NumberFormatterService {

    commaSeparated(number) {
        return number.toLocaleString();
    }

    currencyFormat(number) {
        let fixedNumber = Number.parseFloat(number).toFixed(2);
        return String(fixedNumber).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

}
