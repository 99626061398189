var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: [_vm.colClasses, { flex: _vm.colClasses }] }, [
    _c(
      "div",
      {
        staticClass: "app-card",
        class: [_vm.customClasses, { "d-none": _vm.close }]
      },
      [
        _c("app-card-heading", {
          attrs: {
            heading: _vm.heading,
            removePanel: _vm.removePanel,
            disableNotification: _vm.disableNotification,
            settings: _vm.settings,
            viewMore: _vm.viewMore,
            withTabs: _vm.withTabs,
            tabs: _vm.tabs
          },
          on: {
            onChangeTabCallback: _vm.onChangeTab,
            onReload: _vm.onReload,
            onClose: _vm.onClose
          }
        }),
        _c(
          "app-card-content",
          {
            attrs: {
              extraClass: [_vm.contentCustomClass],
              fullBlock: _vm.fullBlock
            }
          },
          [_vm._t("default")],
          2
        ),
        _vm.footer ? _c("app-card-footer", [_vm._t("footer")], 2) : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }