<template>
  <div>
    <AdminPanelHeader
      icon="mdi-handshake"
      title="Trading Partner"
      :actions="headerActions"
      @search="onSearch"
    />

    <TradingPartnerTable
      :isLoading="isLoading"
      :paginatedList="currentEntity"
      v-on:onDelete="onDelete"
      :searchParams="paginatedQueryParams"
    />
  </div>
</template>

<script>
import { AdminPanelHeaderAction } from "../../../components/AdminPanelHeader/AdminPanelHeaderAction";
import apiClient from "../../../apiClient";
import { showDialog } from '../../../components/Dialogs/NotifyDialog';
import { parentComponentMixin } from '../../../mixins/parentComponentMixin';
export default {
  mixins:[parentComponentMixin],
  data() {
    return {
      paginatedQueryParams: {
        pageSize: 10,
        pageNumber: 0,
        searchValue: "",
        sortField: "X12tradingPartnerId",
        sortDirection: "DESC",
      },
      headerActions: [
        new AdminPanelHeaderAction({
          color: "accent",
          label: "Add Partner",
          icon: "add",
          action: () => this.$router.push("/admin-panel/partner-add"),
        }),
      ],
    };
  },
  
  methods: {
    async onDelete(id){
      try {
        this.isLoading = true;
        await apiClient.X12("TradingPartner").delete(id);
        showDialog("Deleted Successfully");
        this.getPaginated()
      } catch (error) {
        showDialog("Could not delete item", error);
      }finally{
        this.isLoading = false;
      }
    },
    async getPaginated(){
      try {
        this.isLoading = true;
        this.currentEntity = await apiClient.X12("tradingPartner").getPaginated(this.paginatedQueryParams);
      } catch (error) {
        showDialog("Could not get partners data",error);
      }finally{
        this.isLoading = false;
      }

    }
  },
};
</script>