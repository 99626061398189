var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main" },
    [
      _c(
        "div",
        { staticClass: "banner-wrap" },
        [
          _c("emb-home-main-banner-v3", {
            attrs: { data: _vm.homeBannerV3Data }
          })
        ],
        1
      ),
      _c("feature-v2", { attrs: { data: _vm.featureV2Data } }),
      _c("collection-gallery"),
      _c("shop-by-category", {
        attrs: { data: _vm.shopbyCategoryData, secTitle: "Shop By Category" }
      }),
      _c("emb-feature-product-v2", {
        attrs: { secTitle: "New Products", data: _vm.featureProductV2Data }
      }),
      _c("banner"),
      _c(
        "div",
        { staticClass: "top-brands-wrap-v2" },
        [
          _c("emb-shop-card", {
            staticClass: "pt-0",
            attrs: { secTitle: "Top Brands", data: _vm.topBrandsData }
          })
        ],
        1
      ),
      _c("subscribe-v2", {
        attrs: {
          heading: "Subscribe our Newsletter",
          description: "Stay up to date with our latest new and products"
        }
      }),
      _c("download-app")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }