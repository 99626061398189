var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gadget-content" },
    [
      _c("emb-page-title", {
        attrs: { heading: "My Account", imageType: "my-account" }
      }),
      _c(
        "div",
        { staticClass: "container grid-list-xl my-10 py-0" },
        [
          _vm.currentAccount
            ? _c("accounts-view", {
                attrs: {
                  accountId: _vm.currentAccount.internalId,
                  readOnly: true,
                  isClient: true
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }