var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      disabled: _vm.isView,
                      label: "Branch *",
                      rules: _vm.requiredRule
                    },
                    model: {
                      value: _vm.selectedSupplier.bankAccountBranch,
                      callback: function($$v) {
                        _vm.$set(_vm.selectedSupplier, "bankAccountBranch", $$v)
                      },
                      expression: "selectedSupplier.bankAccountBranch"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      disabled: _vm.isView,
                      label: "Code *",
                      rules: _vm.requiredRule
                    },
                    model: {
                      value: _vm.selectedSupplier.bankAccountCode,
                      callback: function($$v) {
                        _vm.$set(_vm.selectedSupplier, "bankAccountCode", $$v)
                      },
                      expression: "selectedSupplier.bankAccountCode"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      disabled: _vm.isView,
                      label: "Name *",
                      rules: _vm.requiredRule
                    },
                    model: {
                      value: _vm.selectedSupplier.bankAccountName,
                      callback: function($$v) {
                        _vm.$set(_vm.selectedSupplier, "bankAccountName", $$v)
                      },
                      expression: "selectedSupplier.bankAccountName"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      disabled: _vm.isView,
                      label: "Number *",
                      rules: _vm.requiredRule
                    },
                    model: {
                      value: _vm.selectedSupplier.bankAccountNumber,
                      callback: function($$v) {
                        _vm.$set(_vm.selectedSupplier, "bankAccountNumber", $$v)
                      },
                      expression: "selectedSupplier.bankAccountNumber"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("v-text-field", {
            attrs: {
              disabled: _vm.isView,
              label: "International Code *",
              rules: _vm.requiredRule
            },
            model: {
              value: _vm.selectedSupplier.bankInternationalCode,
              callback: function($$v) {
                _vm.$set(_vm.selectedSupplier, "bankInternationalCode", $$v)
              },
              expression: "selectedSupplier.bankInternationalCode"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }