/* eslint-disable */

import api from '@/api';
import Vue from 'vue';

export default {
    namespaced: true,

    state: {
        moduleName: 'product',
        stockItems: [],
        searchParams: {
            pageSize: 10,
            pageNumber: 1,
            searchValue: undefined, // it can be either stockItemName or internalId
            sortField: 'stockItemName',
            sortDirection: 'ASC',
        },
    }, // state

    getters: {
        getStockItems: state => state.stockItems,
        getSearchParams: state => state.searchParams,
    }, // getters

    mutations: {
        SET_STOCK_ITEMS(state, stockItems) {
            state.stockItems = stockItems;
        },
        SET_SEARCH_PARAMS(state, searchParams) {
            state.searchParams = searchParams;
        },
        SET_SEARCH_PARAM_PAGE_SIZE(state, pageSize) {
            state.searchParams.pageSize = pageSize;
        },
        SET_SEARCH_PARAM_PAGE_NUMBER(state, pageNumber) {
            state.searchParams.pageNumber = pageNumber;
        },
        SET_SEARCH_PARAM_SEARCH_VALUE(state, searchValue) {
            state.searchParams.searchValue = searchValue;
        },
        SET_SEARCH_PARAM_SORT_FIELD(state, sortField) {
            state.searchParams.sortField = sortField;
        },
        SET_SEARCH_PARAM_SORT_DIRECTION(state, sortDirection) {
            state.searchParams.sortDirection = sortDirection;
        },
        RESET_SEARCH_PARAMS(state) {
            state.searchParams.pageSize = 10;
            state.searchParams.pageNumber = 1;
            state.searchParams.searchValue = undefined;
            state.searchParams.sortField = 'stockItemName';
            state.searchParams.sortDirection = 'ASC';
        },
    }, // mutations

    actions: {
        getByTag({ state, commit }, stockItemTagId) {
            return api.get(`${state.moduleName}/tag/${stockItemTagId}`, { params: state.searchParams })
            .then(result => commit('SET_STOCK_ITEMS', result.data));
        },
    }, // actions
}
