<template>
  <div class="responsive-table">
    <v-data-table
      :headers="headers"
      :items="paginatedList.items"
      :server-items-length="paginatedList.totalCount"
      :options.sync="listViewOptions"
      :loading="isLoading"
      :footer-props="{ 'items-per-page-options': [10, 15, 20, 25, 50] }"
    >
      <template v-slot:[`item.action`]="{ item }">
        <Menu :menuItems="rowMenuItems" :menuItemContext="item">
          <v-btn
            small
            icon
            class="primary--text" 
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </Menu>
      </template>
    </v-data-table>
    <YesNoDialog ref="yesNoDialog" />
  </div>
</template>
<script>
import { MenuItem } from '../../../../components/Menu/MenuItem';
import { tableProps } from "../../../../mixins/tableProps";
import { yesNoDialogMixin } from "../../../../mixins/showYesNoDialog";
export default {
  mixins: [tableProps,yesNoDialogMixin],
  name: "FileTypeTable",
  data() {
    return {
      rowMenuItems: [
        new MenuItem({
          icon: "edit",
          label: "Edit",
          action: (file) => {
            this.$router.push(
              "/admin-panel/definitions/fileTypes/edit/" + file.x12fileTypeId
            );
          },
        }),
        new MenuItem({
          icon: "delete",
          label: "Delete",
          action: (file ) => {
            this.showDeleteDialog(file.x12fileTypeId)
          },
        }),
      ],
      headers: [
        {
          text: "FileType Id",
          sortable: true,
          value: "x12fileTypeId",
        },
        {
          text: "File Type Description",
          sortable: true,
          value: "x12fileTypeDescription",
        },
        {
          text: "File Type Transaction Set Id",
          sortable: true,
          value: "x12fileTypeTransactionSetId",
        },
        {
          text: "Actions",
          sortable: false,
          value: "action",
        },
      ],
    };
  },
};
</script>
