/* eslint-disable */

import axios from 'axios';
import Vue from 'vue';


export const cancelToken = axios.CancelToken;

const axiosApiInstance = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
        'Ocp-Apim-Subscription-Key': process.env.VUE_APP_API_KEY,
        'Access-Control-Allow-Origin': '*',
    },
});

axiosApiInstance.interceptors.request.use(async config => {
    const msal = Vue.prototype.$msal;
    try {
        let expirationTime = sessionStorage.getItem('fb.expirationTime');
        let now = new Date();
        let expire = new Date(expirationTime);
        expire.setMinutes(expire.getMinutes() - 5);

        // Token expired!
        if (now.getTime() >= expire.getTime()) {
            throw 'Token expired';
        }

        // Access token is still valid
        let accessToken = sessionStorage.getItem('fb.accessToken');
        config.headers.Authorization = `Bearer ${accessToken}`;

        return config;
    }
    // Session expired
    catch (error) {
        console.error(error);

        sessionStorage.setItem('fb.isSessionExpired', 'true');
        sessionStorage.setItem('fb.lastLocation', window.location.pathname);
        msal.logout();

        return config;
    }
},
    error => Promise.reject(error));

export default axiosApiInstance;
