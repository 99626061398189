export class MenuItem {
    /**
     * @type {String}
     */
    icon
    
    /**
     * @type {String}
     */
    label

    /**
     * @type {Function}
     */
    action

    constructor({ icon, label, action }) {
        this.action = action
        this.icon = icon
        this.label = label
    }
}
