<template>
  <div class="emb-cart-wrap emb-card">

      <!-- banner -->
      <emb-page-title
          heading="Here's what's in your cart"
          imageType="cart">
      </emb-page-title><!-- banner -->

      <!-- main container -->
      <div class="cart-content section-gap">
          <v-container grid-list-xl py-0>
            <div v-if="!isCartEmpty">
              <v-row justify="end">
                <v-col cols="auto" sm="auto" class="d-flex justify-center align-center" >
                  <v-btn color="primary" @click="removeAllFromCart">Remove All</v-btn>
              
                </v-col>

              </v-row>


            </div>

            <!-- cart is loading -->
            <div v-if="isCartLoading">

              <h5 class="text-center font-weight-regular mb-12">Loading your cart...</h5>

              <v-card class="mb-12">

                <div v-for="(_, index) of new Array(3)" :key="index">

                  <!-- loading order line item -->
                  <div :class="{ 'pt-1': index == 0 }">
                    <v-skeleton-loader type="text" width="100%" class="my-8 px-6"/>
                    <v-skeleton-loader type="text" width="100%" class="my-8 px-6"/>
                    <v-skeleton-loader type="text" width="100%" class="my-8 px-6"/>
                  </div><!-- loading order line item -->

                  <v-divider/>
                </div>

              </v-card>

              <!-- order totals -->
              <v-row>

                <!-- spacer -->
                <v-col sm="12" md="7">
                </v-col><!-- spacer -->

                <!-- totals -->
                <v-col sm="12" md="5">

                  <v-skeleton-loader type="text" width="100%" class="mb-8"/>
                  <v-skeleton-loader type="text" width="100%" class="mb-8"/>
                  <v-skeleton-loader type="text" width="100%" class="mb-8"/>
                  <v-skeleton-loader type="text" width="100%" class=""/>

                </v-col><!-- totals -->

              </v-row><!-- order totals -->

            </div><!-- cart is loading -->

            <!-- cart loaded -->
            <div v-else>

              <!-- cart is empty -->
              <div v-if="isCartEmpty" class="text-center">
                  <div class="text-center">
                      <div class="mb-6">
                          <img alt="cart-empty" height="128" src="static/images/empty-cart.png" width="128"/>
                      </div>
                      <h4>Your Shopping Bag is empty</h4>
                      <router-link class="primary--text" to="/">Go to Shopping</router-link>
                  </div>
              </div><!-- cart is empty -->

              <!-- cart has items -->
              <div v-else>
                  
                  <!-- order lines -->
                  <v-card class="mb-12">
                    <div v-for="(orderLine, index) of order.orderLines" :key="index">
                      <OrderLineItem
                        :ref="`orderLineItem-${orderLine.orderLineId}`"
                        :item="orderLine"
                        :order="order"/>
                      <v-divider/>
                    </div>
                  </v-card><!-- order lines -->

                  <!-- order totals -->
                  <v-row>

                    <!-- spacer -->
                    <v-col sm="12" md="7">
                    </v-col><!-- spacer -->

                    <!-- totals -->
                    <v-col sm="12" md="5">

                      <OrderTotals :order="order"
                        :amountSubtotal="orderSubTotal"
                        :amountDeposits="orderDeposits"
                        :amountDiscounts="orderDiscounts"
                        :amountTotal="orderTotal"/>

                      <!-- minimum amount alert/warning -->
                      <v-alert v-if="isMimimumAmountReached == false" type="warning">
                        You must have a minimun order amount of $300.00 to place your order.
                      </v-alert><!-- minimum amount alert/warning -->
                      
                      <!-- checkout -->
                      <div class="d-flex justify-end w-100 mt-6">
                          <v-btn
                            class="accent"
                            large
                            :disabled="isMimimumAmountReached == false"
                            to="/checkout">
                          Checkout
                        </v-btn>
                      </div><!-- checkout -->
                      
                    </v-col><!-- totals -->

                  </v-row><!-- order totals -->

              </div><!-- cart has items -->

            </div><!-- cart loaded -->

          </v-container>
      </div><!-- main container -->

      <!-- dialogs -->
      <div>

          <!-- edit quantity dialog -->
          <v-dialog v-model="isShowingEditQuantityDialog" max-width="300" persistent>
              <v-card>
                  <v-card-title>Edit Quantity</v-card-title>
                  <v-divider/>

                  <v-card-text>
                      <div class="my-8"></div>
                      <v-text-field
                      type="number"
                      label="Current Quantity"
                      disabled
                      v-model="selectedOrderLine.quantity"
                      />
                      <v-text-field
                      type="number"
                      label="New Quantity"
                      v-model="newQuantity"
                      />
                  </v-card-text>`

                  <v-divider/>
                  <v-card-actions>
                      <v-spacer />
                      <v-btn
                      text
                      color="primary"
                      :disabled="!isNewQuantityValid || isUpdatingQuantity"
                      :loading="isUpdatingQuantity"
                      @click="onConfirmQuantityChange"
                      >Confirm</v-btn
                      >
                      <v-btn
                      text
                      color="error"
                      :disabled="isUpdatingQuantity"
                      @click="isShowingEditQuantityDialog = false"
                      >Cancel</v-btn
                      >
                  </v-card-actions>
              </v-card>
          </v-dialog><!-- edit quantity dialog -->

          <!-- remove from cart dialog -->
          <v-dialog v-model="isShowingRemoveFromCartDialog" max-width="600" persistent>
              <v-card>
                  <v-card-title>Remove from cart</v-card-title>
                  <v-divider/>

                  <v-card-text v-if="orderLineToRemove && orderLineToRemove.stockItem">
                      <p class="ma-0 mt-8 pa-0">Are you sure you want to remove <b>{{orderLineToRemove.stockItem.stockItemName}}</b> from your cart?</p>
                  </v-card-text>`

                  <v-divider/>
                  <v-card-actions>
                      <v-spacer/>
                      <v-btn text color="primary"
                          :disabled="isRemovingFromCart"
                          :loading="isRemovingFromCart"
                          @click="onRemoveFromCartYesClicked">
                          Yes
                      </v-btn>

                      <v-btn text color="error"
                          :disabled="isRemovingFromCart"
                          @click="isShowingRemoveFromCartDialog = false">
                          No
                      </v-btn>
                  </v-card-actions>
              </v-card>
          </v-dialog><!-- remove from cart dialog -->
      
      </div><!-- dialogs -->
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters, mapMutations } from "vuex";
import { currency, utils } from "../mixins";
import OrderLineItem from '@/components/OrderLineItem';
import OrderTotals from '@/components/OrderTotals';

export default {

  mixins: [ currency, utils ],
  
  data() {
    return {
      selectDeletedProduct: null,
      isMimimumAmountReached: false,
      isRemovingFromCart: false,
      isShowingEditQuantityDialog: false,
      isShowingRemoveFromCartDialog: false,
      isUpdatingQuantity: false,
      orderLineToRemove: {},
      selectedOrderLine: {},
      newQuantity: 0,
      orderSubTotal: undefined,
      orderDeposits: undefined,
      orderDiscounts: undefined,
      orderTotal: undefined,
    };
  }, // data

  mounted() {
    if (this.order) {
      this.$nextTick(() => {
        this.orderSubTotal = this.getOrderSubTotal();
        this.orderDeposits = this.getOrderDeposits();
        this.orderDiscounts = this.getOrderDiscounts();
        this.orderTotal = this.getOrderTotal();
        this.isMimimumAmountReached = this.orderTotal >= 0;
      });
    }
  }, // mounted

  computed: {
    ...mapGetters({ order: "order/getCart" }),

    isCartLoading() {
      if (this.order == undefined || this.order == null)
        return true;

      if (!this.order.orderId)
        return true;

      if (this.order.orderId == 0)
        return true;

      return false;
    },

    isCartEmpty() {
      if (this.isCartLoading)
        return true;

      if (!this.order.orderLines)
        return true;
      
      if (this.order.orderLines.length == 0)
        return true;
      
      return false;
    },

    isNewQuantityValid() {
      return this.newQuantity && this.newQuantity > 0;
    },
  }, // computed

  methods: {
    ...mapMutations({
      setCartProducts: "order/SET_ORDER_LINES",
    }),

    ...mapActions({
      addProductToCart: "order/addProductToCart2",
      removeFromCart: 'order/removeFromCart',
    }),
    
    getOrderSubTotal() {
      let orderSubTotal = 0;

      for (const orderLine of this.order.orderLines) {
        const orderLineItemRef = `orderLineItem-${orderLine.orderLineId}`;
        const orderLineItem = this.$refs[orderLineItemRef][0];
        orderSubTotal += orderLineItem.unitPrice * orderLineItem.quantity;
      }

      return orderSubTotal;
    },

    getOrderDeposits() {
      let orderDeposits = 0;

      for (const orderLine of this.order.orderLines) {
        const orderLineItemRef = `orderLineItem-${orderLine.orderLineId}`;
        const orderLineItem = this.$refs[orderLineItemRef][0];
        orderDeposits += orderLineItem.depositPrice * orderLineItem.quantity;
      }

      return orderDeposits;
    },

    getOrderDiscounts() {
      let orderDiscounts = 0;

      for (const orderLine of this.order.orderLines) {
        const orderLineItemRef = `orderLineItem-${orderLine.orderLineId}`;
        const orderLineItem = this.$refs[orderLineItemRef][0];
        orderDiscounts += orderLineItem.discount * orderLineItem.quantity;
      }

      return orderDiscounts;
    },

    getOrderTotal() {
      return this.getOrderSubTotal() + this.getOrderDeposits() - this.getOrderDiscounts();
    },

    getStockItemPath(stockItem) {
      const rawType = stockItem.type;

      if (!rawType)
        return null;

      const type = rawType.trim().toLowerCase();
      const id = stockItem.stockItemId;

      return `/products/${type}/${id}`;
    },

    hasSpecialDeals(stockItem) {
      if (!stockItem)
        return false;

      const specialDeals = stockItem.specialDeals;

      if (!specialDeals)
        return false;

      if (specialDeals.length == 0)
        return false;

      return true;
    },

    updateCart() {
      this.setCartProducts(this.order.orderLines);
      this.$snotify.success("The cart has been updated", {
        closeOnClick: false,
        pauseOnHover: false,
        timeout: 1000,
      });
    },

    onRemoveFromCartYesClicked() {
        console.log('onRemoveFromCartYesClicked', this.orderLineToRemove);

        this.isRemovingFromCart = true;

        this.removeFromCart({orderLineId:this.orderLineToRemove.orderLineId, removeAll: false})
        .then(() => {
            this.isShowingRemoveFromCartDialog = false;
            this.$snotify.success('The product has been removed!', {
                closeOnClick: false,
                pauseOnHover: false,
                timeout: 1500,
            });
        })
        .catch(error => {
            this.$snotify.error(`Could not remove product. ${error}`, {
                closeOnClick: false,
                pauseOnHover: false,
                timeout: 4000,
            });
        })
        .finally(() => this.isRemovingFromCart = false);
    },

    openRemoveFromCartDialog(orderLine) {
        this.isShowingRemoveFromCartDialog = true;
        this.orderLineToRemove = orderLine;
    },

    showEditQuantityDialog(selectedOrderLine) {
      this.selectedOrderLine = selectedOrderLine;
      this.newQuantity = this.selectedOrderLine.quantity;
      this.isShowingEditQuantityDialog = true;
    },

    onConfirmQuantityChange() {
      this.isUpdatingQuantity = true;

      this.addProductToCart({
        quantity: this.newQuantity - this.selectedOrderLine.quantity,
        stockItemId: this.selectedOrderLine.stockItemId,
      })
        .then(() => {
          console.log("Order has been updated!", this.order);
          this.isShowingEditQuantityDialog = false;
          this.selectedOrderLine = {};
        })
        .catch((error) => {
          console.error("Could not update quantity:", error);
          this.$snotify.error(`Could not update quantity.\n${error}`, {
            closeOnClick: false,
            pauseOnHover: true,
            timeout: 4000,
          });
        })
        .finally(() => (this.isUpdatingQuantity = false));
    }, // onConfirmQuantityChange
    removeAllFromCart(){
      this.removeFromCart({ orderLineId: 0, removeAll: true})
            .then(() => {
                this.isShowingRemoveFromCartDialog = false;
                this.$snotify.success('All products have been removed!', {
                    closeOnClick: false,
                    pauseOnHover: false,
                    timeout: 1500,
                });
            })
            .catch(error => {
                this.$snotify.error(`Could not remove products. ${error}`, {
                    closeOnClick: false,
                    pauseOnHover: false,
                    timeout: 4000,
                });
            })
            .finally(() => this.isRemovingFromCart = false);
    }

  }, // methods

  watch: {
    order() {
      this.$nextTick(() => {
        this.orderSubTotal = this.getOrderSubTotal();
        this.orderDeposits = this.getOrderDeposits();
        this.orderDiscounts = this.getOrderDiscounts();
        this.orderTotal = this.getOrderTotal();
        this.isMimimumAmountReached = this.orderTotal >= 0;
      });
    },
  }, // watch

  components: {
    OrderLineItem,
    OrderTotals,
  }, // components
};
</script>
