export default [
   {
      image: "https://lnfextassetsprodscus.blob.core.windows.net/product-tiles/ab.jpg",
      path: "/products/Anheuser-Busch",
      description: "Anheuser-Busch",
      subheader: "Beverages from the worlds largest brewer"
   },
   {
      image: "https://lnfextassetsprodscus.blob.core.windows.net/product-tiles/crafts.jpg",
      path: "/products/beer",
      description: "Craft Beer and Imports",
      subheader: "Small scale - big taste"
   },
   {
      image: "https://lnfextassetsprodscus.blob.core.windows.net/product-tiles/wines.jpg",
      path: "/products/wine",
      description: "Wine",
      subheader: "From the Earth to the table"
   },
   {
      image: "https://lnfextassetsprodscus.blob.core.windows.net/product-tiles/spirits.jpg",
      path: "/products/spirits",
      description: "Spirits",
      subheader: "Spirits and stuff"
   },
   {
      image: "https://lnfextassetsprodscus.blob.core.windows.net/product-tiles/seltzers.jpg",
      path: "/products/seltzer",
      description: "Seltzers",
      subheader: "For seltzer enthusiasts"
   },
   {
      image: "https://lnfextassetsprodscus.blob.core.windows.net/product-tiles/mixers.jpg",
      path: "/products/non-alcoholic",
      description: "Non Alcoholic",
      subheader: "Non alcoholic and stuff"
   },
]