var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function(ref) {
              var on = ref.on
              return [_c("span", _vm._g({}, on), [_vm._t("default")], 2)]
            }
          }
        ],
        null,
        true
      )
    },
    [
      _c(
        "v-list",
        _vm._l(_vm.menuItems, function(item, index) {
          return _c(
            "v-list-item",
            {
              key: index,
              on: {
                click: function($event) {
                  return _vm.onMenuItemClicked(item)
                }
              }
            },
            [
              item.icon
                ? _c("v-icon", { staticClass: "mr-4" }, [
                    _vm._v(_vm._s(item.icon))
                  ])
                : _vm._e(),
              _c("span", [_vm._v(_vm._s(item.label))])
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }