<template>
  <div>
    <Breadcrumbs class="mb-4" :items="breadcrumbs" />

    <v-card class="mb-4">
      <v-tabs
        :vertical="verticalTabs"
        :centered="!verticalTabs"
        :center-active="!verticalTabs"
      >
        <v-tab
          class="d-flex flex-column flex-md-row justify-md-start justify-center"
        >
          <v-icon :left="verticalTabs">mdi-information-outline</v-icon>
          Overview
        </v-tab>
        <v-tab
          class="d-flex flex-column flex-md-row justify-md-start justify-center"
        >
          <v-icon :left="verticalTabs">mdi-blur</v-icon>
          Unit Mappings
        </v-tab>
        <v-tab-item class="pa-4">
          <div v-if="isLoading">
            <v-skeleton-loader class="mb-4" type="text" width="100%" />
            <v-skeleton-loader class="mb-4" type="text" width="100%" />
            <v-skeleton-loader class="mb-4" type="text" width="100%" />
            <v-skeleton-loader class="mb-4" type="text" width="100%" />
          </div>
          <v-row v-if="!isLoading">
            <v-col cols="12" sm="6">
              <v-text-field
                label="Subscription Id"
                v-model="currentObject.x12subscriptionId"
                :disabled="isView"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-autocomplete
                v-model="currentObject.x12tradingPartnerId"
                :items="tradingPartners.items"
                item-value="x12tradingPartnerId"
                item-text="x12tradingPartnerId"
                label="x12tradingPartnerId"
                :disabled="isView"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                label="Chain Id"
                v-model="currentObject.chainId"
                :disabled="isView"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                label="Buying Group Id"
                v-model="currentObject.buyingGroupId"
                :disabled="isView"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                label="Subscription Description"
                v-model="currentObject.x12subscriptionDescription"
                :disabled="isView"
              />
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item>
          <TradingSubscriptionsMappings />
        </v-tab-item>
      </v-tabs>
    </v-card>
    <v-row justify="end" v-if="!isView">
      <v-col align-self="end" cols="auto">
        <v-btn color="primary" @click="save">Save</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import apiClient from "../../../apiClient";
import { BreadcrumbItem } from "../../../components/Breadcrumbs/BreadcrumbItem";
import { showDialog } from "../../../components/Dialogs/NotifyDialog";
import { formsMixin } from '../../../mixins/formsMixin';
import { StaticRoutes } from "../../../router/Routes";
export default {
  mixins:[formsMixin],
  data() {
    return {
      verticalTabs: true,
      tradingPartners: {},
      paginatedQueryParams: {
        pageSize: 10,
        pageNumber: 0,
        searchValue: "",
        sortField: "partnerId",
        sortDirection: "DESC",
      },
      breadcrumbs: [
        new BreadcrumbItem({
          label: "Trading Subscriptions",
          route: StaticRoutes.adminPanel.trading.subscriptions,
        }),
        new BreadcrumbItem({ label: "Trading Subscription Details" }),
      ],
    };
  },
  mounted() {
    this.verticalTabs = window.innerWidth >= 960;
    this.$nextTick(() => {
      window.addEventListener("resize", () => {
        this.verticalTabs = window.innerWidth >= 960;
      });
    });
    this.getTradingPartners();
  },
  methods: {
    async save() {
      try {
        this.isLoading = true;
        await apiClient.X12("tradingSubscriptions").update(this.currentObject)
        showDialog("Updated Successfully");
      } catch (error) {
        showDialog("Could not save item", error);
      } finally {
        this.isLoading = false;
      }
    },
    async getTradingPartners() {
      this.tradingPartners = await apiClient.X12("tradingPartner").getPaginated(this.paginatedQueryParams);
    },
    async getById() {
      try {
        this.isLoading = true;
        this.currentObject = await apiClient.X12("tradingSubscriptions").getById(this.id);
      } catch (error) {
        showDialog("Could not get data",error)
      }
      this.isLoading = false;
    },
  },
};
</script>