<template>
  <div class="table-responsive">
    <v-data-table
      :headers="headers"
      :items="paginatedList.items"
      :server-items-length="paginatedList.totalCount"
      :options.sync="listViewOptions"
      :loading="isLoading"
      :footer-props="{ 'items-per-page-options': [10, 20, 30, 40, 50] }"
    >
      <!-- TODO: Create two route for the edit and the view partners -->
      <template v-slot:[`item.action`]="{ item }">
        <Menu :menuItems="rowMenuItems" :menuItemContext="item">
          <v-btn small icon class="primary--text">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </Menu>
      </template>
      
    </v-data-table>
    <YesNoDialog ref="yesNoDialog" />
  </div>
</template>

<script>
import { MenuItem } from '../../../components/Menu/MenuItem';
import { yesNoDialogMixin } from '../../../mixins/showYesNoDialog';
import { tableProps } from '../../../mixins/tableProps';

export default {
name:'TradingPartnerTable',
mixins:[tableProps,yesNoDialogMixin],
  data() {
    return {
      rowMenuItems: [
        new MenuItem({
          icon: "edit",
          label: "Edit",
          action: (item) => {
            this.$router.push(
              "/admin-panel/partner-edit/" +
                item.x12tradingPartnerId
            );
          },
        }),
        new MenuItem({
          icon: "delete",
          label: "Delete",
          action: (item) => {
            this.showDeleteDialog(item.x12tradingPartnerId);
          },
        }),
      ],
      headers: [
        {
          text: "Partner Id",
          sortable: true,
          value: "x12tradingPartnerId",
        },
        {
          text: "Company  Id",
          sortable: true,
          value: "companyId",
        },
        {
          text: "Partner description",
          sortable: true,
          value: "x12tradingPartnerDescription",
        },
        {
          text: "Partner qualifier",
          sortable: true,
          value: "x12interchangeTradingPartnerQualifier",
        },
        {
          text: "Partner Identifier",
          sortable: true,
          value: "x12interchangeTradingPartnerIdentifier",
        },
        {
          text: "Partner Group Identifier",
          sortable: true,
          value: "x12interchangeTradingPartnerGroupIdentifier",
        },
        {
          sortable: false,
          value: "action",
        },
      ],
    };
  },
};
</script>

