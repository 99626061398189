var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "feature-v2-wrap section-gap overlay-section  pb-0" },
    [
      _c(
        "v-container",
        { attrs: { "grid-list-xl": "" } },
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            _vm._l(_vm.data, function(feature, key) {
              return _c(
                "v-flex",
                {
                  key: key,
                  attrs: { xs12: "", sm12: "", md4: "", lg4: "", xl4: "" }
                },
                [
                  _c("div", { staticClass: "emb-card pa-4" }, [
                    _c(
                      "div",
                      { staticClass: "feature-section-v2-list" },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "primary medium mx-0",
                            attrs: { icon: "" }
                          },
                          [_c("v-icon", [_vm._v(_vm._s(feature.icon))])],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: " ml-6 feature-section-v2-content" },
                          [
                            _c("h4", { staticClass: "mb-2 font-weight-bold" }, [
                              _vm._v(_vm._s(feature.subTitle))
                            ]),
                            _c("p", { staticClass: "mb-0" }, [
                              _vm._v(_vm._s(feature.desc))
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  ])
                ]
              )
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }