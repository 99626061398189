/* eslint-disable */
import api from "@/api";

export default {
    namespaced: true,

    state: {
        moduleName: 'me',
        accounts: undefined,
        orders: undefined,
        licenses: undefined,
    }, // state

    getters: {
        getAccounts: state => state.accounts,
        getOrders: state => state.orders,
        getLicenses: state => state.licenses,
    }, // getters

    mutations: {
        SET_ACCOUNTS(state, accounts) {
            state.accounts = accounts;
        },
        SET_ORDERS(state, orders) {
            state.orders = orders;
        },
        SET_LICENSES(state, licenses) {
            state.licenses = licenses;
        },
    }, // mutations

    actions: {
        getAccounts({ state, commit }) {
            return api.get(`${state.moduleName}/accounts`)
            .then(result => commit('SET_ACCOUNTS', result.data.items));
        },
        

        requestGetOrders({ state, commit }, emailAddress) {
            return api.get(`${state.moduleName}/orders/${emailAddress}`)
            .then(result => commit('SET_ORDERS', result.data.items));
        },

        requestGetLicenses({ state, commit }, emailAddress) {
            return api.get(`${state.moduleName}/licenses/${emailAddress}`)
            .then(result => commit('SET_LICENSES', result.data.items));
        },
    }, // actions
}
