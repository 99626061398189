<template>
  <div>
    <Breadcrumbs class="mb-4" :items="breadcrumbs" />

    <div class="center-x" v-if="isLoading">
      <v-skeleton-loader type="text" width="100%" />
    </div>

    <v-card>
      <v-tabs
        :vertical="verticalTabs"
        :centered="!verticalTabs"
        :center-active="!verticalTabs"
      >
        <v-tab
          class="d-flex flex-column flex-md-row justify-md-start justify-center"
        >
          <v-icon :left="verticalTabs">info</v-icon>Overview
        </v-tab>
        <v-tab
          class="d-flex flex-column flex-md-row justify-md-start justify-center"
        >
          <v-icon :left="verticalTabs">mdi-account-cog</v-icon>Configuration
        </v-tab>
        <v-tab
          class="d-flex flex-column flex-md-row justify-md-start justify-center"
        >
          <v-icon :left="verticalTabs">mdi-cards</v-icon>Subscriptions
        </v-tab>

        <v-tab
          class="d-flex flex-column flex-md-row justify-md-start justify-center"
        >
          <v-icon :left="verticalTabs">mdi-message-settings</v-icon>Messages
        </v-tab>
        <v-tab
          class="d-flex flex-column flex-md-row justify-md-start justify-center"
        >
          <v-icon :left="verticalTabs">mdi-file-account</v-icon> Subscription
          FTA
        </v-tab>

        <v-tab-item>
          <v-card flat>
            <TradingPartnerForm />
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <X12ConfigData />
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <TradingSubscriptionsPage />
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <TradingMessage />
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <X12subscriptionFileTypeAssociation />
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </div>
</template>

<script>
import { BreadcrumbItem } from "../../../components/Breadcrumbs/BreadcrumbItem";
import { StaticRoutes } from "../../../router/Routes";
export default {
  data() {
    return {
      isLoading: false,
      verticalTabs: true,
      breadcrumbs: [
        new BreadcrumbItem({
          label: "Trading Partner",
          route: StaticRoutes.adminPanel.trading.partners,
        }),
        new BreadcrumbItem({ label: "Trading Partner Details" }),
      ],
    };
  },
};
</script>