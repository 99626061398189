//-----------------------| Search Module |-------------------//

/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import api from "@/api";
import Vue from 'vue'
import { store } from '../../store'

const state = {
    searchString: '*',
    moduleName: 'order',
    searchResults: [],
    searchResultsCount: 0,
    facets: [],
    topProducts: {
        hasNextPage: false,
        hasPreviousPage: false,
        items: [],
        totalPages: 0,
        totalCount: 0
    },
    orders: {
        hasNextPage: false,
        hasPreviousPage: false,
        items: [],
        totalPages: 0,
        totalCount: 0,
    },
    currentOrder: {
        orderId: 0,
        isOrderFinalized: false,
        orderLines: [], // Orderlines when returned to the backend for post or add add the stockitemholdingId or locationId to them
    },
    order: {},
    currentOrderId: 0,
    orderRequest: {
        pageSize: 10,
        pageNumber: 1,
        // status: 'Active',
        sortField: 'LastEditedWhen', // TODO Change to OrderDate when api client in backend is fixed (sorting by OrderDate fails for now)
        sortDirection: 'DESC',
    },

}; // state

const getters = {
    searchString: state => {
        return state.searchString;
    },
    searchResults: state => {
        return state.searchResults;
    },
    searchResultsCount: state => {
        return state.searchResultsCount;
    },
    facets: state => {
        return state.facets;
    },
    getOrders: state => {
        return state.orders;
    },
    request: state => {
        return state.orderRequest
    },
    getOrder: state => state.order,
    getNewOrder: state => state.newOrder,
    getTopProducts: state => state.topProducts,
    getCart: state => state.currentOrder,

}; // getters

const actions = {

    /**
     * DEPRECATED. Instead use addProductToCart2
     */
    addProductToCart({ state, commit }, newOrderLine) { // newOrderLine: { stockItemId: number, stockItem: any, quantity: number }
        newOrderLine.stockItemId = +newOrderLine.stockItemId;
        newOrderLine.quantity = +newOrderLine.quantity;

        let currentOrder = Object.assign({}, state.currentOrder); // Do not modify original currentOrder
        let cartHasProduct = currentOrder.orderLines.some(product => product.stockItemId == newOrderLine.stockItemId);

        // Add more quantity to product in cart
        if (cartHasProduct) {

            let updatedOrderLines = currentOrder.orderLines.map(orderLine => {
                orderLine.orderId = +currentOrder.orderId;
                orderLine.stockItemId = +orderLine.stockItemId;
                orderLine.quantity = +orderLine.quantity;

                if (orderLine.stockItemId == newOrderLine.stockItemId)
                    orderLine.quantity += newOrderLine.quantity;

                return orderLine;
            });

            currentOrder.orderLines = updatedOrderLines;
        }
        // Add new product to cart
        else {
            currentOrder.orderLines.push(newOrderLine);
        }

        currentOrder.orderId = +currentOrder.orderId;

        // Update order
        if (currentOrder.orderId && currentOrder.orderId > 0) {
            return api.put(`${state.moduleName}/${currentOrder.orderId}`, currentOrder)
            .then(result => {
                commit('SET_CURRENT_ORDER', result.data);
                return result.data;
            });
        }
        // Create new order
        else {
            return api.post(`${state.moduleName}`, currentOrder)
            .then(result => {
                commit('SET_CURRENT_ORDER', result.data);
                return result.data;
            });
        }
    }, // addProductToCart

    addProductToCart2({ state, commit }, newOrderLine) { // newOrderLine: { stockItemId: number, quantity: number }
        newOrderLine.stockItemId = Number(newOrderLine.stockItemId);
        newOrderLine.quantity = Number(newOrderLine.quantity);

        return api.put(`${state.moduleName}/addToCart`, newOrderLine)
        .then(result => {
            commit('SET_CURRENT_ORDER', result.data);
            return result.data;
        });
    }, // addProductToCart2

    removeFromCart({ state, commit }, { orderLineId, removeAll }) { // orderLineId: number
        orderLineId = Number(orderLineId);

        console.log("removeAll", removeAll);

        const url = `${state.moduleName}/removeFromCart/${orderLineId}?removeAll=${removeAll}`;

        return api.put(url)
        .then(result => {
            commit('SET_CURRENT_ORDER', result.data);
            return result.data;
        });
    }, // removeFromCart

    

    get({ state, commit }) {
        return api.get(`${state.moduleName}`, { params: state.orderRequest }).then(result => {
            commit("SET_ORDERS", result);
        });
    },

    getOnlyFinalized({ state, commit }, customerId) {
        //const request = JSON.parse(JSON.stringify(state.orderRequest));
        const request = Object.assign({}, state.orderRequest);

        request.customerId = customerId;
        request.isOrderFinalized = true;

        return api.get(`${state.moduleName}`, { params: request })
            .then(result => {
                commit("SET_ORDERS", result);
            });
    },

    getCurrentOrder({ state, commit }) {
        api.get(`${state.moduleName}/current`, { params: state.orderRequest }).then(result => {
            commit("SET_CURRENT_ORDER_FROM_REQUEST", result);
        });
    },
    getCommonItems({ state, commit }) {
        api.get(`${state.moduleName}/MostCommonItems`, { params: state.orderRequest }).then(result => commit("SET_COMMON_ITEMS", result));
    },
    getTrendingItems({ state, commit }) {
        api.get(`${state.moduleName}/GetTrendingItems`, { params: state.orderRequest }).then(result => commit("SET_COMMON_ITEMS", result));
    },
    getById({ state, commit }) {
        return api.get(`${state.moduleName}/${state.currentOrderId}`).then(result => commit("SET_ORDER_FROM_REQUEST", result));
    },
    update({ state, commit, dispatch }) {
        // Map string fields that sould be numbers to avoid errors in backend
        state.currentOrder.orderLines.map(orderLine => {
            orderLine.quantity = +orderLine.quantity;
            return orderLine;
        });

        return api.put(`${state.moduleName}/${state.currentOrderId}`, state.currentOrder).then(result => {
            let message = "The order has been updated successfully.";

            if (state.currentOrder.isOrderFinalized) {
                message = "The order was created and sent.";
                Vue.$router.push("/products");
            }

            Vue.$snotify.success(message, {
                closeOnClick: false,
                pauseOnHover: false,
                timeout: 1000,
            });

            dispatch('getCurrentOrder');
            return result;
        });
    },
    create({ state, commit, dispatch }) {
        return api.post(`${state.moduleName}`, state.currentOrder).then(result => {
            Vue.$snotify.success("The order has been updated successfully.", {
                closeOnClick: false,
                pauseOnHover: false,
                timeout: 1000,
            });
            dispatch('getCurrentOrder');
            return result;
        });
    },
    
    place({ state, commit }) {
        return api.put(`${state.moduleName}/place`, {})
        .then(result => {
            commit('SET_CURRENT_ORDER', result.data);
            return result.data;
        });
    },

    reorder({ state, commit }, order) {
        return api.post(`${state.moduleName}/reorder`, order);
    },
}; // actions

const mutations = {

    SET_RESULTS(state, data) {
        state.searchResults = data;
    },
    SET_PAGE_NUMBER(state, data) {
        state.orderRequest.pageNumber = data;
    },
    SET_PAGE_SIZE(state, data) {
        state.orderRequest.pageSize = data;
    },
    SET_ORDER_LINES(state, data) {
        data = data.map(x => {
            x.quantity = +x.quantity;
            return x;
        });
        Vue.set(state.currentOrder, 'orderLines', data);
        store.dispatch('order/update');
    },
    SET_ORDER_FROM_REQUEST(state, data) {
        state.order = data.data;
    },
    SET_CURRENT_ORDER(state, currentOrder) {
        state.currentOrder = currentOrder;
    },
    SET_CURRENT_ORDER_FROM_REQUEST(state, data) {
        state.currentOrder = data.data;
    },
    SET_ORDER(state, data) {
        state.order = data;
    },
    SET_ORDERS(state, data) {
        state.orders = data.data;
        Vue.set(state.orders, 'items', data.data.items);
    },
    SET_CURRENT_ORDER_ID(state, data) {
        state.currentOrderId = data;
    },
    SET_RESULTS_COUNT(state, count) {
        state.searchResultsCount = count;
    },
    SET_ORDER_FINALIZED(state, data) {
        state.currentOrder.isOrderFinalized = data;
        store.dispatch('order/update');
    },
    SET_REQUEST_CUSTOMER_ID(state, data) {
        state.orderRequest.customerId = data;
    },
    SET_COMMON_ITEMS(state, data) {
        state.topProducts = data.data;
    },
    ADD_TO_CART(state, data) {
        // Case 1. Adding more quantity to product in cart
        if (state.currentOrder.orderLines.some(x => x.stockItemId == data.stockItemId)) {
            var updatedOrderLines = state.currentOrder.orderLines.map(x => {
                x.orderId = +state.currentOrder.orderId;
                if (x.stockItemId == data.stockItemId)
                    x.quantity += data.quantity;
                return x;
            });
            Vue.set(state.currentOrder, 'orderLines', updatedOrderLines);
        }
        // Case 2. Adding new product to cart
        else {
            state.currentOrder.orderLines.push(data);
        }

        state.currentOrder.orderId = +state.currentOrder.orderId;

        // Update order
        if (state.currentOrder.orderId && state.currentOrder.orderId != 0) {
            store.dispatch('order/update');
        }
        else {
            // Create new order
            store.dispatch('order/create');
        }
    },
    FINALIZE_ORDER(state, data) {
        state.currentOrder.isOrderFinalized = true;
    },
}; // mutations

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true
};
