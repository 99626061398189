var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "profile-wrapper emb-card pa-4" },
    [
      _vm.isAdd
        ? _c("Breadcrumbs", {
            staticClass: "mb-4",
            attrs: { items: _vm.breadcrumbs }
          })
        : _vm._e(),
      _c(
        "v-expansion-panels",
        { attrs: { multiple: "", focusable: "", value: [0, 1] } },
        [
          _c(
            "v-expansion-panel",
            [
              _c("v-expansion-panel-header", [_vm._v("Partner information")]),
              _c(
                "v-expansion-panel-content",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: { label: "Company Id" },
                            model: {
                              value: _vm.currentObject.companyId,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.currentObject,
                                  "companyId",
                                  _vm._n($$v)
                                )
                              },
                              expression: "currentObject.companyId"
                            }
                          })
                        ],
                        1
                      ),
                      !_vm.isAdd
                        ? _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "6" } },
                            [
                              _c("v-text-field", {
                                attrs: { label: "Partner Id", disabled: "" },
                                model: {
                                  value: _vm.currentObject.x12tradingPartnerId,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.currentObject,
                                      "x12tradingPartnerId",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "currentObject.x12tradingPartnerId"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Partner description",
                              required: ""
                            },
                            model: {
                              value:
                                _vm.currentObject.x12TradingPartnerDescription,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.currentObject,
                                  "x12TradingPartnerDescription",
                                  $$v
                                )
                              },
                              expression:
                                "currentObject.x12TradingPartnerDescription"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Partner Quanlifier",
                              required: ""
                            },
                            model: {
                              value:
                                _vm.currentObject
                                  .x12interchangeTradingPartnerQualifier,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.currentObject,
                                  "x12interchangeTradingPartnerQualifier",
                                  $$v
                                )
                              },
                              expression:
                                "currentObject.x12interchangeTradingPartnerQualifier"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6" } },
                        [
                          _c("v-autocomplete", {
                            attrs: {
                              items: _vm.partnerIds.items,
                              "item-value":
                                "x12interchangeTradingPartnerIdentifier",
                              "item-text":
                                "x12interchangeTradingPartnerIdentifier",
                              label: "Partner Identifier"
                            },
                            model: {
                              value:
                                _vm.currentObject
                                  .x12interchangeTradingPartnerIdentifier,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.currentObject,
                                  "x12interchangeTradingPartnerIdentifier",
                                  $$v
                                )
                              },
                              expression:
                                "currentObject.x12interchangeTradingPartnerIdentifier"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Partner Group Identifier",
                              required: ""
                            },
                            model: {
                              value:
                                _vm.currentObject
                                  .x12interchangeTradingPartnerGroupIdentifier,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.currentObject,
                                  "x12interchangeTradingPartnerGroupIdentifier",
                                  $$v
                                )
                              },
                              expression:
                                "\n                currentObject.x12interchangeTradingPartnerGroupIdentifier\n              "
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Partner Erp Identifier",
                              required: ""
                            },
                            model: {
                              value:
                                _vm.currentObject
                                  .x12interchangeTradingPartnerErpidentifier,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.currentObject,
                                  "x12interchangeTradingPartnerErpidentifier",
                                  $$v
                                )
                              },
                              expression:
                                "\n                currentObject.x12interchangeTradingPartnerErpidentifier\n              "
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Partner Type Identifier",
                              required: ""
                            },
                            model: {
                              value:
                                _vm.currentObject
                                  .x12interchangeTradingPartnerTypeId,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.currentObject,
                                  "x12interchangeTradingPartnerTypeId",
                                  _vm._n($$v)
                                )
                              },
                              expression:
                                "currentObject.x12interchangeTradingPartnerTypeId"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Internal Partner Desc",
                              required: ""
                            },
                            model: {
                              value:
                                _vm.currentObject.x12internalTradingPartnerDesc,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.currentObject,
                                  "x12internalTradingPartnerDesc",
                                  $$v
                                )
                              },
                              expression:
                                "currentObject.x12internalTradingPartnerDesc"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Internal Trading Partner Qualifier",
                              required: ""
                            },
                            model: {
                              value:
                                _vm.currentObject
                                  .x12internalTradingPartnerQualifier,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.currentObject,
                                  "x12internalTradingPartnerQualifier",
                                  $$v
                                )
                              },
                              expression:
                                "currentObject.x12internalTradingPartnerQualifier"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "x12internal Trading Partner Identifierr",
                              required: ""
                            },
                            model: {
                              value:
                                _vm.currentObject
                                  .x12internalTradingPartnerIdentifier,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.currentObject,
                                  "x12internalTradingPartnerIdentifier",
                                  $$v
                                )
                              },
                              expression:
                                "currentObject.x12internalTradingPartnerIdentifier"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { justify: "end" } },
        [
          _c(
            "v-col",
            { attrs: { "align-self": "end", cols: "auto" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.save()
                    }
                  }
                },
                [_vm._v("Save")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }