/*eslint-disable*/

import api from '@/api';
import Vue from 'vue';

export default {
    namespaced: true,

    state: {
        moduleName: 'licenses',
        licenseTypes: {},
        currentLicenseType: {},
        searchParams: {
            pageSize: 10,
            pageNumber: 1,
            searchValue: undefined,
            //sortField: 'licenseDescription',
            //sortDirection: 'ASC',
        },
    }, // state

    getters: {
        getLicenseTypes: state => state.licenseTypes,
        getCurrentLicenseType: state => state.currentLicenseType,
        getSearchParams: state => state.searchParams,
    }, // getters

    mutations: {
        SET_LICENSE_TYPES(state, result) {
            state.licenseTypes = result.data;
            Vue.set(state.licenseTypes, 'items', result.data.items);
        },

        SET_CURRENT_LICENSE_TYPE(state, licenseType) {
            state.currentLicenseType = licenseType;
        },

        SET_SEARCH_PARAMS(state, searchParams) {
            state.searchParams = searchParams;
        },
        SET_SEARCH_PARAM_PAGE_SIZE(state, pageSize) {
            state.searchParams.pageSize = pageSize;
        },
        SET_SEARCH_PARAM_PAGE_NUMBER(state, pageNumber) {
            state.searchParams.pageNumber = pageNumber;
        },
        SET_SEARCH_PARAM_SEARCH_VALUE(state, searchValue) {
            state.searchParams.searchValue = searchValue;
        },
        SET_SEARCH_PARAM_SORT_FIELD(state, sortField) {
            state.searchParams.sortField = sortField;
        },
        SET_SEARCH_PARAM_SORT_DIRECTION(state, sortDirection) {
            state.searchParams.sortDirection = sortDirection;
        },
        RESET_SEARCH_PARAMS(state) {
            state.searchParams.pageSize = 10;
            state.searchParams.pageNumber = 1;
            state.searchParams.searchValue = undefined;
            //state.searchParams.sortField = 'fullName';
            //state.searchParams.sortDirection = 'ASC';
        },
    }, // mutations

    actions: {
        get({ state, commit }) {
            return api.get(`${state.moduleName}/types`, { params: state.searchParams })
            .then(result => commit('SET_LICENSE_TYPES', result));
        },

        getById({ state, commit }, licenseTypeId) {
            return api.get(`${state.moduleName}/types/${licenseTypeId}`)
            .then(result => commit('SET_CURRENT_LICENSE_TYPE', result.data));
        },

        update({ state, commit }, licenseType) {
            return api.put(`${state.moduleName}/types/edit/${licenseType.licenseTypeId}`, licenseType);
        },
    }, // actions
}
