var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "feature-product-v2-wrap section-gap pt-0" },
    [
      _c(
        "div",
        { staticClass: "sec-title black px-4 pb-4" },
        [
          _c("v-container", [
            _c("h2", { staticClass: "font-weight-light white--text mb-4" }, [
              _vm._v(_vm._s(_vm.secTitle))
            ]),
            _c(
              "div",
              { staticClass: "btn-wrap" },
              _vm._l(_vm.data, function(tab, key, index) {
                return _c(
                  "div",
                  { key: key, staticClass: "tab-bar d-inline-block" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "d-inline-block dar",
                        attrs: { text: index === _vm.selectedTab },
                        on: {
                          click: function($event) {
                            return _vm.onTabChange(index)
                          }
                        }
                      },
                      [_vm._v(_vm._s(key))]
                    )
                  ],
                  1
                )
              }),
              0
            )
          ])
        ],
        1
      ),
      _c("div", { staticClass: "container" }, [
        _c(
          "div",
          { staticClass: "tab-content" },
          [
            _vm._l(_vm.data, function(tab, title, index) {
              return [
                index == _vm.selectedTab
                  ? _c(
                      "slick",
                      {
                        key: index,
                        ref: "carousel",
                        refInFor: true,
                        attrs: { options: _vm.slickOptions }
                      },
                      _vm._l(_vm.data[title], function(cateogary) {
                        return _c(
                          "div",
                          { key: cateogary.name, staticClass: "tab-item" },
                          [
                            _c("div", { staticClass: "emb-card" }, [
                              _c(
                                "div",
                                { staticClass: "thumb-wrap" },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to:
                                          "/products/" +
                                          cateogary.type +
                                          "/" +
                                          cateogary.objectID
                                      }
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          alt: "feature product image",
                                          src: cateogary.image,
                                          width: "626",
                                          height: "800"
                                        }
                                      })
                                    ]
                                  ),
                                  cateogary.tags.length > 1
                                    ? _c(
                                        "div",
                                        [
                                          _c("v-chip", [
                                            _vm._v(_vm._s(cateogary.tags))
                                          ])
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to:
                                          "/products/" +
                                          cateogary.type +
                                          "/" +
                                          cateogary.objectID
                                      }
                                    },
                                    [
                                      _c(
                                        "h6",
                                        {
                                          staticClass:
                                            "black--text font-weight-medium white"
                                        },
                                        [
                                          _c("emb-currency-sign"),
                                          _vm._v(_vm._s(cateogary.price) + " ")
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "add-to-cart" },
                                    [
                                      _vm.ifItemExistInCart(cateogary, _vm.cart)
                                        ? _c(
                                            "v-btn",
                                            {
                                              staticClass: "primary",
                                              attrs: { to: "/cart", icon: "" }
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("remove_red_eye")
                                              ])
                                            ],
                                            1
                                          )
                                        : _c(
                                            "v-btn",
                                            {
                                              staticClass: "primary",
                                              attrs: { icon: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.addProductToCart(
                                                    cateogary
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("shopping_cart")
                                              ])
                                            ],
                                            1
                                          )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "emb-card-content pa-4" },
                                [
                                  _c(
                                    "h5",
                                    { staticClass: "font-weight-medium" },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to:
                                              "/products/" +
                                              cateogary.type +
                                              "/" +
                                              cateogary.objectID
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(cateogary.name) + " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c("div", { staticClass: "emb-meta-info" }, [
                                    _c(
                                      "div",
                                      { staticClass: "inline-block " },
                                      [
                                        _c("v-rating", {
                                          attrs: {
                                            readonly: "",
                                            "background-color": "grey",
                                            color: "#edb876"
                                          },
                                          model: {
                                            value: cateogary.rate,
                                            callback: function($$v) {
                                              _vm.$set(cateogary, "rate", $$v)
                                            },
                                            expression: "cateogary.rate"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ])
                                ]
                              ),
                              _c("div", { staticClass: "emb-card-footer" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "emb-meta-info layout justify-space-between align-center"
                                  },
                                  [
                                    _c(
                                      "div",
                                      [
                                        _vm.ifItemExistInWishlist(cateogary)
                                          ? _c(
                                              "v-btn",
                                              {
                                                staticClass: "px-0",
                                                attrs: { icon: "" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.addItemToWishlist(
                                                      cateogary
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass: "black--text"
                                                  },
                                                  [_vm._v("favorite")]
                                                )
                                              ],
                                              1
                                            )
                                          : _c(
                                              "v-btn",
                                              {
                                                staticClass: "px-0",
                                                attrs: { icon: "" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.addItemToWishlist(
                                                      cateogary
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  { staticClass: "grey--text" },
                                                  [_vm._v("favorite")]
                                                )
                                              ],
                                              1
                                            )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            attrs: {
                                              to:
                                                "/products/" +
                                                cateogary.type +
                                                "/" +
                                                cateogary.objectID
                                            }
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v("remove_red_eye")
                                            ])
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ])
                            ])
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e()
              ]
            })
          ],
          2
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }