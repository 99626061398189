<template>
    <div class="table-responsive">
        <v-data-table
            :headers="headers"
            :items="paginatedList.items"
            :server-items-length="paginatedList.totalCount"
            :options.sync="listViewOptions"
            :loading="isLoading"
            :footer-props="{ 'items-per-page-options': [10, 20, 30, 40, 50] }">

            <template v-slot:item.quantityOnHand="{ item }">
                {{$numformat.commaSeparated(item.quantityOnHand)}}
            </template>

            <template v-slot:item.unitPrice="{ item }">
                $ {{$numformat.currencyFormat(item.unitPrice)}}
            </template>

            <template v-slot:item.recommendedRetailPrice="{ item }">
                $ {{$numformat.currencyFormat(item.recommendedRetailPrice)}}
            </template>

            <template v-slot:item.depositPrice="{ item }">
                $ {{$numformat.currencyFormat(item.depositPrice)}}
            </template>
            
            <template v-slot:item.isPermittedOnline="{ item }">
                <v-switch disabled v-model="item.isPermittedOnline"/>
            </template>
            
            <template v-slot:item.action="{ item }">
                <v-btn small icon :to="'/admin-panel/product-edit/' + item.stockItems.stockItemId">
                    <v-icon>edit</v-icon>
                </v-btn>
                <v-btn small icon :to="'/admin-panel/product-view/' + item.stockItems.stockItemId">
                    <v-icon>visibility</v-icon>
                </v-btn>
            </template>

        </v-data-table>
    </div>
</template>

<script>
export default {
    
    props: ['paginatedList', 'isLoading', 'searchParams'],
    
    data: () => ({
        headers: [
            {
                text: 'Internal ID',
                sortable: true,
                value: 'stockItems.internalId',
            },
            {
                text: 'Quantity on Hand',
                sortable: true,
                value: 'quantityOnHand',
            },
            {
                text: 'Unit Price',
                sortable: true,
                value: 'unitPrice',
            },
            {
                text: 'Product',
                sortable: true,
                value: 'stockItems.stockItemName',
            },
            {
                text: 'Recommended Retail Price',
                sortable: true,
                value: 'recommendedRetailPrice',
            },
            {
                text: 'Deposit Price',
                sortable: true,
                value: 'depositPrice',
            },
            {
                text: 'Online Ordering',
                sortable: false,
                value: 'isPermittedOnline',
            },
            {
                sortable: false,
                value: 'action',
            },
        ],

        listViewOptions: {
            page: 1,
            itemsPerPage: 10,
        },
    }), // data

    mounted() {
        this.listViewOptions.page = this.searchParams.pageNumber;
        this.listViewOptions.itemsPerPage = this.searchParams.pageSize;
    }, // mounted

    watch: {
        listViewOptions: {
            handler() {
                this.searchParams.pageNumber = this.listViewOptions.page;
                this.searchParams.pageSize = this.listViewOptions.itemsPerPage;

                let sortField = this.listViewOptions.sortBy[0];
                let sortDesc = this.listViewOptions.sortDesc[0];

                if (sortField) {
                    this.searchParams.sortField = sortField;
                    this.searchParams.sortDirection = sortDesc? 'DESC' : 'ASC';
                }

                this.$emit('search-params-changed', this.searchParams);
            },
        },
    }, // watch
}
</script>
