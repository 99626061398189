export const tableProps = {
  data() {
    return {
      listViewOptions: {
        page: 0,
        itemsPerPage: 10,
      },
    }
  },
  props: {
    searchParams: Object,
    paginatedList: Object,
    isLoading: Boolean
  },
  watch: {
    

    listViewOptions() {

      this.searchParams.pageNumber = this.listViewOptions.page;
      this.searchParams.pageSize = this.listViewOptions.itemsPerPage;

      let sortField = this.listViewOptions.sortBy[0];
      let sortDesc = this.listViewOptions.sortDesc[0];

      if (sortField) {
        this.searchParams.sortField = sortField;
        this.searchParams.sortDirection = sortDesc ? "DESC" : "ASC";
      }
      
    },
  }, // watch
}