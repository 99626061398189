<template v-if="account && account.accountId">
  <div class="profile-wrapper emb-card pa-4">
    
    <v-expansion-panels focusable multiple class="mb-4" :value="[0, 1]">

      <!-- postal address -->
      <v-expansion-panel>
        <v-expansion-panel-header>Postal Address</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col>
              <v-text-field
                :disabled="isView"
                label="Address Line 1"
                v-model="account.postalAddressLine1"/>
            </v-col>
            <v-col>
              <v-text-field
                :disabled="isView"
                label="Address Line 2"
                v-model="account.postalAddressLine2"/>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-text-field
                  :disabled="isView"
                  label="Zip Code"
                  v-model="account.postalPostalCode"/>
            </v-col>

            <v-col>
              <v-text-field
                :disabled="isView"
                v-model="account.postalCity.cityName"
                label="City"/>
            </v-col>
          </v-row>

        </v-expansion-panel-content>
      </v-expansion-panel><!-- postal address -->

      <!-- delivery address -->
      <v-expansion-panel>
        <v-expansion-panel-header>Delivery Address</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col>
              <v-text-field
                :disabled="isView"
                label="Address Line 1"
                v-model="account.deliveryAddressLine1"/>
            </v-col>
            <v-col>
              <v-text-field
                :disabled="isView"
                label="Address Line 2"
                v-model="account.deliveryAddressLine2"/>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-text-field
                  :disabled="isView"
                  label="Zip Code"
                  v-model="account.deliveryPostalCode"/>
            </v-col>

            <v-col>
              <v-text-field
                :disabled="isView"
                v-model="account.deliveryCity.cityName"
                label="City"/>
            </v-col>
          </v-row>

        </v-expansion-panel-content>
      </v-expansion-panel><!-- delivery address -->

    </v-expansion-panels>
    
    <v-btn v-if="!isClient" color="primary" :disabled="isView">Save</v-btn>
    <emb-delete-confirmation-2
      ref="deleteConfirmationDialog"
      messageTitle="Are you sure you want to delete?"
      messageDescription="Are you sure you want to delete this user permanently?"
      @onConfirm="ondeleteItemFromCollaborationList"
      btn1="Cancel"
      btn2="Yes"
    ></emb-delete-confirmation-2>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  props: ["account", "readOnly", "isClient"],
  data() {
    return {
      isView: false,
      collaborationList: null,
      open: false,
      select: null,
      valid: true,
      newAddress: {},
      items: [{ text: "Admin" }, { text: "Write" }, { text: "Read" }],
      selectRules: [v => !!v || "Item is required"],
      loader: true,
      selectDeletedItem: null,
    };
  }, // data

  mounted() {
    this.isView = this.$route.path.includes("view") || this.readOnly;
  }, // mounted

  methods: {
    ...mapMutations({ setSelectedAddress: "accounts/SET_SELECTED_ADDRESS" }),
    addAddressToList() {
      if (this.$refs.form.validate()) {
        // this.account.addresses.push({
        //   street: this.street,
        //   apt: this.apt,
        //   city: this.city,
        //   state: this.state
        // });
        this.open = false;
        this.$refs.form.reset();
      } else {
        console.log("Invalid Inputs");
      }
    },
    goToAddress(address) {
      let that = this;
      setTimeout(() => {
        that.setSelectedAddress(address);
      }, 0);

      this.$router.push({
        path: "/admin-panel/address-edit/" + address.accountAddressId
      });
    },
    deleteItemFromCollaborationList(item) {
      this.$refs.deleteConfirmationDialog.openDialog();
      this.selectDeletedItem = item;
    },
    ondeleteItemFromCollaborationList() {
      this.$refs.deleteConfirmationDialog.close();
      let index = this.account.addresses.indexOf(this.selectDeletedItem);
      this.account.addresses.splice(index, 1);
    }
  }, // methods
};
</script>