<template>
  <v-card class="px-4 py-2 mb-4" :class="{ 'py-7': !searchBar }">
    <v-row no-gutters>
      <!-- title -->
      <v-col
        cols="12"
        sm="3"
        v-if="searchBar"
        class="d-flex align-center justify-center justify-sm-start"
      >
        <v-icon class="mr-2">{{ icon }}</v-icon>
        <h5 class="ma-0">{{ title }}</h5> </v-col
      ><!-- title -->

      <!-- search  -->

      <v-col
        v-if="searchBar"
        cols="12"
        sm="6"
        class="d-flex justify-center align-center"
      >
        <v-text-field
          append-icon="search"
          class="mr-4"
          :label="searchPlaceholder"
          v-model="searchValue"
          @change="onSearch"
          @click:append="onSearch"
        /> </v-col
      ><!-- search  -->

      <v-col>
        <v-layout justify-end align-center style="height: 100%">
          <v-btn
            v-for="(item, index) of actions"
            :key="index"
            class="ml-2"
            :color="item.color"
            :text="item.text"
            @click="onActionClicked(item)"
          >
            <v-icon v-if="item.icon" left>
              {{ item.icon }}
            </v-icon>
            {{ item.label }}
          </v-btn>
        </v-layout>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: {
    title: String,
    icon: String,
    isOnlyHeader: Boolean,
    actions: {
      type: Array,
      default: () => [],
      required: false,
    },
    searchBar: {
      type: Boolean,
      default: true,
    },
    searchPlaceholder: {
      type: String,
      default: "Search",
    },
  }, // props

  data: () => ({
    searchValue: "",
  }), // data

  methods: {
    /**
     * @param {AdminPanelHeaderAction} action
     */
    onActionClicked(action) {
      action.action();
    },

    onSearch() {
      this.$emit("search", this.searchValue);
    },
  }, // methods
};
</script>
