var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "lang-menu" },
    [
      _c(
        "v-menu",
        {
          attrs: { transition: "scale-transition", "nudge-width": 40 },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "v-toolbar-title",
                    _vm._g({}, on),
                    [
                      _c(
                        "a",
                        {
                          staticClass: "white--text font-weight-regular",
                          attrs: { href: "javascript:void(0)" }
                        },
                        [_vm._v(_vm._s(_vm.selectedLocale.name))]
                      ),
                      _c("v-icon", { attrs: { dark: "" } }, [
                        _vm._v("arrow_drop_down")
                      ])
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [
          _c(
            "v-list",
            { staticClass: "py-0" },
            _vm._l(_vm.languages, function(language, key) {
              return _c(
                "v-list-item",
                {
                  key: key,
                  on: {
                    click: function($event) {
                      return _vm.changeLanguage(language)
                    }
                  }
                },
                [_c("span", [_vm._v(_vm._s(language.name))])]
              )
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }