var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Breadcrumbs", {
        staticClass: "mb-4",
        attrs: { items: _vm.breadcrumbs }
      }),
      _vm.isLoading
        ? _c(
            "div",
            { staticClass: "center-x" },
            [
              _c("v-skeleton-loader", {
                attrs: { type: "text", width: "100%" }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v("Interchange Trading Partner Type")]),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  !_vm.isAdd
                    ? _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Interchange Trading Partner Type Id",
                              disabled: ""
                            },
                            model: {
                              value:
                                _vm.currentObject
                                  .interchangeTradingPartnerTypeId,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.currentObject,
                                  "interchangeTradingPartnerTypeId",
                                  $$v
                                )
                              },
                              expression:
                                "currentObject.interchangeTradingPartnerTypeId"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Interchange Trading Partner Type Description"
                        },
                        model: {
                          value:
                            _vm.currentObject
                              .interchangeTradingPartnerTypeDescription,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.currentObject,
                              "interchangeTradingPartnerTypeDescription",
                              $$v
                            )
                          },
                          expression:
                            "currentObject.interchangeTradingPartnerTypeDescription"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", [
            _c(
              "div",
              { staticClass: "d-flex justify-end" },
              [
                _c(
                  "v-btn",
                  {
                    attrs: { color: "error", large: "" },
                    on: {
                      click: function() {
                        return _vm.save()
                      }
                    }
                  },
                  [_vm._v("Save")]
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }