<template>
  <div class="profile-wrapper emb-card pa-4">

    <v-expansion-panels multiple focusable :value="[0, 1]">

      <!-- general inforamtion -->
      <v-expansion-panel>
        <v-expansion-panel-header>General Information</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <v-text-field
                :disabled="isView"
                label="Customer Code"
                class="name-input"
                v-model="account.customerId"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <v-text-field
                :disabled="isView"
                label="TABC id"
                class="name-input"
                v-model="account.alternateId"
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel><!-- general inforamtion -->
    </v-expansion-panels>

  </div>
</template>

<script>
export default {
  props: ["account", "readOnly", "isClient"],

  data() {
    return {
      isView: false,
    };
  }, // data

  mounted() {
    this.isView = this.$route.path.includes("view") || this.readOnly;
  }, // mounted
};
</script>

