export const yesNoDialogMixin  = {
    methods:{
        showDeleteDialog(id) {
          this.$refs.yesNoDialog
            .open({
              title: "Remove item",
              message: "Are you sure you want to remove this?",
            })
            .then(() => this.$emit("onDelete", id));
        },
    },
}