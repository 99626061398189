<template>
  <div>
    <AdminPanelHeader
      icon="mdi-shopping-outline"
      title="Market Area Qualifier"
      :actions="headerActions"
      @search="onSearch"
    />
    <X12marketAreaQualifierTable
      :paginatedList="currentEntity"
      v-on:onDelete="onDelete"
      :isLoading="isLoading"
      :searchParams="paginatedQueryParams"
    />
  </div>
</template>

<script>
import { AdminPanelHeaderAction } from "../../../../components/AdminPanelHeader/AdminPanelHeaderAction";
import { parentComponentMixin } from "../../../../mixins/parentComponentMixin";
import { showDialog } from "../../../../components/Dialogs/NotifyDialog";
import apiClient from "../../../../apiClient";
export default {
  mixins: [parentComponentMixin],
  name: "X12marketAreaQualifier",
  data() {
    return {
      paginatedQueryParams: {
        pageSize: 10,
        pageNumber: 0,
        searchValue: "",
        sortField: "marketAreaQualifierId",
        sortDirection: "DESC",
      },
      headerActions: [
        new AdminPanelHeaderAction({
          color: "accent",
          label: "Add new",
          icon: "add",
          action: () =>
            this.$router.push(
              "/admin-panel/definitions/marketAreaQualifier/add"
            ),
        }),
      ],
    };
  },
  methods: {
    async getPaginated() {
      try {
        this.isLoading = true;
        this.currentEntity = await apiClient.X12("x12marketAreaQualifiers").getPaginated(this.paginatedQueryParams);
      } catch (error) {
        showDialog("Could not get data", error);
      } finally {
        this.isLoading = false;
      }
    },
    async onDelete(id) {
      try {
        this.isLoading = true;
        await apiClient.X12("x12marketAreaQualifiers").delete(id);
        showDialog("Deleted Successfully");
        this.getPaginated();
      } catch (error) {
        showDialog("Could not delete item", error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>