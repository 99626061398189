<template>
  <div>
    <Breadcrumbs class="mb-4" :items="breadcrumbs" />
    <v-card class="mb-4">
      <v-tabs
        :vertical="verticalTabs"
        :centered="!verticalTabs"
        :center-active="!verticalTabs"
      >
        <v-tab
          class="d-flex flex-column flex-md-row justify-md-start justify-center"
        >
          <v-icon :left="verticalTabs">account_circle</v-icon>
          Overview
        </v-tab>
        <v-tab
          class="d-flex flex-column flex-md-row justify-md-start justify-center"
        >
          <v-icon :left="verticalTabs">mdi-view-headline</v-icon>
          Logs
        </v-tab>

        <v-tab-item>
          <div v-if="isLoading">
            <v-skeleton-loader class="mb-4" type="text" width="100%" />
            <v-skeleton-loader class="mb-4" type="text" width="100%" />
            <v-skeleton-loader class="mb-4" type="text" width="100%" />
            <v-skeleton-loader class="mb-4" type="text" width="100%" />
          </div>
          <TradingMessageForm :message="message"/>
        </v-tab-item>
        <v-tab-item>
          <Log :correlationId="message.x12correlationId"/>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </div>
</template>
<script>
import apiClient from '../../../apiClient';
import { BreadcrumbItem } from "../../../components/Breadcrumbs/BreadcrumbItem";
import { showDialog } from '../../../components/Dialogs/NotifyDialog';
import { StaticRoutes } from '../../../router/Routes';
export default {
  data() {
    return {
      isLoading: false,
      id:null,
      message:{},
      verticalTabs: true,
      breadcrumbs: [
        new BreadcrumbItem({
          label: "Trading Messages",
          route: StaticRoutes.adminPanel.trading.messages,
        }),
        new BreadcrumbItem({ label: "Trading Message Details" }),
      ],
    };
  },
  mounted(){
    this.id = this.$route.params.id;
    this.getById();
  },
  methods:{
    async getById() {
      try {
        this.isLoading = true;
        this.message = await apiClient.X12("X12messages").getById(this.id)
      } catch (error) {
        showDialog("Could get message data",error);
      } finally {
        this.isLoading = false;
      }
    },
  }
};
</script>