/*eslint-disable*/

export const utils = {
    methods: {
        getProductImage(orderLine) {
            let orderLineHasImage = orderLine.stockItem.stockItemImages && orderLine.stockItem.stockItemImages.length > 0;
            return orderLineHasImage ?
                orderLine.stockItem.stockItemImages[0].location :
                this.getPlaceHolderImage(orderLine.stockItem.type);
        },

        getPlaceHolderImage(type) {
            return "/static/images/placeholder/" + type?.toLowerCase() + ".svg";
        },

        /**
         * Gives "MM/DD/YYYY ormat to a utc date string"
         * @param {Date | String} date
         */
        getFormattedDateUtcShort(date) {
            const d = new Date(date);
            return d.toLocaleDateString('en-US');

        },

        getFormattedDateUtcShortForOrderDate(date) {
            return new Date(date).toLocaleDateString('en-US', { timeZone: 'UTC' });
        },
    }
};
