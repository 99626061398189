var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.product
      ? _c(
          "div",
          { staticClass: "px-sm-4 px-md-12 py-10" },
          [
            _c("v-progress-linear", { attrs: { indeterminate: "" } }),
            _c("p", { staticClass: "text-center" }, [
              _vm._v("Loading product...")
            ])
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "px-sm-4 px-md-12" },
          [
            !_vm.isAdd
              ? _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      {
                        staticClass: "layout justify-start mt-0 mx-0",
                        attrs: { cols: "12" }
                      },
                      [
                        _c(
                          "v-icon",
                          { staticClass: "mr-2", attrs: { large: "" } },
                          [_vm._v("shopping_cart")]
                        ),
                        _c("v-text-field", {
                          staticClass: "tab-input-title",
                          attrs: {
                            disabled: _vm.isView,
                            "hide-details": "",
                            required: ""
                          },
                          model: {
                            value: _vm.product.stockItemName,
                            callback: function($$v) {
                              _vm.$set(_vm.product, "stockItemName", $$v)
                            },
                            expression: "product.stockItemName"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _c("Breadcrumbs", {
              staticClass: "mb-4",
              attrs: { items: _vm.breadcrumbs }
            }),
            _c(
              "v-card",
              { staticClass: "mb-4" },
              [
                _c(
                  "v-tabs",
                  {
                    attrs: {
                      vertical: _vm.verticalTabs,
                      centered: !_vm.verticalTabs,
                      "center-active": !_vm.verticalTabs
                    }
                  },
                  [
                    _c(
                      "v-tab",
                      {
                        staticClass:
                          "d-flex flex-column flex-md-row justify-md-start justify-center"
                      },
                      [
                        _c("v-icon", { attrs: { left: _vm.verticalTabs } }, [
                          _vm._v("info")
                        ]),
                        _vm._v(" Overview ")
                      ],
                      1
                    ),
                    _c(
                      "v-tab",
                      {
                        staticClass:
                          "d-flex flex-column flex-md-row justify-md-start justify-center"
                      },
                      [
                        _c("v-icon", { attrs: { left: _vm.verticalTabs } }, [
                          _vm._v("person")
                        ]),
                        _vm._v(" Supplier ")
                      ],
                      1
                    ),
                    _c(
                      "v-tab",
                      {
                        staticClass:
                          "d-flex flex-column flex-md-row justify-md-start justify-center"
                      },
                      [
                        _c("v-icon", { attrs: { left: _vm.verticalTabs } }, [
                          _vm._v("insert_photo")
                        ]),
                        _vm._v(" Images ")
                      ],
                      1
                    ),
                    _c(
                      "v-tab-item",
                      [
                        _c("product-overview", {
                          attrs: { product: _vm.product }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-tab-item",
                      [
                        _c("product-supplier-tab", {
                          attrs: { product: _vm.product }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-tab-item",
                      [
                        _c("product-images-tab", {
                          attrs: { product: _vm.product }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            !_vm.product.supplierId
              ? _c("h6", { staticClass: "text-end mb-4" }, [
                  _vm._v("Remember to add a supplier for this product!")
                ])
              : _vm._e(),
            !_vm.isView
              ? _c("div", [
                  !_vm.product.stockItemImages ||
                  _vm.product.stockItemImages.length == 0
                    ? _c("h6", { staticClass: "text-end mb-4" }, [
                        _vm._v(
                          "Remember to add images for this product! (optional)"
                        )
                      ])
                    : _vm._e()
                ])
              : _vm._e(),
            !_vm.isView
              ? _c(
                  "div",
                  { staticClass: "d-flex justify-end" },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          loading: _vm.isPerformingOperation,
                          disabled:
                            _vm.isPerformingOperation || !_vm.isProductValid,
                          color: "error",
                          large: ""
                        },
                        on: { click: _vm.onClickSave }
                      },
                      [_vm._v(" Save ")]
                    )
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }