<template>
    <div>
        <!-- search bar -->
        <v-card class="pa-4 mb-4">
            <v-row no-gutters>
                <v-col cols="12" sm="3" class="d-flex align-center justify-center justify-sm-start">
                    <v-icon class="mr-1">label</v-icon>
                    <h5 class="ma-0">Tags</h5>
                    <v-btn class="d-sm-none" color="error" icon @click="isAddingNewTag = true">
                        <v-icon>add</v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="12" sm="6" class="d-flex justify-center align-center">
                    <v-text-field class="mr-4" label="Search Tags" v-model="searchValue" @change="executeSearch"/>
                    <v-btn color="primary" @click="executeSearch">Search</v-btn>
                </v-col>
                <v-col cols="12" sm="3" class="d-flex justify-end align-center">
                    <v-btn class="d-none d-sm-flex" color="error" @click="isAddingNewTag = true">
                        Add New
                        <v-icon>add</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-card><!-- search bar -->

        <tags-list-view
            :isLoading="isLoading"
            :paginatedList="tagsPaginatedList"
            :searchParams="searchParams"
            v-on:search-params-changed="onSearchParamsChanged">
        </tags-list-view>

        <!-- add tag dialog -->
        <v-dialog v-model="isAddingNewTag" max-width="400">
            <v-card>
                <v-card-text>
                    <v-card-title>Add a new tag</v-card-title>
                    <v-text-field class="ma-0 pa-0" label="Tag name" v-model="newTag.stockItemTagName" :rules="requiredRule"/>
                    <div class="d-flex justify-space-between align-center">
                        <p class="ma-0 pa-0">Promoted</p>
                        <v-switch v-model="newTag.isTagPromoted"/>
                    </div>
                </v-card-text>
                <v-card-actions class="d-flex justify-end">
                    <v-btn
                        :disabled="!newTag.stockItemTagName || newTag.stockItemTagName.trim().length == 0"
                        color="error"
                        @click="createTag(newTag)">
                        Add
                    </v-btn>
                    <v-btn color="primary" @click="isAddingNewTag = false">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog><!-- add tag dialog -->
    </div>
</template>

<script>
import TagsListView from './TagsListView';
import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {

    data: () => ({
        isLoading: true,
        searchValue: undefined,
        isAddingNewTag: false,
        newTag: {
            internalId: undefined,
            lastEditedBy: 1,
            stockItemTagName: undefined,
            isTagPromoted: false,
        },
        requiredRule: [
            v => !!v || 'Required',
        ],
    }), // data

    computed: {
        ...mapGetters({
            tagsPaginatedList: 'tag/getTags',
            searchParams: 'tag/getSearchParams'
        }),
    }, // computed

    mounted() {
        this.searchValue = this.searchParams.searchValue;
        this.getTags().then(() => this.isLoading = false);
    }, // mounted

    methods: {
        ...mapMutations({
            setSearchValue: 'tag/SET_SEARCH_PARAM_SEARCH_VALUE',
            setSearchParams: 'tag/SET_SEARCH_PARAMS',
            resetSearchParams: 'tag/RESET_SEARCH_PARAMS',
        }),

        ...mapActions({
            getTags: 'tag/get',
            postTag: 'tag/post',
        }),

        createTag(tag) {
            tag.internalId = tag.stockItemTagName;

            this.postTag(tag).then(() => {
                this.newTag.stockItemTagName = undefined;
                this.isAddingNewTag = false;
                
                this.isLoading = true;
                this.getTags().then(() => this.isLoading = false);
            });
        },

        executeSearch() {
            this.isLoading = true;
            this.setSearchValue(this.searchValue);

            this.getTags().then(() => this.isLoading = false);
        },

        onSearchParamsChanged(searchParams) {
            this.isLoading = true;
            this.setSearchParams(searchParams);
            this.setSearchValue(this.searchValue);

            this.getTags().then(() => this.isLoading = false);
        },
    }, // methods

    components: {
        TagsListView,
    }, // components
}
</script>
