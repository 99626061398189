/* eslint-disable */

import api from "@/api";
import Vue from 'vue';

export default {
    namespaced: true,

    state: {
        moduleName: 'supplier',
        suppliers: [],
        currentSupplier: undefined,
        searchParams: {
            pageSize: 10,
            pageNumber: 1,
            sortField: 'supplierName',
            sortDirection: 'ASC',
            searchValue: undefined, // searchValue can either be supplierName or internalId
        },
    }, // state

    getters: {
        getSuppliers: state => state.suppliers,
        getCurrentSupplier: state => state.currentSupplier,
        getSearchParams: state => state.searchParams,
    }, // getters

    mutations: {
        SET_SUPPLIERS(state, data) {
            state.suppliers = data.data;
            Vue.set(state.suppliers, 'items', data.data.items);
        },
        SET_CURRENT_SUPPLIER(state, data) {
            state.currentSupplier = data;
        },
        SET_SEARCH_PARAM_PAGE_SIZE(state, pageSize) {
            state.searchParams.pageSize = pageSize;
        },
        SET_SEARCH_PARAM_PAGE_NUMBER(state, pageNumber) {
            state.searchParams.pageNumber = pageNumber;
        },
        SET_SEARCH_PARAM_SEARCH_VALUE(state, searchValue) {
            state.searchParams.searchValue = searchValue;
        },
        SET_SEARCH_PARAM_SORT_FIELD(state, sortField) {
            state.searchParams.sortField = sortField;
        },
        SET_SEARCH_PARAM_SORT_DIRECTION(state, sortDirection) {
            state.searchParams.sortDirection = sortDirection;
        },
        SET_SEARCH_PARAMS(state, searchParams) {
            state.searchParams = searchParams;
        },
        RESET_SEARCH_PARAMS(state) {
            state.searchParams.pageSize = 10;
            state.searchParams.pageNumber = 1;
            state.searchParams.searchValue = undefined;
            state.searchParams.sortField = 'supplierName';
            state.searchParams.sortDirection = 'ASC';
        },
    }, // mutations

    actions: {
        get({ state, commit }) {
            return api.get(`${state.moduleName}`, { params: state.searchParams })
            .then(result => commit('SET_SUPPLIERS', result))
        },

        requestGetSupplierById({ state, commit }, supplierId) {
            api.get(`${state.moduleName}/${supplierId}`)
            .then(result => commit('SET_CURRENT_SUPPLIER', result.data))
            .catch(error => console.log(error));
        },

        requestUpdateSupplier({ state, commit }, { supplier, onSuccessCallback, onErrorCallback}) {
            api.put(`${state.moduleName}`, supplier)
            .then(result => onSuccessCallback(result))
            .catch(error => onErrorCallback(error));
        },
    }, // actions
}
