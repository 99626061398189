/* eslint-disable */

import { HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import environment from '../environment';

const baseHubUrl = environment.hubUrl; // TODO Uncomment
//const baseHubUrl = "https://lnf-portal-dev-scus-api.azurewebsites.net"; // TODO Remove
const indexerHubUrl = `${baseHubUrl}/indexer`;

console.log("indexerHubUrl:", indexerHubUrl);

const connection = new HubConnectionBuilder()
    .withUrl(indexerHubUrl)
    .build();

export default {
    connect() {
        return connection.start();
    },

    onConnectionClosed(listener) {
        connection.onclose(listener);
    },

    onIndexingFailed(listener) {
        connection.on("IndexingFailed", listener);
    },

    onIndexingFinished(listener) {
        connection.on("IndexingFinished", listener);
    },

    onIndexingStarted(listener) {
        connection.on("IndexingStarted", listener);
    },

    onProductsIndexed(listener) {
        connection.on("ProductsIndexed", listener);
    },
}
