<template>
  <div>
    <AdminPanelHeader
      icon="mdi-account-cog"
      title="Config Data "
      @search="onSearch"
    />
    <X12ConfigDataTable :searchParams="paginatedQueryParams" :isLoading="isLoading" :paginatedList="currentEntity"/>
  </div>
</template>

<script>
import apiClient from "../../../../../apiClient";
import { showDialog } from "../../../../../components/Dialogs/NotifyDialog";
import { parentComponentMixin } from "../../../../../mixins/parentComponentMixin";

export default {
  mixins: [parentComponentMixin],
  data() {
    return {
      paginatedQueryParams: {
        pageSize: 10,
        pageNumber: 0,
        searchValue: "",
        sortField: "InternalId",
        sortDirection: "DESC",
        filterValue:""
      },
    };
  },
  methods: {
    async onDelete(id){
      try {
        this.isLoading = true;
        await apiClient.X12("X12tradingPartnerConfigData").delete(id);
        showDialog("Deleted Syccessfully");
        this.getPaginated();
      } catch (error) {
        showDialog("Could not delete item",error)
      }finally{
        this.isLoading = false;
      }
    },
    async getPaginated() {
      try {
        this.isLoading = true;
        this.paginatedQueryParams.filterValue = this.$route.params.id;
        this.currentEntity = await apiClient
          .X12("X12tradingPartnerConfigData")
          .getFilteredPaginated(this.paginatedQueryParams);
      } catch (error) {
        showDialog("Could not get partner config data", error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

