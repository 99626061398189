var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h4", { staticClass: "mb-6" }, [_vm._v("Invoice List")]),
    _c(
      "div",
      { staticClass: "pa-4 pt-0 emb-card mb-6" },
      [
        _c(
          "v-row",
          [
            _c(
              "v-col",
              { attrs: { cols: "12", sm: "8", md: "5", lg: "5" } },
              [
                _c("v-text-field", {
                  attrs: {
                    label: "Search",
                    "single-line": "",
                    "hide-details": ""
                  },
                  model: {
                    value: _vm.search,
                    callback: function($$v) {
                      _vm.search = $$v
                    },
                    expression: "search"
                  }
                })
              ],
              1
            )
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "emb-card pa-4" },
      [_c("invoice", { attrs: { search: _vm.search } })],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }