
<template>
  <div class="emb-card pa-4">
    <template>
      <v-data-table :headers="headers" :items="account.customerLicenses">
        <template v-slot:item.expirationDate="{ item }">
          {{ getFormattedDate(item.expirationDate) }}
        </template>

        <template v-slot:item.action="{ item }">
          <v-btn small icon @click="showLicenseData(item)">
            <v-icon>visibility</v-icon>
          </v-btn>
        </template>
        <template v-slot:item.isLicenseVerified="{ item }">
          <span v-if="item.isLicenseVerified">Yes</span>
          <span v-else>No</span>
        </template>
      </v-data-table>
    </template>

    <!-- license info dialog -->
    <v-dialog v-if="selectedLicense" v-model="isViewingLicense" max-width="350">
      <v-card>
        <v-card-text class="pa-6">
          <v-card-title>
            {{ selectedLicense.licenseCode }} -
            {{ selectedLicense.licenseDescription }}
          </v-card-title>
          <!-- boolean values -->
          <div class="mb-4">
            <!-- yes/no -->
            <div class="d-flex justify-end">
              <h6 class="font-weight-regular ml-4">Yes</h6>
              <h6 class="font-weight-regular ml-4">No</h6>
            </div>
            <!-- yes/no -->

            <!-- item -->
            <div class="d-flex align-center">
              <p class="ma-0 mr-auto">Parent License</p>
              <v-icon v-if="selectedLicense.isParentLicense" class="mr-9"
                >done</v-icon
              >
              <v-icon v-else>close</v-icon>
            </div>
            <!-- item -->

            <!-- item -->
            <div class="d-flex align-center">
              <p class="ma-0 mr-auto">Pending Location Change</p>
              <v-icon
                v-if="selectedLicense.isPendingLocationChange"
                class="mr-9"
                >done</v-icon
              >
              <v-icon v-else>close</v-icon>
            </div>
            <!-- item -->

            <!-- item -->
            <div class="d-flex align-center">
              <p class="ma-0 mr-auto">Pending Renewal</p>
              <v-icon v-if="selectedLicense.isPendingRenewal" class="mr-9"
                >done</v-icon
              >
              <v-icon v-else>close</v-icon>
            </div>
            <!-- item -->

            <!-- item -->
            <div class="d-flex align-center">
              <p class="ma-0 mr-auto">Pending Trade Name Change</p>
              <v-icon
                v-if="selectedLicense.isPendingTradeNameChange"
                class="mr-9"
                >done</v-icon
              >
              <v-icon v-else>close</v-icon>
            </div>
            <!-- item -->
          </div>
          <!-- boolean values -->

          <v-text-field
            label="Expiration Date"
            v-model="selectedLicense.expirationDateFormatted"
            disabled
          />
          <v-text-field
            label="Original License Date"
            v-model="selectedLicense.originalLicenseDateFormatted"
            disabled
          />
          <v-text-field
            label="Wine Percent"
            v-model="selectedLicense.winePercent"
            disabled
          />
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn color="primary" @click="isViewingLicense = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import { currency, utils } from "../../../../mixins";

export default {
  mixins: [utils],
  props: ["account", "readOnly"],

  data() {
    return {
      accountSnapshot: undefined,
      selectedLicense: undefined,
      isViewingLicense: false,
      headers: [
        {
          text: "License Code",
          sortable: false,
          value: "licenseCode",
        },
        {
          text: "License Number",
          sortable: false,
          value: "licenseNumber",
        },
        // {
        //   text: "Description",
        //   sortable: false,
        //   value: "licenseDescription"
        // },
        {
          text: "Verified",
          sortable: false,
          value: "isLicenseVerified",
        },
        {
          text: "Status",
          sortable: false,
          value: "licenseStatusDescription",
        },
        {
          text: "Expiration Date",
          sortable: false,
          value: "expirationDate",
        },
        {
          sortable: false,
          value: "action",
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      licenses: "licenses/getLicenses",
      anyLicenseActive: "session/isAnyLicenseActive",
      isCreditOnHold: "session/isCreditOnHold",
    }),
  }, // computed

  mounted() {
    this.accountSnapshot = this.account;
    this.sortByDate();

    //this.getLicenses();
  }, // mounted

  methods: {
    ...mapActions({
      getLicenses: "licenses/get",
    }),

    getFormattedDate(date) {
      return moment.utc(date).format("MMMM Do YYYY");
      //  moment(new Date(date.toString())).format("MMMM Do YYYY");
    },
    sortByDate() {
      const currentDate = new Date();
      const index = 0;

      //FiterByStatus Date
      // const filterByStatus = Array.prototype.filter.call(
      //   this.accountSnapshot.customerLicenses,
      //   (date) => {
      //     if (
      //       date.licenseStatusDescription
      //         .toString()
      //         .toLowerCase()
      //         .includes("active") ||
      //       date.licenseStatusDescription
      //         .toString()
      //         .toLowerCase()
      //         .includes("current")
      //     ) {
      //       return true;
      //     }
      //   }
      // );
      // console.log("date from obj", filterByStatus);

      // console.log("date from obj", filterByStatus);
      const filterByDate = Array.prototype.filter.call(
        this.accountSnapshot.customerLicenses,
        (date) => {
          const time = new Date(date.expirationDate);
          return currentDate <= time;
        }
      );
      if (filterByDate.length > 0) {
        this.account.customerLicenses = filterByDate;
      }

      this.account.customerLicenses.sort((a, b) => {
        return new Date(b.expirationDate) - new Date(a.expirationDate);
      });
    },

    showLicenseData(license) {
      console.log(license);

      license.expirationDateFormatted = this.getFormattedDate(
        license.expirationDate
      );
      license.originalLicenseDateFormatted = this.getFormattedDate(
        license.originalLicenseDate
      );

      this.selectedLicense = license;
      this.isViewingLicense = true;
    },
  }, // methods
};
</script>
