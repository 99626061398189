<template>
  <div>
    <Breadcrumbs class="mb-4" :items="breadcrumbs" />
    <div class="center-x" v-if="isLoading">
      <v-skeleton-loader type="text" width="100%" />
    </div>
    <v-card>
      <v-card-title>Payment Methods </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" v-show="!isAdd">
            <v-text-field
              label="Payment Method Id"
              v-model="currentObject.paymentMethodId"
              disabled
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              label="Payment Mehod Description"
              v-model="currentObject.paymentMehodDescription"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              label="Payment Method Code"
              v-model="currentObject.paymentMethodCode"
            />
          </v-col>
          <v-col cols="12" sm="6" v-if="!isAdd">
            <v-text-field
              label="Last Edited By"
              v-model="currentObject.lastEditedBy"
              disabled
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-row>
      <v-col>
        <div class="d-flex justify-end">
          <v-btn color="error" large @click="save">Save</v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { BreadcrumbItem } from '../../../../components/Breadcrumbs/BreadcrumbItem';
import { StaticRoutes } from '../../../../router/Routes';
import {formsMixin} from '../../../../mixins/formsMixin'
import { showDialog } from '../../../../components/Dialogs/NotifyDialog';
import apiClient from '../../../../apiClient';
export default {
  name: "X12paymentMethodForm",
  mixins:[formsMixin],
  data() {
    return {
      breadcrumbs: [
        new BreadcrumbItem({
          label: "Payment Method",
          route:
            StaticRoutes.adminPanel.trading.x12Definitions.paymentMethodForm,
        }),
        new BreadcrumbItem({ label: "Payment Method Details" }),
      ],
    };
  },
  
  methods: {
    async getById() {
        try {
            this.isLoading = true;
            this.currentObject = await apiClient.X12("x12paymentMethods").getById(this.id);
        } catch (error) {
            showDialog("Could not get data",error);
        }finally{
            this.isLoading = false;
        }
    },
    async save(){
        try {
            this.isLoading = true;
            if(this.isAdd){
                await apiClient.X12("x12paymentMethods").create(this.currentObject);
                showDialog("Created Successfully");
            }
            if(this.isEdit){
                await apiClient.X12("x12paymentMethods").update(this.id,this.currentObject);
                showDialog("Updated Successfully");
            }
                this.$router.push("/admin-panel/definitions/paymentMethod");
        } catch (error) {
         showDialog("could not save item",error)   
        }finally{
            this.isLoading = false;
        }
    }
  },
};
</script>