<template>
  <v-card class="emb-FinalReceipt-wrap" v-if="order && order.orderLines">
    <v-card-text class="pa-4">
      <v-flex xs12>

        <div class="text-center bg-grey px-4 py-6">
          <h4>Purchase Order Receipt</h4>
          <h5 class="mb-6">THIS IS NOT AN INVOICE</h5>
          <h6 class="mb-6">Transaction ID:{{order.orderId}}</h6>

          <!-- order is submitted -->
          <div v-if="order.isOrderSubmitted">
            <v-icon size="64" color="green">check_circle</v-icon>
            <h5 class="text-center ma-0" style="color: green">SUBMITTED</h5>
          </div><!-- order is submitted -->
          
          <!-- order is not submitted -->
          <div v-else>
            <v-icon size="64" color="gray">report_problem</v-icon>
            <h5 class="text-center ma-0" style="color: gray">PENDING</h5>
          </div><!-- order is not submitted -->

        </div>

        <div class="mt-12 mx-4">
          <v-layout row wrap mb-10 mx-0 mt-0>

            <!-- summary -->
            <v-flex xs12 sm6 md6 lg6 xl6 text-left pa-0>
              <h6>Summary</h6>
              <p class="mb-1">Order ID: <b>{{order.internalId}}</b></p>
              <p class="mb-1">Order Date: <b>{{getOrderDate()}}</b></p>
              <p class="mb-1">Order Total: <b><emb-currency-sign></emb-currency-sign> {{getOrderTotal()}}</b></p>
            </v-flex><!-- summary -->

            <!-- deliver to -->
            <v-flex xs12 sm6 md6 lg6 xl6 text-left pa-0>
              <h6>Deliver To</h6>
              <p class="mb-1">{{order.customer.customerName}}</p>
              <p class="mb-1">{{order.customer.deliveryAddressLine1}}, {{order.customer.deliveryCity.cityName}}</p>
              <p class="mb-1">{{order.customer.deliveryAddressLine2}}</p>
              <!-- <p class="mb-1">{{order.country}} - {{order.zipCode}}</p> -->
              <p class="mb-1">Contact No. {{order.customer.phoneNumber}}</p>
            </v-flex><!-- deliver to -->

          </v-layout>
          <div class="py-6 text-center bg-grey">
            <h4>Expected Date of Delivery</h4>
            <h3>{{getOrderExpectedDeliveryDate()}}</h3>
          </div>
          <h4 class="pt-12 mb-7 text-sm-left text-center">Your Ordered Details</h4>

          <!-- order lines -->
          <v-card v-for="(orderLine, index) in order.orderLines" :key="index" class="ma-0 mb-4 pa-0 px-6">
            <OrderLineItem :item="orderLine" :canEditQuantity="false" :canRemove="false"/>
          </v-card><!-- order lines -->

          <v-divider class="mb-4"></v-divider>

          <!-- total calculations -->
          <div>
            <OrderTotals :order="order"/>
          </div><!-- total calculations -->

        </div>
      </v-flex>
    </v-card-text>
  </v-card>
</template>

<script>
import { currency, orders, utils } from "../../../../mixins";
import OrderLineItem from '@/components/OrderLineItem';
import OrderTotals from '@/components/OrderTotals';

export default {
  
  mixins: [currency, orders, utils],

  components: {
    OrderLineItem,
    OrderTotals,
  },

  props: ["order"],

  data() {
    return {

    };
  }, // data

  mounted() {

  }, // mounted

  methods: {

    getOrderTotal() {
      let total = orders.methods.calculateTotal(this.order);
      return currency.methods.formatNumber(total);
    },

    getOrderSubTotal() {
      let subTotal = orders.methods.calculateSubTotal(this.order);
      return currency.methods.formatNumber(subTotal);
    },

    getOrderDiscounts() {
      let discounts = orders.methods.calculateDiscounts(this.order);
      return currency.methods.formatNumber(discounts);
    },

    getOrderDeposits() {
      let deposits = orders.methods.calculateDeposits(this.order);
      return currency.methods.formatNumber(deposits);
    },

    getOrderDate() {
      if (!this.order || !this.order.orderDate) {
        return '';
      }

      let dateUtc = this.order.orderDate.toString();
      let formattedDate = utils.methods.getFormattedDateUtcShort(dateUtc);

      return formattedDate;
    },

    getOrderExpectedDeliveryDate() {
      if (!this.order || !this.order.expectedDeliveryDate) {
        return '';
      }
    
      let dateUtc = this.order.expectedDeliveryDate.toString();
      let formattedDate = utils.methods.getFormattedDateUtcShort(dateUtc);

      return formattedDate;
    },
  },
};
</script>
