/*eslint-disable*/

import api from '@/api';

const CompanyCodes = {
    FB: 'FB',
    LNF: 'LNF',
};

const StorageItems = {
    session: {
        person: 'fb.person',
        account: 'fb.account',
        location: 'fb.location',
        stockGroupEligibilities: 'fb.stockGroupEligibilities',
    },
};

export default {
    namespaced: true,

    getters: {
        getPerson: state => {
            if (!sessionStorage.getItem(StorageItems.session.person))
                return undefined;

            return JSON.parse(sessionStorage.getItem(StorageItems.session.person));
        },

        getAccount: state => {
            if (!sessionStorage.getItem(StorageItems.session.account))
                return undefined;

            return JSON.parse(sessionStorage.getItem(StorageItems.session.account));
        },

        getLocation: state => {
            if (!sessionStorage.getItem(StorageItems.session.location))
                return undefined;

            return JSON.parse(sessionStorage.getItem(StorageItems.session.location));
        },

        getStockGroupEligibilities: state => {
            if (!sessionStorage.getItem(StorageItems.session.stockGroupEligibilities))
                return undefined;

            return JSON.parse(sessionStorage.getItem(StorageItems.session.stockGroupEligibilities));
        },

        isAccountSelected: state => {
            if (!sessionStorage.getItem('fb.account')) {
                return false;
            }

            return true;
        },
        isCreditOnHold: state => {
            let accountStr = sessionStorage.getItem('fb.account');

            if (!accountStr)
                return true;

            let account = JSON.parse(accountStr);

            if (account.isOnCreditHold == true)
                return true;

            return false;

        },

        isAnyLicenseExpired: state => {

            let accountStr = sessionStorage.getItem('fb.account');

            if (!accountStr)
                return false;

            let account = JSON.parse(accountStr);

            if (account.isOnCreditHold == true)
                return true;

            if (!account.customerLicenses || account.customerLicenses.length == 0)
                return true;


            let expirationDates = account.customerLicenses.map(license => {
                let dateStr = license.expirationDate;
                return new Date(dateStr);
            });

            return expirationDates.some(date => {
                let now = new Date();
                return now > date;
            });
        },
        //This is for checking the licenses filtered by date 
        isAnyLicenseActive: state => {
            const currentDate = new Date();

            let accountStr = sessionStorage.getItem('fb.account');

            if (!accountStr)
                return false;

            let account = JSON.parse(accountStr);

            let expirationDates = account.customerLicenses.map(license => {
                let dateStr = license.expirationDate;
                return new Date(dateStr);
            });

            expirationDates = Array.prototype.filter.call(expirationDates, (date) => {
                return currentDate < date;
            });

            if (expirationDates.length === 0 )
                return false;

            let anyLicenseActive = expirationDates.every(date => {
                return currentDate < date;
            });
            
            return anyLicenseActive;
        }
    }, // getters

    mutations: {
        SET_PERSON(state, person) {
            if (!person) {
                sessionStorage.removeItem(StorageItems.session.person);
                return;
            }

            sessionStorage.setItem(StorageItems.session.person, JSON.stringify(person));
        },

        SET_ACCOUNT(state, account) {
            if (!account) {
                sessionStorage.removeItem(StorageItems.session.account);
                return;
            }

            sessionStorage.setItem(StorageItems.session.account, JSON.stringify(account));
        },

        SET_LOCATION(state, location) {
            if (!location) {
                sessionStorage.removeItem(StorageItems.session.location);
                return;
            }

            sessionStorage.setItem(StorageItems.session.location, JSON.stringify(location));
        },

        SET_STOCK_GROUP_ELIGIBILITIES(state, eligibilities) {
            if (!eligibilities) {
                sessionStorage.removeItem(StorageItems.session.stockGroupEligibilities);
                return;
            }

            sessionStorage.setItem(StorageItems.session.stockGroupEligibilities, JSON.stringify(eligibilities));
        },
    }, // mutations

    actions: {
        getCurrentEnvironmentInfo({ state, commit }) {
            return api.get('me/currentEnvironment');
        },

        getUserProfile({ state, commit }, email) {
            return api.get(`me/${email}`)
                .then(result => {
                    commit('SET_PERSON', result.data.person);
                    commit('SET_ACCOUNT', result.data.account);
                    commit('SET_LOCATION', result.data.location);
                    commit('SET_STOCK_GROUP_ELIGIBILITIES', result.data.stockGroupEligibilities);
                });
        },

        switchAccount({ state, commit }, accountInternalId) {
            return api.put(`me/switch/account/${accountInternalId}`)
                .then(result => {
                    commit('SET_PERSON', result.data.person);
                    commit('SET_ACCOUNT', result.data.account);
                    commit('SET_STOCK_GROUP_ELIGIBILITIES', result.data.stockGroupEligibilities);
                });
        },

        switchLocation({ state, commit }, locationId) {
            return api.put(`me/switch/location/${locationId}`)
                .then(result => {
                    commit('SET_PERSON', result.data.person);
                    commit('SET_LOCATION', result.data.location);
                    commit('SET_ACCOUNT', undefined);
                    commit('SET_STOCK_GROUP_ELIGIBILITIES', undefined);
                });
        },
    }, // actions
}
