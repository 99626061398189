var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.selectedBlog != null
    ? _c("div", { staticClass: "emb-blogDetail-wrap" }, [
        _c(
          "div",
          {
            staticClass: "blog-img-wrapper relative",
            style: {
              "background-image": " url(" + _vm.selectedBlog.origanalThumb + ")"
            }
          },
          [
            _c("div", { staticClass: "center-holder" }, [
              _c(
                "div",
                { staticClass: "container" },
                [
                  _c(
                    "v-layout",
                    {
                      attrs: {
                        row: "",
                        wrap: "",
                        "align-center": "",
                        "justify-center": "",
                        "ma-0": ""
                      }
                    },
                    [
                      _c(
                        "v-flex",
                        {
                          attrs: {
                            sm12: "",
                            xs12: "",
                            md8: "",
                            lg7: "",
                            xl7: "",
                            "pa-0": ""
                          }
                        },
                        [
                          _c("h5", { staticClass: "mb-6" }, [
                            _c("i", { staticClass: "material-icons" }, [
                              _vm._v("trending_up")
                            ]),
                            _vm._v(" Featured post. ")
                          ]),
                          _c("h2", { staticClass: "mb-6" }, [
                            _vm._v(_vm._s(_vm.selectedBlog.subtitle))
                          ]),
                          _c("div", { staticClass: "blog-meta mb-4" }, [
                            _c(
                              "a",
                              {
                                staticClass: "mr-4",
                                attrs: { href: "javascript:void(0);" }
                              },
                              [
                                _c("i", { staticClass: "material-icons" }, [
                                  _vm._v("account_circle")
                                ]),
                                _c(
                                  "span",
                                  { staticClass: "font-weight-regular" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.selectedBlog.authorName) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            ),
                            _c(
                              "a",
                              { attrs: { href: "javascript:void(0);" } },
                              [
                                _c("i", { staticClass: "material-icons" }, [
                                  _vm._v("date_range")
                                ]),
                                _c(
                                  "span",
                                  { staticClass: "font-weight-regular" },
                                  [_vm._v(_vm._s(_vm.selectedBlog.date))]
                                )
                              ]
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ]
        ),
        _c(
          "div",
          { staticClass: "inner-container bg-white" },
          [
            _c("v-container", { attrs: { "grid-list-xl": "", "pb-0": "" } }, [
              _c(
                "div",
                { staticClass: "blog-detail section-gap" },
                [
                  _c(
                    "v-layout",
                    {
                      attrs: {
                        row: "",
                        wrap: "",
                        "align-center": "",
                        "justify-center": ""
                      }
                    },
                    [
                      _c(
                        "v-flex",
                        {
                          attrs: {
                            xs12: "",
                            sm12: "",
                            md10: "",
                            lg7: "",
                            xl7: ""
                          }
                        },
                        [
                          _c("div", {
                            staticClass: "blog-content mb-12",
                            domProps: {
                              innerHTML: _vm._s(_vm.selectedBlog.blogDesc)
                            }
                          }),
                          _c("div", { staticClass: "tags-social-block mb-6" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-sm-flex align-center justify-space-between"
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "mb-sm-0 mb-6" },
                                  [
                                    _c(
                                      "v-chip",
                                      {
                                        attrs: {
                                          color: "accent",
                                          "text-color": "white"
                                        }
                                      },
                                      [_vm._v(" Shopping ")]
                                    ),
                                    _c(
                                      "v-chip",
                                      {
                                        attrs: {
                                          color: "accent",
                                          "text-color": "white"
                                        }
                                      },
                                      [_vm._v(" Online ")]
                                    )
                                  ],
                                  1
                                ),
                                _c("div", [_c("emb-social-share")], 1)
                              ]
                            )
                          ]),
                          _c(
                            "div",
                            { staticClass: "blog-links  grey lighten-3 pa-6" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex align-center justify-space-between "
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "previous-blog" },
                                    [
                                      _c("v-btn", { staticClass: "accent" }, [
                                        _vm._v("Prev")
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "next-blog " },
                                    [
                                      _c("v-btn", { staticClass: "accent" }, [
                                        _vm._v("Next")
                                      ])
                                    ],
                                    1
                                  )
                                ]
                              )
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "author-section pt-6 pb-2" },
                            [
                              _c("div", { staticClass: "sec-title" }, [
                                _c("h3", { staticClass: "mb-0" }, [
                                  _vm._v("About the Author")
                                ])
                              ]),
                              _vm.selectedBlog != null
                                ? _c(
                                    "v-layout",
                                    {
                                      attrs: {
                                        row: "",
                                        wrap: "",
                                        "align-center": ""
                                      }
                                    },
                                    [
                                      _c(
                                        "v-flex",
                                        {
                                          attrs: {
                                            sm3: "",
                                            md2: "",
                                            lg2: "",
                                            xl2: "",
                                            "mb-3": ""
                                          }
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "br-100",
                                            attrs: {
                                              alt: "author detail",
                                              src: _vm.selectedBlog.authorImg,
                                              width: "200",
                                              height: "200"
                                            }
                                          })
                                        ]
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          attrs: {
                                            sm9: "",
                                            md8: "",
                                            lg10: "",
                                            xl10: "",
                                            "mb-3": ""
                                          }
                                        },
                                        [
                                          _c("h4", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.selectedBlog.authorName
                                              )
                                            )
                                          ]),
                                          _c("p", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.selectedBlog.authorDesc
                                              )
                                            )
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c("v-divider", { staticClass: "my-6" })
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "blog-comment-section mb-2" },
                            [
                              _c("div", { staticClass: "sec-title" }, [
                                _c("h3", { staticClass: "mb-0" }, [
                                  _vm._v("Comments(3)")
                                ])
                              ]),
                              _c(
                                "ul",
                                { staticClass: "comment-list" },
                                [
                                  _vm._l(_vm.CommentsList, function(
                                    comments,
                                    key
                                  ) {
                                    return [
                                      comments.subComment
                                        ? _c(
                                            "li",
                                            {
                                              key: key,
                                              staticClass:
                                                "layout comment-item row wrap  align-center justify-between"
                                            },
                                            [
                                              _c(
                                                "v-flex",
                                                {
                                                  attrs: {
                                                    sm2: "",
                                                    md2: "",
                                                    lg2: "",
                                                    xl2: ""
                                                  }
                                                },
                                                [
                                                  _c("img", {
                                                    staticClass: "br-50",
                                                    attrs: {
                                                      src: comments.img,
                                                      alt: "user",
                                                      width: "100",
                                                      height: "100"
                                                    }
                                                  })
                                                ]
                                              ),
                                              _c(
                                                "v-flex",
                                                {
                                                  attrs: {
                                                    sm10: "",
                                                    md10: "",
                                                    lg10: "",
                                                    xl10: ""
                                                  }
                                                },
                                                [
                                                  _c("h5", [
                                                    _vm._v(
                                                      _vm._s(comments.username)
                                                    )
                                                  ]),
                                                  _c("p", [
                                                    _vm._v(
                                                      _vm._s(comments.comment)
                                                    )
                                                  ]),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex justify-space-between"
                                                    },
                                                    [
                                                      _c("div", [
                                                        _c(
                                                          "a",
                                                          {
                                                            staticClass:
                                                              "primary--text font-weight-bold",
                                                            attrs: {
                                                              href:
                                                                "javascript:void(0)"
                                                            }
                                                          },
                                                          [_vm._v("Reply")]
                                                        )
                                                      ]),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "primary--text font-weight-bold comment-date"
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                comments.date
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "ul",
                                                {
                                                  staticClass:
                                                    "sub-comment comment-list"
                                                },
                                                _vm._l(
                                                  comments.subComment,
                                                  function(comments, i) {
                                                    return _c(
                                                      "li",
                                                      {
                                                        key: i,
                                                        staticClass:
                                                          "layout comment-item row wrap  align-center justify-between"
                                                      },
                                                      [
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            attrs: {
                                                              sm2: "",
                                                              md2: "",
                                                              lg2: "",
                                                              xl2: ""
                                                            }
                                                          },
                                                          [
                                                            _c("img", {
                                                              staticClass:
                                                                "br-50",
                                                              attrs: {
                                                                src:
                                                                  comments.img,
                                                                alt: "user",
                                                                width: "100",
                                                                height: "100"
                                                              }
                                                            })
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            attrs: {
                                                              sm10: "",
                                                              md10: "",
                                                              lg10: "",
                                                              xl10: ""
                                                            }
                                                          },
                                                          [
                                                            _c("h5", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  comments.username
                                                                )
                                                              )
                                                            ]),
                                                            _c("p", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  comments.comment
                                                                )
                                                              )
                                                            ]),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex justify-space-between"
                                                              },
                                                              [
                                                                _c("div", [
                                                                  _c(
                                                                    "a",
                                                                    {
                                                                      staticClass:
                                                                        "primary--text font-weight-bold",
                                                                      attrs: {
                                                                        href:
                                                                          "javascript:void(0)"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Reply"
                                                                      )
                                                                    ]
                                                                  )
                                                                ]),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "primary--text font-weight-bold comment-date"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          comments.date
                                                                        ) +
                                                                        " "
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            ],
                                            1
                                          )
                                        : _c(
                                            "li",
                                            {
                                              key: key,
                                              staticClass:
                                                "layout comment-item row wrap  align-center justify-between"
                                            },
                                            [
                                              _c(
                                                "v-flex",
                                                {
                                                  attrs: {
                                                    sm2: "",
                                                    md2: "",
                                                    lg2: "",
                                                    xl2: ""
                                                  }
                                                },
                                                [
                                                  _c("img", {
                                                    staticClass: "br-50",
                                                    attrs: {
                                                      src: comments.img,
                                                      alt: "user",
                                                      width: "100",
                                                      height: "100"
                                                    }
                                                  })
                                                ]
                                              ),
                                              _c(
                                                "v-flex",
                                                {
                                                  attrs: {
                                                    sm10: "",
                                                    md10: "",
                                                    lg10: "",
                                                    xl10: ""
                                                  }
                                                },
                                                [
                                                  _c("h5", [
                                                    _vm._v(
                                                      _vm._s(comments.username)
                                                    )
                                                  ]),
                                                  _c("p", [
                                                    _vm._v(
                                                      _vm._s(comments.comment)
                                                    )
                                                  ]),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex justify-space-between"
                                                    },
                                                    [
                                                      _c("div", [
                                                        _c(
                                                          "a",
                                                          {
                                                            staticClass:
                                                              "primary--text font-weight-bold",
                                                            attrs: {
                                                              href:
                                                                "javascript:void(0)"
                                                            }
                                                          },
                                                          [_vm._v("Reply")]
                                                        )
                                                      ]),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "primary--text font-weight-bold comment-date"
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                comments.date
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                    ]
                                  })
                                ],
                                2
                              )
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "leave-commet pt-6" },
                            [
                              _c("div", { staticClass: "sec-title mb-6" }, [
                                _c("h3", { staticClass: "mb-0" }, [
                                  _vm._v("Leave Comments")
                                ])
                              ]),
                              _c(
                                "v-layout",
                                { attrs: { row: "", wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: {
                                        sm12: "",
                                        md12: "",
                                        lg12: "",
                                        xl12: "",
                                        "py-0": ""
                                      }
                                    },
                                    [
                                      _c("v-text-field", {
                                        staticClass: "mt-0",
                                        attrs: {
                                          type: "text",
                                          placeholder: "First Name"
                                        }
                                      }),
                                      _c("v-text-field", {
                                        attrs: {
                                          type: "text",
                                          placeholder: "Last Name"
                                        }
                                      }),
                                      _c("v-text-field", {
                                        attrs: {
                                          type: "email",
                                          placeholder: "Email"
                                        }
                                      }),
                                      _c("v-textarea", {
                                        attrs: {
                                          placeholder: "Leave a Message",
                                          rows: "2"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "accent mt-6 mx-0",
                                  attrs: { large: "" }
                                },
                                [_vm._v("Send Message")]
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }