<template>
    <div class="d-flex justify-center align-center">
        
        <!-- price sorting -->
        <v-select
            hide-details
            :disabled="disabled"
            label="Price Sorting"
            :items="priceSortingOptions"
            item-text="value"
            item-value="id"
            v-model="priceSorting"
            @change="onPriceSoringChanged"/>

        <div class="mx-3">
        </div>

        <!-- results per page -->
        <v-select
            hide-details
            :disabled="disabled"
            label="Results per page"
            item-text="value"
            item-value="id"
            :items="pageSizeOptions"
            v-model="pageSize"
            @change="onPageSizeChanged"/>

    </div>
</template>

<script>
export default {

    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
    },

    data: () => ({
        pageSizeOptions: [
            { id: 8, value: "8 hits per page" },
            { id: 12, value: "12 hits per page" },
            { id: 16, value: "16 hits per page" },
        ],
        priceSortingOptions: [
            { id: null, value: "Default" },
            { id: "asc", value: "Lowest Price" },
            { id: "desc", value: "Highest Price" },
        ],
        pageSize: 8,
        priceSorting: null,
    }), // data

    methods: {
        onPageSizeChanged() {
            this.$emit("page-size-changed", this.pageSize);
        },

        onPriceSoringChanged() {
            this.$emit("price-sorting-changed", this.priceSorting);
        },
    }, // methods
}
</script>
