var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("admin-panel-header", {
        attrs: { title: "Stock Groups", icon: "group_work", addButton: false },
        on: { search: _vm.onSearch }
      }),
      _c(
        "div",
        { staticClass: "d-flex justify-end" },
        [
          _c(
            "v-btn",
            {
              attrs: { small: "", text: "" },
              on: { click: _vm.onRefreshClicked }
            },
            [
              _vm._v(" Refresh "),
              _c("v-icon", { attrs: { right: "" } }, [_vm._v("refresh")])
            ],
            1
          )
        ],
        1
      ),
      _c("stock-groups-list-view", {
        attrs: {
          paginatedList: _vm.stockGroupsPaginatedList,
          isLoading: _vm.isLoading,
          searchParams: _vm.searchParams
        },
        on: { "search-params-changed": _vm.onSearchParamsChanged }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }