<template>
    <div class="px-sm-4 px-md-12">

        <!-- title -->
        <section class="d-flex mb-4">
            <v-icon class="mr-2" large>label</v-icon>
            <v-text-field
                disabled
                class="tab-input-title"
                v-model="tag.stockItemTagName"/>
        </section><!-- title -->

        <!-- back button -->
        <div class="d-flex mb-6">
            <router-link class="font-weight-medium" to="/admin-panel/tags">Back</router-link>
        </div>

        <!-- tabs -->
        <v-card class="mb-3">
            <v-tabs :vertical="verticalTabs" :centered="!verticalTabs" :center-active="!verticalTabs">
                
                <v-tab class="d-flex flex-column flex-md-row justify-md-start justify-center">
                    <v-icon :left="verticalTabs">info</v-icon>
                    Overview
                </v-tab>
                
                <v-tab class="d-flex flex-column flex-md-row justify-md-start justify-center">
                    <v-icon :left="verticalTabs">list</v-icon>
                    Tagged Products
                </v-tab>
                
                <!-- overview -->
                <v-tab-item>
                    <v-card>
                        <v-card-text>
                            <v-text-field
                                disabled
                                label="Tag name *"
                                v-model="tag.stockItemTagName"
                                :rules="requiredRule"/>

                            <div class="d-flex justify-space-between align-center">
                                <p class="ma-0 pa-0">Promoted</p>
                                <v-switch disabled v-model="tag.isTagPromoted"/>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-tab-item><!-- overview -->
                
                <!-- tagged products -->
                <v-tab-item>
                    <v-card>
                        <v-card-text>
                            <div class="d-flex justify-end">
                                <v-btn
                                    color="primary"
                                    @click="isShowingBulkRemoveDialog = true">
                                    Remove tag from all products
                                </v-btn>
                            </div>
                        </v-card-text>
                        <stock-items-list-view
                            :searchParams="searchParams"
                            :paginatedList="taggedStockItems"
                            :isLoading="isLoading"
                            v-on:search-params-changed="onSearchParamsChanged">
                        </stock-items-list-view>
                    </v-card>
                </v-tab-item><!-- tagged products -->

            </v-tabs>
        </v-card><!-- tabs -->

        <!-- bulk remove dialog -->
        <v-dialog v-model="isShowingBulkRemoveDialog" max-width="400">
            <v-card>
                <v-card-text>
                    <v-card-title>Remove Tag</v-card-title>
                    <p class="ma-0 pa-0">Are you sure you want to remove the tag "{{tag.stockItemTagName}}" from all the listed products?</p>
                </v-card-text>
                <v-card-actions class="d-flex justify-end">
                    <v-btn color="error" @click="bulkRemoveTag(tag.stockItemTagId)">Yes</v-btn>
                    <v-btn color="primary" @click="isShowingBulkRemoveDialog = false">No</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import StockItemsListView from './StockItemsListView';
import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {
    data: () => ({
        isEdit: false,
        isView: false,
        isShowingBulkRemoveDialog: false,
        isLoading: true,
        stockItemTagId: undefined,
        verticalTabs: true,
        isPerformingOperation: false,
        requiredRule: [
            v => !!v || 'Required',
        ],
    }), // data

    computed: {
        ...mapGetters({
            tag: 'tag/getCurrentTag',
            taggedStockItems: 'taggedStockItems/getStockItems',
            searchParams: 'taggedStockItems/getSearchParams',
        }),
    }, // computed

    beforeMount() {
        this.isEdit = this.$route.path.includes('edit');
        this.isView = this.$route.path.includes('view');
        this.stockItemTagId = this.$route.params.stockItemTagId;

        this.getTag(this.stockItemTagId);
    }, // beforeMount

    mounted() {
        // watch for window dimensions (for tabs responsiveness)
        this.$nextTick(() => window.addEventListener('resize', () => this.verticalTabs = window.innerWidth >= 960));
        this.verticalTabs = window.innerWidth >= 960;
    }, // mounted

    methods: {
        ...mapMutations({
            setSearchParams: 'taggedStockItems/SET_SEARCH_PARAMS',
        }),
        ...mapActions({
            getTag: 'tag/getById',
            getStockItemsByTag: 'taggedStockItems/getByTag'
        }),

        bulkRemoveTag(stockItemTagId) {
            console.log('bulkRemoveTag', stockItemTagId);
        },

        onClickSave() {
            console.log('onClickSave()');
            //this.isPerformingOperation = true;
        },

        onSearchParamsChanged(searchParams) {
            this.isLoading = true;
            this.setSearchParams(searchParams);

            this.getStockItemsByTag(this.stockItemTagId).then(() => this.isLoading = false);
        },
    }, // methods

    components: {
        StockItemsListView,
    }, // components
}
</script>
