<template>
    <v-card>
        <v-card-text>
            <v-row>

                <!-- add image template -->
                <v-col v-if="!isView" cols="12" sm="6" md="4" lg="3">
                    <v-card>
                        <v-card-text>
                            <!-- image container -->
                            <div class="d-flex justify-center">
                                <img src="https://via.placeholder.com/250x250" alt="product image" width="250" height="250">
                            </div><!-- image container -->

                            <!-- image type -->
                            <v-select
                                label="Image Type"
                                :items="imageTypesArray"
                                item-text="stockItemImageTypeName"
                                item-value="stockItemImageTypeID"
                                v-model="imageToAdd.stockItemImageTypeID"/>

                            <!-- image file -->
                            <v-file-input
                                :disabled="imageToAdd.stockItemImageTypeID == undefined"
                                label="Choose an image"
                                v-model="chosenImage"
                                @change="onImageChosen"/>
                        </v-card-text>
                    </v-card>
                </v-col><!-- add image template -->

                <!-- product images -->
                <v-col cols="12" sm="6" md="4" lg="3" v-for="(image, index) of product.stockItemImages" :key="index">
                    <v-card>
                        <!-- remove button -->
                        <div v-if="!isView" class="d-flex justify-end pt-1 pr-1">
                            <v-btn icon small @click="onImageRemove(image, index)">
                                <v-icon small>close</v-icon>
                            </v-btn>
                        </div><!-- remove button -->

                        <v-card-text>
                            <!-- image container -->
                            <div class="d-flex justify-center">
                                <img :src="image.location" alt="product image" width="250" height="250">
                            </div><!-- image container -->

                            <!-- image type -->
                            <v-select
                                label="Image Type"
                                :items="imageTypesArray"
                                item-text="stockItemImageTypeName"
                                item-value="stockItemImageTypeID"
                                v-model="image.stockItemImageTypeID"
                                @change="onChangingImageType(image.stockItemImageTypeID)"
                                />

                        </v-card-text>
                    </v-card>
                </v-col><!-- product images -->

            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
    props: ['product'],

    data: () => ({
        imageTypesArray:[],
        errorMessage:null,

        isAdd: false,
        isEdit: false,
        isView: false,

        imageToAdd: {
            source: undefined, // base64
            stockItemImageTypeID: undefined,
            lastEditedBy: 1,
        },
        chosenImage: undefined,
    }), // data

    computed: {
        ...mapGetters({
            stockItemImageTypes: 'catalog/getStockItemImageTypes',
        }),
    }, // computed

    beforeMount() {
        this.isAdd = this.$route.path.includes('add');
        this.isEdit = this.$route.path.includes('edit');
        this.isView = this.$route.path.includes('view');
        console.log('this are the images ' ,this.product);
        if (!this.product.stockItemImages) {
            this.product.stockItemImages = [];
        }
    }, // beforeMount

    mounted() {
        //this.requestGetStockItemImageTypes();
        this.onLoadingImageTypes();
        

        console.log('Images of this product are:', this.product.stockItemImages);
        
    }, // mounted

    methods: {
        ...mapActions({
            requestGetStockItemImageTypes: 'catalog/requestGetStockItemImageTypes',
            requestCreateStockItemImage: 'productImages/createStockItemImage',
            getProductImageTypes: 'imageType/getTypes',
        }),

        onImageChosen(imageFile) {
            if (!imageFile) {
                return;
            }
            
            this.imageToBase64(imageFile)
            .then(base64 => {
                this.imageToAdd.source = base64;
                let imageToAddCopy = JSON.parse(JSON.stringify(this.imageToAdd));
                this.product.stockItemImages.push(imageToAddCopy);

                // If it's edit, upload to database before removing image object data
                if (this.isEdit) {
                    imageToAddCopy.stockItemId = this.product.stockItemId;
                    this.requestCreateStockItemImage(imageToAddCopy);
                }

                this.imageToAdd.source = undefined;
                this.imageToAdd.stockItemImageTypeID = undefined;
                this.imageToAdd.lastEditedBy = 1;
                this.chosenImage = undefined;
            })
            .catch(error => console.error(error));
        },
        onChangingImageType(newType){
            this.product.stockItemImages.stockItemImageTypeID = newType;
        },
        

        onImageRemove(image, index) {
            //this.product.stockItemImages.splice(index, 1);
            index = 1
            this.product.stockItemImages.splice(this.product.stockItemImages.indexOf(image), index)
            this.product.stockItemImages = [...this.product.stockItemImages]

            //this.productTags.splice(this.productTags.indexOf(item), 1)
            //this.productTags = [...this.productTags]
        },

        imageToBase64(imageFile) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(imageFile);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            });
        },
        
        onLoadingImageTypes(){
            this.getProductImageTypes()
            .then(response =>{
                
                this.imageTypesArray = response.data.items;
                errorMessage = null;
            })
            .catch(()=> this.errorMessage ="An error occurred");
        }
    }, // methods
}
</script>
