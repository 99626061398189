var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Breadcrumbs", {
        staticClass: "mb-4",
        attrs: { items: _vm.breadcrumbs }
      }),
      _vm.isLoading
        ? _c(
            "div",
            { staticClass: "center-x" },
            [
              _c("v-skeleton-loader", {
                attrs: { type: "text", width: "100%" }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v("Person Routes")]),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Route Id",
                          disabled:
                            (_vm.readOnly && !_vm.isEdit) ||
                            (!_vm.isEdit && !_vm.isView)
                        },
                        model: {
                          value: _vm.personModel.routeId,
                          callback: function($$v) {
                            _vm.$set(_vm.personModel, "routeId", $$v)
                          },
                          expression: "personModel.routeId"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "routeName",
                          disabled: _vm.readOnly,
                          required: ""
                        },
                        model: {
                          value: _vm.personModel.routeName,
                          callback: function($$v) {
                            _vm.$set(_vm.personModel, "routeName", $$v)
                          },
                          expression: "personModel.routeName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Route Type",
                          disabled: _vm.readOnly,
                          required: ""
                        },
                        model: {
                          value: _vm.personModel.routeType,
                          callback: function($$v) {
                            _vm.$set(_vm.personModel, "routeType", $$v)
                          },
                          expression: "personModel.routeType"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          required: "",
                          label: "Priority Id",
                          disabled: _vm.readOnly
                        },
                        model: {
                          value: _vm.personModel.priorityId,
                          callback: function($$v) {
                            _vm.$set(_vm.personModel, "priorityId", $$v)
                          },
                          expression: "personModel.priorityId"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Status",
                          disabled: _vm.readOnly,
                          required: ""
                        },
                        model: {
                          value: _vm.personModel.status,
                          callback: function($$v) {
                            _vm.$set(_vm.personModel, "status", $$v)
                          },
                          expression: "personModel.status"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Internal Id",
                          disabled: _vm.readOnly,
                          required: ""
                        },
                        model: {
                          value: _vm.personModel.internalId,
                          callback: function($$v) {
                            _vm.$set(_vm.personModel, "internalId", $$v)
                          },
                          expression: "personModel.internalId"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", [
            !_vm.isView
              ? _c(
                  "div",
                  { staticClass: "d-flex justify-end" },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: { color: "error", large: "" },
                        on: { click: _vm.createPersonRoute }
                      },
                      [_vm._v("Save")]
                    )
                  ],
                  1
                )
              : _vm._e()
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }