var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "emb-card pa-4" },
    [
      _c(
        "v-dialog",
        {
          staticClass: "pa-4",
          attrs: { "max-width": "500px" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        attrs: { slot: "activator", color: "primary" },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            _vm.open = true
                          }
                        },
                        slot: "activator"
                      },
                      on
                    ),
                    [_vm._v(" Add Users ")]
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.open,
            callback: function($$v) {
              _vm.open = $$v
            },
            expression: "open"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "h4" }, [
                _vm._v(" Add New User ")
              ]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-form",
                    {
                      ref: "form",
                      attrs: { "lazy-validation": "" },
                      model: {
                        value: _vm.valid,
                        callback: function($$v) {
                          _vm.valid = $$v
                        },
                        expression: "valid"
                      }
                    },
                    [
                      _c("v-text-field", {
                        attrs: {
                          rules: _vm.nameRules,
                          label: "Name",
                          required: ""
                        },
                        model: {
                          value: _vm.name,
                          callback: function($$v) {
                            _vm.name = $$v
                          },
                          expression: "name"
                        }
                      }),
                      _c("v-text-field", {
                        attrs: {
                          rules: _vm.emailRules,
                          label: "E-mail",
                          required: ""
                        },
                        model: {
                          value: _vm.email,
                          callback: function($$v) {
                            _vm.email = $$v
                          },
                          expression: "email"
                        }
                      }),
                      _c("v-select", {
                        attrs: {
                          items: _vm.items,
                          rules: [
                            function(v) {
                              return !!v || "Item is required"
                            }
                          ],
                          label: "Access Type",
                          required: ""
                        },
                        model: {
                          value: _vm.select,
                          callback: function($$v) {
                            _vm.select = $$v
                          },
                          expression: "select"
                        }
                      }),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-3",
                          attrs: { color: "primary" },
                          on: {
                            click: function($event) {
                              _vm.open = false
                            }
                          }
                        },
                        [_vm._v("Close")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { disabled: !_vm.valid, color: "error" },
                          on: {
                            click: function($event) {
                              return _vm.addItemToCollaborationList({
                                image: "/static/images/user.png",
                                name: _vm.name,
                                email: _vm.email,
                                access: _vm.select
                              })
                            }
                          }
                        },
                        [_vm._v(" Submit ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      [
        _c("v-data-table", {
          attrs: {
            headers: _vm.headers,
            items: _vm.collaborationData,
            "hide-default-footer": ""
          },
          scopedSlots: _vm._u([
            {
              key: "item.image",
              fn: function(ref) {
                var item = ref.item
                return [_c("img", { attrs: { src: item.image, width: "40" } })]
              }
            },
            {
              key: "item.action",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "v-btn",
                    { attrs: { icon: "", color: "grey", small: "" } },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "error--text",
                          on: {
                            click: function($event) {
                              return _vm.deleteItemFromCollaborationList(item)
                            }
                          }
                        },
                        [_vm._v("delete")]
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ])
        })
      ],
      _c("emb-delete-confirmation-2", {
        ref: "deleteConfirmationDialog",
        attrs: {
          messageTitle: "Are you sure you want to delete?",
          messageDescription:
            "Are you sure you want to delete this user permanently?",
          btn1: "Cancel",
          btn2: "Yes"
        },
        on: { onConfirm: _vm.ondeleteItemFromCollaborationList }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }