var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.heading
    ? _c(
        "div",
        { staticClass: "app-card-title d-flex justify-space-between" },
        [
          _c("h5", [_vm._v(_vm._s(_vm.heading))]),
          _c(
            "div",
            { staticClass: "d-inline-flex align-items-center" },
            [
              _c(
                "v-btn",
                { staticClass: "ma-0 mr-2", attrs: { icon: "" } },
                [_c("v-icon", [_vm._v("sync")])],
                1
              ),
              !_vm.withTabs
                ? [
                    _vm.settings ||
                    _vm.reloadable ||
                    _vm.removePanel ||
                    _vm.viewMore
                      ? _c(
                          "div",
                          { staticClass: "app-contextual-link" },
                          [
                            _c(
                              "v-menu",
                              {
                                attrs: {
                                  transition: "scale-transition",
                                  origin: "right top",
                                  bottom: "",
                                  left: ""
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              {
                                                staticClass: "ma-0",
                                                attrs: { icon: "" }
                                              },
                                              on
                                            ),
                                            [
                                              _c("v-icon", [
                                                _vm._v("more_horiz")
                                              ])
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  2716706199
                                )
                              },
                              [
                                _c(
                                  "v-list",
                                  { staticClass: "app-card-action" },
                                  [
                                    _vm.settings
                                      ? _c(
                                          "v-list-item",
                                          {
                                            on: {
                                              click: function($event) {
                                                return _vm.$emit("onCollapse")
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "i",
                                              {
                                                staticClass:
                                                  "v-icon material-icons theme--light",
                                                attrs: { "aria-hidden": "true" }
                                              },
                                              [_vm._v(" settings ")]
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "px-2" },
                                              [_vm._v("Settings")]
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.viewMore
                                      ? _c(
                                          "v-list-item",
                                          {
                                            on: {
                                              click: function($event) {
                                                return _vm.$emit("onCollapse")
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "i",
                                              {
                                                staticClass:
                                                  "v-icon material-icons theme--light",
                                                attrs: { "aria-hidden": "true" }
                                              },
                                              [_vm._v(" more ")]
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "px-2" },
                                              [_vm._v("View More")]
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.disableNotification
                                      ? _c(
                                          "v-list-item",
                                          {
                                            on: {
                                              click: function($event) {
                                                return _vm.$emit("onReload")
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "i",
                                              {
                                                staticClass:
                                                  "v-icon material-icons theme--light",
                                                attrs: { "aria-hidden": "true" }
                                              },
                                              [_vm._v(" notifications_off ")]
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "px-2" },
                                              [_vm._v("Disable Notification")]
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.removePanel
                                      ? _c(
                                          "v-list-item",
                                          {
                                            on: { click: _vm.removePanelOption }
                                          },
                                          [
                                            _c(
                                              "i",
                                              {
                                                staticClass:
                                                  "v-icon material-icons theme--light",
                                                attrs: { "aria-hidden": "true" }
                                              },
                                              [_vm._v("exit_to_app")]
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "px-2" },
                                              [_vm._v("Remove Panel")]
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                : [
                    _c("div", { staticClass: "app-contextual-link" }, [
                      _c(
                        "ul",
                        { staticClass: "custom-tab-wrap list-inline" },
                        [
                          _vm._l(_vm.tabs, function(tab, key) {
                            return [
                              _c(
                                "li",
                                {
                                  key: key,
                                  on: {
                                    click: function($event) {
                                      return _vm.onChangeTab(key)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "fs-12 fw-normal px-2 py-1 rounded mx-1",
                                      class: [
                                        {
                                          "primary white--text":
                                            _vm.activeTab === key
                                        }
                                      ],
                                      attrs: { href: "javascript:void(0)" }
                                    },
                                    [_vm._v(_vm._s(tab))]
                                  )
                                ]
                              )
                            ]
                          })
                        ],
                        2
                      )
                    ])
                  ]
            ],
            2
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }