var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "emb-privacy-policy-wrap" },
    [
      _c("emb-page-title", {
        attrs: {
          heading: "Privacy Policy",
          subHeading: "Your Information is fully secure with us."
        }
      }),
      _c("div", { staticClass: "section-gap bg-white" }, [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "privacy-page" }, [
            _vm._m(0),
            _c(
              "section",
              { staticClass: "mb-8" },
              [
                _c("h4", [_vm._v("INFORMATION WE COLLECT ABOUT YOU")]),
                _vm._l(_vm.infoWeCollect, function(item) {
                  return _c("p", [
                    _c(
                      "span",
                      { staticStyle: { "text-decoration": "underline" } },
                      [_vm._v(_vm._s(item.title))]
                    ),
                    _vm._v(" " + _vm._s(item.text) + " ")
                  ])
                })
              ],
              2
            ),
            _c("section", { staticClass: "mb-8" }, [
              _c("h4", [_vm._v("HOW WE USE YOUR INFORMATION")]),
              _c("p", [_vm._v(_vm._s(_vm.howWeUseYourInfo.headline))]),
              _c(
                "ul",
                _vm._l(_vm.howWeUseYourInfo.items, function(item) {
                  return _c("li", [_vm._v(" " + _vm._s(item) + " ")])
                }),
                0
              )
            ]),
            _c(
              "section",
              { staticClass: "mb-8" },
              [
                _c("h4", [_vm._v(_vm._s(_vm.howWeShareYourInfo.title))]),
                _c("p", [_vm._v(_vm._s(_vm.howWeShareYourInfo.headline))]),
                _vm._l(_vm.howWeShareYourInfo.items, function(item) {
                  return _c("p", [
                    _c(
                      "span",
                      { staticStyle: { "text-decoration": "underline" } },
                      [_vm._v(_vm._s(item.title))]
                    ),
                    _vm._v(" " + _vm._s(item.text) + " ")
                  ])
                })
              ],
              2
            ),
            _c(
              "section",
              { staticClass: "mb-8" },
              [
                _c("h4", [_vm._v(_vm._s(_vm.otherImportantNotices.title))]),
                _vm._l(_vm.otherImportantNotices.items, function(item) {
                  return _c("p", [
                    _c(
                      "span",
                      { staticStyle: { "text-decoration": "underline" } },
                      [_vm._v(_vm._s(item.title))]
                    ),
                    _vm._v(" " + _vm._s(item.text) + " ")
                  ])
                })
              ],
              2
            ),
            _c("h6", { staticClass: "text-center" }, [
              _vm._v(" LAST UPDATED "),
              _c("strong", [_vm._v(_vm._s(_vm.lastUpdated))])
            ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "mb-8" }, [
      _c("p", [
        _vm._v(" This Privacy Policy describes how L&F (“"),
        _c("strong", [_vm._v("L&F")]),
        _vm._v(",” “"),
        _c("strong", [_vm._v("we")]),
        _vm._v("” or “"),
        _c("strong", [_vm._v("us")]),
        _vm._v(
          "”) collects, uses and shares personal information, such as your name, address, phone number and email address. Use of L&F’s websites and apps (the “"
        ),
        _c("strong", [_vm._v("Service")]),
        _vm._v(
          "”) is subject to this Privacy Policy and our Terms of Service. Read them carefully to understand our practices and your options. "
        )
      ]),
      _c("p", [
        _vm._v(
          " We will notify you by email or through the Service before making significant changes to this Privacy Policy. If you have questions, please contact us at ______________. "
        )
      ]),
      _c("p", [
        _vm._v(
          " The Service is for persons 21 years of age and older. Do not use the Service or send us information if you are under 21. We do not knowingly collect information about anyone under 21, and if we discover that we have personal information about somebody under 21, we will delete it as soon as possible. If you think we have personal information about somebody under 21, please contact us as soon as possible at ______________. Our Terms of Service have more information on age verification and eligibility to use our Service. "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }