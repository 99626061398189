var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "emb-register-wrap section-gap" }, [
    _c(
      "div",
      { staticClass: "container py-0" },
      [
        _c(
          "v-layout",
          {
            attrs: {
              row: "",
              wrap: "",
              "align-center": "",
              "justify-center": ""
            }
          },
          [
            _c(
              "v-flex",
              { attrs: { sm12: "", md12: "", lg8: "", xl7: "" } },
              [
                _c(
                  "v-layout",
                  {
                    attrs: {
                      row: "",
                      "mx-sm-0": "",
                      "mx-3": "",
                      wrap: "",
                      "align-center": "",
                      "justify-center": ""
                    }
                  },
                  [
                    _c(
                      "v-flex",
                      {
                        attrs: {
                          sm6: "",
                          md7: "",
                          lg6: "",
                          xl6: "",
                          "hidden-sm-and-down": ""
                        }
                      },
                      [_c("div", { staticClass: "form-img register-image" })]
                    ),
                    _c(
                      "v-flex",
                      { attrs: { sm10: "", md5: "", lg6: "", xl6: "" } },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "emb-card sign-in-form form-margin d-block white pa-6"
                          },
                          [
                            _c("h4", [_vm._v("Enter Your Details")]),
                            _c(
                              "v-form",
                              {
                                ref: "form",
                                model: {
                                  value: _vm.valid,
                                  callback: function($$v) {
                                    _vm.valid = $$v
                                  },
                                  expression: "valid"
                                }
                              },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    type: "text",
                                    placeholder: "First Name*",
                                    rules: _vm.inputRules.basictextRules
                                  }
                                }),
                                _c("v-text-field", {
                                  attrs: {
                                    type: "text",
                                    placeholder: "Last Name*",
                                    rules: _vm.inputRules.basictextRules
                                  }
                                }),
                                _c("v-text-field", {
                                  attrs: {
                                    type: "email",
                                    placeholder: "Email*",
                                    rules: _vm.emailRules
                                  }
                                }),
                                _c("v-text-field", {
                                  attrs: {
                                    type: "password",
                                    placeholder: "Enter Password*",
                                    rules: _vm.inputRules.basictextRules
                                  }
                                }),
                                _c("v-text-field", {
                                  staticClass: "mb-4",
                                  attrs: {
                                    type: "password",
                                    placeholder: "Retype Passowrd*",
                                    rules: _vm.inputRules.basictextRules
                                  }
                                }),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "accent mx-0 mb-4",
                                    attrs: { large: "" },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        $event.preventDefault()
                                        return _vm.saveDetails($event)
                                      }
                                    }
                                  },
                                  [_vm._v(" Sign Up ")]
                                ),
                                _c(
                                  "p",
                                  [
                                    _vm._v("Already have account? then"),
                                    _c(
                                      "router-link",
                                      {
                                        staticClass: "accent--text",
                                        attrs: { to: "/session/signin" }
                                      },
                                      [_vm._v(" Sign In")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }