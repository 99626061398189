<template>
  <div>
    <div class="table-responsive" v-if="paginatedList">
      <v-data-table
        :headers="headers"
        :items="paginatedList.items"
        :server-items-length="paginatedList.totalCount"
        :options.sync="listViewOptions"
        :loading="isLoading"
        :footer-props="{ 'items-per-page-options': [10, 20, 30, 40, 50] }"
      >
        <template v-slot:[`item.validTo`]="{ item }">
          {{ getFormattedDate(item.validTo) }}
        </template>

        <template v-slot:[`item.action`]="{ item }">
          <Menu :menuItems="rowMenuItems" :menuItemContext="item">
            <v-btn small icon class="primary--text">
                <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </Menu>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { MenuItem } from "../../../components/Menu/MenuItem";
import { tableProps } from "../../../mixins/tableProps";

export default {
  name: "RoutesTable",
  mixins: [tableProps],
  props: ["paginatedList", "isLoading", "searchParams"],
  data() {
    return {
      rowMenuItems: [
        new MenuItem({
            icon: "edit",
            label: "Edit",
            action: (item) => {
                this.$router.push('/admin-panel/person-route/edit/' + item.routeId);
            },
        }),
        new MenuItem({
          icon: "visibility",
          label: "View",
          action: (item) => {
              this.$router.push('/admin-panel/person-route/view/' + item.routeId);
          },
        }),
      ],
      headers: [
        {
          text: "RouteId ID",
          sortable: true,
          value: "routeId",
        },
        {
          text: "Route Name",
          sortable: true,
          value: "routeName",
        },
        {
          text: "Valid To",
          sortable: true,
          value: "validTo",
        },

        {
          text: "Priority Id",
          sortable: true,
          value: "priorityId",
        },
        {
          text: "Status",
          sortable: true,
          value: "status",
        },
        {
          text: "Internal Id",
          sortable: true,
          value: "internalId",
        },
        {
          sortable: true,
          value: "action",
        },
      ],
    };
  },
  mounted() {
    this.listViewOptions.page = this.searchParams.pageNumber;
    this.listViewOptions.itemsPerPage = this.searchParams.pageSize;
    console.log("Paginated list", this.paginatedList);
  },
  methods: {
    getFormattedDate(date) {
      // return moment.utc(date).format("MMMM Do YYYY");
      return moment(new Date(date.toString())).format("MMMM Do YYYY");
    },
  },
  watch: {
    listViewOptions() {
      this.searchParams.pageNumber = this.listViewOptions.page;
      this.searchParams.pageSize = this.listViewOptions.itemsPerPage;

      let sortField = this.listViewOptions.sortBy[0];
      let sortDesc = this.listViewOptions.sortDesc[0];

      if (sortField) {
        this.searchParams.sortField = sortField;
        this.searchParams.sortDirection = sortDesc ? "DESC" : "ASC";
      }

      this.$emit("search-params-changed", this.searchParams);
    },
  }, // watch
};
</script>
