var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("AdminPanelHeader", {
        attrs: {
          actions: _vm.headerActions,
          icon: "person",
          title: "People",
          searchPlaceholder: "Search People"
        },
        on: { search: _vm.onSearch }
      }),
      _c("div", [
        _c(
          "div",
          { staticClass: "d-flex justify-end" },
          [
            _c("v-switch", {
              staticClass: "ma-0 pa-0",
              attrs: { label: "Show All People" },
              on: { change: _vm.onShowAllPeopleSwitched },
              model: {
                value: _vm.searchParams.showAllPeople,
                callback: function($$v) {
                  _vm.$set(_vm.searchParams, "showAllPeople", $$v)
                },
                expression: "searchParams.showAllPeople"
              }
            })
          ],
          1
        ),
        !_vm.searchParams.showAllPeople
          ? _c("p", { staticClass: "text-right" }, [
              _vm._v(
                " Showing people from " +
                  _vm._s(_vm.currentLocation.locationName) +
                  " "
              )
            ])
          : _vm._e()
      ]),
      _c("PeopleTable", {
        attrs: {
          isLoading: _vm.isLoading,
          paginatedList: _vm.peoplePaginatedList,
          searchParams: _vm.searchParams
        },
        on: { "search-params-changed": _vm.onSearchParamsChanged }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }