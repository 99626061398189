var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "shop-content-wrap" },
    [
      _c(
        "v-row",
        { staticClass: "center-center" },
        _vm._l(_vm.products.items, function(product, index) {
          return _c(
            "div",
            { key: index },
            [
              index < 6
                ? _c(
                    "v-col",
                    [_c("recommended-item", { attrs: { product: product } })],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }