var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      disabled: _vm.isView,
                      label: "Address Line 1 *",
                      rules: _vm.requiredRule
                    },
                    model: {
                      value: _vm.selectedSupplier.deliveryAddressLine1,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.selectedSupplier,
                          "deliveryAddressLine1",
                          $$v
                        )
                      },
                      expression: "selectedSupplier.deliveryAddressLine1"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6" } },
                [
                  _c("v-text-field", {
                    attrs: { disabled: _vm.isView, label: "Address Line 2" },
                    model: {
                      value: _vm.selectedSupplier.deliveryAddressLine2,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.selectedSupplier,
                          "deliveryAddressLine2",
                          $$v
                        )
                      },
                      expression: "selectedSupplier.deliveryAddressLine2"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6" } },
                [
                  _c("v-select", {
                    attrs: {
                      disabled: _vm.isView,
                      label: "City *",
                      rules: _vm.requiredRule,
                      items: _vm.cities,
                      "item-text": "cityName",
                      "item-value": "cityId"
                    },
                    model: {
                      value: _vm.selectedSupplier.deliveryCityId,
                      callback: function($$v) {
                        _vm.$set(_vm.selectedSupplier, "deliveryCityId", $$v)
                      },
                      expression: "selectedSupplier.deliveryCityId"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      disabled: _vm.isView,
                      label: "Postal Code *",
                      rules: _vm.requiredRule,
                      type: "number"
                    },
                    model: {
                      value: _vm.selectedSupplier.deliveryPostalCode,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.selectedSupplier,
                          "deliveryPostalCode",
                          $$v
                        )
                      },
                      expression: "selectedSupplier.deliveryPostalCode"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("v-select", {
            attrs: { disabled: _vm.isView, label: "Location" },
            model: {
              value: _vm.selectedSupplier.deliveryLocation,
              callback: function($$v) {
                _vm.$set(_vm.selectedSupplier, "deliveryLocation", $$v)
              },
              expression: "selectedSupplier.deliveryLocation"
            }
          }),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6" } },
                [
                  _c("v-select", {
                    attrs: {
                      disabled: _vm.isView,
                      label: "Delivery Method *",
                      rules: _vm.requiredRule,
                      items: _vm.deliveryMethods,
                      "item-text": "deliveryMethodName",
                      "item-value": "deliveryMethodId"
                    },
                    model: {
                      value: _vm.selectedSupplier.DeliveryMethodId,
                      callback: function($$v) {
                        _vm.$set(_vm.selectedSupplier, "DeliveryMethodId", $$v)
                      },
                      expression: "selectedSupplier.DeliveryMethodId"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      disabled: _vm.isView,
                      label: "Fax Number *",
                      rules: _vm.requiredRule,
                      type: "number"
                    },
                    model: {
                      value: _vm.selectedSupplier.faxNumber,
                      callback: function($$v) {
                        _vm.$set(_vm.selectedSupplier, "faxNumber", $$v)
                      },
                      expression: "selectedSupplier.faxNumber"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }