<template>
  <div>
    <AdminPanelHeader
      :actions="headerActions"
      icon="mdi-routes"
      title="Route"
      searchPlaceholder="Search route"
      @search="onSearch"
    />

    <PeopleRoutesTable
      :isLoading="isLoading"
      :paginatedList="routesPaginatedList"
      :searchParams="paginatedQueryParams"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { AdminPanelHeaderAction } from "../../../components/AdminPanelHeader/AdminPanelHeaderAction";
import apiClient from "../../../apiClient";
export default {
  name: "APPepleRoute",
  data: (thiz) => ({
    isLoading: true,
    searchValue: undefined,
    routesPaginatedList: {},
    paginatedQueryParams: {
      pageSize: 10,
      pageNumber: 0,
      searchValue: "",
      sortField: "routeId",
      sortDirection: "DESC",
    },
    headerActions: [
      new AdminPanelHeaderAction({
        icon: "mdi-reload",
        text: true,
        action: () => thiz.executeSearch(),
      }),
      new AdminPanelHeaderAction({
        color: "accent",
        label: "Add Route",
        action: () => thiz.$router.push("person-route/new"),
      }),
    ],
  }), // data
  mounted() {
    this.getPeopleRoutes();
  },
  computed: {
    ...mapGetters({
      peoplePaginatedList: "peopleRoutes/getRoutes",
      searchParams: "peopleRoutes/getSearchParams",
    }),
  },
  methods: {
    ...mapMutations({
      setSearchValue: "peopleRoutes/SET_SEARCH_PARAM_SEARCH_VALUE",
      setSearchParams: "peopleRoutes/SET_SEARCH_PARAMS",
      resetSearchParams: "peopleRoutes/RESET_SEARCH_PARAMS",
    }),

    async getPeopleRoutes() {
      this.isLoading = true;
      try {
        this.routesPaginatedList = await apiClient.routes.getPaginatedAsync(
          this.paginatedQueryParams
        );
      } catch (error) {
        this.$snotify.error(`Could not find route. ${error}`, {
          closeOnClick: false,
          pauseOnHover: false,
          timeout: 4000,
        });
        this.isLoading = false;
      }
      this.isLoading = false;
    },
    onSearch(searchValue) {
      this.paginatedQueryParams.searchValue = searchValue;
    },
  },
  watch: {
    paginatedQueryParams: {
      deep: true,
      handler() {
        this.getPeopleRoutes();
      },
    },
  },
};
</script>