var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "footerV1-wrap section-gap" },
    [
      _c("v-container", { attrs: { "grid-list-xl": "", "py-0": "" } }, [
        _c(
          "div",
          { staticClass: "footer-top" },
          [
            _c(
              "v-layout",
              { attrs: { row: "", wrap: "" } },
              [
                _c(
                  "v-flex",
                  { attrs: { xs12: "", sm12: "", md4: "", lg5: "", xl5: "" } },
                  [
                    _c("div", { staticClass: "about-wrap" }, [
                      _c("h6", { staticClass: "white--text mb-6" }, [
                        _vm._v(_vm._s(_vm.title))
                      ]),
                      _c("span", { staticClass: "white--text" }, [
                        _vm._v(_vm._s(_vm.description))
                      ])
                    ])
                  ]
                ),
                _c(
                  "v-flex",
                  { attrs: { xs12: "", sm3: "", md2: "", lg2: "", xl2: "" } },
                  [
                    _c("h6", { staticClass: "white--text mb-4" }, [
                      _vm._v("Categories")
                    ]),
                    _c("div", { staticClass: "abt-menu" }, [
                      _c(
                        "ul",
                        { staticClass: "list-unstyled pl-0" },
                        _vm._l(_vm.categories, function(list, key) {
                          return _c(
                            "li",
                            { key: key },
                            [
                              _c("router-link", { attrs: { to: list.path } }, [
                                _vm._v(_vm._s(_vm.$t(list.menuItem)))
                              ])
                            ],
                            1
                          )
                        }),
                        0
                      )
                    ])
                  ]
                ),
                _c(
                  "v-flex",
                  { attrs: { xs12: "", sm3: "", md2: "", lg2: "", xl2: "" } },
                  [
                    _c("h6", { staticClass: "white--text mb-4" }, [
                      _vm._v("My Account")
                    ]),
                    _c("div", { staticClass: "features-menu" }, [
                      _c(
                        "ul",
                        { staticClass: "list-unstyled pl-0" },
                        _vm._l(_vm.session, function(list, key) {
                          return _c(
                            "li",
                            { key: key },
                            [
                              _c("router-link", { attrs: { to: list.path } }, [
                                _vm._v(_vm._s(_vm.$t(list.menuItem)))
                              ])
                            ],
                            1
                          )
                        }),
                        0
                      )
                    ])
                  ]
                ),
                _c(
                  "v-flex",
                  { attrs: { xs12: "", sm3: "", md2: "", lg1: "", xl1: "" } },
                  [
                    _c("div", { staticClass: "policy-menu" }, [
                      _c("h6", { staticClass: "white--text mb-4" }, [
                        _vm._v("About")
                      ]),
                      _c(
                        "ul",
                        { staticClass: "list-unstyled pl-0" },
                        _vm._l(_vm.about, function(list, key) {
                          return _c("li", { key: key }, [
                            _c("a", { attrs: { href: list.path } }, [
                              _vm._v(_vm._s(_vm.$t(list.menuItem)))
                            ])
                          ])
                        }),
                        0
                      )
                    ])
                  ]
                )
              ],
              1
            )
          ],
          1
        ),
        _c("hr"),
        _c("div", { staticClass: "footer-bottom" }, [
          _c(
            "div",
            { staticClass: "footer-bottom-content px-3" },
            [
              _c(
                "v-layout",
                {
                  attrs: {
                    row: "",
                    wrap: "",
                    "align-center": "",
                    "justify-space-between": ""
                  }
                },
                [
                  _c(
                    "v-flex",
                    {
                      staticClass: "text-md-left text-center",
                      attrs: { xs12: "", sm12: "", md6: "", lg6: "", xl6: "" }
                    },
                    [
                      _c("img", {
                        attrs: { src: _vm.img, width: "218", height: "18" }
                      })
                    ]
                  ),
                  _c(
                    "v-flex",
                    {
                      attrs: {
                        xs12: "",
                        sm12: "",
                        md6: "",
                        lg6: "",
                        xl6: "",
                        "py-3": "",
                        "px-2": ""
                      }
                    },
                    [
                      _c("div", { staticClass: "text-md-right text-center" }, [
                        _c("span", { staticClass: "white--text" }, [
                          _vm._v(_vm._s(_vm.copyrightText))
                        ])
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }