<template>
    <v-card>
        <v-card-text>
            <v-row>
                <v-col cols="12" sm="6">
                    <v-text-field
                        :disabled="isView"
                        label="Branch *"
                        :rules="requiredRule"
                        v-model="selectedSupplier.bankAccountBranch"/>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field
                        :disabled="isView"
                        label="Code *"
                        :rules="requiredRule"
                        v-model="selectedSupplier.bankAccountCode"/>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="6">
                    <v-text-field
                        :disabled="isView"
                        label="Name *"
                        :rules="requiredRule"
                        v-model="selectedSupplier.bankAccountName"/>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field
                        :disabled="isView"
                        label="Number *"
                        :rules="requiredRule"
                        v-model="selectedSupplier.bankAccountNumber"/>
                </v-col>
            </v-row>
            <v-text-field
                :disabled="isView"
                label="International Code *"
                :rules="requiredRule"
                v-model="selectedSupplier.bankInternationalCode"/>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    props: ['selectedSupplier'],

    data: () => ({
        isEdit: false,
        isView: false,

        requiredRule: [
            v => !!v || 'Required',
        ],
    }),

    beforeMount() {
        this.isEdit = this.$route.path.includes('edit');
        this.isView = this.$route.path.includes('view');
    }, // beforeMount
}
</script>
