<template>
  <div>
    <AdminPanelHeader
      icon="mdi-file-account"
      title="Terms Type Codes"
      :actions="headerActions"
      @search="onSearch"
    />
    <X12termsTypeCodeTable
      v-on:onDelete="onDelete"
      :paginatedList="currentEntity"
      :searchParams="paginatedQueryParams"
      :isLoading="isLoading"
    />
  </div>
</template>

<script>
import apiClient from "../../../../apiClient";
import { AdminPanelHeaderAction } from "../../../../components/AdminPanelHeader/AdminPanelHeaderAction";
import { showDialog } from "../../../../components/Dialogs/NotifyDialog";
import { parentComponentMixin } from "../../../../mixins/parentComponentMixin";
export default {
  mixins: [parentComponentMixin],
  name: "TermsTypeCodes",
  data() {
    return {
      headerActions: [
        new AdminPanelHeaderAction({
          color: "accent",
          label: "Add new",
          icon: "add",
          action: () =>
            this.$router.push("/admin-panel/definitions/termsTypeCodes/add"),
        }),
      ],
      paginatedQueryParams: {
        pageSize: 10,
        pageNumber: 0,
        searchValue: "",
        sortField: "termsTypeCodeId",
        sortDirection: "DESC",
      },
    };
  },

  methods: {
    async onDelete(id) {
      try {
        this.isLoading = true;
        await apiClient.X12("x12termsTypeCodes").delete(id);
        showDialog("Deleted Successfully");
        this.getPaginated();
      } catch (error) {
        showDialog("Could not delete item", error);
      } finally {
        this.isLoading = false;
      }
    },
    async getPaginated() {
      try {
        this.isLoading = true;
        this.currentEntity = await apiClient.X12("x12termsTypeCodes").getPaginated(
          this.paginatedQueryParams
        );
      } catch (error) {
        showDialog("Could not retrieve data", error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

