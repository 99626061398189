<template>
  <div class="responsive-table">
    <v-data-table
      :headers="headers"
      :items="paginatedList.items"
      :server-items-length="paginatedList.totalCount"
      :options.sync="listViewOptions"
      :loading="isLoading"
      :footer-props="{ 'items-per-page-options': [10, 15, 20, 25, 50] }"
    >
      <template v-slot:[`item.action`]="{ item }">
        <Menu :menuItems="rowMenuItems" :menuItemContext="item">
          <v-btn small icon class="primary--text">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </Menu>
      </template>
    </v-data-table>
    <YesNoDialog ref="yesNoDialog" />
  </div>
</template>
<script>
import { MenuItem } from '../../../../components/Menu/MenuItem';
import { tableProps } from "../../../../mixins/tableProps";
import { yesNoDialogMixin } from "../../../../mixins/showYesNoDialog";
export default {
  mixins: [tableProps,yesNoDialogMixin],
  name: "X12marketAreaQualifierTable",
  data() {
    return {
      rowMenuItems: [
        new MenuItem({
          icon: "edit",
          label: "Edit",
          action: (item) => {
            this.$router.push(
              "/admin-panel/definitions/marketAreaQualifier/edit/" +
                item.marketAreaQualifierId
            );
          },
        }),
        new MenuItem({
          icon: "delete",
          label: "Delete",
          action: (item) => {
            this.showDeleteDialog(item.marketAreaQualifierId);
          },
        }),
      ],
      headers: [
        {
          text: "Market Area Qualifier Id",
          sortable: true,
          value: "marketAreaQualifierId",
        },
        {
          text: "Market Area Qualifier Description",
          sortable: true,
          value: "marketAreaQualifierDescription",
        },
        {
          text: "Market Area Qualifier",
          sortable: true,
          value: "marketAreaQualifier",
        },
        {
          text: "Actions",
          sortable: false,
          value: "action",
        },
      ],
    };
  },
};
</script>