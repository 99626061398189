<template>
  <div class="table-responsive">
    <v-data-table
      :headers="headers"
      :items="paginatedList.items"
      :server-items-length="paginatedList.totalCount"
      :options.sync="listViewOptions"
      :loading="isLoading"
      :footer-props="{ 'items-per-page-options': [10, 20, 30, 40, 50] }"
    >
      <template v-slot:item.isTagPromoted="{ item }">
        <v-switch v-model="item.isTagPromoted" @change="editTag(item)" />
      </template>

      <template v-slot:item.action="{ item }">
        <v-btn small icon :to="'/admin-panel/tags/' + item.stockItemTagId">
          <v-icon>list</v-icon>
        </v-btn>
        <v-btn small icon @click="showEditTagDialog(item)">
          <v-icon>edit</v-icon>
        </v-btn>
        <v-btn small icon @click="showDeleteTagDialog(item)">
          <v-icon>delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <!-- delete tag dialog -->
    <v-dialog v-model="isShowingDeleteDialog" max-width="400">
      <v-card>
        <v-card-text>
          <h6 class="font-weight-regular ma-0 pt-4">
            Are you sure you want to delete tag "{{
              selectedTag.stockItemTagName
            }}"?
          </h6>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn color="error" @click="deleteTag(selectedTag)">Yes</v-btn>
          <v-btn color="primary" @click="isShowingDeleteDialog = false"
            >No</v-btn
          >
        </v-card-actions>
      </v-card> </v-dialog
    ><!-- delete tag dialog -->

    <!-- edit tag dialog -->
    <v-dialog v-model="isShowingEditDialog" max-width="400">
      <v-card>
        <v-card-text>
          <v-card-title>Edit Tag "{{ selectedTagName }}"</v-card-title>
          <v-text-field
            label="Tag name"
            v-model="selectedTag.stockItemTagName"
            :rules="requiredRule"
          />
          <div class="d-flex justify-space-between align-center">
            <p class="ma-0 pa-0">Promoted</p>
            <v-switch v-model="selectedTag.isTagPromoted" />
          </div>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn
            color="error"
            :disabled="
              !selectedTag.stockItemTagName ||
              selectedTag.stockItemTagName.trim().length == 0
            "
            @click="editTag(selectedTag)"
          >
            Edit
          </v-btn>
          <v-btn color="primary" @click="isShowingEditDialog = false"
            >Cancel</v-btn
          >
        </v-card-actions>
      </v-card> </v-dialog
    ><!-- edit tag dialog -->
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: ["paginatedList", "isLoading", "searchParams"],

  data: () => ({
    isShowingDeleteDialog: false,
    isShowingEditDialog: false,
    selectedTag: {},
    selectedTagName: undefined,
    requiredRule: [(v) => !!v || "Required"],
    headers: [
      {
        text: "ID",
        sortable: true,
        value: "stockItemTagId",
      },
      {
        text: "Name",
        sortable: true,
        value: "stockItemTagName",
      },
      {
        text: "Promoted",
        sortable: true,
        value: "isTagPromoted",
      },
      {
        sortable: false,
        value: "action",
      },
    ],

    listViewOptions: {
      page: 1,
      itemsPerPage: 10,
    },
  }), // data

  mounted() {
    this.listViewOptions.page = this.searchParams.pageNumber;
    this.listViewOptions.itemsPerPage = this.searchParams.pageSize;
  }, // mounted

  methods: {
    ...mapActions({
      deleteTagRequest: "tag/delete",
      updateTagRequest: "tag/put",
    }),

    showDeleteTagDialog(tag) {
      this.selectedTag = JSON.parse(JSON.stringify(tag)); // copy to prevent editing original object
      this.isShowingDeleteDialog = true;
    },

    showEditTagDialog(tag) {
      this.selectedTag = JSON.parse(JSON.stringify(tag)); // copy to prevent editing original object
      this.selectedTagName = this.selectedTag.stockItemTagName;
      this.isShowingEditDialog = true;
    },

    deleteTag(tag) {
      this.deleteTagRequest(tag.stockItemTagId).then(() => {
        this.isShowingDeleteDialog = false;
        this.$emit("search-params-changed", this.searchParams);
      });
    },

    editTag(tag) {
      this.selectedTag.internalId = this.selectedTag.stockItemTagName;

      this.updateTagRequest(tag).then(() => {
        this.isShowingEditDialog = false;
        this.$emit("search-params-changed", this.searchParams);
      });
    },
  }, // methods

  watch: {
    listViewOptions: {
      handler() {
        this.searchParams.pageNumber = this.listViewOptions.page;
        this.searchParams.pageSize = this.listViewOptions.itemsPerPage;

        let sortField = this.listViewOptions.sortBy[0];
        let sortDesc = this.listViewOptions.sortDesc[0];

        if (sortField) {
          this.searchParams.sortField = sortField;
          this.searchParams.sortDirection = sortDesc ? "DESC" : "ASC";
        }

        this.$emit("search-params-changed", this.searchParams);
      },
    },
  }, // watch
};
</script>
