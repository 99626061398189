<template>
  <div>
    <v-card class="pa-4 mb-4">
      <v-row no-gutters>
        <v-col
          cols="12"
          sm="3"
          class="d-flex align-center justify-center justify-sm-start"
        >
          <v-icon class="mr-2">hourglass_full</v-icon>
          <h5 class="ma-0">Brand</h5>
        </v-col>
        <v-col cols="12" sm="6" class="d-flex justify-center align-center">
          <v-text-field
            class="mr-4"
            label="Search People"
            v-model="searchValue"
          />
          <v-btn color="primary">Search</v-btn>
        </v-col>
      </v-row>
    </v-card>
    <div>
      <div class="d-flex justify-end">
        <v-switch class="ma-0 pa-0" label="Show All Brands" />
      </div>
    </div>
    <brand-list-view :isLoading="isLoading"> </brand-list-view>
  </div>
</template>

<script>
import BrandListView from "./BrandListView.vue";
export default {
  data() {
    return {
      isLoading: true,
      searchValue: undefined,
    };
  },
  components: {
    BrandListView,
  },
};
</script>
