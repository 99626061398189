var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "table-responsive" },
    [
      _c("v-data-table", {
        attrs: {
          headers: _vm.headers,
          "footer-props": { "items-per-page-options": [5, 10, 15, 20, 25] }
        },
        scopedSlots: _vm._u(
          [
            {
              key: "item.x12tradingPartnerId",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "v-menu",
                    {
                      attrs: {
                        "close-on-content-click": false,
                        "nudge-width": 200,
                        "offset-x": "",
                        transition: "slide-x-transition"
                      },
                      on: { input: _vm.onPartnerIdMenuInput },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-layout",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass: "app-hover-pointer",
                                        staticStyle: {
                                          display: "inline-block"
                                        },
                                        attrs: { "align-center": "" }
                                      },
                                      "v-layout",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c("span", { staticClass: "mr-2" }, [
                                      _vm._v(_vm._s(item.x12tradingPartnerId))
                                    ]),
                                    _c("v-icon", { staticClass: "ma-0" }, [
                                      _vm._v("mdi-pencil")
                                    ])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    },
                    [
                      _c(
                        "v-card",
                        { attrs: { "max-width": "400px" } },
                        [
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "h3",
                                { staticClass: "text-truncate mt-2 mb-6" },
                                [_vm._v("Trading partner Id")]
                              ),
                              _c("v-text-field", {
                                attrs: {
                                  filled: "",
                                  readonly: "",
                                  label: "Current Trading Partner Id"
                                },
                                model: {
                                  value: item.x12tradingPartnerId,
                                  callback: function($$v) {
                                    _vm.$set(item, "x12tradingPartnerId", $$v)
                                  },
                                  expression: "item.x12tradingPartnerId"
                                }
                              }),
                              _c("v-text-field", {
                                attrs: {
                                  filled: "",
                                  disabled: _vm.savingSrsCode == true,
                                  "hide-details": "",
                                  label: "New Partner Id"
                                },
                                model: {
                                  value: _vm.newPartnerId,
                                  callback: function($$v) {
                                    _vm.newPartnerId = $$v
                                  },
                                  expression: "newPartnerId"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-card-actions",
                            [
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    disabled:
                                      !_vm.isPartnerIdValid ||
                                      _vm.saving == true,
                                    loading: _vm.savingSrsCode,
                                    text: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.onSaveSrsCodeClicked(item)
                                    }
                                  }
                                },
                                [_vm._v("Save")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              }
            },
            {
              key: "item.eventTime",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm._v(
                    " " + _vm._s(_vm.getFormattedDate(item.eventTime)) + " "
                  )
                ]
              }
            }
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }