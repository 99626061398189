<template>
  <div>
    <AdminPanelHeader
      icon="mdi-shopping-outline"
      title="Logs"
      @search="onSearch"
    />

    <LogTable
      :paginatedList="currentEntity"
      :searchParams="paginatedQueryParams"
      :isLoading="isLoading"
      v-on:onDelete="onDelete"
    />
  </div>
</template>

<script>
import apiClient from "../../../../../apiClient";
import { showDialog } from "../../../../../components/Dialogs/NotifyDialog";
import { parentComponentMixin } from "../../../../../mixins/parentComponentMixin";
export default {
  mixins: [parentComponentMixin],
  name: "Logs",
  props: {
    correlationId: String,
  },
  data() {
    return {
      paginatedQueryParams: {
        pageSize: 10,
        pageNumber: 0,
        searchValue: "",
        sortField: "logId",
        sortDirection: "DESC",
        filterValue: null,
      },
    };
  },
  methods: {
    async onDelete(id) {
      try {
        this.isLoading = true;
        await apiClient.X12("Logs").delete(id);
        showDialog("Deleted Successfully");
      } catch (error) {
        showDialog("Could not delete log item", error);
      } finally {
        this.isLoading = false;
      }
    },
    async getPaginated() {
      try {
        this.isLoading = true;
        this.paginatedQueryParams.filterValue = this.correlationId;
        this.currentEntity = await apiClient
          .X12("Logs")
          .getFilteredPaginated(this.paginatedQueryParams);
      } catch (error) {
        showDialog("Could not get logs data", error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>