var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.product
    ? _c("div", [
        _c(
          "div",
          {
            staticClass:
              "product-item-wrap emb-card image-max-width cursor-pointer",
            on: {
              click: function($event) {
                return _vm.goToDetail()
              }
            }
          },
          [
            _c(
              "div",
              { staticClass: "image-container center-center pa-5" },
              [
                _c("v-img", {
                  attrs: {
                    alt: "product",
                    "max-height": "250px",
                    "min-height": "250px",
                    src: _vm.getProductImage(),
                    contain: true
                  }
                })
              ],
              1
            ),
            _c("div", { staticClass: "emb-card-content pa-4" }, [
              _c("h5", { staticClass: "font-weight-medium text-capitalize" }, [
                _vm._v(_vm._s(_vm.product.name))
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "emb-meta-info layout align-center justify-space-between"
                },
                [
                  _c("div", { staticClass: "inline-block" }, [
                    _c(
                      "h6",
                      { staticClass: "accent--text" },
                      [
                        _c("emb-currency-sign"),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.formatNumber(_vm.product.unitPrice) || "XX.XX"
                            ) +
                            " "
                        )
                      ],
                      1
                    )
                  ])
                ]
              )
            ])
          ]
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }