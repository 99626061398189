var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("input", {
      staticStyle: { display: "none" },
      attrs: {
        id: "dragAndDropFileInput",
        type: "file",
        multiple: _vm.multiple
      },
      on: { change: _vm.handleFileInput }
    }),
    _c(
      "div",
      {
        staticClass: "pt-2",
        class: { "pb-4": _vm.files.length == 0 },
        staticStyle: { border: "3px dashed darkgray", "border-radius": "20px" },
        on: {
          dragover: function($event) {
            $event.preventDefault()
          },
          drop: [
            function($event) {
              $event.preventDefault()
            },
            _vm.handleFileDrop
          ]
        }
      },
      [
        _c("div", [
          _c(
            "p",
            { staticClass: "text-center" },
            [
              _c(
                "v-icon",
                { staticStyle: { color: "darkgray" }, attrs: { size: "64" } },
                [_vm._v("cloud_upload")]
              )
            ],
            1
          ),
          _c("h5", { staticClass: "text-center font-weight-regular" }, [
            _vm._v("Drag & Drop here")
          ]),
          _c("h6", { staticClass: "text-center font-weight-regular" }, [
            _vm._v("or")
          ]),
          _c(
            "div",
            { staticClass: "d-flex justify-center" },
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", outlined: "" },
                  on: { click: _vm.triggerFileInput }
                },
                [_vm._v("Browse Files")]
              )
            ],
            1
          )
        ]),
        _c(
          "div",
          {
            staticClass: "d-flex flex-wrap",
            class: { "mt-5": _vm.files.length > 0 }
          },
          _vm._l(_vm.files, function(file, index) {
            return _c(
              "v-card",
              {
                key: index,
                staticClass: "mx-3 mb-3",
                attrs: { width: "100", height: "100" }
              },
              [
                _c(
                  "div",
                  { staticClass: "d-flex justify-end mb-4" },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: { small: "", icon: "" },
                        on: {
                          click: function($event) {
                            return _vm.removeFile(index)
                          }
                        }
                      },
                      [_c("v-icon", [_vm._v("close")])],
                      1
                    )
                  ],
                  1
                ),
                _c("p", { staticClass: "text-center ma-0 pa-0" }, [
                  _vm._v(_vm._s(file.name))
                ])
              ]
            )
          }),
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }