import axios from "../api"

const routesUrl = 'routes'

export default {
    async createAsync(route) {
        const response = await axios.post(routesUrl, route)
        return response.data
    },

    /**
     * @param {Number} routeId 
     */
    async deleteAsync(routeId) {
        await axios.delete(`${routesUrl}/${routeId}`)
    },

    /**
     * @param {Number} routeId 
     */
    async getByIdAsync(routeId) {
        const response = await axios.get(`${routesUrl}/${routeId}`)
        return response.data
    },

    async getPaginatedAsync({ pageNumber, pageSize, searchValue, sortDirection, sortField }) {
        const response = await axios.get(`${routesUrl}/paginated`, {
            params: { pageNumber, pageSize, searchValue, sortDirection, sortField }
        })

        return response.data
    },

    async updateAsync(route) {
        await axios.put(`${routesUrl}/${route.routeId}`, route)
    }
}
