export const parentComponentMixin = {
    data() {
        return {
            isLoading: false,
            currentEntity: {},
        }
    },
    methods: {
        onSearch(value) {
            this.paginatedQueryParams.searchValue = value;
            this.paginatedQueryParams.pageNumber = 0;
        },
    },
    watch: {
        paginatedQueryParams: {
            deep: true,
            handler() {
                this.getPaginated();
            },
        },
    },
}