<template>
  <div>
    <AdminPanelHeader
      :actions="headerActions"
      icon="person"
      title="People"
      searchPlaceholder="Search People"
      @search="onSearch"
    />

    <!-- filter people by location switch -->
    <div>
      <div class="d-flex justify-end">
        <v-switch
          class="ma-0 pa-0"
          label="Show All People"
          v-model="searchParams.showAllPeople"
          @change="onShowAllPeopleSwitched"
        />
      </div>
      <p v-if="!searchParams.showAllPeople" class="text-right">
        Showing people from {{ currentLocation.locationName }}
      </p>
    </div>
    <!-- filter people by location switch -->

    <PeopleTable
      :isLoading="isLoading"
      :paginatedList="peoplePaginatedList"
      :searchParams="searchParams"
      v-on:search-params-changed="onSearchParamsChanged"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { AdminPanelHeaderAction } from "../../../components/AdminPanelHeader/AdminPanelHeaderAction";
import { StaticRoutes } from "../../../router/Routes";

export default {
  name: "PeoplePage",
  data: (thiz) => ({
    isLoading: true,
    searchValue: undefined,
    headerActions: [
      new AdminPanelHeaderAction({
        label: "Refresh",
        text: true,
        action: () => thiz.executeSearch(),
      }),
      new AdminPanelHeaderAction({
        color: "accent",
        label: "Add Person",
        action: () => thiz.$router.push(StaticRoutes.adminPanel.addPerson),
      }),
    ],
  }), // data

  computed: {
    ...mapGetters({
      currentLocation: "session/getLocation",
      peoplePaginatedList: "people/getPeople",
      searchParams: "people/getSearchParams",
    }),
  }, // computed

  mounted() {
    let resetSearchParams = this.$route.query.resetSearchParams;

    if (resetSearchParams && resetSearchParams.toString() == true.toString())
      this.resetSearchParams();

    this.searchValue = this.searchParams.searchValue;

    if (this.searchParams.showAllPeople)
      this.getPeople().then(() => (this.isLoading = false));
    else this.getPeopleByLocation().then(() => (this.isLoading = false));
  }, // mounted

  methods: {
    ...mapMutations({
      setSearchValue: "people/SET_SEARCH_PARAM_SEARCH_VALUE",
      setSearchParams: "people/SET_SEARCH_PARAMS",
      resetSearchParams: "people/RESET_SEARCH_PARAMS",
    }),

    ...mapActions({
      getPeople: "people/get",
      getPeopleByLocation: "people/getByLocation",
    }),

    executeSearch() {
      this.isLoading = true;
      this.setSearchValue(this.searchValue);

      if (this.searchParams.showAllPeople)
        this.getPeople().then(() => (this.isLoading = false));
      else this.getPeopleByLocation().then(() => (this.isLoading = false));
    },

    onSearch(searchValue) {
      this.searchValue = searchValue;
      this.executeSearch();
    },

    onSearchParamsChanged(searchParams) {
      this.isLoading = true;
      this.setSearchParams(searchParams);
      this.setSearchValue(this.searchValue);

      if (this.searchParams.showAllPeople)
        this.getPeople().then(() => (this.isLoading = false));
      else this.getPeopleByLocation().then(() => (this.isLoading = false));
    },

    onShowAllPeopleSwitched() {
      this.isLoading = true;

      if (this.searchParams.showAllPeople)
        this.getPeople().then(() => (this.isLoading = false));
      else this.getPeopleByLocation().then(() => (this.isLoading = false));
    },
  }, // methods
};
</script>
