var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      disabled: _vm.isView,
                      label: "Phone number *",
                      rules: _vm.requiredRule,
                      type: "number"
                    },
                    model: {
                      value: _vm.selectedSupplier.phoneNumber,
                      callback: function($$v) {
                        _vm.$set(_vm.selectedSupplier, "phoneNumber", $$v)
                      },
                      expression: "selectedSupplier.phoneNumber"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      disabled: _vm.isView,
                      label: "Reference",
                      rules: _vm.requiredRule
                    },
                    model: {
                      value: _vm.selectedSupplier.supplierReference,
                      callback: function($$v) {
                        _vm.$set(_vm.selectedSupplier, "supplierReference", $$v)
                      },
                      expression: "selectedSupplier.supplierReference"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6" } },
                [
                  _c("v-select", {
                    attrs: {
                      disabled: _vm.isView,
                      label: "Primary Contact *",
                      rules: _vm.requiredRule,
                      items: _vm.people,
                      "item-text": "fullName",
                      "item-value": "personId"
                    },
                    model: {
                      value: _vm.selectedSupplier.primaryContactPersonId,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.selectedSupplier,
                          "primaryContactPersonId",
                          $$v
                        )
                      },
                      expression: "selectedSupplier.primaryContactPersonId"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6" } },
                [
                  _c("v-select", {
                    attrs: {
                      disabled: _vm.isView,
                      label: "Alternate Contact",
                      items: _vm.people,
                      "item-text": "fullName",
                      "item-value": "personId"
                    },
                    model: {
                      value: _vm.selectedSupplier.alternateContactPersonId,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.selectedSupplier,
                          "alternateContactPersonId",
                          $$v
                        )
                      },
                      expression: "selectedSupplier.alternateContactPersonId"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6" } },
                [
                  _c("v-select", {
                    attrs: {
                      disabled: _vm.isView,
                      label: "Category *",
                      rules: _vm.requiredRule,
                      items: _vm.supplierCategories,
                      "item-text": "supplierCategoryName",
                      "item-value": "supplierCategoryId"
                    },
                    model: {
                      value: _vm.selectedSupplier.supplierCategoryId,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.selectedSupplier,
                          "supplierCategoryId",
                          $$v
                        )
                      },
                      expression: "selectedSupplier.supplierCategoryId"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6" } },
                [
                  _c("v-text-field", {
                    attrs: { disabled: _vm.isView, label: "Website" },
                    model: {
                      value: _vm.selectedSupplier.websiteUrl,
                      callback: function($$v) {
                        _vm.$set(_vm.selectedSupplier, "websiteUrl", $$v)
                      },
                      expression: "selectedSupplier.websiteUrl"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }