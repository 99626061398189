<template>
    <div>

        <v-container>
        
            <Breadcrumbs class="mb-4" :items="breadcrumbs"/>

            <!-- order container -->
            <v-card>

                <!-- id and status -->
                <div class="bg-grey pa-4">

                    <!-- if order is finalized -->
                    <div>
                        <div v-if="isLoadingOrder" class="mb-8">
                            <v-skeleton-loader type="text"/>
                        </div>
                        <v-alert v-else-if="!isLoadingOrder && order.isOrderFinalized" dense type="success" class="mb-4">This order has been <strong>finalized</strong></v-alert>
                    </div>
                    <!-- if order is finalized -->

                    <!-- id -->
                    <div class="mb-4">
                        <div v-if="isLoadingOrder" class="center-x">
                            <v-skeleton-loader type="text" width="200px"/>
                        </div>
                        <h5 v-else class="text-center">Transaction ID: {{order.orderId}}</h5>
                    </div><!-- id -->

                    <!-- status -->
                    <div>
                        
                        <!-- loading -->
                        <div v-if="isLoadingOrder">
                            <div class="center-x mb-3">
                                <v-progress-circular indeterminate size="48" style="color: darkgrey"/>
                            </div>

                            <div class="center-x">
                                <v-skeleton-loader type="text" width="100px"/>
                            </div>
                        </div><!-- loading -->

                        <!-- loaded -->
                        <div v-else>
                            <!-- order is submitted -->
                            <div v-if="order.isOrderSubmitted">
                                <div class="d-flex justify-center">
                                    <v-icon size="64" color="green">check_circle</v-icon>
                                </div>
                                <h5 class="text-center ma-0" style="color: green">SUBMITTED</h5>
                            </div><!-- order is submitted -->
                            
                            <!-- order is not submitted -->
                            <div v-else>
                                <div class="d-flex justify-center">
                                    <v-icon size="64" color="gray">report_problem</v-icon>
                                </div>
                                <h5 class="text-center ma-0" style="color: gray">PENDING</h5>
                            </div><!-- order is not submitted -->
                        </div><!-- loaded -->

                    </div><!-- status -->


                </div><!-- id and status -->
                
                <!-- summary and deliver to -->
                <v-card-text>
                    <v-row>

                        <!-- summary -->
                        <v-col cols="12" sm="6">

                            <!-- loading -->
                            <div v-if="isLoadingOrder">
                                <h6 class="mb-2">Summary</h6>
                                <v-skeleton-loader type="text" width="150px" class="mb-1"/>
                                <v-skeleton-loader type="text" width="150px" class="mb-1"/>
                                <v-skeleton-loader type="text" width="150px" class="mb-1"/>
                            </div><!-- loading -->

                            <!-- loaded -->
                            <div v-else>
                                <h6>Summary</h6>
                                <p class="mb-1">Order ID: <b>{{order.orderId}}</b></p>
                                <p class="mb-1">Order Date: <b>{{getOrderDate()}}</b></p>
                                <p class="mb-1">Order Total: <b><emb-currency-sign></emb-currency-sign> {{getOrderTotal()}}</b></p>
                            </div><!-- loaded -->

                        </v-col><!-- summary -->

                        <!-- deliver to -->
                        <v-col cols="12" sm="6">

                            <!-- loading -->
                            <div v-if="isLoadingOrder">
                                <h6 class="mb-2">Deliver To</h6>
                                <v-skeleton-loader type="text" width="150px" class="mb-1"/>
                                <v-skeleton-loader type="text" width="150px" class="mb-1"/>
                                <v-skeleton-loader type="text" width="150px" class="mb-1"/>
                            </div><!-- loading -->

                            <!-- loaded -->
                            <div v-else>
                                <h6>Deliver To</h6>
                                <p class="mb-1">{{order.customer.customerName}}</p>
                                <p class="mb-1">{{order.customer.deliveryAddressLine1}}, {{order.customer.deliveryCity.cityName}}</p>
                                <p class="mb-1">{{order.customer.deliveryAddressLine2}}</p>
                                <p class="mb-1">Contact No. {{order.customer.phoneNumber}}</p>
                            </div><!-- loaded -->

                        </v-col><!-- deliver to -->

                    </v-row>
                </v-card-text><!-- summary and deliver to -->

                <!-- Expected date of delivery -->
                <div class="bg-grey pa-4">
                    
                    <!-- loading -->
                    <div v-if="isLoadingOrder">
                        <h4 class="text-center ma-0 mb-4 font-weight-regular">Expected Date of Delivery</h4>
                        <div class="center-x">
                            <v-skeleton-loader type="text" width="180px"/>
                        </div>
                    </div><!-- loading -->

                    <!-- loaded -->
                    <div v-else>
                        <h4 class="text-center ma-0 font-weight-regular">Expected Date of Delivery</h4>
                        <h3 class="text-center ma-0">{{getOrderExpectedDeliveryDate()}}</h3>
                    </div><!-- loaded -->

                </div><!-- Expected date of delivery -->

                <!-- order lines -->
                <div>
                    <h4 class="ma-4">You order details</h4>
                    
                    <!-- loading -->
                    <div v-if="isLoadingOrder" class="center-x my-8">
                        <v-progress-circular indeterminate size="80" style="color: darkgrey"/>
                    </div><!-- loading -->

                    <!-- loaded -->
                    <div v-else>

                        <!-- order line items -->
                        <div v-for="(orderLine, index) in order.orderLines" :key="index">
                            <OrderLineItem
                                :item="orderLine"
                                :canEditQuantity="isOrderEditable"
                                :canRemove="isOrderEditable"/>
                        </div><!-- order line items -->

                    </div><!-- loaded -->

                </div><!-- order lines -->

                <!-- total calculations -->
                <div v-if="!isLoadingOrder" class="bg-grey pt-6 px-4">
                    <OrderTotals :order="order" :useOrderCustomerId="true"/>
                </div><!-- total calculations -->

            </v-card><!-- order container -->

        </v-container>

        <!-- dialogs -->
        <div>
            <!-- edit quantity dialog -->
            <v-dialog v-model="isShowingEditQuantityDialog" max-width="300">
                <v-card>
                    <v-card-title>Edit Quantity</v-card-title>
                    <v-divider/>

                    <v-card-text>
                        <div class="my-8"></div>
                        <v-text-field type="number" label="Current Quantity" disabled v-model="selectedOrderLine.quantity"/>
                        <v-text-field type="number" label="New Quantity" v-model="newQuantity"/>

                    </v-card-text>

                    <v-divider/>
                    <v-card-actions>
                        <v-spacer/>
                        <v-btn text color="primary" :disabled="!isNewQuantityValid || isUpdatingQuantity" :loading="isUpdatingQuantity" @click="onConfirmQuantityChange">Confirm</v-btn>
                        <v-btn text color="error" :disabled="isUpdatingQuantity" @click="isShowingEditQuantityDialog = false">Cancel</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog><!-- edit quantity dialog -->

            <!-- confirm remove dialog -->
            <v-dialog v-model="isShowingConfirmDialog" max-width="400">
                <v-card>
                    <v-card-title>Remove product</v-card-title>
                    <v-divider/>

                    <v-card-text>
                        <p class="ma-0 pt-5">Are you sure you want to remove this product from the order?</p>
                    </v-card-text>

                    <v-divider/>
                    <v-card-actions>
                        <v-spacer/>
                        <v-btn text color="primary" :disabled="isRemovingOrderLine" :loading="isRemovingOrderLine" @click="onConfirmRemoveOrderLine">Yes</v-btn>
                        <v-btn text color="error" :disabled="isRemovingOrderLine" @click="isShowingConfirmDialog = false">No</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog><!-- confirm remove dialog -->

        </div><!-- dialogs -->
    </div>
</template>

<script>
/* eslint-disable */

import { mapActions, mapGetters } from 'vuex';
import { currency, orders, utils } from '../../../mixins/index';
import OrderLineItem from "@/components/OrderLineItem";
import OrderTotals from "@/components/OrderTotals";
import { BreadcrumbItem } from '../../../components/Breadcrumbs/BreadcrumbItem';
import { StaticRoutes } from '../../../router/Routes';

export default {

    data: () => ({
        breadcrumbs: [
            new BreadcrumbItem({ label: 'Orders', route: StaticRoutes.adminPanel.orders }),
            new BreadcrumbItem({ label: 'Order Details' }),
        ],
        isLoadingOrder: true,
        isShowingConfirmDialog: false,
        isShowingEditQuantityDialog: false,
        isUpdatingQuantity: false,
        isRemovingOrderLine: false,
        selectedOrderLine: {},
        newQuantity: 0,
    }), // data

    computed: {
        ...mapGetters({
            order: 'ordersAdmin/getCurrentOrder',
        }),

        isOrderEditable() {
            if (!this.order)
                return false;

            return this.order.isOrderFinalized == false;
        },

        isNewQuantityValid() {
            return this.newQuantity && this.newQuantity > 0;
        },
    }, // computed

    mounted() {
        let orderId = this.$route.params.id;
        this.getOrderById(orderId).finally(() => this.isLoadingOrder = false);
    }, // mounted

    methods: {
        ...mapActions({
            getOrderById: 'ordersAdmin/getById',
            updateOrder: 'ordersAdmin/update',
        }),

        getOrderTotal() {
            let total = orders.methods.calculateTotal(this.order);
            return currency.methods.formatNumber(total);
        },

        getOrderSubTotal() {
            let subTotal = orders.methods.calculateSubTotal(this.order);
            return currency.methods.formatNumber(subTotal);
        },

        getOrderDiscounts() {
            let discounts = orders.methods.calculateDiscounts(this.order);
            return currency.methods.formatNumber(discounts);
        },

        getOrderDeposits() {
            let deposits = orders.methods.calculateDeposits(this.order);
            return currency.methods.formatNumber(deposits);
        },

        getOrderDate() {
            if (!this.order || !this.order.orderDate) {
                return '';
            }

            let dateUtc = this.order.orderDate.toString();
            let formattedDate = utils.methods.getFormattedDateUtcShortForOrderDate(dateUtc);

            return formattedDate;
        },

        getOrderExpectedDeliveryDate() {
            if (!this.order || !this.order.expectedDeliveryDate) {
                return '';
            }
            
            let dateUtc = this.order.expectedDeliveryDate.toString();
            let formattedDate = utils.methods.getFormattedDateUtcShortForOrderDate(dateUtc);

            return formattedDate;
        },

        onConfirmQuantityChange() {
            this.isUpdatingQuantity = true;
            this.selectedOrderLine.quantity = this.newQuantity;

            this.updateOrder(this.order)
            .then(() => {
                console.log('Order has been updated!', this.order);
                this.isShowingEditQuantityDialog = false;
                this.selectedOrderLine = {};
            })
            .catch(error => {
                console.error('Could not update quantity:', error);
                this.$snotify.error(`Could not update quantity.\n${error}`, {
                    closeOnClick: false,
                    pauseOnHover: true,
                    timeout: 4000,
                });
            })
            .finally(() => this.isUpdatingQuantity = false);
        },

        onConfirmRemoveOrderLine() {
            console.log('onConfirmRemoveOrderLine()', this.selectedOrderLine);
            this.isRemovingOrderLine = true;

            let orderCopy = JSON.parse(JSON.stringify(this.order));
            orderCopy.orderLines = orderCopy.orderLines.filter(orderLine => orderLine.orderLineId != this.selectedOrderLine.orderLineId);

            this.updateOrder(orderCopy)
            .then(() => {
                this.order = this.order.orderLines.filter(orderLine => orderLine.orderLineId != this.selectedOrderLine.orderLineId);

                console.log('The order has been updated', this.order);

                this.isShowingConfirmDialog = false;
                this.selectedOrderLine = {};
            })
            .catch(error => {
                console.error('Could not remove product:', error);
                this.$snotify.error(`Could not remove product.\n${error}`, {
                    closeOnClick: false,
                    pauseOnHover: true,
                    timeout: 4000,
                });
            })
            .finally(() => this.isRemovingOrderLine = false);
        },

        showEditQuantityDialog(selectedOrderLine) {
            console.log('showEditQuantityDialog', selectedOrderLine);
            this.selectedOrderLine = selectedOrderLine;
            this.newQuantity = this.selectedOrderLine.quantity;
            this.isShowingEditQuantityDialog = true;
        },

        showConfirmRemoveDialog(selectedOrderLine) {
            console.log('showConfirmRemoveDialog', selectedOrderLine);
            this.selectedOrderLine = selectedOrderLine;
            this.isShowingConfirmDialog = true;
        },
    }, // methods

    components: {
        OrderLineItem,
        OrderTotals,
    },

    mixins: [ currency, orders, utils ],
}
</script>
