var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "shop-content-wrap" },
    [
      _c("v-alert", { attrs: { type: "error" } }, [
        _vm._v(" Discounts may be applied at checkout ")
      ]),
      _c(
        "v-flex",
        {
          staticClass: "fixed-top",
          attrs: {
            id: "fixedFilters",
            "d-flex": "",
            "d-xs-flex": "",
            "d-sm-flex": "",
            "d-md-none": "",
            "d-lg-none": "",
            "d-xl-none": "",
            xs12: "",
            sm12: ""
          }
        },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-expansion-panels",
                    { attrs: { accordion: "" } },
                    [
                      _c(
                        "v-expansion-panel",
                        [
                          _c("v-expansion-panel-header", [_vm._v("Filters")]),
                          _c(
                            "v-expansion-panel-content",
                            [_c("sidebar-filters")],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6", md: "4", lg: "3" } },
                [
                  _c("v-select", {
                    attrs: {
                      label: "Price Sorting",
                      items: _vm.priceDirection,
                      "item-text": "value",
                      "item-value": "id"
                    },
                    model: {
                      value: _vm.price,
                      callback: function($$v) {
                        _vm.price = $$v
                      },
                      expression: "price"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6", md: "4", lg: "3" } },
                [
                  _c("v-select", {
                    attrs: {
                      label: "Results per page",
                      "item-text": "value",
                      "item-value": "id",
                      items: _vm.pageSizeOptions
                    },
                    model: {
                      value: _vm.itemsPerPage,
                      callback: function($$v) {
                        _vm.itemsPerPage = $$v
                      },
                      expression: "itemsPerPage"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-flex",
        {
          attrs: {
            "d-none": "",
            "d-xs-none": "",
            "d-sm-none": "",
            "d-md-flex": "",
            md12: ""
          }
        },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6", md: "4", lg: "3" } },
                [
                  _c("v-select", {
                    attrs: {
                      label: "Price Sorting",
                      items: _vm.priceDirection,
                      "item-text": "value",
                      "item-value": "id"
                    },
                    model: {
                      value: _vm.price,
                      callback: function($$v) {
                        _vm.price = $$v
                      },
                      expression: "price"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6", md: "4", lg: "3" } },
                [
                  _c("v-select", {
                    attrs: {
                      label: "Results per page",
                      "item-text": "value",
                      "item-value": "id",
                      items: _vm.pageSizeOptions
                    },
                    model: {
                      value: _vm.itemsPerPage,
                      callback: function($$v) {
                        _vm.itemsPerPage = $$v
                      },
                      expression: "itemsPerPage"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("v-flex", {
        staticClass: "header-separator",
        attrs: {
          "d-none": "",
          "d-xs-flex": "",
          "d-sm-flex": "",
          "d-md-none": ""
        }
      }),
      _c("div", [
        _vm.isShowingErrorMessage
          ? _c("div", { staticClass: "my-12 py-12" }, [
              _c(
                "h4",
                {
                  staticClass: "text-center font-weight-light",
                  staticStyle: { color: "gray" }
                },
                [
                  _vm._v(
                    " An error ocurred while trying to search the products "
                  )
                ]
              ),
              _c(
                "div",
                { staticClass: "d-flex justify-center mb-4" },
                [
                  _c(
                    "v-icon",
                    {
                      staticStyle: { color: "darkgray" },
                      attrs: { size: "82" }
                    },
                    [_vm._v(" sentiment_dissatisfied ")]
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "d-flex justify-center" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "", color: "accent" },
                      on: { click: _vm.searchProducts }
                    },
                    [_vm._v(" Click Here to Try Again ")]
                  )
                ],
                1
              )
            ])
          : _c(
              "div",
              [
                _vm.isLoadingProducts
                  ? _c(
                      "v-row",
                      _vm._l(_vm.itemsPerPage, function(index) {
                        return _c(
                          "v-col",
                          {
                            key: index,
                            attrs: { cols: "12", sm: "6", md: "4", lg: "3" }
                          },
                          [
                            _c("v-skeleton-loader", {
                              attrs: { type: "card", height: "450" }
                            })
                          ],
                          1
                        )
                      }),
                      1
                    )
                  : _c(
                      "div",
                      [
                        _vm.isSearchResultEmpty
                          ? _c("div", { staticClass: "my-12 py-12" }, [
                              _c(
                                "h4",
                                {
                                  staticClass: "text-center font-weight-light",
                                  staticStyle: { color: "gray" }
                                },
                                [
                                  _vm._v(
                                    " Looks like there aren't any results! "
                                  )
                                ]
                              ),
                              _c(
                                "h6",
                                {
                                  staticClass: "text-center",
                                  staticStyle: { color: "gray" }
                                },
                                [
                                  _vm._v(
                                    " Try another category or changing some filters. "
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "d-flex justify-center mb-4" },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticStyle: { color: "darkgray" },
                                      attrs: { size: "82" }
                                    },
                                    [_vm._v(" search ")]
                                  )
                                ],
                                1
                              )
                            ])
                          : _c(
                              "v-row",
                              _vm._l(_vm.products.items, function(
                                product,
                                index
                              ) {
                                return _c(
                                  "v-col",
                                  {
                                    key: index,
                                    attrs: {
                                      cols: "12",
                                      sm: "6",
                                      md: "4",
                                      lg: "3"
                                    }
                                  },
                                  [
                                    _c("product-item", {
                                      attrs: { product: product }
                                    })
                                  ],
                                  1
                                )
                              }),
                              1
                            )
                      ],
                      1
                    )
              ],
              1
            )
      ]),
      !_vm.isShowingErrorMessage
        ? _c(
            "div",
            { staticClass: "pagination-container", attrs: { cols: "12" } },
            [
              _c(
                "span",
                {
                  staticClass: "first",
                  class: { disabled: _vm.productRequest.pageNumber == 1 },
                  on: {
                    click: function($event) {
                      return _vm.firstPage()
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v("mdi-chevron-double-left")
                  ])
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "previous",
                  class: { disabled: _vm.productRequest.pageNumber == 1 },
                  on: {
                    click: function($event) {
                      return _vm.previousPage()
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v("mdi-chevron-left")
                  ])
                ],
                1
              ),
              _vm._l(7 + _vm.productRequest.leftMostPage, function(index) {
                return _c(
                  "div",
                  {
                    key: index,
                    on: {
                      click: function($event) {
                        return _vm.changePage(index)
                      }
                    }
                  },
                  [
                    _c(
                      "a",
                      {
                        class: {
                          selected: index == _vm.productRequest.pageNumber,
                          show:
                            index > _vm.products.totalPages ||
                            index < _vm.productRequest.leftMostPage
                        }
                      },
                      [_vm._v(" " + _vm._s(index) + " ")]
                    )
                  ]
                )
              }),
              _c(
                "span",
                {
                  staticClass: "next",
                  class: {
                    disabled:
                      _vm.productRequest.pageNumber == _vm.products.totalPages
                  },
                  on: {
                    click: function($event) {
                      return _vm.nextPage()
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v("mdi-chevron-right")
                  ])
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "last",
                  class: {
                    disabled:
                      _vm.productRequest.pageNumber == _vm.products.totalPages
                  },
                  on: {
                    click: function($event) {
                      return _vm.lastPage()
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v("mdi-chevron-double-right")
                  ])
                ],
                1
              )
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }