var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "home-banner-v2 section-gap pt-4 pb-0" }, [
    _c(
      "div",
      { staticClass: "slider-container container" },
      [
        _c(
          "slick",
          { ref: "carousel", attrs: { options: _vm.slickOptions2 } },
          _vm._l(_vm.data, function(sliderItem, key) {
            return _c(
              "div",
              { key: key, staticClass: "slider-wrap text-center" },
              [
                _c(
                  "div",
                  { staticClass: "banner-slide" },
                  [
                    _c("router-link", { attrs: { to: sliderItem.url } }, [
                      _c("img", {
                        attrs: {
                          src: sliderItem.image,
                          alt: "slide-item",
                          width: "100%"
                        }
                      })
                    ]),
                    _c("div", { staticClass: "emb-banner-content" }, [
                      _c("div", {
                        staticClass: "slider-content-v3-inner ma-auto"
                      })
                    ])
                  ],
                  1
                )
              ]
            )
          }),
          0
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }