var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("AdminPanelHeader", {
        staticClass: "mb-5",
        attrs: { icon: "mdi-bell", title: "Trading Subscriptions" },
        on: { search: _vm.onSearch }
      }),
      _c("TradingSubscriptionsTable", {
        attrs: {
          paginatedList: _vm.currentEntity,
          isLoading: _vm.isLoading,
          searchParams: _vm.paginatedQueryParams
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }