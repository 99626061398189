var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Breadcrumbs", {
        staticClass: "mb-4",
        attrs: { items: _vm.breadcrumbs }
      }),
      _vm.isLoading
        ? _c(
            "div",
            { staticClass: "center-x" },
            [
              _c("v-skeleton-loader", {
                attrs: { type: "text", width: "100%" }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-card",
        [
          _c(
            "v-tabs",
            {
              attrs: {
                vertical: _vm.verticalTabs,
                centered: !_vm.verticalTabs,
                "center-active": !_vm.verticalTabs
              }
            },
            [
              _c(
                "v-tab",
                {
                  staticClass:
                    "d-flex flex-column flex-md-row justify-md-start justify-center"
                },
                [
                  _c("v-icon", { attrs: { left: _vm.verticalTabs } }, [
                    _vm._v("info")
                  ]),
                  _vm._v("Overview ")
                ],
                1
              ),
              _c(
                "v-tab",
                {
                  staticClass:
                    "d-flex flex-column flex-md-row justify-md-start justify-center"
                },
                [
                  _c("v-icon", { attrs: { left: _vm.verticalTabs } }, [
                    _vm._v("mdi-account-cog")
                  ]),
                  _vm._v("Configuration ")
                ],
                1
              ),
              _c(
                "v-tab",
                {
                  staticClass:
                    "d-flex flex-column flex-md-row justify-md-start justify-center"
                },
                [
                  _c("v-icon", { attrs: { left: _vm.verticalTabs } }, [
                    _vm._v("mdi-cards")
                  ]),
                  _vm._v("Subscriptions ")
                ],
                1
              ),
              _c(
                "v-tab",
                {
                  staticClass:
                    "d-flex flex-column flex-md-row justify-md-start justify-center"
                },
                [
                  _c("v-icon", { attrs: { left: _vm.verticalTabs } }, [
                    _vm._v("mdi-message-settings")
                  ]),
                  _vm._v("Messages ")
                ],
                1
              ),
              _c(
                "v-tab",
                {
                  staticClass:
                    "d-flex flex-column flex-md-row justify-md-start justify-center"
                },
                [
                  _c("v-icon", { attrs: { left: _vm.verticalTabs } }, [
                    _vm._v("mdi-file-account")
                  ]),
                  _vm._v(" Subscription FTA ")
                ],
                1
              ),
              _c(
                "v-tab-item",
                [
                  _c(
                    "v-card",
                    { attrs: { flat: "" } },
                    [_c("TradingPartnerForm")],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-tab-item",
                [
                  _c(
                    "v-card",
                    { attrs: { flat: "" } },
                    [_c("X12ConfigData")],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-tab-item",
                [
                  _c(
                    "v-card",
                    { attrs: { flat: "" } },
                    [_c("TradingSubscriptionsPage")],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-tab-item",
                [
                  _c(
                    "v-card",
                    { attrs: { flat: "" } },
                    [_c("TradingMessage")],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-tab-item",
                [
                  _c(
                    "v-card",
                    { attrs: { flat: "" } },
                    [_c("X12subscriptionFileTypeAssociation")],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }