<template>
  <div class="shop-content-wrap">
    <v-row class="center-center">
      <div v-for="(product, index) in products.items" :key="index">
        <v-col v-if="index < 6">
          <recommended-item :product="product"></recommended-item>
        </v-col>
      </div>
    </v-row>
  </div>
</template>

<script>
import RecommendedItem from "./RecommendedItem";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {

  props: ["category", "subType", "productStyle"],

  mounted() {
    // console.log('Category', this.category);
    // console.log('SubType', this.subType);
    // console.log('Style', this.productStyle);

    this.setRecommendedCategory(this.category);
    if (this.category == "Beer") this.setRecommendedSubType(this.subType);
    if (this.category == "Wine") this.setRecommendedStyle(this.productStyle);

    this.getProducts();
  }, // methods

  methods: {
    ...mapActions({
      getProducts: "search/getRecommendedProducts",
      getProductsByCategory: "search/getRecommendedProductsByCategory",
    }),

    ...mapMutations({
      setRecommendedCategory: "search/SET_RECOMMENDED_CATEGORY",
      setRecommendedSubType: "search/SET_RECOMMENDED_SUBTYPE",
      setRecommendedStyle: "search/SET_RECOMMENDED_STYLE",
    }),
  }, // methods

  computed: {
    ...mapGetters({ products: "search/recommendedProducts" }),
  }, // computed

  components: {
    RecommendedItem,
  }, // components

};
</script>
