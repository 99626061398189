<template>
    <div class="table-responsive">
        <v-data-table
            :headers="headers"
            :items="paginatedList.items"
            :server-items-length="paginatedList.totalCount"
            :options.sync="listViewOptions"
            :loading="isLoading"
            :footer-props="{'items-per-page-options': [10, 15, 20, 25, 50] }"
        >
            <template v-slot:[`item.action`]="{ item }">
                <Menu :menuItems="rowMenuItems" :menuItemContext="item">
                    <v-btn small icon class="primary--text">
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                </Menu>
            </template>
        </v-data-table>
        <YesNoDialog ref="yesNoDialog"/>
    </div>
</template>

<script>
import { yesNoDialogMixin } from "../../../../mixins/showYesNoDialog";
import { tableProps } from "../../../../mixins/tableProps";
import { MenuItem } from "../../../../components/Menu/MenuItem";

export default {
    mixins: [tableProps, yesNoDialogMixin],
    name: "TraceTypesCodesTable",

    data() {
        return {
            rowMenuItems: [
        new MenuItem({
          icon: "edit",
          label: "Edit",
          action: (item) => {
            this.$router.push(
              "/admin-panel/definitions/traceTypesCodes/edit/" +
                item.traceTypeId
            );
          },
        }),
        new MenuItem({
          icon: "delete",
          label: "Delete",
          action: (item) => {
            this.showDeleteDialog(item.traceTypeId);
          },
        }),
      ],
            headers: [
                {
                    text: "Trace Type Id",
                    sortable: true,
                    value: "traceTypeId",
                },
                {
                    text: "Trace Type Description",
                    sortable: true,
                    value: "traceTypeDescription",
                },
                {
                    text: "Trace Type Code",
                    sortable: true,
                    value: "traceTypeCode",
                },
                {
                    text: "Last Edited By",
                    sortable: true,
                    value: "lastEditedBy",
                },
                {
                    text: "Actions",
                    sortable: false,
                    value: "action",
                },
            ],
        }
    },
}
</script>
