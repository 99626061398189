<template>
  <v-card>
    <v-card-text>
      <v-expansion-panels
        v-if="product"
        focusable
        multiple
        class="mb-4"
        :value="[0, 1, 2]"
      >
        <!-- product -->
        <v-expansion-panel>
          <v-expansion-panel-header>Product</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-text-field
              :disabled="isView"
              label="Name *"
              v-model="product.stockItemName"
              :rules="requiredRule"
            />
            <v-row>
              <v-col cols="12" sm="12">
                <v-text-field
                  :disabled="isView"
                  label="Brand *"
                  v-model="product.brand"
                  :rules="requiredRule"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  :disabled="isView"
                  label="Alcohol by Volume"
                  v-model="product.alcoholByVolume"
                  type="number"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  :disabled="isView"
                  label="Brand Type"
                  v-model="product.brandType"
                />
              </v-col>
            </v-row>
            <v-textarea
              :disabled="isView"
              outlined
              rows="2"
              label="Marketing Comments"
              v-model="product.marketingComments"
            />
            <v-textarea
              disabled
              outlined
              rows="2"
              label="Search Details"
              v-model="product.searchDetails"
            />

            <!-- tags -->
            <div class="d-flex">
              <v-combobox
                multiple
                solo
                chips
                class="mr-4"
                label="Tags"
                prepend-icon="label"
                item-value="stockItemTagId"
                item-text="stockItemTagName"
                :items="tagsList"
                :disabled="isTagInputDisabled"
                :loading="isTagInputDisabled"
                v-model="product.stockItemTags"
                @change="onTagAdded($event)"
              >
                <template v-slot:selection="{ attrs, item, select, selected }">
                  <v-chip
                    close
                    :disabled="isTagInputDisabled"
                    :input-value="selected"
                    v-bind="attrs"
                    @click:close="removeTag(item)"
                  >
                    <strong>{{ item.stockItemTagName }}</strong
                    >&nbsp;
                  </v-chip>
                </template>
              </v-combobox>

              <!-- alerts -->
              <div>
                <div v-if="isAssigningTags || isUnassigningTags">
                  <v-alert v-if="isAssigningTags" type="info">
                    Adding Tag...
                  </v-alert>

                  <v-alert v-if="isUnassigningTags" type="info">
                    Removing Tag...
                  </v-alert>
                </div>
                <div v-else>
                  <v-alert v-if="showTagAssignedMessage" type="success">
                    Tag added!
                  </v-alert>

                  <v-alert v-if="showTagUnassignedMessage" type="success">
                    Tag removed!
                  </v-alert>
                </div>
              </div>
              <!-- alerts -->
            </div>
            <!-- tags -->
          </v-expansion-panel-content> </v-expansion-panel
        ><!-- product -->

        <!-- smart categories -->
        <v-expansion-panel>
          <v-expansion-panel-header>Smart Category</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col cols="12" sm="6">
                <v-select
                  :loading="loadingCategories == true"
                  :disabled="isView"
                  label="Category *"
                  :items="types"
                  item-text="value"
                  item-value="value"
                  v-model="product.type"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                v-if="enableSubtype() && getSubTypes().length > 0"
              >
                <v-select
                  :disabled="isView"
                  label="Sub Type"
                  item-text="value"
                  item-value="value"
                  :items="getSubTypes()"
                  v-model="product.subType"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                v-if="enableStyle() && getStyles().length > 0"
              >
                <v-select
                  :disabled="isView"
                  label="Style"
                  :items="getStyles()"
                  item-text="value"
                  item-value="value"
                  v-model="product.style"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                v-if="enableMaterials() && getMaterials().length > 0"
              >
                <v-select
                  :disabled="isView"
                  label="Varietals"
                  :items="getMaterials()"
                  item-text="value"
                  item-value="value"
                  v-model="product.materials"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                v-if="enableRegion() && getRegions().length > 0"
              >
                <v-select
                  :disabled="isView"
                  label="Region"
                  :items="getRegions()"
                  item-text="value"
                  item-value="value"
                  v-model="product.originRegion"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                v-if="enableAppelation() && getAppelations().length > 0"
              >
                <v-select
                  :disabled="isView"
                  label="Appelation"
                  :items="getAppelations()"
                  item-text="value"
                  item-value="value"
                  v-model="product.appelation"
                />
              </v-col>
            </v-row>
          </v-expansion-panel-content> </v-expansion-panel
        ><!-- smart categories -->

        <!-- origin -->
        <v-expansion-panel>
          <v-expansion-panel-header>Origin</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col cols="12" sm="6">
                <v-select
                  :disabled="isView"
                  label="Country"
                  :items="countries"
                  item-text="countryName"
                  item-value="countryName"
                  v-model="product.originCountry"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  :disabled="isView"
                  label="Sub region"
                  v-model="product.originSubregion"
                />
              </v-col>
            </v-row>
          </v-expansion-panel-content> </v-expansion-panel
        ><!-- origin  -->
      </v-expansion-panels>
    </v-card-text>
  </v-card>
</template>

<script>
/*eslint-disable*/

import { mapGetters, mapActions } from "vuex";

export default {

  props: {
    product: {
      type: Object,
      required: true,
    },
  }, // props

  data() {
    return {
      isAdd: false,
      isView: false,
      isEdit:false,
      isAssigningTags: false,
      isUnassigningTags: false,
      showTagAssignedMessage: false,
      showTagUnassignedMessage: false,
      requiredRule: [(v) => !!v || "Required"],

      loadingCategories: false,
    };
  }, // data

  mounted() {
    this.isView = this.$route.path.includes("view");
    this.isAdd = this.$route.path.includes("add");
    this.isEdit = this.$route.path.includes("edit");

    this.requestGetColors();
    this.requestGetCountries();
    this.requestGetCities();
    this.requestGetLocations();
    this.getTypesRequest();
    this.getTagsList();

    console.log("this.product:", this.product);
  }, // mounted

  computed: {
    ...mapGetters({
      selectedImage: "products/getSelectedProductImage",
      iriSegments: "products/getIRISegments",
      iriTypes: "products/getIRITypes",
      sizes: "products/getSizes",

      colors: "catalog/getColors",
      countries: "catalog/getCountries",
      cities: "catalog/getCities",
      locations: "catalog/getLocations",
      packageTypes: "catalog/getPackageTypes",
      regions: "geographic/getRegions",
      subregions: "geographic/getSubregions",
      tagsList: "tag/getTagsList",
      types: "types/getTypes",
    }),
    isTagInputDisabled() {
      return (
        this.isView == true ||
        this.isAssigningTags == true ||
        this.isUnassigningTags == true
      );
    },
  }, // computed

  methods: {
    ...mapActions({
      assignTag: "products/assignTag",
      unassignTag: "products/unassignTag",
      requestGetColors: "catalog/requestGetColors",
      requestGetCountries: "catalog/requestGetCountries",
      requestGetCities: "catalog/requestGetCities",
      requestGetLocations: "catalog/requestGetLocations",
      getTagsList: "tag/getList",
      getTagsListByIds: "tag/getListByIds",
      getTypes: "types/get",
    }),
    async refreshPage() {
      await this.requestGetColors();
      await this.requestGetCountries();
      await this.requestGetCities();
      await this.requestGetLocations();
      this.getTypesRequest();
      await this.getTagsList();
    },

    getTypesRequest() {
      this.loadingCategories = true;
      this.getTypes()
      .then(() => { })
      .catch(() => {
        this.$snotify.error(`Could not retrieve  . ${error}`, {
          closeOnClick: false,
          pauseOnHover: false,
          timeout: 4000,
        });
      })
      .finally(() => {
        this.loadingCategories = false;
      });
    },

    onTagAdded() {
      const tagIdsFromCustomFields = this.getCustomFieldsTagIds();
      const tagIdsFromStockItem = this.getStockItemTagIds();
      const tagIdsDifference = tagIdsFromStockItem.filter(
        (id) => !tagIdsFromCustomFields.includes(id)
      );
      this.assignTagsRequest(tagIdsDifference);
    },

    getCustomFields() {
      return this.hasCustomFields()
        ? JSON.parse(this.product.customFields)
        : {};
    },

    getCustomFieldsTagIds() {
      const customFields = this.getCustomFields();

      if (customFields.Tags == undefined || customFields.Tags == null)
        return [];

      return customFields.Tags;
    },

    getStockItemTagIds() {
      const tags = this.product.stockItemTags;

      if (!tags) return [];

      return tags.map((tag) => tag.stockItemTagId);
    },

    hasCustomFields() {
      return (
        this.product.customFields &&
        this.product.customFields.trim().length != 0
      );
    },

    removeTag(tag) {
      this.product.stockItemTags = this.product.stockItemTags.filter(
        (stockItemTag) => stockItemTag.stockItemTagId != tag.stockItemTagId
      );

      const tagIdsFromCustomFields = this.getCustomFieldsTagIds();
      const tagIdsFromStockItem = this.getStockItemTagIds();
      const tagIdsDifference = tagIdsFromCustomFields.filter(
        (id) => !tagIdsFromStockItem.includes(id)
      );
      this.unassignTagsRequest(tagIdsDifference);
    },

    assignTagsRequest(tagIds) {
      console.log("assignTagsRequest", tagIds);
      const stockItemId = this.product.stockItemId;
      const requests = tagIds.map((stockItemTagId) =>
        this.assignTag({ stockItemId, stockItemTagId })
      );

      this.isAssigningTags = true;

      Promise.all(requests)
        .then(() => {
          // Add to tags in stock item (in case save button is pressed)
          const currentCustomFields = JSON.parse(this.product.customFields) ?? {
            Tags: [],
          };
          currentCustomFields.Tags = [...currentCustomFields.Tags, ...tagIds];
          this.product.customFields = JSON.stringify(currentCustomFields);

          this.showTagAssignedMessage = true;
          setTimeout(() => (this.showTagAssignedMessage = false), 1000);
        })
        .finally(() => (this.isAssigningTags = false));
    },

    unassignTagsRequest(tagIds) {
      const stockItemId = this.product.stockItemId;
      const requests = tagIds.map((stockItemTagId) =>
        this.unassignTag({ stockItemId, stockItemTagId })
      );

      this.isUnassigningTags = true;

      Promise.all(requests)
        .then(() => {
          console.log("Tags unnasigned!", tagIds);

          const currentCustomFields = JSON.parse(this.product.customFields) ?? {
            Tags: [],
          };
          currentCustomFields.Tags = currentCustomFields.Tags.filter(
            (el) => !tagIds.includes(el)
          );
          this.product.customFields = JSON.stringify(currentCustomFields);

          this.showTagUnassignedMessage = true;
          setTimeout(() => (this.showTagUnassignedMessage = false), 1000);
        })
        .finally(() => (this.isUnassigningTags = false));
    },

    enableSubtype() {
      var result =
        this.types.length > 0 &&
        this.product.type &&
        (this.product.type == "Beer" || this.product.type == "Spirits");
      if (!result) this.product.subType = undefined;
      return result;
    },
    enableStyle() {
      var result =
        (this.types.length > 0 &&
          this.product.type &&
          this.product.type == "Wine") ||
        ((this.product.type == "Beer" || this.product.type == "Spirits") &&
          this.product.subType);
      if (!result) this.product.style = undefined;
      return result;
    },
    enableMaterials() {
      var result =
        this.types.length > 0 &&
        this.product.type &&
        this.product.type == "Wine" &&
        this.product.style;
      if (!result) this.product.materials = undefined;
      return result;
    },
    enableRegion() {
      var result =
        (this.types.length > 0 &&
          this.enableMaterials() &&
          this.product.materials) ||
        (this.enableSubtype() && this.product.subType && this.product.style);
      if (!result) this.product.originRegion = undefined;
      return result;
    },
    enableAppelation() {
      var result =
        this.types.length > 0 &&
        this.enableMaterials() &&
        this.product.materials &&
        this.product.originRegion;

      if (!result) this.product.appelation = undefined;
      return result;
    },
    getStyles() {
      if (!this.enableStyle() || this.types.length == 0) return [];
      if (this.product.type == "Wine") {
        return (
          this.types.find((x) => x.value == this.product.type)?.facets1 || []
        );
      } else if (
        this.product.type == "Beer" ||
        this.product.type == "Spirits"
      ) {
        return (
          this.getSubTypes().find((x) => x.value == this.product.subType)
            ?.facets1 || []
        );
      } else {
        return [];
      }
    },
    getSubTypes() {
      if (!this.enableSubtype() || this.types.length == 0) return [];

      return (
        this.types.find((x) => x.value == this.product.type)?.facets2 || []
      );
    },
    getRegions() {
      if (!this.enableRegion() || this.types.length == 0) return [];

      if (this.product.type == "Beer" || this.product.type == "Spirits") {
        return (
          this.getStyles().find((x) => x.value == this.product?.style)
            ?.facets1 || []
        );
      } else if (this.product.type == "Wine") {
        return (
          this.getMaterials().find((x) => x.value == this.product.materials)
            ?.facets1 || []
        );
      } else {
        return [];
      }
    },
    getAppelations() {
      if (!this.enableAppelation() || this.types.length == 0) return [];

      return (
        this.getRegions().find((x) => x.value == this.product.originRegion)
          ?.facets1 || []
      );
    },
    getMaterials() {
      if (!this.enableMaterials() || this.types.length == 0) return [];

      return (
        this.getStyles().find((x) => x.value == this.product.style)?.facets1 ||
        []
      );
    },
  }, // methods
};
</script>
