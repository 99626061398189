/*eslint-disable*/

import api from '@/api';

export default {

    namespaced: true,

    state: {
        moduleName: 'customerStockGroupEligibility',
    }, // state

    getters: {

    }, // getters

    mutations: {

    }, // mutations

    actions: {
        getByCustomer({ state, commit }, customerId) {
            return api.get(`${state.moduleName}/customer/${customerId}`);
        },
    }, // actions
}
