import Vue from 'vue'
import Router from 'vue-router'

import userRoutes from './user';
import adminRoutes from './admin';

const Login = () => import('Views/Login.vue');
const NotFound = () => import('Views/NotFound.vue');
const InactiveAccount = () => import('Views/InactiveAccount.vue');

// Guards
import { authGuard, loginGuard } from './guards';

// Store
import {store} from '../store/store';

Vue.use(Router)

const router = new Router({
	mode: 'history',
	routes: [
		userRoutes,
		adminRoutes,
		{
			path: '/login',
			component: Login,
			name: 'Login',
			beforeEnter: loginGuard,
		},
		{
			path: '/inactive-account',
			component: InactiveAccount,
			name: 'InactiveAccount',
			beforeEnter: authGuard,
		},
		{ 
			path: '*',
			component: NotFound, 
			name:'NotFound',
			beforeEnter: authGuard,
		},
	]
});

// Watch changes in route to figure out if fab should be displayed
router.beforeEach((to, _, next) => {
	let routePath = to.path;

	let isFabVisible = true;
	
	if (routePath.includes('admin'))
		isFabVisible = false;
	
	if (routePath.includes('login'))
		isFabVisible = false;
		
	if (routePath.includes('inactive-account'))
		isFabVisible = false;
	
	store.commit('fabmenu/SET_IS_FAB_VISIBLE', isFabVisible);
	next();
});

export default router;
