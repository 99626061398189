export const products = {
   men : [
      {
         objectID:101,
         type:"men",
         image: "/static/images/men/3-item-a.jpg",
         name : "El Afan Tequila",
         brand : "Tequila",
         discount_price:13.30,
         price : 26.99,
         rate : 4,
         availablity:true,
         product_code : "#401455",
         tags: ["Jalisco", "Tequila", "Reposado"],
         description:"Lorem ipsum dolor sit amet consectetur, adipisicing elit. Distinctio aperiam debitis ipsa veniam eos quas excepturi quae? Recusandae distinctio nihil quia quis, eaque aspernatur perferendis repudiandae adipisci labore, impedit beatae!",
         features : ["Exquisite Agave", "2x Distilled", "40% abv (80-proof)"],
         image_gallery : [
            "/static/images/men/3-item-a.jpg",
            "/static/images/men/3-item-b.jpg",
            //"/static/images/men/3-item-c.jpg",
            //"/static/images/men/3-item-d.jpg",
            //"/static/images/men/3-item-e.jpg"
         ],
         category : "Jeans",
         color    : "Blue",
         quantity : 1,
         total:18.75
      },
      {
         objectID:102,
         type:"men",
         brand : "Bull RobjectIDers",
         image: "/static/images/men/2-item-a.jpg",
         name : "Opera Prima - Tempranillo",
         discount_price:6.99,
         price : 8.99,
         rate  : 5,
         availablity :true,
         product_code : "#118081",
         tags : ["Spain", "Tempranillo", "2015"],
         description:"Sit amet consectetur, adipisicing elit. Distinctio aperiam debitis ipsa veniam eos quas excepturi quae? Recusandae distinctio nihil quia quis, eaque aspernatur perferendis repudiandae adipisci labore, impedit beatae! Lorem ipsum dolor",
         features : ["Vintage", "Vibrant", "Fresh"],
         image_gallery: [
            "/static/images/men/00017619033008-3.jpg",
            "/static/images/men/00017619033008-4.png",
         ],
         category : "Wines",
         color   : "Black",
         quantity : 1,
         total:82.75
      },
      {
         objectID :103,
         type :"men",
         brand : "Jeno Karla",
         image: "/static/images/men/1-item-a.jpg",
         name : "Don Simon Floral Sangria",
         discount_price:36.00,
         price : 9.25,
         rate  : 4,
         availablity:true,
         product_code : "#120090",
         tags: ["Spain", "Wine", "Fruit Wine"],
         description:"Suspendisse porttitor ornare ligula. Nam massa erat, fermentum dolor quis, maximus ultrices diam. Aenean pellentesque auctor elementum. Nunc vitae tortor iaculis, mollis odio at, lacinia sapien. Mauris et leo sem. Curabitur sit amet enim nisi. Nunc placerat commodo sem, sed maximus purus",
         features : ["Fizzy", "Sangria", "Exceptionally breezy taste"],
         image_gallery: [
            "/static/images/men/1-item-a.jpg",
            "/static/images/men/1-item-b.jpg",
            "/static/images/men/1-item-c.jpg",
            //"/static/images/men/1-item-d.jpg",
             ],
         category : "Shirt",
         color    : "Blue",
         quantity : 1,
         total:42.75
      },
      {
         objectID:104,
         type :"men",
         brand : "The Capressi",
         image: "/static/images/men/4-item-a.jpg",
         name : "Blue Jean",
         discount_price:32.00,
         price : 40.82,
         rate  : 5,
         availablity:true,
         product_code : "#EM1204",
         tags: ["Blue", "Men", "Jean"],
         description:"Dolor sit amet consectetur, adipisicing elit. Distinctio aperiam debitis ipsa veniam eos quas excepturi quae? Recusandae distinctio nihil quia quis, eaque aspernatur perferendis repudiandae adipisci labore, impedit beatae!",
         features : ["Slim Fit", "Relaxed", "Free Shipping and delivery in 1 Days"],
         image_gallery: [
            "/static/images/men/4-item-a.jpg",
            "/static/images/men/4-item-b.jpg",
            "/static/images/men/4-item-c.jpg",
            "/static/images/men/4-item-d.jpg",
            "/static/images/men/4-item-e.jpg"
         ],
         category : "Jeans",
         color    : "Blue",
         quantity : 1,
         total:40.82
      },
      {
         objectID:105,
         type:"men",
         brand : "JK CK",
         image: "/static/images/men/5-item-a.jpg",
         name : "John L. Sullivan Irish Whiskey",
         discount_price:17.00,
         price : 21.99,
         rate  : 5,
         availablity:true,
         product_code : "#101290",
         tags: ["John L. Sullivan", "Ireland", "Irish whiskey"],
         description:"Lorem ipsum dolor sit amet consectetur, adipisicing elit. Distinctio aperiam debitis ipsa veniam eos quas excepturi quae? Recusandae distinctio nihil quia quis, eaque aspernatur perferendis repudiandae adipisci labore, impedit beatae!",
         features : ["Will knock you out", "Silky bourbon", "Smooth drinking experience"],
         image_gallery: [
            "/static/images/men/5-item-a.jpg",
            "/static/images/men/5-item-b.jpg",
            "/static/images/men/5-item-c.jpg",
           // "/static/images/men/5-item-d.jpg",
           // "/static/images/men/5-item-e.jpg"
         ],
         category : "T-Shirt",
         color    : "Black",
         quantity : 1,
         total:22.75
      }
   ],
   women : [
      {
         objectID:106,
         type:"women",
         brand : "Liod Marcos",
         image: "/static/images/women/6-item-a.jpg",
         name : "Red Strip Dress",
         discount_price:75.00,
         price : 97.75,
         rate  : 5,
         availablity:true,
         product_code : "#EM1205",
         tags: ["Red ", "Women ", "Outwear"],
         description:"Amet consectetur, adipisicing elit. Distinctio aperiam debitis ipsa veniam eos quas excepturi quae? Recusandae distinctio nihil quia quis, eaque aspernatur perferendis repudiandae adipisci labore, impedit beatae!",
         features : ["Regular Fit ", "Pure Cotton ", "Delivery in 3 Days"],
         image_gallery: [
            "/static/images/women/6-item-a.jpg",
            "/static/images/women/6-item-b.jpg",
            "/static/images/women/6-item-c.jpg",
            "/static/images/women/6-item-d.jpg",
            "/static/images/women/6-item-e.jpg"
         ],
         category : "Dresses",
         color    : "Red",
         quantity : 1,
         total:97.75
      },
      {
         objectID:107,
         type:"women",
         brand : "Liod Marcos",
         image: "/static/images/women/7-item-a.jpg",
         name : "Blue Denim",
         discount_price:45.00,
         price : 49.75,
         rate  : 4,
         availablity:true,
         product_code : "#EM1206",
         tags: ["Blue ", "Outwear ", "Denim"],
         description:"Dolor sit amet consectetur, adipisicing elit. Distinctio aperiam debitis ipsa veniam eos quas excepturi quae? Recusandae distinctio nihil quia quis, eaque aspernatur perferendis repudiandae adipisci labore, impedit beatae!",
         features : ["Loose Fit", "Pure Denim", "Free Shipping and delivery in 2 Days"],
         image_gallery: [
            "/static/images/women/7-item-a.jpg",
            "/static/images/women/7-item-b.jpg",
            "/static/images/women/7-item-c.jpg",
            "/static/images/women/7-item-d.jpg",
            "/static/images/women/7-item-e.jpg"
         ],
         category : "Shirt",
         color    : "Blue",
         quantity : 1,
         total:49.75
      },
      {
         objectID:108,
         type:"women",
         brand : "Liod Marcos",
         image: "/static/images/women/8-item-a.jpg",
         name : "Black Dress",
         discount_price:12.00,
         price : 15.75,
         rate  : 5,
         availablity:true,
         product_code : "#EM1207",
         tags: ["Red ", "Women ", "Outwear"],
         description:"Consectetur, adipisicing elit. Distinctio aperiam debitis ipsa veniam eos quas excepturi quae? Recusandae distinctio nihil quia quis, eaque aspernatur perferendis repudiandae adipisci labore, impedit beatae!",
         features : ["Slim Fit", "Pure Cotton", "Free Shipping and delivery in 4 Days"],
         image_gallery: [
            "/static/images/women/8-item-a.jpg",
            "/static/images/women/8-item-b.jpg",
            "/static/images/women/8-item-c.jpg",
            "/static/images/women/8-item-d.jpg",
            "/static/images/women/8-item-e.jpg"
         ],
         category : "Dresses",
         color    : "Black",
         quantity : 1,
         total:15.75
      },
      {
         objectID:109,
         type:"women",
         brand : "Liod Marcos",
         image: "/static/images/women/9-item-a.jpg",
         name : "White T-Shirt",
         discount_price:15.00,
         price : 19.45,
         rate  : 5,
         availablity:true,
         product_code : "#EM1208",
         tags: ["White ", "Graphic ", "Women"],
         description:"Sit amet consectetur, adipisicing elit. Distinctio aperiam debitis ipsa veniam eos quas excepturi quae? Recusandae distinctio nihil quia quis, eaque aspernatur perferendis repudiandae adipisci labore, impedit beatae!",
         features : ["Slim Fit ", "100% Cotton ", "Free Shipping and delivery in 4 Days"],
         image_gallery: [
            "/static/images/women/9-item-b.jpg",
            "/static/images/women/9-item-c.jpg",
            "/static/images/women/9-item-d.jpg",
            "/static/images/women/9-item-e.jpg"
         ],
         category : "T-Shirt",
         color    : "White",
         quantity : 1,
         total:19.45
      }
   ],
   gadgets : [
      {
         objectID:101,
         type:"gadgets",
         brand : "Liod Marcos",
         image: "/static/images/gadgets/g-1-a.jpg",
         name : "Cantina Casteggio Pinot Grigio",
         discount_price:90.00,
         price : 8.99,
         rate  : 5,
         availablity:true,
         product_code : "#126281",
         tags: ["Italy", "White Wine", "Pinot Grigio"],
         description:"Ipsum dolor sit amet consectetur, adipisicing elit. Distinctio aperiam debitis ipsa veniam eos quas excepturi quae? Recusandae distinctio nihil quia quis, eaque aspernatur perferendis repudiandae adipisci labore, impedit beatae!",
         features : ["Oltrepo Pavese", "Lombardia ", "Cantina de Casteggio"],
         image_gallery: [
            "/static/images/gadgets/g-1-a.jpg",
            "/static/images/gadgets/g-1-b.jpg",
            "/static/images/gadgets/g-1-c.jpg",
            //"/static/images/gadgets/g-1-d.jpg"
         ],
         category : "Headphone",
         color : "Black",
         quantity : 1,
         total:125.75
      },
      {
         objectID:111,
         type:"gadgets",
         brand : "Liod Marcos",
         image: "/static/images/gadgets/g-2-a.jpg",
         name : "Angeline Cabernet Sauvignon California, 2017",
         discount_price:655.00,
         price : 14.99,
         rate  : 4,
         availablity:true,
         product_code : "#123839",
         tags: ["Angeline", "Red Wine", "Cabernet Sauvignon"],
         description:"Dolor sit amet consectetur, adipisicing elit. Distinctio aperiam debitis ipsa veniam eos quas excepturi quae? Recusandae distinctio nihil quia quis, eaque aspernatur perferendis repudiandae adipisci labore, impedit beatae!",
         features : ["California", "Cherry, Plum", "Medium-bodied"],
         image_gallery: [
            "/static/images/gadgets/g-2-a.jpg",
            "/static/images/gadgets/g-2-b.jpg",
            "/static/images/gadgets/g-2-c.jpg",
            //"/static/images/gadgets/g-2-d.jpg"
         ],
         category : "Smartphone",
         color : "Black",
         quantity : 1,
         total:847.63
      },
      {
         objectID:112,
         type:"gadgets",
         brand : "Liod Marcos",
         image: "/static/images/gadgets/g-3-a.jpg",
         name : "Analog Watch",
         discount_price:225.56,
         price : 357.75,
         rate  : 4,
         availablity:true,
         product_code : "#EM12012",
         tags: ["Analog/Digital ", "Men/Women ", "Black"],
         description:"Dolor sit amet consectetur, adipisicing elit. Distinctio aperiam debitis ipsa veniam eos quas excepturi quae? Recusandae distinctio nihil quia quis, eaque aspernatur perferendis repudiandae adipisci labore, impedit beatae!",
         features : ["Soft Leather", "Chargable", "Delivery in 4 Days"],
         image_gallery: [
            "/static/images/gadgets/g-3-a.jpg",
            "/static/images/gadgets/g-3-b.jpg",
            "/static/images/gadgets/g-3-c.jpg",
            "/static/images/gadgets/g-3-d.jpg"
         ],
         category : "Watch",
         color : "Black",
         quantity : 1,
         total:357.75
      },
      {
         objectID:113,
         type:"gadgets",
         brand : "Liod Marcos",
         image: "/static/images/gadgets/g-4-a.jpg",
         name : "Digital Watch",
         discount_price:458.00,
         price : 568.75,
         rate  : 5,
         availablity:true,
         product_code : "#EM1213",
         tags: ["Analog/Digital ", "Men/Women ", "Black"],
         description:"Opsum dolor sit amet consectetur, adipisicing elit. Distinctio aperiam debitis ipsa veniam eos quas excepturi quae? Recusandae distinctio nihil quia quis, eaque aspernatur perferendis repudiandae adipisci labore, impedit beatae!",
         features : ["Touch Screen", "Bluetooth", "Free Shipping and delivery in 4 Days"],
         image_gallery: [
            "/static/images/gadgets/g-4-a.jpg",
            "/static/images/gadgets/g-4-b.jpg",
            "/static/images/gadgets/g-4-c.jpg",
            "/static/images/gadgets/g-4-d.jpg",
            "/static/images/gadgets/g-4-e.jpg"
         ],
         category : "Watch",
         color : "Black",
         quantity : 1,
         total:568.75
      },
      {
         objectID:114,
         type:"gadgets",
         brand : "Liod Marcos",
         image: "/static/images/gadgets/g-5-a.jpg",
         name : "Circular Speaker",
         discount_price:698.45,
         price : 989,
         rate  : 5,
         availablity:true,
         product_code : "#EM1214",
         tags: ["Speaker", "Smart", "Circular"],
         description:"Lorem ipsum dolor sit amet consectetur, adipisicing elit. Distinctio aperiam debitis ipsa veniam eos quas excepturi quae? Recusandae distinctio nihil quia quis, eaque aspernatur perferendis repudiandae adipisci labore, impedit beatae!",
         features : ["Woofer", "Wireless", "Free Shipping and delivery in 4 Days"],
         image_gallery: [
            "/static/images/gadgets/g-5-a.jpg",
            "/static/images/gadgets/g-5-b.jpg",
            "/static/images/gadgets/g-5-c.jpg",
            "/static/images/gadgets/g-5-d.jpg"
         ],
         category : "Speaker",
         color : "Black",
         quantity : 1,
         total:698.45
      }
   ],
   accessories : [
      {
         objectID:115,
         type:"accessories",
         brand : "Liod Marcos",
         image: "/static/images/gadgets/g-5-a.jpg",
         name : "Laptop Backpack",
         discount_price:88.00,
         price : 92.75,
         rate  : 4,
         availablity:true,
         product_code : "#EM1215",
         tags: ["Black", "Laptop", "Bags"],
         description:"Adipisicing elit. Distinctio aperiam debitis ipsa veniam eos quas excepturi quae? Recusandae distinctio nihil quia quis, eaque aspernatur perferendis repudiandae adipisci labore, impedit beatae! Lorem ipsum dolor sit amet consectetur",
         features : ["Dell/Mac/Acer Laptop Bag", "Pure Leather", "20 Kg Capacity"],
         image_gallery: [
            "/static/images/accesories/a-1-a.jpg",
            "/static/images/accesories/a-1-b.jpg",
            "/static/images/accesories/a-1-c.jpg",
            "/static/images/accesories/a-1-d.jpg"
         ],
         category : "Laptap",
         color : "Black",
         quantity : 1,
         total:92.75
      },
      {
         objectID:116,
         type:"accessories",
         brand : "Liod Marcos",
         image: "/static/images/accesories/a-2-a.jpg",
         name : "Men Belts",
         discount_price:44.00,
         price : 38.31,
         rate  : 4,
         availablity:true,
         product_code : "#EM1216",
         tags: ["Tan", "Belts", "Leather"],
         description:"Uorem ipsum dolor sit amet consectetur, adipisicing elit. Distinctio aperiam debitis ipsa veniam eos quas excepturi quae? Recusandae distinctio nihil quia quis, eaque aspernatur perferendis repudiandae adipisci labore, impedit beatae!",
         features : ["Pure Leather", "Water Proof", "Steel Buckle"],
         image_gallery: [
            "/static/images/accesories/a-2-a.jpg",
            "/static/images/accesories/a-2-b.jpg",
            "/static/images/accesories/a-2-c.jpg",
            "/static/images/accesories/a-2-d.jpg"
         ],
         category : "Belts",
         color : "Black",
         quantity : 1
      },
      {
         objectID:117,
         type:"accessories",
         brand : "Liod Marcos",
         image: "/static/images/accesories/a-3-a.jpg",
         name : "Steel Chain",
         discount_price:78.00,
         price : 56.75,
         rate  : 5,
         availablity:true,
         product_code : "#EM1217",
         tags: ["Chain", "Long Chain", "Accessories"],
         description:"Ipsum dolor sit amet consectetur, adipisicing elit. Distinctio aperiam debitis ipsa veniam eos quas excepturi quae? Recusandae distinctio nihil quia quis, eaque aspernatur perferendis repudiandae adipisci labore, impedit beatae!",
         features : ["Slim Fit", "Pure Steel", "Free Shipping and delivery in 7 Days"],
         image_gallery: [
            "/static/images/accesories/a-3-a.jpg",
            "/static/images/accesories/a-3-b.jpg",
            "/static/images/accesories/a-3-c.jpg",
            "/static/images/accesories/a-3-d.jpg"
         ],
         category : "Jewellery",
         color : "Black",
         quantity : 1,
         total:56.75
      },
      {
         objectID:118,
         type:"accessories",
         brand : "Liod Marcos",
         image: "/static/images/accesories/a-4-a.jpg",
         name : "Men Purse",
         discount_price:14.00,
         price : 15.75,
         rate  : 5,
         availablity:true,
         product_code : "#EM1218",
         tags: ["Tan", "Men", "Money"],
         description:"Lorem ipsum dolor sit amet consectetur, adipisicing elit. Distinctio aperiam debitis ipsa veniam eos quas excepturi quae? Recusandae distinctio nihil quia quis, eaque aspernatur perferendis repudiandae adipisci labore, impedit beatae!",
         features : ["Slim Designed", "Pure Leather", "Credit/Debit Cards Pockets"],
         image_gallery: [
            "/static/images/accesories/a-4-a.jpg",
            "/static/images/accesories/a-4-b.jpg",
            "/static/images/accesories/a-4-c.jpg",
            "/static/images/accesories/a-4-d.jpg"
         ],
         category : "Purse",
         color : "Brown",
         quantity : 1,
         total:15.75
      },
      {
         objectID:119,
         type:"accessories",
         brand : "Liod Marcos",
         image: "/static/images/accesories/a-5-a.jpg",
         name : "Summer Goggles",
         discount_price:14.00,
         price : 15.75,
         rate  : 5,
         availablity:true,
         product_code : "#EM1219",
         tags: ["Black", "Men", "Shape"],
         description:"Iit amet consectetur, adipisicing elit. Distinctio aperiam debitis ipsa veniam eos quas excepturi quae? Recusandae distinctio nihil quia quis, eaque aspernatur perferendis repudiandae adipisci labore, impedit beatae!",
         features : ["Smooth Finishing", "Soft", "Free Shipping and delivery in 4 Days"],
         image_gallery: [
            "/static/images/accesories/a-5-a.jpg",
            "/static/images/accesories/a-5-b.jpg",
            "/static/images/accesories/a-5-c.jpg",
            "/static/images/accesories/a-5-d.jpg"
         ],
         category : "Goggles",
         color : "Black",
         quantity : 1,
         total:15.75
      }
   ]
}
