<template>
  <div class="emb-contact-wrap">

    <emb-page-title
			heading="Contact Us"
			subHeading="Share your feedback with us."
			imageType="contact">
		</emb-page-title>

    <div class="emb-contact-content">
      <div class="Contact-page">
        <div class="contact-map">
          <emb-google-map></emb-google-map>
        </div>
      </div>
      <div class="contact-info-wrapper">
        <v-container grid-list-xl py-0>
          <div class="section-gap">
            <v-layout row wrap>
              <v-flex sm12 md12 lg5 xl5>
                <h2 class="v-layout justify-start align-center mb-6">Contact Info</h2>

                <!-- phone number -->
                <div class="mb-4">
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon color="grey lighten-1" size="48">call</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-subtitle>
                        <h6 class="font-weight-regular ma-0" style="color: gray">Phone Number</h6>
                      </v-list-item-subtitle>
                      <v-list-item-title>
                        <h4 v-if="location.phoneNumber">{{location.phoneNumber}}</h4>
                        <h4 v-if="!location.phoneNumber" class="ma-0">8123812381</h4>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </div>
                <!-- phone number -->

                <!-- website -->
                <div class="mb-4">
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon color="grey lighten-1" size="48">language</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-subtitle>
                        <h6 class="font-weight-regular ma-0" style="color: gray">Website</h6>
                      </v-list-item-subtitle>
                      <v-list-item-title class="text-wrap">
                        <h4
                          v-if="location.websiteUrl"
                          class="hoverable"
                          @click="openHyperLinkInNewTab(location.websiteUrl)"
                        >{{location.websiteUrl}}</h4>
                        <h4 v-if="!location.websiteUrl" class="ma-0">www.website.com</h4>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </div>
                <!-- website -->

                <!-- address -->
                <div class="mb-4">
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon color="grey lighten-1" size="48">business</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-subtitle>
                        <h6 class="font-weight-regular ma-0" style="color: gray">Address</h6>
                      </v-list-item-subtitle>
                      <v-list-item-title class="text-wrap">
                        <h4 v-if="location.postalAddressLine1">
                          {{location.postalAddressLine1}}
                          <span v-if="location.postalAddressLine2">, {{location.postalAddressLine2}}</span>
                          {{location.postalCity}} {{location.postalPostalCode}}
                        </h4>
                        <h4 class="ma-0" v-if="!location.postalAddressLine1">
                          1234 Street Name,
                          City State, 33123
                        </h4>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </div>
                <!-- address -->
              </v-flex>

              <v-flex sm12 md12 lg7 xl7>
                <div class="sec-title">
                  <h2>Write to Us</h2>
                </div>
                <v-form ref="form" v-model="valid">
                  <v-text-field
                    disabled
                    type="text"
                    label="First Name"
                    v-model="formData.firstName"
                    :rules="inputRules.basictextRules"
                  />
                  <v-text-field
                    disabled
                    type="text"
                    label="Last Name"
                    v-model="formData.lastName"
                    :rules="inputRules.basictextRules"
                  />
                  <v-text-field
                    disabled
                    type="email"
                    label="Email"
                    v-model="formData.emailAddress"
                    :rules="emailRules"
                  />

                  <v-combobox
                    label="Subject"
                    :items="[
											'Product inquiry (need assistance with availability, pricing, finding an item, special orders)', // Direct message to Sales rep/manager (My Sales Team, cc Thomas)
											'Service Assistance (general service assistance, delivery inquiries)', // Direct message to Sales rep/manager (My Sales Team, cc Thomas)
											'Let us know how we\'re doing', // Direct message to Thomas
										]"
                    :rules="inputRules.basictextRules"
										v-model="formData.subject"
                  />

                  <v-textarea
                    rows="4"
                    outlined
                    label="Leave a Message"
                    :rules="inputRules.basictextRules"
										v-model="formData.message"
                  />

                  <v-btn
                    class="accent mx-0 mt-4"
                    large
                    :disabled="isSendingEmail"
                    :loading="isSendingEmail"
                    @click.stop.prevent="saveDetails"
                  >Send Message</v-btn>
                </v-form>
              </v-flex>
            </v-layout>
          </div>
        </v-container>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  data() {
    return {
      valid: false,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      inputRules: {
        basictextRules: [(v) => !!v || "This field should not be empty"],
      },
      formData: {
        firstName: "",
        lastName: "",
        emailAddress: "",
        subject: "",
        message: "",
      },
      isSendingEmail: false,
    };
  }, // data

  computed: {
    ...mapGetters({
      location: "session/getLocation",
      person: "session/getPerson",
    }),
  }, // computed

  mounted() {
		this.formData.firstName = this.person.firstName;
		this.formData.lastName = this.person.lastName;
		this.formData.emailAddress = this.person.emailAddress;
	}, // mounted

  methods: {
    ...mapMutations({
      setEmailRequest: 'email/SET_EMAIL_REQUEST',
    }),
    ...mapActions({
      sendEmail: 'email/sendEmail',
    }),

    openHyperLinkInNewTab(url) {
      let win = window.open(url, "_blank");
      win.focus();
    },

    clearForm() {
      this.formData.message = '';
    },

    saveDetails() {
      let isFormDataValid = this.$refs.form.validate();

      if (!isFormDataValid) {
        return;
      }

      this.setEmailRequest({
        firstName: this.formData.firstName,
        lastName: this.formData.lastName,
        emailAddressFrom: this.formData.emailAddress,
        emailAddressTo: 'carp_97@outlook.com',
        subject: this.formData.subject,
        message: this.formData.message,
      });

      this.isSendingEmail = true;

      this.sendEmail()
      .then(result => {
        console.log('Email sent!', result);

        this.$snotify.success("Your email has been sent!", {
          closeOnClick: false,
          pauseOnHover: false,
          timeout: 2000
        });

        this.clearForm();
      })
      .catch(error => {
        console.error(error);

        this.$snotify.error("And error occurred while sending your email. Please try again later.", {
          closeOnClick: false,
          pauseOnHover: false,
          timeout: 4000
        });
      })
      .finally(() => this.isSendingEmail = false);
    },
	}, // methods
};
</script>

<style scoped>
.hoverable:hover {
  cursor: pointer;
}
</style>
