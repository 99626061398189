var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "px-sm-4 px-md-12" },
    [
      _c(
        "section",
        { staticClass: "d-flex mb-4" },
        [
          _c("v-icon", { staticClass: "mr-2", attrs: { large: "" } }, [
            _vm._v("label")
          ]),
          _c("v-text-field", {
            staticClass: "tab-input-title",
            attrs: { disabled: "" },
            model: {
              value: _vm.tag.stockItemTagName,
              callback: function($$v) {
                _vm.$set(_vm.tag, "stockItemTagName", $$v)
              },
              expression: "tag.stockItemTagName"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex mb-6" },
        [
          _c(
            "router-link",
            {
              staticClass: "font-weight-medium",
              attrs: { to: "/admin-panel/tags" }
            },
            [_vm._v("Back")]
          )
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "mb-3" },
        [
          _c(
            "v-tabs",
            {
              attrs: {
                vertical: _vm.verticalTabs,
                centered: !_vm.verticalTabs,
                "center-active": !_vm.verticalTabs
              }
            },
            [
              _c(
                "v-tab",
                {
                  staticClass:
                    "d-flex flex-column flex-md-row justify-md-start justify-center"
                },
                [
                  _c("v-icon", { attrs: { left: _vm.verticalTabs } }, [
                    _vm._v("info")
                  ]),
                  _vm._v(" Overview ")
                ],
                1
              ),
              _c(
                "v-tab",
                {
                  staticClass:
                    "d-flex flex-column flex-md-row justify-md-start justify-center"
                },
                [
                  _c("v-icon", { attrs: { left: _vm.verticalTabs } }, [
                    _vm._v("list")
                  ]),
                  _vm._v(" Tagged Products ")
                ],
                1
              ),
              _c(
                "v-tab-item",
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-text",
                        [
                          _c("v-text-field", {
                            attrs: {
                              disabled: "",
                              label: "Tag name *",
                              rules: _vm.requiredRule
                            },
                            model: {
                              value: _vm.tag.stockItemTagName,
                              callback: function($$v) {
                                _vm.$set(_vm.tag, "stockItemTagName", $$v)
                              },
                              expression: "tag.stockItemTagName"
                            }
                          }),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-space-between align-center"
                            },
                            [
                              _c("p", { staticClass: "ma-0 pa-0" }, [
                                _vm._v("Promoted")
                              ]),
                              _c("v-switch", {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.tag.isTagPromoted,
                                  callback: function($$v) {
                                    _vm.$set(_vm.tag, "isTagPromoted", $$v)
                                  },
                                  expression: "tag.isTagPromoted"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-tab-item",
                [
                  _c(
                    "v-card",
                    [
                      _c("v-card-text", [
                        _c(
                          "div",
                          { staticClass: "d-flex justify-end" },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: { color: "primary" },
                                on: {
                                  click: function($event) {
                                    _vm.isShowingBulkRemoveDialog = true
                                  }
                                }
                              },
                              [_vm._v(" Remove tag from all products ")]
                            )
                          ],
                          1
                        )
                      ]),
                      _c("stock-items-list-view", {
                        attrs: {
                          searchParams: _vm.searchParams,
                          paginatedList: _vm.taggedStockItems,
                          isLoading: _vm.isLoading
                        },
                        on: {
                          "search-params-changed": _vm.onSearchParamsChanged
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "400" },
          model: {
            value: _vm.isShowingBulkRemoveDialog,
            callback: function($$v) {
              _vm.isShowingBulkRemoveDialog = $$v
            },
            expression: "isShowingBulkRemoveDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-text",
                [
                  _c("v-card-title", [_vm._v("Remove Tag")]),
                  _c("p", { staticClass: "ma-0 pa-0" }, [
                    _vm._v(
                      'Are you sure you want to remove the tag "' +
                        _vm._s(_vm.tag.stockItemTagName) +
                        '" from all the listed products?'
                    )
                  ])
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "d-flex justify-end" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error" },
                      on: {
                        click: function($event) {
                          return _vm.bulkRemoveTag(_vm.tag.stockItemTagId)
                        }
                      }
                    },
                    [_vm._v("Yes")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: {
                        click: function($event) {
                          _vm.isShowingBulkRemoveDialog = false
                        }
                      }
                    },
                    [_vm._v("No")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }