//-----------------------| Search Module |-------------------//

/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import searchClient from "@/services/azsearch.service";
import api from "@/api";
import Vue from 'vue'

const state = {
  moduleName: 'productImage',
  productImages: {
    hasNextPage: false,
    hasPreviousPage: false,
    items: [],
    totalPages: 0,
    totalCount: 0
  },
  productImageTypes: {
    hasNextPage: false,
    hasPreviousPage: false,
    items: [],
    totalPages: 0,
    totalCount: 0
  },
  productImage: {
    productImageId: undefined,
  },
  productImageRequest: {
    pageSize: 10,
    pageNumber: 1,
    // status: 'Active',
    sortField: 'ProductLnfId',
    sortDirection: 'ASC'
  }
}

const getters = {
  getProductImages: state => {
    return state.productImages;
  },
  request: state => {
    return state.productImageRequest
  },
  getProductImageTypes: state => state.productImageTypes,
  getProductImage: state => state.productImage,
}

const actions = {
  get({ state, commit }) {
    api.get(`${state.moduleName}`, { params: state.productImageRequest }).then(result => commit("SET_PRODUCTIMAGES", result));
  },
  getById({ state, commit }) {
    api.get(`${state.moduleName}/${state.currentProductId}`).then(result => commit("SET_PRODUCTIMAGE_FROM_REQUEST", result));
  },
  update({ state, commit }) {
    api.put(`${state.moduleName}/${state.currentProductId}`, state.productImage).then(result => commit("SET_PRODUCTIMAGE_FROM_REQUEST", result));
  },
  create({ state, commit }) {
    api.post(`${state.moduleName}`, state.productImage)
    .then(result => {
      console.log('Got result from productImage post', result);
    })
    .catch(error => {
      console.error('Could not post product image:', error);
    });
  },
  createStockItemImage({ state, commit }, stockItemImage) {
    api.post(`${state.moduleName}`, stockItemImage)
    .then(result => {
      console.log('Posted image!', result);
    })
    .catch(error => console.error(error));
  },
  delete({ state, commit }) {
    api.delete(`${state.moduleName}/${state.currentProductImageId}`).then(result => result);
  },
  getTypes({ state, commit }) {
    api.post(`${state.moduleName}/types`, {}).then(result => commit("SET_PRODUCTIMAGES_TYPES", result));
  }
}

const mutations = {
  SET_RESULTS(state, data) {
    state.searchResults = data;
  },
  SET_PAGE_NUMBER(state, data) {
    state.productImageRequest.pageNumber = data;
  },
  SET_PAGE_SIZE(state, data) {
    state.productImageRequest.pageSize = data;
  },
  SET_PRODUCTIMAGE_FROM_REQUEST(state, data) {
    state.productImage = data.data;
  },
  SET_PRODUCTIMAGE(state, data) {
    console.log(data);
    state.productImage = Object.assign({}, data);
    console.log('Product image is:', state.productImage);
  },
  SET_PRODUCTIMAGES(state, data) {
    state.productImages = data.data;
    Vue.set(state.productImages, 'items', data.data.items);
  },

  SET_PRODUCTIMAGES_TYPES(state, data) {
    state.productImageTypes = data.data;
    Vue.set(state.productImageTypes, 'items', data.data.items);
  },

  SET_CURRENT_PRODUCTIMAGE_ID(state, data) {
    state.currentProductId = data;
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}