export default [
   {
      objectID: 2,
      image: "/static/images/men/00017619033008-3.jpg",
      name: "Opera Prima - Tempranillo",
      category: "Wine",
      price: "8.99"
   },
   {
      objectID: 1,
      image: "/static/images/men/3-item-a.jpg",
      name: "El Afan Tequila Blanco",
      category: "Tequila",
      price: "18.75"
   },
   {
      objectID: 91,
      image: "/static/images/men/1-item-a.jpg",
      name: "Don Simon Sangria",
      category: "Sangria",
      price: "18.75"
   },
   {
      objectID: 92,
      image: "/static/images/men/5-item-a.jpg",
      name: "John L. Sullivan Irish Whiskey",
      category: "Whiskey",
      price: "32.99"
   },
   {
      objectID: 3,
      image: "/static/images/men/4-item-a.jpg",
      name: "Elana Strawberry Moscato",
      category: "Wine",
      price: "9.46"
   },
   {
      objectID: 8,
      image: "/static/images/women/6-item-a.jpg",
      name: "Hennessey Cognac",
      category: "Spirits",
      price: "46.00"
   },
   {
      objectID: 890,
      image: "static/images/cat-shoes.jpg",
      name: "Anciano Tempranillo 3 Years",
      category: "Wine",
      price: "23.99"
   },
   {
      objectID: 891,
      image: "/static/images/accesories/a-2-a.jpg",
      name: "Opera Prima OP Cabernet Sauvignon",
      category: "Wine",
      price: "29.99"
   },
   {
      objectID: 892,
      image: "/static/images/gadgets/g-2-a.jpg",
      name: "Angeline Cabernet Sauvignon California, 2017",
      category: "Wine",
      price: "14.99"
   },
   {
      objectID: 893,
      image: "/static/images/gadgets/g-1-a.jpg",
      name: "Cantina Casteggio Pinot Grigio",
      category: "Wine",
      price: "17.95"
   },
   {
      objectID: 894,
      image: "/static/images/women/10-item-a.jpg",
      name: "Love Strawberry Sparkling Wine",
      category: "Wine",
      price: "6.99"
   },
   {
      objectID: 895,
      image: "/static/images/accesories/a-3-a.jpg",
      name: "Castillo San Simon Cabernet Sauvignon",
      category: "Wine",
      price: "10.28"
   },
]