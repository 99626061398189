<template>
  <div class="gadget-content">

    <emb-page-title
            :heading="'My Account'"
            imageType="my-account"/>

    <div class="container grid-list-xl my-10 py-0">
      <accounts-view
        v-if="currentAccount"
        :accountId="currentAccount.internalId"
        :readOnly="true"
        :isClient="true">
      </accounts-view>
    </div>
  </div>
</template>

<script>
import AccountsView from "../AdminPanel/Accounts/AccountsForm.vue";
import { mapGetters } from 'vuex';

export default {

  data: () => ({

  }), // data

  mounted() {
    
  }, // mounted

  computed: {
    ...mapGetters({
      currentAccount: 'session/getAccount',
    }),
  }, // computed

  components: {
    AccountsView
  }, // components
};
</script>
