<template>
  <div>
    <div class="main">
      <div class="banner-wrap">
        <emb-home-main-banner-v2
          :data="homeBannerV2Data"
        ></emb-home-main-banner-v2>
      </div>

      <!-- categories -->
      <card-items :data="cardData">
      </card-items><!-- categories -->

      <!-- products by promoted tags -->
      <section>
        <!-- loading tags -->
        <div v-if="!promotedTags">
          <!--<v-progress-linear indeterminate/>
					<p class="text-center">Loading promotions</p>-->
        </div>
        <!-- loading tags -->

        <!-- loading products -->
        <div v-else-if="promotedTags && !productsByPromotedTags">
          <div
            v-for="tag in promotedTags"
            :key="tag.stockItemTagId"
            class="mb-16"
          >
            <lightening-deals
              :title="tag.stockItemTagName"
              :isLoading="true"
            ></lightening-deals>
          </div>
        </div>
        <!-- loading products -->

        <!-- products -->
        <div v-else-if="promotedTags && productsByPromotedTags">
          <div
            v-for="tagName in Object.keys(productsByPromotedTags)"
            :key="tagName"
          >
            <div
              v-if="
                productsByPromotedTags[tagName].items &&
                productsByPromotedTags[tagName].items.length > 0
              "
            >
              <lightening-deals
                :title="tagName"
                :items="productsByPromotedTags[tagName].items"
                v-on:view-all="onViewAllClicked"
                v-on:item-clicked="onItemClicked"
                v-on:category-clicked="onCategoryClicked"
              >
              </lightening-deals>
            </div>
          </div>
        </div>
        <!-- products -->
      </section>
      <!-- products by promoted tags -->
      <div class="top-brands-wrap-v1">
        <emb-shop-card
          class="pt-16"
          secTitle="Our Brands"
          :data="topBrandsData"
        ></emb-shop-card>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

// layout components
import HomeMainBannerV2 from "Components/Layouts/Banner/HomeMainBannerV2";
import HomeMainBannerV3 from "Components/Layouts/Banner/HomeMainBannerV3";

// data
import homeBannerV2Data from "Assets/data/homeBannerV2Data";
import cardData from "Assets/data/cardData";
import LighteningDealsData from "Assets/data/lighteningDealsData";
import topProductData from "Assets/data/topProductData";
import topBrandsData from "Assets/data/topBrandsData";

// widgets
import CardItems from "Components/Widgets/CardItems";
import LighteningDeals from "Components/Widgets/LighteningDeals";
import TopProducts from "Components/Widgets/TopProducts";

import ShopCard from "Components/Widgets/ShopCard";

import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      homeBannerV2Data,
      cardData,
      LighteningDealsData,
      topProductData,
      topBrandsData,
      promotedTags: undefined,
      productsByPromotedTags: undefined,
    };
  }, // data

  computed: {
    ...mapGetters(["selectedProduct"]),
    ...mapGetters({
      tagsList: "tag/getTagsList",
    }),
  }, // computed

  async mounted() {
    this.promotedTags = (await this.getPromotedTags()).data;
    this.productsByPromotedTags = (await this.getPromotedProducts()).data;
  }, // mounted

  methods: {
    ...mapActions({
      getPromotedProducts: "search/getPromoted",
      getPromotedTags: "tag/getListPromoted",
    }),

    onViewAllClicked(title) {
      this.$router.push(`/products?tag=${title}`);
    },

    onItemClicked(item) {
      let category = item.categories;
      let id = item.id;
      this.$router.push(`/products/${category}/${id}`);
    },

    onCategoryClicked(category) {
      this.$router.push(`/products/${category}`);
    },
  }, // methods

  components: {
    embHomeMainBannerV2: HomeMainBannerV2,
    embHomeMainBannerV3: HomeMainBannerV3,
    cardItems: CardItems,
    lighteningDeals: LighteningDeals,
    topProducts: TopProducts,
    embShopCard: ShopCard,
    
  }, // components
};
</script>
