var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          staticClass: "pa-4",
          attrs: { "max-width": "500px" },
          model: {
            value: _vm.isDialogOpened,
            callback: function($$v) {
              _vm.isDialogOpened = $$v
            },
            expression: "isDialogOpened"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "div",
                { staticClass: "d-flex align-center px-5" },
                [
                  _c("v-icon", { attrs: { size: "40" } }, [_vm._v("poll")]),
                  _c(
                    "div",
                    [
                      _c("v-card-title", { staticClass: "h4" }, [
                        _vm._v(_vm._s(_vm.selectedForm))
                      ]),
                      _c("v-card-subtitle", [
                        _c("b", [_vm._v("4")]),
                        _vm._v(" Submissions. Created "),
                        _c("i", [_vm._v("July 30, 2020")])
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c("h6", { staticClass: "ma-0" }, [_vm._v("Submissions")]),
                  _c(
                    "v-list",
                    _vm._l(
                      [
                        "Today, 1:24 PM",
                        "Yesterday, 11:30 AM",
                        "Tuesday, 9:57 AM",
                        "July 9, 5:24 PM"
                      ],
                      function(submission) {
                        return _c(
                          "v-list-item",
                          {
                            key: submission,
                            attrs: { dense: "" },
                            on: {
                              click: function($event) {
                                _vm.isDialogOpened = true
                              }
                            }
                          },
                          [
                            _c("v-list-item-title", [
                              _vm._v(_vm._s(submission))
                            ]),
                            _c(
                              "v-list-item-icon",
                              [_c("v-icon", [_vm._v("navigate_next")])],
                              1
                            )
                          ],
                          1
                        )
                      }
                    ),
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-3",
                      attrs: { color: "primary" },
                      on: {
                        click: function($event) {
                          _vm.isDialogOpened = false
                        }
                      }
                    },
                    [_vm._v("Close")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error" },
                      on: { click: _vm.newFormSubmission }
                    },
                    [_vm._v("New Submission")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          model: {
            value: _vm.isSubmittingForm,
            callback: function($$v) {
              _vm.isSubmittingForm = $$v
            },
            expression: "isSubmittingForm"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("div", { staticClass: "pb-12" }, [
                _c("iframe", {
                  style: {
                    width: "100%",
                    height: _vm.formHeight
                  },
                  attrs: {
                    id: _vm.currentFormId,
                    src: "https://form.jotform.com/" + _vm.currentFormId,
                    frameborder: "0"
                  }
                })
              ]),
              _c(
                "v-footer",
                { staticClass: "d-flex justify-end", attrs: { absolute: "" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "my-1",
                      attrs: { color: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.closeFormSubmission(_vm.currentFormId)
                        }
                      }
                    },
                    [_vm._v("Close")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("v-select", {
        attrs: {
          placeholder: "Select a form type",
          label: "Form Type",
          items: Array.from(_vm.formTypes.keys())
        },
        model: {
          value: _vm.selectedFormType,
          callback: function($$v) {
            _vm.selectedFormType = $$v
          },
          expression: "selectedFormType"
        }
      }),
      _c(
        "v-list",
        _vm._l(_vm.formTypes.get(_vm.selectedFormType), function(form) {
          return _c(
            "v-list-item",
            {
              key: form,
              on: {
                click: function($event) {
                  _vm.isDialogOpened = true
                  _vm.selectedForm = form
                }
              }
            },
            [
              _c("v-list-item-title", [_vm._v(_vm._s(form))]),
              _c(
                "v-list-item-icon",
                [_c("v-icon", [_vm._v("navigate_next")])],
                1
              )
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }