<template>
    <v-app>
        <router-view></router-view>

        

    </v-app>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            isFabOpened: false,
        };
    }, // data

    computed: {
        ...mapGetters({
            isFabVisible: 'fabmenu/getIsFabVisible',
        }),

        mobileMenu: {
            get() {
                return this.$store.getters.sidebarOpen;
            },
            set(val) {
                this.$store.dispatch("toggleSidebar", val);
            }
        }
    }, // computed

    mounted() {
        this.initSocialIntentsChat();
    }, // mounted

    methods: {
        initSocialIntentsChat() {
            console.log('initSocialIntentsChat()');

            let socialIntentsLiveChatScript = document.createElement('script');
            socialIntentsLiveChatScript.setAttribute('src', '//www.socialintents.com/api/socialintents.1.3.js#2c9fa23c71582b2e017160316f2911d1');
            socialIntentsLiveChatScript.setAttribute('async', 'async');
            document.head.appendChild(socialIntentsLiveChatScript);

            let currentPersonStr = sessionStorage.getItem('fb.person');
            let currentPerson = currentPersonStr? JSON.parse(currentPersonStr) : {};

            localStorage.setItem('fb.isFabVisible', 'true');
            sessionStorage.setItem('si_name', `${currentPerson.firstName} ${currentPerson.lastName}`);
            sessionStorage.setItem('si_email', currentPerson.emailAddress);
            sessionStorage.setItem('si_phone', currentPerson.phoneNumber);
            sessionStorage.setItem('si_group', '');
            sessionStorage.setItem('si_question', '');
        },

        openSocialMedia(url) {
            /*
            Favorite Brands
                www.facebook.com/favoritebrands01
                www.instagram.com/favoritebrands
            
            L&F
                www.twitter.com/lnfdistributors
                www.facebook.com/lnfmcallen
                www.facebook.com/lnfharlingen
                www.facebook.com/lnflaredo
                www.facebook.com/lnfcorpus
                www.facebook.com/lnfdistbeeville
                www.facebook.com/lnfdistributorsbigbend
                www.facebook.com/elpaso
                www.facebook.com/lnfroswell
                www.instagram.com/lnfmcallen
                www.instagram.com/lnfharlingen
                www.instagram.com/lnflaredo
                www.instagram.com/lnfcorpus
                www.instagram.com/lnfelpaso
                www.instagram.com/lnfroswell
            */
            let win = window.open(url, '_blank');
            win.focus();
        },
    }, // methods
};
</script>

<style>
.app-border-red {
    border: 2px solid red;
}

.app-border-green {
    border: 2px solid green;
}

.app-border-blue {
    border: 2px solid blue;
}

.cursor-pointer:hover {
    cursor: pointer;
}

.chat-desktop {
    background-color: whitesmoke;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 340px;
    height: 563px;
}

/* Social Inents chat FAB*/
.silc-btn {
    left: 16px;
    bottom: 0px;
    z-index: 1;
}

/* Social Intents chat popup */
#siWidget-chat {
    left: 16px;
}

.center-x {
    display: flex;
    justify-content: center;
}
</style>
