<template>
  <!-- eslint-disable -->
  <div class="emb-privacy-policy-wrap">

    <!-- header -->
    <emb-page-title
      heading="Privacy Policy"
      subHeading="Your Information is fully secure with us.">
    </emb-page-title>

    <!-- container -->
    <div class="section-gap bg-white">
      <div class="container">
        <div class="privacy-page">

          <!-- main paragraphs -->
          <section class="mb-8">
            <p>
              This Privacy Policy describes how L&F (“<strong>L&F</strong>,” “<strong>we</strong>” or “<strong>us</strong>”) collects, uses and shares personal information, such as your name, address, phone number and email address.
              Use of L&F’s websites and apps (the “<strong>Service</strong>”) is subject to this Privacy Policy and our Terms of Service. Read them carefully to understand our practices and your options.
            </p>

            <p>
              We will notify you by email or through the Service before making significant changes to this Privacy Policy. If you have questions, please contact us at ______________.
            </p>

            <p>
              The Service is for persons 21 years of age and older. Do not use the Service or send us information if you are under 21. We do not knowingly collect information about anyone
              under 21, and if we discover that we have personal information about somebody under 21, we will delete it as soon as possible.  If you think we have personal information
              about somebody under 21, please contact us as soon as possible at ______________. Our Terms of Service have more information on age verification and eligibility to use our Service.
            </p>
          </section><!-- main paragraphs -->

          <!-- info we collect about you -->
          <section class="mb-8">

            <h4>INFORMATION WE COLLECT ABOUT YOU</h4>

            <p v-for="item of infoWeCollect">
              <span style="text-decoration: underline;">{{item.title}}</span> {{item.text}}
            </p>

          </section><!-- info we collect about you -->

          <!-- how we use yor info -->
          <section class="mb-8">

            <h4>HOW WE USE YOUR INFORMATION</h4>

            <p>{{howWeUseYourInfo.headline}}</p>

            <ul>
              <li v-for="item of howWeUseYourInfo.items">
                {{item}}
              </li>
            </ul>

          </section><!-- how we use yor info -->

          <!-- how we share your info -->
          <section class="mb-8">

            <h4>{{howWeShareYourInfo.title}}</h4>

            <p>{{howWeShareYourInfo.headline}}</p>

            <p v-for="item of howWeShareYourInfo.items">
              <span style="text-decoration: underline;">{{item.title}}</span> {{item.text}}
            </p>

          </section><!-- how we share your info -->


          <!-- other important notifices -->
          <section class="mb-8">

            <h4>{{otherImportantNotices.title}}</h4>

            <p v-for="item of otherImportantNotices.items">
              <span style="text-decoration: underline;">{{item.title}}</span> {{item.text}}
            </p>

          </section><!-- other important notifices -->

          <h6 class="text-center">
            LAST UPDATED <strong>{{lastUpdated}}</strong>
          </h6>

        </div>
      </div>
    </div><!-- container -->

  </div>
</template>

<script>
/*eslint-disable */

export default {
  data: () => ({
    infoWeCollect: [
      {
        title: "Information You Give Us",
        text: "L&F collects the information you give us when you sign up for and use the Service, such as your name, email address, account password, date of birth, billing information, phone number, delivery address and social media IDs.",
      },
      {
        title: "Tracking Information and Cookies",
        text: "We collect tracking information when you use the Service, such as your device and browser type, location, page views, IP address, referring/exit pages, and information about how you interact with the Service and third-party links. (Third parties may also collect this information.) This information may be collected with a cookie, a small file from a web server that is stored in your browser. Your browser can be set to block cookies, but some features of the Service may not work. Your browser may also have a “do not track” feature that tells websites not to track your online activities. Our Service does not respond to this feature.",
      },
      {
        title: "Precise Location Data",
        text: "The Service collects your precise geographical location on some devices, such as smartphones.  You can opt out of this through the settings on your device or in the App, but it may limit some features of the Service.",
      },
      {
        title: "Information from Other Sources",
        text: "L&F may obtain information about you from other sources, including retailers and other companies, social media, and public records.  If you use Facebook or another third party to login to the Service, we’ll receive information about you from them.",
      },
    ],

    howWeUseYourInfo: {
      headline: "We use the information we collect from you to do things like:",
      items: [
        "provide and improve the Service;",
        "personalize your Service experience;",
        "let you rate your purchases and view your purchase history;",
        "obtain additional information about you from third parties;",
        "notify you of new services and special offers;",
        "help you share a product review or join a rewards program;",
        "run sweepstakes and contests;",
        "contact you through social media;",
        "troubleshoot problems with the Service;",
        "notify you of payment, billing, maintenance, and other account-related issues;",
        "provide customer service;",
        "investigate and address fraud; and",
        "create trend and preference information based on your use of the Service.",
      ],
    },

    howWeShareYourInfo: {
      title: "HOW WE SHARE YOUR INFORMATION",
      headline: "We share information collected from or about you as follows:",
      items: [
        {
          title: "Aggregated and Anonymized Data.",
          text: "L&F may share or sell trend and preference information based on your use of the Service, individually and with the information of other users. This information can be used for analysis, to develop consumer insights, and to deliver advertising about other products and services. We will first remove anything that personally identifies you.",
        },
        {
          title: "Retailers.",
          text: "We share information such as your name, payment information, address, purchase history and delivery location with retailers so they can fulfill your orders through the Service. Your information may also be shared with shipping and delivery companies.",
        },
        {
          title: "Service Providers.",
          text: "We use other companies and individuals to help us run the Service and support customers. These service providers may use your personal information to perform their work.",
        },
        {
          title: "Third-Party Analytics.",
          text: "We use analytics service providers to monitor and improve the Service. Opting out may limit the functionality of the Service.",
        },
        {
          title: "Law Enforcement.",
          text: "We may share information about you to protect legal rights and public and personal safety, to stop illegal or unethical activity, or as required by law.",
        },
        {
          title: "Acquisition.",
          text: "If all or a part of L&F is sold or transferred, or a similar event occurs, a third-party may receive your personal information. If they will use your personal information contrary to this Privacy Policy, you will receive prior notice.",
        },
      ],
    },

    otherImportantNotices: {
      title: "OTHER IMPORTANT NOTICES",
      items: [
        {
          title: "Updating Your Personal Information and Preferences; Opting Out.",
          text: "You can update your account information and preferences by logging into your account. To opt out of text or email marketing from us, follow the instructions in the text or email. This opt out does not apply to account-related notifications, which are required in order to use the Service. To stop account-related notifications, send us a request to deactivate your account.",
        },
        {
          title: "Retention of Personal Information.",
          text: "L&F may keep your personal information as long as needed for the purposes in this Privacy Policy unless a longer period is required or permitted by law.",
        },
        {
          title: "Payment/Credit/Debit Card Transactions.",
          text: "The retailer fulfilling your order processes your payment, but we may collect the type, last four digits and expiration date of your credit or debit card number.",
        },
        {
          title: "Invitations to L&F.",
          text: "If you invite someone to use the Service, you will need to provide their name and email address. L&F stores this information to send an invitation to them and track the results of the invitation referral.  You may not provide the name or email address of anyone under 21 years of age.",
        },
        {
          title: "Public Forums.",
          text: "Our Service includes public blogs and forums. To request removal of any personal information that appears in blogs or forums operated by L&F, contact us at __________. If we can reasonably remove your personal information, we will, but we are not responsible for personal information or other information you post in public blogs and forums.  If we cannot remove your personal information, we will let you know why.",
        },
        {
          title: "Security.",
          text: "We use standard security practices such as encryption and firewalls to protect the information we collect from you. No security system is perfect, and we do not guarantee the security of your information. You are responsible for all actions taken in the name of your account, so use your discretion when providing information and managing your account. Use unique numbers, letters and special characters in your password and do not disclose it to anyone. Please review our Terms of Service for additional information. If your password is compromised notify us immediately at ____________ and change your password.",
        },
      ],
    },

    lastUpdated: "September 8, 2020",
  }), // data
};
</script>
