<template>
    <div class="table-responsive" v-if="paginatedList">
        <v-data-table
            :headers="headers"
            :items="paginatedList.items"
            :server-items-length="paginatedList.totalCount"
            :options.sync="listViewOptions"
            :loading="isLoading"
            :footer-props="{ 'items-per-page-options': [10, 20, 30, 40, 50] }">

            <template v-slot:item.action="{ item }">
                <v-btn small icon class="primary--text" :to="'/admin-panel/product-edit/' + item.stockItemId">
                    <v-icon>edit</v-icon>
                </v-btn>
                <v-btn small icon class="primary--text" :to="'/admin-panel/product-view/' + item.stockItemId">
                    <v-icon>visibility</v-icon>
                </v-btn>
            </template>

        </v-data-table>
    </div>
</template>

<script>
export default {

    props: ['paginatedList', 'isLoading', 'searchParams'],

    data() {
        return {
            headers: [
                {
                    text: "Name",
                    sortable: true,
                    value: "stockItemName",
                },
                {
                    text: "Type",
                    sortable: true,
                    value: "type",
                },
                {
                    text: "Sub Type",
                    sortable: true,
                    value: "subType",
                },
                {
                    text: "Style",
                    sortable: true,
                    value: "style",
                },
                {
                    text: "Action",
                    sortable: false,
                    value: "action",
                },
            ],

            listViewOptions: {
                page: 1,
                itemsPerPage: 10,
            },
        };
    }, // data

    watch: {
        listViewOptions: {
            handler() {
                this.searchParams.pageNumber = this.listViewOptions.page;
                this.searchParams.pageSize = this.listViewOptions.itemsPerPage;

                let sortField = this.listViewOptions.sortBy[0];
                let sortDesc = this.listViewOptions.sortDesc[0];
                
                if (sortField) {
                    this.searchParams.sortField = sortField;
                    this.searchParams.sortDirection = sortDesc? 'DESC' : 'ASC';
                }

                this.$emit('search-params-changed', this.searchParams);
            },
        },
    }, // watch
};
</script>
