/* eslint-disable */

export const cart = {
    methods: {
        getOrderLinesGroupedByMixAndMatch(order) {

            const specialDeals = this.getOrderSpecialDeals(order);
            console.log("specialDeals:", specialDeals);

            const groupedOrderLinesBySpecialDeals = new Map(); // key = dealDescription: string, value = { minQuantity: number, orderLines: orderLine[] }

            for (const orderLine of order.orderLines) {
                for (const deal of specialDeals) {
                    const dealDescription = deal.dealDescription;

                    if (this.orderLineHasSpecialDeal(orderLine, dealDescription)) {
                        const currentGroupedOrderLines = groupedOrderLinesBySpecialDeals.has(dealDescription)
                            ? groupedOrderLinesBySpecialDeals.get(dealDescription)
                            : {
                                dealDescription: dealDescription,
                                minimumQuantities: [],
                                orderLines: [],
                                specialDealId: deal.specialDealId,
                                unitPrice: deal.unitPrice,
                            };

                        currentGroupedOrderLines.minimumQuantities = [...new Set([...currentGroupedOrderLines.minimumQuantities, deal.minimumQuantity])];

                        const orderLineIsAlreadyIncluded = currentGroupedOrderLines.orderLines.some(orderLineFromGroup => orderLineFromGroup.orderLineId == orderLine.orderLineId);
                        if (!orderLineIsAlreadyIncluded) {
                            currentGroupedOrderLines.orderLines.push(orderLine);
                        }

                        groupedOrderLinesBySpecialDeals.set(dealDescription, currentGroupedOrderLines);
                    }
                }
            }

            return groupedOrderLinesBySpecialDeals;
        },

        getOrderSpecialDeals(order) {
            if (!order) {
                return [];
            }

            if (!order.orderLines) {
                return [];
            }

            const specialDeals = [];

            for (const orderLine of order.orderLines) {
                const currentSpecialDealsArray = orderLine.stockItem.specialDeals;

                for (const currentSpecialDeal of currentSpecialDealsArray) {
                    specialDeals.push(currentSpecialDeal);
                }
            }

            return specialDeals;
        },

        getOrderSpecialDealsDescriptions(order) {
            const specialDeals = this.getOrderSpecialDeals(order);
            const dealsDescriptions = specialDeals.map(deal => deal.dealDescription);
            return [...new Set(dealsDescriptions)];
        },

        getMinimumQuantitiesBySpecialDeals(order) {
            const specialDeals = this.getOrderSpecialDeals(order);
            const minimumQuantities = new Map();

            for (const deal of specialDeals) {
                const currentMinimumQuantities = minimumQuantities.has(deal.dealDescription)
                    ? minimumQuantities.get(deal.dealDescription)
                    : {
                        dealDescription: deal.dealDescription,
                        specialDealId: deal.specialDealId,
                        minimumQuantities: [],
                    };

                currentMinimumQuantities.minimumQuantities.push(deal.minimumQuantity);
                minimumQuantities.set(deal.dealDescription, currentMinimumQuantities);
            }

            return minimumQuantities;
        },

        orderLineHasSpecialDeal(orderLine, dealDescription) {
            return orderLine.stockItem.specialDeals.some(deal => deal.dealDescription == dealDescription);
        },
    }, // methods
};
