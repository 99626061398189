/* eslint-disable */

import api from "@/api";

export default {
    namespaced: true,

    state: {
        moduleName: 'order',
        orders: {},
        currentOrder: {
            orderLines: [],
        },
        searchParams: {
            pageSize: 10,
            pageNumber: 1,
            sortField: 'LastEditedWhen',
            sortDirection: 'DESC',
            searchValue: undefined, // searchValue should be orderId
        },
    }, // state

    getters: {
        getOrders: state => state.orders,
        getCurrentOrder: state => state.currentOrder,
        getSearchParams: state => state.searchParams,
    }, // getters

    mutations: {
        SET_ORDERS(state, ordersPaginatedList) {
            state.orders = ordersPaginatedList;
        },
        SET_CURRENT_ORDER(state, data) {
            state.currentOrder = data;
        },
        SET_SEARCH_PARAM_PAGE_SIZE(state, pageSize) {
            state.searchParams.pageSize = pageSize;
        },
        SET_SEARCH_PARAM_PAGE_NUMBER(state, pageNumber) {
            state.searchParams.pageNumber = pageNumber;
        },
        SET_SEARCH_PARAM_SEARCH_VALUE(state, searchValue) {
            state.searchParams.searchValue = searchValue;
        },
        SET_SEARCH_PARAM_SORT_FIELD(state, sortField) {
            state.searchParams.sortField = sortField;
        },
        SET_SEARCH_PARAM_SORT_DIRECTION(state, sortDirection) {
            state.searchParams.sortDirection = sortDirection;
        },
        SET_SEARCH_PARAMS(state, searchParams) {
            state.searchParams = searchParams;
        },
        RESET_SEARCH_PARAMS(state) {
            state.searchParams.pageSize = 10;
            state.searchParams.pageNumber = 1;
            state.searchParams.sortField = 'LastEditedWhen';
            state.searchParams.sortDirection = 'DESC';
            state.searchParams.searchValue = undefined;
        },
    }, // mutations

    actions: {
        get({ state, commit }) {
            //return api.get(`${state.moduleName}`, { params: state.searchParams })
            return api.get(`${state.moduleName}/admin`, { params: state.searchParams })
            .then(result => {
                // For some strange reason, sometimes the request will respond with a string instead of a json
                // Maybe it has to do with the length of the response.
                // To avoid errors, parse into a json if response is string
                console.log('Result is', result);
                let ordersPaginatedList = (typeof result.data == 'string') ? JSON.parse(result.data) : result.data;
                console.log('Final result is', ordersPaginatedList);
                commit('SET_ORDERS', ordersPaginatedList);
            });
        },
    
        getById({ state, commit }, orderId) {
            return api.get(`${state.moduleName}/${orderId}`)
            .then(result => commit('SET_CURRENT_ORDER', result.data));
        },

        update({ state, commit }, order) {
            order = safeParse(order);

            let orderRequest = {
                orderId: order.orderId,
                isOrderFinalized: order.isOrderFinalized,
                orderLines: order.orderLines, // maybe LocationId and StockItemHoldingId are missing in the order lines
            };

            console.log('updateOrder', orderRequest);
            
            return api.put(`${state.moduleName}/${orderRequest.orderId}`, orderRequest)
            .then(result => {
                console.log('Updated order!', result);
                //commit('SET_CURRENT_ORDER', result.data);
            });
        },
    }, // actions

};

const safeParse = (order) => {
    order.orderLines = order.orderLines.map(orderLine => {
        orderLine.quantity = Number(orderLine.quantity);
        return orderLine;
    });
    return order
}
