var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "shop-content-wrap" },
    [
      _c(
        "v-flex",
        {
          attrs: {
            "d-none": "",
            "d-xs-none": "",
            "d-sm-none": "",
            "d-md-flex": ""
          }
        },
        [
          _c("emb-page-title", {
            attrs: { heading: "My Top Products", imageType: "my-top-products" }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "gadget-content section-gap" },
        [
          _c(
            "v-container",
            { attrs: { "grid-list-xl": "", "py-0": "" } },
            [
              _c(
                "v-layout",
                {
                  attrs: {
                    row: "",
                    wrap: "",
                    "d-flex": "",
                    "align-center": "",
                    "justify-center": ""
                  }
                },
                [
                  _c(
                    "v-flex",
                    {
                      attrs: { xs12: "", sm12: "", md8: "", lg9: "", xl9: "" }
                    },
                    [
                      _c(
                        "v-row",
                        _vm._l(_vm.products.items, function(product, index) {
                          return _c(
                            "v-col",
                            {
                              key: index,
                              attrs: { cols: "12", sm: "6", md: "4", lg: "3" }
                            },
                            [
                              _c("product-item", {
                                attrs: { product: product }
                              })
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }