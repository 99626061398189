var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "pa-4 mb-4" },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                {
                  staticClass:
                    "d-flex align-center justify-center justify-sm-start",
                  attrs: { cols: "12", sm: "3" }
                },
                [
                  _c("v-icon", { staticClass: "mr-1" }, [_vm._v("label")]),
                  _c("h5", { staticClass: "ma-0" }, [_vm._v("Tags")]),
                  _c(
                    "v-btn",
                    {
                      staticClass: "d-sm-none",
                      attrs: { color: "error", icon: "" },
                      on: {
                        click: function($event) {
                          _vm.isAddingNewTag = true
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("add")])],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "d-flex justify-center align-center",
                  attrs: { cols: "12", sm: "6" }
                },
                [
                  _c("v-text-field", {
                    staticClass: "mr-4",
                    attrs: { label: "Search Tags" },
                    on: { change: _vm.executeSearch },
                    model: {
                      value: _vm.searchValue,
                      callback: function($$v) {
                        _vm.searchValue = $$v
                      },
                      expression: "searchValue"
                    }
                  }),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: { click: _vm.executeSearch }
                    },
                    [_vm._v("Search")]
                  )
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "d-flex justify-end align-center",
                  attrs: { cols: "12", sm: "3" }
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "d-none d-sm-flex",
                      attrs: { color: "error" },
                      on: {
                        click: function($event) {
                          _vm.isAddingNewTag = true
                        }
                      }
                    },
                    [_vm._v(" Add New "), _c("v-icon", [_vm._v("add")])],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("tags-list-view", {
        attrs: {
          isLoading: _vm.isLoading,
          paginatedList: _vm.tagsPaginatedList,
          searchParams: _vm.searchParams
        },
        on: { "search-params-changed": _vm.onSearchParamsChanged }
      }),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "400" },
          model: {
            value: _vm.isAddingNewTag,
            callback: function($$v) {
              _vm.isAddingNewTag = $$v
            },
            expression: "isAddingNewTag"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-text",
                [
                  _c("v-card-title", [_vm._v("Add a new tag")]),
                  _c("v-text-field", {
                    staticClass: "ma-0 pa-0",
                    attrs: { label: "Tag name", rules: _vm.requiredRule },
                    model: {
                      value: _vm.newTag.stockItemTagName,
                      callback: function($$v) {
                        _vm.$set(_vm.newTag, "stockItemTagName", $$v)
                      },
                      expression: "newTag.stockItemTagName"
                    }
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "d-flex justify-space-between align-center"
                    },
                    [
                      _c("p", { staticClass: "ma-0 pa-0" }, [
                        _vm._v("Promoted")
                      ]),
                      _c("v-switch", {
                        model: {
                          value: _vm.newTag.isTagPromoted,
                          callback: function($$v) {
                            _vm.$set(_vm.newTag, "isTagPromoted", $$v)
                          },
                          expression: "newTag.isTagPromoted"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "d-flex justify-end" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled:
                          !_vm.newTag.stockItemTagName ||
                          _vm.newTag.stockItemTagName.trim().length == 0,
                        color: "error"
                      },
                      on: {
                        click: function($event) {
                          return _vm.createTag(_vm.newTag)
                        }
                      }
                    },
                    [_vm._v(" Add ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: {
                        click: function($event) {
                          _vm.isAddingNewTag = false
                        }
                      }
                    },
                    [_vm._v("Cancel")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }