var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "emb-gadget-wrap" },
    [
      _c(
        "v-flex",
        {
          attrs: {
            "d-none": "",
            "d-xs-none": "",
            "d-sm-none": "",
            "d-md-flex": ""
          }
        },
        [
          _c("emb-page-title", {
            attrs: { heading: _vm.pageTitle, imageType: "products" }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "gadget-content section-gap" },
        [
          _c(
            "v-container",
            [
              _c("v-alert", { attrs: { type: "error" } }, [
                _vm._v(" Discounts may be applied at checkout ")
              ]),
              _c(
                "div",
                { staticClass: "d-flex align-center mb-4" },
                [
                  _c("Filters", {
                    attrs: {
                      disabled: _vm.isLoadingProducts || _vm.errorMessage
                    },
                    on: {
                      "page-size-changed": _vm.onPageSizeChanged,
                      "price-sorting-changed": _vm.onPriceSortingChanged
                    }
                  })
                ],
                1
              ),
              _c("SearchResultsGrid", {
                attrs: {
                  errorMessage: _vm.errorMessage,
                  isLoadingProducts: _vm.isLoadingProducts,
                  pageSize: _vm.pageSize,
                  productsPaginatedList: _vm.productsPaginatedList
                },
                on: { "try-again": _vm.onTryAgain }
              }),
              _vm.productsPaginatedList
                ? _c("Pager", {
                    staticClass: "mt-4",
                    attrs: {
                      pageIndex: _vm.pageIndex,
                      totalPageCount: _vm.totalPageCount
                    },
                    on: { "page-changed": _vm.onPageIndexChanged }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }