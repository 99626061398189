var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "shop-content-wrap" },
    [
      _c(
        "div",
        { staticClass: "shop-header" },
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "", "my-0": "", "align-center": "" } },
            [
              _c(
                "v-flex",
                {
                  attrs: {
                    xs12: "",
                    sm12: "",
                    md12: "",
                    lg7: "",
                    xl7: "",
                    "py-0": "",
                    "cpx-7": "",
                    "mb-4": ""
                  }
                },
                [
                  _c("div", { staticClass: "d-sm-flex" }, [
                    _c("div", { staticClass: "app-selectbox-sm" }),
                    _c("div", {
                      staticClass: "app-selectbox-sm ml-sm-4 mt-sm-0 mt-6"
                    })
                  ])
                ]
              ),
              _c(
                "v-flex",
                {
                  attrs: {
                    xs12: "",
                    sm12: "",
                    md12: "",
                    lg5: "",
                    xl5: "",
                    "cpx-7": "",
                    "py-0": "",
                    "pr-0": "",
                    "mb-4": ""
                  }
                },
                [
                  _c("div", { staticClass: "text-lg-right" }, [
                    _vm._v(
                      " " + _vm._s(_vm.searchResultsCount) + " results found "
                    )
                  ])
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        _vm._l(_vm.searchResults, function(item) {
          return _c(
            "v-flex",
            { key: item.itemnumber },
            [_c("product-item", { attrs: { data: item } })],
            1
          )
        }),
        1
      ),
      _c("div", { staticClass: "pagination-wrap pt-2" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }