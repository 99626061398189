var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "emb-signIn-wrap section-gap" }, [
    _c(
      "div",
      { staticClass: "container py-0" },
      [
        _c(
          "v-layout",
          {
            attrs: {
              row: "",
              wrap: "",
              "align-center": "",
              "justify-center": ""
            }
          },
          [
            _c(
              "v-flex",
              { attrs: { sm12: "", md12: "", lg8: "", xl7: "" } },
              [
                _c(
                  "v-layout",
                  {
                    attrs: {
                      row: "",
                      "mx-sm-0": "",
                      "mx-3": "",
                      wrap: "",
                      "align-center": "",
                      "justify-center": ""
                    }
                  },
                  [
                    _c(
                      "v-flex",
                      {
                        attrs: {
                          sm6: "",
                          md7: "",
                          lg6: "",
                          xl6: "",
                          "hidden-sm-and-down": ""
                        }
                      },
                      [_c("div", { staticClass: "form-img sign-in-image" })]
                    ),
                    _c(
                      "v-flex",
                      { attrs: { sm10: "", md5: "", lg5: "", xl6: "" } },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "emb-card sign-in-form form-margin d-block white pa-6"
                          },
                          [
                            _c("h4", [_vm._v("User Sign In")]),
                            _c(
                              "v-form",
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    type: "email",
                                    placeholder: "Email*"
                                  }
                                }),
                                _c("v-text-field", {
                                  attrs: {
                                    type: "password",
                                    placeholder: "Password*"
                                  }
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "layout align-center justify-space-between"
                                  },
                                  [
                                    _c("v-checkbox", {
                                      attrs: { label: "Remember Me" },
                                      model: {
                                        value: _vm.checkbox,
                                        callback: function($$v) {
                                          _vm.checkbox = $$v
                                        },
                                        expression: "checkbox"
                                      }
                                    }),
                                    _c(
                                      "router-link",
                                      {
                                        staticClass: " text-lg-right",
                                        attrs: {
                                          to: "/session/forgot-password"
                                        }
                                      },
                                      [_vm._v("Forgot Password ?")]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "accent mb-3 ma-0",
                                    attrs: {
                                      large: "",
                                      to: "/checkout/payment"
                                    }
                                  },
                                  [_vm._v(" Sign In ")]
                                ),
                                _c(
                                  "p",
                                  [
                                    _vm._v("Don't Have account? "),
                                    _c(
                                      "router-link",
                                      {
                                        staticClass: "accent--text",
                                        attrs: { to: "/session/signup" }
                                      },
                                      [_vm._v("Click here to create one")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }