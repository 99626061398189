<template>
    <div class="shop-content-wrap">
        

        <!-- products container -->
        <div>

            <!-- loading products -->
            <div v-if="isLoadingProducts">
                <v-row>
                    <v-col
                        v-for="index in pageSize" :key="index"
                        cols="12" sm="6" md="4" lg="3">
                        <v-skeleton-loader type="card" height="450"/>
                    </v-col>
                </v-row>
            </div><!-- loading products -->

            <!-- loading finished -->
            <div v-else>

                <!-- error message -->
                <div v-if="errorMessage" class="my-12 py-12">
                    <h4 class="text-center font-weight-light mb-5" style="color: gray">
                        {{errorMessage}}
                    </h4>

                    <div class="d-flex justify-center mb-4">
                        <v-icon size="82" style="color: darkgray">
                            sentiment_dissatisfied
                        </v-icon>
                    </div>

                    <div class="d-flex justify-center">
                        <v-btn text color="accent" @click="onTryAgainClicked">
                            Click Here to Try Again
                        </v-btn>
                    </div>
                </div><!-- error message -->

                <!-- products loaded -->
                <div v-else>

                    <!-- no results found -->
                    <div v-if="isSearchResultEmpty" class="my-12 py-12">
                        <h4 class="text-center font-weight-light mb-5" style="color: gray">
                            Looks like there aren't any results!
                        </h4>

                        <h6 class="text-center" style="color: gray">
                            Try searching another word
                        </h6>

                        <div class="d-flex justify-center mb-4">
                            <v-icon size="82" style="color: darkgray">search</v-icon>
                        </div>
                    </div><!-- no results found -->

                    <!-- show results -->
                    <v-row v-else>
                        <v-col v-for="(product, index) in products" :key="index"
                            cols="12" sm="6" md="4" lg="3">
                            <ProductItem :product="product"/>
                        </v-col>
                    </v-row><!-- show results -->

                </div><!-- products loaded -->

            </div><!-- loading finished -->

        </div><!-- products container -->

    </div>
</template>

<script>
/* eslint-disable */

import ProductItem from "@/views/ClientPanel/Products/ProductItem";

export default {

    props: {
        errorMessage: {
            type: String,
            default: null,
        },
        isLoadingProducts: {
            type: Boolean,
            default: true,
        },
        pageSize: {
            type: Number,
            default: 8,
        },
        productsPaginatedList: {
            type: Object,
        },
    }, // props
    
    computed: {
        isSearchResultEmpty() {
            return this.productsPaginatedList.items.length == 0;
        },

        products() {
            return this.productsPaginatedList.items;
        },
    }, // computed

  methods: {
    onTryAgainClicked() {
        this.$emit("try-again");
    },
  }, // methods

  components: {
    ProductItem,
  }, // components
};
</script>
