export default [
   {
      image: "/static/images/men/2-item-a.jpg",
      total: "9 Products",
      category: "Clothing",
   },
   {
      image: "/static/images/cat-shoes.jpg",
      total: "1 product",
      category: "Shoes",
   },
   {
      image: "/static/images/accessroies/a-1-a.jpg",
      total: "4 Products",
      category: "Accessories",
   },
   {
      image: "/static/images/gadgets/g-1-a.jpg",
      total: "5 Products",
      category: "Gadgets",
   },
]