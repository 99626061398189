<template>
  <div>
    <Breadcrumbs class="mb-4" :items="breadcrumbs" />
    <!-- back button -->

    <!-- report info -->
    <section>
      <v-card class="mb-4">
        <v-card-text>
          <v-text-field label="Report Name" :disabled="isView" />

          <!-- permissions -->
          <div class="mb-6">
            <p class="ma-0 pa-0">Permissions</p>

            <v-list-item dense :disabled="isView">
              <v-checkbox label="Admin" hide-details></v-checkbox>
            </v-list-item>

            <v-list-item dense :disabled="isView">
              <v-checkbox label="Salesman" hide-details></v-checkbox>
            </v-list-item>

            <v-list-item dense :disabled="isView">
              <v-checkbox label="Employee" hide-details></v-checkbox>
            </v-list-item>

            <v-list-item dense :disabled="isView">
              <v-checkbox label="System User" hide-details></v-checkbox>
            </v-list-item>
          </div>
          <!-- permissions -->

          <drag-and-drop v-if="isAdd || isEdit" :multiple="true" />
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn v-if="isAdd" color="primary">Save</v-btn>
          <v-btn v-if="isEdit" color="primary">Edit</v-btn>
          <v-btn v-if="isEdit" color="error">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </section>
    <!-- report info -->

    <!-- view -->
    <section v-if="isView">
      <v-card v-if="false">
        <v-card-title> Report name </v-card-title>
        <v-card-text>
          <span>View report here</span>
        </v-card-text>
      </v-card>

      <v-layout class="emb-card mb-6">
        <app-card
          customClasses="pa-6"
          colClasses="xl12 lg12 md12 xs12 sm12"
          heading="Transaction History"
          :removePanel="true"
          :viewMore="true"
          :disableNotification="true"
          :settings="true"
        >
          <buy-or-sell></buy-or-sell>
        </app-card>
      </v-layout>

      <v-layout v-if="false" class="emb-card mb-6">
        <app-card
          colClasses="xl12 lg12 md12 xs12 sm12"
          contentCustomClass="pa-6"
        >
          <tabs-and-table></tabs-and-table>
        </app-card>
      </v-layout>
    </section>
    <!-- view -->
  </div>
</template>

<script>
import { BreadcrumbItem } from "../../../components/Breadcrumbs/BreadcrumbItem";
import DragAndDrop from "@/components/DragAndDrop";
import TabsAndTable from "Components/Widgets/TabsAndTable";
import BuyOrSell from "Components/Widgets/BuyOrSell";
import { StaticRoutes } from "../../../router/Routes";
export default {
  data: () => ({
    isAdd: false,
    isView: false,
    isEdit: false,
    breadcrumbs: [
      new BreadcrumbItem({
        label: "Reports",
        route: StaticRoutes.adminPanel.reports,
      }),
      new BreadcrumbItem({ label: "Report View Details" }),
    ],
  }), // data

  mounted() {
    this.isAdd = this.$route.path.includes("add");
    this.isView = this.$route.path.includes("view");
    this.isEdit = this.$route.path.includes("edit");
  }, // mounted

  components: {
    DragAndDrop,
    TabsAndTable,
    BuyOrSell,
  }, // components
};
</script>
