//=======================| App Configuration |========================//
export default {
	appLogo: '/static/images/logo.svg', 
	appLogoDark: '/static/images/logo.svg',                         // App Logo,
	brand: 'Favorite Brands',                                        		   // Brand Name
	copyrightText: '© All Rights Reserved | L&F Distributors ',     // Copyright Text
	//theme color
	
	color: {
		primary: '#3a3434',
		accent : '#e31d1a',
	},
	algoliaApiKey: '6be0576ff61c053d5f9a3225e2a90f76',
	azureSearchApiUrl: 'https://lnf-extweb-search-prod-scus.search.windows.net',
	azureSearchApiKey: 'D461F3C23FB765D2E69916A99EC63BF1'
}
