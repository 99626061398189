<template>
  <div>
    <div class="search-box">
      <v-btn
        class="d-inline-block"
        fab
        dark
        v-if="isHidden"
        color="accent"
        @click="isHidden = false"
      >
        <v-icon>search</v-icon>
      </v-btn>
    </div>

    <!-- search bar (visible) -->
    <div v-if="!isHidden" class="search-form">
      <div class="form">

        <!-- search input -->
        <input type="text" placeholder="Search Here"
          v-model="searchValue"
          @keyup.enter="onSearchTriggered" />

        <!-- close button -->
        <v-btn class="close-btn white" @click="isHidden = !isHidden">
          <v-icon>close</v-icon>
        </v-btn>

      </div>
    </div><!-- search bar (visible) -->

  </div>
</template>

<script>
export default {
  data() {
    return {
      searchValue: "",
      isHidden: true,
    };
  }, // data

  methods: {
    onSearchTriggered() {
      if (this.searchValue == undefined || this.searchValue == null) {
        return;
      }

      if (this.searchValue.trim().length == 0) {
        return;
      }
      
      this.$emit("search", this.searchValue);
    },
  },
};
</script>
