var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "shop-content-wrap" }, [
    _c("div", [
      _vm.isLoadingProducts
        ? _c(
            "div",
            [
              _c(
                "v-row",
                _vm._l(_vm.pageSize, function(index) {
                  return _c(
                    "v-col",
                    {
                      key: index,
                      attrs: { cols: "12", sm: "6", md: "4", lg: "3" }
                    },
                    [
                      _c("v-skeleton-loader", {
                        attrs: { type: "card", height: "450" }
                      })
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        : _c("div", [
            _vm.errorMessage
              ? _c("div", { staticClass: "my-12 py-12" }, [
                  _c(
                    "h4",
                    {
                      staticClass: "text-center font-weight-light mb-5",
                      staticStyle: { color: "gray" }
                    },
                    [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")]
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex justify-center mb-4" },
                    [
                      _c(
                        "v-icon",
                        {
                          staticStyle: { color: "darkgray" },
                          attrs: { size: "82" }
                        },
                        [_vm._v(" sentiment_dissatisfied ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex justify-center" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "", color: "accent" },
                          on: { click: _vm.onTryAgainClicked }
                        },
                        [_vm._v(" Click Here to Try Again ")]
                      )
                    ],
                    1
                  )
                ])
              : _c(
                  "div",
                  [
                    _vm.isSearchResultEmpty
                      ? _c("div", { staticClass: "my-12 py-12" }, [
                          _c(
                            "h4",
                            {
                              staticClass: "text-center font-weight-light mb-5",
                              staticStyle: { color: "gray" }
                            },
                            [_vm._v(" Looks like there aren't any results! ")]
                          ),
                          _c(
                            "h6",
                            {
                              staticClass: "text-center",
                              staticStyle: { color: "gray" }
                            },
                            [_vm._v(" Try searching another word ")]
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex justify-center mb-4" },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticStyle: { color: "darkgray" },
                                  attrs: { size: "82" }
                                },
                                [_vm._v("search")]
                              )
                            ],
                            1
                          )
                        ])
                      : _c(
                          "v-row",
                          _vm._l(_vm.products, function(product, index) {
                            return _c(
                              "v-col",
                              {
                                key: index,
                                attrs: { cols: "12", sm: "6", md: "4", lg: "3" }
                              },
                              [
                                _c("ProductItem", {
                                  attrs: { product: product }
                                })
                              ],
                              1
                            )
                          }),
                          1
                        )
                  ],
                  1
                )
          ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }