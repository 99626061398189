<template>
  <div>
    <AdminPanelHeader
      icon="mdi-calendar-range "
      title="Terms Basis Date Code"
      :actions="headerActions"
      @search="onSearch"
    />

    <X12termsBasisDateCodeTable
      v-on:onDelete="onDelete"
      :paginatedList="currentEntity"
      :searchParams="paginatedQueryParams"
      :isLoading="isLoading"
    />
  </div>
</template>

<script>
import apiClient from "../../../../apiClient";
import { AdminPanelHeaderAction } from "../../../../components/AdminPanelHeader/AdminPanelHeaderAction";
import { showDialog } from "../../../../components/Dialogs/NotifyDialog";
import { parentComponentMixin } from '../../../../mixins/parentComponentMixin';
export default {
  mixins:[parentComponentMixin],
  name: "X12termsBasisDateCode",
  data() {
    return {
      paginatedQueryParams: {
        pageSize: 10,
        pageNumber: 0,
        searchValue: "",
        sortField: "termBasisDateCodeId",
        sortDirection: "DESC",
      },
      headerActions: [
        new AdminPanelHeaderAction({
          color: "accent",
          label: "Add new",
          icon: "add",
          action: () =>
            this.$router.push(
              "/admin-panel/definitions/termsBasisDateCodes/add"
            ),
        }),
      ],
    };
  },
  methods: {
    async onDelete(id) {
      try {
        this.isLoading = true;
        await apiClient.X12("x12termsBasisDateCodes").delete(id);
        showDialog("Deleted Successfully");
        this.getPaginated()
      } catch (error) {
        showDialog("Could not delete item", error);
      } finally {
        this.isLoading = false;
      }
    },
    async getPaginated() {
      try {
        this.isLoading = true;
        this.currentEntity =
          await apiClient.X12("x12termsBasisDateCodes").getPaginated(
            this.paginatedQueryParams
          );
      } catch (error) {
        showDialog("Could not retrieve data", error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>