<template>
  <div>
    <Breadcrumbs class="mb-4" :items="breadcrumbs" />
    <div class="center-x" v-if="isLoading">
      <v-skeleton-loader type="text" width="100%" />
    </div>

    <v-card>
      <v-card-title>Terms Type Codes </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" v-show="!isAdd">
            <v-text-field
              label="Subscription File Type Association Id"
              v-model="currentObject.termsTypeCodeId"
              disabled
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              label="Terms Type Code"
              v-model="currentObject.termsTypeCode"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              label="Terms Type Definition"
              v-model="currentObject.termsTypeDefinition"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-row>
      <v-col>
        <div class="d-flex justify-end">
          <v-btn color="error" large @click="save">Save</v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import apiClient from "../../../../apiClient";
import { BreadcrumbItem } from "../../../../components/Breadcrumbs/BreadcrumbItem";
import { showDialog } from "../../../../components/Dialogs/NotifyDialog";
import { formsMixin } from "../../../../mixins/formsMixin";
import { StaticRoutes } from "../../../../router/Routes";
export default {
  mixins: [formsMixin],
  name: "TermsTypeCodesForm",
  data() {
    return {
      breadcrumbs: [
        new BreadcrumbItem({
          label: "Term Type Codes Details",
          route: StaticRoutes.adminPanel.trading.termsTypeCodes,
        }),
        new BreadcrumbItem({ label: "Terms Type Codes Details" }),
      ],
    };
  },
  methods: {
    async getById() {
      try {
        this.isLoading = true;
        this.currentObject = await apiClient.X12("x12termsTypeCodes").getById(this.id);
      } catch (error) {
        showDialog("Could not retrieve data", error);
      } finally {
        this.isLoading = false;
      }
    },
    async save() {
      try {
        this.isLoading = true;
        if (this.isAdd) {
          await apiClient.X12("x12termsTypeCodes").create(this.currentObject);
          showDialog("Saved Successfully");
        }
        if (this.isEdit) {
          await apiClient.X12("x12termsTypeCodes").update(this.id,this.currentObject);
          showDialog("Updated Successfully");
        }
          this.$router.push("/admin-panel/definitions/termsTypeCodes");
      } catch (error) {
        showDialog("Could not save item", error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>