<template>
  <div>
    <!-- header -->
    <div :style="{ 'background-color': primaryColor }">
      <v-container grid-list-xl>
        <v-layout row wrap align-center justify-space-between pa-0 ma-0>
          <v-flex xs6 sm6 md4 lg3 xl3 px-0>
            <div class="site-logo d-inline-block">
              <router-link to="/login">
                <img alt="site-logo" height="34" :src="appLogo" width="70%" />
              </router-link>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
    <!-- header -->

    <!-- main content -->
    <div
      class="emb-signIn-wrap section-gap d-flex justify-center align-center"
      style="height: 90vh"
    >
      <div class="container py-16 py-md-0">
        <v-layout row wrap align-center justify-center>
          <v-flex sm12 md12 lg8 xl7>
            <v-layout row mx-sm-0 mx-3 wrap align-center justify-center>
              <v-flex sm6 md7 lg6 xl6 hidden-sm-and-down>
                <div class="form-img sign-in-image">
                  <v-img :src="loginImage"></v-img>
                </div>
              </v-flex>
              <v-flex sm10 md5 lg5 xl6 style="z-index: 1">
                <div
                  class="emb-card sign-in-form form-margin d-block white pa-6"
                >
                  <h4 class="text-center mb-6">
                    <span class="font-weight-light">Welcome to </span>
                    <span style="color: rgb(80, 80, 80)">{{ title }}</span>
                    <!-- <span class="red--text">Brands</span> -->
                  </h4>
                  <v-btn
                    :loading="isPerformingOperation"
                    :disabled="isPerformingOperation || isLoginDisabled"
                    class="accent mb-3 ma-0"
                    block
                    large
                    @click="signIn"
                    >Sign In / Sign Up</v-btn
                  >
                  <v-checkbox v-model="rememberMe" label="Remember Me" />
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </div>
    </div>
    <!-- main content -->

    <!-- footer -->
    <div class="footer">
      <v-container class="pa-12">
        <div class="d-flex justify-center mb-6">
          <div>
            <h6>About us</h6>
            <p>{{ aboutUs }}</p>
          </div>
        </div>

        <hr class="mb-10" />

        <div class="d-flex justify-center justify-sm-end">
          <p>{{ copyrightText }}</p>
        </div>
      </v-container>
    </div>
    <!-- footer -->

    <!-- session expired dialog -->
    <v-dialog persistent v-model="isSessionExpired" max-width="400">
      <v-card>
        <v-card-text>
          <v-card-title>Your session expired!</v-card-title>
          <p class="ma-0 pa-0">
            Sign in again to keep using the app. You will be redirected to the
            last page you visited.
          </p>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn color="primary" @click="restoreSession">Sign in again</v-btn>
          <v-btn color="error" @click="cancelSessionRestore">Cancel</v-btn>
        </v-card-actions>
      </v-card> </v-dialog
    ><!-- session expired dialog -->

    <!-- restoring session dialog -->
    <v-dialog persistent max-width="300" :v-model="isRestoringSession">
      <v-card class="pa-3">
        <v-card-text class="d-flex flex-column align-center">
          <p>Restoring session...</p>
          <v-progress-circular width="2" indeterminate />
        </v-card-text>
      </v-card> </v-dialog
    ><!-- restoring session dialog -->

    <!-- to be able to dsiplay popup messages -->
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
/*eslint-disable*/

import { mapActions, mapGetters } from "vuex";

const StorageItems = {
  accessToken: "fb.accessToken",
  expirationTime: "fb.expirationTime",
  gotTokenWithRedirect: "fb.gotTokenWithRedirect",
};

export default {
  data() {
    return {
      appLogo: process.env.VUE_APP_LOGO,
      copyrightText: process.env.VUE_APP_COPYRIGHT,
      primaryColor: process.env.VUE_APP_COLOR_PRIMARY,
      aboutUs: process.env.VUE_APP_ABOUTUS_TEXT,
      rememberMe: false,
      isPerformingOperation: false,
      isLoginDisabled: false,
      isSessionExpired: false,
      isRestoringSession: false,
      loginImage: process.env.VUE_APP_LOGIN_IMAGE,
      title: process.env.VUE_APP_BRAND,
    };
  }, // data

  computed: {
    ...mapGetters({
      currentPerson: "session/getPerson",
      currentAccount: "session/getAccount",
      currentLocation: "session/getLocation",
    }),

    gotTokenWithRedirect() {
      const gotTokenWithRedirect = sessionStorage.getItem(
        StorageItems.gotTokenWithRedirect
      );
      const accessToken = sessionStorage.getItem(StorageItems.accessToken);
      const expirationTime = sessionStorage.getItem(
        StorageItems.expirationTime
      );

      return (
        gotTokenWithRedirect == "true" &&
        accessToken != undefined &&
        accessToken != null &&
        expirationTime != undefined &&
        expirationTime != null
      );
    },
  }, // computed

  mounted() {
    this.isSessionExpired = this.checkSessionExpired();

    if (this.isSessionExpired) {
      return;
    }

    this.isRestoringSession = this.checkSessionRestore();

    if (this.isRestoringSession) {
      let lastLocation = sessionStorage.getItem("fb.lastLocation");
      sessionStorage.removeItem("fb.lastLocation");
      this.attemptSignIn(lastLocation);
      return;
    }

    this.attemptSignIn("/home");
  }, // mounted

  methods: {
    ...mapActions({
      createPerson: "people/post",
      getUserProfile: "session/getUserProfile",
      getProductCategories: "search/getProductCategoriesRequest",
    }),

    async attemptSignIn(url) {
      this.isPerformingOperation = true;

      // If there is no token present, do manual sign in
      if (!this.$msal.isAuthenticated()) {
        this.isPerformingOperation = false;
        this.isLoginDisabled = false;
        return;
      }

      // If session access token was caught with redirect. sign in immediately
      if (this.gotTokenWithRedirect) {
        sessionStorage.removeItem(StorageItems.gotTokenWithRedirect);
        this.doSignIn(url);
        return;
      }

      // If there is an existing token, attempt getting access token
      let token = undefined;
      let caughtError = undefined;

      // Try getting access token silently
      if (!token) {
        try {
          console.log("Attempting to get token silently...");
          token = await this.$msal.getAccessToken();
          console.log("Got token silently!");
        } catch (error) {
          console.error("Could not get token silently:", error);
          token = undefined;
          caughtError = error;
        }
      }

      // Try getting access token with popup
      if (!token) {
        try {
          var newWin = window.open();
          console.log("Attempting to get token with popup...");
          token = await this.$msal.getAccessTokenPopup();
          console.log("Got token with popup!");
        } catch (error) {
          console.error("Could not get token with popup:", error);
          token = undefined;
          caughtError = error;
        }
      }

      // Try getting access token with redirect
      if (!token) {
        try {
          console.log("Attempting to get token with redirect...");
          this.$msal.getAccessTokenRedirect();
          sessionStorage.setItem("fb.gotTokenWithRedirect", "true");
        } catch (error) {
          console.error("Could not get token with redirect:", error);
          token = undefined;
          caughtError = error;
        }
      }
      var nav = navigator.userAgent.toLowerCase();

      // Could not get access token by any means
      if (!token) {
        console.log("Access token could not be obtained by any means.");

        this.isPerformingOperation = false;
        this.isLoginDisabled = false;

        const safari = nav.includes("safari");

        if (caughtError && caughtError.errorMessage && !safari) {
          this.$snotify.error(
            `Authentication error: ${caughtError.errorMessage}`,
            {
              closeOnClick: false,
              pauseOnHover: true,
              timeout: 6000,
            }
          );
        }

        return;
      }

      // Access token acquired! Get user profile and logon
      let accessToken = token.accessToken;
      let expirationTime = token.expiresOn;

      sessionStorage.setItem("fb.accessToken", accessToken);
      sessionStorage.setItem("fb.expirationTime", expirationTime);

      this.doSignIn(url);
    },

    cancelSessionRestore() {
      sessionStorage.clear();
      this.$msal.logout();
    },

    checkSessionExpired() {
      let isSessionExpired = sessionStorage.getItem("fb.isSessionExpired");

      if (isSessionExpired == "true") {
        return true;
      }

      return false;
    },

    checkSessionRestore() {
      let restoreSession = sessionStorage.getItem("fb.restoreSession");

      if (restoreSession == "true") {
        sessionStorage.removeItem("fb.restoreSession");
        return true;
      }

      return false;
    },

    async doSignIn(url) {
      console.log("doSignIn", url);

      let account = this.$msal.getAccountToken();
      let email = account.idToken.emails[0];

      try {
        await this.getUserProfile(email);
      } catch (error) {
        // Request made and server responded
        if (error.response) {
          console.log("Response error");
          console.log(error.response.data);
          console.log(error.response.status);
          console.log("");

          // The user profile was not found, create it
          if (error.response.status == 404) {
            let result = await this.createPerson({
              emailAddress: email,
              firstName: token.name,
              lastName: "",
              fullName: "",
              preferredName: "",
              isPermittedToLogon: false,
              lastEditedBy: 1,
            });

            this.$router.push("/inactive-account");
            return;
          }

          this.isPerformingOperation = false;

          this.$snotify.error(
            `Response error: ${error.response.status} - ${error.response.data}`,
            {
              closeOnClick: false,
              pauseOnHover: true,
              timeout: 6000,
            }
          );
        }

        // The request was made but no response was received
        else if (error.request) {
          console.log("Request error");
          console.log(error.request);
          console.log("");

          this.isPerformingOperation = false;

          this.$snotify.error(`Request error: ${error.request}`, {
            closeOnClick: false,
            pauseOnHover: true,
            timeout: 6000,
          });
        }

        // Something happened in setting up the request that triggered an Error
        else {
          console.log("Other error");
          console.log(error.message);
          console.log("");

          this.isPerformingOperation = false;

          this.$snotify.error(`Error: ${error.message}`, {
            closeOnClick: false,
            pauseOnHover: true,
            timeout: 6000,
          });
        }

        return;
      }

      // User profile was found but does not have permission to logon
      if (!this.currentPerson.isPermittedToLogon) {
        this.$router.push("/inactive-account");
        return;
      }

      // User profile was found and has permission to logon
      this.initProductCategories();
      this.$router.push(url);
    },

    initProductCategories() {
      this.getProductCategories();
    },

    restoreSession() {
      sessionStorage.removeItem("fb.isSessionExpired");
      sessionStorage.setItem("fb.restoreSession", "true");

      this.isSessionExpired = false;
      this.isPerformingOperation = true;

      this.$msal.loginRedirect();
    },

    signIn() {
      this.isPerformingOperation = true;
      this.$msal.loginRedirect();
    },
  }, // methods
};
</script>

<style scoped>
.top-header {
  background-color: #3a3434;
}

.footer {
  background-color: black;
}

.footer h6,
.footer p {
  color: whitesmoke;
}

.footer hr {
  border: 1px solid rgb(61, 61, 61);
}
</style>
