var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "emb-card pa-4" },
    [
      _c(
        "div",
        { staticClass: "table-responsive" },
        [
          _c("v-data-table", {
            attrs: {
              headers: _vm.headers,
              items: _vm.peoplePaginatedList.items,
              "server-items-length": _vm.peoplePaginatedList.totalCount,
              options: _vm.listViewOptions,
              loading: _vm.isLoading,
              "footer-props": {
                "items-per-page-options": [10, 20, 30, 40, 50]
              }
            },
            on: {
              "update:options": function($event) {
                _vm.listViewOptions = $event
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.action",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "v-btn",
                        {
                          staticClass: "primary--text",
                          attrs: { small: "", icon: "" },
                          on: {
                            click: function($event) {
                              return _vm.openInvitationEmailDialog(item)
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("email")])],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "primary--text",
                          attrs: {
                            small: "",
                            icon: "",
                            to: "/admin-panel/people/" + item.personId
                          }
                        },
                        [_c("v-icon", [_vm._v("visibility")])],
                        1
                      )
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "400" },
          model: {
            value: _vm.isShowingInvitationEmailDialog,
            callback: function($$v) {
              _vm.isShowingInvitationEmailDialog = $$v
            },
            expression: "isShowingInvitationEmailDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-text", [
                _c("h5", { staticClass: "pt-4" }, [
                  _vm._v(
                    "Do you want to send an invitation email to " +
                      _vm._s(_vm.lastSelectedPerson.firstName) +
                      " " +
                      _vm._s(_vm.lastSelectedPerson.lastName) +
                      "?"
                  )
                ])
              ]),
              _c(
                "v-card-actions",
                { staticClass: "d-flex justify-end" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        disabled: _vm.isSendingInvitationEmail,
                        loading: _vm.isSendingInvitationEmail
                      },
                      on: {
                        click: function($event) {
                          return _vm.sendInvitationEmail(_vm.lastSelectedPerson)
                        }
                      }
                    },
                    [_vm._v("Yes")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "error",
                        disabled: _vm.isSendingInvitationEmail
                      },
                      on: {
                        click: function($event) {
                          _vm.isShowingInvitationEmailDialog = false
                        }
                      }
                    },
                    [_vm._v("No")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }