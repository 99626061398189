<template>
  <div>
    <Breadcrumbs class="mb-4" :items="breadcrumbs" />
    <div class="center-x" v-if="isLoading">
      <v-skeleton-loader type="text" width="100%" />
    </div>

    <v-card>
      <v-card-title>File Type </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" v-show="!isAdd">
            <v-text-field
              label="File Type Id"
              v-model="currentObject.x12fileTypeId"
              disabled
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              label="File Type Description"
              v-model="currentObject.x12fileTypeDescription"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              label="File Types Transaction Set Id"
              v-model="currentObject.x12fileTypeTransactionSetId"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-row>
      <v-col>
        <div class="d-flex justify-end">
          <v-btn color="error" large @click="save">Save</v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import apiClient from "../../../../apiClient";
import { BreadcrumbItem } from "../../../../components/Breadcrumbs/BreadcrumbItem";
import { StaticRoutes } from "../../../../router/Routes";
import { showDialog } from "../../../../components/Dialogs/NotifyDialog";
import { formsMixin } from "../../../../mixins/formsMixin";
export default {
  mixins: [formsMixin],
  name: "X12FileTypesForm",
  data() {
    return {
      breadcrumbs: [
        new BreadcrumbItem({
          label: "File Types",
          route: StaticRoutes.adminPanel.trading.x12Definitions.fileType,
        }),
        new BreadcrumbItem({ label: "File Types Details" }),
      ],
    };
  },
  methods: {
    async getById() {
      try {
        this.isLoading = true;
        this.currentObject = await apiClient.X12("X12FileTypes").getById(this.id);
      } catch (error) {
        showDialog("Could not get data", error);
      } finally {
        this.isLoading = false;
      }
    },
    async save() {
      try {
        this.isLoading = true;
        if (this.isEdit) {
          await apiClient.X12("X12FileTypes").update(this.id,this.currentObject);
          showDialog("File Type Updated Successfully");
        }
        if (this.isAdd) {
          await apiClient.X12("X12FileTypes").create(this.currentObject);
          showDialog("File Type Created Successfully");
        }
          this.$router.push("/admin-panel/definitions/fileTypes");
      } catch (error) {
        showDialog("Could save file type", error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
