<template>
   <div class="download-app py-2">
      <div class="container text-center">
         <v-Layout row wrap text-center align-center>
            <v-flex xs12 sm12 md6 lg6 xl6 py-4 my-4 px-4 download-app-list text-center>
               <a href="javascript:void();">
                  <h4 class="mb-0 font-weight-bold">
                     <img class="mr-4" src="static/images/apple.png" alt="16 x 16" width="22" height="22"> 
                     Download iOS App 
                  </h4>
               </a>
            </v-flex>
            <v-flex xs12 sm12 md6 lg6 xl6 py-4 my-4 download-app-list>
               <a href="javascript:void();">
                  <h4 class="mb-0 font-bold">
                     <img class="mr-4" src="static/images/android-logo.png" alt="16 x 16" width="22" height="22">
                     Download Android App
                  </h4>
                </a>
            </v-flex>
         </v-Layout>
      </div>
   </div>
</template>

<script>
export default {
   
}
</script>
