import Vue from "vue"
import Vuetify from "vuetify"
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify);

export default new Vuetify({
	theme: {
		themes: {
			light: {
				primary: process.env.VUE_APP_COLOR_PRIMARY,
				accent: process.env.VUE_APP_COLOR_ACCENT,
				secondary: '#b0bec5',
				error: '#f44336',
				info: '#00D0BD',
				success: '#00D014'
			},
		},
	},
})