<template>
  <div>
    <AdminPanelHeader
      icon="mdi-search-web"
      title="Trace Types Codes"
      :actions="headerActions"
      @search="onSearch"
    />

    <X12TraceTypesCodesTable
      :paginatedList="currentEntity"
      :isLoading="isLoading"
      :searchParams="paginatedQueryParams"
      v-on:onDelete="onDelete"
    />
  </div>
</template>

<script>
import apiClient from "../../../../apiClient";
import { AdminPanelHeaderAction } from "../../../../components/AdminPanelHeader/AdminPanelHeaderAction";
import { showDialog } from "../../../../components/Dialogs/NotifyDialog";
import { parentComponentMixin } from "../../../../mixins/parentComponentMixin";

export default {
  name: "X12TraceTypesCodes",
  mixins: [parentComponentMixin],
  data() {
    return {
      headerActions: [
        new AdminPanelHeaderAction({
          color: "accent",
          label: "Add new",
          icon: "add",
          action: () =>
            this.$router.push("/admin-panel/definitions/traceTypesCodes/add"),
        }),
      ],
      paginatedQueryParams: {
        pageSize: 10,
        pageNumber: 0,
        searchValue: "",
        sortField: "traceTypeId",
        sortDirection: "DESC",
      },
    };
  },
  methods: {
    async onDelete(id) {
      try {
        this.isLoading = true;
        await apiClient.X12("X12TraceTypesCodes").delete(id);
        showDialog("Deleted successfully");
        await this.getPaginated();
      } catch (error) {
        showDialog("Could not delete record", error);
      } finally {
        this.isLoading = false;
      }
    },
    async getPaginated() {
      try {
        this.isLoading = true;
        this.currentEntity = await apiClient
          .X12("X12TraceTypesCodes")
          .getPaginated(this.paginatedQueryParams);
      } catch (error) {
        showDialog("Could not load Trace Types Codes data", error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
