<template>
    <v-data-table
        hide-default-footer
        :headers="headers"
        :items="indexedProducts.items"
        :server-items-length="indexedProducts.totalCount"/>
</template>

<script>
export default {

    props: {
        indexedProducts: {
            type: Object,
            default: () => ({
                items: [],
            }),
        },
    },

    data: () => ({
        headers: [
            {
                text: "StockItemId",
                value: "stockItemId",
            },
            {
                text: "InternalId",
                value: "internalId",
            },
            {
                text: "Name",
                sortable: true,
                value: "stockItemName",
            },
        ], // headers
    }), // data
};
</script>
