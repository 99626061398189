<template>
  <div>
    <AdminPanelHeader
      icon="forum"
      title="Trading Messages"
      @search="onSearch"
    />

    <TradingMessagesTable
      :isLoading="isLoading"
      :paginatedList="currentEntity"
      :searchParams="paginatedQueryParams"
    />
  </div>
</template>

<script>
import apiClient from "../../../apiClient";
import { showDialog } from "../../../components/Dialogs/NotifyDialog";
import { parentComponentMixin } from "../../../mixins/parentComponentMixin";
export default {
  mixins: [parentComponentMixin],
  data() {
    return {
      paginatedQueryParams: {
        pageSize: 10,
        pageNumber: 0,
        searchValue: "",
        sortField: "x12messageId",
        sortDirection: "DESC",
        filterValue: "",
      },
      id: null,
    };
  },
  mounted() {
    this.id = this.$route.params.id;
  },
  methods: {
    async getPaginated() {
      try {
        this.isLoading = true;

        if (this.id) {
          this.paginatedQueryParams.filterValue = this.id;
          this.currentEntity = await apiClient
            .X12("X12messages")
            .getFilteredPaginated(this.paginatedQueryParams);
        } else {
          this.currentEntity = await apiClient
            .X12("X12messages")
            .getPaginated(this.paginatedQueryParams);
        }
      } catch (error) {
        showDialog("Could not load messages", error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

