<template>
  <div class="shop-content-wrap">
    <v-flex d-none d-xs-none d-sm-none d-md-flex>
      <emb-page-title heading="My Top Products" imageType="my-top-products"></emb-page-title>
    </v-flex>
    <div class="gadget-content section-gap">
      <v-container grid-list-xl py-0>
        <v-layout row wrap d-flex align-center justify-center>
          <v-flex xs12 sm12 md8 lg9 xl9>
            <v-row>
              <v-col
                v-for="(product,index) in products.items"
                cols="12"
                sm="6"
                md="4"
                lg="3"
                :key="index"
              >
                <product-item :product="product"></product-item>
              </v-col>
            </v-row>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
  </div>
</template>
<script>
import ProductItem from "./ProductItem";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    ProductItem,
  },
  methods: {
    ...mapActions({ getTopProducts: "order/getCommonItems" }),
  },
  mounted() {
    this.getTopProducts();
  },
  computed: {
    ...mapGetters({ products: "order/getTopProducts" }),
  },
};
</script>
