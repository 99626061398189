<template>
  <v-menu>
    <template v-slot:activator="{ on }">
      <span v-on="on">
        <slot></slot>
      </span>
    </template>
    <v-list>
      <v-list-item
        v-for="(item, index) of menuItems"
        :key="index"
        @click="onMenuItemClicked(item)"
      >
        <v-icon v-if="item.icon" class="mr-4">{{ item.icon }}</v-icon>
        <span>{{ item.label }}</span>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    menuItemContext: {
      type: Object,
      required: false,
    },
    menuItems: {
      type: Array,
      default: () => [],
    },
  }, // props

  mounted() {}, // mounted

  methods: {
    onMenuItemClicked(menuItem) {
      menuItem.action(this.menuItemContext);
    },
  }, // methods
};
</script>

<style>
</style>