var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "500", persistent: "" },
      model: {
        value: _vm.isDialogOpened,
        callback: function($$v) {
          _vm.isDialogOpened = $$v
        },
        expression: "isDialogOpened"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v(_vm._s(_vm.title))]),
          _c("v-divider"),
          _c("v-card-text", { staticClass: "mt-6" }, [
            _c("p", { staticClass: "ma-0" }, [_vm._v(_vm._s(_vm.message))])
          ]),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { text: "", color: "primary" },
                  nativeOn: {
                    click: function($event) {
                      return _vm.onYesClicked($event)
                    }
                  }
                },
                [_vm._v(" Yes ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { text: "", color: "error" },
                  nativeOn: {
                    click: function($event) {
                      return _vm.onNoClicked($event)
                    }
                  }
                },
                [_vm._v(" No ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }