var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { staticClass: "pa-2", attrs: { "align-center": "" } },
    [
      _vm.back
        ? _c(
            "span",
            {
              staticClass: "mr-6",
              staticStyle: { cursor: "pointer", "margin-top": "0.1rem" },
              on: { click: _vm.onBackClicked }
            },
            [
              _c("v-icon", { staticStyle: { "margin-bottom": "0.1rem" } }, [
                _vm._v("mdi-chevron-left")
              ]),
              _c("b", { staticStyle: { "font-size": "0.875rem" } }, [
                _vm._v("Back")
              ])
            ],
            1
          )
        : _vm._e(),
      _c("v-breadcrumbs", {
        staticClass: "pa-0 ma-0",
        attrs: { items: _vm.items },
        scopedSlots: _vm._u([
          {
            key: "item",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "v-breadcrumbs-item",
                  [
                    item.route
                      ? _c("router-link", { attrs: { to: item.route } }, [
                          _c("b", [_vm._v(" " + _vm._s(item.label) + " ")])
                        ])
                      : _c(
                          "span",
                          {
                            staticStyle: {
                              color: "gray",
                              "font-size": "0.875rem",
                              "margin-bottom": "0.06rem"
                            }
                          },
                          [_vm._v(" " + _vm._s(item.label) + " ")]
                        )
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }