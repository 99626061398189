var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "d-flex justify-space-between align-center mb-3 px-3" },
        [
          _c("h6", { staticClass: "font-weight-regular ma-0 mb-2" }, [
            _vm._v("Subtotal")
          ]),
          _vm.isLoadingSpecialDeals
            ? _c("v-skeleton-loader", { attrs: { type: "text", width: "100" } })
            : _vm._e(),
          !_vm.isLoadingSpecialDeals
            ? _c(
                "h5",
                { staticClass: "font-weight-regular ma-0" },
                [
                  _c("emb-currency-sign"),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.formatNumber(_vm.amountSubtotal || _vm.subtotal)
                      ) +
                      " "
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex justify-space-between align-center mb-3 px-3" },
        [
          _c("h6", { staticClass: "font-weight-regular ma-0 mb-2" }, [
            _vm._v("Deposits")
          ]),
          _vm.isLoadingSpecialDeals
            ? _c("v-skeleton-loader", { attrs: { type: "text", width: "100" } })
            : _vm._e(),
          !_vm.isLoadingSpecialDeals
            ? _c(
                "h5",
                { staticClass: "font-weight-regular ma-0" },
                [
                  _c("emb-currency-sign"),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.formatNumber(_vm.amountDeposits || _vm.deposits)
                      ) +
                      " "
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex justify-space-between align-center mb-3 px-3" },
        [
          _c("h6", { staticClass: "font-weight-regular ma-0 mb-2" }, [
            _vm._v("Discounts")
          ]),
          _vm.isLoadingSpecialDeals
            ? _c("v-skeleton-loader", { attrs: { type: "text", width: "100" } })
            : _vm._e(),
          !_vm.isLoadingSpecialDeals
            ? _c(
                "h5",
                { staticClass: "font-weight-regular ma-0" },
                [
                  _c("emb-currency-sign"),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.formatNumber(_vm.amountDiscounts || _vm.discounts)
                      ) +
                      " "
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c("v-divider", { staticClass: "my-4" }),
      _c(
        "div",
        { staticClass: "d-flex justify-space-between align-center mb-2 px-3" },
        [
          _c("h4", { staticClass: "font-weight-regular ma-0 mb-1" }, [
            _vm._v("Total")
          ]),
          _vm.isLoadingSpecialDeals
            ? _c("v-skeleton-loader", { attrs: { type: "text", width: "130" } })
            : _vm._e(),
          !_vm.isLoadingSpecialDeals
            ? _c(
                "h3",
                { staticClass: "font-weight-regular ma-0" },
                [
                  _c("emb-currency-sign"),
                  _vm._v(
                    " " +
                      _vm._s(_vm.formatNumber(_vm.amountTotal || _vm.total)) +
                      " "
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c("v-divider", { staticClass: "mt-4" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }