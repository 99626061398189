<template>
  <div>
    <Breadcrumbs class="mb-4" :items="breadcrumbs" />
    <div class="center-x" v-if="isLoading">
      <v-skeleton-loader type="text" width="100%" />
    </div>
    <v-card>
      <v-card-title>File Type Versions </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" v-if="!isAdd">
            <v-text-field
              label="File Type Version Id"
              v-model.number="currentObject.x12fileTypeVersionId"
              disabled
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-autocomplete 
            v-model.number="currentObject.x12fileTypeId"
            :items="fileTypesId.items"
            item-value="x12fileTypeId"
            :item-text="concatenatedFileTypeName"
            label="File TypeId"
            >

            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              label="File Type Version Description"
              v-model="currentObject.x12fileTypeVersionDescription"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              label="File Type Version Identifier"
              v-model="currentObject.x12fileTypeVersionIdentifier"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              label="File Type Version Control Number"
              v-model="currentObject.x12fileTypeVersionControlNumber"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              label="File Type Identifier Code"
              v-model="currentObject.x12fileTypeIdentifierCode"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              label="File Type ControlStd Identifier"
              v-model="currentObject.x12fileTypeControlStdIdentifier"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              label="File Type Responible Agency Code"
              v-model="currentObject.x12fileTypeResponsibleAgencyCode"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-row>
      <v-col>
        <div class="d-flex justify-end">
          <v-btn color="error" large @click="save">Save</v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import apiClient from "../../../../apiClient";
import { BreadcrumbItem } from "../../../../components/Breadcrumbs/BreadcrumbItem";
import { StaticRoutes } from "../../../../router/Routes";
import { formsMixin } from "../../../../mixins/formsMixin";
import { showDialog } from "../../../../components/Dialogs/NotifyDialog";
export default {
  mixins: [formsMixin],
  name: "FileTypesVersionForm",
  data() {
    return {
      fileTypesId:{},
      paginatedQueryParams: {
      pageSize: 10,
      pageNumber: 0,
      searchValue: "",
      sortField: "X12fileTypeId",
      sortDirection: "DESC",
      filterValue:""
    },
      breadcrumbs: [
        new BreadcrumbItem({
          label: "File Types Versions",
          route: StaticRoutes.adminPanel.trading.x12Definitions.fileTypeVersion,
        }),
        new BreadcrumbItem({ label: "File Types Version Details" }),
      ],
    };
  },
  mounted(){
    this.getFileTypes();
  },
  methods: {
    concatenatedFileTypeName(value){
      return `${value.x12fileTypeId} - ${value.x12fileTypeDescription}`
    },
    async getFileTypes(){
      try {
        this.isLoading = true;
        this.fileTypesId =  await apiClient.X12("X12FileTypes").getPaginated(this.paginatedQueryParams);

      } catch (error) {
        showDialog("Could not get file type data", error);
      }finally{
        this.isLoading = false
      }
    },
    async getById() {
      try {
        this.isLoading = true;
        this.currentObject = await apiClient.X12("X12FileTypesVersion").getById(this.id);
      } catch (error) {
        showDialog("Could not load file type", error);
      } finally {
        this.isLoading = false;
      }
    },

    async save() {
      try {
        this.isLoading = true;
        if (this.isEdit) {
          await apiClient.X12("X12FileTypesVersion").update(this.id, this.currentObject);
          showDialog("Updated Succesfully");
        }
        if (this.isAdd) {
          await apiClient.X12("X12FileTypesVersion").create(this.currentObject);
          showDialog("Created Successfully");
        }
        this.$router.push("/admin-panel/definitions/fileTypesVersion");
      } catch (error) {
        showDialog("Could not update", error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>