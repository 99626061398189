var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "emb-forgetpassword-wrap section-gap" }, [
    _c(
      "div",
      { staticClass: "container py-0" },
      [
        _c(
          "v-layout",
          {
            attrs: {
              row: "",
              wrap: "",
              "align-center": "",
              "justify-center": ""
            }
          },
          [
            _c(
              "v-flex",
              { attrs: { sm12: "", md12: "", lg8: "", xl8: "" } },
              [
                _c(
                  "v-layout",
                  {
                    attrs: {
                      "align-center": "",
                      "mx-sm-0": "",
                      "mx-3": "",
                      "justify-center": "",
                      row: "",
                      wrap: ""
                    }
                  },
                  [
                    _c(
                      "v-flex",
                      {
                        attrs: {
                          sm6: "",
                          md6: "",
                          lg6: "",
                          xl6: "",
                          "hidden-sm-and-down": ""
                        }
                      },
                      [
                        _c("div", {
                          staticClass: "forgot-password-image form-img"
                        })
                      ]
                    ),
                    _c(
                      "v-flex",
                      {
                        staticClass:
                          "emb-card form-margin pa-4 d-inline-block white",
                        attrs: { sm10: "", md6: "", lg6: "", xl6: "" }
                      },
                      [
                        _c("h3", { staticClass: "accent--text" }, [
                          _vm._v("Forgot Password ?")
                        ]),
                        _c("h4", [_vm._v("No Problem")]),
                        _c(
                          "v-form",
                          {
                            ref: "form",
                            model: {
                              value: _vm.valid,
                              callback: function($$v) {
                                _vm.valid = $$v
                              },
                              expression: "valid"
                            }
                          },
                          [
                            _c("v-text-field", {
                              attrs: {
                                type: "email",
                                rules: _vm.emailRules,
                                placeholder: "Enter Your Email*"
                              }
                            }),
                            _c("v-text-field", {
                              staticClass: "mb-4",
                              attrs: {
                                type: "email",
                                rules: _vm.emailRules,
                                placeholder: "Retype Your Email*"
                              }
                            }),
                            _c(
                              "v-btn",
                              {
                                staticClass: "accent mb-4 ma-0",
                                attrs: { large: "" },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    return _vm.saveDetails($event)
                                  }
                                }
                              },
                              [_vm._v(" Submit ")]
                            ),
                            _c(
                              "p",
                              [
                                _vm._v("If have an account then "),
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "accent--text",
                                    attrs: { to: "/session/signin" }
                                  },
                                  [_vm._v("Sign In ")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }