var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tabs-listing" },
    [
      _c(
        "v-tabs",
        {
          attrs: { "slider-color": "red", "show-arrows": "" },
          model: {
            value: _vm.active,
            callback: function($$v) {
              _vm.active = $$v
            },
            expression: "active"
          }
        },
        [
          _vm._l(_vm.tabs, function(tab, index) {
            return _c("v-tab", { key: index, attrs: { ripple: "" } }, [
              _vm._v(" " + _vm._s(tab.title) + " ")
            ])
          }),
          _vm._l(3, function(n) {
            return _c(
              "v-tab-item",
              { key: n },
              [
                n == 1
                  ? _c("v-card", { attrs: { flat: "" } }, [
                      _c(
                        "div",
                        { staticClass: "table-responsive" },
                        [
                          _c("v-data-table", {
                            attrs: {
                              headers: _vm.headersForTransactionList,
                              items: _vm.tabsAndTableDetails.transactionList,
                              "hide-default-footer": "",
                              align: "center"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "item.typeColor",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _c(
                                        "v-chip",
                                        { attrs: { color: item.typeColor } },
                                        [_vm._v(_vm._s(item.type))]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ],
                        1
                      )
                    ])
                  : _vm._e(),
                n == 2
                  ? _c("v-card", { attrs: { flat: "" } }, [
                      _c(
                        "div",
                        { staticClass: "table-responsive" },
                        [
                          _c("v-data-table", {
                            attrs: {
                              headers: _vm.headersForTransferReport,
                              items: _vm.tabsAndTableDetails.transferreport,
                              align: "center",
                              "hide-default-footer": ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "item.typeColor",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _c(
                                        "v-chip",
                                        { attrs: { color: item.typeColor } },
                                        [_vm._v(_vm._s(item.type))]
                                      )
                                    ]
                                  }
                                },
                                {
                                  key: "item.statusColor",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _c(
                                        "v-chip",
                                        { attrs: { color: item.statusColor } },
                                        [_vm._v(_vm._s(item.status))]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ],
                        1
                      )
                    ])
                  : _vm._e(),
                n == 3
                  ? _c("v-card", { attrs: { flat: "" } }, [
                      _c(
                        "div",
                        { staticClass: "table-responsive" },
                        [
                          _c("v-data-table", {
                            attrs: {
                              headers: _vm.headersForExpenseCategory,
                              items: _vm.tabsAndTableDetails.expenseCategory,
                              align: "center",
                              "hide-default-footer": ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "item.typeColor",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _c(
                                        "v-chip",
                                        { attrs: { color: item.typeColor } },
                                        [_vm._v(_vm._s(item.type))]
                                      )
                                    ]
                                  }
                                },
                                {
                                  key: "item.statusColor",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _c(
                                        "v-chip",
                                        { attrs: { color: item.statusColor } },
                                        [_vm._v(_vm._s(item.status))]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ],
                        1
                      )
                    ])
                  : _vm._e()
              ],
              1
            )
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }