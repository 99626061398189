<template>
    <v-card>
        <v-card-text>
            <v-expansion-panels focusable multiple class="mb-4" :value="[0, 1, 2, 3]">

                <!-- basic info -->
                <v-expansion-panel>
                    <v-expansion-panel-header>Basic Information</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-select
                            :disabled="isView"
                            label="Name *"
                            :items="suppliers"
                            item-text="supplierName"
                            item-value="supplierId"
                            v-model.number="product.supplierId"
                            :rules="requiredRule"
                            @change="selectedSupplier = suppliers.find(s => s.supplierId == product.supplierId)"/>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-text-field disabled label="Phone number" type="number" v-model="selectedSupplier.phoneNumber"/>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field disabled label="Reference" v-model="selectedSupplier.supplierReference"/>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-select v-if="!selectedSupplier.primaryContactPerson" disabled label="Primary Contact"/>
                                <v-select v-if="selectedSupplier.primaryContactPerson" disabled label="Primary Contact" v-model="selectedSupplier.primaryContactPerson.fullName"/>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-select v-if="!selectedSupplier.alternateContactPerson" disabled label="Alternate Contact"/>
                                <v-select v-if="selectedSupplier.alternateContactPerson" disabled label="Alternate Contact" v-model="selectedSupplier.alternateContactPerson.fullName"/>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-select disabled label="Category" v-model="selectedSupplier.supplierCategory"/>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field disabled label="Website" v-model="selectedSupplier.websiteUrl"/>
                            </v-col>
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel><!-- basic info -->

                <!-- bank account -->
                <v-expansion-panel class="d-none">
                    <v-expansion-panel-header>Bank Account</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-text-field disabled label="Branch" v-model="selectedSupplier.bankAccountBranch"/>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field disabled label="Code" v-model="selectedSupplier.bankAccountCode"/>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-text-field disabled label="Name" v-model="selectedSupplier.bankAccountName"/>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field disabled label="Number" v-model="selectedSupplier.bankAccountNumber"/>
                            </v-col>
                        </v-row>
                        <v-text-field disabled label="International Code" v-model="selectedSupplier.bankInternationalCode"/>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <!-- postal address -->
                <v-expansion-panel>
                    <v-expansion-panel-header>Postal Address</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-text-field disabled label="Address Line 1" v-model="selectedSupplier.postalAddressLine1"/>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field disabled label="Address Line 2" v-model="selectedSupplier.postalAddressLin2"/>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-select disabled label="City" v-model="selectedSupplier.postalCity"/>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field disabled label="Postal Code" type="number" v-model="selectedSupplier.postalPostalCode"/>
                            </v-col>
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel><!-- postal address -->

                <!-- delivery -->
                <v-expansion-panel>
                    <v-expansion-panel-header>Delivery</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-text-field disabled label="Address Line 1" v-model="selectedSupplier.deliveryAddressLine1"/>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field disabled label="Address Line 2" v-model="selectedSupplier.deliveryAddressLine2"/>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-select disabled label="City" v-model="selectedSupplier.deliveryCity"/>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field disabled label="Postal Code" type="number" v-model="selectedSupplier.deliveryPostalCode"/>
                            </v-col>
                        </v-row>

                        <v-select disabled label="Location" v-model="selectedSupplier.deliveryLocation"/>
                        
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-select v-if="!selectedSupplier.deliveryMethod" disabled label="Delivery Method"/>
                                <v-select v-if="selectedSupplier.deliveryMethod" disabled label="Delivery Method" v-model="selectedSupplier.deliveryMethod.deliveryMethodName"/>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field disabled label="Fax Number" type="number" v-model="selectedSupplier.faxNumber"/>
                            </v-col>
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel><!-- delivery -->
            </v-expansion-panels>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    props: ['product'],

    data: () => ({
        isView: false,
        isAdd: false,
        selectedSupplier: {},
        requiredRule: [
            v => !!v || 'Required',
        ],
    }), // data

    beforeMount() {
        this.isView = this.$route.path.includes("view");
    }, // beforeMount

    mounted() {
        this.requestGetSuppliers();
        this.requestGetSupplierCategories();
        this.requestGetDeliveryMethods();

        if (this.product.supplierId) {
            this.selectedSupplier = this.product.supplier;
        }
    }, // mounted

    computed: {
        ...mapGetters({
            suppliers: 'catalog/getSuppliers',
            supplierCategories: 'catalog/getSupplierCategories',
            deliveryMethods: 'catalog/getDeliveryMethods',
        }),
    }, // computed

    methods: {
        ...mapActions({
            requestGetSuppliers: 'catalog/requestGetSuppliers',
            requestGetSupplierCategories: 'catalog/requestGetSupplierCategories',
            requestGetDeliveryMethods: 'catalog/requestGetDeliveryMethods',
        }),
    }, // methods
}
</script>
