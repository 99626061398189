import { render, staticRenderFns } from "./StockGroups.vue?vue&type=template&id=a6d8bf36&"
import script from "./StockGroups.vue?vue&type=script&lang=js&"
export * from "./StockGroups.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\a\\1\\s\\Lnf.Portal\\Lnf.Portal.Web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a6d8bf36')) {
      api.createRecord('a6d8bf36', component.options)
    } else {
      api.reload('a6d8bf36', component.options)
    }
    module.hot.accept("./StockGroups.vue?vue&type=template&id=a6d8bf36&", function () {
      api.rerender('a6d8bf36', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/AdminPanel/StockGroups/StockGroups.vue"
export default component.exports