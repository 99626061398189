var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.supplier
    ? _c(
        "div",
        { staticClass: "px-sm-4 px-md-12" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "layout justify-start mt-0 mx-0",
                  attrs: { cols: "12" }
                },
                [
                  _c("v-icon", { staticClass: "mr-2", attrs: { large: "" } }, [
                    _vm._v("people")
                  ]),
                  _c("v-text-field", {
                    staticClass: "tab-input-title",
                    attrs: { disabled: _vm.isView, required: "" },
                    model: {
                      value: _vm.supplier.supplierName,
                      callback: function($$v) {
                        _vm.$set(_vm.supplier, "supplierName", $$v)
                      },
                      expression: "supplier.supplierName"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            {},
            [
              _c("Breadcrumbs", {
                staticClass: "mb-4",
                attrs: { items: _vm.breadcrumbs }
              })
            ],
            1
          ),
          _c(
            "v-card",
            { staticClass: "mb-3" },
            [
              _c(
                "v-tabs",
                {
                  attrs: {
                    vertical: _vm.verticalTabs,
                    centered: !_vm.verticalTabs,
                    "center-active": !_vm.verticalTabs
                  }
                },
                [
                  _c(
                    "v-tab",
                    {
                      staticClass:
                        "d-flex flex-column flex-md-row justify-md-start justify-center"
                    },
                    [
                      _c("v-icon", { attrs: { left: _vm.verticalTabs } }, [
                        _vm._v("info")
                      ]),
                      _vm._v(" Overview ")
                    ],
                    1
                  ),
                  _c(
                    "v-tab",
                    {
                      staticClass:
                        "d-flex flex-column flex-md-row justify-md-start justify-center"
                    },
                    [
                      _c("v-icon", { attrs: { left: _vm.verticalTabs } }, [
                        _vm._v("local_post_office")
                      ]),
                      _vm._v(" Postal Address ")
                    ],
                    1
                  ),
                  _c(
                    "v-tab",
                    {
                      staticClass:
                        "d-flex flex-column flex-md-row justify-md-start justify-center"
                    },
                    [
                      _c("v-icon", { attrs: { left: _vm.verticalTabs } }, [
                        _vm._v("local_shipping")
                      ]),
                      _vm._v(" Delivery ")
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    [
                      _c("supplier-overview-tab", {
                        attrs: { selectedSupplier: _vm.supplier }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    [
                      _c("supplier-bank-account-tab", {
                        attrs: { selectedSupplier: _vm.supplier }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    [
                      _c("supplier-postal-address-tab", {
                        attrs: { selectedSupplier: _vm.supplier }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    [
                      _c("supplier-delivery-tab", {
                        attrs: { selectedSupplier: _vm.supplier }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          !_vm.isView
            ? _c(
                "div",
                { staticClass: "d-flex justify-end" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        loading: _vm.isPerformingOperation,
                        disabled: _vm.isPerformingOperation,
                        color: "error",
                        large: ""
                      },
                      on: { click: _vm.onClickSave }
                    },
                    [_vm._v("Save")]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }