var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.product
    ? _c(
        "div",
        [
          _vm.product.stockItemHoldingId !== undefined && _vm.product.stockItems
            ? _c(
                "div",
                { staticClass: "emb-product-edit" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "mx-auto",
                          attrs: { cols: "12", sm: "9", md: "10", lg: "8" }
                        },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    cols: "12",
                                    sm: "12",
                                    md: "6",
                                    lg: "6",
                                    "pl-5": ""
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "product-images-wrap d-flex"
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "product-images mr-4" },
                                        _vm._l(
                                          _vm.product.stockItems
                                            .stockItemImages,
                                          function(img, key) {
                                            return _c(
                                              "div",
                                              {
                                                key: key,
                                                staticClass:
                                                  "thumb-wrap ml-auto",
                                                attrs: { for: "upload" },
                                                on: {
                                                  mouseover: function($event) {
                                                    return _vm.doHover(img)
                                                  }
                                                }
                                              },
                                              [
                                                _c("v-img", {
                                                  staticStyle: {
                                                    height: "70px"
                                                  },
                                                  attrs: {
                                                    src: img.location,
                                                    contain: true
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                      _vm.product.stockItems.stockItemImages &&
                                      _vm.product.stockItems.stockItemImages
                                        .length > 0
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "product-preview-wrap"
                                            },
                                            [
                                              _c("v-img", {
                                                attrs: {
                                                  src: _vm.selectedImage,
                                                  "max-height": "700px",
                                                  "max-width": "100%",
                                                  contain: true
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      !_vm.product.stockItems.stockItemImages ||
                                      _vm.product.stockItems.stockItemImages
                                        .length == 0
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "product-preview-wrap"
                                            },
                                            [
                                              _c("v-img", {
                                                attrs: {
                                                  src: _vm.pictureUrl,
                                                  "max-height": "700px",
                                                  "max-width": "100%",
                                                  contain: true
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                ]
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "content-wrap pl-md-6",
                                  attrs: {
                                    cols: "12",
                                    sm: "12",
                                    md: "6",
                                    lg: "6"
                                  }
                                },
                                [
                                  _c("div", { staticClass: "d-flex py-4" }, [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "font-weight-medium cursor-pointer",
                                        on: {
                                          click: function($event) {
                                            return _vm.back()
                                          }
                                        }
                                      },
                                      [_vm._v("Back to Shop")]
                                    )
                                  ]),
                                  _c("h3", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.product.stockItems.stockItemName
                                      )
                                    )
                                  ]),
                                  _vm.isProductPurchasable
                                    ? _c(
                                        "h5",
                                        { staticClass: "accent--text" },
                                        [
                                          _c("emb-currency-sign"),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.formatNumber(
                                                  _vm.product.unitPrice
                                                ) || "XX.XX"
                                              ) +
                                              " "
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.product.depositPrice &&
                                  _vm.product.depositPrice > 0
                                    ? _c(
                                        "p",
                                        [
                                          _c("b", [_vm._v("Deposit:")]),
                                          _vm._v(" "),
                                          _c("emb-currency-sign"),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.formatNumber(
                                                  _vm.product.depositPrice
                                                ) || "XX.XX"
                                              ) +
                                              " "
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c("p", { staticClass: "mb-1" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.product.stockItems.marketingComments
                                      )
                                    )
                                  ]),
                                  _c("p", { staticClass: "mb-1 mt-4" }, [
                                    _c("b", [_vm._v("Availability:")]),
                                    _vm._v(" In Stock")
                                  ]),
                                  _c("p", { staticClass: "mt-1" }, [
                                    _c("b", [_vm._v("Weight Per Unit:")]),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.product.stockItems
                                            .typicalWeightPerUnit
                                        ) +
                                        " Kg "
                                    )
                                  ]),
                                  _c("p", { staticClass: "mb-1 mt-1" }, [
                                    _c("b", [_vm._v("Stock: ")]),
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.product.quantityOnHand) +
                                        " Units "
                                    )
                                  ]),
                                  _c("p", { staticClass: "mb-1" }, [
                                    _c("b", [_vm._v("Product Code:")]),
                                    _vm._v(
                                      " #" +
                                        _vm._s(
                                          _vm.product.stockItems.internalId
                                        ) +
                                        " "
                                    )
                                  ]),
                                  _vm.product.stockItems.type
                                    ? _c("p", { staticClass: "mb-1" }, [
                                        _c("b", [_vm._v("Type:")]),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.product.stockItems.type
                                            ) +
                                            " "
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.product.stockItems.subType
                                    ? _c("p", { staticClass: "mb-1" }, [
                                        _vm._v(
                                          " Sub Type : " +
                                            _vm._s(
                                              _vm.product.stockItems.subType
                                            ) +
                                            " "
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.product.stockItems.style
                                    ? _c("p", { staticClass: "mb-1" }, [
                                        _vm._v(
                                          " Style : " +
                                            _vm._s(
                                              _vm.product.stockItems.style
                                            ) +
                                            " "
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.product.stockItems.materials
                                    ? _c("p", { staticClass: "mb-1" }, [
                                        _vm._v(
                                          " Varietal : " +
                                            _vm._s(
                                              _vm.product.stockItems.materials
                                            ) +
                                            " "
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.product.stockItems.alcoholByVolume
                                    ? _c("p", { staticClass: "mb-1" }, [
                                        _vm._v(
                                          " ABV : " +
                                            _vm._s(
                                              _vm.product.stockItems
                                                .alcoholByVolume
                                            ) +
                                            " "
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.product.stockItems.originRegion
                                    ? _c("p", { staticClass: "mb-1" }, [
                                        _vm._v(
                                          " Region : " +
                                            _vm._s(
                                              _vm.product.stockItems
                                                .originRegion
                                            ) +
                                            " "
                                        )
                                      ])
                                    : _vm._e(),
                                  !_vm.isProductPurchasable
                                    ? _c(
                                        "div",
                                        { staticClass: "mt-6" },
                                        [
                                          _c(
                                            "v-alert",
                                            {
                                              attrs: {
                                                dense: "",
                                                border: "left",
                                                type: "warning"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " You are not licensed to purchase this product from us. Please contact your sales rep if you have questions. "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.isProductPurchasable
                                    ? _c(
                                        "div",
                                        { staticClass: "mt-6" },
                                        [
                                          _c(
                                            "v-form",
                                            {
                                              staticClass: "mb-2",
                                              model: {
                                                value: _vm.isFormValid,
                                                callback: function($$v) {
                                                  _vm.isFormValid = $$v
                                                },
                                                expression: "isFormValid"
                                              }
                                            },
                                            [
                                              _c("v-text-field", {
                                                staticStyle: {
                                                  "max-width": "150px"
                                                },
                                                attrs: {
                                                  label: "Quantity",
                                                  type: "number",
                                                  rules: _vm.quantityRules
                                                },
                                                model: {
                                                  value: _vm.quantity,
                                                  callback: function($$v) {
                                                    _vm.quantity = $$v
                                                  },
                                                  expression: "quantity"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          !_vm.isQuantityInRange
                                            ? _c(
                                                "v-alert",
                                                {
                                                  attrs: {
                                                    dense: "",
                                                    border: "left",
                                                    type: "error"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " The quantity must be between 0 and 250 "
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.isQuantityInRange &&
                                          _vm.isQuantityOverRecommended
                                            ? _c(
                                                "v-alert",
                                                {
                                                  attrs: {
                                                    dense: "",
                                                    border: "left",
                                                    type: "warning"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " Your selected quantity seems pretty large. "
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "accent",
                                                large: "",
                                                disabled:
                                                  !_vm.isFormValid ||
                                                  _vm.isAddingToCart,
                                                loading: _vm.isAddingToCart
                                              },
                                              on: {
                                                click: _vm.onAddToCartClicked
                                              }
                                            },
                                            [_vm._v(" Add To Cart ")]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.product.stockItems
            ? _c(
                "div",
                { staticClass: "d-block recommended-section pa-4" },
                [
                  _c("h2", { staticClass: "text-center" }, [
                    _vm._v("Recommended")
                  ]),
                  _c("product-recommendations", {
                    attrs: {
                      category: _vm.getProductCategory(),
                      subType: _vm.getProductSubType(),
                      productStyle: _vm.getProductStyle()
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-dialog",
            {
              attrs: { "max-width": "400" },
              model: {
                value: _vm.isShowingWarningDialog,
                callback: function($$v) {
                  _vm.isShowingWarningDialog = $$v
                },
                expression: "isShowingWarningDialog"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "mr-4",
                          staticStyle: { color: "orange" }
                        },
                        [_vm._v("warning")]
                      ),
                      _vm._v(" Warning ")
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c("v-card-text", [
                    _c("p", { staticClass: "ma-0 pt-5" }, [
                      _vm._v(" You are placing a large quantity order of "),
                      _c("strong", [_vm._v(_vm._s(_vm.quantity))]),
                      _vm._v(
                        " items. Are you sure you would like to continue? "
                      )
                    ])
                  ]),
                  _c("v-divider"),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "", color: "primary" },
                          on: { click: _vm.onConfirmBigOrder }
                        },
                        [_vm._v("Yes")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "", color: "error" },
                          on: {
                            click: function($event) {
                              _vm.isShowingWarningDialog = false
                            }
                          }
                        },
                        [_vm._v("No")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }