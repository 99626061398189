export class BreadcrumbItem {
    /**
     * @type {String}
     */
    label

    /**
     * @type {String}
     */
    route

    constructor({ label, route }) {
        this.label = label
        this.route = route
    }
}
