<template>
    <div>
        <v-dialog max-width="500px" class="pa-4" v-model="isDialogOpened">
            <v-card>
                <div class="d-flex align-center px-5">
                    <v-icon size="40">poll</v-icon>
                    <div>
                        <v-card-title class="h4">{{selectedForm}}</v-card-title>
                        <v-card-subtitle>
                            <b>4</b> Submissions. Created <i>July 30, 2020</i>
                        </v-card-subtitle>
                    </div>
                </div>
                <v-card-text>
                    <h6 class="ma-0">Submissions</h6>
                    <v-list>
                        <v-list-item
                            dense
                            v-for="submission of ['Today, 1:24 PM', 'Yesterday, 11:30 AM', 'Tuesday, 9:57 AM', 'July 9, 5:24 PM']"
                            v-bind:key="submission"
                            @click="isDialogOpened = true">
                            <v-list-item-title>{{submission}}</v-list-item-title>
                            <v-list-item-icon>
                                <v-icon>navigate_next</v-icon>
                            </v-list-item-icon>
                        </v-list-item>
                    </v-list>
                    <v-btn color="primary" class="mr-3" @click="isDialogOpened = false">Close</v-btn>
                    <v-btn color="error" @click="newFormSubmission">New Submission</v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="isSubmittingForm">
            <v-card>
                <div class="pb-12">
                    <iframe
                        :id="currentFormId"
                        :src="`https://form.jotform.com/${currentFormId}`"
                        frameborder="0"
                        :style="{
                            width: '100%',
                            height: formHeight,
                        }"/>
                </div>
                <v-footer absolute class="d-flex justify-end">
                    <v-btn class="my-1" color="primary" @click="closeFormSubmission(currentFormId)">Close</v-btn>
                </v-footer>
            </v-card>
        </v-dialog>

        <v-select
            placeholder="Select a form type"
            label="Form Type"
            v-model="selectedFormType"
            :items="Array.from(formTypes.keys())"/>

        <v-list>
            <v-list-item
                v-for="form of formTypes.get(selectedFormType)"
                v-bind:key="form"
                @click="isDialogOpened = true; selectedForm = form">
                <v-list-item-title>{{form}}</v-list-item-title>
                <v-list-item-icon>
                    <v-icon>navigate_next</v-icon>
                </v-list-item-icon>
            </v-list-item>
        </v-list>
    </div>
</template>

<script>
export default {
    data() {
        return {
            formTypes: new Map([
                ['Form type 1', [
                    'New Customer Survey',
                    'Change in Ownership request',
                    'SF Club Survey',
                ]],
                ['Form type 2', [
                    '+1 Display Pictures',
                    '2020 KAS Call Point',
                    '2020 Racetrac Bullet Cooler',
                ]],
                ['Form type 3', [
                    '2020 VIP Pricing & Compiliance Survey',
                    "Aaron Bell's Totally Bad Ass 2020 Incentive",
                    'Albertsons Monster Freezer (SW Division)',
                ]],
            ]),
            selectedFormType: undefined,
            selectedForm: undefined,
            isDialogOpened: false,
            isSubmittingForm: false,
            formHeight: `${window.innerHeight * 0.80}px`,
            //currentFormId: '201965802508053', // test form
            currentFormId: '202087150601140', // client mockup form
        };
    }, // data

    mounted() {

    }, // mounted

    methods: {
        newFormSubmission() {
            this.isSubmittingForm = true;
        },

        closeFormSubmission(formId) {
            const iframe = document.getElementById(formId);
            iframe.src = `https://form.jotform.com/${formId}`;
                        //https://form.jotform.com/201956039148055
            this.isSubmittingForm = false;
        },
    },
}
</script>
