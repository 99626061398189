<template>
    <div>

        <!-- search bar -->
        <v-card class="pa-4 mb-4">
            <v-row no-gutters>
                <v-col cols="12" sm="3" class="d-flex align-center justify-center justify-sm-start">
                    <v-icon class="mr-2">credit_card</v-icon>
                    <h5 class="ma-0">License Types</h5>
                </v-col>
                <v-col cols="12" sm="6" class="d-flex justify-center align-center">
                    <v-text-field class="mr-4" label="Search License Types" v-model="searchValue" @change="executeSearch"/>
                    <v-btn color="primary" @click="executeSearch">Search</v-btn>
                </v-col>
            </v-row>
        </v-card><!-- search bar -->

        <license-types-list-view
            :isLoading="isLoading"
            :paginatedList="licenseTypesPaginatedList"
            :searchParams="searchParams"
            v-on:search-params-changed="onSearchParamsChanged">
        </license-types-list-view>

    </div>
</template>

<script>
import LicenseTypesListView from './LicenseTypesListView.vue';

import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
    data() {
        return {
            isLoading: true,
            searchValue: undefined,
        };
    }, // data

    computed: {
        ...mapGetters({
            currentLocation: 'session/getLocation',
            licenseTypesPaginatedList: 'licenseTypes/getLicenseTypes',
            searchParams: 'licenseTypes/getSearchParams',
        }),
    }, // computed

    mounted() {
        let resetSearchParams = this.$route.query.resetSearchParams;
        if (resetSearchParams && resetSearchParams.toString() == true.toString()) this.resetSearchParams();

        this.searchValue = this.searchParams.searchValue;
    }, // mounted

    methods: {
        ...mapMutations({
            setSearchValue: 'licenseTypes/SET_SEARCH_PARAM_SEARCH_VALUE',
            setSearchParams: 'licenseTypes/SET_SEARCH_PARAMS',
            resetSearchParams: 'licenseTypes/RESET_SEARCH_PARAMS',
        }),

        ...mapActions({
            getLicenseTypes: 'licenseTypes/get',
        }),

        executeSearch() {
            this.isLoading = true;
            this.setSearchValue(this.searchValue);
            this.getLicenseTypes().finally(() => this.isLoading = false);
        },

        onSearchParamsChanged(searchParams) {
            this.isLoading = true;
            this.setSearchParams(searchParams);
            this.setSearchValue(this.searchValue);
            this.getLicenseTypes().finally(() => this.isLoading = false);
        },
    }, // methods

    components: {
        LicenseTypesListView,
    }, // components
}
</script>
