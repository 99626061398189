<template>
  <v-data-table
    :headers="headers"
    :items="paginatedList.items"
    :server-items-length="paginatedList.totalCount"
    :options.sync="listViewOptions"
    :loading="isLoading"
    :footer-props="{ 'items-per-page-options': [5, 10, 15, 20, 25] }"
  >
    <template v-slot:[`item.action`]="{ item }">
      <Menu :menuItems="rowMenuItems" :menuItemContext="item">
        <v-btn small icon class="primary--text">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </Menu>
    </template>
  </v-data-table>
</template>

<script>
import { MenuItem } from "../../../components/Menu/MenuItem";
import { yesNoDialogMixin } from '../../../mixins/showYesNoDialog';
import { tableProps } from '../../../mixins/tableProps';
export default {
  mixins:[tableProps,yesNoDialogMixin],
  name: "APSubscriptionTable",
  data() {
    return {
      rowMenuItems: [
        new MenuItem({
          icon: "visibility",
          label: "View",
          action: (sub) => {
            this.$router.push(
              "/admin-panel/trading-subscriptions/detail/" +
                sub.x12subscriptionId
            );
          },
        }),
      ],
      headers: [
        {
          text: "Subscription Id",
          sortable: true,
          value: "x12subscriptionId",
        },

        {
          text: "Trading partner Id",
          sortable: true,
          value: "x12tradingPartnerId",
        },
        {
          text: "Chain Id",
          sortable: true,
          value: "chainId",
        },
        {
          text: "Customer Chain Name",
          sortable: true,
          value: "chain.customerChainName",
        },
        {
          text:"Actions",
          sortable: false,
          value: "action",
        },
      ],
    };
  },
};
</script>