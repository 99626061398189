var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "address-block-wrap emb-card pa-4" },
    [
      _c("h4", { staticClass: "mb-6" }, [_vm._v("Address Infomation")]),
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "", sm5: "", md5: "", lg5: "", xl5: "" } },
            [
              _c("h5", [_vm._v("Billing Address")]),
              _c(
                "div",
                { staticClass: "pt-4" },
                [
                  _c("address", { staticClass: "mb-6" }, [
                    _vm._v(" 2735 Sherman Street"),
                    _c("br"),
                    _vm._v(" Hodour Sheridan Plaza"),
                    _c("br"),
                    _vm._v(" New Jersey"),
                    _c("br"),
                    _vm._v(" Zip - 6739HG1"),
                    _c("br"),
                    _vm._v(" USA"),
                    _c("br")
                  ]),
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: { name: "EditAddress", query: { type: "address" } }
                      }
                    },
                    [
                      _c("v-btn", { staticClass: "accent mx-0" }, [
                        _vm._v("Edit")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ]
          ),
          _c(
            "v-flex",
            { attrs: { xs12: "", sm5: "", md5: "", lg5: "", xl5: "" } },
            [
              _c("h5", [_vm._v("Shipping Address")]),
              _c(
                "div",
                { staticClass: "pt-4" },
                [
                  _c("address", { staticClass: "mb-6" }, [
                    _vm._v(" 2735 Sherman Street"),
                    _c("br"),
                    _vm._v(" Hodour Sheridan Plaza"),
                    _c("br"),
                    _vm._v(" New Jersey"),
                    _c("br"),
                    _vm._v(" Zip - 6739HG1"),
                    _c("br"),
                    _vm._v(" USA"),
                    _c("br")
                  ]),
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "EditAddress",
                          query: { type: "ship-address" }
                        }
                      }
                    },
                    [
                      _c("v-btn", { staticClass: "accent mx-0" }, [
                        _vm._v("Edit")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }