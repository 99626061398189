/* eslint-disable */

import api from '@/api';
import Vue from 'vue';

export default {

    namespaced: true,

    state: {
        moduleName: 'stockItemTag',
        tags: [],
        tagsList: [],
        currentTag: {},
        searchParams: {
            pageSize: 10,
            pageNumber: 1,
            sortField: 'stockItemTagName',
            sortDirection: 'ASC',
            searchValue: undefined, // it must be stockItemTagName
        },
    }, // state

    getters: {
        getTags: state => state.tags,
        getTagsList: state => state.tagsList,
        getCurrentTag: state => state.currentTag,
        getSearchParams: state => state.searchParams,
    }, // getters

    mutations: {
        SET_TAGS(state, data) {
            state.tags = data.data;
            Vue.set(state.tags, 'items', data.data.items);
        },
        SET_TAGS_LIST(state, list) {
            state.tagsList = list;
        },
        SET_CURRENT_TAG(state, currentTag) {
            state.currentTag = currentTag;
        },
        SET_SEARCH_PARAM_PAGE_SIZE(state, pageSize) {
            state.searchParams.pageSize = pageSize;
        },
        SET_SEARCH_PARAM_PAGE_NUMBER(state, pageNumber) {
            state.searchParams.pageNumber = pageNumber;
        },
        SET_SEARCH_PARAM_SEARCH_VALUE(state, searchValue) {
            state.searchParams.searchValue = searchValue;
        },
        SET_SEARCH_PARAM_SORT_FIELD(state, sortField) {
            state.searchParams.sortField = sortField;
        },
        SET_SEARCH_PARAM_SORT_DIRECTION(state, sortDirection) {
            state.searchParams.sortDirection = sortDirection;
        },
        SET_SEARCH_PARAMS(state, searchParams) {
            state.searchParams = searchParams;
        },
        RESET_SEARCH_PARAMS(state) {
            state.searchParams.pageSize = 10;
            state.searchParams.pageNumber = 1;
            state.searchParams.searchValue = undefined;
            state.searchParams.sortField = 'stockItemTagName';
            state.searchParams.sortDirection = 'ASC';
        },
    }, // mutations

    actions: {
        get({ state, commit }) {
            return api.get(`${state.moduleName}`, { params: state.searchParams })
            .then(result => commit('SET_TAGS', result));
        },

        getList({ state, commit }) {
            return api.get(`${state.moduleName}/list`)
            .then(result => commit('SET_TAGS_LIST', result.data));
        },
        
        getListByIds({ state, commit }, stockItemTagIds) {
            return api.put(`${state.moduleName}/list-all`, stockItemTagIds);
        },

        getListPromoted({ state, commit }) {
            return api.get(`${state.moduleName}/list-promoted`);
        },

        getById({ state, commit }, stockItemTagId) {
            return api.get(`${state.moduleName}/${stockItemTagId}`)
            .then(result => commit('SET_CURRENT_TAG', result.data));
        },

        delete({ state, commit }, stockItemTagId) {
            return api.delete(`${state.moduleName}/${stockItemTagId}`);
        },

        post({ state, commit }, stockItemTag) {
            return api.post(`${state.moduleName}`, stockItemTag);
        },

        put({ state, commit }, stockItemTag) {
            return api.put(`${state.moduleName}`, stockItemTag);
        },
    }, // actions
}
