export default [
   {
      icon: "shopping_cart",
      subTitle: "Free Shipping",
      desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit."
   },
   {
      icon: "thumb_up",
      subTitle: "24X7 Support",
      desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit."
   },
   {
      icon: "whatshot",
      subTitle: "Best Price",
      desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit."
   },
]