var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Breadcrumbs", {
        staticClass: "mb-4",
        attrs: { items: _vm.breadcrumbs }
      }),
      _vm.isLoading
        ? _c(
            "div",
            { staticClass: "center-x" },
            [
              _c("v-skeleton-loader", {
                attrs: { type: "text", width: "100%" }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v("File Type Versions ")]),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  !_vm.isAdd
                    ? _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "File Type Version Id",
                              disabled: ""
                            },
                            model: {
                              value: _vm.currentObject.x12fileTypeVersionId,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.currentObject,
                                  "x12fileTypeVersionId",
                                  _vm._n($$v)
                                )
                              },
                              expression: "currentObject.x12fileTypeVersionId"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6" } },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          items: _vm.fileTypesId.items,
                          "item-value": "x12fileTypeId",
                          "item-text": _vm.concatenatedFileTypeName,
                          label: "File TypeId"
                        },
                        model: {
                          value: _vm.currentObject.x12fileTypeId,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.currentObject,
                              "x12fileTypeId",
                              _vm._n($$v)
                            )
                          },
                          expression: "currentObject.x12fileTypeId"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: { label: "File Type Version Description" },
                        model: {
                          value:
                            _vm.currentObject.x12fileTypeVersionDescription,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.currentObject,
                              "x12fileTypeVersionDescription",
                              $$v
                            )
                          },
                          expression:
                            "currentObject.x12fileTypeVersionDescription"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: { label: "File Type Version Identifier" },
                        model: {
                          value: _vm.currentObject.x12fileTypeVersionIdentifier,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.currentObject,
                              "x12fileTypeVersionIdentifier",
                              $$v
                            )
                          },
                          expression:
                            "currentObject.x12fileTypeVersionIdentifier"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: { label: "File Type Version Control Number" },
                        model: {
                          value:
                            _vm.currentObject.x12fileTypeVersionControlNumber,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.currentObject,
                              "x12fileTypeVersionControlNumber",
                              $$v
                            )
                          },
                          expression:
                            "currentObject.x12fileTypeVersionControlNumber"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: { label: "File Type Identifier Code" },
                        model: {
                          value: _vm.currentObject.x12fileTypeIdentifierCode,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.currentObject,
                              "x12fileTypeIdentifierCode",
                              $$v
                            )
                          },
                          expression: "currentObject.x12fileTypeIdentifierCode"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: { label: "File Type ControlStd Identifier" },
                        model: {
                          value:
                            _vm.currentObject.x12fileTypeControlStdIdentifier,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.currentObject,
                              "x12fileTypeControlStdIdentifier",
                              $$v
                            )
                          },
                          expression:
                            "currentObject.x12fileTypeControlStdIdentifier"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: { label: "File Type Responible Agency Code" },
                        model: {
                          value:
                            _vm.currentObject.x12fileTypeResponsibleAgencyCode,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.currentObject,
                              "x12fileTypeResponsibleAgencyCode",
                              $$v
                            )
                          },
                          expression:
                            "currentObject.x12fileTypeResponsibleAgencyCode"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", [
            _c(
              "div",
              { staticClass: "d-flex justify-end" },
              [
                _c(
                  "v-btn",
                  {
                    attrs: { color: "error", large: "" },
                    on: { click: _vm.save }
                  },
                  [_vm._v("Save")]
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }