var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "emb-termCondition-wrap" },
    [
      _c("emb-page-title", {
        attrs: {
          heading: "Terms and Conditions",
          subHeading: "Please read and contact if you have some issue. "
        }
      }),
      _c("div", { staticClass: "emb-tnc section-gap" }, [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "tnc-content" }, [
            _c("section", { staticClass: "mb-8" }, [
              _c("h5", { staticClass: "mb-6" }, [
                _vm._v(_vm._s(_vm.mainTitle))
              ]),
              _vm._m(0),
              _vm._m(1),
              _c("p", [
                _vm._v(
                  " THE SERVICE IS FOR PERSONS 21 YEARS OF AGE AND OLDER. YOU MAY NOT USE OUR SERVICE IF YOU ARE UNDER 21. YOU MAY NOT PURCHASE FOR, PICK-UP FOR, OR SEND A DELIVERY OR SHIPMENT TO ANYONE UNDER 21. DO NOT SEND US INFORMATION ABOUT ANYONE UNDER 21. "
                )
              ]),
              _c("p", [
                _vm._v(
                  "If you have any questions about these Terms, please contact L&F at __________."
                )
              ])
            ]),
            _c("section", { staticClass: "mb-8" }, [
              _c(
                "ol",
                _vm._l(_vm.conditions, function(condition) {
                  return _c(
                    "li",
                    {
                      staticClass: "font-weight-bold",
                      staticStyle: { "font-size": "18px" }
                    },
                    [
                      condition.title
                        ? _c("h5", [_vm._v(_vm._s(condition.title))])
                        : _vm._e(),
                      condition.paragraphs
                        ? _vm._l(condition.paragraphs, function(text) {
                            return _c(
                              "p",
                              { staticClass: "font-weight-regular" },
                              [_vm._v(" " + _vm._s(text) + " ")]
                            )
                          })
                        : _vm._e(),
                      condition.subConditions
                        ? [
                            _c(
                              "ol",
                              { attrs: { type: "a" } },
                              _vm._l(condition.subConditions, function(
                                subCondition
                              ) {
                                return _c(
                                  "li",
                                  {
                                    staticClass: "font-weight-bold",
                                    staticStyle: { "font-size": "18px" }
                                  },
                                  [
                                    subCondition.title
                                      ? _c("h5", [
                                          _vm._v(_vm._s(subCondition.title))
                                        ])
                                      : _vm._e(),
                                    subCondition.paragraphs
                                      ? _vm._l(
                                          subCondition.paragraphs,
                                          function(text) {
                                            return _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "font-weight-regular"
                                              },
                                              [_vm._v(" " + _vm._s(text) + " ")]
                                            )
                                          }
                                        )
                                      : _vm._e()
                                  ],
                                  2
                                )
                              }),
                              0
                            )
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                }),
                0
              )
            ]),
            _c("h6", { staticClass: "text-center" }, [
              _vm._v(" LAST UPDATED "),
              _c("strong", [_vm._v(_vm._s(_vm.lastUpdated))])
            ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        ' Your use of orders.lnfdistributors.com, other websites, and any related offerings ("'
      ),
      _c("strong", [_vm._v("Service")]),
      _vm._v('") is subject to these U.S.Terms of Service ("'),
      _c("strong", [_vm._v("Terms")]),
      _vm._v('"). ')
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        " THESE TERMS ARE A LEGAL AGREEMENT BETWEEN YOU AND L&F Distributors, LLC (“"
      ),
      _c("strong", [_vm._v("L&F")]),
      _vm._v(
        "”). UNLESS YOU OPT OUT OF ARBITRATION WITHIN 30 DAYS OF ACCEPTING THESE TERMS (AS DESCRIBED BELOW), YOU WILL BE REQUIRED TO USE BINDING ARBITRATION TO RESOLVE DISPUTES WITH L&F. PLEASE READ THESE TERMS CAREFULLY. "
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }