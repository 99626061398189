var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-card-text",
        [
          _vm.product
            ? _c(
                "v-expansion-panels",
                {
                  staticClass: "mb-4",
                  attrs: { focusable: "", multiple: "", value: [0, 1, 2] }
                },
                [
                  _c(
                    "v-expansion-panel",
                    [
                      _c("v-expansion-panel-header", [_vm._v("Product")]),
                      _c(
                        "v-expansion-panel-content",
                        [
                          _c("v-text-field", {
                            attrs: {
                              disabled: _vm.isView,
                              label: "Name *",
                              rules: _vm.requiredRule
                            },
                            model: {
                              value: _vm.product.stockItemName,
                              callback: function($$v) {
                                _vm.$set(_vm.product, "stockItemName", $$v)
                              },
                              expression: "product.stockItemName"
                            }
                          }),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "12" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      disabled: _vm.isView,
                                      label: "Brand *",
                                      rules: _vm.requiredRule
                                    },
                                    model: {
                                      value: _vm.product.brand,
                                      callback: function($$v) {
                                        _vm.$set(_vm.product, "brand", $$v)
                                      },
                                      expression: "product.brand"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "6" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      disabled: _vm.isView,
                                      label: "Alcohol by Volume",
                                      type: "number"
                                    },
                                    model: {
                                      value: _vm.product.alcoholByVolume,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.product,
                                          "alcoholByVolume",
                                          $$v
                                        )
                                      },
                                      expression: "product.alcoholByVolume"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "6" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      disabled: _vm.isView,
                                      label: "Brand Type"
                                    },
                                    model: {
                                      value: _vm.product.brandType,
                                      callback: function($$v) {
                                        _vm.$set(_vm.product, "brandType", $$v)
                                      },
                                      expression: "product.brandType"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("v-textarea", {
                            attrs: {
                              disabled: _vm.isView,
                              outlined: "",
                              rows: "2",
                              label: "Marketing Comments"
                            },
                            model: {
                              value: _vm.product.marketingComments,
                              callback: function($$v) {
                                _vm.$set(_vm.product, "marketingComments", $$v)
                              },
                              expression: "product.marketingComments"
                            }
                          }),
                          _c("v-textarea", {
                            attrs: {
                              disabled: "",
                              outlined: "",
                              rows: "2",
                              label: "Search Details"
                            },
                            model: {
                              value: _vm.product.searchDetails,
                              callback: function($$v) {
                                _vm.$set(_vm.product, "searchDetails", $$v)
                              },
                              expression: "product.searchDetails"
                            }
                          }),
                          _c(
                            "div",
                            { staticClass: "d-flex" },
                            [
                              _c("v-combobox", {
                                staticClass: "mr-4",
                                attrs: {
                                  multiple: "",
                                  solo: "",
                                  chips: "",
                                  label: "Tags",
                                  "prepend-icon": "label",
                                  "item-value": "stockItemTagId",
                                  "item-text": "stockItemTagName",
                                  items: _vm.tagsList,
                                  disabled: _vm.isTagInputDisabled,
                                  loading: _vm.isTagInputDisabled
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.onTagAdded($event)
                                  }
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "selection",
                                      fn: function(ref) {
                                        var attrs = ref.attrs
                                        var item = ref.item
                                        var select = ref.select
                                        var selected = ref.selected
                                        return [
                                          _c(
                                            "v-chip",
                                            _vm._b(
                                              {
                                                attrs: {
                                                  close: "",
                                                  disabled:
                                                    _vm.isTagInputDisabled,
                                                  "input-value": selected
                                                },
                                                on: {
                                                  "click:close": function(
                                                    $event
                                                  ) {
                                                    return _vm.removeTag(item)
                                                  }
                                                }
                                              },
                                              "v-chip",
                                              attrs,
                                              false
                                            ),
                                            [
                                              _c("strong", [
                                                _vm._v(
                                                  _vm._s(item.stockItemTagName)
                                                )
                                              ])
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  299196084
                                ),
                                model: {
                                  value: _vm.product.stockItemTags,
                                  callback: function($$v) {
                                    _vm.$set(_vm.product, "stockItemTags", $$v)
                                  },
                                  expression: "product.stockItemTags"
                                }
                              }),
                              _c("div", [
                                _vm.isAssigningTags || _vm.isUnassigningTags
                                  ? _c(
                                      "div",
                                      [
                                        _vm.isAssigningTags
                                          ? _c(
                                              "v-alert",
                                              { attrs: { type: "info" } },
                                              [_vm._v(" Adding Tag... ")]
                                            )
                                          : _vm._e(),
                                        _vm.isUnassigningTags
                                          ? _c(
                                              "v-alert",
                                              { attrs: { type: "info" } },
                                              [_vm._v(" Removing Tag... ")]
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  : _c(
                                      "div",
                                      [
                                        _vm.showTagAssignedMessage
                                          ? _c(
                                              "v-alert",
                                              { attrs: { type: "success" } },
                                              [_vm._v(" Tag added! ")]
                                            )
                                          : _vm._e(),
                                        _vm.showTagUnassignedMessage
                                          ? _c(
                                              "v-alert",
                                              { attrs: { type: "success" } },
                                              [_vm._v(" Tag removed! ")]
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-expansion-panel",
                    [
                      _c("v-expansion-panel-header", [
                        _vm._v("Smart Category")
                      ]),
                      _c(
                        "v-expansion-panel-content",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "6" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      loading: _vm.loadingCategories == true,
                                      disabled: _vm.isView,
                                      label: "Category *",
                                      items: _vm.types,
                                      "item-text": "value",
                                      "item-value": "value"
                                    },
                                    model: {
                                      value: _vm.product.type,
                                      callback: function($$v) {
                                        _vm.$set(_vm.product, "type", $$v)
                                      },
                                      expression: "product.type"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm.enableSubtype() &&
                              _vm.getSubTypes().length > 0
                                ? _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "6" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          disabled: _vm.isView,
                                          label: "Sub Type",
                                          "item-text": "value",
                                          "item-value": "value",
                                          items: _vm.getSubTypes()
                                        },
                                        model: {
                                          value: _vm.product.subType,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.product,
                                              "subType",
                                              $$v
                                            )
                                          },
                                          expression: "product.subType"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.enableStyle() && _vm.getStyles().length > 0
                                ? _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "6" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          disabled: _vm.isView,
                                          label: "Style",
                                          items: _vm.getStyles(),
                                          "item-text": "value",
                                          "item-value": "value"
                                        },
                                        model: {
                                          value: _vm.product.style,
                                          callback: function($$v) {
                                            _vm.$set(_vm.product, "style", $$v)
                                          },
                                          expression: "product.style"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.enableMaterials() &&
                              _vm.getMaterials().length > 0
                                ? _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "6" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          disabled: _vm.isView,
                                          label: "Varietals",
                                          items: _vm.getMaterials(),
                                          "item-text": "value",
                                          "item-value": "value"
                                        },
                                        model: {
                                          value: _vm.product.materials,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.product,
                                              "materials",
                                              $$v
                                            )
                                          },
                                          expression: "product.materials"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.enableRegion() && _vm.getRegions().length > 0
                                ? _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "6" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          disabled: _vm.isView,
                                          label: "Region",
                                          items: _vm.getRegions(),
                                          "item-text": "value",
                                          "item-value": "value"
                                        },
                                        model: {
                                          value: _vm.product.originRegion,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.product,
                                              "originRegion",
                                              $$v
                                            )
                                          },
                                          expression: "product.originRegion"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.enableAppelation() &&
                              _vm.getAppelations().length > 0
                                ? _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "6" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          disabled: _vm.isView,
                                          label: "Appelation",
                                          items: _vm.getAppelations(),
                                          "item-text": "value",
                                          "item-value": "value"
                                        },
                                        model: {
                                          value: _vm.product.appelation,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.product,
                                              "appelation",
                                              $$v
                                            )
                                          },
                                          expression: "product.appelation"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-expansion-panel",
                    [
                      _c("v-expansion-panel-header", [_vm._v("Origin")]),
                      _c(
                        "v-expansion-panel-content",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "6" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      disabled: _vm.isView,
                                      label: "Country",
                                      items: _vm.countries,
                                      "item-text": "countryName",
                                      "item-value": "countryName"
                                    },
                                    model: {
                                      value: _vm.product.originCountry,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.product,
                                          "originCountry",
                                          $$v
                                        )
                                      },
                                      expression: "product.originCountry"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "6" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      disabled: _vm.isView,
                                      label: "Sub region"
                                    },
                                    model: {
                                      value: _vm.product.originSubregion,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.product,
                                          "originSubregion",
                                          $$v
                                        )
                                      },
                                      expression: "product.originSubregion"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }