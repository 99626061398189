<template>
  <div class="profile-wrapper emb-card pa-4">
    <Breadcrumbs class="mb-4" :items="breadcrumbs" v-if="isAdd"/>
    <v-expansion-panels multiple focusable :value="[0, 1]">
      <!-- general inforamtion -->
      <v-expansion-panel>
        <v-expansion-panel-header>Partner information</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                label="Company Id"
                v-model.number="currentObject.companyId"
              />
            </v-col>
            <v-col cols="12" sm="6" v-if="!isAdd">
              <v-text-field
                label="Partner Id"
                v-model="currentObject.x12tradingPartnerId"
                disabled
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                label="Partner description"
                v-model="currentObject.x12TradingPartnerDescription"
                required
              />
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                label="Partner Quanlifier"
                v-model="currentObject.x12interchangeTradingPartnerQualifier"
                required
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-autocomplete
                v-model="currentObject.x12interchangeTradingPartnerIdentifier"
                :items="partnerIds.items"
                item-value="x12interchangeTradingPartnerIdentifier"
                item-text="x12interchangeTradingPartnerIdentifier"
                label="Partner Identifier"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                label="Partner Group Identifier"
                v-model="
                  currentObject.x12interchangeTradingPartnerGroupIdentifier
                "
                required
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                label="Partner Erp Identifier"
                v-model="
                  currentObject.x12interchangeTradingPartnerErpidentifier
                "
                required
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                label="Partner Type Identifier"
                v-model.number="currentObject.x12interchangeTradingPartnerTypeId"
                required
                
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                label="Internal Partner Desc"
                v-model="currentObject.x12internalTradingPartnerDesc"
                required
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                label="Internal Trading Partner Qualifier"
                v-model="currentObject.x12internalTradingPartnerQualifier"
                required
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                label="x12internal Trading Partner Identifierr"
                v-model="currentObject.x12internalTradingPartnerIdentifier"
                required
              />
            </v-col>
          </v-row>
        </v-expansion-panel-content> </v-expansion-panel
      ><!-- general inforamtion -->
    </v-expansion-panels>
    <v-row justify="end">
      <v-col align-self="end" cols="auto">
        <v-btn color="primary" @click="save()">Save</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import apiClient from "../../../apiClient";
import { BreadcrumbItem } from '../../../components/Breadcrumbs/BreadcrumbItem';
import { showDialog } from "../../../components/Dialogs/NotifyDialog";
import { formsMixin } from "../../../mixins/formsMixin";
import { StaticRoutes } from '../../../router/Routes';
export default {
  mixins: [formsMixin],
  name: "TradingPartnerForm",
  data: () => ({
    breadcrumbs: [
        new BreadcrumbItem({
          label: "Trading Partner",
          route: StaticRoutes.adminPanel.trading.partners,
        }),
        new BreadcrumbItem({ label: "Trading Partner Details" }),
      ],
    partnerIds: {},
    verticalTabs: true,
    paginatedQueryParams: {
        pageSize: 10,
        pageNumber: 0,
        searchValue: "",
        sortField: "X12tradingPartnerId",
        sortDirection: "DESC",
      },
  }),
  mounted() {
    this.getPaginated();
  },
  methods: {
    async getById() {
      try {
        this.isLoading = true;
        this.currentObject = await apiClient
          .X12("TradingPartner")
          .getById(this.id);
      } catch (error) {
        showDialog("Could not get current trading partner data", error);
      } finally {
        this.isLoading = false;
      }
    },
    async getPaginated() {
      try {
        this.isLoading = true;
        this.partnerIds = await apiClient
          .X12("TradingPartner")
          .getPaginated(this.paginatedQueryParams);
      } catch (error) {
        showDialog("Could not get current trading partner data", error);
      } finally {
        this.isLoading = false;
      }
    },
    async save() {
      try {
        this.isLoadingPartner = true;
        if (this.isAdd) {
          await apiClient.X12("TradingPartner").create(this.currentObject)
          showDialog("The partner has been added");
        }
        if (this.isEdit) {
          await apiClient.X12("TradingPartner").update(this.id,this.currentObject)
          showDialog("The partner has been updated");
        }
        this.$router.replace("/admin-panel/partners");
      } catch (error) {
        showDialog("Could not save trading partner", error);
      } finally {
        this.isLoadingPartner = false;
      }
    },
  },
};
</script>