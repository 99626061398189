<template>
    <div>

        <!-- list item -->
        <div v-if="asListItem == true">
            <div class="d-flex align-center">
                <div class="mr-4" style="min-width: 60px; min-height: 60px;">
                    <v-img
                        contain
                        max-height="60"
                        max-width="60"
                        :src="image"/>
                </div>
                <div>
                    <h6 class="ma-0">{{item.stockItem.stockItemName}}</h6>
                    <p class="ma-0">
                        <emb-currency-sign></emb-currency-sign>
                        {{formatNumber(subTotal)}}
                    </p>
                </div>
            </div>
        </div><!-- list item -->

        <!-- row item -->
        <div v-else>

            <!-- order line -->
            <v-row>

                <!-- image, code and view -->
                <v-col cols="12" sm="12" md="2">

                    <!-- image -->
                    <div class="mb-2">
                        <v-img
                            class="d-block ma-auto"
                            width="130px"
                            height="130px"
                            :contain="true"
                            :src="image"/>
                    </div><!-- image -->

                    <!-- code -->
                    <p class="text-center ma-0">
                        <b>Product code:</b> #{{item.stockItem.internalId}}
                    </p><!-- code -->

                    <!-- view -->
                    <p v-if="hasTypeAndId" class="text-center ma-0">
                        <router-link :to="viewProductPath" style="color: #AD1814">view product</router-link>
                    </p><!-- view -->

                </v-col><!-- image, code and view -->

                <!-- info -->
                <v-col cols="12" sm="6" md="4">

                    <!-- name -->
                    <div class="d-flex justify-center align-center mb-4">
                        <h5 class="ma-0">{{item.stockItem.stockItemName}}</h5>
                    </div>
                    
                    <!-- unit price -->
                    <div class="d-flex justify-space-between mb-2">
                        <p class="ma-0"><b>Unit Price:</b></p>
                        <p class="ma-0">
                            <emb-currency-sign></emb-currency-sign>
                            {{formatNumber(unitPrice)}}
                        </p>
                    </div><!-- unit price -->

                    <v-divider class="mb-2" />

                    <!-- deposit -->
                    <div class="d-flex justify-space-between mb-2">
                        <p class="ma-0"><b>Deposit:</b></p>
                        <p class="ma-0">
                            <emb-currency-sign></emb-currency-sign>
                            {{formatNumber(depositPrice)}}
                        </p>
                    </div><!-- deposit -->

                    <v-divider class="mb-2" />

                    <!-- discount -->
                    <div v-if="hasQuantityDiscount"
                        class="d-flex justify-space-between mb-2">
                        <p class="ma-0">
                            <b>Discount {{discountDescription}}:</b>
                        </p>
                        <p class="ma-0">
                            <emb-currency-sign></emb-currency-sign>
                            {{formatNumber(discount)}}
                        </p>
                    </div><!-- discount -->

                    <v-divider class="mb-2" />
                
                </v-col><!-- info -->

                <!-- quantity -->
                <v-col cols="6" sm="3" md="2" class="d-flex justify-space-between align-center">
                    <div>
                        <h6 class="text-center">
                            <span :class="{ 'ml-5': canEditQuantity }">Quantity</span>
                            <v-btn v-if="canEditQuantity" small icon @click="showEditQuantityDialog()">
                                <v-icon small>edit</v-icon>
                            </v-btn>
                        </h6>
                        <p class="text-center">{{item.quantity}}</p>
                    </div>
                </v-col><!-- quantity -->

                <!-- subtotal -->
                <v-col cols="6" sm="3" md="2" class="d-flex justify-center align-center">
                    <div>

                        <!-- discounted price -->
                        <div v-if="isQuantityDiscountApplied == true || isMixAndMatchDiscountApplied == true">
                            <h5 class="text-center font-weight-light discounted ma-0">

                                <!-- both discounts are applied -->
                                <span v-if="isQuantityDiscountApplied == true && isMixAndMatchDiscountApplied == true">
                                    <emb-currency-sign></emb-currency-sign>{{formatNumber(subTotalNoDiscount)}}
                                </span>
                                
                                <!-- quantity discount applied -->
                                <span v-else-if="isQuantityDiscountApplied == true">
                                    <emb-currency-sign></emb-currency-sign>{{formatNumber(subTotalNoDiscount)}}
                                </span><!-- show if discount applied -->

                                <!-- mix and match discount applied -->
                                <span v-else-if="isMixAndMatchDiscountApplied == true">
                                    <emb-currency-sign></emb-currency-sign>{{formatNumber(subTotalNoDiscount)}}
                                </span>
                                
                            </h5>
                        </div><!-- discounted price -->
                        
                        <!-- subtotal -->
                        <h4 class="text-center font-weight-regular ma-0">
                            <emb-currency-sign></emb-currency-sign>{{formatNumber(subTotal)}}
                        </h4><!-- subtotal -->

                        <!-- discounted amount -->
                        <p v-if="isQuantityDiscountApplied == true || isMixAndMatchDiscountApplied == true" class="ma-0">

                            <!-- both discounts are applied -->
                            <span v-if="isQuantityDiscountApplied == true && isMixAndMatchDiscountApplied == true">
                                -<emb-currency-sign></emb-currency-sign>{{formatNumber(discountAll)}} from discounts
                            </span>

                            <!-- both discounts are applied -->
                            <span v-else-if="isQuantityDiscountApplied == true">
                                -<emb-currency-sign></emb-currency-sign>{{formatNumber(discountAll)}} from discounts
                            </span>

                            <!-- mix and match discount applied -->
                            <span v-else-if="isMixAndMatchDiscountApplied == true">
                                -<emb-currency-sign></emb-currency-sign>{{formatNumber(discountAll)}} from discounts
                            </span>
                        </p><!-- discounted amount -->
                    </div>
                </v-col><!-- subtotal -->

                <!-- remove order line -->
                <v-col v-if="canRemove" cols="2" sm="2" md="2" class="res-float-icon d-inline-flex align-center justify-md-center justify-end">
                    <a class="accent--text remove-cart"
                        href="javascript:void(0)"
                        @click="openRemoveFromCartDialog(item)">
                    <i class="material-icons font-weight-bold">close</i>
                    </a>
                </v-col><!-- remove order line -->

            </v-row><!-- order line -->

            <!-- dialogs -->
            <div>

                <!-- edit quantity dialog -->
                <v-dialog v-model="isShowingEditQuantityDialog" max-width="420" persistent>
                    <v-card>
                        <v-card-title>Edit Quantity</v-card-title>
                        <v-divider/>

                        <v-card-text>
                            <v-alert type="info" class="mt-4">
                                Page will reload automatically after editing the quantity in order for discounts to be re-calculated correctly
                            </v-alert>

                            <v-text-field
                                type="number"
                                label="Current Quantity"
                                disabled
                                v-model="item.quantity"/>

                            <v-text-field
                                type="number"
                                label="New Quantity"
                                v-model="newQuantity"/>
                        
                        </v-card-text>

                        <v-divider/>
                        <v-card-actions>
                            <v-spacer />
                            <v-btn
                                text
                                color="primary"
                                :disabled="!isNewQuantityValid || isUpdatingQuantity"
                                :loading="isUpdatingQuantity"
                                @click="onConfirmQuantityChange">
                                Confirm
                            </v-btn>
                            <v-btn
                                text
                                color="error"
                                :disabled="isUpdatingQuantity"
                                @click="isShowingEditQuantityDialog = false">
                                Cancel
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog><!-- edit quantity dialog -->

                <!-- remove from cart dialog -->
                <v-dialog v-model="isShowingRemoveFromCartDialog" max-width="600" persistent>
                    <v-card>
                        <v-card-title>Remove from cart</v-card-title>
                        <v-divider/>

                        <v-card-text>
                            <p class="ma-0 mt-8 pa-0">Are you sure you want to remove <b>{{stockItem.stockItemName}}</b> from your cart?</p>
                        </v-card-text>

                        <v-divider/>
                        <v-card-actions>
                            <v-spacer/>
                            <v-btn text color="primary"
                                :disabled="isRemovingFromCart"
                                :loading="isRemovingFromCart"
                                @click="onRemoveFromCartYesClicked">
                                Yes
                            </v-btn>

                            <v-btn text color="error"
                                :disabled="isRemovingFromCart"
                                @click="isShowingRemoveFromCartDialog = false">
                                No
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog><!-- remove from cart dialog -->
            
            </div><!-- dialogs -->

        </div><!-- row item -->

    </div>
</template>

<style scoped>
.discounted {
    color: red;
    text-decoration: line-through;
}
</style>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from "vuex";
import { currency, cart, utils } from "../mixins";

export default {

    props: {
        item: Object,
        order: Object,
        canEditQuantity: {
            default: true,
            type: Boolean,
        },
        canRemove: {
            default: true,
            type: Boolean,
        },
        useOrderCustomerId: { // TODO Use this for submitted orders
            type: Boolean,
            default: false, // false to use current customer id, true to use the order id
        },
        asListItem: { // TODO To show in cart dropdown
            type: Boolean,
            default: false,
        },
    }, // props

    data: () => ({
        isRemovingFromCart: false,
        isShowingEditQuantityDialog: false,
        isShowingRemoveFromCartDialog: false,
        isUpdatingQuantity: false,
        orderLinesByMixAndMatch: new Map(), // Map<string, Object> where key = dealDescription: string, value = Object: { /* details */}
        newQuantity: 0,
        specialDeals: [],
    }), // data

    mounted() {
        this.getSpecialDealsRequest();
    },

    computed: {
        ...mapGetters({
            currentCustomer: "session/getAccount",
        }),

        appliedDiscount() { /* SpecialDeal */
            if (this.isQuantityDiscountApplied == true) {
                return this.appliedQuantityDiscount;
            }

            if (this.isMixAndMatchDiscountApplied == true) {
                return this.appliedMixAndMatchDiscounts;
            }

            return {
                unitPrice: 0, // return type needs to be SpecialDeal
            };
        },

        appliedDiscountAmount() { /* number */
            return Number(this.appliedDiscount.unitPrice);
        },

        appliedMixAndMatchDiscounts() { /* SpecialDeal */
            const thisOrderLineId = this.item.orderLineId;
            let matchingMixAndMatchDeal = [];

            for (const [ _, deals ] of this.orderLinesByMixAndMatch.entries()) {
                const thisOrderLineIsIncludedInSpecialDeal = deals.orderLines.some(orderLine => orderLine.orderLineId == thisOrderLineId);

                if (thisOrderLineIsIncludedInSpecialDeal) {
                    matchingMixAndMatchDeal = deals;
                    break;
                }
            }

            let combinedQuantitiesByMixAndMatch = 0;
            matchingMixAndMatchDeal.orderLines.forEach(orderLine => combinedQuantitiesByMixAndMatch += orderLine.quantity);
            const specialDealsCopy = Object.assign([], this.specialDeals);
            const specialDealsFiltered = specialDealsCopy.filter(deal => deal.dealDescription);
            const sortedSpecialDeals = Object.assign([], specialDealsFiltered.sort((a, b) => a.minimumQuantity - b.minimumQuantity));
            let currentAppliedDeal = sortedSpecialDeals.shift();

            for (const deal of sortedSpecialDeals) {
                if (combinedQuantitiesByMixAndMatch >= deal.minimumQuantity) {
                    currentAppliedDeal = deal;
                }
            }

            return currentAppliedDeal;
        },

        appliedQuantityDiscount() {
            if (this.specialDeals.length == 1)
                return this.specialDeals[0];
            
            const specialDealsCopy = Object.assign([], this.specialDeals);
            const sortedSpecialDeals = Object.assign([], specialDealsCopy.sort((a, b) => a.minimumQuantity - b.minimumQuantity));
            const currentQuantity = this.item.quantity;
            let currentAppliedDeal = sortedSpecialDeals.shift();

            for (const deal of sortedSpecialDeals) {
                if (currentQuantity >= deal.minimumQuantity) {
                    currentAppliedDeal = deal;
                }
            }

            return currentAppliedDeal;
        },

        combinedQuantitiesByMixAndMatch() { /* number */
            const thisOrderLineId = this.item.orderLineId;
            let matchingMixAndMatchDeal = [];

            for (const [ _, deals ] of this.orderLinesByMixAndMatch.entries()) {
                const thisOrderLineIsIncludedInSpecialDeal = deals.orderLines.some(orderLine => orderLine.orderLineId == thisOrderLineId);

                if (thisOrderLineIsIncludedInSpecialDeal) {
                    matchingMixAndMatchDeal = deals;
                    break;
                }
            }
            
            let combinedQuantitiesByMixAndMatch = 0;
            matchingMixAndMatchDeal.orderLines.forEach(orderLine => combinedQuantitiesByMixAndMatch += orderLine.quantity);
            return combinedQuantitiesByMixAndMatch;
        },

        dealDescriptions() {
            if (!this.specialDeals) {
                return "--------";
            }

            if (this.specialDeals.length == 0) {
                return "none";
            }

            return this.specialDeals.map(deal => `${deal.dealDescription} (${deal.minimumQuantity})`).join(", ");
        },

        depositPrice() {
            return this.item.depositPrice;
        },

        discount() {
            if (this.appliedDiscountAmount == 0) {
                return 0;
            }
            
            return this.item.baseUnitPrice - this.appliedDiscountAmount;
        },

        discountAll() {
            const discount = this.discount;
            const quantity = this.item.quantity;
            return discount * quantity;
        },

        discountDescription() {
            const minQuantities = this.minQuantitiesForDiscount;

            if (minQuantities.length == 1) {
                return `(at least ${minQuantities[0]})`;
            }

            const lastQuantity = minQuantities.pop();
            const joinedWithouLast = minQuantities.join(", ");

            return `(at least ${joinedWithouLast} or ${lastQuantity})`;
        },

        hasMixAndMatchDiscounts() {
            const thisOrderLineId = this.item.orderLineId;

            for (const [ _, deals ] of this.orderLinesByMixAndMatch.entries()) {
                const thisOrderLineIsIncludedInSpecialDeal = deals.orderLines.some(orderLine => orderLine.orderLineId == thisOrderLineId);

                if (thisOrderLineIsIncludedInSpecialDeal) {
                    return true;
                }
            }

            return false;
        },

        hasQuantityDiscount() {
            return this.specialDeals.length > 0;
        },

        hasTypeAndId() {
            const stockItem = this.stockItem;

            if (!stockItem)
                return false;
            
            if (!stockItem.type)
                return false;
            
            if (!stockItem.stockItemId)
                return false;
            
            return true;
        },
        
        isMixAndMatchDiscountApplied() {
            
            if (!this.hasMixAndMatchDiscounts)
                return false;
            
            const thisOrderLineId = this.item.orderLineId;
            let matchingMixAndMatchDeal = [];

            for (const [ _, deals ] of this.orderLinesByMixAndMatch.entries()) {
                const thisOrderLineIsIncludedInSpecialDeal = deals.orderLines.some(orderLine => orderLine.orderLineId == thisOrderLineId);

                if (thisOrderLineIsIncludedInSpecialDeal) {
                    matchingMixAndMatchDeal = deals;
                    break;
                }
            }

            let combinedQuantitiesByMixAndMatch = 0;
            matchingMixAndMatchDeal.orderLines.forEach(orderLine => combinedQuantitiesByMixAndMatch += orderLine.quantity);
            
            const combinedQuantitySurpassesAnyMinQuantityFromSpecialDeals = this.minQuantitiesForDiscount.some(quantity => combinedQuantitiesByMixAndMatch >= quantity);
            return combinedQuantitySurpassesAnyMinQuantityFromSpecialDeals;
        },

        isQuantityDiscountApplied() {
            if (!this.hasQuantityDiscount)
                return false;
            
            const currentQuantity = this.item.quantity;
            return this.minQuantitiesForDiscount.some(quantity => currentQuantity >= quantity);
        },

        isNewQuantityValid() {
            return this.newQuantity && this.newQuantity > 0;
        },

        image() {
            return utils.methods.getProductImage(this.item);
        },

        minQuantitiesForDiscount() {
            const minQuantities = this.specialDeals
                .map(deal => deal.minimumQuantity)
                .sort((a, b) => a - b);

            return [ ...new Set(minQuantities) ];
        },

        quantity() {
            return this.item.quantity;
        },

        stockItem() {
            return this.item.stockItem;
        },

        subTotal() {
            const unitPrice = this.unitPrice;
            const deposit = this.depositPrice;
            const discount = this.discount;
            const quantity = this.item.quantity;
            const subTotal = (unitPrice + deposit - discount) * quantity;
            return subTotal;
        },

        subTotalNoDiscount() {
            const unitPrice = this.unitPrice;
            const deposit = this.depositPrice;
            const quantity = this.item.quantity;
            return (unitPrice + deposit) * quantity;
        },

        unitPrice() {
            return this.item.baseUnitPrice;
        },

        viewProductPath() {
            const stockItem = this.stockItem;

            const rawType = stockItem.type;

            if (!rawType)
                return null;

            const type = rawType.trim().toLowerCase();
            const id = stockItem.stockItemId;

            return `/products/${type}/${id}`;
        },
    }, // computed

    methods: {
        ...mapActions({
            addProductToCart: "order/addProductToCart2",
            getSpecialDeals: "specialDeals/search",
            removeFromCart: 'order/removeFromCart',
        }),

        getAppliedSpecialDealWithMinQuantity(quantity) {
            let appliedSpecialDeal = {};

            for (const [ _, deal ] of this.orderLinesByMixAndMatch.entries()) {
                for (const orderLine of deal.orderLines) {
                    if (orderLine.orderLineId == this.item.orderLineId) {
                        for (const minimumQuantity of deal.minimumQuantities) {
                            if (quantity >= minimumQuantity) {
                                appliedSpecialDeal = deal;
                            }
                        }
                    }
                }
            }

            return appliedSpecialDeal;
        },

        getSpecialDealsRequest() {
            if (this.order) { // Get special deals from order
                const thisOrderLine = this.order.orderLines.find(orderLine => orderLine.orderLineId == this.item.orderLineId);
                const specialDeals = [];

                for (const deal of thisOrderLine.stockItem.specialDeals) {
                    specialDeals.push(deal);
                }

                this.specialDeals = specialDeals;
                this.orderLinesByMixAndMatch = cart.methods.getOrderLinesGroupedByMixAndMatch(this.order);
            }
            else { // Get special deals from request
                const filters = {
                    customerId: this.currentCustomer.customerId,
                    stockItemId: this.item.stockItemId,
                };

                this.getSpecialDeals(filters).then(result => {
                    this.specialDeals = result.data;
                    this.orderLinesByMixAndMatch = new Map();
                });
            }
        },

        onConfirmQuantityChange() {
            this.isUpdatingQuantity = true;

            this.addProductToCart({
                quantity: this.newQuantity - this.item.quantity,
                stockItemId: this.item.stockItemId,
            })
            .then(() => {
                this.isShowingEditQuantityDialog = false;
                window.location.reload();
            })
            .catch(error => {
                console.error("Could not update quantity:", error);
                this.$snotify.error(`Could not update quantity.\n${error}`, {
                    closeOnClick: false,
                    pauseOnHover: true,
                    timeout: 4000,
                });
            })
            .finally(() => this.isUpdatingQuantity = false);
        }, // onConfirmQuantityChange

        onRemoveFromCartYesClicked() {
            this.isRemovingFromCart = true;

            this.removeFromCart({ orderLineId: this.item.orderLineId, removeAll: false})
            .then(() => {
                this.isShowingRemoveFromCartDialog = false;
                this.$snotify.success('The product has been removed!', {
                    closeOnClick: false,
                    pauseOnHover: false,
                    timeout: 1500,
                });
            })
            .catch(error => {
                this.$snotify.error(`Could not remove product. ${error}`, {
                    closeOnClick: false,
                    pauseOnHover: false,
                    timeout: 4000,
                });
            })
            .finally(() => this.isRemovingFromCart = false);
        },

        openRemoveFromCartDialog() {
            this.isShowingRemoveFromCartDialog = true;
        },

        showEditQuantityDialog() {
            this.newQuantity = this.item.quantity;
            this.isShowingEditQuantityDialog = true;
        },

        showGroupedOrderLines() {
            const orderLinesByMixAndMatch = cart.methods.getOrderLinesGroupedByMixAndMatch(this.order);
        },
    }, // methods

    mixins: [ currency, utils ],
}
</script>
