var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pa-4" },
    [
      _c("v-text-field", {
        attrs: { disabled: "", label: "Email" },
        model: {
          value: _vm.person.emailAddress,
          callback: function($$v) {
            _vm.$set(_vm.person, "emailAddress", $$v)
          },
          expression: "person.emailAddress"
        }
      }),
      _c("v-text-field", {
        attrs: { disabled: "", label: "Username" },
        model: {
          value: _vm.person.fullName,
          callback: function($$v) {
            _vm.$set(_vm.person, "fullName", $$v)
          },
          expression: "person.fullName"
        }
      }),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              _c("v-text-field", {
                attrs: { disabled: _vm.isView, label: "First Name" },
                model: {
                  value: _vm.person.firstName,
                  callback: function($$v) {
                    _vm.$set(_vm.person, "firstName", $$v)
                  },
                  expression: "person.firstName"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              _c("v-text-field", {
                attrs: { disabled: _vm.isView, label: "Last Name" },
                model: {
                  value: _vm.person.lastName,
                  callback: function($$v) {
                    _vm.$set(_vm.person, "lastName", $$v)
                  },
                  expression: "person.lastName"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("v-text-field", {
        attrs: { disabled: _vm.isView, label: "Phone Number" },
        model: {
          value: _vm.person.phoneNumber,
          callback: function($$v) {
            _vm.$set(_vm.person, "phoneNumber", $$v)
          },
          expression: "person.phoneNumber"
        }
      }),
      _vm._m(0),
      !_vm.person.isEmployee
        ? _c(
            "div",
            { staticClass: "d-flex justify-end" },
            [
              _vm.isView
                ? _c(
                    "v-btn",
                    {
                      attrs: { color: "error" },
                      on: { click: _vm.editSettings }
                    },
                    [_vm._v("Edit profile")]
                  )
                : _vm._e(),
              !_vm.isView
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        disabled: _vm.isUpdatingPerson,
                        loading: _vm.isUpdatingPerson
                      },
                      on: { click: _vm.saveSettings }
                    },
                    [_vm._v("Save profile")]
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex justify-end mb-4" }, [
      _c("p", { staticClass: "ma-0" }, [_vm._v("Change my password")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }