export default {
    namespaced: true,

    state: {
        isFabVisible: true,
    }, // state

    getters: {
        getIsFabVisible: state => state.isFabVisible,
    }, // getters

    mutations: {
        SET_IS_FAB_VISIBLE(state, isFabVisible) {
            state.isFabVisible = isFabVisible;

            let isFabVisibleStr = isFabVisible == true ? 'true' : 'false';
            localStorage.setItem('fb.isFabVisible', isFabVisibleStr);
            
            // If the social intents chat FAB is setup, dynamically change visibility
            let socialIntentsFab = document.getElementById('silc-btn');
            if (socialIntentsFab) {
                socialIntentsFab.style.display = isFabVisible == true ? 'block' : 'none';
            }
        },
    }, // mutations
};
