import { render, staticRenderFns } from "./Address.vue?vue&type=template&id=382c48dd&"
import script from "./Address.vue?vue&type=script&lang=js&"
export * from "./Address.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\a\\1\\s\\Lnf.Portal\\Lnf.Portal.Web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('382c48dd')) {
      api.createRecord('382c48dd', component.options)
    } else {
      api.reload('382c48dd', component.options)
    }
    module.hot.accept("./Address.vue?vue&type=template&id=382c48dd&", function () {
      api.rerender('382c48dd', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/UserAccount/Address.vue"
export default component.exports