 <template>
    <div>

        <!-- header -->
        <div :style="{ 'background-color': primaryColor }">
            <v-container grid-list-xl>
              <v-layout row wrap align-center justify-space-between pa-0 ma-0>
                <v-flex xs6 sm6 md4 lg3 xl3 px-0>
                  <div class="site-logo d-inline-block">
                      <img alt="site-logo" height="34" :src="appLogo" width="70%" />
                  </div>
                </v-flex>
              </v-layout>
            </v-container>
        </div>

        <!-- main content -->
        <div class="emb-notFound-wrap section-gap d-flex justify-center align-center" style="height: 90vh">
            <div class="container">
                <div class="layout align-center justify-center">
                <v-flex xs12 sm9 md6 lg5 xl5>
                    <div class="emb-card text-center pa-6">
                        <h1>403</h1>
                        <p>Hello <b>{{userEmail}}</b></p>
                        <h5 class="mb-4">Your account seems to be unactive or nonexistent.</h5>
                        <p>If you just signed up, an admin needs to activate your account. If you already had an active or existing account, contact your administrator for more details.</p>
                        <p>You can try to sign in again once your account is activated.</p>
                        <v-btn :loading="isLoggingOut" :disabled="isLoggingOut" block class="accent" @click="logout">Logout</v-btn>
                    </div>
                </v-flex>
                </div>
            </div>
        </div><!-- main content -->
 
        <!-- footer -->
        <div class="footer">
            <v-container class="pa-12">
                <div class="d-flex justify-center mb-6">
                    <div>
                        <h6>About us</h6>
                        <p>{{aboutUs}}</p>
                    </div>
                </div>

                <hr class="mb-10">

                <div class="d-flex justify-center justify-sm-end">
                    <p>{{copyrightText}}</p>
                </div>
            </v-container>
        </div><!-- footer -->
    </div>
</template>

<script>
import UserBlock from "../components/Layouts/Header/UserBlock";

export default {
    data() {
        return {
            appLogo: process.env.VUE_APP_LOGO,
            copyrightText: process.env.VUE_APP_COPYRIGHT,
            primaryColor: process.env.VUE_APP_COLOR_PRIMARY,
            aboutUs: process.env.VUE_APP_ABOUTUS_TEXT,
            isLoggingOut: false,
            userEmail: undefined,
        }
    }, // data

    mounted() {
        let token = this.$msal.getAccountToken();
        this.userEmail = token.idToken.emails[0];
    },

    methods: {
        logout() {
            this.isLoggingOut = true;
            this.$msal.logout();
        }
    },

    components: {
        embUserBlock: UserBlock,
    }, // components
}
</script>

<style scoped>
.top-header {
    background-color: #3A3434;
}

.footer {
    background-color: black;
}

.footer h6,
.footer p {
    color: whitesmoke;
}

.footer hr {
    border: 1px solid rgb(61, 61, 61);
}
</style>
