var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.order && _vm.order.orderLines
    ? _c(
        "v-card",
        { staticClass: "emb-FinalReceipt-wrap" },
        [
          _c(
            "v-card-text",
            { staticClass: "pa-4" },
            [
              _c("v-flex", { attrs: { xs12: "" } }, [
                _c("div", { staticClass: "text-center bg-grey px-4 py-6" }, [
                  _c("h4", [_vm._v("Purchase Order Receipt")]),
                  _c("h5", { staticClass: "mb-6" }, [
                    _vm._v("THIS IS NOT AN INVOICE")
                  ]),
                  _c("h6", { staticClass: "mb-6" }, [
                    _vm._v("Transaction ID:" + _vm._s(_vm.order.orderId))
                  ]),
                  _vm.order.isOrderSubmitted
                    ? _c(
                        "div",
                        [
                          _c(
                            "v-icon",
                            { attrs: { size: "64", color: "green" } },
                            [_vm._v("check_circle")]
                          ),
                          _c(
                            "h5",
                            {
                              staticClass: "text-center ma-0",
                              staticStyle: { color: "green" }
                            },
                            [_vm._v("SUBMITTED")]
                          )
                        ],
                        1
                      )
                    : _c(
                        "div",
                        [
                          _c(
                            "v-icon",
                            { attrs: { size: "64", color: "gray" } },
                            [_vm._v("report_problem")]
                          ),
                          _c(
                            "h5",
                            {
                              staticClass: "text-center ma-0",
                              staticStyle: { color: "gray" }
                            },
                            [_vm._v("PENDING")]
                          )
                        ],
                        1
                      )
                ]),
                _c(
                  "div",
                  { staticClass: "mt-12 mx-4" },
                  [
                    _c(
                      "v-layout",
                      {
                        attrs: {
                          row: "",
                          wrap: "",
                          "mb-10": "",
                          "mx-0": "",
                          "mt-0": ""
                        }
                      },
                      [
                        _c(
                          "v-flex",
                          {
                            attrs: {
                              xs12: "",
                              sm6: "",
                              md6: "",
                              lg6: "",
                              xl6: "",
                              "text-left": "",
                              "pa-0": ""
                            }
                          },
                          [
                            _c("h6", [_vm._v("Summary")]),
                            _c("p", { staticClass: "mb-1" }, [
                              _vm._v("Order ID: "),
                              _c("b", [_vm._v(_vm._s(_vm.order.internalId))])
                            ]),
                            _c("p", { staticClass: "mb-1" }, [
                              _vm._v("Order Date: "),
                              _c("b", [_vm._v(_vm._s(_vm.getOrderDate()))])
                            ]),
                            _c("p", { staticClass: "mb-1" }, [
                              _vm._v("Order Total: "),
                              _c(
                                "b",
                                [
                                  _c("emb-currency-sign"),
                                  _vm._v(" " + _vm._s(_vm.getOrderTotal()))
                                ],
                                1
                              )
                            ])
                          ]
                        ),
                        _c(
                          "v-flex",
                          {
                            attrs: {
                              xs12: "",
                              sm6: "",
                              md6: "",
                              lg6: "",
                              xl6: "",
                              "text-left": "",
                              "pa-0": ""
                            }
                          },
                          [
                            _c("h6", [_vm._v("Deliver To")]),
                            _c("p", { staticClass: "mb-1" }, [
                              _vm._v(_vm._s(_vm.order.customer.customerName))
                            ]),
                            _c("p", { staticClass: "mb-1" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.order.customer.deliveryAddressLine1
                                ) +
                                  ", " +
                                  _vm._s(
                                    _vm.order.customer.deliveryCity.cityName
                                  )
                              )
                            ]),
                            _c("p", { staticClass: "mb-1" }, [
                              _vm._v(
                                _vm._s(_vm.order.customer.deliveryAddressLine2)
                              )
                            ]),
                            _c("p", { staticClass: "mb-1" }, [
                              _vm._v(
                                "Contact No. " +
                                  _vm._s(_vm.order.customer.phoneNumber)
                              )
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    _c("div", { staticClass: "py-6 text-center bg-grey" }, [
                      _c("h4", [_vm._v("Expected Date of Delivery")]),
                      _c("h3", [
                        _vm._v(_vm._s(_vm.getOrderExpectedDeliveryDate()))
                      ])
                    ]),
                    _c(
                      "h4",
                      { staticClass: "pt-12 mb-7 text-sm-left text-center" },
                      [_vm._v("Your Ordered Details")]
                    ),
                    _vm._l(_vm.order.orderLines, function(orderLine, index) {
                      return _c(
                        "v-card",
                        { key: index, staticClass: "ma-0 mb-4 pa-0 px-6" },
                        [
                          _c("OrderLineItem", {
                            attrs: {
                              item: orderLine,
                              canEditQuantity: false,
                              canRemove: false
                            }
                          })
                        ],
                        1
                      )
                    }),
                    _c("v-divider", { staticClass: "mb-4" }),
                    _c(
                      "div",
                      [_c("OrderTotals", { attrs: { order: _vm.order } })],
                      1
                    )
                  ],
                  2
                )
              ])
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }