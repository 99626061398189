var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            [
              !_vm.isView
                ? _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6", md: "4", lg: "3" } },
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex justify-center" },
                                [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        "https://via.placeholder.com/250x250",
                                      alt: "product image",
                                      width: "250",
                                      height: "250"
                                    }
                                  })
                                ]
                              ),
                              _c("v-select", {
                                attrs: {
                                  label: "Image Type",
                                  items: _vm.imageTypesArray,
                                  "item-text": "stockItemImageTypeName",
                                  "item-value": "stockItemImageTypeID"
                                },
                                model: {
                                  value: _vm.imageToAdd.stockItemImageTypeID,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.imageToAdd,
                                      "stockItemImageTypeID",
                                      $$v
                                    )
                                  },
                                  expression: "imageToAdd.stockItemImageTypeID"
                                }
                              }),
                              _c("v-file-input", {
                                attrs: {
                                  disabled:
                                    _vm.imageToAdd.stockItemImageTypeID ==
                                    undefined,
                                  label: "Choose an image"
                                },
                                on: { change: _vm.onImageChosen },
                                model: {
                                  value: _vm.chosenImage,
                                  callback: function($$v) {
                                    _vm.chosenImage = $$v
                                  },
                                  expression: "chosenImage"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._l(_vm.product.stockItemImages, function(image, index) {
                return _c(
                  "v-col",
                  {
                    key: index,
                    attrs: { cols: "12", sm: "6", md: "4", lg: "3" }
                  },
                  [
                    _c(
                      "v-card",
                      [
                        !_vm.isView
                          ? _c(
                              "div",
                              { staticClass: "d-flex justify-end pt-1 pr-1" },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { icon: "", small: "" },
                                    on: {
                                      click: function($event) {
                                        return _vm.onImageRemove(image, index)
                                      }
                                    }
                                  },
                                  [
                                    _c("v-icon", { attrs: { small: "" } }, [
                                      _vm._v("close")
                                    ])
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "v-card-text",
                          [
                            _c(
                              "div",
                              { staticClass: "d-flex justify-center" },
                              [
                                _c("img", {
                                  attrs: {
                                    src: image.location,
                                    alt: "product image",
                                    width: "250",
                                    height: "250"
                                  }
                                })
                              ]
                            ),
                            _c("v-select", {
                              attrs: {
                                label: "Image Type",
                                items: _vm.imageTypesArray,
                                "item-text": "stockItemImageTypeName",
                                "item-value": "stockItemImageTypeID"
                              },
                              on: {
                                change: function($event) {
                                  return _vm.onChangingImageType(
                                    image.stockItemImageTypeID
                                  )
                                }
                              },
                              model: {
                                value: image.stockItemImageTypeID,
                                callback: function($$v) {
                                  _vm.$set(image, "stockItemImageTypeID", $$v)
                                },
                                expression: "image.stockItemImageTypeID"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              })
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }