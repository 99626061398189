<template>
  <div class="responsive-table">
    <v-data-table
      :headers="headers"
      :items="paginatedList.items"
      :server-items-length="paginatedList.totalCount"
      :options.sync="listViewOptions"
      :loading="isLoading"
      :footer-props="{ 'items-per-page-options': [10, 15, 20, 25, 50] }"
    >
      <template v-slot:[`item.action`]="{ item }">
        <Menu :menuItems="rowMenuItems" :menuItemContext="item">
          <v-btn
            small
            icon
            class="primary--text" 
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </Menu>
      </template>
    </v-data-table>
    <YesNoDialog ref="yesNoDialog" />
  </div>
</template>

<script>
import { MenuItem } from '../../../../../components/Menu/MenuItem';
import { tableProps } from '../../../../../mixins/tableProps';
export default {
  name: "SubscriptionFileTypeAssociationsTable",
  mixins:[tableProps],
  data() {
    return {
      rowMenuItems:[
        new MenuItem({
          icon: "edit",
          label: "Edit",
          action: (item) => {
            this.$router.push(
              "/admin-panel/subscriptionFileTypeAssociations/edit/" + item.x12subscriptionFileTypeAssociationId
            );
          },
        }),
        new MenuItem({
          icon: "delete",
          label: "Delete",
          action: (item) => {
            this.showDeleteDialog(item.x12subscriptionFileTypeAssociationId);
          },
        }),
      ],
      headers: [
        {
          text: "Sub File Type Association Id",
          sortable: true,
          value: "x12subscriptionFileTypeAssociationId",
        },
        {
          text: "Trading Partner Id",
          sortable: true,
          value: "x12tradingPartnerId",
        },
        {
          text: "File TypeVersion Id",
          sortable: true,
          value: "x12fileTypeVersionId",
        },
        {
          text: "Actions",
          sortable: false,
          value: "action",
        },
      ],
    };
  },
  
};
</script>