var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "home-banner-v3 pt-12" },
    [
      _c(
        "v-container",
        { attrs: { "grid-list-xl": "" } },
        [
          _c(
            "slick",
            { ref: "carousel", attrs: { options: _vm.slickOptions } },
            _vm._l(_vm.data, function(sliderItem, key) {
              return _c(
                "div",
                { key: key, staticClass: "slider-wrap text-center" },
                [
                  _c("div", { staticClass: "slider-item relative" }, [
                    _c("img", {
                      attrs: {
                        src: sliderItem.image,
                        alt: "slide-item",
                        width: "100%",
                        height: "660"
                      }
                    }),
                    _c("div", { staticClass: "slider-content-v3" }, [
                      _c(
                        "div",
                        { staticClass: "slider-content-v3-inner ma-auto" },
                        [
                          _c("p", { staticClass: "text-inverse" }, [
                            _vm._v(_vm._s(sliderItem.subHeading))
                          ]),
                          _c(
                            "h4",
                            { staticClass: "font-bold mb-6 white--text" },
                            [_vm._v(_vm._s(sliderItem.heading))]
                          ),
                          _c("v-btn", { attrs: { to: "/products" } }, [
                            _vm._v("SHOP NOW")
                          ])
                        ],
                        1
                      )
                    ])
                  ])
                ]
              )
            }),
            0
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }