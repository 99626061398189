var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sidebar-menu" },
    [
      _c(
        "emb-perfect-scrollbar",
        { staticClass: "scroll-area", attrs: { settings: _vm.settings } },
        [
          _c(
            "v-list",
            [
              _c(
                "div",
                {
                  staticClass: "close-btn-sidebar layout justify-end mx-4 pt-2"
                },
                [
                  _c(
                    "a",
                    {
                      attrs: { href: "javascript:void(0)" },
                      on: { click: _vm.toggleMobileSidebar }
                    },
                    [
                      _c(
                        "i",
                        {
                          staticClass: "material-icons close-icon black--text"
                        },
                        [_vm._v(" close")]
                      )
                    ]
                  )
                ]
              ),
              _vm._l(_vm.menus, function(menu) {
                return [
                  menu.children !== null
                    ? [
                        _c(
                          "v-list-group",
                          {
                            key: menu.title,
                            attrs: { light: "", "no-action": "" },
                            model: {
                              value: menu.active,
                              callback: function($$v) {
                                _vm.$set(menu, "active", $$v)
                              },
                              expression: "menu.active"
                            }
                          },
                          [
                            _c(
                              "v-list-item",
                              {
                                staticClass: "px-0",
                                attrs: { slot: "activator", to: menu.path },
                                slot: "activator"
                              },
                              [
                                _c(
                                  "v-list-item-action",
                                  { staticClass: "mr-0" },
                                  [_c("v-icon", [_vm._v(_vm._s(menu.icon))])],
                                  1
                                ),
                                _c("v-list-item-title", [
                                  _vm._v(" " + _vm._s(_vm.$t(menu.name)) + " ")
                                ])
                              ],
                              1
                            ),
                            menu.type === "sub_menu"
                              ? [
                                  _vm._l(menu.children, function(subItem) {
                                    return [
                                      subItem !== null
                                        ? _c(
                                            "v-list-item",
                                            {
                                              key: subItem.title,
                                              staticClass: "sub-menu-item",
                                              attrs: { to: subItem.path }
                                            },
                                            [
                                              subItem.children_menus == null
                                                ? [
                                                    _c(
                                                      "v-list-item-action",
                                                      { staticClass: "mr-0" },
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v(
                                                            "arrow_right_alt"
                                                          )
                                                        ])
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-list-item-title",
                                                      {
                                                        staticClass: "sub-item"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(subItem.name)
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                : [
                                                    _c(
                                                      "v-list-group",
                                                      {
                                                        staticClass:
                                                          "submenu-child",
                                                        attrs: {
                                                          "no-action": "",
                                                          "sub-group": "",
                                                          value: "true",
                                                          "prepend-icon":
                                                            "mdi mdi-chevron-down"
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function() {
                                                                return [
                                                                  _c(
                                                                    "v-list-item-title",
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          staticClass:
                                                                            "pr-2"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "arrow_right_alt"
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            subItem.name
                                                                          )
                                                                        )
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              },
                                                              proxy: true
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      _vm._l(
                                                        subItem.children_menus,
                                                        function(item, i) {
                                                          return _c(
                                                            "v-list-item",
                                                            {
                                                              key: i,
                                                              attrs: {
                                                                link: "",
                                                                to: item.path
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                {
                                                                  staticClass:
                                                                    "sub-item"
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      staticClass:
                                                                        "pr-2"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "arrow_right_alt"
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        item.name
                                                                      )
                                                                    )
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    )
                                                  ]
                                            ],
                                            2
                                          )
                                        : _vm._e()
                                    ]
                                  })
                                ]
                              : [
                                  _vm._l(menu.children, function(
                                    subItem,
                                    subItemKey
                                  ) {
                                    return [
                                      subItem !== null
                                        ? _c(
                                            "v-list-group",
                                            {
                                              key: subItemKey,
                                              staticClass: "submenukey",
                                              attrs: {
                                                to: subItem.path,
                                                "no-action": "",
                                                "sub-group": "",
                                                "prepend-icon":
                                                  "mdi mdi-chevron-down"
                                              },
                                              model: {
                                                value: _vm.subGroup[subItemKey],
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.subGroup,
                                                    subItemKey,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "subGroup[subItemKey]"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-list-item",
                                                {
                                                  attrs: { slot: "activator" },
                                                  slot: "activator"
                                                },
                                                [
                                                  _c(
                                                    "v-list-item-title",
                                                    {
                                                      staticClass:
                                                        "megamenu-key pl-1"
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        { staticClass: "pr-2" },
                                                        [
                                                          _vm._v(
                                                            "arrow_right_alt"
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(subItemKey)
                                                        )
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._l(subItem, function(
                                                megaChild,
                                                megaChildKey
                                              ) {
                                                return _c(
                                                  "v-list-item",
                                                  {
                                                    key: megaChildKey,
                                                    staticClass:
                                                      "mega-menu-item",
                                                    attrs: {
                                                      to: megaChild.path
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-list-item-action",
                                                      {
                                                        staticClass: "mr-0 pl-1"
                                                      },
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v(
                                                            "arrow_right_alt"
                                                          )
                                                        ])
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-list-item-title",
                                                      {
                                                        attrs: { value: true }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              megaChild.name
                                                            )
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              })
                                            ],
                                            2
                                          )
                                        : _vm._e()
                                    ]
                                  })
                                ]
                          ],
                          2
                        )
                      ]
                    : [
                        _c(
                          "v-list-item",
                          { key: menu.title, attrs: { to: menu.path } },
                          [
                            _c(
                              "v-list-item-action",
                              { staticClass: "mr-0" },
                              [_c("v-icon", [_vm._v(_vm._s(menu.icon))])],
                              1
                            ),
                            _c(
                              "v-list-item-content",
                              [
                                _c("v-list-item-title", [
                                  _vm._v(" " + _vm._s(_vm.$t(menu.name)) + " ")
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                ]
              })
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }