<template>
  <div class="responsive-table">
    <v-data-table 
    :headers="headers"
    :loading="isLoading"
    :items="paginatedList.items"
    :server-items-length="paginatedList.totalCount"
    :options.sync="listViewOptions"
    :footer-props="{ 'items-per-page-options': [10, 15, 20, 25, 50] }"

    >
    <template v-slot:[`item.action`]="{ item }">
        <Menu :menuItems="rowMenuItems" :menuItemContext="item">
          <v-btn
            small
            icon
            class="primary--text" 
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </Menu>
      </template>
     </v-data-table>
     <YesNoDialog ref="yesNoDialog" />
  </div>
</template>

<script>
import { MenuItem } from '../../../../components/Menu/MenuItem';
import { tableProps } from "../../../../mixins/tableProps";
import { yesNoDialogMixin } from "../../../../mixins/showYesNoDialog";
export default {
  mixins: [tableProps,yesNoDialogMixin],
  name: "DateTimeQualifiersTable",
  data() {
    return {
      rowMenuItems:[
        new MenuItem({
          icon: "edit",
          label: "Edit",
          action: (qualifiers) => {
            this.$router.push(
              "/admin-panel/definitions/dateTimeQualifiers/edit/" + qualifiers.dateTimeQualifierId
            );
          },
        }),
        new MenuItem({
          icon: "delete",
          label: "Delete",
          action: (qualifiers) => {
            this.showDeleteDialog(qualifiers.dateTimeQualifierId);
          },
        })
      ],
      headers: [
        {
          text: "Date Time Qualifier Id",
          sortable: true,
          value: "dateTimeQualifierId",
        },
        {
          text: "Date Time Qualifier Description",
          sortable: true,
          value: "dateTimeQualifierDescription",
        },
        {
          text: "Date Time Qualifier",
          sortable: true,
          value: "dateTimeQualifier",
        },
        {
          text: "Actions",
          sortable: false,
          value: "action",
        },
      ],
    };
  },  
};
</script>