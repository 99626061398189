var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "profile-wrapper emb-card pa-4" },
    [
      _c(
        "v-expansion-panels",
        { attrs: { multiple: "", focusable: "", value: [0, 1] } },
        [
          _c(
            "v-expansion-panel",
            [
              _c("v-expansion-panel-header", [_vm._v("General Information")]),
              _c(
                "v-expansion-panel-content",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          attrs: {
                            cols: "12",
                            xs: "12",
                            sm: "12",
                            md: "6",
                            lg: "6",
                            xl: "6"
                          }
                        },
                        [
                          _c("v-text-field", {
                            staticClass: "name-input",
                            attrs: {
                              disabled: _vm.isView,
                              label: "Customer Code",
                              required: ""
                            },
                            model: {
                              value: _vm.account.customerId,
                              callback: function($$v) {
                                _vm.$set(_vm.account, "customerId", $$v)
                              },
                              expression: "account.customerId"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            cols: "12",
                            xs: "12",
                            sm: "12",
                            md: "6",
                            lg: "6",
                            xl: "6"
                          }
                        },
                        [
                          _c("v-text-field", {
                            staticClass: "name-input",
                            attrs: {
                              disabled: _vm.isView,
                              label: "TABC id",
                              required: ""
                            },
                            model: {
                              value: _vm.account.alternateId,
                              callback: function($$v) {
                                _vm.$set(_vm.account, "alternateId", $$v)
                              },
                              expression: "account.alternateId"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }