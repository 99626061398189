<template>
  <div>
    <AdminPanelHeader
      icon="mdi-dolly"
      title="File Type Versions"
      :actions="headerActions"
      @search="onSearch"
    />

    <X12FileTypesVersionsTable
      :paginatedList="currentEntity"
      :isLoading="isLoading"
      :searchParams="paginatedQueryParams"
      v-on:onDelete="onDelete"
    />
  </div>
</template>

<script>
import apiClient from "../../../../apiClient";
import {AdminPanelHeaderAction} from "../../../../components/AdminPanelHeader/AdminPanelHeaderAction";
import { parentComponentMixin } from "../../../../mixins/parentComponentMixin";
import {showDialog} from '../../../../components/Dialogs/NotifyDialog'
export default {
  mixins: [parentComponentMixin],
  name:'X12FileTypeVersion',
  data() {
    return {
      headerActions: [
        new AdminPanelHeaderAction({
          color: "accent",
          label: "Add new",
          icon: "add",
          action: () => this.$router.push("/admin-panel/definitions/fileTypesVersion/add"),
        }),
      ],
      paginatedQueryParams: {
        pageSize: 10,
        pageNumber: 0,
        searchValue: "",
        sortField: "x12fileTypeVersionId",
        sortDirection: "DESC",
      },
    };
  },
  
  methods: {
    async onDelete(id) {
      try {
        this.isLoading = true;
        await apiClient.X12("X12FileTypesVersion").delete(id);
        showDialog('Record deleted successfully')
        await this.getPaginated();
      } catch (error) {
        showDialog('Could not delete record',error)
      }finally{
        this.isLoading = false;
      }
    },
    async getPaginated() {
      try {
        this.isLoading = true;
        this.currentEntity = await apiClient.X12("X12FileTypesVersion").getPaginated(
          this.paginatedQueryParams
        );
      } catch (error) {
        showDialog("Could not load File Types Versions data",error)
      } finally {
        this.isLoading = false;
      }
    },
  },
  
};
</script>
