<template>
  <!-- eslint-disable -->
  <div class="emb-termCondition-wrap">

    <emb-page-title
      heading="Terms and Conditions"
      subHeading="Please read and contact if you have some issue. ">
    </emb-page-title>

    <!-- main container -->
    <div class="emb-tnc section-gap">
      <div class="container">
        <div class="tnc-content">
          
          <!-- main headlines -->
          <section class="mb-8">

            <h5 class="mb-6">{{mainTitle}}</h5>

            <p>
              Your use of orders.lnfdistributors.com, other websites, and any related offerings 
              ("<strong>Service</strong>") is subject to these U.S.Terms of Service ("<strong>Terms</strong>").
            </p>

            <p>
              THESE TERMS ARE A LEGAL AGREEMENT BETWEEN YOU AND L&F Distributors, LLC (“<strong>L&F</strong>”). 
              UNLESS YOU OPT OUT OF ARBITRATION WITHIN 30 DAYS OF ACCEPTING THESE TERMS (AS DESCRIBED BELOW), 
              YOU WILL BE REQUIRED TO USE BINDING ARBITRATION TO RESOLVE DISPUTES WITH L&F. PLEASE READ THESE TERMS CAREFULLY.
            </p>

            <p>
              THE SERVICE IS FOR PERSONS 21 YEARS OF AGE AND OLDER. YOU MAY NOT USE OUR SERVICE IF YOU ARE UNDER 21. YOU MAY
              NOT PURCHASE FOR, PICK-UP FOR, OR SEND A DELIVERY OR SHIPMENT TO ANYONE UNDER 21. DO NOT SEND US INFORMATION ABOUT ANYONE UNDER 21.
            </p>

            <p>If you have any questions about these Terms, please contact L&F at __________.</p>

          </section><!-- main headlines -->

          <!-- conditions -->
          <section class="mb-8">
            <ol>
              <li v-for="condition of conditions" class="font-weight-bold" style="font-size: 18px;">
                
                <h5 v-if="condition.title">{{condition.title}}</h5>

                <!-- paragraphs -->
                <template v-if="condition.paragraphs">
                  <p v-for="text of condition.paragraphs" class="font-weight-regular">
                    {{text}}
                  </p>
                </template><!-- paragraphs -->

                <!-- sub-conditions -->
                <template v-if="condition.subConditions">
                  <ol type="a">
                    <li v-for="subCondition of condition.subConditions" class="font-weight-bold" style="font-size: 18px;">
                  
                      <h5 v-if="subCondition.title">{{subCondition.title}}</h5>

                      <!-- paragraphs -->
                      <template v-if="subCondition.paragraphs">
                        <p v-for="text of subCondition.paragraphs" class="font-weight-regular">
                          {{text}}
                        </p>
                      </template><!-- paragraphs -->

                    </li>
                  </ol>
                </template><!-- sub-conditions -->

              </li>
            </ol>
          </section><!-- conditions -->

          <h6 class="text-center">
            LAST UPDATED <strong>{{lastUpdated}}</strong>
          </h6>

        </div>
      </div>
    </div><!-- main container -->

  </div>
</template>

<script>
/*eslint-disable */

export default {
  data: () => ({
    mainTitle: "TERMS OF SERVICE (U.S.)",
    conditions: [
      {
        title: "Shopping with Orders.LNFDISTRIBUTORS.com",
        paragraphs: [
          'L&F does not sell, offer to sell or solicit sales of alcohol: our Service enables you to search online for alcohol and other products available for sale by licensed alcohol retailers that use L&F e-commerce service ("Retailers"). The Service is not meant to facilitate any improper furnishing of inducements by any manufacturer, importer, supplier, wholesaler or distributor of alcohol beverages to any retailer of alcoholic beverages or to facilitate any improper exclusionary practices by any alcohol beverage licensee. When you search for a product, the Service shows you its availability, price and other information based on your location and information provided by the Retailers that service your location; if you prefer, you may sort and filter results based on search criteria. Our Service may be limited or not available at all in some places due to local law or other restrictions.',
          "When you order, it is an offer to purchase from a Retailer. The total purchase amount (plus any additional service fees) may be authorized by your payment card company, but your offer is not accepted by the Retailer at that time. The Retailer reviews your order and decides whether to accept it. If the Retailer decides to accept your order, they will charge your payment card for the purchase amount (plus any additional service fees) and arrange for the delivery, pick-up, or shipment. (See Section 4 for information about fees.)",
          "L&F does not guarantee shipping or delivery times and is not liable for the actions, products, or content of Retailers.",
        ],
      },
      {
        title: "Account Registration",
        paragraphs: [
          "The information you provide must be accurate and complete, and you must keep it current. Use your legal name and actual date of birth on all accounts and orders you create, or your order may be declined and you may be charged restocking fees. You are responsible for use of your account, so do not let anyone else use it. Keep your username and password confidential. If you suspect unauthorized activity notify L&F, at ________, immediately.",
          "If you wish to deactivate your account for any reason, send a request to L&F, at _________. If L&F wishes to deactivate your account for any reason, it may do so in its sole discretion without notice to you. L&F may, but is not obligated to, reactivate an account upon written request to it at _________.",
        ],
      },
      {
        title: "License and Access",
        paragraphs: [
          "L&F grants you a limited, personal, nonexclusive, non-transferable, non-sublicensable license to access and make personal and non-commercial use of the Service. You may use the Service only as permitted by these Terms and law. All rights not expressly granted in these Terms are reserved. You may not resell or otherwise make commercial use of the Service or its contents; collect and use any product listings, descriptions, or prices; make any derivative use of the Service or its contents; download, copy, or otherwise use account information for the benefit of any third party; use data mining, robots, or similar data gathering and extraction tools; or otherwise misuse the Service. This license will terminate if you do not comply with these Terms.",
          'We license the Service to any US Government users as a "Commercial Item" as defined in 48 C.F.R. § 2.101), and the rights granted are the same as we grant to all others under these Terms.',
        ],
      },
      {
        title: "Prices, taxes and fees",
        paragraphs: [
          "Retailers set the price of their products on order.lnfdistributors.com, and Retailers and their delivery providers may charge you additional fees such as shipping and delivery fees, redelivery fees (see Section 7b), and restocking fees (see Section 7b). Your order, including delivery fees, may be subject to tax. The amount of the tax depends on things like the products ordered, delivery location, and the legal obligations of the Retailer.",
          "When you use the Service, you may incur separate and additional charges by your wireless and internet provider(s).",
        ],
      },
      {
        title: "Changes to orders",
        paragraphs: [
          "If you wish to change your order for any reason after it has been placed, you may contact L&F, at ____________, but we cannot guarantee that your changes will be accepted by the Retailer. If they are accepted, you will be responsible for any difference in charges and fees resulting from the change(s).",
        ],
      },
      {
        title: "ID verification; other safety checks",
        paragraphs: [
          "It is the responsibility of the Retailer and any delivery provider they use to verify identification and age, and to determine whether it is otherwise safe and appropriate to furnish you with alcohol. They may require a valid form of photo identification with birthdate and a signed acknowledgment confirming acceptance at the time of pick-up or delivery. If they cannot verify your age or identity, if you appear intoxicated, or if the situation is otherwise unsafe or inappropriate (for example, you are 25 but appear to be in the company of only 18-year olds), they may refuse to furnish you with alcohol.",
          "Alcohol cannot be left unattended—someone 21 or older must be present to accept and sign for it. If a Retailer is unable to deliver your product for this or a similar reason, the Retailer may charge a restocking fee (see Section 7b). Subsequent delivery attempts are at the discretion of the Retailer and are subject to redelivery fees (see Section 7b).",
          "If we believe that your order is fraudulent or unlawful, we may alert the Retailer and suspend your use of the Service.",
        ],
      },
      {
        subConditions: [
          {
            title: "Retailer right to refuse, cancel and adjust orders",
            paragraphs: [
              "A Retailer may refuse, cancel or adjust your order for any reason. If the product you want is not available, the Retailer will notify you and offer a reasonable substitute or refund your money. If you accept the substitution, the order total will be adjusted accordingly.",
              "Some Retailers limit where they deliver (for example, some Retailers will not deliver to hotels and restaurants). If a Retailer policy prohibits delivery to your address, the Retailer will notify you, cancel your order and issue a refund.",
              "Sometimes the law requires Retailers to limit the number or type of product(s) in your order. If this happens, the Retailer will notify you and offer a reasonable substitute or refund your money. If you accept the substitution, the order total will be adjusted accordingly.",
            ],
          },
          {
            title: "Retailer right to refuse multiple delivery attempts and charge restocking and re-delivery fees",
            paragraphs: [
              "A Retailer or their delivery provider may refuse to deliver your order if they believe that furnishing alcohol to you or the person accepting the delivery on your behalf is illegal, unsafe or inappropriate. If a delivery cannot be made for this or any other reason, you may be charged a restocking fee. Subsequent delivery attempts are at the discretion of the Retailer and are subject to redelivery fees.",
            ],
          },
        ],
      },
      {
        title: "Errors",
        paragraphs: [
          "Sometimes product information—such as images, descriptions, availability, pricing and reviews—contains errors. If a Retailer identifies such an error, they will notify you and either offer a reasonable substitute or refund your money. If you accept the substitute, the order total will be adjusted accordingly.",
          "If you receive a product that is not as described (or that you did not order), your sole remedy is to notify the Retailer and the Retailer will in its sole discretion either allow you to return the unused product (within the time period specified by the Retailer) for a refund or provide you with a reasonable substitute. If you accept the substitution, the order total will be adjusted accordingly. (See Section 10 for more information.)",
        ],
      },
      {
        title: "Gift Orders",
        paragraphs: [
          "When you send a gift order, provide the full name, email address, delivery address and phone number of the gift recipient. If you choose to keep the gift a surprise, we will not inform your gift recipient of the order, and you assume the risk that they will not be present to receive the order, including any redelivery or restocking fees. If you choose to notify your gift recipient of the gift, they will receive a message informing them of their gift and have the opportunity to schedule the delivery or pick the gift order up from the Retailer (the name of the Retailer will be shown on the gift order notice). If permitted by the Retailer, the gift recipient can also designate someone to pick up or receive the gift on their behalf. ",
          "Your gift recipient or their designate may be required to present a valid form of photo identification with birth date and a signed acknowledgment confirming acceptance at the time of pick-up or delivery. As with any order, a Retailer (or their delivery provider) may refuse or cancel a gift order or delivery for any reason (see Section 7). ",
        ],
      },
      {
        title: "Returns, refunds, and exchanges",
        paragraphs: [
          "Retailers and the laws in your state determine whether a return, refund or exchange is available to you. For example, you may be able to return a tainted or damaged product for a refund or credit, or exchange it for something else. In some states, you can exchange a product you bought (or received as a gift) if you want something else.",
        ],
      },
      {
        title: "Privacy and communications",
        paragraphs: [
          "Use of the Service is subject to our Privacy Policy. When you use the Service or send e-mails, text messages, or other communications from your desktop or mobile device to us, you may be communicating with us electronically. You consent to receive electronic communications from us, and you agree that such electronic communications satisfy any legal requirement that a document be in writing. ",
        ],
      },
      {
        title: "Promotional and referral codes",
        paragraphs: [
          "Your eligibility to use promotional or referral codes (or credits) depends on the laws in your state. The codes are non-transferable, and may be used only with the Service. Codes cannot be redeemed for cash, and expire in one year unless otherwise specified. L&F, in its sole discretion, decides whether to accept codes created by any third party.",
          "If you attempt to evade restrictions on code redemption, L&F may decline to redeem your code.",
        ],
      },
      {
        title: "L&F ownership of the Service",
        paragraphs: [
          "L&F and its licensors own the Service and its contents, including patent, copyright, trade secret, trademark, show-how, know-how and any other US or international intellectual property rights therein. ",
        ],
      },
      {
        title: "Additional Apple Device Terms",
        paragraphs: [
          `If you use the L&F App on an Apple device (e.g., iPhone, iPad, iPod Touch) (any such device, an "Apple Device"); these additional terms apply to your use of the Service on such Apple device. We are solely responsible for the L&F App on your Apple Device, and you may use the L&F App on your Apple Device only as permitted by these Terms and the "Usage Rules" set forth in the Application Store Terms and Conditions (http://www.apple.com/legal/itunes/appstore/us/terms.html). As between L&F and Apple Inc., to the extent that: (a) we are required to address any claims related to your or a third party's use or possession of the L&F App on your Apple Device, we will be responsible for addressing, investigating, or defending the claim; and (b) we have not effectively disclaimed any warranties relating to the L&F App on your Apple Device, we will be solely responsible for any product warranties or other claims, losses, liabilities, damages, costs or expenses if the L&F App fails to conform to any warranty. If the L&F App on your Apple Device fails to conform to any applicable warranty, you may notify Apple Inc. for a refund of the purchase price of the L&F App on your Apple Device, if any. Apple Inc. will not have any other warranty obligations whatsoever with respect to the L&F App on your Apple Device. Apple Inc. and its subsidiaries are third party beneficiaries to these Terms as relating to the L&F App on Apple Devices, and Apple Inc. and its subsidiaries may enforce these Terms against you as a third-party beneficiary of these Terms.`,
        ],
      },
      {
        title: "Content you provide",
        paragraphs: [
          `You may post reviews, comments, photos, videos, and other content; send e-cards and other communications; and submit suggestions, ideas, comments, questions, or other information, if the content is not illegal, obscene, threatening, defamatory, invasive of privacy, infringing of intellectual property rights (including publicity rights), or otherwise injurious or objectionable to us or others, and does not contain software viruses, political campaigning, commercial solicitation, chain letters, mass mailings, or any form of "spam" or unsolicited commercial electronic messages. You may not use a false e-mail address, impersonate any person or entity, or otherwise mislead as to the origin of a gift or other content. We do not regularly review posted content, but we do reserve the right to remove or edit such content. We may use this content for any purpose, without restrictions, and without notice or compensation, and you grant us a perpetual, royalty-free, irrevocable, sublicensable right to use, reproduce, modify, adapt, publish, perform, translate, create derivative works from, distribute, and display such content throughout the world in any media. You also grant us the right to use the name you submit in connection with such content.`,
        ],
      },
      {
        title: "Third party offerings",
        paragraphs: [
          `L&F does not examine, warrant or endorse any third party sites and apps to which the Service links, and is not liable for your use of them. `,
        ],
      },
      {
        title: "Disclaimer of warranties",
        paragraphs: [
          `USE OF THE SERVICE IS AT YOUR OWN RISK. THE SERVICE AND EVERYTHING RELATED TO IT IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. L&F MAKES NO EXPRESS OR IMPLIED WARRANTY OF ANY KIND, SUCH AS WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT, AND WARRANTIES IMPLIED FROM ANY COURSE OF DEALING OR USAGE OF TRADE. NO ORAL OR WRITTEN INFORMATION OR ADVICE PROVIDED BY L&F OR ITS AUTHORIZED AGENTS OR REPRESENTATIVES WILL BE DEEMED TO CREATE A WARRANTY. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES SO SOME OR ALL OF THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU.`,
          `L&F MAKES NO REPRESENTATION OR WARRANTY AS TO WHETHER IT IS LEGAL FOR YOU TO USE OUR SERVICE.`,
        ],
      },
      {
        title: "Limitation of liability",
        paragraphs: [
          `L&F IS NOT LIABLE FOR DAMAGES OF ANY KIND ARISING OUT OF OR RELATED TO YOUR USE OF THE SERVICE, INCLUDING DIRECT, INDIRECT, SPECIAL, EXEMPLARY, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, LOSS OF USE, DATA OR PROFITS OR ANY OTHER DAMAGES OR LOSSES OF ANY KIND. DRIZLY IS NOT BE LIABLE FOR ANY DEFAMATORY, OFFENSIVE, ILLEGAL OR OTHER CONDUCT OF ANY RETAILER, TRANSPORTATION PROVIDER, ADVERTISER, OR OTHER USER OF THE SERVICE. NEITHER L&F NOR ANY RETAILER IS LIABLE FOR ANY DAMAGES CAUSED BY WEATHER CONDITIONS AND OTHER “ACTS OF GOD,” SUCH AS UNFORESEEABLE DELAYS OR ACCIDENTS, PUBLIC UNREST, CONFISCATION AND NATURAL DISASTERS. `,
          `If an arbitrator or a court finds L&F liable for damages notwithstanding the foregoing, L&F’s total liability for all damages shall not exceed the amount paid by you to L&F for your use of the Service. `,
        ],
      },
      {
        title: "Updates",
        paragraphs: [
          `We may update these Terms at any time. Updates are effective as of the “Last updated” date at the end of these Terms. Your use of the Service after an update is your acceptance of the updated Terms. If any part of the Terms is found invalid, void, or unenforceable, that part shall be severed and not affect any remaining term or condition.`,
        ],
      },
      {
        subConditions: [
          {
            title: "Dispute resolution",
            paragraphs: [
              "UNLESS YOU OPT OUT WITHIN 30 DAYS OF ACCEPTING THESE TERMS (AS DESCRIBED BELOW), ANY DISPUTE OR CLAIM RELATING TO THE SERVICE OR THESE TERMS MUST BE RESOLVED WITH BINDING ARBITRATION RATHER THAN IN COURT, EXCEPT THAT YOU MAY ASSERT CLAIMS IN SMALL CLAIMS COURT. CLAIMS MUST BE BROUGHT WITHIN ONE YEAR OF THE EVENTS GIVING RISE TO THEM, AND ON AN INDIVIDUAL BASIS AND NOT IN A CLASS, CONSOLIDATED OR REPRESENTATIVE ACTION. THERE IS NO JUDGE OR JURY IN ARBITRATION. AN ARBITRATOR CAN AWARD THE SAME DAMAGES AND RELIEF AS A COURT (INCLUDING INJUNCTIVE AND DECLARATORY RELIEF OR STATUTORY DAMAGES) AND MUST FOLLOW THESE TERMS AS A COURT WOULD. COURT REVIEW OF AN ARBITRATION AWARD IS LIMITED.",
              "To begin the dispute resolution process, notify L&F of your claim by sending certified mail via U.S. Postal Service to L&F Distributors, LLC, Attn: Annie Cullen, Legal Department, 119 S. Main St., Victoria, Texas 77904. You must include: (a) a description of your claim or dispute; (b) the specific relief you want; and (c) your name, address and contact information. If the claim is not resolved within 30 days of your notice, you or L&F may begin formal arbitration. If we intend to begin arbitration, we will notify you in writing using the contact information you provide.",
              "Any arbitration hereunder shall be administered in accordance the International Institute for Conflict Prevention, Resolution (“CPR”) Rules for Non-Administered Arbitration (the “Rules”), and to the maximum extent applicable, the Federal Arbitration Act (Title 9 of the United States Code).  The parties hereto acknowledge and agree that the relationship between the parties, including the transaction contemplated herein, involves interstate commerce.  If it is determined that the Federal Arbitration Act is inapplicable, Disputes shall be resolved in accordance with the provisions of the Texas General Arbitration Act.  All issues are for the arbitrator to decide, including the scope and enforceability of this arbitration clause, but these Terms bind the arbitrator. The CPR’s rules govern filing, administrative and arbitrator fees. Unless an arbitrator decides your claims are frivolous, we will reimburse those fees for claims totaling less than $10,000 and will not seek attorneys' fees and costs. You may choose to have the arbitration conducted by telephone, in writing, or in person in the State of Texas or at another mutually agreed location.",
            ],
          },
          {
            title: "Opting Out of Arbitration",
            paragraphs: [
              "IF YOU DO NOT WANT TO USE ARBITRATION TO RESOLVE YOUR DISPUTES WITH L&F, NOTIFY L&F IN WRITING OF YOUR DESIRE TO OPT OUT OF ARBITRATION BY WRITTEN NOTIFICATION VIA U.S. POSTAL SERVICE CERTIFIED MAIL TO L&F DISTRIBUTORS, LLC, ATTN: ANNIE CULLEN, LEGAL DEPARTMENT, 119 S. MAIN ST., VICTORIA, TEXAS 77904. YOU MUST PROVIDE THIS NOTICE WITHIN 30 DAYS OF ACCEPTING THESE TERMS. IF YOU DO NOT OPT OUT WITHIN THE 30-DAY PERIOD, ARBITRATION WILL BE REQUIRED. AGREEING TO ARBITRATION IS AN IMPORTANT DECISION. YOU SHOULD NOT RELY SOLELY ON THE INFORMATION CONTAINED IN THESE TERMS TO MAKE YOUR DECISION, AS IT IS NOT INTENDED TO PROVIDE A COMPLETE EXPLANATION OF THE CONSEQUENCES OF ARBITRATION.",
            ],
          },
        ],
      },
      {
        title: "Applicable Law",
        paragraphs: [
          `The laws of the State of Texas, regardless of its conflicts of law rules, govern these Terms to the extent not preempted by The Federal Arbitration Act. The state and federal courts located in the Hidalgo County, Texas shall have jurisdiction over all claims or disputes that are not subject to arbitration. Solicitations, offers, advertisements and communications are void where prohibited.`,
          `You must comply with any local, state or national laws applicable to your use of the Service, such as rules and regulations regarding the sale, service, transportation, import, shipment or delivery of alcoholic beverages. You must comply with all applicable U.S. or other export and re-export restrictions.`,
        ],
      },
      {
        title: "Communications Decency Act",
        paragraphs: [
          `Parental control protections (such as computer hardware, software, or filtering services) may help you limit access to material on the Internet that may be harmful to minors. Such tools are available for purchase online. We have not tested or evaluated these tools and cannot attest to their quality. `,
        ],
      },
    ],
    lastUpdated: "September 8, 2020",
  }), // data
};
</script>
