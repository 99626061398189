export default [
   {
      image: "static/images/h-slider-1.jpg",
      subHeading: "2019 Latest Collection",
      heading: "New Fashion Collection",
   },
   {
      image: "static/images/h-slider-2.jpg",
      subHeading: "2019 Latest Collection",
      heading: "Summer Time Collection",
   },
   {
      image: "static/images/h-slider-3.jpg",
      subHeading: "2019 Latest Collection",
      heading: "Men's Suiting and Clothing",
   },
]