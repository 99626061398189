var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.person
    ? _c(
        "div",
        { staticClass: "px-sm-4 px-md-12" },
        [
          _c("v-text-field", {
            staticClass: "tab-input-title mb-4",
            attrs: {
              disabled: _vm.isView,
              "hide-details": "",
              "prepend-icon": "person",
              required: ""
            },
            model: {
              value: _vm.person.fullName,
              callback: function($$v) {
                _vm.$set(_vm.person, "fullName", $$v)
              },
              expression: "person.fullName"
            }
          }),
          _c("Breadcrumbs", {
            staticClass: "mb-4",
            attrs: { items: _vm.breadcrumbs }
          }),
          _c(
            "v-card",
            { staticClass: "mb-4" },
            [
              _c(
                "v-tabs",
                {
                  attrs: {
                    vertical: _vm.verticalTabs,
                    centered: !_vm.verticalTabs,
                    "center-active": !_vm.verticalTabs
                  }
                },
                [
                  _c(
                    "v-tab",
                    {
                      staticClass:
                        "d-flex flex-column flex-md-row justify-md-start justify-center"
                    },
                    [
                      _c("v-icon", { attrs: { left: _vm.verticalTabs } }, [
                        _vm._v("account_circle")
                      ]),
                      _vm._v(" Profile ")
                    ],
                    1
                  ),
                  _c(
                    "v-tab",
                    {
                      staticClass:
                        "d-flex flex-column flex-md-row justify-md-start justify-center"
                    },
                    [
                      _c("v-icon", { attrs: { left: _vm.verticalTabs } }, [
                        _vm._v("security")
                      ]),
                      _vm._v(" Permissions ")
                    ],
                    1
                  ),
                  _c(
                    "v-tab",
                    {
                      staticClass:
                        "d-flex flex-column flex-md-row justify-md-start justify-center"
                    },
                    [
                      _c("v-icon", { attrs: { left: _vm.verticalTabs } }, [
                        _vm._v("account_box")
                      ]),
                      _vm._v(" Customers ")
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    [
                      _c("PersonProfileTab", {
                        attrs: { selectedPerson: _vm.person }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    [
                      _c("PersonPermissionsTab", {
                        attrs: { selectedPerson: _vm.person }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    [
                      _c("PersonCustomersTab", {
                        attrs: { person: _vm.person }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          !_vm.isView
            ? _c(
                "div",
                { staticClass: "d-flex justify-end" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        loading: _vm.isPerformingOperation,
                        disabled: _vm.isPerformingOperation,
                        color: "error",
                        large: ""
                      },
                      on: { click: _vm.onClickSave }
                    },
                    [_vm._v("Save")]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }