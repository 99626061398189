var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Breadcrumbs", {
        staticClass: "mb-4",
        attrs: { items: _vm.breadcrumbs }
      }),
      _vm.isLoading
        ? _c(
            "div",
            { staticClass: "center-x" },
            [
              _c("v-skeleton-loader", {
                attrs: { type: "text", width: "100%" }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v("Location Codes")]),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.isAdd,
                          expression: "!isAdd"
                        }
                      ],
                      attrs: { cols: "12", sm: "6" }
                    },
                    [
                      _c("v-text-field", {
                        attrs: { label: "Location Code Id", disabled: "" },
                        model: {
                          value: _vm.currentObject.locationCodeId,
                          callback: function($$v) {
                            _vm.$set(_vm.currentObject, "locationCodeId", $$v)
                          },
                          expression: "currentObject.locationCodeId"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: { label: "Location Code Description" },
                        model: {
                          value: _vm.currentObject.locationCodeDescription,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.currentObject,
                              "locationCodeDescription",
                              $$v
                            )
                          },
                          expression: "currentObject.locationCodeDescription"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: { label: "Location Code" },
                        model: {
                          value: _vm.currentObject.locationCode,
                          callback: function($$v) {
                            _vm.$set(_vm.currentObject, "locationCode", $$v)
                          },
                          expression: "currentObject.locationCode"
                        }
                      })
                    ],
                    1
                  ),
                  !_vm.isAdd
                    ? _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: { label: "Last Edited By", disabled: "" },
                            model: {
                              value: _vm.currentObject.lastEditedBy,
                              callback: function($$v) {
                                _vm.$set(_vm.currentObject, "lastEditedBy", $$v)
                              },
                              expression: "currentObject.lastEditedBy"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", [
            _c(
              "div",
              { staticClass: "d-flex justify-end" },
              [
                _c(
                  "v-btn",
                  {
                    attrs: { color: "error", large: "" },
                    on: { click: _vm.save }
                  },
                  [_vm._v("Save")]
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }